import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import SweetAlertModal from '../../components/SweetAlert/SweetAlertModal';
import { ReactComponent as ViewIcon } from "../../assets/images/common/view-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/common/add-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/common/edit-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/common/close-icon.svg";
import { Link, useNavigate } from 'react-router-dom';
import { ADD_BRAND_CATEGORY, EDIT_BRAND_CATEGORY, VIEW_MEMBER_REVIEW } from '../../constants/pathname';
import { deleteBrandCategory, getBrandCategoryStatusChanging, listBrandCategory } from '../../api/actions/brandCategory';
import { FormControlLabel, RadioGroup, styled, Switch } from "@mui/material";
import { paginationRowsPerPageOptions } from '../../helpers/helper';
import toast from 'react-hot-toast';
import { FaTimes } from 'react-icons/fa';
import { deleteMemberReview, listMemeberRevies } from '../../api/actions/memberRevies';
import dayjs from 'dayjs';

const MemeberReviewList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [limit, setLimit] = useState(25);
  const [search, setSearch] = useState('');
  const [update, setUpdate] = useState(false);
  const [pending, setPending] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [content, setContent] = useState(""); // Content for sweet alert
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [show, setShow] = useState(false);
  const [isSwal, setIsSwal] = useState({ show: false, id: '' });
  const userInfo = JSON.parse(localStorage.getItem('user_info'));

  const clubId = userInfo?.loi?.[0]?.club_id;
  const countryId = userInfo?.club_details?.[0]?.country;

  const handleSearch = (value) => {
    setSearch(value);
    setSearchText(value);
  };

  useEffect(() => {
    if (search.length >= 2) {
      const debounceTimer = setTimeout(() => {
        setDebouncedSearch(search);
      }, 500);

      return () => clearTimeout(debounceTimer);
    } else {
      setDebouncedSearch('');
    }
  }, [search]);

  const clearSearch = () => {
    setSearch('');
    setSearchText('');
    setDebouncedSearch('');
    setUpdate(!update);
  };


  useEffect(() => {
    getListData();
  }, [page, limit, debouncedSearch, update]);

  const getListData = () => {
    setPending(true);

    if (debouncedSearch) {
      setPage(1);
    }
    listMemeberRevies({
      page: debouncedSearch ? 1 : page,
      limit,
      search: debouncedSearch,
      // club:clubId,
      // country:countryId
    })((response) => {
      if (response && response.status) {
        setData(response?.data?.results);
        setTotalRows(response?.data?.count);
        setPending(false);
      }
    });
  };

  const toggleActive = (id) => {
    getBrandCategoryStatusChanging({ id: id })((response) => {
      if (response?.status_code === 200) {
        toast.success(response?.message);
        const updatedData = data.map(item => {
          if (item.id === id) {
            return { ...item, status: response?.status };
          }
          return item;
        });
        setUpdate(!update);
      } else {
        toast.error(response?.message || "Something went wrong");
      }
    });
  };

  const columns = [
    {
      name: "Sl.No",
      key: "name",
      selector: row => row?.name,
      cell: (row, index) =>
        <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.name}>
          {(limit * (page - 1)) + (index + 1)}
        </div>,
      sortable: true,
      grow: 2,
      reorder: true,
      width: '100px'
    },
    { name: "Member Name", selector: row => row.member_name, minWidth: "200px", sortable: true },
    { name: "Club Name", selector: row => row.club_name, minWidth: "200px", sortable: true },
    // { name: "Description", selector: row => row.description, minWidth: "300px", sortable: true },
    { name: "Rating", selector: row => row.rating, minWidth: "100px", sortable: true },
    { name: "Created Date", selector: row => dayjs(row.created_date).format('MMM D, YYYY'), minWidth: "200px" },
  {
      name: "Actions",
      selector: row => (
        <div className="d-flex align-items-center gap-3">
          <Link to={`${VIEW_MEMBER_REVIEW}${row?.id}`}>
            <div className="view-icon cursor-pointer" title="Edit">
              <ViewIcon />
            </div>
          </Link>
          <div className="view-icon remove-icon cursor-pointer" onClick={() => setIsSwal({ show: true, id: row?.id })} title="Delete">
            <CloseIcon />
          </div>
        </div>
      ),
      width: "200px",
      sortable: false,
    },
  ];

  const customStyles = {
    table: {
      style: {
        borderRadius: "15px 15px 0px 0px",
        border: "1px solid var(--border-color)",
        overflow: "hidden"
      },
    },
    headCells: {
      style: {
        backgroundColor: "var(--light-blue)",
        justifyContent: 'flex-start !important',
        color: "var(--dark-gray)",
        fontWeight: "400",
        fontSize: "15px",
      },
    },
    rows: {
      style: {
        height: "45px",
        justifyContent: "start",
      },
    },
    cells: {
      style: {
        fontSize: '14px',
        justifyContent: 'flex-start !important',
        borderRight: "1px solid var(--border-color)",
      },
    },
  };

  const handleDelete = (id) => {
    if (isDeleting) return;
    setIsDeleting(true);
    deleteMemberReview(id)((response) => {
      if (response && response?.status) {
        toast.success('Successfully deleted');
        getListData();
      } else {
        toast.error('Deletion failed');
      }
      setIsDeleting(false);
      setIsSwal({ show: false, id: null });
    });
  };

  return (
    <div className='member-stats-section brands'>
      <SweetAlertModal showSweetAlert={isSwal.show} setShowSweetAlert={(show) => {
        if (show) handleDelete(isSwal.id);
        else setIsSwal({ ...isSwal, show: false });
      }} />
      <div className='page-details'>
        <div className='top-section d-flex align-items-center justify-content-between'>
          <div className='left'>{userInfo?.is_superuser ? 'Member Testimonials' : 'Club Rating'}</div>
          <div className='right d-flex align-items-center gap-3'>
            <div className='search-box'>
              <input
                type='text'
                placeholder='Search Member Name'
                className='form-control'
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
              />
              {searchText && (
                <FaTimes className='clear-icon' onClick={clearSearch} />
              )}
            </div>
          </div>
        </div>

        <div className='table-box mt-3'>
          <DataTable
            columns={columns}
            data={data}
            customStyles={customStyles}
            fixedHeader
            highlightOnHover
            responsive
            pagination
            paginationServer
            paginationPerPage={limit}
            progressPending={pending}
            onChangeRowsPerPage={setLimit}
            paginationTotalRows={totalRows}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            onChangePage={setPage}
            noDataComponent={
              <span style={{ padding: "20px", fontSize: "15px", color: "#555" }}>
                No Reviews Yet
              </span>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MemeberReviewList;