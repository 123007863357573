import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

const Sweetalert = ({ showSweetAlert, setShowSweetAlert, onConfirm, content }) => {
  return (
    <>
      {showSweetAlert && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
          confirmBtnCssClass="custom-confirm-button-class"
          cancelBtnCssClass="custom-cancel-button-class"
          title={
            <>
              <span className="custom-sweetalert-title">Are You Sure</span>
              <div className="text-center" style={{ fontSize: "12px", fontWeight: "normal", lineHeight: 2 }}>
                {content}
              </div>
            </>
          }
          onConfirm={onConfirm} 
          onCancel={() => setShowSweetAlert(false)}
          focusCancelBtn
        />
      )}
    </>
  );
};

export default Sweetalert;
