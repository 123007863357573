import React from 'react'
import ClubOfferAdd from '../../components/ClubOfferAdd/ClubOfferAdd'

const ClubOfferAddPage = () => {

  return (
    <div className='club-offer-add-page'>
        <ClubOfferAdd />
    </div>
  )
}

export default ClubOfferAddPage