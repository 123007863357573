import { DELETE_MEMBER_REVIEW, DETAIL_MEMBER_REVIEW, LIST_MEMBER_REVIEW } from "../api";
import axiosInstance from "../axiosinstance";

export const listMemeberRevies = (props) => onResponse => {
    try {
        let BASE_URL = LIST_MEMBER_REVIEW + '?'
        if (props.page) {
            BASE_URL += 'page=' + props.page + '&'

    }
        if (props.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if(props.sort_by){
            BASE_URL+='sort_by='+props.sort_by+'&'
        }
        if(props.order){
            BASE_URL+='order='+props.order+'&'
        }
      
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }

};
export const deleteMemberReview = (id) => onResponse => {
    try {

        axiosInstance.delete(DELETE_MEMBER_REVIEW, { data: { id: [id] } })

            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getMemebrReviewDetails = (props) => onResponse => {
    try {
        axiosInstance.get(DETAIL_MEMBER_REVIEW + '?id=' + props.id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};