import React from 'react'
import SyncContact from '../../components/SyncContact/SyncContact'

const SyncContactPage = () => {

  return (
    <div className='sync-contact-page'>
        <SyncContact />
    </div>
  )
}

export default SyncContactPage