import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component";
import { ReactComponent as FilterIcon } from "../../../assets/images/common/filter-icon.svg";
import { ReactComponent as AddIcon } from "../../../assets/images/common/add-icon.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/common/edit-icon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/common/close-icon.svg";
import Select from 'react-select';
import { ADD_CLUB, EDIT_CLUB, PLATINUMCLUBNETADD } from '../../../constants/pathname';
import SweetAlertModal from '../../SweetAlert/SweetAlertModal';
import { deleteClubDetails, listClub } from '../../../api/actions/club';
import toast from 'react-hot-toast';
import Drawer from '@mui/material/Drawer';
import { useFormik } from 'formik';
import { FaSearch, FaTimes } from 'react-icons/fa';
const ClubListing = () => {
    const [data, setData] = useState([{}]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [isDeleting, setIsDeleting] = useState(false);
    const [limit, setLimit] = useState(25);
    const [search, setSearch] = useState('');
    const [update, setUpdate] = useState(false);
    const [pending, setPending] = useState(false);
    const navigate = useNavigate();
    const [showSweetAlert, setShowSweetAlert] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isSwal, setIsSwal] = useState({ show: false, id: '' });
    const [showDrawer, setShowDrawer] = useState(false);
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [searchText, setSearchText] = useState('');

    const handleSearch = (value) => {
        setSearch(value);
        setSearchText(value);
    };


    useEffect(() => {
        if (search.length >= 2) {
            const debounceTimer = setTimeout(() => {
                setDebouncedSearch(search);
            }, 500);

            return () => clearTimeout(debounceTimer);
        } else {
            setDebouncedSearch('');
        }
    }, [search]);

   const clearSearch = () => {
        setSearch('');
        setSearchText('');
        setDebouncedSearch('');
        getListData(); 
    };

    const formik = useFormik({
        initialValues: {
            countryName: ""
        },
        onSubmit: (values, resetForm) => {

        }
    });
    const handlePageChange = (newPage) => {
        setPage(newPage);
        getListData(); 
    };
    const columns = [
        {
            name: "Sl.No",
            key: "name",
            selector: row => row?.name,
            cell: (row, index) =>
                <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.name}>
                    {(limit * (page - 1)) + (index + 1)}
                </div>,
            sortable: true,
            grow: 2,
            reorder: true,
            width: '110px'
        },
        { name: "Club Name", selector: row => row.club_name, sortable: true, reorder: true },
        { name: "Club Type", selector: row => row.club_type, sortable: true, reorder: true },
        { name: "Location", selector: row => row.country, sortable: true, reorder: true },
        {
            name: "Actions",
            selector: row =>
                <div className='d-flex align-items-center gap-3'>
                    <Link to={`${EDIT_CLUB}${row?.id}`}>
                        <div className='view-icon cursor-pointer' title="Edit"><EditIcon /></div>
                    </Link>

                    <div className='view-icon cursor-pointer' onClick={() => setIsSwal({ show: true, id: row?.id })} title='Delete'><CloseIcon style={{ marginLeft: "2px" }} /></div>
                </div>,
            width: "180px", sortable: true
        },
    ];

    const customStyles = {
        table: { style: { borderRadius: "15px 15px 0px 0px", border: "1px solid var(--border-color)", overflow: "hidden" } },
        headCells: { style: { backgroundColor: "var(--light-blue)", justifyContent: 'flex-start !important', color: "var(--dark-gray)", fontWeight: "400", fontSize: "15px" } },
        rows: { style: { height: "45px", justifyContent: "start" } },
        cells: { style: { fontSize: '14px', justifyContent: 'flex-start !important', borderRight: "1px solid var(--border-color)" } },
    };

    useEffect(() => { getListData(); }, [update,debouncedSearch]);

    const getListData = () => {
        setPending(true);
        listClub({ page, limit, search:debouncedSearch })(
            (response) => {
                if (response && response.status) {
                    setData(response?.data?.results);
                    setTotalRows(response?.data?.count);
                    setPending(false);
                }
            }
        );
    };

    const handleDelete = (id) => {
        if (isDeleting) return;
        setIsDeleting(true);
        deleteClubDetails(id)((response) => {
            if (response && response?.status) {
                toast.success('Successfully deleted');
                getListData();
            } else {
                toast.error('Deletion failed');
            }
            setIsDeleting(false);
            setIsSwal({ show: false, id: null });
        });
    };

    return (
        <div className='manage-admins-section'>
            <Drawer
                anchor={'right'}
                open={showDrawer}
                onClose={() => setShowDrawer(false)}
                className='member-stats-drawer'
                sx={{
                    '& .MuiPaper-root': {
                        padding: '30px 19px',
                        height: "100vh",
                        // overflow: "hidden"
                    },
                    '& .MuiDrawer-paper': {
                        width: '420px'
                    },
                    '& .MuiBox-root': {
                        width: '420px'
                    },
                    '& .MuiList-root': {
                        display: 'flex',
                        columnGap: '10px',
                        width: '90%',
                        margin: 'auto',
                        paddingTop: "25px"
                    },
                    '& .MuiButtonBase-root': {
                        padding: '7px',
                        textAlign: 'center',
                        alignItems: 'center',
                        borderRadius: '10px',
                        backgroundColor: '#F5F5F5',
                    }
                }}
            >
                <div className="drawer-top-box">
                    <div>Filter</div>
                    <button type="button" class="btn-close" aria-label="Close" onClick={() => setShowDrawer(false)}></button>
                </div>
                <div className="drawer-bottom-box mt-4">
                    <Form onSubmit={(e) => e.preventDefault()}>
                        {/* Club Name */}
                        {/* <div className='item'>                        
                        <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em5">
                            <Form.Label>Club Name</Form.Label>
                            <div className='input-border'>
                                <Form.Control
                                    type="text"
                                    className='input-field'
                                    name='clubname'
                                />
                            </div>
                        </Form.Group>
                    </div>
                 */}


                        {/* Status */}
                        <div className='item'>
                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em5">
                                <Form.Label>Club Category</Form.Label>
                                <div className='input-border'>
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            colors: {
                                                ...theme.colors,
                                            },
                                        })}
                                        placeholder="Select"
                                        options={[{ label: "Platinum Clubs of America", value: "Platinum Clubs of America" },
                                        { label: "Platinum Clubs of the World", value: "Platinum Clubs of the World" },
                                        { label: "Platinum Clubs of Europe and Middle East", value: "Platinum Clubs of Europe and Middle East" },
                                        { label: "Platinum Clubs of Asia-Pacific", value: "Platinum Clubs of Asia-Pacific" }]}
                                        isSearchable={false}
                                        isClearable={true}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        {/* Published */}
                        <div className='item'>
                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em5">
                                <Form.Label>Club Type</Form.Label>
                                <div className='input-border'>
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            colors: {
                                                ...theme.colors,
                                            },
                                        })}
                                        placeholder="Select"
                                        options={[{ label: 'Golf Clubs', value: 'Golf Clubs' }, { label: 'Country Clubs', value: 'Country Clubs' }, { label: 'City Clubs', value: 'City Clubs' }, { label: 'Athletic Clubs', value: 'Athletic Clubs' }, { label: 'Yacht Clubs', value: 'Yacht Clubs' }]}
                                        isSearchable={false}
                                        isClearable={true}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className='item'>
                            <Form.Group className=" d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em5">
                                <Form.Label>Location</Form.Label>
                                <div className='input-border'>
                                    <Form.Control
                                        type="text"
                                        className='input-field'
                                        name='clubname'
                                    />
                                </div>
                            </Form.Group>
                        </div>


                        <div className='submit-box d-flex align-items-center justify-content-end gap-3' style={{ height: "10vh" }}>
                            <button className='btn-type-2 cursor-pointer' >Clear</button>
                            <button className='btn-type-1' type="submit">Submit</button>
                        </div>
                    </Form>
                </div>

            </Drawer>
            <div className='page-details'>
                <div className='top-section d-flex align-items-center justify-content-between'>
                    <div className='left'>Club Listings</div>
                    <div className='right d-flex align-items-center gap-3'>
                    <div className='search-box'>
                        <input
                            type='text'
                            placeholder='Search clubs...'
                            className='form-control'
                            value={searchText}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                        {/* <FaSearch className='search-icon' /> */}
                        {searchText && (
                            <FaTimes className='clear-icon' onClick={clearSearch} />
                        )}
                    </div>
                        <div className='filter-box d-flex align-items-center gap-3 cursor-pointer' onClick={() => setShowDrawer(true)}>
                            <FilterIcon />
                            <span>Filter</span>
                        </div>
                        <div className='ordering-box d-flex align-items-center gap-3 cursor-pointer' onClick={() => navigate(PLATINUMCLUBNETADD)} style={{ padding: '5px 10px', border: '1px solid blue', borderRadius: '4px', transition: 'border 0.3s ease-in-out' }}>
                            <span>Order</span>
                        </div>
                        <div className='add-clubs-info cursor-pointer' onClick={() => navigate(ADD_CLUB, { state: { currentPageName: "Add Admin" } })} title="Add Admin">
                            <AddIcon />
                        </div>
                    </div>
                </div>
                <div className='table-box mt-3'>
                <DataTable
                    columns={columns}
                    data={data}
                    customStyles={customStyles}
                    fixedHeader
                    highlightOnHover
                    responsive
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                />
            </div>
            </div>
            <SweetAlertModal showSweetAlert={isSwal.show} setShowSweetAlert={(show) => {
                if (show) handleDelete(isSwal.id);
                else setIsSwal({ ...isSwal, show: false });
            }} />
        </div>
    )
}

export default ClubListing;
