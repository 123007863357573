import { ReactComponent as AddIcon } from "../../assets/images/common/add-icon.svg";
import { ReactComponent as AddIconBlack } from "../../assets/images/common/add-icon-black.svg";
import { ReactComponent as MinusIcon } from "../../assets/images/common/minus-icon.svg";
import { LuTrash2 } from "react-icons/lu";
import { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
    ClassicEditor,
    Autoformat,
    Autosave,
    Bold,
    Essentials,
    Heading,
    Italic,
    Paragraph,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    Undo,
    List,
    Alignment
} from "ckeditor5";
import { Form } from "react-bootstrap";
// Google Map
import {
    GoogleMap,
    Marker,
    useLoadScript,
    DirectionsService,
    DirectionsRenderer,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";

const containerStyle = {
    width: "100%",
    height: "90vh",
};
const libraries = ["places"];

const About = ({ formik }) => {
    // For Ck Editor
    const editorContainerRef = useRef(null);
    const editorContainerRef1 = useRef(null);
    const editorRef = useRef(null);
    const editorRefHonor = useRef(null);

    const editorClubContainerRef = useRef(null);
    const editorClubRef = useRef(null);
    const [isLayoutReady, setIsLayoutReady] = useState(false);
    const [clubRules, setClubRules] = useState([{ value: "" }]);

    // Google Maps
    const userLocation = JSON.parse(localStorage.getItem("userLocation"));
    const [clubposition, setClubposition] = useState([]);
    const [connect, setConnect] = useState([]);

    // Editor configuration
    const editorConfig = {
        toolbar: {
            items: [
                "undo",
                "redo",
                "|",
                "|",
                "heading",
                "|",
                "bold",
                "italic",
                "bulletedList",
                "numberedList",
                "|",
                "alignment"
            ],
            shouldNotGroupWhenFull: false,
        },
        alignment: {
            options: ['left', 'right', 'center', 'justify']
        },
        plugins: [
            Autoformat,
            Autosave,
            Bold,
            Essentials,
            Heading,
            Italic,
            Paragraph,
            List,
            TextTransformation,
            Table,
            TableCaption,
            TableCellProperties,
            TableColumnResize,
            TableProperties,
            TableToolbar,
            Alignment,
            Undo,
        ],
        heading: {
            options: [
                {
                    model: "paragraph",
                    title: "Paragraph",
                    class: "ck-heading_paragraph",
                },
                {
                    model: "heading1",
                    view: "h1",
                    title: "Heading 1",
                    class: "ck-heading_heading1",
                },
                {
                    model: "heading2",
                    view: "h2",
                    title: "Heading 2",
                    class: "ck-heading_heading2",
                },
                {
                    model: "heading3",
                    view: "h3",
                    title: "Heading 3",
                    class: "ck-heading_heading3",
                },
                {
                    model: "heading4",
                    view: "h4",
                    title: "Heading 4",
                    class: "ck-heading_heading4",
                },
                {
                    model: "heading5",
                    view: "h5",
                    title: "Heading 5",
                    class: "ck-heading_heading5",
                },
                {
                    model: "heading6",
                    view: "h6",
                    title: "Heading 6",
                    class: "ck-heading_heading6",
                },
            ],
        },

        initialData: "",
        menuBar: {
            isVisible: true,
        },
        placeholder: "Type or paste your content here!",
    };

    // Setting layout for Ckeditor
    useEffect(() => {
        setIsLayoutReady(true);

        return () => setIsLayoutReady(false);
    }, []);

    // Google map
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_API_KEY,
        libraries: libraries,
    });
    // const {
    //     ready,
    //     value,
    //     setValue,
    //     suggestions: { status, data },
    //     clearSuggestions,
    // }=usePlacesAutocomplete();

    // const handleInputChange=(e) => {
    //     setValue(e.target.value);
    //     if (e.target.value==="") {
    //         clearSuggestions();
    //     }
    // };
    // const handleSelect = async (address) => {
    //     setValue(address, false);
    //     clearSuggestions();

    //     try {
    //         const results = await getGeocode({ address });
    //         const { lat, lng } = await getLatLng(results[0]);
    //         setMarkerPosition({ lat, lng });

    //         // Update formik state with new latitude and longitude
    //         formik.setValues({
    //             ...formik.values,
    //             latitude: lat,
    //             longitude: lng,
    //         });
    //     } catch (error) {
    //         console.error("Error fetching geocode:", error);
    //     }
    // };
    const [markerPosition, setMarkerPosition] = useState({
        lat: formik.values.latitude || 0,
        lng: formik.values.longitude || 0,
    });
    useEffect(() => {
        if (formik.values.latitude && formik.values.longitude) {
            setMarkerPosition({
                lat: formik.values.latitude,
                lng: formik.values.longitude,
            });
        }
    }, [formik.values.latitude, formik.values.longitude]);

    // Handle input change
    const handleRuleInputChange = (index, value) => {
        // setClubRules(
        //     clubRules.map((item, i) => (i===index? { ...item, value }: item))
        // );
        let filteredList = formik?.values?.club_rules.map((item, i) => (i === index ? { ...item, club_rules: value } : item));

        formik?.setValues({
            ...formik?.values,
            club_rules: filteredList
        });
    };

    // Add a new rule
    const addRule = () => {
        // setClubRules([...clubRules, { value: "" }]);
        formik?.setValues({
            ...formik?.values,
            club_rules: [
                ...formik?.values?.club_rules,
                { club_rules: "" }
            ]
        });
    };

    // Remove a rule
    const removeRule = (index) => {
        // setClubRules(clubRules.filter((item, i) => i !== index));
        let filteredList = formik?.values?.club_rules?.filter((item, i) => i !== index);
        formik?.setValues({
            ...formik?.values,
            club_rules: filteredList
        });
    };

    console.log("ss", formik.values?.club_rules);
    
    return (
        <div className='about-club-section'>
            {/* CK Editor for Document */}
            {/* <div className='panel-type-1 mt-5'>
                About Club
            </div>
            <div
                className="editor-container editor-container_classic-editor mt-4"
                ref={editorContainerRef}
            >
                <div className="editor-container__editor">
                    <div ref={editorRef} id="#editorAbout">
                        {isLayoutReady && (
                            <CKEditor
                                data={formik.values?.about_club || ""} // Populate with fetched data
                                editor={ClassicEditor}
                                config={editorConfig}
                                onReady={(editor) =>{
                                    console.log("Editor Ready:", editor);

                                    editorRef.current = editor
                                }}
                                onChange={(event, editor) => {
                                    const editorData = editor.getData();
                                    // formik.setValues({
                                    //     ...formik.values,
                                    //     about_club: editorData,
                                    // });
                                    formik.setFieldValue("about_club", editorData);
                                }}
                            />
                        )}
                    </div>
                </div>
            </div> */}
            {/* CK Editor for Document */}
            <div className='panel-type-1 mt-5 d-flex justify-content-between'>
                <div>Club Rules <span className="required">*</span></div>
                <AddIconBlack size={18} onClick={addRule} title="Add Rule" className="cursor-pointer" />
            </div>
            <div className="onboard-rules-box row m-0">
                <div className="col-md-6">
                {formik.values?.club_rules?.map((item, index) => (
    <>
        <div className="item border-type-1 d-flex align-items-center mt-4 p-2" key={index}>
            <div className="col-md-11">
                <Form.Control
                    type="text"
                    className="input-field border-0 p-0"
                    name={`club_rules[${index}].club_rules`}
                    placeholder="Enter club rule"
                    value={item?.club_rules}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />                              
            </div>
            <div className="col-md-1 d-flex justify-content-end cursor-pointer">
                {index > 0 && (
                    <LuTrash2 size={18} color="#9d2e16" onClick={() => removeRule(index)} title="Remove" />
                )}
            </div>
        </div>
        {formik.touched.club_rules?.[index]?.club_rules && formik.errors.club_rules?.[index]?.club_rules && (
            <div className="text-danger">{formik.errors.club_rules[index].club_rules}</div>
        )}
    </>
))}
                </div>
            </div>

            {/* <div className='panel-type-1 mt-5'>
                Honors, Awards, Recognition, Accolades
            </div>
            <div
                className="editor-container editor-container_classic-editor mt-4"
                ref={editorContainerRef1}
            >
                <div className="editor-container__editor">
                    <div ref={editorRefHonor} id="#editor">
                        {isLayoutReady && (
                            <CKEditor
                                data={formik.values.honors_awards} // Populate with fetched data
                                editor={ClassicEditor}
                                config={editorConfig}
                                onReady={(editor) => (editorRefHonor.current = editor)}
                                onChange={(event, editor) => {
                                    const editorData = editor.getData();
                                    // formik.setValues({
                                    //     ...formik.values,
                                    //     honors_awards: editorData,
                                    // });
                                    formik.setFieldValue("honors_awards", editorData);
                                }}
                            />
                        )}
                    </div>
                </div> */}
            {/* </div> */}
            {/* <div className='panel-type-1 mt-5'>
                Location <span className="required">*</span>
            </div> */}
            {/* {isLoaded
            ? <div className="location-map mt-4">
            
                <div className="map-box">
                <GoogleMap
                            mapContainerStyle={{ width: "100%", height: "400px" }}
                            center={markerPosition}
                            zoom={3}

                        >
                       <Marker
                                position={markerPosition}
                                draggable={true}
                                onDragEnd={(event) => {
                                    const lat = event.latLng.lat();
                                    const lng = event.latLng.lng();
                                    setMarkerPosition({ lat, lng });

                                  
                                    formik.setValues({
                                        ...formik.values,
                                        latitude: lat,
                                        longitude: lng,
                                    });
                                }}
                            />
                    </GoogleMap>
                </div>
                <div className="search-loc-box">
                        <input
                            type="text"
                            name="search-location"
                            className="w-100"
                            placeholder="Enter a Location"
                            onChange={handleInputChange}
                            value={value}
                        />
                        {value !== "" && status === "OK" && (
                            <ul
                                style={{
                                    listStyleType: "none",
                                    padding: "0",
                                    margin: "8px 0",
                                    backgroundColor: "#fff",
                                    border: "1px solid #ddd",
                                    borderRadius: "4px",
                                    position: "absolute",
                                    top: "100%",
                                    left: "0",
                                    width: "100%",
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    zIndex: 1000,
                                }}
                            >
                                {data.map(({ place_id, description }) => (
                                    <li
                                        key={place_id}
                                        onClick={() => handleSelect(description)}
                                        style={{
                                            padding: "10px",
                                            cursor: "pointer",
                                            borderBottom: "1px solid #eee",
                                            backgroundColor: "#fff",
                                            transition: "background-color 0.2s",
                                        }}
                                        onMouseEnter={(e) =>
                                            (e.target.style.backgroundColor = "#f0f0f0")
                                        }
                                        onMouseLeave={(e) =>
                                            (e.target.style.backgroundColor = "#fff")
                                        }
                                    >
                                        {description}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
            </div>
            : <div>Loading...</div>} */}
        </div>
    )
}

export default About