import { ReactComponent as AddIcon } from "../../assets/images/common/add-icon.svg";
import { ReactComponent as AddIconBlack } from "../../assets/images/common/add-icon-black.svg";
import { ReactComponent as MinusIcon } from "../../assets/images/common/minus-icon.svg";
import { RiDeleteBin6Line } from "react-icons/ri";
// Ck Editor
import { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
    ClassicEditor,
    Autoformat,
    Autosave,
    Bold,
    Essentials,
    Heading,
    Italic,
    Paragraph,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    Undo,
    List,
    Alignment
} from "ckeditor5";
import { Form } from "react-bootstrap";
// Rsuite Datepicker, TimePicker
import { DatePicker, TimePicker } from 'rsuite';

const About=() => {
    const [fromDate, setFromDate]=useState(null);
    const [toDate, setToDate]=useState(null);

    // For Ck Editor
    const editorContainerRef=useRef(null);
    const editorRef=useRef(null);
    const [isLayoutReady, setIsLayoutReady]=useState(false);

    // Editor configuration
    const editorConfig={
        toolbar: {
            items: [
                "undo",
                "redo",
                "|",
                "|",
                "heading",
                "|",
                "bold",
                "italic",
                "bulletedList",
                "numberedList",
                "|",
                "alignment"
            ],
            shouldNotGroupWhenFull: false,
        },
        alignment: {
            options: ['left', 'right', 'center', 'justify']
        },
        plugins: [
            Autoformat,
            Autosave,
            Bold,
            Essentials,
            Heading,
            Italic,
            Paragraph,
            List,
            TextTransformation,
            Table,
            TableCaption,
            TableCellProperties,
            TableColumnResize,
            TableProperties,
            TableToolbar,
            Alignment,
            Undo,
        ],
        heading: {
            options: [
                {
                    model: "paragraph",
                    title: "Paragraph",
                    class: "ck-heading_paragraph",
                },
                {
                    model: "heading1",
                    view: "h1",
                    title: "Heading 1",
                    class: "ck-heading_heading1",
                },
                {
                    model: "heading2",
                    view: "h2",
                    title: "Heading 2",
                    class: "ck-heading_heading2",
                },
                {
                    model: "heading3",
                    view: "h3",
                    title: "Heading 3",
                    class: "ck-heading_heading3",
                },
                {
                    model: "heading4",
                    view: "h4",
                    title: "Heading 4",
                    class: "ck-heading_heading4",
                },
                {
                    model: "heading5",
                    view: "h5",
                    title: "Heading 5",
                    class: "ck-heading_heading5",
                },
                {
                    model: "heading6",
                    view: "h6",
                    title: "Heading 6",
                    class: "ck-heading_heading6",
                },
            ],
        },

        initialData: "",
        menuBar: {
            isVisible: true,
        },
        placeholder: "Type or paste your content here!",
    };

    const [inclusions, setInclusions]=useState([
        { id: 1, inclusion: "", description: "" },
    ]);
    const [experiences, setExperiences]=useState([{ id: 1, value: "" }]);
    const [itineraries, setItineraries]=useState([
        { id: Date.now(), startDate: null, endDate: null, startTime: null, endTime: null },
    ]);

    // Add a new inclusion
    const addInclusion=() => {
        setInclusions([
            ...inclusions,
            { id: Date.now(), inclusion: "", description: "" },
        ]);
    };

    // Remove an inclusion
    const removeInclusion=(id) => {
        setInclusions(inclusions.filter((item) => item.id!==id));
    };

    // Handle input change
    const handleInputChange=(id, field, value) => {
        setInclusions(
            inclusions.map((item) =>
                item.id===id? { ...item, [field]: value }:item
            )
        );
    };

    // Add a new experience
    const addExperience=() => {
        setExperiences([...experiences, { id: Date.now(), value: "" }]);
    };

    // Remove an experience
    const removeExperience=(id) => {
        setExperiences(experiences.filter((item) => item.id!==id));
    };

    // Handle input change
    const handleExpInputChange=(id, value) => {
        setExperiences(
            experiences.map((item) => (item.id===id? { ...item, value }:item))
        );
    };

    // Add new itinerary
    const addItinerary=() => {
        setItineraries([
            ...itineraries,
            { id: Date.now(), startDate: null, endDate: null, startTime: null, endTime: null },
        ]);
    };

    // Remove itinerary
    const removeItinerary=(id) => {
        setItineraries(itineraries.filter((item) => item.id!==id));
    };

    // Update itinerary field
    const updateItinerary=(id, field, value) => {
        setItineraries(
            itineraries.map((item) =>
                item.id===id? { ...item, [field]: value }:item
            )
        );
    };

    // Setting layout for Ckeditor
    useEffect(() => {
        setIsLayoutReady(true);

        return () => setIsLayoutReady(false);
    }, []);


    return (
        <div className='about-club-section'>
            {/* About Event */}
            <div className='panel-type-1'>
                About Event
            </div>
            <div
                className="editor-container editor-container_classic-editor mt-4"
                ref={editorContainerRef}
            >
                {/* CK Editor for Document */}
                <div className="editor-container__editor">
                    <div ref={editorRef} id="#editor">
                        {isLayoutReady&&(
                            <CKEditor
                                data={""}
                                editor={ClassicEditor}
                                config={editorConfig}
                                onReady={(editor) =>
                                    (editorRef.current=editor)
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            {/* Inclusions */}
            <div className='panel-type-1 mt-5'>
                Inclusions <span className="required">*</span>
            </div>
            <div className="inclusions-box mt-4">
                {inclusions.map((item, index) => (
                    <div
                        className="item row m-0 border-type-1 p-23 mb-3"
                        key={item.id}
                    >
                        {/* Inclusion Input */}
                        <div className="col-md-6">
                            <Form.Control
                                type="text"
                                className="input-field"
                                name={`inclusion-${index+1}`}
                                placeholder="Inclusion"
                                value={item.inclusion}
                                onChange={(e) =>
                                    handleInputChange(item.id, "inclusion", e.target.value)
                                }
                            />
                        </div>
                        {/* Description Textarea */}
                        <div className="col-md-6">
                            <Form.Control
                                as="textarea"
                                placeholder="Description"
                                name={`description-${index+1}`}
                                rows={4}
                                value={item.description}
                                onChange={(e) =>
                                    handleInputChange(item.id, "description", e.target.value)
                                }
                            />
                        </div>
                        {/* Delete Button */}
                        {inclusions.length>1&&index>0&&(
                            <div className="d-flex align-items-center gap-1">
                                <RiDeleteBin6Line
                                    className="text-danger cursor-pointer"
                                    onClick={() => removeInclusion(item.id)}
                                    title="Delete Inclusion"
                                    size={18}
                                />
                                <div>Delete Inclusion</div>
                            </div>
                        )}
                    </div>
                ))}
                <div className='d-flex align-items-start btn-type-4 cursor-pointer mt-3' onClick={addInclusion}>
                    <AddIcon />
                    Add New
                </div>
            </div>
            {/* Onboard Experiences */}
            <div className='panel-type-1 mt-5'>
                Onboard Experiences <span className="required">*</span>
            </div>
            {/* Experiences List */}
            <div className="onboard-experiences-box row m-0">
                <div className="col-md-6">
                    {experiences.map((item, index) => (
                        <div
                            className="item border-type-1 d-flex align-items-center mt-4 p-2"
                            key={item.id}
                        >
                            {/* Input Field */}
                            <div className="col-md-11">
                                <Form.Control
                                    type="text"
                                    className="input-field border-0 p-0"
                                    name={`experience-${index+1}`}
                                    placeholder="Enter experience"
                                    value={item.value}
                                    onChange={(e) => handleExpInputChange(item.id, e.target.value)}
                                />
                            </div>

                            {/* Add or Remove Icons */}
                            <div className="col-md-1 d-flex justify-content-end cursor-pointer">
                                {!index? (
                                    // Show Add Icon only on the last field
                                    <AddIconBlack size={18} onClick={addExperience} title="Add" />
                                ):(
                                    // Show Remove Icon for all except the last field
                                    <MinusIcon
                                        size={18}
                                        onClick={() => removeExperience(item.id)}
                                        title="Remove"
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* Itinerary */}
            <div className='panel-type-1 mt-5'>
                Itinerary <span className="required">*</span>
            </div>            
            {/* Itinerary List */}
            <div className="itineray-box">
                {itineraries.map((item, index) => (
                    <div
                        className="item border-type-1 row m-0 mt-4 p-23"
                        key={item.id}
                    >
                        {/* Start Date */}
                        <div className="col-md-3 mb-2 start-date-input">
                            <Form.Group className="mb-3" controlId={`start_date_${index}`}>
                                <Form.Label>
                                    Start Date <span className="required">*</span>
                                </Form.Label>
                                <div className="input-border">
                                    <DatePicker
                                        selected={item.startDate}
                                        onChange={(value) => {
                                            updateItinerary(item.id, "startDate", value);
                                            if (item.endDate&&value>item.endDate) {
                                                updateItinerary(item.id, "endDate", null); // Reset "End Date" if invalid
                                            }
                                        }}
                                    />
                                </div>
                            </Form.Group>
                        </div>

                        {/* End Date */}
                        <div className="col-md-3 mb-2 end-date-input">
                            <Form.Group className="mb-3" controlId={`end_date_${index}`}>
                                <Form.Label>
                                    End Date <span className="required">*</span>
                                </Form.Label>
                                <div className="input-border">
                                    <DatePicker
                                        selected={item.endDate}
                                        onChange={(value) =>
                                            updateItinerary(item.id, "endDate", value)
                                        }
                                    />
                                </div>
                            </Form.Group>
                        </div>

                        {/* Start Time */}
                        <div className="col-md-3 mb-2">
                            <Form.Group className="mb-3" controlId={`start_time_${index}`}>
                                <Form.Label>
                                    Start Time <span className="required">*</span>
                                </Form.Label>
                                <div className="input-border">
                                    <TimePicker
                                        value={item.startTime}
                                        onChange={(value) =>
                                            updateItinerary(item.id, "startTime", value)
                                        }
                                    />
                                </div>
                            </Form.Group>
                        </div>

                        {/* End Time */}
                        <div className="col-md-3 mb-2">
                            <Form.Group className="mb-3" controlId={`end_time_${index}`}>
                                <Form.Label>
                                    End Time <span className="required">*</span>
                                </Form.Label>
                                <div className="input-border">
                                    <TimePicker
                                        value={item.endTime}
                                        onChange={(value) =>
                                            updateItinerary(item.id, "endTime", value)
                                        }
                                    />
                                </div>
                            </Form.Group>
                        </div>

                        {/* Add or Remove Button */}
                        <div className="d-flex align-items-start btn-type-4 cursor-pointer mt-3">
                            {!index? (
                                <div className="d-flex align-items-start">
                                    <AddIcon size={18} onClick={addItinerary} title="Add New" />
                                    <div>Add New</div>
                                </div>
                            ):(
                                <div className="d-flex align-items-center">                                
                                    <RiDeleteBin6Line
                                        className="text-danger cursor-pointer"
                                        size={18}
                                        onClick={() => removeItinerary(item.id)}
                                    />
                                    <div style={{color: "var(--black)"}}>Delete Itinerary </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default About