import React from 'react'
import CreateLetterOfIntroduction from '../../components/CreateLetterOfIntroduction/CreateLetterOfIntroduction'

const CreateLetterOfIntroductionPage = () => {

  return (
    <div className='create-letter-of-introduction'>
        <CreateLetterOfIntroduction />
    </div>
  )
}

export default CreateLetterOfIntroductionPage