import DataTable from "react-data-table-component";
import {ReactComponent as AddIcon} from "../../assets/images/common/add-icon.svg";
import { useNavigate } from "react-router-dom";
import { CREATE_LETTER_OF_INTRODUCTION } from "../../constants/pathname";
import {ReactComponent as ViewIcon} from "../../assets/images/common/view-icon.svg";
import {ReactComponent as EditIcon} from "../../assets/images/common/edit-icon.svg";
import {ReactComponent as CloseIcon} from "../../assets/images/common/close-icon.svg";
import SweetAlertModal from '../../components/SweetAlert/SweetAlertModal';
import { useState } from "react";

const LetterOfIntroduction = () => {
  const navigate = useNavigate();
  // Sweet alert
  const [showSweetAlert, setShowSweetAlert] = useState(false);
 
  const handleDelete = () =>{
    setShowSweetAlert(true);
  }

  // Data for the table
  const data = [
    { id: "01", name: "John Doe", designation: "Software Engineer", address: "123 Main Street, New York, NY, USA" },
    { id: "02", name: "Jane Smith", designation: "Product Manager", address: "456 Elm Street, Los Angeles, CA, USA" },
    { id: "03", name: "Michael Brown", designation: "UX Designer", address: "789 Maple Avenue, Chicago, IL, USA" },
    { id: "04", name: "Emily Davis", designation: "Data Scientist", address: "101 Pine Street, San Francisco, CA, USA" },
    { id: "05", name: "David Wilson", designation: "Marketing Specialist", address: "234 Oak Lane, Seattle, WA, USA" },
    { id: "06", name: "Sophia Johnson", designation: "HR Manager", address: "567 Birch Road, Austin, TX, USA" },
    { id: "07", name: "James Anderson", designation: "Finance Analyst", address: "890 Cedar Drive, Miami, FL, USA" },
    { id: "08", name: "Olivia Martinez", designation: "DevOps Engineer", address: "123 Willow Way, Boston, MA, USA" },
    { id: "09", name: "Liam Taylor", designation: "Project Manager", address: "456 Aspen Trail, Denver, CO, USA" },
    { id: "10", name: "Emma Moore", designation: "Content Writer", address: "789 Spruce Circle, Houston, TX, USA" },
  ];
  
  // Define columns for the table
  const columns = [
    { name: "Name", selector: row => row.name, width: "200px", sortable: true },
    { name: "Designation", selector: row => row.designation, width: "220px", sortable: true },
    { name: "Address", selector: row => row.address, sortable: true, wrap: true },
    { name: "Actions", 
      selector: row => 
      <div className='d-flex align-items-center gap-3'>
          <div className='view-icon cursor-pointer' onClick={() => navigate(CREATE_LETTER_OF_INTRODUCTION, {state : {currentPageName: "View Facilities Category" }})} title='View'><ViewIcon style={{marginTop: "-1px"}}/></div>
          <div className='view-icon cursor-pointer' onClick={() => navigate(CREATE_LETTER_OF_INTRODUCTION, {state : {currentPageName: "Edit Facilities Category" }})} title="Edit"><EditIcon /></div>                
          <div className='view-icon cursor-pointer' onClick={() => handleDelete()} title='Delete'><CloseIcon style={{marginLeft: "2px"}}/></div>
      </div>,  
      width: "180px", sortable: true }
  ];

  // Custom styling for the table
  const customStyles = {
    table: {
        style: {
            borderRadius: "15px 15px 0px 0px",        
            border: "1px solid var(--border-color)",
            overflow: "hidden"
        },
   },
    headCells: {
      style: {
        backgroundColor: "var(--light-blue)",
        justifyContent: 'flex-start !important',
        color: "var(--dark-gray)",
        fontWeight: "400",
        fontSize: "15px",
      },
    },
    rows: {
      style: {
        height: "45px", // Set row height
        justifyContent: "start",
      },
    },
    cells: {
        style: {
            fontSize: '14px',
            justifyContent: 'flex-start !important',
            borderRight: "1px solid var(--border-color)",
        },
    },
  };

  return (
    <div className='member-stats-section'>  
        <SweetAlertModal showSweetAlert={showSweetAlert} setShowSweetAlert={setShowSweetAlert}/>
        <div className='page-details'>
            <div className='top-section d-flex align-items-center justify-content-between'>
                <div className='left'>List Letter of Introduction</div>
                <div className='right'>
                    <div className='add-clubs-info cursor-pointer' onClick={() => navigate(CREATE_LETTER_OF_INTRODUCTION, {state : {currentPageName: "Create Letter of Introduction" }})} title="Create">
                        <AddIcon />
                    </div>
                </div>
            </div>
            <div className='table-box mt-3'>
                <DataTable
                    columns={columns}
                    data={data}
                    customStyles={customStyles}
                    fixedHeader
                    highlightOnHover
                    responsive
                />
            </div>
        </div>
    </div>
  )
}

export default LetterOfIntroduction