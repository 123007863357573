import React, { useState } from 'react'
import DataTable from "react-data-table-component";
import { ReactComponent as FilterIcon } from "../../assets/images/common/filter-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/common/add-icon.svg";
import { ReactComponent as ViewIcon } from "../../assets/images/common/view-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/common/edit-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/common/close-icon.svg";
import SweetAlertModal from '../../components/SweetAlert/SweetAlertModal';
import Select from 'react-select';
// Drawer
import Drawer from '@mui/material/Drawer';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CREATE_ADMIN } from '../../constants/pathname';

const ManageAdmins=() => {
    const navigate=useNavigate();

    const [showDrawer, setShowDrawer]=useState(false);
    const [isFocused, setIsFocused]=useState(false); // State to track focus

    // Sweet alert
    const [showSweetAlert, setShowSweetAlert]=useState(false);

    const handleDelete=() => {
        setShowSweetAlert(true);
    }

    // Data for the table
    const data=[
        { slNo: "01", firstName: "John", lastName: "Doe", email: "john.doe@example.com", role: ["Global Platinum Club Citizenship", "Curated Member Events", "Curated Sponsorship"] },
        { slNo: "02", firstName: "Jane", lastName: "Smith", email: "jane.smith@example.com", role: ["Curated Member Events", "Curated Sponsorship"] },
        { slNo: "03", firstName: "Michael", lastName: "Johnson", email: "michael.johnson@example.com", role: ["Global Platinum Club Citizenship"] },
        { slNo: "04", firstName: "Emily", lastName: "Davis", email: "emily.davis@example.com", role: ["Curated Sponsorship", "Curated Member Events"] },
        { slNo: "05", firstName: "William", lastName: "Brown", email: "william.brown@example.com", role: ["Global Platinum Club Citizenship", "Curated Sponsorship"] },
    ];

    // Define columns for the table
    const columns=[
        { name: "Sl.No", selector: row => row.slNo, width: "110px" },
        { name: "First Name", selector: row => row.firstName, sortable: true, reorder: true },
        { name: "Last Name", selector: row => row.lastName, sortable: true, reorder: true },
        { name: "Email", selector: row => <div title={row.email}>{row.email}</div>, sortable: true, reorder: true, minWidth: "250px" },
        {
            name: "Role",
            selector: row => <div title={row.role.join(", ")}>{row.role.join(", ")}</div>, // Convert array to comma-separated string
            sortable: true,
            reorder: true,
            minWidth: "250px"
        },
        {
            name: "Actions",
            selector: row =>
                <div className='d-flex align-items-center gap-3'>
                    <div className='view-icon cursor-pointer' onClick={() => navigate(CREATE_ADMIN, { state: { currentPageName: "View Club" } })} title='View'><ViewIcon /></div>
                    <div className='view-icon cursor-pointer' onClick={() => navigate(CREATE_ADMIN, { state: { currentPageName: "Edit Club" } })} title='Edit'><EditIcon /></div>
                    <div className='view-icon cursor-pointer' onClick={() => handleDelete()} title='Delete'><CloseIcon style={{marginLeft: "2px"}}/></div>
                </div>,
            minWidth: "100px"
        }
    ];

    // Custom styling for the table
    const customStyles={
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",
                border: "1px solid var(--border-color)",
                overflow: "hidden"
            },
        },
        headCells: {
            style: {
                backgroundColor: "var(--light-blue)",
                justifyContent: 'flex-start !important',
                color: "var(--dark-gray)",
                fontWeight: "400",
                fontSize: "15px",
            },
        },
        rows: {
            style: {
                height: "45px", // Set row height
                justifyContent: "start",
            },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    };

    // Select box style
    const customSelectStyles={
        control: (provided, state) => ({
            ...provided,
            width: "231px",
            overflow: "hidden",
            paddingTop: "2px",
            //   height: state?.isFocused ? "auto" : "38px"
        }),
        multiValue: (provided) => ({
            ...provided,
            borderRadius: 5,
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            width: '80px',
            overflow: 'hidden',
            marginBottom: "2px"
        }),
    };

    return (
        <div className='manage-admins-section'>
            <SweetAlertModal showSweetAlert={showSweetAlert} setShowSweetAlert={setShowSweetAlert}/>
            <Drawer
                anchor={'right'}
                open={showDrawer}
                onClose={() => setShowDrawer(false)}
                className='member-stats-drawer'
                sx={{
                    '& .MuiPaper-root': {
                        padding: '30px 25px',
                        height: "100vh",
                        overflow: "hidden"
                    },
                    '& .MuiDrawer-paper': {
                        width: '420px'
                    },
                    '& .MuiBox-root': {
                        width: '420px'
                    },
                    '& .MuiList-root': {
                        display: 'flex',
                        columnGap: '10px',
                        width: '90%',
                        margin: 'auto',
                        paddingTop: "25px"
                    },
                    '& .MuiButtonBase-root': {
                        padding: '7px',
                        textAlign: 'center',
                        alignItems: 'center',
                        borderRadius: '10px',
                        backgroundColor: '#F5F5F5',
                    }
                }}
            >
                <div className="drawer-top-box">
                    <div>Filter</div>
                    <button type="button" class="btn-close" aria-label="Close" onClick={() => setShowDrawer(false)}></button>
                </div>
                <div className="drawer-bottom-box mt-4">
                    <Form onSubmit={(e) => e.preventDefault()}>
                        {/* Registered */}
                        <div className='item'>
                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em5">
                                <Form.Label>Registered</Form.Label>
                                <div className='input-border'>
                                    <Select
                                        styles={customSelectStyles}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            colors: {
                                                ...theme.colors,
                                            },
                                        })}
                                        placeholder="Select"
                                        options={[
                                            { label: "Registered", value: "registered" },
                                            { label: "Unregistered", value: "unregistered" },
                                        ]}
                                        isSearchable={false}
                                        isClearable={true}
                                        isMulti
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className='submit-box d-flex align-items-center justify-content-end gap-3' style={{ height: "100vh" }}>
                            <div className='btn-type-2 cursor-pointer' >Clear</div>
                            <div className='btn-type-1' type="submit">Submit</div>
                        </div>
                    </Form>
                </div>

            </Drawer>
            <div className='page-details'>
                <div className='top-section d-flex align-items-center justify-content-between'>
                    <div className='left'>Manage Admins</div>
                    <div className='right d-flex align-items-center  gap-3'>
                        <div className='filter-box d-flex align-items-center gap-3 cursor-pointer' onClick={() => setShowDrawer(true)}>
                            <FilterIcon />
                            <span>Filter</span>
                        </div>
                        <div className='add-clubs-info cursor-pointer' onClick={() => navigate(CREATE_ADMIN, { state: { currentPageName: "Add Admin" } })} title="Add Admin">
                            <AddIcon />
                        </div>
                    </div>
                </div>

                <div className='table-box mt-3'>
                    <DataTable
                        columns={columns}
                        data={data}
                        customStyles={customStyles}
                        fixedHeader
                        highlightOnHover
                        responsive
                    />
                </div>
            </div>
        </div>
    )
}

export default ManageAdmins