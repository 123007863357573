import React from 'react'
import ClubProfile from '../../components/ClubProfile/ClubProfile';
import ClubProfileInfo from '../../components/ClubProfile/ClubProfileInfo';

const ClubProfilePage = () => {

  return (
    <div className='club-profile-page'>
        {/* <ClubProfile /> */}
        <ClubProfileInfo />
    </div>
  )
}

export default ClubProfilePage;