import React from 'react'
import { Container, Card } from "react-bootstrap";

const CurrentClubEvents=() => {
    return (
        <div className='manage-members-page cur'>
            <div className='manage-members member-stats-section'>
                <div className='page-details'>
                    <div className='top-section'>
                        <div className='left'>
                            Club Events
                        </div>
                    </div>
                    <Container className="mt-4">
                        <Card className="border-0">
                            <Card.Body>
                                <p>
                                    The Current Club Events Program is an optional service for the Club to choose if it requires MobiCom to bring brand engagements for existing Club events/activities.
                                    MobiCom has partnered with a network of corporations, premium, and luxury brands worldwide to deliver such a service.
                                </p>
                                <h5 className="mt-3" style={{ fontWeight: 500 }}>Inclusions:</h5>
                                <ul className="mt-2" style={{listStyle: "auto", paddingLeft: "40px"}}>
                                    <li>Services of PrivateClubWorld Brand Engagement Specialists;</li>
                                    <li>Ability to leverage the PrivateClubWorld ecosystem.</li>
                                </ul>
                                <h5 className="mt-3" style={{ fontWeight: 500 }}>Payment and Other Terms:</h5>
                                <ul className="mt-2" style={{listStyle: "auto", paddingLeft: "40px"}}>
                                    <li>
                                        Upon listing the current club events by the Club on its CMS, PrivateClubWorld Brand Engagement Specialists
                                        will engage with the Club to finalize options, pricing, rules, inclusions, benefits, and terms prior to publishing and
                                        promoting on the PrivateClubWorld ecosystem. The current club event plan(s) will only be published and promoted on
                                        the PrivateClubWorld ecosystem once terms and conditions are mutually agreed and executed between the Club, MobiCom,
                                        and the corporation/brand partner.
                                    </li>
                                    <li>
                                        All transactions incurred in relation to the Current Club Events Program with the Club for booking and settlement by
                                        brands and/or corporations/entities will be routed through MobiCom and MobiCom will settle all payments to the Club.
                                    </li>
                                    <li>
                                        The Club is required to list their requirement(s) at least 90 (ninety) days prior to the expected date of rendering the service.
                                    </li>
                                    <li>
                                        While MobiCom offers these services on a best effort basis, it does not guarantee delivery of service for any request listed by the Club.
                                    </li>
                                    <li>
                                        An annual activation fee of USD 500 shall be payable at the time MobiCom first renders the service to the Club and not
                                        at the time of listing the event(s) on the CMS requesting this service.
                                    </li>
                                    <li>
                                        This activation fee is valid for a period of 365 days from the date of rendering of the first service by MobiCom.
                                    </li>
                                    <li>
                                        A service fee of 15% (fifteen percent) is applicable for all cash or cash-equivalent sponsorships given to the Club by
                                        MobiCom or any of its partner brands and/or corporations and/or entities.
                                    </li>
                                    <li>
                                        For any listing to be serviced by MobiCom beyond 365 days of the date of the first event serviced, an annual renewal
                                        fee of USD 500 will be charged by MobiCom and shall be payable on the date of rendering the service by MobiCom.
                                    </li>
                                    <li>
                                        No additional renewal fee shall be payable on rendering the service within a 365-day period from the date of first
                                        rendering the renewed service.
                                    </li>
                                    <li>
                                        Upon renewal, a service fee of 15% (fifteen percent) is applicable for all cash or cash-equivalent sponsorships given
                                        to the Club by MobiCom or any of its partner brands and/or corporations/entities.
                                    </li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default CurrentClubEvents