import DataTable from "react-data-table-component";
import { ReactComponent as AddIcon } from "../../assets/images/common/add-icon.svg";
import { ReactComponent as ViewIcon } from "../../assets/images/common/view-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/common/edit-icon.svg";
import { FaTimes } from 'react-icons/fa';
// Drawer
import { useNavigate } from 'react-router-dom';
import { CREATE_SPECIAL_SUBSCRIBERS, VIEW_MEMBER_INFO, VIEW_RECIPROCAL_REQUESTS } from '../../constants/pathname';
import { useEffect, useState } from "react";
import { listSpecialSubscribe } from "../../api/actions/specialUser";
import { paginationRowsPerPageOptions } from "../../helpers/helper";

const SpecialSubscribers = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([{}]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [isDeleting, setIsDeleting] = useState(false);
    const [limit, setLimit] = useState(25);
    
    const [update, setUpdate] = useState(false);
    const [pending, setPending] = useState(false);
    const [showSweetAlert, setShowSweetAlert] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isSwal, setIsSwal] = useState({ show: false, id: '' });
    const [showDrawer, setShowDrawer] = useState(false);

    // Search
    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [searchText, setSearchText] = useState('');

    // Permissions
    const [permissionsList, setPermissionsList] = useState([]);
    
    useEffect(() => {
        if (search.length >= 2) {
            const debounceTimer = setTimeout(() => {
                setDebouncedSearch(search);
            }, 500);

            return () => clearTimeout(debounceTimer);
        } else {
            setDebouncedSearch('');
        }
    }, [search]);

    // Handle search
    const handleSearch = (value) => {
        setSearch(value);
    }
    // Clear search
    const clearSearch = () => {
        setSearch('');
        setDebouncedSearch('');
        setUpdate(!update);
    };

    // Setting permission list
    useEffect(() => {
        let permissions = JSON.parse(localStorage.getItem("user_info"));

        if(permissions && permissions?.permissions && permissions?.permissions?.length){
            setPermissionsList(permissions?.permissions);
        }

    }, []);

    // Define columns for the table
    const columns = [
        {
            name: "Sl.No",
            key: "name",
            selector: row => row?.name,
            cell: (row, index) =>
                <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.name}>
                    {(limit * (page - 1)) + (index + 1)}
                </div>,
            sortable: true,
            grow: 2,
            reorder: true,
            width: '150px'
        },
        { name: "First Name", selector: row => row.first_name, sortable: true, minWidth: '300px' },
        { name: "Last Name", selector: row => row.last_name, sortable: true, minWidth: '300px' },
        { name: "Email", selector: row => row.email, sortable: true, minWidth: '300px' },
        { name: "Club Name", selector: row => row.club_name, sortable: true, minWidth: '200px' },
         { name: "Added By", selector: row => row.added_by || "", sortable: true, minWidth: '200px' },
        { name: "Status", selector: row => row.status, sortable: true ,minWidth: '200px'},
        { name: "Package", selector: row => row.subscriber_type, sortable: true, minWidth: '200px' },
        // {
        //     name: "Actions",
        //     selector: row => (
        //         <div className="d-flex align-items-center gap-3">
        //             <div className="view-icon cursor-pointer" onClick={() => navigate(`${VIEW_MEMBER_INFO}${row?.id}`)} title='View'>
        //                 <ViewIcon />
        //             </div>
        //         </div>
        //     ),
        //     width: "120px",
        //     sortable: false,
        // },
    ];

    // Custom styling for the table
    const customStyles = {
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",
                border: "1px solid var(--border-color)",
                overflow: "hidden"
            },
        },
        headCells: {
            style: {
                backgroundColor: "var(--light-blue)",
                justifyContent: 'flex-start !important',
                color: "var(--dark-gray)",
                fontWeight: "400",
                fontSize: "15px",
            },
        },
        rows: {
            style: {
                height: "45px", // Set row height
                justifyContent: "start",
            },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    };
    useEffect(() => { getListData(); }, [page, limit, debouncedSearch, update]);

    const getListData = () => {
        setPending(true);

        if (debouncedSearch) {
            setPage(1);
        }
        listSpecialSubscribe({
            page: debouncedSearch ? 1 : page,
            limit,
            search: debouncedSearch,
        })(
            (response) => {
                if (response && response.status) {
                    setData(response?.data?.results);
                    setTotalRows(response?.data?.count);
                    setPending(false);
                }
            }
        );
    };

    // Scroll to top    
    useEffect(() => {
        if (window) {
            window.scrollTo(0, 0);
        }
    }, []); 

    return (
        <div className='member-stats-section'>
            <div className='page-details'>
                <div className='top-section d-flex align-items-center justify-content-between'>
                    <div className='left'>Life Time Members</div>
                    <div className='right d-flex align-items-center gap-4'>
                        <div className='search-box'>
                            <input
                                type='text'
                                placeholder='Search by Name/Club'
                                className='form-control'
                                value={search}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                            {
                                search && (
                                    <FaTimes className='clear-icon' onClick={clearSearch} />
                                )}
                        </div> 
                        <div className='add-clubs-info cursor-pointer' onClick={() => navigate(CREATE_SPECIAL_SUBSCRIBERS, { state: { currentPageName: "Add Lifetime Member" } })} title="Add Member">
                            <AddIcon />
                        </div>
                    </div>
                </div>

                <div className='table-box mt-3'>
                    <DataTable
                        columns={columns}
                        data={data}
                        customStyles={customStyles}
                        fixedHeader
                        highlightOnHover
                        responsive
                        pagination
                        paginationServer
                        paginationPerPage={limit}
                        progressPending={pending}
                        onChangeRowsPerPage={setLimit}
                        paginationTotalRows={totalRows}
                        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                        onChangePage={setPage}
                    />
                </div>
            </div>
        </div>
    )
}

export default SpecialSubscribers