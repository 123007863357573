import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
// Icons
import { ReactComponent as RadioIcon} from "../../assets/images/common/radio-icon.svg";
import { ReactComponent as ParagraphIcon} from "../../assets/images/common/paragraph-menu-icon.svg";
import { ReactComponent as ShortAnswerIcon} from "../../assets/images/common/short-ans-icon.svg";
import { ReactComponent as DeleteIcon} from "../../assets/images/common/delete-icon.svg";
import {ReactComponent as AddIcon} from "../../assets/images/common/add-icon.svg";
import { useNavigate } from 'react-router-dom';
import { SURVEY_QUESTIONER } from '../../constants/pathname';

const CreateSurveyQuestioner = () => {

    const navigate = useNavigate();
    const [questionList, setQuestionList] = useState([
        {
            number: 1,
            type: "objective",
            optionsList: [
                {
                    id: 1,
                    checked: false
                }
            ],
            otherOption: false,
        }
    ]);
    
    // Function to handle question type change
    const handleQuestionType = (selectedOption, index) =>{
        setQuestionList((prevList) => 
             prevList.map((item) => 
                 item.number === index 
                     ? { ...item, ['type'] : selectedOption?.value}    
                     : item
             )
        );
    }
    
    // Function to handle options adding
    const handleAddOption = (number, count) =>{        
        
        setQuestionList((prevList) =>
            prevList.map((item) =>
                item.number === number
                    ? {
                        number: number,
                        type: "objective",
                        optionsList: [
                            ...item.optionsList,
                            {
                                id: count,
                                checked: false
                            }
                        ],
                        otherOption: false,
                    }
                    : item
            )
        )
    }

    // Function to handle other option
    const handleOtherOption = (number) =>{

        setQuestionList((prevList) =>
            prevList.map((item) =>
                item?.number === number
                    ? {...item, otherOption : true} 
                    : item
            )
        )
    }
    // Function to delete  other option
    const handleDeleteOtherOption = (number) =>{

        setQuestionList((prevList) =>
            prevList.map((item) =>
                item?.number === number
                    ? {...item, otherOption : false} 
                    : item
            )
        )
    }

    // Function to add more question
    const  handleMoreQuestion = () =>{

        setQuestionList((prevList) => 
            [...prevList,  
                {
                    number: questionList?.length + 1,
                    type: "objective",
                    optionsList: [
                        {
                            id: 1,
                            checked: false
                        }
                    ],
                    otherOption: false,
                }
            ])
    }

    // Function to handle option deletion
    const handleDeleteOption = (questionNumber, optionId) => {
        
        setQuestionList((prevList) =>
          prevList.map((item) =>
            item.number === questionNumber
              ? {
                  ...item,
                  optionsList: item.optionsList.filter((option) => option.id !== optionId),
                }
              : item
          )
        );
    };
      
    // Function to delete question
    const handleDeleteQuestion = (number) =>{
        setQuestionList((prevList) =>
            prevList
              .filter((item) => item.number !== number)
              .map((item, index) => ({
                ...item,
                number: index + 1, // Re-index numbers after deletion
              }))
          );
    }
    // HTML content for paragraph, short answer and objective
    const paragraphContent = (
        <Form.Control 
            as="textarea"
            name='question-01-answer' 
            placeholder="Enter Answer"
            rows={6}
        />
    )

    const shortAnswerContent = (
        <Form.Control 
            type="textarea"
            name='question-01-answer'
            placeholder="Enter Answer" 
            rows={1}
        />)

    const ObjectiveContent = ({other, number, optionsList, count}) => {
        let tempOptionsArray = new Array(count).fill('');                
        
        return(
            <>
                {tempOptionsArray.map((optItem, index) => {
                        let checked = optionsList[index].checked;
                        return(
                            <div className='d-flex align-items-center gap-1 options-list mt-3'>            
                                <input
                                    type='radio'
                                    name={`question-${number}-answer`}
                                    id={`question-${number}-${index}-answer`}
                                    style={{width: "17px", height: "17px"}}
                                />
                                <div>
                                    <label for={`question-${number}-${index++}-answer`} style={{ color: "var(--dark-gray)"}}>
                                        Option {index}
                                    </label>
                                </div>
                                <div className='delete-option-icon cursor-pointer' onClick={() => handleDeleteOption(number, index++)}> 
                                    <DeleteIcon title='Delete'/>
                                </div>
                            </div>
                        )
                    }
                )}
                
                {other && 
                    <div className='d-flex align-items-center gap-1 options-list mt-3'>            
                        <input
                            type='radio'
                            name={`question-${number}-answer`}
                            id={`question-${number}-answer`}
                            style={{width: "17px", height: "17px"}}
                        />
                        <div>
                            <label for={`question-${number}-answer`} style={{ color: "var(--dark-gray)"}}>
                                Other
                            </label>
                        </div>
                        <div className='delete-option-icon cursor-pointer' onClick={() => handleDeleteOtherOption(number)}>                
                            <DeleteIcon title='Delete'/>
                        </div>
                    </div>}
                
                <div className='d-flex align-items-start btn-type-4 cursor-pointer mt-4' style={{ background: "transparent"}}>                    
                    <span onClick={() => handleAddOption(number, count+1)}>
                        <AddIcon style={{marginTop:"-2px"}}/>Add Option
                    </span>&nbsp;
                    <span style={{color: 'var(--black)'}}>or</span> 
                    <span style={{color: 'var(--sandy-brown)'}} onClick={() => handleOtherOption(number)}>&nbsp;add "Other"</span>
                </div>
            </>
        );
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px dashed var(--light-grayish)',
            outline: 'none',
            boxShadow: 'none',
            color: 'var(--medium-gray)',
            fontSize: '15px',
            '&:hover': {
                border: '1px dashed var(--light-grayish)', // Prevent hover border color
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        menu: (provided) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            boxShadow: 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--light-grayish)' : 'white',
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
      }

    const questionOptions = [
        {
            label: 
                <div className='d-flex align-items-center gap-4'>
                    <RadioIcon />
                    <div>Objective</div>
                </div>, 
            value: "objective"
        },
        {
            label: 
                <div className='d-flex align-items-center gap-4'>
                    <ParagraphIcon />
                    <div>Paragraph</div>
                </div>, 
            value: "paragraph"
        },
        {
            label: 
                <div className='d-flex align-items-center gap-4'>
                    <ShortAnswerIcon />
                    <div>Short Answer</div>
                </div>, 
            value: "shortAnswer"
        }
    ]

    
  return (
    <div className='create-clubs-info-section create-survey-questioner'>
        <div className='page-details'>
            <Form onSubmit={(e) => e.preventDefault()}>
                <div className='panel-type-1'>
                    Select Event Name
                </div>
                <div className='row'>
                    <div className='col-md-6 mt-4 mb-4'>                        
                    {/* Event Name */}
                    <Form.Group controlId="eventName">
                        <Form.Label>Select Event Name<span className='required'>*</span></Form.Label>
                        <AsyncSelect
                            classNamePrefix="select"
                            isClearable={true}
                            placeholder={'Select'}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 5, 
                                border: "1px solid var(--light-grayish)",
                            })}
                        />
                    </Form.Group>   
                    </div>
                </div>

                <div className='panel-type-1'>
                    Questionnaire
                </div>
                <div className='questioner-section'>
                    {questionList?.map((item, index) =>{

                        return(                
                            <div className='item mt-4 mb-4'>
                                <div className='row'>
                                    {/* Question */}
                                    <div className='left col-lg-8 col-xl-9'>
                                        <div className='d-flex align-items-center gap-3'>
                                            <div className='question-number'>{(index+1).toString().padStart(2, "0")}</div>
                                            <div className='question-box w-100'>
                                                <Form.Control 
                                                    as="textarea"
                                                    placeholder="Enter Question" 
                                                    name="question-01" 
                                                    rows={1}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Select type */}
                                    <div className='right col-lg-4 col-xl-3'>
                                        <Select
                                            placeholder="Select"
                                            styles={customStyles}
                                            options={questionOptions}                                            
                                            value={questionOptions.find(option => option.value === item?.type)}                                                                                  
                                            onChange={(selectedOption) => handleQuestionType(selectedOption, index + 1) }
                                        />
                                    </div>
                                </div>
                                {/* Answer */}
                                <div className='answer-box mt-4'>
                                    {item?.type === "objective" ?
                                            <ObjectiveContent other={item?.otherOption} number={item?.number} optionsList={item?.optionsList} count={item?.optionsList?.length} />
                                        : item?.type === "paragraph" ?
                                            paragraphContent
                                        : item?.type === "shortAnswer" ?
                                            shortAnswerContent
                                        : '' }                                    
                                </div>
                                {/* Delete question */}
                                <div className='d-flex justify-content-end align-items-center mt-4 gap-1'>
                                    <div className='delete-icon cursor-pointer d-flex align-items-center gap-2' onClick={() => handleDeleteQuestion(item?.number)}>
                                        <DeleteIcon />
                                    </div>
                                    <span>Delete</span>
                                </div>
                            </div>)
                    })}
                </div>
                <div className='d-flex align-items-start btn-type-4 cursor-pointer' onClick={() => handleMoreQuestion()}>                    
                    <AddIcon />
                    Add More Question
                </div>
                <div className='submit-box d-flex align-items-center justify-content-end gap-3'>
                    <button className='btn-type-2 cursor-pointer' onClick={() => navigate(SURVEY_QUESTIONER) }>Cancel</button>
                    <button className='btn-type-1' type="submit" >Submit</button>
                </div>
            </Form>
        </div>
    </div>
  )
}

export default CreateSurveyQuestioner