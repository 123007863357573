import React from 'react'
import ForgotPassword from '../../components/ForgotPassword/ForgotPassword'

const ForgotPasswordPage = () => {

  return (
    <div className='forgot-password-page forgot'>
        <ForgotPassword />
    </div>
  )
}

export default ForgotPasswordPage