import { ADD_CLUB_INVITATION, DELETE_CLUB_INVITATION, GET_CLUB_INVITATION_DETAIL, LIST_CLUB_INVITATION, SEND_CLUB_INVITATION, UPDATE_CLUB_INVITATION_STATUS } from "../api";
import axiosInstance from "../axiosinstance";

export const clubOfferList = (props) => onResponse => {
    
    try {
        let BASE_URL = `${LIST_CLUB_INVITATION}?`;
        if (props?.page) {
            BASE_URL += 'page=' + props.page + '&'
        }
        if (props?.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props?.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};
export const clubOfferDetail = (id) => onResponse => {
    
    try {
        let BASE_URL = `${GET_CLUB_INVITATION_DETAIL}?id=${id}`;
        
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};
export const createInvitation = (props) => onResponse => {
    try {
        axiosInstance.post(ADD_CLUB_INVITATION, props)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};
export const updateInvitationStatus = (props) => onResponse => {
    try {
        axiosInstance.post(UPDATE_CLUB_INVITATION_STATUS, props)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};
export const sendClubInvitation = (id) => onResponse => {
    try {
        axiosInstance.post(SEND_CLUB_INVITATION, {id: id})
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};
export const deleteClubInvitation = (id) => onResponse => {
    try {
        axiosInstance.delete(DELETE_CLUB_INVITATION, { data: { id : [id] } } )
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};