import React from "react";
import { Container } from "react-bootstrap";
import { checkTheme } from "../../utils/utils";
import logo from "../../assets/images/common/logo/logo-pcw-white.png";
import logo1 from "../../assets/images/common/logo/logo-pcn-white.png";
import { CANCELLATION, POLICY_UPDATE } from "../../constants/pathname";

const TermsOfusage = () => {
  const contentStyle = {
    lineHeight: "1.6",
    fontSize: "16px",
  };

  const headingStyle = {
    textAlign: "center",
    marginBottom: "2rem",
    fontWeight: "600",
  };

  const paragraphStyle = {
    marginBottom: "1.5rem",
  };

  const listContainerStyle = {
    marginLeft: "20px",
    marginBottom: "2rem",
  };

  const mainListStyle = {
    listStyle: "outside",
    marginBottom: "1rem",
  };

  const mainListItemStyle = {
    fontWeight: 400,
    marginBottom: "1rem",
  };

  const subListContainerStyle = {
    marginLeft: "30px",
    marginTop: "0.75rem",
    marginBottom: "1.5rem",
  };

  const subListItemStyle = {
    fontWeight: 400,
    marginBottom: "0.75rem",
  };

  const alphabeticalListStyle = {
    listStyle: "none",
    marginBottom: "1rem",
    paddingLeft: "1.5rem",
  };

  const alphabeticalListItemStyle = {
    fontWeight: 400,
    marginBottom: "1rem",
    position: "relative",
  };

  const linkStyle = {
    fontWeight: 400,
    color: "var(--sandy-brown)",
    textDecoration: "none",
  };

  const getAlphabeticalMarker = (index) => {
    return String.fromCharCode(97 + index) + ")";
  };

  return (
    <section className="terms-condtion" style={{ paddingBottom: "10px" }}>
      <div className="logo" style={{ marginBottom: "2rem" }}>
        <div className="image-wrapper">
          <img
            src={checkTheme() ? logo1 : logo}
            alt="Logo"
            style={{ maxWidth: "275px" }}
          />
        </div>
      </div>

      <Container style={contentStyle}>
        <h3 style={headingStyle}>CLUBS - TERMS OF USAGE AGREEMENT</h3>

        <p style={paragraphStyle}>
          <span style={{ fontWeight: 400 }}>
            This is a legally binding agreement (“<strong>Agreement</strong>”) between the Club (“<strong>You</strong>” or “<strong>you</strong>”) and MobiCom America Inc., a Delaware corporation located at 6010 W Spring Creek Parkway, Plano, Texas 75024 (“<strong>MobiCom</strong>” or “<strong>us</strong>” or “<strong>we</strong>”) governing your use of PrivateClubWorld and/or its related internet and web components (collectively, the “<strong>Platform</strong>”).
          </span>
        </p>

        <p style={paragraphStyle}>
          <span style={{ fontWeight: 400 }}>
            Please read the Terms of Usage (“<strong>Terms</strong>”) carefully. By clicking “I Agree,” or accessing/using/installing the Platform or any updates OR by streaming its content from any web portal (as may be applicable), owned by MobiCom, you agree to the terms and conditions contained herein.
          </span>
        </p>

        <p style={paragraphStyle}>
          <span style={{ fontWeight: 400 }}>
            MobiCom provides a platform for clubs, corporates, brands etc., to connect with members of private clubs (“<strong>Users</strong>”) and provide Services  <span style={{ fontStyle: 'italic' }}>(defined below)</span>. Your use of the Platform is subject to the following terms and conditions:
          </span>
        </p>

        <p style={paragraphStyle}>
          <span>When you use the Platform, you agree to be bound by these Terms, our Terms of Use, our Privacy Policy and any separate and specific rules, guidelines, policies, terms, and conditions applicable to such Service, conditions of which shall be treated as an integral part of these Terms. You agree and acknowledge that you have read, understood and agreed to the Terms of Use and our
            &nbsp;<a href={POLICY_UPDATE} target="_blank" style={linkStyle}>
              Privacy Policy
            </a></span>
        </p>

        <p style={paragraphStyle}>
          <span style={{ fontWeight: 600 }}>
            Membership Eligibility
          </span>
        </p>

        <p style={paragraphStyle}>
          <span style={{ fontWeight: 400 }}>
            In order to access and use the Platform or register yourself on a MobiCom Platform, you must be a duly organized, validly existing business organization or other legal entity in good standing under the laws of the country you are established and able to enter into legally binding contracts. You agree that:
          </span>
        </p>

        <div style={listContainerStyle}>
          <ul style={alphabeticalListStyle}>
            <li style={alphabeticalListItemStyle}>
              <span style={{ position: "absolute", left: "-1.5rem" }}>
                {getAlphabeticalMarker(0)}
              </span>
              <span style={{ fontWeight: 400 }}>
                You have the right, authority and legal capacity to agree to the Terms on behalf of yourself/ these Terms have been agreed to on behalf of an authorized representative.
              </span>
            </li>

            <li style={alphabeticalListItemStyle}>
              <span style={{ position: "absolute", left: "-1.5rem" }}>
                {getAlphabeticalMarker(1)}
              </span>
              <span style={{ fontWeight: 400 }}>
                You have read, understood, and agree to be bound by the Terms with respect to yourself and entity authorized by you; and
              </span>
            </li>

            <li style={alphabeticalListItemStyle}>
              <span style={{ position: "absolute", left: "-1.5rem" }}>
                {getAlphabeticalMarker(2)}
              </span>
              <span style={{ fontWeight: 400 }}>
                You have read, understood and agree to be bound by our <a href={POLICY_UPDATE} target="_blank" style={linkStyle}>Privacy Policy</a>
              </span>
            </li>

            <li style={{ listStyle: "none", listStylePosition: "inside" }}>
              <span>with respect to yourself and any entity authorized by you.</span>
            </li>

          </ul>
        </div>

        <p style={paragraphStyle}>
          <span style={{ fontWeight: 600 }}>
            Account and Registration Obligations
          </span>
        </p>

        <div style={subListContainerStyle}>
          <ul style={mainListStyle}>
            <li style={subListItemStyle}>
              <span style={{ fontWeight: 400 }}>
                You must register yourself using your business name on the Platform to access and use the Platform. The steps to use the Platform shall be communicated to you via registered electronic mail.
              </span>
            </li>
            <li style={subListItemStyle}>
              <span style={{ fontWeight: 400 }}>
                You will be required to enter a valid phone number while registering with the Platform. By registering your phone number with us, you consent to be contacted by us via phone calls and/or SMS notifications, in case of any booking related updates. We will not use your information to initiate any promotional phone calls or SMS.
              </span>
            </li>
            <li style={subListItemStyle}>
              <span style={{ fontWeight: 400 }}>
                As part of PrivateClubWorld, you are allowed to provide access to the facilities at your club, and other related services to Users (“<strong>Services</strong>”).
              </span>
            </li>
            <li style={subListItemStyle}>
              <span style={{ fontWeight: 400 }}>
                You must provide an accurate and detailed description of your Services. MobiCom reserves the right to request for any changes to be made in such description if it is found to be incorrect or misleading in any respect.
              </span>
            </li>
            <li style={subListItemStyle}>
              <span style={{ fontWeight: 400 }}>
                You agree that upon entering any transaction with Users, you will not attempt to increase or manipulate the transaction prices agreed upon therein.
              </span>
            </li>
            <li style={subListItemStyle}>
              <span style={{ fontWeight: 400 }}>
                You agree that you are solely responsible for ensuring that any of your Services follow all applicable Law.
              </span>
            </li>
            <li style={subListItemStyle}>
              <span style={{ fontWeight: 400 }}>
                You agree to undertake your best efforts to fulfill any requests for modifications of bookings by Users.
              </span>
            </li>
            <li style={subListItemStyle}>
              <span style={{ fontWeight: 400 }}>
                You agree that you shall independently verify the bona fides of any Users that you choose to deal with on the Platform and use your best judgment on that behalf. Notwithstanding its reasonable efforts on its behalf, MobiCom has no responsibility to verify any information provided by Users which are made available on the Platform. You acknowledge that MobiCom is not and cannot be a party to or control in any manner any transaction between you and any User(s).
              </span>
            </li>
            <li style={subListItemStyle}>
              <span style={{ fontWeight: 400 }}>
                You acknowledge that MobiCom may charge fees for providing access and/or facilitating bookings and/or rendering its services on the Platform.
              </span>
            </li>
          </ul>
        </div>

        <p style={paragraphStyle}>
          <span style={{ fontWeight: 600 }}>
            Fees and Payment Terms
          </span>
        </p>

        <div style={subListContainerStyle}>
          <ul style={mainListStyle}>
            <li style={subListItemStyle}>
              <span style={{ fontWeight: 400 }}>
                MobiCom will charge a license fee as mutually agreed between the Club and MobiCom for use of the Platform to enable their seamless access process to your network of reciprocal clubs published on the Platform.
              </span>
            </li>
            <li style={subListItemStyle}>
              <span style={{ fontWeight: 400 }}>
                MobiCom will charge fees to the Club for booking of private event venues at the Club when the User’s booking is confirmed by both the Club and the User through the Platform. These fees are defined in the Terms of Use Agreements between MobiCom and the Club and also the User.
              </span>
            </li>
            <li style={subListItemStyle}>
              <span style={{ fontWeight: 400 }}>
                MobiCom will charge fees to the Club towards its availing of any of the Optional Services through the Platform, as defined in the License Agreement and/or further quantified in the specific Optional Service Agreement executed between the Club and MobiCom.
              </span>
            </li>
            <li style={subListItemStyle}>
              <span style={{ fontWeight: 400 }}>
                When you receive a request for a booking from the User, such requests are not considered a valid booking unless confirmed by you to the User and further accepted by the User and communicated to you, through the Platform.
              </span>
            </li>
            <li style={subListItemStyle}>
              <span style={{ fontWeight: 400 }}>
                If you were to seek advance payment from the visiting member through the Platform, MobiCom will facilitate a seamless and secure transfer through a payment gateway. MobiCom shall provide you with details of the authorized payment gateway, and you agree to provide and enter into such documentation as may be required by the payment gateway. You confirm that you have provided accurate bank account details and will adhere to any terms and conditions imposed by the payment gateway. All transactions are subject to the payment gateway’s terms and conditions, and any financial information provided and secured are subject to their respective privacy policy; MobiCom bears no responsibility for the same .
              </span>
            </li>
            <li style={subListItemStyle}>
              <span style={{ fontWeight: 400 }}>
                Payment processing services on the Platform are provided by <strong>Stripe Inc.</strong> (hereinafter referred to as “ <strong>Stripe</strong> ”) and are subject to the <a href="https://stripe.com/connect-account/legal" target="_blank" style={linkStyle}> Stripe Connected Account Agreement</a> , which includes the <a href="https://stripe.com/legal" target="_blank" style={linkStyle}>Stripe terms of service</a> (collectively, the “ <strong>Stripe Services Agreement</strong> ”). By agreeing to these terms or continuing to operate as an account holder of the Platform, you agree to be bound by the Stripe Services Agreement, as the same may be modified by Stripe from time to time. As a condition of the Platform enabling payment processing services through Stripe, you agree to provide us accurate and complete information about yourself, and you authorize MobiCom to share it and the transaction information related to your use of the payment processing services provided by Stripe.
              </span>
            </li>
            <li style={subListItemStyle}>
              <span style={{ fontWeight: 400 }}>
                In the event that you cancel any bookings, refund of such transaction shall be governed by the PrivateClubWorld <a href={CANCELLATION} target="_blank" style={linkStyle}>Cancellation Policy (Clubs)</a>  Such payments will be processed through our payment gateway.
              </span>
            </li>
            <li style={subListItemStyle}>
              <span style={{ fontWeight: 400 }}>
                MobiCom may do such checks as it deems fit before approving the receipt of a User’s commitment to pay the transaction price. As a result of such a check if MobiCom is not satisfied with the credibility of the User or genuineness of the transaction/ transaction price, it will have the right at its sole discretion to reject the commitment to pay transaction price and impose such other conditions as it may deem fit. MobiCom is a facilitator for payment from the User to you, and our obligation to pay you is conditional upon successful receipt of the associated payments from User. MobiCom does not guarantee payments for amounts that have not been successfully received by us from Users.
              </span>
            </li>
            <li style={subListItemStyle}>
              <span style={{ fontWeight: 400 }}>
                You may track the status of bookings made, and the status of any payments to be received by you, in the Platform.
              </span>
            </li>
            <li style={subListItemStyle}>
              <span style={{ fontWeight: 400 }}>
                MobiCom is not responsible and assumes no liability whatsoever in respect of any loss or damage arising directly or indirectly to you while receiving payments through the payment gateway, including due to:
              </span>
            </li>

            <ul style={alphabeticalListStyle} className="p-0">
              <li style={alphabeticalListItemStyle}>
                <span style={{ position: "absolute", left: "-1.5rem" }}>
                  {getAlphabeticalMarker(0)}
                </span>
                <span style={{ fontWeight: 400 }}>
                  Incorrect account details furnished by you;
                </span>
              </li>
              <li style={alphabeticalListItemStyle}>
                <span style={{ position: "absolute", left: "-1.5rem" }}>
                  {getAlphabeticalMarker(1)}
                </span>
                <span style={{ fontWeight: 400 }}>
                  Any payment issues arising out of the transaction(s); or
                </span>
              </li>
              <li style={alphabeticalListItemStyle}>
                <span style={{ position: "absolute", left: "-1.5rem" }}>
                  {getAlphabeticalMarker(2)}
                </span>
                <span style={{ fontWeight: 400 }}>
                  Decline of transaction for any other reason(s).
                </span>
              </li>
            </ul>

          </ul>

          <p style={paragraphStyle}>
            <span style={{ fontWeight: 600 }}>
              General terms
            </span>
          </p>

          <p style={paragraphStyle}>
            <span style={{ fontWeight: 400 }}>
              Through the Platform and the Content Management System (“ <strong>CMS</strong> ”), you may list your Services, manage bookings, raise and manage invoices and payments, and such other services that MobiCom may provide from time to time. MobiCom’s services are strictly restricted to providing a Platform and providing ancillary support services. Consequently, you acknowledge that:
            </span>
          </p>

          <div style={subListContainerStyle}>
            <ul style={mainListStyle}>
              <li style={subListItemStyle}>
                <span style={{ fontWeight: 400 }}>
                  You accept responsibility for all activities that occur through your account including any transactions entered into with User(s), content stored, posted or otherwise transmitted through your account even when certain content is posted by others who have access to your account with or without your consent/authorization. You accept that we are not responsible for any changes to the content in your account, including without limitation, Service listings or prices, that are attributable to use of your account by any third party, with or without your consent and you hereby expressly agree not to hold us liable in such a case. We shall not be liable to any person for any loss or damage which may arise as a result of any failure by you to protect your password or account and the loss or damage arising therefrom.
                </span>
              </li>
              <li style={subListItemStyle}>
                <span style={{ fontWeight: 400 }}>
                  You agree that you are solely and entirely responsible for maintaining the confidentiality and security of your account (including display name, passwords, billing information etc) and that MobiCom has no responsibility towards the same. If you know or suspect that someone unauthorized knows your password or any other security breach, then you should notify us by contacting us immediately and in no event later than 24 (twenty four) hours at  <a href="mailto:support@privateclubworld.com" style={linkStyle}>support@privateclubworld.com</a>  If MobiCom has reason to believe that there is likely to be a breach of security or misuse of the Platform, we may require you to change your password or we may suspend your account without any prior notice to you. You agree that MobiCom shall not be responsible for any loss, damages or cost that may be incurred by you during or due to the period of suspension of your account. Without prejudice to the above, MobiCom reserves the right to recover from you any and all damages, losses or costs that are levied on or suffered by MobiCom due to misuse of your account or password whether due to reasons attributable to you or not.
                </span>
              </li>
              <li style={subListItemStyle}>
                <span style={{ fontWeight: 400 }}>
                  You shall not establish any direct engagement with the User of a visiting club or any third-party service provider (Brand, Corporates and any other entities) introduced by MobiCom to the Club, with regard to the Primary Benefits and/or Optional Services outside the Platform. If the Club were to do so, the Club will be liable for penalties as determined by MobiCom alongside revoking the license and suspending services being disqualified from the future use of the Platform. All the communications or payments or any other transactions for a booking of any Service, shall be done only through the MobiCom Platform.
                </span>
              </li>
              <li style={subListItemStyle}>
                <span style={{ fontWeight: 400 }}>
                  Unless specifically stated herein or in the Terms of Use, all transactions are offered and agreed to strictly by and between you and the User(s). MobiCom does not have any control over nor does it determine or advise or in any way involve itself. MobiCom is in no way liable in guaranteeing that you receive favorable reservations to other clubs, brands etc. Further, MobiCom has no obligation to extend to you the same or similar Services as those provided to other users.
                </span>
              </li>
              <li style={subListItemStyle}>
                <span style={{ fontWeight: 400 }}>
                  In order to facilitate interaction with Users, the Platform allows you access to information with regard to Users, including contact information. You agree that you will use such information only for (a) communications in relation to providing your Services and usage of the Platform, which are not unsolicited commercial messages; (b) in accordance with the usage expressly permitted by such Users; and (c) in accordance with applicable Law. You acknowledge that you are solely responsible for any unauthorized use of the same. You will not communicate with Users without their express consent.
                </span>
              </li>
              <li style={subListItemStyle}>
                <span style={{ fontWeight: 400 }}>
                  MobiCom may but is not obliged to mediate or resolve any dispute between you and any User(s).
                </span>
              </li>

            </ul>
          </div>

          <p style={paragraphStyle}>
            <span style={{ fontWeight: 600 }}>
              Disclaimer of Warranties and Liability
            </span>
          </p>

          <div style={subListContainerStyle}>
            <ul style={mainListStyle}>
              <li style={subListItemStyle}>
                <span style={{ fontWeight: 400 }}>
                  You agree that you shall provide and have provided information that is true, accurate, current and complete during the registration process, and at any time that we may ask for such details. You agree that if you provide any information that is untrue, inaccurate, misleading, not current or incomplete or we have reasonable grounds to suspect that such information is untrue, inaccurate, misleading not current or incomplete, or not in accordance with the Terms, the Terms of Use and/or our Privacy Policy, we shall have the right to take such action as we may deem appropriate.
                </span>
              </li>
              <li style={subListItemStyle}>
                <span style={{ fontWeight: 400 }}>
                  You agree that you have the absolute right to and are legally able to offer your Services through our Platform.
                </span>
              </li>
              <li style={subListItemStyle}>
                <span style={{ fontWeight: 400 }}>
                  You agree that the business name you provide during registration must be a name that: accurately identifies you; is not misleading; that you have the right to use; and does not infringe on third party rights or is otherwise in violation of applicable Law.
                </span>
              </li>
              <li style={subListItemStyle}>
                <span style={{ fontWeight: 400 }}>
                  You acknowledge that you and any Users(s) you may interact or transact with via the Platform are each solely liable for your own acts or omissions. You expressly agree that MobiCom is not liable for any acts, errors or omissions, whether on behalf of itself or third parties. MobiCom cannot and does not guarantee that Users shall perform their obligations, including adhere to your club rules and policies.
                </span>
              </li>
              <li style={subListItemStyle}>
                <span style={{ fontWeight: 400 }}>
                  In the event the pricing of any of your Services listed on the Platform is, due to some technical issue, typographical error or mistake, either due to your fault or MobiCom, the booking of your Service by a User may change or stand canceled. In any such event, you agree that MobiCom is not liable for any loss, damage or any liability that may be caused and you hereby release and indemnify MobiCom and/or any of its affiliates, group entities, officers, employees and representatives from any cost, damage, liability or other consequence arising thereon.
                </span>
              </li>
              <li style={subListItemStyle}>
                <span style={{ fontWeight: 400 }}>
                  The Platform, the Services and any and all materials provided by us, including but not limited to those provided though the Platform, are provided on 'as is' and 'as available' basis without any representation or warranties, express or implied except otherwise specified in writing. Nothing on the Platform constitutes, or is meant to constitute, advice of any kind. You rely on the same entirely at your own risk.
                </span>
              </li>

              <li style={subListItemStyle}>
                <span style={{ fontWeight: 400 }}>
                  In no event shall MobiCom be liable for any special, incidental, indirect, lost profits, incidental or consequential damages of any kind in connection with these Terms, even if you have been informed in advance of the possibility of such damages. The limitation on liability as contained in this clause applies to anything related to the Platform and its content, these Terms, Terms of Use, Privacy Policy, and claims for breach of contract; breach of warranty, guarantee, or condition; any claims by Users; strict liability, negligence, or other tort, to the extent permitted by applicable Law.
                </span>
              </li>

              <li style={subListItemStyle}>
                <span style={{ fontWeight: 400 }}>
                  <li style={subListItemStyle}>
                    <span style={{ fontWeight: 400 }}>
                    MobiCom does not make any representation or warranty with regard to its Users, including their solvency, legal title, creditworthiness, identity, etc. You agree that you shall independently verify the bona fides of any Users that you choose to deal with on the Platform and use your best judgment in that behalf. Notwithstanding its reasonable efforts in that behalf, MobiCom has no responsibility to verify any information provided by Users which are made available on the Platform. You acknowledge that MobiCom is not and cannot be a party to or control in any manner any transaction between you and any User(s).
                    </span>
                  </li>
                </span>
              </li>

            </ul>
          </div>

          <p style={paragraphStyle}>
            <span style={{ fontWeight: 600 }}>
            Indemnity
            </span>
          </p>

          <p style={paragraphStyle}>
            <span style={{ fontWeight: 400 }}>
            You shall indemnify and hold harmless MobiCom, its affiliates, subsidiaries, group companies (as applicable) and their respective officers, directors, agents, and employees, from any claim or demand, or actions including reasonable attorneys' fees, made by any third party or penalty imposed or loss or damage incurred by us due to or arising out of your usage of the Platform, breach of these Terms and/or our Privacy Policy and/or our Terms of Use and any other policy or terms (including with a User), or your violation of any applicable Law, rules or regulations or the rights (including infringement of intellectual property rights) of a third party.
            </span>
          </p>

          <p style={paragraphStyle}>
            <span style={{fontWeight: 400}}>
            You release MobiCom, its affiliates, subsidiaries, group companies (as applicable) and their respective officers, directors, agents, and employees, from any cost, damage, liability or other consequence of any of the actions of User(s) and/or MobiCom and specifically waive any claims that you may have in this behalf under any applicable Law. 
            </span>
          </p>

          <p style={paragraphStyle}>
            <span style={{ fontWeight: 600 }}>
            Compliance with Laws
            </span>
          </p>

          <p style={paragraphStyle}>
            <span style={{fontWeight: 400}}>
            You agree and acknowledge that you are in compliance with and shall comply with all applicable Laws. A reference to any applicable Laws or to any legislation, or to any provision of applicable Laws or of any legislation includes a reference to such applicable Laws or legislation as amended, modified or re-enacted from time to time unless the context otherwise requires.
            </span>
          </p>

          <p style={paragraphStyle}>
            <span style={{ fontWeight: 600 }}>
            Applicable Law
            </span>
          </p>

          <p style={paragraphStyle}>
            <span style={{fontWeight: 400}}>
            The Terms shall be governed by and interpreted and construed in accordance with the laws of the state of Delaware, United States of America. The courts of Delaware have exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with these Terms or the usage of the Platform.
            </span>
          </p>

          <p style={paragraphStyle}>
            <span style={{ fontWeight: 600 }}>
            Changes
            </span>
          </p>

          <p style={paragraphStyle}>
            <span style={{fontWeight: 400}}>
            We reserve the right, at our sole discretion, to change, modify, add or remove portions of these Terms, at any time without any prior written notice to you. It is your responsibility to review these Terms periodically for updates / changes. Your continued use of the Platform following the posting of changes will mean that you accept and agree to the revisions. We will post any changes on this page and, if the changes are significant, we will provide a more prominent notice (including, for certain services, email notification of changes). We will also keep prior versions of the Terms in an archive for your review. 
            </span>
          </p>

          <p style={paragraphStyle}>
            <span style={{ fontWeight: 600 }}>
            Severability
            </span>
          </p>

          <p style={paragraphStyle}>
            <span style={{fontWeight: 400}}>
            If any provision of these Terms is determined to be invalid or unenforceable in whole or in part, such invalidity or unenforceability shall attach only to such provision or part of such provision and the remaining part of such provision and all other provisions of these Terms shall continue to be in full force and effect.
            </span>
          </p>

          <p style={paragraphStyle}>
            <span style={{ fontWeight: 600 }}>
            Consequences of breach of Terms
            </span>
          </p>

          <p style={paragraphStyle}>
            <span style={{fontWeight: 400}}>
            If you or your affiliates violate any of the Terms, or any terms and conditions laid down; notwithstanding other resources available to us under applicable Law, we reserve the right to (a) suspend or terminate your account with immediate effect; (b) permanently block access to the Platform; and (c) report to Law Enforcement or Appropriate Authorities. We shall not be liable for any loss, damage, or cost that you may incur due to the same. During such suspension of your account, MobiCom reserves the right to discontinue the provision of any agreed upon services and take such other action as it may deem necessary. Any further bookings may be permitted to be honored only at the discretion of MobiCom. 
            </span>
          </p>

          <p style={paragraphStyle}>
            <span style={{fontWeight: 400}}>
            We reserve the right, at our sole discretion, to decide on a case to case basis to take action as we may deem appropriate and you agree to adhere to the same. We shall not be liable for any loss, damage, or cost that you may incur due to the same.
            </span>
          </p>

          <p style={paragraphStyle}>
            <span style={{ fontWeight: 600 }}>
            Contact Us
            </span>
          </p>

          <p style={paragraphStyle}>
            <span style={{fontWeight: 400}}>
            For any legal clarifications or communications regarding the Platform, please contact us at <a href="mailto:support@privateclubworld.com" style={linkStyle}>support@privateclubworld.com</a>
            </span>
          </p>

        </div>

      </Container>
    </section>
  );
};

export default TermsOfusage;