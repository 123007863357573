import React from "react";
import { Button, Row, Col, Card, Form } from "react-bootstrap";

const PackageConclusion = ({ setValue }) => {
  return (
    <div>
      <h3 className="mb-3" style={{ fontSize: "20px", fontWeight: "400", lineHeight: "25px" }}>
        Package Inclusions
      </h3>

      <Form
        style={{
          border: "1px solid #c58f5a",
          padding: "20px",
          borderRadius: "5px",
          background: "transparent",
        
        }}
      >
        <div className="p-4">
          <p>
            Thank you for your interest to license our Reciprocity Management System to offer a seamless and real-time
            access to your reciprocal club network for your members.
          </p>
          <p>
            This would include booking, customized letter of introduction, option to moderate outbound requests,
            define limitations and benefits including billing, payment, and settlement at the host club.
          </p>
          <p>
            There are no fees charged to the member for this service. Please refer to the quote submitted by PrivateClubWorld
            and approved by the club for the annual license fees charged to the club.
          </p>
          <p>
            In addition, your members have the option to subscribe for an annual fee of $250 and enjoy the benefit of
            unlimited access request to private clubs in our global network outside of your reciprocal clubs.
          </p>

          <Card className="mb-3">
            <Card.Header className="bg-dark text-light">What You Get When You License The Reciprocity Management System</Card.Header>
            <Card.Body>
              <ol>
                <li>PrivateClubWorld Club Management System [CMS]</li>
                <li>PrivateClubWorld Mobile App / Web Portal for Members</li>
                <li>PrivateClubWorld Admin App for Club Employees</li>
                <li>Customer Support through a dedicated Customer Success Team.</li>
              </ol>
            </Card.Body>
          </Card>
          <div
            style={{
              textAlign: "center",
              backgroundColor: "#f8f9fa",
              padding: "15px",
              borderRadius: "5px",
              marginTop: "20px",
              fontWeight: "bold",
              fontSize: "18px",
              color: "#c58f5a",
            }}
          >
          Welcome to PrivateClubWorld!
          </div>
  <Row className="mt-3">
          <Col xs={12} className="text-center text-md-end">
          <Button
              variant="dark"
              style={{
                backgroundColor: "#c58f5a",
                color: "white",
                border: "none",
                padding: "10px 20px",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={() => setValue(1)}
            >
              Next
            </Button>
          </Col>
        </Row>
         
        </div>
      </Form>
    </div>
  );
};

export default PackageConclusion;
