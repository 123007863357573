import React from "react";
import { Container } from "react-bootstrap";
import { checkTheme } from "../../utils/utils";
import logo from "../../assets/images/common/logo/logo-pcw-white.png";
import logo1 from "../../assets/images/common/logo/logo-pcn-white.png";

const PolicyUpdate = () => {
    const contentStyle = {
        lineHeight: "1.6",
        fontSize: "16px",
    };
    
    const headingStyle = {
        textAlign: "center",
        marginBottom: "2rem",
        fontWeight: "600",
    };
    
    const paragraphStyle = {
        marginBottom: "1.5rem",
    };
    
    const listContainerStyle = {
        marginLeft: "20px",
        marginBottom: "2rem",
    };
    
    const mainListStyle = {
        listStyle: "inside",
        marginBottom: "1rem",
    };
    
    const mainListItemStyle = {
        fontWeight: 400,
        marginBottom: "1rem",
    };
    
    const subListContainerStyle = {
        marginLeft: "30px",
        marginTop: "0.75rem",
        marginBottom: "1.5rem",
    };
    
    const subListStyle = {
        listStyle: "inside",
        listStyleType: "circle",
    };
    
    const subListItemStyle = {
        fontWeight: 400,
        marginBottom: "0.75rem",
    };
    
    const linkStyle = {
        fontWeight: 400,
        color: "var(--sandy-brown)",
        textDecoration: "none",
    };
    
    const footerStyle = {
        marginTop: "2rem",
    };

    return (
        <section className="terms-condtion" style={{paddingBottom:"10px"}}>
            <div className="logo" style={{ marginBottom: "2rem" }}>
                <div className="image-wrapper">
                    <img
                        src={checkTheme() ? logo1 : logo}
                        alt="Logo"
                        style={{ maxWidth: "275px" }}
                    />
                </div>
            </div>

            <Container style={contentStyle}>
                <h3 style={headingStyle}>Policy Updates</h3>
                
                <p style={paragraphStyle}>
                    <span style={{ fontWeight: 400 }}>
                        We have made the following updates to agreements that will apply to you.
                    </span>
                </p>

                {checkTheme() && 
                    <p style={paragraphStyle}>
                    <span style={{ fontWeight: 400 }}>
                    1). Renewal with PlatinumClubNet after the first year of registration will no longer require payment of U$1 upon procuring the Platinum Club License from Club Leaders Forum. 
                    </span>
                </p>
                }
                
                <p style={paragraphStyle}>
                    <span style={{ fontWeight: 400 }}>
                        2). We have restructured the Fees and Terms for the following Optional Services Programs
                    </span>
                </p>

                <div style={listContainerStyle}>
                    <ul style={mainListStyle}>
                        <li style={mainListItemStyle}>
                            <span style={{ fontWeight: 400 }}>
                                Sponsoring Current Clubs Events Program
                            </span>
                        </li>
                        
                        <div style={subListContainerStyle}>
                            <ul style={subListStyle}>
                                <li style={subListItemStyle}>
                                    <span style={{ fontWeight: 400 }}>
                                        The annual activation fee of US$500 shall be payable at the time {checkTheme() ? "PlatinumClubNet" : "PrivateClubWorld"} first renders the service to the Club and not at the time of listing the event(s) on the CMS requesting this service.
                                    </span>
                                </li>
                                <li style={subListItemStyle}>
                                    <span style={{ fontWeight: 400 }}>
                                        This activation fee is valid for a period of 12 (twelve) months from the date of rendering of the first service by {checkTheme() ? "PlatinumClubNet" : "PrivateClubWorld"}.
                                    </span>
                                </li>
                                <li style={subListItemStyle}>
                                    <span style={{ fontWeight: 400 }}>
                                        A service fee of 15% (fifteen percent) is applicable for all cash or cash equivalent sponsorships given to the Club by {checkTheme() ? "PlatinumClubNet" : "PrivateClubWorld"} or any of its partner brands and/or corporations and/or entities.
                                    </span>
                                </li>
                            </ul>
                        </div>
                        
                        <li style={mainListItemStyle}>
                            <span style={{ fontWeight: 400 }}>
                                Promoting Private Events Program
                            </span>
                        </li>
                        
                        <div style={subListContainerStyle}>
                            <ul style={subListStyle}>
                                <li style={subListItemStyle}>
                                    <span style={{ fontWeight: 400 }}>
                                        The annual activation fee of US$500 shall be payable at the time {checkTheme() ? "PlatinumClubNet" : "PrivateClubWorld"} generates the first booking for the Club and not at the time of listing the venue(s) on the CMS.
                                    </span>
                                </li>
                                <li style={subListItemStyle}>
                                    <span style={{ fontWeight: 400 }}>
                                        This activation fee is valid for a period of 12 (twelve) months from the date of rendering the service of the first booking by {checkTheme() ? "PlatinumClubNet" : "PrivateClubWorld"}.
                                    </span>
                                </li>
                                <li style={subListItemStyle}>
                                    <span style={{ fontWeight: 400 }}>
                                        A service fee of 10% (ten percent) on the gross transaction value of the Private Event Program invoice will be payable to MobiCom.
                                    </span>
                                </li>
                            </ul>
                        </div>
                        
                        <li style={mainListItemStyle}>
                            <span style={{ fontWeight: 400 }}>
                                Membership Promote Program
                            </span>
                        </li>
                        
                        <div style={subListContainerStyle}>
                            <ul style={subListStyle}>
                                <li style={subListItemStyle}>
                                    <span style={{ fontWeight: 400 }}>
                                        The annual activation fee of US$500 shall be payable at the time {checkTheme() ? "PlatinumClubNet" : "PrivateClubWorld"} brings in a new member to the Club and not at the time of listing membership promotions on the CMS.
                                    </span>
                                </li>
                                <li style={subListItemStyle}>
                                    <span style={{ fontWeight: 400 }}>
                                        This activation fee is valid for a period of 12 (twelve) months from the date of rendering of the first membership by {checkTheme() ? "PlatinumClubNet" : "PrivateClubWorld"}.
                                    </span>
                                </li>
                                <li style={subListItemStyle}>
                                    <span style={{ fontWeight: 400 }}>
                                        A service fee of 10% (ten percent) is payable to MobiCom on the initiation/membership fees for each new member transaction.
                                    </span>
                                </li>
                            </ul>
                        </div>
                        
                        <li style={mainListItemStyle}>
                            <span style={{ fontWeight: 400 }}>
                                Curate New Events Program
                            </span>
                        </li>
                        
                        <div style={subListContainerStyle}>
                            <ul style={subListStyle}>
                                <li style={subListItemStyle}>
                                    <span style={{ fontWeight: 400 }}>
                                        No updates have been made to the licensing terms for this optional service.
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </ul>
                </div>
                
                <p style={footerStyle}>
                    <span style={{ fontWeight: 400 }}>
                        If you have questions about any of these changes or your account, please do not hesitate to{" "}
                    </span>
                    <strong>get in touch</strong>
                    <span style={{ fontWeight: 400 }}>
                        {" "}with us by writing to us at{" "}
                    </span>
                    {checkTheme() ? (
                        <>
                        <a href="mailto:support@platinumclubnet.com" style={linkStyle}>
                        support@platinumclubnet.com
                        </a>
                        <span style={{ fontWeight: 400 }}>.</span>
                        </>

                    ) : (
                        <>
                        <a href="mailto:support@privateclubworld.com" style={linkStyle}>
                            support@privateclubworld.com
                        </a>
                        <span style={{ fontWeight: 400 }}>.</span>
                        </>
                    )}
                </p>
            </Container>
        </section>
    );
};

export default PolicyUpdate;