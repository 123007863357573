import React, { useEffect, useState } from 'react'
import DataTable from "react-data-table-component";
import { ReactComponent as FilterIcon } from "../../assets/images/common/filter-icon.svg";
// Drawer
import Drawer from '@mui/material/Drawer';
import { Form } from 'react-bootstrap';
import Select from "react-select"
// Icon
import {ReactComponent as AddIcon} from "../../assets/images/common/add-icon.svg";
import {ReactComponent as ViewIcon} from "../../assets/images/common/view-icon.svg";
import {ReactComponent as EditIcon} from "../../assets/images/common/edit-icon.svg";
import {ReactComponent as CloseIcon} from "../../assets/images/common/close-icon.svg";
import { useNavigate } from 'react-router-dom';
import { CREATE_CORPORATE } from '../../constants/pathname';
import SweetAlertModal from '../../components/SweetAlert/SweetAlertModal';

const Corporate =() => {
    // Sweet alert
    const [showSweetAlert, setShowSweetAlert] = useState(false);
    
    const [showDrawer, setShowDrawer]=useState(false);
    
    const navigate = useNavigate();

    const handleDelete = () =>{
        setShowSweetAlert(true);
    }

    // Scroll to top    
    useEffect(() => {
        if (window) {
            window.scrollTo(0, 0);
        }
    }, []);

    // Data for the table
    const data = [
        { slNo: "01", name: "OneClubNet Demo Club", type: "Golf Club", status: "Active", city: "New York", country: "USA" },
        { slNo: "02", name: "Sapphire City Club", type: "City Club", status: "Inactive", city: "Los Angeles", country: "USA" },
        { slNo: "03", name: "Estancia Golf Club", type: "Golf Club", status: "Active", city: "Phoenix", country: "USA" },
        { slNo: "04", name: "St. Morrie’s World Country Club", type: "Country Club", status: "Active", city: "Chicago", country: "USA" },
        { slNo: "05", name: "Wise Horse Yacht Club", type: "Yacht Club", status: "Inactive", city: "Miami", country: "USA" },
        { slNo: "06", name: "The Metropolitan Club", type: "City Club", status: "Active", city: "San Francisco", country: "USA" },
        { slNo: "07", name: "The Diamond Club", type: "Private Club", status: "Inactive", city: "Houston", country: "USA" },
        { slNo: "08", name: "The Terminal City", type: "City Club", status: "Active", city: "Vancouver", country: "Canada" },
        { slNo: "09", name: "Shipyard Golf Club", type: "Golf Club", status: "Inactive", city: "Hilton Head", country: "USA" },
        { slNo: "10", name: "Boulder Ridge Country Club", type: "Country Club", status: "Active", city: "Denver", country: "USA" },
    ];

    // Define columns for the table
    const columns = [ 
        { name: "Sl.No", selector: row => row.slNo, minWidth: "100px"  },
        { name: "Name", selector: row => row.name, sortable: true, reorder: true, minWidth: "200px"  },
        { name: "Type", selector: row => row.type, sortable: true, reorder: true, minWidth: "150px" },
        { name: "Status", selector: row => row.status, sortable: true, reorder: true, minWidth: "150px"  },
        { name: "City", selector: row => row.city, sortable: true, reorder: true, minWidth: "200px"   },
        { name: "Country", selector: row => row.country, sortable: true, reorder: true, minWidth: "200px"   },
        {
            name: "Actions",
            selector: row => (
                <div className="d-flex align-items-center gap-3">
                    <div className="view-icon cursor-pointer" title='View'>
                        <ViewIcon onClick={() => navigate(CREATE_CORPORATE, {state : {currentPageName: "View Corporate" }})} />
                    </div>
                    <div className="view-icon cursor-pointer" title='Edit'>
                        <EditIcon onClick={() => navigate(CREATE_CORPORATE, {state : {currentPageName: "Edit Corporate" }})} />
                    </div>
                    <div className="view-icon remove-icon cursor-pointer" onClick={() => handleDelete()} title='Delete'>
                        <CloseIcon />
                    </div>
                </div>
            ),
            width: "100%",
            sortable: false,
        },
    ];

    // Custom styling for the table
    const customStyles={
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",
                border: "1px solid var(--border-color)",
                overflow: "hidden"
            },
        },
        headCells: {
            style: {
                backgroundColor: "var(--light-blue)",
                justifyContent: 'flex-start !important',
                color: "var(--dark-gray)",
                fontWeight: "400",
                fontSize: "15px",
            },
        },
        rows: {
            style: {
                height: "45px", // Set row height
                justifyContent: "start",
            },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    };

    return (
        <div className='member-stats-section'>
            <SweetAlertModal showSweetAlert={showSweetAlert} setShowSweetAlert={setShowSweetAlert}/>

            <Drawer
                anchor={'right'}
                open={showDrawer}
                onClose={() => setShowDrawer(false)}
                className='member-stats-drawer'
                sx={{
                    '& .MuiPaper-root': {
                        padding: '30px 25px',
                        height: "100vh",
                        overflow: "hidden"
                    },
                    '& .MuiDrawer-paper': {
                        width: '420px'
                    },
                    '& .MuiBox-root': {
                        width: '420px'
                    },
                    '& .MuiList-root': {
                        display: 'flex',
                        columnGap: '10px',
                        width: '90%',
                        margin: 'auto',
                        paddingTop: "25px"
                    },
                    '& .MuiButtonBase-root': {
                        padding: '7px',
                        textAlign: 'center',
                        alignItems: 'center',
                        borderRadius: '10px',
                        backgroundColor: '#F5F5F5',
                    }
                }}
            >
                <div className="drawer-top-box">
                    <div>Filter</div>
                    <button type="button" class="btn-close" aria-label="Close" onClick={() => setShowDrawer(false)}></button>
                </div>
                <div className="drawer-bottom-box mt-4">
                    <Form onSubmit={(e) => e.preventDefault()}>                       
                        {/* Name */}
                        <div className='item'>
                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em5">
                                <Form.Label>Name</Form.Label>
                                <div className='input-border'>
                                    <Form.Control
                                        type="text"
                                        className='input-field'
                                        name='name'
                                    />
                                </div>
                            </Form.Group>
                        </div> 
                        {/* Club Type */}
                        <div className='item'>                        
                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em7">
                                <Form.Label>Club Type</Form.Label>
                                <div className='input-border'>
                                    <Select
                                        theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        colors: {
                                            ...theme.colors,
                                        },
                                        })}
                                        placeholder="Select"
                                        isSearchable={false}
                                        isClearable={true}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        {/* Status */}
                        <div className="item">
                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="status">
                                <Form.Label>Status</Form.Label>
                                <div className='input-border'>
                                    <Select
                                        theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        colors: {
                                            ...theme.colors,
                                        },
                                        })}
                                        placeholder="Select"
                                        isSearchable={false}
                                        isClearable={true}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className='submit-box d-flex align-items-center justify-content-end gap-3' style={{ height: "100vh" }}>
                            <button className='btn-type-2 cursor-pointer' >Clear</button>
                            <button className='btn-type-1' type="submit">Submit</button>
                        </div>
                    </Form>
                </div>

            </Drawer>
            <div className='page-details'>
                <div className='top-section d-flex align-items-center justify-content-between'>
                    <div className='left'>Corporates</div>
                    <div className='right d-flex align-items-center gap-3'>
                        <div className='filter-box d-flex align-items-center gap-3 cursor-pointer' onClick={() => setShowDrawer(true)}>
                            <FilterIcon />
                            <span>Filter</span>
                        </div>
                        <div className='add-clubs-info cursor-pointer' title="Create" onClick={() => navigate(CREATE_CORPORATE, {state : {currentPageName: "Add Corporate" }})}>
                            <AddIcon />
                        </div> 
                    </div>
                </div>

                <div className='table-box mt-3'>
                    <DataTable
                        columns={columns}
                        data={data}
                        customStyles={customStyles}
                        fixedHeader
                        highlightOnHover
                        responsive
                    />
                </div>
            </div>
        </div>
    )
}

export default Corporate