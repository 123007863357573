import React, { useEffect, useState } from 'react';
import SweetAlertModal from '../../SweetAlert/SweetAlertModal';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { GROUPS, USER_MANAGEMENT, USERS } from '../../../constants/pathname';
import { useNavigate, useParams } from 'react-router-dom';
import Select from "react-select"
import { createOrUpdateGroup, retriveGroupInfo, retriveRolesDropdown } from '../../../api/actions/usermanagement';
import toast from 'react-hot-toast';

const AddOrEditGroup = () => {
    const [showSweetAlert, setShowSweetAlert] = useState(false);
    const [roles, setRoles] = useState([]);
    const {id}=useParams()
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleDelete = () => {
        setShowSweetAlert(true);
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            roles:[]
        },
        onSubmit: (values,{resetForm}) => {
            setLoading(true);

            if(!values.name) {
                toast.error("Please enter group name");   
                setLoading(false);
                return;             
            }
            if(Array.isArray(values?.roles) && !values?.roles?.length){
                toast.error("Please select at least one role");
                setLoading(false);
                return;
            }

            createOrUpdateGroup(values)((res)=>{
                if(res?.status_code == 200 || res?.status_code == 201){
                    toast(res?.message)
                    resetForm();
                    navigate(GROUPS)
                }
                setLoading(false);
            })
        }
    });

    useEffect(()=>{
        retriveRolesDropdown()((res)=>{
            if(res?.status_code == 200 || res?.status_code == 201){
                setRoles(res?.data?.results?.data)
            }
        })
    },[])
    
    useEffect(()=>{
        if(id){
            retriveGroupInfo(id)((response)=>{

                if(response.status){
                    formik.setValues({
                        ...formik.values,
                        group: response?.data?.group?.id,
                        name:response?.data?.group?.name,
                        roles: response?.data?.group?.roles?.map(item => { return item?.value }),
                        selected_role: response?.data?.group?.roles,
                    })
                
                }
            })
        }
    },[])

    const customStyles={
        control: (provided, state) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            outline: 'none',
            boxShadow: 'none',
            color: 'var(--medium-gray)',
            fontSize: '15px',
            '&:hover': {
                border: '1px solid var(--light-grayish)', // Prevent hover border color
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        menu: (provided) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            boxShadow: 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused? 'var(--light-grayish)':'white',
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
    };

   

    return (
        <div className='user-management-section'>
            <SweetAlertModal showSweetAlert={showSweetAlert} setShowSweetAlert={setShowSweetAlert} />

            <div className='page-details'>
                <div className='panel-type-1'>Group</div>
                <div className='details'>
                    <Form onSubmit={formik.handleSubmit}>
                        <div className='row'>
                            <div className='col-sm-12 col-md-6'>
                            <Form.Group>
                                <Form.Label>Group Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    className='input-field'
                                    name='name'
                                    value={formik?.values?.name}
                                    onChange={formik.handleChange}
                                />
                            </Form.Group>
                            </div>
                            <div className='col-sm-12 col-md-6'>
                            <Form.Group>
                                <Form.Label>Role</Form.Label>
                                <Select
                                    placeholder="Select Role"
                                    styles={customStyles}
                                    isClearable
                                    isMulti
                                    isSearchable
                                    options={roles}
                                    value={roles?.filter((opt)=> formik.values?.roles?.includes(opt?.value))}
                                    onChange={(selectedOptions)=>{
                                        formik.setFieldValue('roles', selectedOptions ? selectedOptions?.map((opt)=> opt?.value) : [])
                                    }}
                                    />
                            </Form.Group>
                            </div>
                        </div>
                        <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-5'>
                            <button className='btn-type-2 cursor-pointer' onClick={() => navigate(GROUPS)}>Cancel</button>
                            <button className='btn-type-1' type="submit" disabled={loading}>{id?"Update":"Submit"}</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default AddOrEditGroup;
