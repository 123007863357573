import React from 'react'
import ClubsPromotions from '../../components/ClubsPromotions/ClubsPromotions'

const ClubsPromotionsPage = () => {

  return (
    <div className='clubs-promotions-page'>
      <ClubsPromotions />
    </div>
  )
}

export default ClubsPromotionsPage