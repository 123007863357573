import React from 'react'
import CreateSurveyQuestioner from '../../components/CreateSurveyQuestioner/CreateSurveyQuestioner'

const CreateSurveyQuestionerPage = () => {

  return (
    <div className='create-survey-questioner-page'>
        <CreateSurveyQuestioner />
    </div>
  )
}

export default CreateSurveyQuestionerPage