import React from 'react'
import CreatePushNotifications from '../../components/CreatePushNotifications/CreatePushNotifications'

const CreatePushNotificationsPage = () => {

  return (
    <div className='create-push-notifications'>
      <CreatePushNotifications />
    </div>
  )
}

export default CreatePushNotificationsPage