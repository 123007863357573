import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { MEMBERREVIEWLIST } from "../../constants/pathname";
import { getMemebrReviewDetails } from "../../api/actions/memberRevies";

const ViewMemberReview = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { country } = location.state || {};
  const { id } = useParams();
  const [reviewDetails, setReviewDetails] = useState(null);

  useEffect(() => {
    if (id) {
      getMemebrReviewDetails({ id, country })(response => {
        if (response.status) {
          setReviewDetails(response.data);
        }
      });
    }
  }, [id, country]);

  return (
    <div className="create-clubs-info-section view-member-request">
      <div className="page-details">
        <div className="panel-type-1">View Member Review</div>
        <Form>
          <div className="club-form mt-5">
            <div className="row">
              <div className="col-xl-4 col-md-6 mb-3">
                <Form.Group controlId="clubName">
                  <Form.Label>Club Name</Form.Label>
                  <Form.Control type="text" value={reviewDetails?.club_name} disabled />
                </Form.Group>
              </div>
              <div className="col-xl-4 col-md-6 mb-3">
                <Form.Group controlId="memberName">
                  <Form.Label>Member Name</Form.Label>
                  <Form.Control type="text" value={reviewDetails?.member_name} disabled />
                </Form.Group>
              </div>
              <div className="col-xl-4 col-md-6 mb-3">
                <Form.Group controlId="rating">
                  <Form.Label>Rating</Form.Label>
                  <Form.Control type="text" value={reviewDetails?.rating} disabled />
                </Form.Group>
              </div>
              {/* <div className="col-xl-4 col-md-6 mb-3">
                <Form.Group controlId="totalReviews">
                  <Form.Label>Total Reviews</Form.Label>
                  <Form.Control type="text" value={reviewDetails?.total_reviews} disabled />
                </Form.Group>
              </div> */}
              {/* <div className="col-xl-4 col-md-6 mb-3">
                <Form.Group controlId="averageRating">
                  <Form.Label>Average Rating</Form.Label>
                  <Form.Control type="text" value={reviewDetails?.average_rating} disabled />
                </Form.Group>
              </div> */}
              <div className="col-xl-4 col-md-6 mb-3">
                <Form.Group controlId="startDate">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control type="text" value={reviewDetails?.start_date} disabled />
                </Form.Group>
              </div>
              <div className="col-xl-4 col-md-6 mb-3">
                <Form.Group controlId="endDate">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control type="text" value={reviewDetails?.end_date} disabled />
                </Form.Group>
              </div>
              <div className="col-12 mb-3">
                <Form.Group controlId="description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control as="textarea" rows={3} value={reviewDetails?.description} disabled />
                </Form.Group>
              </div>
            </div>
            <div className="submit-box d-flex align-items-center justify-content-end gap-3 mt-5">
              <div className="btn-type-2 cursor-pointer" onClick={() => navigate(MEMBERREVIEWLIST)}>
                Back
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ViewMemberReview;
