import React, { useEffect, useState } from 'react';
import SweetAlertModal from '../../SweetAlert/SweetAlertModal';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { USERS } from '../../../constants/pathname';
import { useNavigate, useParams } from 'react-router-dom';
import Select from "react-select"
import { FormControlLabel, RadioGroup, styled, Switch } from "@mui/material";
import { FaRegEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import AsyncSelect from 'react-select/async'
import { createorupdateUser, retriveGroupDropdown, retrivePermissionDropdown, retriveUserInfo } from '../../../api/actions/usermanagement';
import toast from 'react-hot-toast';
import { listAllClubs } from '../../../api/actions/club';
import { checkTheme } from "../../../utils/utils";

const selectStyle = {
    control: (styles, { isFocused }) => ({
        ...styles,
        borderRadius: '5px',
        maxHeight: "40px",
        //   width:"200px",
        minHeight: "30px",
        boxShadow: 'none',
        fontSize: "15px",
        backgroundColor: 'white',
        borderColor: isFocused ? 'gray' : styles.borderColor,
    }),
    placeholder: (styles) => ({
        ...styles,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontSize: "15px"
    }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: 'white',
    }),
    option: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: isFocused ? 'lightgray' : 'white',
        color: 'black',
    }),
};

const AddOrEditUser = () => {
    const [showSweetAlert, setShowSweetAlert] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const [permissions, setPermissions] = useState([]);
    const [loading, setLoading] = useState(false);
    const userInfo = JSON.parse(localStorage.getItem("user_info"));
    // Show password
    const [showPassword, setShowPassword] = useState(false);
    const [clubDropdown, setClubdropdown] = useState([]);
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);


    const formik = useFormik({
        initialValues: {
            user: '',
            first_name: '',
            last_name: '',
            username: '',
            email: '',            
            phonenumber: '',
            is_active: true,
            is_admin: false,
            is_staff: false,
            club: '',
            selectedClub: [],
            country: '',
            club_name: '',
            pcn: false,
            pcw: false,
            permissions: []
        },
        validate: (values) => {
            const errors = {};

            if (!values.first_name) {
                errors.first_name = "First Name is required";
            }

            if (!values.email) {
                errors.email = "Email is required";
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
                errors.email = "Invalid email address";
            }

            
            return errors;

        },
        onSubmit: (values, { resetForm }) => {
            let valuesObj;

            if (id) {
                // Include 'user' when 'id' is present
                valuesObj = { ...values };
            } else {
                // Exclude 'user' when 'id' is not present
                let { user, ...temp } = values;
                valuesObj = temp;
            }

            if (!values?.phonenumber) {
                // Exclude 'phonenumber' 
                let { phonenumber, ...temp } = valuesObj;
                valuesObj = temp;
            }            

            if (!values?.username) {
                valuesObj.username = values?.first_name;
            }
            // Adding details for club admin
            if (userInfo?.club_admin || (userInfo?.is_staff && !userInfo?.is_superuser)) {
                valuesObj.country = userInfo?.club_details?.[0]?.country;
                valuesObj.club = userInfo?.club_details?.[0]?.club_id;
                valuesObj.club_name = userInfo?.club_details?.[0]?.club_name;
            }
            if (id && !valuesObj.country && !valuesObj.club && !valuesObj.club_name) {
                let { country, club, club_name, ...temp } = valuesObj;
                valuesObj = temp;
            }

            if (!id) {
                valuesObj.pcn = checkTheme();
                valuesObj.pcw = !checkTheme();
            }
            if (userInfo?.is_superuser && !id && !valuesObj?.club) {
                toast.error("Please select a club");
                return;
            }
            
            setLoading(true);

            createorupdateUser(valuesObj)((response) => {
                console.log("response", response?.response);

                if (response?.status_code == 200 || response?.status_code == 201) {
                    toast.success(id ? "User updated successfully!" : "User created successfully!")
                    resetForm()
                    navigate(USERS)
                } else if (response?.response?.data?.errors?.email) {
                    toast.error(response?.response?.data?.errors?.email?.[0] || "Something went wrong!");
                } else if (response?.response?.data?.errors?.username) {
                    toast.error(response?.response?.data?.errors?.username?.[0] || "Something went wrong!");
                }else if (response?.response?.data?.errors?.phonenumber) {
                    toast.error(response?.response?.data?.errors?.phonenumber?.[0] || "Something went wrong!");
                }
                setLoading(false);
            })

        }
    });


    useEffect(() => {
        listAllClubs({
            page,
            limit: limit
        })((res) => {
            if (res?.status) {
                setClubdropdown(res?.data.results?.map(item => ({
                    value: item.club,
                    label: item.club_name,
                    country: item.country
                })));
            }
        })
    }, [])


    let typingTimerone;
    const loadOptionClub = inputValue => {
        return new Promise((resolve, reject) => {
            clearTimeout(typingTimerone);
            typingTimerone = setTimeout(() => {
                if (inputValue.length >= 3) {
                    listAllClubs({ page: 1, limit: 10, search: inputValue })(response => {
                        if (response?.status) {
                            resolve(response?.data?.results?.map(item => ({
                                value: item.club,
                                label: item.club_name,
                                country: item.country
                            })));
                        }
                    })
                }
                else {
                    resolve([])
                }
            }, 300);
        })
    };

    useEffect(() => {
        if (id) {
            retriveUserInfo(id)((response) => {

                if (response.status) {
                    formik.setValues({
                        ...formik.values,
                        user: response?.data?.pk || id,
                        username: response?.data?.username,
                        pcn: response?.data?.pcn,
                        pcw: response?.data?.pcw,
                        email: response?.data?.email,                        
                        phonenumber: response?.data?.phonenumber,
                        is_active: response?.data?.is_active,
                        is_admin: response?.data?.is_admin,
                        is_staff: response?.data?.is_staff,
                        first_name: response?.data?.first_name,
                        last_name: response?.data?.last_name,
                        club: response?.data?.club_details?.club_id,
                        country: response?.data?.club_details?.country_id,
                        permissions: response?.data?.permissions?.length ? response?.data?.permissions : [], 
                        selectedClub: response?.data?.club_details
                            ? {
                                value: response?.data?.club_details?.club_id,
                                label: response?.data?.club_details?.club_name,
                                country: response?.data?.club_details?.country_id
                            }
                            : null,
                        club_name: response?.data?.club_details
                            ? response?.data?.club_details?.club_name
                            : null,
                    })

                }
            })
        }
    }, []);

    const CustomSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 39,
        height: 20,
        padding: 0,
        marginLeft: 5,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            // margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: 'var(--sandy-brown)',
                    opacity: 1,
                    border: 0,
                    ...theme.applyStyles('dark', {
                        backgroundColor: '#2ECA45',
                    }),
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.grey[100],
                ...theme.applyStyles('dark', {
                    color: theme.palette.grey[600],
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                backgroundColor: 'var(--sandy-brown)',
                opacity: 0.7,
                ...theme.applyStyles('dark', {
                    opacity: 0.3,
                }),
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 20,
            height: 20,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#E9E9EA',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
            ...theme.applyStyles('dark', {
                backgroundColor: '#39393D',
            }),
        },
    }));

    const handleChecked1 = (e) => {
        formik.setValues({
            ...formik.values,
            is_active: e.target.checked,

        });
    };


    useEffect(() => {
        retrivePermissionDropdown({ page: 1, limit: 100 })((response) => {
            if (response?.status_code == 200) {
                setPermissions(response?.data?.results)
            }
        })
    }, []);

    console.log(formik.values.permissions, permissions);
    
    const selectAllPermission = () =>{

        let idList = permissions?.map(item => item.id);

        if(checkTheme()){
            idList = permissions?.filter(item => item?.codename !== "Affiliated Clubs")?.map(item => item.id);
        }        
        
        if(checkTheme() ? formik.values?.permissions?.length === 14 : formik.values?.permissions?.length === 15){
            formik.setValues({
                ...formik.values,
                permissions: []
            });
        }else{
            formik.setValues({
                ...formik.values,
                permissions: idList
            });
        }
    }
    const handleToggleChange = (id) => {
        let temp = [...formik.values.permissions]
        if (temp.includes(id)) {
            let filtertemp = temp.filter(item => item != id)
            formik.setValues({
                ...formik.values,
                permissions: filtertemp
            })
        }
        else {
            temp.push(id)
            formik.setValues({
                ...formik.values,
                permissions: temp
            })
        }

    }
    console.log(formik?.values)
    return (
        <div className='user-management-section'>
            <SweetAlertModal showSweetAlert={showSweetAlert} setShowSweetAlert={setShowSweetAlert} />

            <div className='page-details'>
                <div className='panel-type-1'>User</div>
                <div className='details'>
                    <Form onSubmit={formik.handleSubmit}>
                        <div className='row'>
                            <div className='col-sm-12 col-md-4 mb-1 mt-3'>
                                <Form.Group>
                                    <Form.Label>First Name <sup className='text-danger'>*</sup></Form.Label>
                                    <Form.Control
                                        type="text"
                                        className='dottedrole-input-half'
                                        placeholder="Enter First Name"
                                        name='first_name'
                                        maxLength={35}
                                        value={formik.values?.first_name}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            formik.setFieldValue(
                                                'first_name',
                                                value.charAt(0).toUpperCase() + value.slice(1)
                                            );
                                        }}
                                        isInvalid={formik.touched.first_name && formik.errors.first_name}
                                    />
                                    {formik.touched.first_name && formik.errors.first_name &&
                                        <Form.Control.Feedback type="invalid">{formik.errors.first_name}</Form.Control.Feedback>}
                                </Form.Group>
                            </div>
                            <div className='col-sm-12 col-md-4 mb-1 mt-3'>
                                <Form.Group>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className='dottedrole-input-half'
                                        placeholder="Enter Last Name"
                                        name='last_name'
                                        maxLength={35}
                                        value={formik.values?.last_name}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            formik.setFieldValue(
                                                'last_name',
                                                value
                                            );
                                        }}                                        
                                    />                                    
                                </Form.Group>
                            </div>
                            <div className='col-sm-12 col-md-4 mb-1 mt-3'>
                                <Form.Group>
                                    <Form.Label>Email<sup className='text-danger'>*</sup></Form.Label>
                                    <Form.Control
                                        className='dottedrole-input-half'
                                        placeholder=" Enter Email ID"
                                        name='email'
                                        value={formik.values?.email}
                                        onChange={formik.handleChange}
                                        isInvalid={formik.touched.email && formik.errors.email}
                                    />
                                    {formik.touched.email && formik.errors.email &&
                                        <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>}
                                </Form.Group>
                            </div>                           
                            <div className='col-sm-12 col-md-4 mt-3'>
                                <Form.Group>
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control
                                        type="number"
                                        className='input-field'
                                        name='phonenumber'
                                        value={formik.values?.phonenumber}
                                        // onChange={formik.handleChange}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            if (/^\d*$/.test(value) && value.length <= 15) { // Allow only numbers & limit to 10 digits
                                                formik.setFieldValue("phonenumber", value);
                                            }
                                        }}
                                        maxLength={15}
                                    />
                                </Form.Group>
                            </div>

                            {userInfo?.is_superuser &&
                                <div className='col-sm-12 col-md-3 mt-3'>
                                    <Form.Group>
                                        <Form.Label>Club<span className='required'><sup>*</sup></span></Form.Label>
                                        <AsyncSelect
                                            placeholder="Select..."
                                            isClearable
                                            value={formik?.values?.selectedClub}
                                            onChange={(selectedOption) => {
                                                console.log(selectedOption, "||||SELE")
                                                formik.setFieldValue("club", selectedOption ? selectedOption.value : "");
                                                formik.setFieldValue("country", selectedOption ? selectedOption.country : "");
                                                formik.setFieldValue("selectedClub", selectedOption);
                                                formik.setFieldValue("club_name", selectedOption?.label || "");
                                            }}
                                            defaultOptions={clubDropdown}
                                            loadOptions={loadOptionClub}
                                            styles={selectStyle}
                                            isDisabled={id ? true : false}
                                        />
                                    </Form.Group>
                                </div>}
                            <div className='col-sm-12 col-md-2 mt-5'>
                                <Form.Group>
                                    <Form.Label>Active</Form.Label>
                                    <CustomSwitch
                                        checked={formik.values?.is_active}
                                        onChange={handleChecked1}
                                        color="primary"
                                        name="is_active"
                                        value={formik.values?.is_active} />
                                </Form.Group>
                            </div>                            
                        </div>
                        <div>
                            <div className='panel-type-1 mt-5 mb-2'>Permissions</div>
                            {Array.isArray(permissions) && permissions?.length 
                                ? <Form.Check
                                        label="Select All"     
                                        checked={checkTheme() ? formik.values?.permissions?.length === 14 : formik.values?.permissions?.length === 15} 
                                        onChange={() => selectAllPermission()}
                                    /> 
                                : '' }
                            {(!checkTheme() ? 
                                permissions 
                                : permissions?.filter(item => item?.codename !== "Affiliated Clubs")
                             )?.map((item, index) => (
                                    <div key={index}>
                                    <Form.Check
                                        label={item?.codename}
                                        checked={formik.values.permissions?.includes(item?.id)}
                                        onChange={() => handleToggleChange(item?.id)}
                                    />
                                    </div>
                                ))}
                        </div>
                        <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-5'>
                            <button className='btn-type-2 cursor-pointer' onClick={() => navigate(USERS)}>Cancel</button>
                            <button className='btn-type-1' type="submit" disabled={loading}>Submit</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default AddOrEditUser;
