import { CREATE_CLUB, DELETE_CLUB, DETAIL_CLUB, LIST_CLUB, CLUB_TYPE_DROPDOWN_URL, CLUB_FACILITY_DELETE, LIST_ALL_CLUBS, ADD_AFFILIATED_CLUB, CLUB_HOST_BOOKING, AFFILIATED_CLUBS_LIST } from "../api";
import axiosInstance from "../axiosinstance";

export const listClub = (props) => onResponse => {
    try {
        let BASE_URL = LIST_CLUB + '?'
        if (props.page) {
            BASE_URL += 'page=' + props.page + '&'

        }
        if (props.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if(props.sort_by){
            BASE_URL+='sort_by='+props.sort_by+'&'
        }
        if(props.order){
            BASE_URL+='order='+props.order+'&'
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }

};
export const createAddClub = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_CLUB, props.values)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};
export const deleteClubDetails = (id) => onResponse => {
    try {

        axiosInstance.delete(DELETE_CLUB, { data: { id: [id] } })

            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getClubDetails = (props) => onResponse => {
    try {
        axiosInstance.get(DETAIL_CLUB + '?id=' + props.id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const clubTypeListDropDown = (props) => onResponse => {
    try {
        let BASE_URL = `${CLUB_TYPE_DROPDOWN_URL}?`
        if (props?.page) {
            BASE_URL += `page=${props?.page}&`
        }
        if (props?.limit) {
            BASE_URL += `limit=${props?.limit}&`
        }
        if (props?.search_param) {
            BASE_URL += `search_param=${props?.search_param}&`
        }


        axiosInstance.get(BASE_URL, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
              
                onResponse(err?.response?.data);
            });

    } catch (error) {

    }
}

export const deleteClubFacility = (id) => onResponse =>{

    try {

        axiosInstance.delete(CLUB_FACILITY_DELETE, {data: { id: [id] } })

            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }
    
}

export const listAllClubs = (props) => onResponse => {
    try {
        let BASE_URL = `${LIST_ALL_CLUBS}?`
        if (props?.page) {
            BASE_URL += `page=${props?.page}&`
        }
        if (props?.limit) {
            BASE_URL += `limit=${props?.limit}&`
        }
        if (props?.filter?.status_club) {
            BASE_URL += `status_club=${props?.filter?.status_club}&`
        }
        if (props?.search) {
            BASE_URL += `search=${props?.search}&`
        }
        if (props?.dropdown_search) {
            BASE_URL += `dropdown_search=${props?.dropdown_search}&`
        }
        if (props?.club) {
            BASE_URL += `club=${props?.club}&`
        }
        if (props?.country) {
            BASE_URL += `country=${props?.country}&`
        }
        
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }
}

export const addAffiliatedClubs = (props) => onResponse => {
    try {
        axiosInstance.post(ADD_AFFILIATED_CLUB, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

}

export const clubHostBooking = (props) => onResponse => {
    try{
        axiosInstance.post(CLUB_HOST_BOOKING,props)
        .then((response) => {
            onResponse(response.data);
        })
        .catch((err) => {
            onResponse(err);
        });
    } catch (err){

    }
}


export const affiliatedListingClub = (props) => onResponse => {
    try {
        let BASE_URL = `${AFFILIATED_CLUBS_LIST}?`
        if (props?.page) {
            BASE_URL += `page=${props?.page}&`
        }
        if (props?.limit) {
            BASE_URL += `limit=${props?.limit}&`
        }
        if (props?.search) {
            BASE_URL += `search=${props?.search}&`
        }
        if (props?.id) {
            BASE_URL += `id=${props?.id}&`
        }
        if (props?.country) {
            BASE_URL += `country=${props?.country}&`
        }
        
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }
}