import { CHANGE_PASSWORD, EMAIL_VERIFICATION, LOGIN, LOGOUT, OTP_VERIFICATION, RESET_PASSWORD } from "../api";
import axiosInstance from "../axiosinstance";

export const userLogin=(props) => onResponse => {
    try {
        let BASE_URL=LOGIN + "?web=" +true;
        axiosInstance.post(BASE_URL, props)
            .then((response) => {
                onResponse(response?.data);
            })
            .catch((err) => {
                console.log("user login error", err);
                
                onResponse(err);
            });

    } catch (error) {

    }

};

export const userLogout = (props) => onResponse => {
    try {
        axiosInstance.post(LOGOUT, props.formData)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err?.response?.data);
            });

    } catch (error) {

    }

};


export const forgotPassword= (props) => onResponse => {
    try {
      
        axiosInstance.post(EMAIL_VERIFICATION , props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
             
                onResponse(err);
            });
  
    } catch (error) {
      
    }
  
  };
  export const verifyotp= (props) => onResponse => {
    try {
      
        axiosInstance.post(OTP_VERIFICATION , props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
             
                onResponse(err);
            });
  
    } catch (error) {
      
    }
  
  };
  export const resetPassword= (props) => onResponse => {
    try {
      
        axiosInstance.post(RESET_PASSWORD , props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
             
                onResponse(err);
            });
  
    } catch (error) {
      
    }
  
  };
  export const changePassword= (props) => onResponse => {
    try {
      
        axiosInstance.post(CHANGE_PASSWORD , props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
             
                onResponse(err);
            });
  
    } catch (error) {
      
    }
  
  };