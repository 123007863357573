import { ADD_INTEREST_TYPE, INTEREST_TYPE_DELETE, INTEREST_TYPE_DETAILS, INTEREST_TYPE_LISTIING, STATUS_CHANGING_INTEREST_TYPE } from "../api";
import axiosInstance from "../axiosinstance";

export const listInterestTyping = (props) => onResponse => {
    try {
        let BASE_URL = INTEREST_TYPE_LISTIING + '?'
        if (props.page) {
            BASE_URL += 'page=' + props.page + '&'

    }
        if (props.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if(props.sort_by){
            BASE_URL+='sort_by='+props.sort_by+'&'
        }
        if(props.order){
            BASE_URL+='order='+props.order+'&'
        }
        if(props.interest_type){
            BASE_URL+='interest_type='+props.interest_type+'&'
        }
        if(props.status){
            BASE_URL+='status='+props.status+'&'
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }

};

export const addInterestType=(props) => onResponse => {
    try {
        axiosInstance.post(ADD_INTEREST_TYPE, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const deleteInterestType = (id) => onResponse => {
    try {

        axiosInstance.delete(INTEREST_TYPE_DELETE, { data: { id: [id] } })

            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};


export const getInterestTypedetails = (props) => onResponse => {
    try {
        axiosInstance.get(INTEREST_TYPE_DETAILS + '?id=' + props.id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const interesttypeStatusChanging=(props) => onResponse => {
    try {
        axiosInstance.post(STATUS_CHANGING_INTEREST_TYPE, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};