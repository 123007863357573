import { Form, Modal } from 'react-bootstrap';
import AsyncSelect from "react-select/async";
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { SPECIAL_SUBSCRIBERS } from "../../constants/pathname";
import { useFormik } from 'formik';
import { addSpecialSubscribeUser } from '../../api/actions/specialUser';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { clubDropdowns } from '../../api/actions/clubProfile';
import * as Yup from 'yup';
import { getClubDetails, listAllClubs } from '../../api/actions/club';
import { IoIosAddCircleOutline } from 'react-icons/io';
import SubscriptionPrivilege from '../Subscription/subscriptionPrivilege';
import AddsubscriptionType from '../SubscriptionType/AddsubscriptionType';
import { checkTheme } from '../../utils/utils';
const CreateSpecialSubscribers = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [club, setClub] = useState('');
    const [country, setCountry] = useState('');
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const toggleModal = () => setShowModal(!showModal);
    const [supplierModal, setSupplierModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const validationSchema = Yup.object({
        club: Yup.string().required('Required'),
        first_name: Yup.string().required('Required'),

        email: Yup.string().required('Required'),


        special_subscriber_type: Yup.string().required('Required'),


    })

    const prefix = [
        { label: 'Mr', value: 'Mr' },
        { label: 'Miss', value: 'Miss' },
        { label: 'Mrs', value: 'Mrs' }
    ]
    const subscribe_types = [
        { label: 'MSR With LG', value: 2 },
        { label: 'MSR Without LG', value: 1 },
        { label: 'Long Term ', value: 3 },
        { label: 'GP Member', value: 1 }

    ]
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            outline: 'none',
            boxShadow: 'none',
            color: 'var(--medium-gray)',
            fontSize: '15px',
            '&:hover': {
                border: '1px solid var(--light-grayish)', // Prevent hover border color
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        menu: (provided) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            boxShadow: 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--light-grayish)' : 'white',
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
    };
    const handleChange = (e, name, selected_name) => {
        if (e) {
            formik.setValues({
                ...formik.values,
                [name]: e.value,
                [selected_name]: e,

            })
        } else {
            formik.setValues({
                [name]: null,
                [selected_name]: [],
            })
        }
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            club: '',
            selectedClub: [],
            prefix_type: '',
            select_prefixtype: [],
            special_subscriber_type: '',
            select_specialsubscriberType: [],
            first_name: '',
            last_name: '',
            email: '',
            country: "",

        },
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            const payload = {
                ...values,
                country: values.country,
            }
            setLoading(true);
            addSpecialSubscribeUser(payload)((response) => {
                if (response.status_code === 200 || response.status_code === 201) {
                    toast.success('Successfully added special subscriber!')
                    resetForm();
                    navigate(SPECIAL_SUBSCRIBERS);
                }
                else {
                    toast.error(response?.response?.data?.message)
                }
                setLoading(false);
            })
           
        }
    })
    let typingTimer;
    const loadOptionsClub = inputValue => {
        return new Promise((resolve, reject) => {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {
                if (inputValue.length > 0) {
                    listAllClubs({ page: 1, limit: 10, search: inputValue })(response => {
                        if (response?.status) {

                            resolve(response?.data?.results?.map(item => ({
                                value: item.club,
                                label: item.club_name,
                                country: item.country
                            })));
                            setClub(response?.data?.results)

                        }
                    })
                }
                else {
                    resolve([])
                }
            }, 300);

        })
    };
    useEffect(() => {
        listAllClubs({
            page,
            limit: limit
        })((res) => {
            if (res?.status) {
                setClub(res?.data.results?.map(item => ({
                    value: item.club,
                    label: item.club_name,
                    country: item.country
                })));
            }
        })
    }, [])



    return (
        <div className='create-clubs-info-section create-special-subscribers'>
              <Modal show={showModal} onHide={handleClose} size="lg" className="custom-modal">
                            <Modal.Header closeButton >
                                <Modal.Title>Add Subscription Type</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <AddsubscriptionType
                                    modal={true}
                                    backdrop="static"
                                    setShowModal={setShowModal}
                                    setRefresh={() => { }}
                                    refresh={false}
                                />
                            </Modal.Body>
                        </Modal>
            <div className='page-details'>
                <div className='panel-type-1'>
                    Add Member
                </div>
                <Form onSubmit={formik.handleSubmit}>

                    <div className='mt-5'>
                        <div className="row">
                            {/* Club */}
                            <div className='col-xl-3 col-md-4 mb-4'>
                                <Form.Group controlId="club">
                                    <Form.Label>Club<span className='required'>*</span></Form.Label>
                                    <AsyncSelect
                                        loadOptions={loadOptionsClub}
                                        defaultOptions={club}
                                        classNamePrefix="select"
                                        isClearable={true}
                                        placeholder={'Select Club'}
                                        onChange={(selectedOption) => {
                                            console.log(selectedOption, "||||SELE")
                                            formik.setFieldValue("club", selectedOption ? selectedOption.value : "");
                                            formik.setFieldValue("country", selectedOption ? selectedOption.country : "");
                                            formik.setFieldValue("selectedClub", selectedOption);
                                            formik.setFieldValue("club_name", selectedOption?.label || "");
                                        }}
                                        value={formik.values?.selectedClub}
                                        styles={{
                                            control: (styles, { isFocused }) => ({
                                                ...styles,
                                                maxHeight: '31px',
                                                border: formik.errors.club && formik.touched.club
                                                    ? '1px solid #dc3545'
                                                    : '1px solid var(--light-grayish)',
                                                outline: 'none',
                                                boxShadow: isFocused ? '0 0 0 1px var(--light-grayish)' : 'none',
                                                color: 'var(--medium-gray)',
                                                fontSize: '15px',
                                                '&:hover': {
                                                    border: formik.errors.club && formik.touched.club
                                                        ? '1px solid #dc3545'
                                                        : '1px solid var(--light-grayish)',
                                                },
                                            }),
                                            valueContainer: (styles) => ({
                                                ...styles,
                                                alignItems: 'center',
                                                padding: '3px 8px',
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: 'var(--medium-gray)',
                                                fontSize: '15px',
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                border: '1px solid var(--light-grayish)',
                                                boxShadow: 'none',
                                            }),
                                            placeholder: (styles) => ({
                                                ...styles,
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                color: 'var(--medium-gray)',
                                                fontSize: '15px',
                                            }),
                                        }}
                                    />
                                    {formik.touched.club && formik.errors.club && <span className='required'>{formik.errors.club}</span>}

                                </Form.Group>
                            </div>
                            {/* Prefix */}
                            <div className="col-xl-3 col-md-4 mb-4">
                                <Form.Group controlId="prefix">
                                    <Form.Label>Prefix</Form.Label>
                                    <Select
                                        placeholder="Select"
                                        styles={customStyles}
                                        options={prefix}
                                        isClearable
                                        isSearchable
                                        value={formik.values?.select_prefixtype}
                                        onChange={(e) => handleChange(e, 'prefix_type', 'select_prefixtype')}
                                    />
                                </Form.Group>
                            </div>
                            {/* First Name */}
                            <div className="col-xl-3 col-md-4 mb-4">
                                <Form.Group controlId="firstName">
                                    <Form.Label>First Name<span className='required'>*</span></Form.Label>
                                    <Form.Control type="text" placeholder="First Name" name="first_name"
                                        value={formik?.values?.first_name}
                                        onChange={formik.handleChange}
                                        isInvalid={formik?.touched?.first_name && formik?.errors?.first_name} />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.touched.first_name && formik.errors.first_name}
                                    </Form.Control.Feedback>

                                </Form.Group>

                            </div>
                            {/* Last Name */}
                            <div className="col-xl-3 col-md-4 mb-4">
                                <Form.Group controlId="lastName">
                                    <Form.Label>Last Name<span className='required'>*</span></Form.Label>
                                    <Form.Control type="text" placeholder="Last Name" name="last_name"
                                        value={formik?.values?.last_name}
                                        onChange={formik.handleChange}
                                        isInvalid={formik?.touched?.last_name && formik?.errors?.last_name} />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.last_name}
                                    </Form.Control.Feedback>

                                </Form.Group>
                            </div>
                            {/* Email */}
                            <div className="col-xl-3 col-md-4 mb-4">
                                <Form.Group controlId="email">
                                    <Form.Label>Email<span className='required'>*</span></Form.Label>
                                    <Form.Control type="email" placeholder="Email" name="email"
                                        value={formik?.values?.email}
                                        onChange={formik.handleChange}
                                        isInvalid={formik?.touched?.email && formik?.errors?.email} />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.touched.email && formik.errors.email}
                                    </Form.Control.Feedback>

                                </Form.Group>
                            </div>
                            {/* Special Subscriber Type */}
                            <div className='col-xl-3 col-md-4 mb-4'>
                                <Form.Group controlId="club">
                                    <div className='d-flex justify-content-between'>
                                        <Form.Label>Special Subscriber Type<span className='required'><sup>*</sup></span></Form.Label>
                                        {/* <div className='fw-semibold' onClick={() => {
                                            setSupplierModal(true);
                                            handleShow();
                                        }} style={{ fontSize: "20px", cursor: "pointer" }}><span style={{ paddingLeft: "3px" }}><IoIosAddCircleOutline size={18} title='Add Subscription' /></span></div> */}
                                    </div>
                                    <Select
                                        placeholder="Select"
                                        styles={{
                                            control: (styles, { isFocused }) => ({
                                                ...styles,
                                                maxHeight: '31px',
                                                border: formik.errors.special_subscriber_type && formik.touched.special_subscriber_type
                                                    ? '1px solid #dc3545'
                                                    : '1px solid var(--light-grayish)',
                                                outline: 'none',
                                                boxShadow: isFocused ? '0 0 0 1px var(--light-grayish)' : 'none',
                                                color: 'var(--medium-gray)',
                                                fontSize: '15px',
                                                '&:hover': {
                                                    border: formik.errors.special_subscriber_type && formik.touched.special_subscriber_type
                                                        ? '1px solid #dc3545'
                                                        : '1px solid var(--light-grayish)',
                                                },
                                            }),
                                            valueContainer: (styles) => ({
                                                ...styles,
                                                alignItems: 'center',
                                                padding: '3px 8px',
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: 'var(--medium-gray)',
                                                fontSize: '15px',
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                border: '1px solid var(--light-grayish)',
                                                boxShadow: 'none',
                                            }),
                                            placeholder: (styles) => ({
                                                ...styles,
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                color: 'var(--medium-gray)',
                                                fontSize: '15px',
                                            }),
                                        }}
                                        options={checkTheme() ? subscribe_types.filter((item) => !(['MSR With LG', 'MSR Without LG', 'GP Member'].includes(item.label)) )  : subscribe_types}
                                        isClearable
                                        isSearchable
                                        value={formik.values?.select_specialsubscriberType}
                                        onChange={(e) => handleChange(e, 'special_subscriber_type', 'select_specialsubscriberType')}
                                    />
                                    {formik.touched.special_subscriber_type && formik.errors.special_subscriber_type && <span className='required'>{formik.errors.special_subscriber_type}</span>}

                                </Form.Group>
                            </div>
                        </div>
                        <div className='submit-box d-flex align-items-center justify-content-end gap-3'>
                            <button className='btn-type-2 cursor-pointer' onClick={() => navigate(SPECIAL_SUBSCRIBERS)}>Cancel</button>
                            <button className='btn-type-1' type="submit"  disabled={loading}>Add</button>
                        </div>
                    </div>
                </Form>
            </div>

        </div>
    )
}

export default CreateSpecialSubscribers