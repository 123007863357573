import { MEMBER_STATUS_LISTING } from "../api";
import axiosInstance from "../axiosinstance";

export const listMemberStats = (props) => onResponse => {
    try {
        let BASE_URL = MEMBER_STATUS_LISTING + '?'
        if (props.page) {
            BASE_URL += 'page=' + props.page + '&'

        }
        if (props.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if(props.sort_by){
            BASE_URL+='sort_by='+props.sort_by+'&'
        }
        if(props.order){
            BASE_URL+='order='+props.order+'&'
        }
        if(props.from_date){
            BASE_URL+='from_date='+props.from_date+'&'
        }
        if(props.to_date){
            BASE_URL+='to_date='+props.to_date+'&'
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }

};