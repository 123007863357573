import React, { useEffect, useState } from 'react'
import { LIST_BRAND_CATEGORY } from '../../constants/pathname'
import { useNavigate, useParams } from 'react-router-dom'
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import { addBrandCategory, getBrandCategory } from '../../api/actions/brandCategory';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
const AddBrandCategory = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const [loadings, setLoadings] = useState(false);
  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: ""
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      setLoadings(true);
      addBrandCategory(values)((response) => {
        if (response.status_code === 200 || response.status_code === 201) {
          toast.success(id?"Successfully Updated!":"Successfully Created!")
          navigate(LIST_BRAND_CATEGORY);
          resetForm();
          setLoadings(false);

        } else {
          toast.error(response?.response?.data?.errors[0]);
        }
      })

    }
  })
  useEffect(()=>{
    if(id){
      getBrandCategory({id})((response)=>{
        if(response?.status){
          formik.setValues({
            id:response?.data?.id,
            name:response?.data?.name
          })
        }
      })
    }
  },[])
  return (
    <div className='create-clubs-info-section create-brands'>
      <div className='page-details'>
        <div className='panel-type-1'>
         {id?"Update Brand category":"Add Brand Category"}
        </div>
        <Form onSubmit={formik.handleSubmit}>
          <div className='mt-5'>
            <div className="row">

              <div className="col-xl-3 col-md-4 mb-3">
                <Form.Group controlId="clubName">
                  <Form.Label>Brand Category Name<span className='required'>*</span></Form.Label>
                  <Form.Control type="text" placeholder="Club Name" name="clubName"
                    value={formik.values.name}
                    onChange={(e) => {
                      const value = e.target.value;
                      formik.setFieldValue(
                        'name',
                        value.charAt(0).toUpperCase() + value.slice(1)
                      );
                    }}
                    isInvalid={formik.touched.name && formik.errors.name} />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

            </div>
            <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-5'>
              <button className='btn-type-2 cursor-pointer' onClick={() => navigate(LIST_BRAND_CATEGORY)}>Cancel</button>
              <button className='btn-type-1' type="submit" >{id? "Update":"Submit"}</button>
            </div>
          </div>
        </Form>
      </div>

    </div>
  )
}

export default AddBrandCategory
