
import React, { useEffect, useState } from 'react'
import DataTable from "react-data-table-component";
import { ReactComponent as EditIcon } from "../../assets/images/common/edit-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/common/close-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/common/add-icon.svg";
import { useNavigate } from 'react-router-dom';
import { ADD_ROLE, EDIT_ROLE } from '../../constants/pathname';
import SweetAlertModal from '../SweetAlert/SweetAlertModal';
import { destroyRole, retriveRoles } from '../../api/actions/usermanagement';
import toast from 'react-hot-toast';
import { FaTimes } from 'react-icons/fa';
import { paginationRowsPerPageOptions } from '../../helpers/helper';

const Roles = () => {

    const [showSweetAlert, setShowSweetAlert] = useState(false);
    const [data, setData] = useState([])
    const [pending, setPending] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [update, setUpdate] = useState(false);
    const [isSwal, setIsSwal] = useState({ show: false, id: '' });
    // Search
    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState('');
    
    // Pagination
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [totalRows, setTotalRows] = useState(0);
    
    // Permissions
    const [permissionsList, setPermissionsList] = useState([]);
    // User information
    const [userInfo, setUserInfo]=useState(null);  
    // Setting permission list
    useEffect(() => {
        let userInfoObj = JSON.parse(localStorage.getItem("user_info"));
        setUserInfo(userInfoObj);
        if(userInfoObj && userInfoObj?.permissions && userInfoObj?.permissions?.length){
            setPermissionsList(userInfoObj?.permissions);
        }

    }, []);

    const handleSearch = (value) => {
        setSearch(value);
    }

    const clearSearch = () => {
        setSearch('');
        setDebouncedSearch('');
        setUpdate(!update);
    };

    useEffect(() => {
        if (search.length >= 2) {
          const debounceTimer = setTimeout(() => {
            setDebouncedSearch(search);
          }, 500);
    
          return () => clearTimeout(debounceTimer);
        } else {
          setDebouncedSearch('');
        }
    }, [search]);

    const navigate = useNavigate();        

    useEffect(() => {
        setPending(true);
        if (debouncedSearch) {
            setPage(1);
        }
        retriveRoles({
            page: debouncedSearch ? 1 : page,
            limit,
            search: debouncedSearch,
        })((res) => {
            if (res?.status_code == 200 || res?.status_code == 201) {
                setData(res?.data?.results?.data);
                setTotalRows(res?.data?.count);
            }
            setPending(false);
        })
    }, [page, update, limit, debouncedSearch])


    // const handleDelete=(id) => {
    //     if(isDelete) return
    //     setIsDelete(true);
    //     destroyRole(id)((res)=>{
    //         if(res?.status_code === 200 || res?.status_code === 201){
    //             toast.success('Successfully deleted');
    //             setUpdate(!update);
    //         } else{
    //             toast.error("Something Went Wrong")
    //         }
    //         setIsDelete(false);
    //         setIsSwal({ show: false, id: null });

    //     })
    // }

    const handleDelete = (id, roleName) => {
        if (isDelete) return;
        setIsSwal({ show: true, id: id, roleName:  roleName});
    }

    const confirmDelete = (id) => {
        setIsDelete(true);
        destroyRole(id)((res) => {
            if (res?.status_code === 200 || res?.status_code === 201) {
                toast.success('Successfully deleted');
                setUpdate(!update);
            } else {
                toast.error("Something Went Wrong")
            }
            setIsDelete(false);
            setIsSwal({ show: false, id: null });
        })
    }

    // Define columns for the table
    const columns = [
        { name: "Sl.No", 
          selector: row => row?.name,
          cell: (row, index) =>
            <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.name}>
              {(limit * (page - 1)) + (index + 1)}
            </div>
        , width: "180px"},
        { name: "Role", selector: row => row.name, minWidth: "200px", sortable: true },
        {
            name: "Actions",
            selector: row =>
                <div className='d-flex align-items-center gap-3'>
                    {/* <div className='view-icon cursor-pointer' title='View'><ViewIcon style={{ marginTop: "-1px" }} /></div> */}
                        <div className='view-icon cursor-pointer' title="Edit" onClick={() => navigate(`${EDIT_ROLE}/${row?.id}`)}>
                            <EditIcon />
                        </div>
                        <div className='view-icon cursor-pointer' title='Delete' onClick={() => handleDelete(row?.id, row?.name)}>
                            <CloseIcon style={{ marginLeft: "2px" }} />
                        </div>
                </div>,
            width: "180px"
        },
    ];

    // Custom styling for the table
    const customStyles = {
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",
                border: "1px solid var(--border-color)",
                overflow: "hidden"
            },
        },
        headCells: {
            style: {
                backgroundColor: "var(--light-blue)",
                justifyContent: 'flex-start !important',
                color: "var(--dark-gray)",
                fontWeight: "400",
                fontSize: "15px",
            },
        },
        rows: {
            style: {
                height: "45px", // Set row height
                justifyContent: "start",
            },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    };

    return (
        <div className='member-stats-section'>
            <div className='page-details'>
                <div className='top-section d-flex align-items-center justify-content-between'>
                    <div className='left'>Roles</div>
                        <div className='right d-flex align-items-center  gap-3'>
                            <div className='search-box'>
                            <input
                                type='text'
                                placeholder='Search'
                                className='form-control'
                                value={search}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                            {
                                search && (
                                <FaTimes className='clear-icon' onClick={clearSearch} />
                                )}
                            </div>
                                <div className='add-clubs-info cursor-pointer' onClick={() => navigate(ADD_ROLE, { state: { currentPageName: "Add or Edit Role" } })} title="Create">
                                    <AddIcon />
                                </div>
                        </div>
                </div> 
                <div className='table-box mt-3'>
                    <DataTable
                        columns={columns}
                        data={data}
                        customStyles={customStyles}
                        fixedHeader
                        highlightOnHover
                        responsive
                        onChangePage={(page) => { setPage(page); setUpdate(!update) }}
                        onChangeRowsPerPage={(perPage) => { setLimit(perPage); setUpdate(!update) }}
                        pagination
                        paginationServer
                        paginationPerPage={limit}
                        progressPending={pending}
                        // onChangeRowsPerPage={setLimit}
                        paginationTotalRows={totalRows}
                        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                        // onChangePage={setPage}
                    />
                </div>
                <SweetAlertModal
                    showSweetAlert={isSwal.show}
                    setShowSweetAlert={(confirmed) => {
                        if (confirmed) {
                            confirmDelete(isSwal.id);
                        } else {
                            setIsSwal({ show: false, id: null });
                        }
                    }}
                    memberName={isSwal.roleName || ""}
                />
            </div>
        </div>
    )
}

export default Roles;