import { CITY_DROPDOWN_URL } from "../api";
import axiosInstance from "../axiosinstance";

export const cityListDropdown = (props) => onResponse => {


    let BASE_URL = `${CITY_DROPDOWN_URL}?`
    if (props?.page) {
        BASE_URL += `page=${props?.page}&`
    }
    if (props?.limit) {
        BASE_URL += `limit=${props?.limit}&`
    }
    if (props?.search) {
        BASE_URL += `search_param=${props?.search}&`
    }
    if (props?.city_country) {
        BASE_URL += `city_country=${props?.city_country}&`
    }
    try {
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
          
                onResponse(err?.response?.data);
            });

    } catch (error) {

    }

};