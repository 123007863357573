import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, } from 'react-router-dom'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { FaLongArrowAltLeft } from 'react-icons/fa'
import OtpInput from 'otp-input-react';
import { LOGIN, RESET_PASSWORD } from '../../constants/pathname'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { forgotPassword, verifyotp } from '../../api/actions/auth';
import toast from 'react-hot-toast';
import LoginHeader from "../../common/layout/LoginHeader";

const VerfiyEmailPage=() => {
    const [showPass, setShowPass]=useState(false)
    const [show, setShow]=useState(false)
    const [lopader, setLopader]=useState(false)
    const navigate=useNavigate()
    const { state }=useLocation()
    const debounceTimer = useRef(null);


    const formik=useFormik({
        initialValues: {
            email: state?.email,
            otp: ""
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            otp: Yup.string().required('Required')

        }),
        onSubmit: (values) => {
            setShow(true)
            verifyotp(values)((res)=>{
           
                const token = res.data?.token
                const uid=res?.data?.uid
                if(res?.status_code==200){
                    setShow(false)
           
                    formik.resetForm()
                    navigate(RESET_PASSWORD,{state:{uid,token}})
                    
                }
                else{
                    setShow(false)
                    formik.setErrors({
                        ...formik?.values,
                        otp:res?.response?.data?.errors?.non_field_errors[0]
                    })
                }
                
            })
        
        }
    })

    const handleotp=(e) => {

        formik.setValues({
            ...formik?.values,
            otp: e
        })

    }
    const resendotp = () => {
        setLopader(true)
        forgotPassword({
          user_email: state?.email 
        })((response) => {
            if (response?.status) {
                setLopader(false)
                toast.success(response?.message)
            }
            else {
                setLopader(false)
                toast.error(response?.message)
            }
        })
    }
    const onClickHandler = () => {
        // Clear the timer if it exists
        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
        }

        // Set a new timer for 5 seconds
        debounceTimer.current = setTimeout(() => {
            resendotp();
        }, 700);
    };

    useEffect(() => {

        const timeout=setTimeout(() => {
            setShowPass(true)
        }, 5000);

        return () => clearTimeout(timeout);


    }, []);

    return (
        <section className="login">
            <LoginHeader />
            <section className='verfiy-page'>
                <Row style={{ width: "100%" }}>
                    <Col lg={6}> </Col>
                    <Col lg={6}>

                        <div className='formd-section magh'>

                            <div className='formd-main'>
                                <div className='heading heak'>
                                    <h1 className='haed'>Verify your email</h1>
                                    <p className='sub-head sub'>Please enter the 4 digit code sent to your email</p>
                                </div>
                                <div className='form-content'>
                                    <Form onSubmit={formik.handleSubmit} >
                                        <div className='otp-main'>
                                            <h6 className='otp-mao'>OTP</h6>
                                            <OtpInput
                                                value={formik?.values?.otp}
                                                // onChange={setOtp}
                                                OTPLength={4}
                                                otpType="otp"
                                                disabled={false}
                                                onChange={(e) => handleotp(e)}
                                                className="otp-input"

                                            />

                                            {formik?.touched?.otp&&formik?.errors?.otp? <p className='required mt-2' style={{color: "var(--red)", fontWeight: "300", fontSize: "12px"}}> {formik?.errors.otp}   </p>:""}
                                        </div>

                                        <div className='sign'>
                                            <Button className='sign-button' disabled={show} type='submit' >Verify</Button>
                                        </div>
                                    </Form>
                                    {showPass?
                                        <div className='Active'>
                                            <button className='backto b1' disabled={lopader}  onClick={() => onClickHandler()} >Resend Code</button>
                                        </div>:<></>
                                    }

                                    <div className='Active'>
                                        <button onClick={() => navigate(LOGIN)} className='backto'> <span > <FaLongArrowAltLeft size={15} className='left' /></span>Back to login</button>
                                    </div>

                                </div>

                            </div>


                        </div>
                    </Col>
                </Row>


            </section>
        </section>
    )
}

export default VerfiyEmailPage
