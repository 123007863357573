import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { INTERESTS } from '../../constants/pathname';
import { MdOutlineDelete } from 'react-icons/md';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import { addInterest, getInterestdetails } from '../../api/actions/interest';
import * as Yup from 'yup';
import imageToBase64 from 'image-to-base64/browser';
import { checkTheme } from '../../utils/utils';

const CreateInterest = () => {
    const navigate = useNavigate();
    const [photo, setPhoto] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { id } = useParams();
    const isNightMode = checkTheme(); // true for PCN (night), false for PCW (day)

    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required'),
        // Require either pcn_image or pcw_image based on current theme
        ...(isNightMode ? { pcn_image: Yup.string().required('Image is required') } : { pcw_image: Yup.string().required('Image is required') })
    });

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
            const maxSize = 5 * 1024 * 1024; // 5MB

            if (!allowedTypes.includes(file.type)) {
                toast.error('Please upload a valid image (JPEG, PNG, or GIF)');
                return;
            }

            if (file.size > maxSize) {
                toast.error('File size should not exceed 5MB');
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setPhoto(base64String);
                
                // Set the image for the current theme mode
                if (isNightMode) {
                    formik.setFieldValue('pcn_image', base64String);
                } else {
                    formik.setFieldValue('pcw_image', base64String);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const removeImage = () => {
        setPhoto(null);
        document.getElementById('themeImageInput').value = '';
        
        // Clear the image for the current theme mode
        if (isNightMode) {
            formik.setFieldValue('pcn_image', '');
        } else {
            formik.setFieldValue('pcw_image', '');
        }
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            pcn_image: '',
            pcw_image: '',
            publish: false,
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (isSubmitting) return;
            setIsSubmitting(true);

            const updatedValues = { ...values, publish: values.publish };

            addInterest(updatedValues)((response) => {
                setIsSubmitting(false);

                if (response.status_code === 200 || response.status_code === 201) {
                    toast.success(id ? "Successfully Updated!" : "Successfully Created!");
                    resetForm();
                    navigate(INTERESTS);
                } else {
                    formik.setErrors({
                        ...formik.errors,
                        name: response?.response?.data?.errors?.non_field_errors?.[0] || '',
                    });
                }
            });
        },
    });

    useEffect(() => {
        if (id) {
            getInterestdetails({ id })(async (response) => {
                if (response?.status) {
                    const pcw_image = response?.data?.pcw_image 
                        ? "data:image/png;base64," + await imageToBase64(response?.data?.pcw_image) 
                        : "";
                    const pcn_image = response?.data?.pcn_image 
                        ? "data:image/png;base64," + await imageToBase64(response?.data?.pcn_image) 
                        : "";
                    
                    formik.setValues({
                        ...formik.values,
                        id: response?.data?.id,
                        name: response?.data?.name,
                        pcw_image: pcw_image,
                        pcn_image: pcn_image,
                        publish: response?.data?.publish || false,
                    });
                    
                    // Set the photo based on current theme
                    if (isNightMode && pcn_image) {
                        setPhoto(pcn_image);
                    } else if (!isNightMode && pcw_image) {
                        setPhoto(pcw_image);
                    }
                }
            });
        }
    }, [id]);

    return (
        <div className='create-clubs-info-section create-interest-section'>
            <div className='page-details'>
                <div className='panel-type-1'>
                    {id ? "Update Interests" : "Add New Interests"}
                </div>
                <Form onSubmit={formik.handleSubmit}>
                    <div className='mt-5'>
                        <div className="row ">
                            {/* Interests Name */}
                            <div className="col-md-6 mb-3">
                                <Form.Group controlId="interestName">
                                    <Form.Label> Name<span className='required'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Name"
                                        name='name'
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            formik.setFieldValue(
                                                'name',
                                                value.charAt(0).toUpperCase() + value.slice(1)
                                            );
                                        }}
                                        value={formik.values.name}
                                        isInvalid={formik.touched.name && formik.errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>

                            {/* Theme Responsive Image Upload */}
                            <div className='col-md-6 mb-4'>
                                <Form.Group className="mb-3" controlId="themeImage">
                                    <Form.Label>
                                         Image 
                                        <span className='required'>*</span>
                                    </Form.Label>
                                    <div className='input-border'>
                                        <Form.Control
                                            type="file"
                                            id="themeImageInput"
                                            className='input-field'
                                            onChange={handleImageChange}
                                        />
                                        {isNightMode && formik.touched.pcn_image && formik.errors.pcn_image && (
                                            <div className="text-danger mt-1">{formik.errors.pcn_image}</div>
                                        )}
                                        {!isNightMode && formik.touched.pcw_image && formik.errors.pcw_image && (
                                            <div className="text-danger mt-1">{formik.errors.pcw_image}</div>
                                        )}
                                        {photo && (
                                            <div className="mt-2 position-relative">
                                                <img
                                                    src={photo}
                                                    alt="Uploaded"
                                                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                                />
                                                <MdOutlineDelete
                                                    color='red'
                                                    style={{ fontSize: '20px', cursor: 'pointer', marginTop: "80px" }}
                                                    onClick={removeImage}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Form.Group>
                            </div>
                        </div>

                        <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-5'>
                            <div className='btn-type-2 cursor-pointer' onClick={() => navigate(INTERESTS)}>Cancel</div>
                            <button className='btn-type-5' type="submit">{id ? "Update" : "Save"}</button>
                            {id ? (
                                <button
                                    className='btn-type-1 publish-btn'
                                    type="button"
                                    disabled={formik.values.publish || isSubmitting}
                                    onClick={() => {
                                        formik.setFieldValue('publish', true, false);
                                        formik.handleSubmit();
                                    }}
                                >
                                    {formik.values.publish ? "Published" : isSubmitting ? "Publishing..." : "Publish"}
                                </button>
                            ) : null}
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default CreateInterest;