import { Form } from 'react-bootstrap'
import { ReactComponent as UploadIcon } from "../../assets/images/common/cloud-upload-alt.svg";
import imageToBase64 from 'image-to-base64/browser';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import { LETTER_OF_INTRODUCTION } from '../../constants/pathname';
import { useNavigate, useParams } from 'react-router-dom';
import { RiDeleteBin6Line } from 'react-icons/ri';

import { use, useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
    ClassicEditor,
    Autoformat,
    Autosave,
    Bold,
    Essentials,
    Heading,
    Italic,
    Paragraph,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    Undo,
    List,
    Alignment
} from "ckeditor5";
import { addLetterofIntroduction, getletterofIntroduction } from '../../api/actions/letterofIntroduction';
import { VIEWPDF } from '../../api/api';
const CreateLetterOfIntroduction = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    // For Ck Editor
    const editorContainerRef = useRef(null);
    const editorRef = useRef(null);
    const [isLayoutReady, setIsLayoutReady] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [existingData, setExistingData] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Editor configuration
    const editorConfig = {
        toolbar: {
            items: [
                "undo",
                "redo",
                "|",
                "|",
                "heading",
                "|",
                "bold",
                "italic",
                "bulletedList",
                "numberedList",
                "|",
                "alignment"
            ],
            shouldNotGroupWhenFull: false,
        },
        alignment: {
            options: ['left', 'right', 'center', 'justify']
        },
        plugins: [
            Autoformat,
            Autosave,
            Bold,
            Essentials,
            Heading,
            Italic,
            Paragraph,
            List,
            TextTransformation,
            Table,
            TableCaption,
            TableCellProperties,
            TableColumnResize,
            TableProperties,
            TableToolbar,
            Alignment,
            Undo,
        ],
        heading: {
            options: [
                {
                    model: "paragraph",
                    title: "Paragraph",
                    class: "ck-heading_paragraph",
                },
                {
                    model: "heading1",
                    view: "h1",
                    title: "Heading 1",
                    class: "ck-heading_heading1",
                },
                {
                    model: "heading2",
                    view: "h2",
                    title: "Heading 2",
                    class: "ck-heading_heading2",
                },
                {
                    model: "heading3",
                    view: "h3",
                    title: "Heading 3",
                    class: "ck-heading_heading3",
                },
                {
                    model: "heading4",
                    view: "h4",
                    title: "Heading 4",
                    class: "ck-heading_heading4",
                },
                {
                    model: "heading5",
                    view: "h5",
                    title: "Heading 5",
                    class: "ck-heading_heading5",
                },
                {
                    model: "heading6",
                    view: "h6",
                    title: "Heading 6",
                    class: "ck-heading_heading6",
                },
            ],
        },

        initialData: "",
        menuBar: {
            isVisible: true,
        },
        placeholder: "Type or paste your content here!",
    };

    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    const letterOfIntroductionId = userInfo?.loi?.[0]?.letter_of_introduction_id;
    const clubId = userInfo?.loi?.[0]?.club_id;
    const countryId = userInfo?.club_details?.[0]?.country;



    // Setting layout for Ckeditor
    useEffect(() => {
        setIsLayoutReady(true);

        return () => setIsLayoutReady(false);
    }, []);



    const formik = useFormik({
        initialValues: {
            logo: '',
            letter_description: '',
            member_name: '',
            designation: '',
            address: '',
            signature_file: '',
        },
        onSubmit: (values, { resetForm }) => {
            if (isSubmitting) return;

            setIsSubmitting(true);

            addLetterofIntroduction(values)((response) => {
                if (response.status_code === 200 || response.status_code === 201) {
                    toast.success(id ? "Updated Successfully" : "Created Successfully", { id: "single-toast" });
                } else {
                    toast.error("Failed to Create Letter of Introduction!", { id: "single-toast" });
                }
                setIsSubmitting(false);
            });
        },
    });

    useEffect(() => {
        if (letterOfIntroductionId && clubId) {

            getletterofIntroduction(letterOfIntroductionId, clubId)((response) => {
                if (response?.status && response?.data) {
                    formik.setValues({
                        ...formik.initialValues,
                        ...response.data,
                    });
                }
            });
        }
    }, [letterOfIntroductionId, clubId]);
    const handleImageChange = async (e) => {
        const file = e?.target?.files[0]; // Get the first file
        const maxSizeInBytes = 1 * 1024 * 1024;
        const validImageTypes = ['image/jpeg', 'image/png'];

        if (!file) return;

        if (!validImageTypes.includes(file?.type)) {
            toast.error("Only jpg/jpeg and png files are allowed!");
            e.target.value = null;
            return;
        }

        if (file?.size > maxSizeInBytes) {
            toast.error("File size exceeds 1 MB limit");
            e.target.value = null;
            return;
        }

        try {
            const response = await imageToBase64(URL.createObjectURL(file));
            formik.setValues({
                ...formik.values,
                logo: `data:image/jpeg;base64,${response}`,
                thumbnailName: file.name
            });
        } catch (error) {
            toast.error("Failed to upload image.");
        }

        e.target.value = null;
    };

    const handleSignatureChange = async (e) => {
        const file = e?.target?.files[0];
        const maxSizeInBytes = 1 * 1024 * 1024;
        const validImageTypes = ['image/jpeg', 'image/png'];

        if (!file) return;

        if (!validImageTypes.includes(file?.type)) {
            toast.error("Only jpg/jpeg and png files are allowed!");
            e.target.value = null;
            return;
        }

        if (file?.size > maxSizeInBytes) {
            toast.error("File size exceeds 1 MB limit");
            e.target.value = null;
            return;
        }

        try {
            const response = await imageToBase64(URL.createObjectURL(file));
            formik.setValues({
                ...formik.values,
                signature_file: `data:image/jpeg;base64,${response}`,
                signature: file.name
            });
        } catch (error) {
            toast.error("Failed to upload signature.");
        }

        e.target.value = null;
    };


    const removeThumbNail = () => {
        formik.setValues({
            ...formik.values,
            logo: "",
            thumbnailName: ""
        });
    };

    const removeSignature = () => {
        formik.setValues({
            ...formik.values,
            signature_file: "",
            signature: ""
        });
    };
    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        formik.setFieldValue('letter_description', data);
    };

    const handleExportMemberList = () => {
        const myHeaders = new Headers();
        let token = localStorage.getItem('token');

        myHeaders.append(`Authorization`, `Bearer ${token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BACKENDURL}/${VIEWPDF}?country=${countryId}&letter_id=${letterOfIntroductionId}`, requestOptions)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");

                a.href = url;
                a.download = "Letter of Introduction.pdf";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => console.error(error));
    };
    return (
        <div className='create-clubs-info-section'>
            <div className='page-details'>
                <div className='club-profile-section'>
                    <div className='panel-type-1'>
                        Letter of Introduction
                    </div>
                    <Form onSubmit={formik.handleSubmit}>
                        <div className='club-form mt-5'>
                        <div className='item upload'>
                                <label>Upload Logo <span className='required'>*</span></label>
                                <div className='d-flex align-items-end mt-2'>
                                    <div>
                                        {!formik.values?.logo ? (
                                            <label className='upload-icon cursor-pointer' htmlFor='fileInput'>
                                                <UploadIcon />
                                                <div className='mt-3'>Upload</div>
                                            </label>
                                        ) : (
                                            <div className='uploaded-images active'>
                                                <div className='pos-rel'>
                                                    <img src={formik.values.logo} alt="Uploaded Logo" className="uploaded-image" />
                                                </div>
                                            </div>
                                        )}
                                        <div className='filename mt-2 d-flex align-items-center justify-content-between gap-2'>
                                            <div className='name' title={formik?.values?.thumbnailName}>{formik?.values?.thumbnailName}</div>
                                            {formik.values?.logo && (
                                                <RiDeleteBin6Line size={16} fill='var(--red)' title='Delete' onClick={removeThumbNail} />
                                            )}
                                        </div>
                                        {!formik.values?.logo && (
                                            <div className='info'>
                                                Use JPEG or PNG.<br />Best size 1280×720 pixels. Keep it under 1MB
                                            </div>
                                        )}
                                    </div>
                                    <input
                                        id="fileInput"
                                        className='d-none'
                                        type="file"
                                        onChange={handleImageChange}
                                    />
                                </div>
                            </div>
                            <div className='mt-5'>
                                <div className="row">

                                    {/* Letter Description */}
                                    <div className="col-md-12 mb-3">
                                        <Form.Group controlId="letter-description">
                                            <Form.Label>Letter Description<span className='required'>*</span></Form.Label>
                                            <div
                                                className="editor-container editor-container_classic-editor mt-2"
                                                ref={editorContainerRef}
                                            >
                                                {/* CK Editor for Document */}
                                                <div className="editor-container__editor">
                                                    <div ref={editorRef} id="#editor">
                                                        {isLayoutReady && (
                                                            <CKEditor
                                                                data={formik.values?.letter_description}
                                                                editor={ClassicEditor}
                                                                config={editorConfig}
                                                                onReady={(editor) =>
                                                                    (editorRef.current = editor)
                                                                }
                                                                onChange={handleEditorChange}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>


                                </div>
                            </div>
                            <div className='row mt-4'>
                            <div className='item upload mb-3'>
                                <label>Signature <span className='required'>*</span></label>
                                <div className='d-flex align-items-end mt-2'>
                                    <div>
                                        {!formik.values?.signature_file ? (
                                            <label className='upload-icon cursor-pointer' htmlFor='fileInputSignature'>
                                                <UploadIcon />
                                                <div className='mt-3'>Upload</div>
                                            </label>
                                        ) : (
                                            <div className='uploaded-images active'>
                                                <div className='pos-rel'>
                                                    <img src={formik.values.signature_file} alt="Uploaded Signature" className="uploaded-image" />
                                                </div>
                                            </div>
                                        )}
                                        <div className='filename mt-2 d-flex align-items-center justify-content-between gap-2'>
                                            <div className='name' title={formik?.values?.signature || ""}>{formik?.values?.signature || ""}</div>
                                            {formik.values?.signature_file && (
                                                <RiDeleteBin6Line size={16} fill='var(--red)' title='Delete' onClick={removeSignature} />
                                            )}
                                        </div>
                                        {!formik.values?.signature_file && (
                                            <div className='info'>
                                                Use JPEG or PNG.<br />Best size 1280×720 pixels. Keep it under 1MB
                                            </div>
                                        )}
                                    </div>
                                    <input
                                        id="fileInputSignature"
                                        className='d-none'
                                        type="file"
                                        onChange={handleSignatureChange}
                                    />
                                </div>
                            </div>   
                            <div className="col-md-3 mb-3">
                                        <Form.Group controlId="name">
                                            <Form.Label>Name<span className='required'>*</span></Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='member_name'
                                                placeholder='Enter name'
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    formik.setFieldValue(
                                                        'member_name',
                                                        value.charAt(0).toUpperCase() + value.slice(1)
                                                    );
                                                }}
                                                isInvalid={formik.touched.member_name && formik.errors.member_name}
                                                value={formik.values.member_name}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.member_name}
                                            </Form.Control.Feedback>

                                        </Form.Group>
                                    </div>
                                    {/* Designation */}
                                    <div className="col-md-3 mb-3">
                                        <Form.Group controlId="designation">
                                            <Form.Label>Designation<span className='required'>*</span></Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='designation'
                                                placeholder='Enter Designation'
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    formik.setFieldValue(
                                                        'designation',
                                                        value.charAt(0).toUpperCase() + value.slice(1)
                                                    );
                                                }}
                                                isInvalid={formik.touched.designation && formik.errors.designation}
                                                value={formik.values.designation}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.designation}
                                            </Form.Control.Feedback>

                                        </Form.Group>
                                    </div>
                                    {/* Address */}
                                    <div className="col-md-6 mb-3">
                                        <Form.Group controlId="address">
                                            <Form.Label>Address<span className='required'>*</span></Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                name='address'
                                                placeholder='Enter address'
                                                rows={5}
                                                isInvalid={formik.touched.address && formik.errors.address}
                                                value={formik.values.address}
                                                onChange={formik.handleChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formik.errors.address}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                            </div>
                            

                        </div>

                        <div className='submit-box d-flex align-items-center justify-content-end gap-3'>

                            <button className='btn-type-1' type="submit" disabled={isSubmitting}>
                                {isSubmitting ? "Submitting..." : "Submit"}
                            </button>

                            <button className='btn-type-5' type="button" onClick={handleExportMemberList}>View PDF</button>

                        </div>
                    </Form>

                </div>
            </div>

        </div>
    )
}

export default CreateLetterOfIntroduction