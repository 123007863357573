import React from 'react'
import { Container, Card } from "react-bootstrap";
import { checkTheme } from '../../utils/utils';

const OverView=() => {
    return (
        <div className='manage-members-page cur'>
            <div className='manage-members member-stats-section'>
                <div className='page-details'>
                    <div className='top-section'>
                        <div className='left'>
                            Overview
                        </div>
                    </div>
                    <Container className="mt-4">
                        {checkTheme() 
                            ? <Card className="border-0">
                                <Card.Body>
                                    <p>
                                        The Global Platinum Club Access Program offers Members of the Club the opportunity to request access to other 
                                        Platinum Clubs of America, Platinum Clubs of Europe, Platinum Clubs of Asia-Pacific, and Platinum Clubs of the 
                                        World (a “Platinum Club”) subject to the acceptance of such a request by the receiving club, at its sole discretion.
                                    </p>

                                    <h5 className="mt-3" style={{ fontWeight: 500 }}>Inclusions:</h5>
                                    <ul className="mt-2" style={{ listStyle: "auto", paddingLeft: "40px" }}>
                                        <li>PlatinumClubNet™ CMS</li>
                                        <li>PlatinumClubNet™ Mobile App / Web Portal for Members</li>
                                        <li>PlatinumClubNet™ Staff Function App for Club Employees</li>
                                        <li>Customer support through a 24×7 Concierge service</li>
                                    </ul>

                                    <h5 className="mt-3" style={{ fontWeight: 500 }}>Other Terms:</h5>
                                    <ul className="mt-2" style={{ listStyle: "auto", paddingLeft: "40px" }}>
                                        <li>
                                            All visiting member transactions on PlatinumClubNet associated with access to the Club can be settled directly 
                                            by the club with the visiting member or can be routed through MobiCom if the Club would prefer to do so. 
                                            MobiCom will settle the payments to the Club after collecting the same from the member.
                                        </li>
                                        <li>
                                            For providing this seamless service through the Licensed Software, MobiCom would not charge any fee on Clubs. 
                                            MobiCom will also not charge any convenience or transaction fee to the visiting Member, other than recovering 
                                            the actual bank charges associated with card transaction processing.
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                            : <Card className="border-0">
                                <Card.Body>
                                    <p>
                                        The Global Private Club Access Program offers Members of the Club the opportunity to request access to other Private Clubs
                                        subject to the acceptance of such a request by the receiving club, at its sole discretion. In addition, Members of the Club will be able to:
                                    </p>
                                    <ul className="mt-2" style={{ listStyle: "auto", paddingLeft: "40px" }}>
                                        <li>Network on the world’s most influential network of Private Clubs, that is discreet and managed by member preferences.</li>
                                        <li>Access to luxury lifestyle and travel experiences combining access to the world’s best venues, events, and brands.</li>
                                    </ul>

                                    <h5 className="mt-3" style={{ fontWeight: 500 }}>Inclusions:</h5>
                                    <ul className="mt-2" style={{ listStyle: "auto", paddingLeft: "40px" }}>
                                        <li>PrivateClubWorld™ CMS</li>
                                        <li>PrivateClubWorld™ Mobile App / Web Portal for Members</li>
                                        <li>PrivateClubWorld™ Admin App for Club Employees</li>
                                        <li>Customer support through a 24×7 Concierge service</li>
                                    </ul>

                                    <h5 className="mt-3" style={{ fontWeight: 500 }}>Other Terms:</h5>
                                    <ul className="mt-2" style={{ listStyle: "auto", paddingLeft: "40px" }}>
                                        <li>
                                            All visiting member transactions on PrivateClubWorld associated with access to the Club can be settled directly by the club with
                                            the visiting member or can be routed through MobiCom if the Club would prefer to do so. MobiCom will settle the payments to the Club
                                            after collecting the same from the member.
                                        </li>
                                        <li>
                                            For providing this seamless service through the Licensed Software, MobiCom would not charge any fee on Clubs. MobiCom will also not
                                            charge any convenience or transaction fee to the visiting Member, other than recovering the actual bank charges associated with card
                                            transaction processing.
                                        </li>
                                    </ul>
                                </Card.Body>
                                </Card>
                            }
                    </Container>

                </div>
            </div>
        </div>
    )
}

export default OverView