import { useNavigate } from 'react-router-dom'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { FaLongArrowAltLeft } from 'react-icons/fa'
import { LOGIN, VERIFY_EMAIL } from '../../constants/pathname'
import { ReactComponent as Mail } from '../../assets/images/login/email.svg'
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { forgotPassword } from '../../api/actions/auth'
import toast from 'react-hot-toast'
import { useState } from 'react'
import LoginHeader from "../../common/layout/LoginHeader";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false)
    const [showPass, setShowPass] = useState(false)
    const unmask = () => {
        setShowPass(!showPass)
    }
    const formik = useFormik({
        initialValues: {
            user_email: ""
        },
        validationSchema: Yup.object().shape({
            user_email: Yup.string()
                .required('Email is required')
                .test('is-valid-email', 'Invalid email address', function (value) {
                    if (!value) {
                        return this.createError({ message: 'Email is required' });
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
                    ) {
                        return this.createError({ message: 'Invalid email address' });
                    }
                    return true;
                }),
        }),
        onSubmit: (values) => {
            setShow(true)
            forgotPassword(values)((res) => {
                if (res?.status_code === 200) {

                    navigate(VERIFY_EMAIL, { state: { email: formik?.values?.user_email } })
                    formik.resetForm();
                    setShow(false)
                } else {
                    formik.setErrors({
                        ...formik?.values,
                        user_email: res?.errors?.user_email
                    })
                    toast.error(res?.response?.data?.message)
                    setShow(false)
                }

            })

        },
    });

    return (
        <section className="login">
            <LoginHeader />
            <section className='forgot-page'>

                <Row style={{ width: "100%" }}>
                    <Col lg={6}> </Col>
                    <Col lg={6}>

                        <div className='formd-section magh'>

                            <div className='formd-main'>
                                <div className='heading'>
                                    <h1 className='haed'>Forgot Password?</h1>

                                </div>
                                <div className='form-content col-md-10'>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <div className='email-main '>
                                            <Form.Group className="mb-3" controlId="formPlaintextEmail">
                                                <Form.Label >
                                                    Email
                                                </Form.Label>
                                                <Form.Control
                                                    type='email'
                                                    placeholder='Enter your email'
                                                    name='user_email'
                                                    value={formik.values?.user_email}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={formik.touched.user_email && formik.errors.user_email}
                                                />
                                                {formik.touched.user_email && formik.errors.user_email && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.user_email}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            <div className='image-wrapper'>
                                                <Mail />
                                            </div>
                                        </div>
                                        <div className='sign'>
                                            <Button className='sign-button w-100' type='submit'>{show ? "loading" : "Recover Account"} </Button>
                                        </div>
                                    </Form>
                                    <div className='Active'>
                                        <button onClick={() => navigate(LOGIN)} className='backto'> <span > <FaLongArrowAltLeft size={15} className='left' /></span >Back to login</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
        </section>
    )
}

export default ForgotPassword
