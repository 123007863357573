import { USER_SUBSCRIPTION_LIST } from "../api";
import axiosInstance from "../axiosinstance";

export const listuserSubscriptionlist = (props) => onResponse => {
    try {
        let BASE_URL = USER_SUBSCRIPTION_LIST + '?'
        if (props?.page) {
            BASE_URL += 'page=' + props.page + '&'
        }
        if (props?.location) {
            BASE_URL += 'location=' + props.location + '&'
        }
        if (props?.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props?.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
     
        if(props?.sort_by){
            BASE_URL+='sort_by='+props.sort_by+'&'
        }
        if(props?.order){
            BASE_URL+='order='+props.order+'&'
        }
       
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {
        console.log("Listing club facility error", error);
        
    }

};