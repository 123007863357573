import React from 'react'
import PushNotificationsCategory from '../../components/PushNotificationsCategory/PushNotificationsCategory'

const PushNotificationsCategoryPage = () => {

  return (
    <div className='push-notification-category-page'>
        <PushNotificationsCategory />
    </div>
  )
}

export default PushNotificationsCategoryPage