import React from 'react'
import Dashboard from '../../components/Dashboard/Dashboard'

const Home = () => {

  return (
    <div className='home-page'>
        <Dashboard />
    </div>
  )
}

export default Home