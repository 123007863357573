import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

const ClubsStatusAlert = ({ showClubsStatusAlert, setShowClubsSwal, content, error }) => {
    return (
        <div>
            {showClubsStatusAlert && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="OK"
                    confirmBtnCssClass="custom-confirm-button-class"
                    cancelBtnCssClass="custom-cancel-button-class d-none"
                    customClass='bulk-update-alert'
                    title={<span className="custom-sweetalert-title">{error ? "Error" : "Success"}</span>}
                    onConfirm={() => setShowClubsSwal(false)}
                    onCancel={() => setShowClubsSwal(false)}
                    focusCancelBtn
                >
                    {error ? (
                        <div style={{ fontWeight: '500' }}>
                            {error}
                        </div>
                    ) : (
                        <div>{content || ""}</div>
                    )}
                </SweetAlert>
            )}
        </div>
    );
};

export default ClubsStatusAlert;