import React, { useEffect, useState } from 'react'
import DataTable from "react-data-table-component";
import { ReactComponent as FilterIcon } from "../../assets/images/common/filter-icon.svg";
import { ReactComponent as ExcelIcon } from "../../assets/images/common/sheets.svg";
import uploadIcon from "../../assets/images/common/upload_icon.png";
import { BsSend } from "react-icons/bs";
import { EXPORT_MEMBER_LIST, SAMPLE_CSV_DOWNLOAD } from '../../api/api';
// Mui
import { FormControlLabel, RadioGroup, styled, Switch } from "@mui/material";
import { ReactComponent as EditIcon } from "../../assets/images/common/edit-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/common/close-icon.svg";
// Drawer
import Drawer from '@mui/material/Drawer';
import { Form, Spinner } from 'react-bootstrap';
// Rsuite Datepicker
import Select from 'react-select';
import { useFormik } from 'formik';
// Modal
import Modal from 'react-bootstrap/Modal';
// Radio group
import Radio from '@mui/material/Radio';
import * as Yup from 'yup';
import BulkUploadModal from './BulkUploadModal';
import { addManager, bulkUpdateMemberProfile, bulkUploadMember, deleteMemberDetails, exportMemberList, getManageMemberDetails, inviteClubMember, listMember, updateMemberActiveStatus } from '../../api/actions/manageMembers';
import toast from 'react-hot-toast';
import { paginationRowsPerPageOptions } from '../../helpers/helper';
import { FaSearch, FaTimes } from 'react-icons/fa';
import SweetAlertModal from '../SweetAlert/SweetAlertModal';
import BulkUpdateAlert from '../SweetAlert/BulkUpdateAlert';
import InviteMemberAlert from '../SweetAlert/InviteMemberAlert';
import { checkTheme } from '../../utils/utils';
import AsyncSelect from "react-select/async";
import { listAllClubs } from '../../api/actions/club';
import ClubsStatusAlert from '../SweetAlert/ClubsStatusAlert';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import { IoInformationCircleOutline } from "react-icons/io5";

const ManageMembers = () => {
  // Bulk modal
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [data, setData] = useState([{}]);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [limit, setLimit] = useState(25);
  const [search, setSearch] = useState('');
  const [update, setUpdate] = useState(false);
  const [pending, setPending] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [content, setContent] = useState(""); // Content for sweet alert
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [show, setShow] = useState(false);
  // Filter
  const [filter, setFilter] = useState(null);
  // SweetAlert
  const [isSwal, setIsSwal] = useState({ show: false, id: '', country: null, active_status: false }); // Delete
  const [isListSwal, setIsListSwal] = useState({ show: false, data: '', type: '' }); // RCA and RRM in list  
  const [isBulkSwal, setIsBulkSwal] = useState({ show: false, id: '', type: '', content: '' }); // Bulk Update
  const [isInviteSwal, setShowInviteSwal] = useState({ show: false, id: '' }); // Invite Member
  const [showDrawer, setShowDrawer] = useState(false);
  // Loading for sweet alert
  const [loadingAlert, setLoadingAlert] = useState(false);

  // Selected Member Id and Country Id
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [selectedMemberCountry, setSelectedMemberCountry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  // RCA and RRM Status
  const [reciprocalStatusObj, setReciprocalStatusObj] = useState({
    RCA: false,
    RRM: false
  });
  // State to manage selected rows
  const [selectedRows, setSelectedRows] = useState([]);
  const [resetSelection, setResetSelection] = useState(false);

  // Permissions and User Information
  const [permissionsList, setPermissionsList] = useState([]);
  const [userInfoObj, setUserInfoObj] = useState({});

  const [exportXlsLoading, setExportXlsLoading] = useState(false);

  // Club options
  const [clubOptions, setClubOptions] = useState([]);

  // Show success and error messages
  const [showMessages, setShowMessages] = useState(false);
  const [successErrorMsgObj, setSuccessErrorMsgObj] = useState({ success: "", error: [] });

  // Clear success and error messages 
  const clearMessages = () => {
    setSuccessErrorMsgObj({ success: "", error: [] });
    setShowMessages(false);
  }

  // Setting permission list
  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem("user_info"));

    if (userInfo) {
      setUserInfoObj(userInfo);
    }

    if (userInfo && userInfo?.permissions && userInfo?.permissions?.length) {
      setPermissionsList(userInfo?.permissions);
    }

  }, []);
  console.log(selectedRows);

  // Club options 
  useEffect(() => {
    // List clubs
    listAllClubs({
      limit,
      filter: { status_club: "True" },
    })((response) => {
      if (response && response.status) {
        if (Array.isArray(response?.data?.results)) {
          let clubArr = response.data?.results?.map((item) => {
            return {
              label: item.club_name,
              value: item.club,
              country: item.country,
              both_clubs: item.both_clubs
            };
          });
          setClubOptions(clubArr);
        }
      }
    });

  }, []);

  // Function to fetch club options based on input
  let typingTimer;
  const loadOptionsClub = (inputValue) => {
    return new Promise((resolve) => {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        if (inputValue.length >= 3) {
          listAllClubs({
            page: 1,
            limit: 10,
            search: inputValue,
            filter: { status_club: "True" },
          })((response) => {
            if (response?.status_code === 200) {
              const options = response?.data?.results || [];
              let clubArr = options?.map((item) => {
                return {
                  label: item.club_name,
                  value: item.club,
                  country: item.country,
                  both_clubs: item.both_clubs
                };
              });
              setClubOptions(clubArr);
              resolve(clubArr);
            } else {
              resolve([]);
            }
          });
        } else {
          resolve([]);
        }
      }, 300);
    });
  };

  // Handler for when rows are selected/unselected
  const handleRowSelection = (state) => {
    setSelectedRows(state.selectedRows); // Update selected rows state
    console.log("Selected Rows:", state.selectedRows); // Log selected rows for debugging
    if (state.selectedRows?.length === 1) {
      setReciprocalStatusObj({
        RCA: state.selectedRows?.[0]?.reciprocal_club_access,
        RRM: state.selectedRows?.[0]?.reciprocal_club_moderation
      });
    }
  };

  const handleClose = () => {
    setShow(false);
    formik.resetForm();
    setSelectedMemberId(null);
    setSelectedMemberCountry(null);
  };

  const handleShow = () => setShow(true);

  const handleShowBlkm = () => setShowBulkModal(true);
  const handleCloseBlkm = () => setShowBulkModal(false);

  const CustomSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 39,
    height: 20,
    padding: 0,
    marginLeft: 5,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      // margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: 'var(--sandy-brown)',
          opacity: 1,
          border: 0,
          ...theme.applyStyles('dark', {
            backgroundColor: '#2ECA45',
          }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        ...theme.applyStyles('dark', {
          color: theme.palette.grey[600],
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: 'var(--sandy-brown)',
        opacity: 0.7,
        ...theme.applyStyles('dark', {
          opacity: 0.3,
        }),
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 20,
      height: 20,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      ...theme.applyStyles('dark', {
        backgroundColor: '#39393D',
      }),
    },
  }));

  const validationSchema = Yup.object({
    first_name: Yup.string().required('Required'),
    email: Yup.string().required('Required'),
  });

  // Member Quick Add
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: '',
      email: '',
      rc_request: true,
      rc_request_moderation: false,
      country: '',
      invite: false
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      setLoadings(true);
      values.pcw = checkTheme() ? false : true;
      values.pcn = checkTheme() ? true : false;
      values.club_country = userInfoObj?.club_details?.[0]?.country;
      values.club = userInfoObj?.club_details?.[0]?.club_id;
      if (!values?.country) {
        values.country = userInfoObj?.club_details?.[0]?.country;
      }
      let valuesObj = values;

      // if (checkTheme()) {
      //   valuesObj = Object.fromEntries(
      //     Object.entries(values).filter(([key]) => !["rc_request", "rc_request_moderation"].includes(key))
      //   );
      // }

      addManager(valuesObj)((response) => {

        if (response.status_code === 200 || response.status_code === 201) {
          toast.success("Successfully Created!");
          resetForm();
          handleClose();
          setUpdate(!update);
        } else {
          toast.error(response?.response?.data?.errors?.non_field_errors?.[0] || "Something went wrong");
        }
        setLoadings(false);
      });
    },
  });

  const handleSearch = (value) => {
    setSearch(value);
    setSearchText(value);
  }

  useEffect(() => {
    if (search.length >= 2) {
      const debounceTimer = setTimeout(() => {
        setDebouncedSearch(search);
      }, 500);

      return () => clearTimeout(debounceTimer);
    } else {
      setDebouncedSearch('');
    }
  }, [search]);

  const clearSearch = () => {
    setSearch('');
    setSearchText('');
    setDebouncedSearch('');
    setUpdate(!update);
  };

  useEffect(() => { getListData(); }, [page, limit, debouncedSearch, update, userInfoObj?.email, userInfoObj?.club_details]);

  // Get list data
  const getListData = () => {
    setPending(true);

    if (debouncedSearch) {
      setPage(1);
    }

    if (userInfoObj?.club_details?.[0]?.club_id) {
      listMember({
        page: debouncedSearch ? 1 : page,
        limit,
        search: debouncedSearch,
        club: userInfoObj?.club_details?.[0]?.club_id || '',
        country: userInfoObj?.club_details?.[0]?.country || '',
        member_status: filter?.value || ''
      })(
        (response) => {
          if (response && response.status) {
            setData(response?.data?.results);
            setTotalRows(response?.data?.count);
            setPending(false);
          }
        }
      );
    } else {
      setPending(false);
      setData([]);
    }

  };
  useEffect(() => {
    if (!filter) {
      getListData();
    }
  }, [filter]);

  // Handle filter
  const handleFilterSubmit = () => {
    getListData();
  }
  // Export member list
  const handleExportMemberList = () => {
    const myHeaders = new Headers();
    let token = localStorage.getItem('token');
    setExportXlsLoading(true);
    myHeaders.append(`Authorization`, `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_BACKENDURL}/${EXPORT_MEMBER_LIST}?search=${debouncedSearch}&member_status=${filter?.value || ''}&country=${userInfoObj?.club_details?.[0]?.country}&club=${userInfoObj?.club_details?.[0]?.club_id}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = url;
        a.download = "Club Members.xls"; // Ensure correct file extension
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setExportXlsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setExportXlsLoading(false);
      });
  };

  // Handle bulk update
  const handleBulkUpdateMember = (type) => {
    let idArr = selectedRows.map((item) => item.member);
    let content = '';
    if (type === "RCA") {
      if (reciprocalStatusObj?.RCA) {
        content = 'Are you sure you want to turn off Member’s access to Reciprocal Clubs?';
      } else {
        content = 'Are you sure you want to turn on Member’s access to Reciprocal Clubs?';
      }
    } else if (type === "RRM") {
      if (reciprocalStatusObj?.RRM) {
        content = 'Are you sure you want the  request(s) for access submitted by the member to reciprocal clubs will not be routed to the home club administrator for approval?';
      } else {
        content = 'Are you sure you want the request(s) for access submitted by the member to reciprocal clubs to be routed to the home club administrator for approval?';
      }
    }
    setIsBulkSwal({ show: true, id: idArr, type: type, content: content })
  }

  // Update Member RCA and RRM
  const handleUpdateMember = () => {
    let props = {};

    if (isBulkSwal?.type === "RCA") {
      props = {
        member_ids: isBulkSwal?.id,
        reciprocal_club_access: !reciprocalStatusObj?.RCA
      }
      if (reciprocalStatusObj?.RCA) {
        props.reciprocal_club_moderation = false;
      }
    } else if (isBulkSwal?.type === "RRM") {
      props = {
        member_ids: isBulkSwal?.id,
        reciprocal_club_moderation: !reciprocalStatusObj?.RRM
      }
    }
    // console.log("bulkUpdateMemberProfile=", props);
    setLoadingAlert(true);

    bulkUpdateMemberProfile(props)((response) => {
      console.log("response", response);

      if (response?.status) {
        toast.success("Successfully updated")
      } else {
        toast.error("Something went wrong");
      }
      setLoadingAlert(false);
      setIsBulkSwal({ show: false, id: '', type: '', content: '' });
      setUpdate(!update);
      setSelectedRows([]);
      setResetSelection(!resetSelection); // Toggle resetSelection to trigger a reset
      setReciprocalStatusObj({
        RCA: false,
        RRM: false,
      });

      // if(isBulkSwal?.type === "RCA"){
      //   setReciprocalStatusObj({
      //     RCA: !reciprocalStatusObj?.RCA,
      //     RRM: reciprocalStatusObj?.RRM,
      //   });
      // }else if(isBulkSwal?.type === "RRM"){
      //   setReciprocalStatusObj({
      //     RRM: !reciprocalStatusObj?.RRM,
      //     RCA: reciprocalStatusObj?.RCA,
      //   });
      // }

    });
  }
  // Invite Members
  const handleInviteMembers = () => {

    let props = {
      club: userInfoObj?.club_details?.[0]?.club_id,
      club_country: userInfoObj?.club_details?.[0]?.country,
      ids: isInviteSwal?.id || []
    };

    setLoadingAlert(true);

    inviteClubMember(props)((response) => {
      if (response?.status) {
        toast.success("Successfully Sent Email")
      } else {
        toast.error("Something went wrong");
      }
      setLoadingAlert(false);
      setShowInviteSwal({ show: false, id: '' });
      setUpdate(!update)
    })
  }
  // Update RCA and RRM in Member List
  const updateReciprocal = (data, type) => {

    addManager(data)((response) => {
      console.log("updateReciprocal", response);

      if (response.status_code === 200 || response.status_code === 201) {
        let contentInfo = '';
        if (type === 'RCA') {
          if (data?.rc_request) {
            contentInfo = 'Member’s access to Reciprocal Clubs has been turned on';
          } else {
            contentInfo = 'Member’s access to Reciprocal Clubs has been turned off'
          }
        } else if (type === 'RRM') {
          if (data?.rc_request_moderation) {
            contentInfo = 'The request(s) for access submitted by the member to reciprocal clubs will be routed to the home club administrator for approval.';
          } else {
            contentInfo = 'The request(s) for access submitted by the member to reciprocal clubs will not be routed to the home club administrator for approval.'
          }
        }
        setIsListSwal({ show: true, data: contentInfo, type: type });
        // handleClose()        
        // setLoadings(false)
      } else {
        toast.error(response?.response?.data?.errors?.non_field_errors?.[0] || "Failed to update status");
      }
      setUpdate(!update);
    });
  }

  // Update Reciprocal Club Access 
  let columns = [
    {
      name: "Sl.No",
      key: "name",
      selector: row => row?.name,
      cell: (row, index) =>
        <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.name}>
          {(limit * (page - 1)) + (index + 1)}
        </div>,
      grow: 2,
      reorder: true,
      width: '100px'
    },
    { name: "First Name", selector: row => row.member_first_name, minWidth: "200px", sortable: true },
    { name: "Last Name", selector: row => row.member_last_name, minWidth: "200px", sortable: true },
    { name: "Email Id", selector: row => <div title={row.email}>{row.email}</div>, minWidth: "300px" },
    {
      name: "Reciprocal Club Access",
      selector: row => (
        <div>
          <CustomSwitch
            defaultChecked={row.reciprocal_club_access}
            onChange={() =>
              updateReciprocal(
                {
                  club: userInfoObj?.club_details?.[0]?.club_id,
                  club_country: userInfoObj?.club_details?.[0]?.country,
                  country: userInfoObj?.club_details?.[0]?.country,
                  email: row.email,
                  first_name: row.member_first_name,
                  last_name: row.member_last_name,
                  id: row.member,
                  pcw: true,
                  rc_request: !row.reciprocal_club_access,
                  rc_request_moderation: row.reciprocal_club_access ? false : row.reciprocal_club_moderation
                },
                "RCA"
              )}
          />
        </div>
      ),
      minWidth: "200px",
    },
    {
      name: "Reciprocal Request Moderation",
      selector: row => (
        <div
        >
          <CustomSwitch
            defaultChecked={row.reciprocal_club_moderation}
            onChange={() => {
              if (!row.reciprocal_club_access) {
                toast.error('Please enable member’s access to Reciprocal Clubs');
                setUpdate(!update);
                return;
              }
              updateReciprocal(
                {
                  club: userInfoObj?.club_details?.[0]?.club_id,
                  club_country: userInfoObj?.club_details?.[0]?.country,
                  country: userInfoObj?.club_details?.[0]?.country,
                  email: row.email,
                  first_name: row.member_first_name,
                  last_name: row.member_last_name,
                  id: row.member,
                  pcw: true,
                  rc_request: row.reciprocal_club_access,
                  rc_request_moderation: !row.reciprocal_club_moderation
                },
                "RRM"
              )
            }
            }
          />
        </div>
      ),
      minWidth: "250px",
    },
    {
      name: "Status",
      selector: row =>
        <div className='d-flex align-items-center gap-1'>
          {row.member_status}
          {/* Resend */}
          {row.member_status === "Invited" &&
            <div className='filter-box d-flex align-items-center gap-2 cursor-pointer'
              onClick={() => {
                setShowInviteSwal({
                  show: true,
                  id: [row.id]
                })
              }}
              title='Resend Invitation Email'
            >
              <BsSend size={16} color='var(--sandy-brown)' />
            </div>}
        </div>,
      minWidth: "150px",      
    },
    { name: <span>Active/Inactive <IoInformationCircleOutline style={{marginTop: "-2px"}} size={14} title='This feature will soft delete the member and remove the member from the Club CMS'/></span>, 
        selector: row => 
          <>
            <CustomSwitch
              defaultChecked={row.is_active}
              onChange={() =>
                setIsSwal({ show: true, id: row?.id, country: row?.country, active_status: row?.is_active })
              }
            />
            {/* <div>{row.is_active ? 'Active' : 'Inactive'}</div> */}
          </>
        , minWidth: "150px",
        key: "active_inactive"
    },
    {
      name: "Actions",
      selector: row =>
        <div className='d-flex align-items-center gap-3'>
          {/* <div className='view-icon cursor-pointer' onClick={() => navigate(CREATE_FACILITIES_CATEGORY, {state : {currentPageName: "View Facilities Category" }})} title='View'><ViewIcon style={{marginTop: "-1px"}}/></div> */}
          <div
            className='view-icon cursor-pointer'
            title="Edit"
            onClick={() => {
              setSelectedMemberId(row?.member);
              setSelectedMemberCountry(row?.country);
              setShow(true);
            }}
          >
            <EditIcon />
          </div>
          {/* {userInfoObj?.is_superuser && 
            <div
                className='view-icon cursor-pointer'
                title='Update Active Status'
                onClick={() => setIsSwal({ show: true, id: row?.id, country: row?.country, active_status: row?.is_active })}
              >
                <CloseIcon style={{ marginLeft: "2px" }} />
              </div>} */}
        </div>,
      width: "180px"
    },
  ];

  if(!userInfoObj?.is_superuser){
    columns = columns.filter((item) => item.key !== "active_inactive");
  }

  // if (checkTheme()) {
  //   columns = columns.filter((item) => item.name !== "Reciprocal Club Access" && item.name !== "Reciprocal Request Moderation");
  // }

  // Get Member Details
  useEffect(() => {

    if (selectedMemberId) {
      setLoading(true);
      getManageMemberDetails({ id: selectedMemberId, country: selectedMemberCountry })((response) => {
        setLoading(false);
        if (response?.status) {
          formik.setValues({
            ...formik.values,
            id: response?.data?.id,
            first_name: response?.data?.first_name || "",
            last_name: response?.data?.last_name || "",
            email: response?.data?.email || "",
            rc_request: response?.data?.rc_request || false,
            rc_request_moderation: response?.data?.rc_request_moderation || false,
            country: response?.data?.country
          });
        }
      });
    }
  }, [selectedMemberId]);

  // Custom styling for the table
  const customStyles = {
    table: {
      style: {
        borderRadius: "15px 15px 0px 0px",
        border: "1px solid var(--border-color)",
        overflow: "hidden"
      },
    },
    headCells: {
      style: {
        backgroundColor: "var(--light-blue)",
        justifyContent: 'flex-start !important',
        color: "var(--dark-gray)",
        fontWeight: "400",
        fontSize: "15px",
      },
    },
    rows: {
      style: {
        height: "45px", // Set row height
        justifyContent: "start",
      },
    },
    selectedRow: {
      style: {
        backgroundColor: 'unset', // Light blue for selected rows (customize as needed)
        '&:hover': {
          backgroundColor: 'unset', // Optional: Hover color for selected rows
        },
      },
    },
    cells: {
      style: {
        fontSize: '14px',
        justifyContent: 'flex-start !important',
        borderRight: "1px solid var(--border-color)",
      },
    },
  };

  const handleDelete = (id) => {
    if (isDeleting) return;
    setIsDeleting(true);

    let props;

    if (Array.isArray(id)) {
      props = { id: id };
    } else {
      props = { id: [id] };
    }

    deleteMemberDetails(props)((response) => {
      if (response && response?.status) {
        toast.success('Successfully deleted');
        getListData();
      } else {
        toast.error('Deletion failed');
      }
      setIsDeleting(false);
      setIsSwal({ show: false, id: null });
    });
  };

  const handleMultipleDelete = () => {
    let idArr = selectedRows.map((item) => item.member);
    setIsSwal({ show: true, id: idArr });
  }

  const handleFileUpload = async (file) => {

    if (!file) return;

    let userInfo = JSON.parse(localStorage.getItem('user_info'));

    const formData = new FormData();
    formData.append("club", userInfo?.club_details?.[0]?.club_id || userInfoObj?.club_details?.[0]?.club_id);
    formData.append("club_country", userInfo?.club_details?.[0]?.country || userInfoObj?.club_details?.[0]?.country);
    formData.append("csv_file", file);

    try {
      bulkUploadMember(formData)((res) => {
        // console.log(res);
        if (res?.status_code == 200 || res?.status_code == 201) {
          if (res?.message && res?.message && (res?.message?.success?.details || res?.message?.error)) {
            setSuccessErrorMsgObj({ success: res?.message?.success?.details || "", error: res?.message?.error || [] });
            setShowMessages(true);
          } else {
            toast.success("Successfully uploaded")
          }
          setUpdate(!update)
        } else {
          toast.error(res?.response?.data?.message || "Failed to upload file");
        }
      })

    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  console.log("userInfoObj", userInfoObj);

  const handleChange = (e, name, selected_name) => {
    if (e) {
      setUserInfoObj({
        ...userInfoObj,
        club_details: [{
          club_id: e.value,
          country: e.country,
          both_clubs: e.both_clubs,
        }]
      });
      // formik.setValues({
      //   ...formik.values,
      //   [name]: e.value,
      //   [selected_name]: e,
      //   country: e.country,
      // });    
    }
  };

  //  Update member status
  const updateMemberStatus = () =>{
    let props = { ids:Array.isArray(isSwal?.id) ? isSwal?.id : [isSwal?.id], active_status: !isSwal?.active_status, country: isSwal?.country };    

    updateMemberActiveStatus(props)((response) => {
      if (response && response?.status) {
        toast.success('Successfully Updated Member Status');
        setUpdate(!update)
      } else {
        toast.error('Failed to Update Member Status');
      }
      setIsSwal({ show: false, id: null, country: null, active_status: false });
    });
  }


  const checkDisabled = () =>{
    if(selectedMemberId) {
      if(userInfoObj?.is_superuser) {
        return false
      }
      
      if(userInfoObj?.club_admin) {
        return false
      }

      return true
    }
    return false
  }

  return (
    <div className='manage-members member-stats-section'>
      {/* Bulk upload */}
      <BulkUploadModal showBulkModal={showBulkModal} setShowBulkModal={setShowBulkModal} handleCloseBlkm={handleCloseBlkm} onUpload={handleFileUpload} />
      {/* Member Quick Add */}
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Member Quick Add</Modal.Title>
        </Modal.Header>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            {loading ? (
              <div className="spinner-container" style={{ textAlign: "center", fontSize: "5px" }}>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                {/* First Name */}
                <div className="mb-3">
                  <Form.Group controlId="first_name">
                    <Form.Label>First Name<span className="required">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      name="first_name"
                      placeholder="First Name"
                      value={formik.values.first_name}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'first_name',
                          value.charAt(0).toUpperCase() + value.slice(1)
                        );
                      }}
                      isInvalid={formik.touched.first_name && formik.errors.first_name}
                    />
                    {formik.touched.first_name && formik.errors.first_name &&
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.first_name}
                      </Form.Control.Feedback>}
                  </Form.Group>
                </div>

                {/* Last Name */}
                <div className="mb-3">
                  <Form.Group controlId="last_name">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      placeholder="Last Name"
                      value={formik.values.last_name}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                </div>

                {/* Email */}
                <div className="mb-3">
                  <Form.Group controlId="email">
                    <Form.Label>Email<span className="required">*</span></Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      isInvalid={formik.touched.email && formik.errors.email}
                      // disabled={selectedMemberId && (!userInfoObj?.is_superuser || !userInfoObj?.club_admin) ? true : false}
                      disabled={checkDisabled()}
                    />
                    {formik.touched.email && formik.errors.email &&
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.email}
                      </Form.Control.Feedback>}
                  </Form.Group>
                </div>
                <>
                  {/* Reciprocal Club Access */}
                  <div className="d-flex align-items-center">
                    <div>Reciprocal Club Access: &nbsp;</div>
                    <RadioGroup
                      name="rc_request"
                      value={formik.values.rc_request}
                      onChange={(event) => {
                        const isRcRequestEnabled = event.target.value === "true";

                        // Set rc_request_moderation to false if rc_request is set to false
                        if (!isRcRequestEnabled) {
                          formik.setFieldValue("rc_request_moderation", false);
                        }

                        formik.setFieldValue("rc_request", isRcRequestEnabled);

                        // formik.setFieldValue("rc_request", event.target.value === "true");
                      }}
                      className="d-flex flex-row align-items-center"
                    >
                      <FormControlLabel value="true" control={<Radio sx={{ transform: "scale(0.7)" }} />} label={<span style={{ fontSize: "14px" }}>Yes</span>} />
                      <FormControlLabel value="false" control={<Radio sx={{ transform: "scale(0.7)" }} />} label={<span style={{ fontSize: "14px" }}>No</span>} />
                    </RadioGroup>
                    {formik.touched.rc_request && formik.errors.rc_request && (
                      <div className="text-danger" style={{ fontSize: "12px" }}>
                        {formik.errors.rc_request}
                      </div>
                    )}
                  </div>
                  {/* Reciprocal Request Moderation */}
                  <div className="d-flex align-items-center">
                    <div>Reciprocal Request Moderation: &nbsp;</div>
                    <RadioGroup
                      name="rc_request_moderation"
                      value={formik.values.rc_request_moderation}
                      onChange={(event) => {
                        if (formik.values?.rc_request) {
                          formik.setFieldValue("rc_request_moderation", event.target.value === "true");
                          return;
                        }
                        toast.error('Please enable member’s access to Reciprocal Clubs', { position: "top-center", duration: 3000 });

                      }}
                      className="d-flex flex-row align-items-center"
                    >
                      <FormControlLabel value="true" control={<Radio sx={{ transform: "scale(0.7)" }} />} label={<span style={{ fontSize: "14px" }}>Yes</span>} />
                      <FormControlLabel value="false" control={<Radio sx={{ transform: "scale(0.7)" }} />} label={<span style={{ fontSize: "14px" }}>No</span>} />
                    </RadioGroup>
                    {formik.touched.rc_request_moderation && formik.errors.rc_request_moderation && (
                      <div className="text-danger" style={{ fontSize: "12px" }}>
                        {formik.errors.rc_request_moderation}
                      </div>
                    )}
                  </div>
                </>
                {/* Invite */}
                {!selectedMemberId &&
                  <div className="d-flex align-items-start">
                    <div className=''>Invite:</div>
                    <div>
                      <Form.Check
                        type="checkbox"
                        className='px-3'
                        onChange={(e) => {
                          formik.setFieldValue("invite", e.target.checked)
                        }}
                      />
                    </div>
                  </div>}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn-type-2 cursor-pointer" type="button" onClick={handleClose}>
              Close
            </button>
            <button className='btn-type-1 cursor-pointer' type="submit" disabled={loadings}>
              {loadings ? (
                <div className="d-flex align-items-center">
                  <Spinner animation="border" style={{ width: "15px", height: "15px" }} />
                  <span className="ms-2">Saving...</span>
                </div>
              ) : (
                "Save"
              )}
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* Drawer */}
      <Drawer
        anchor={'right'}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        className='member-stats-drawer'
        sx={{
          '& .MuiPaper-root': {
            padding: '30px 19px',
            height: "100vh",
            // overflow: "hidden"
          },
          '& .MuiDrawer-paper': {
            width: '420px'
          },
          '& .MuiBox-root': {
            width: '420px'
          },
          '& .MuiList-root': {
            display: 'flex',
            columnGap: '10px',
            width: '90%',
            margin: 'auto',
            paddingTop: "25px"
          },
          '& .MuiButtonBase-root': {
            padding: '7px',
            textAlign: 'center',
            alignItems: 'center',
            borderRadius: '10px',
            backgroundColor: '#F5F5F5',
          }
        }}
      >
        <div className="drawer-top-box">
          <div>Filter</div>
          <button type="button" class="btn-close" aria-label="Close" onClick={() => setShowDrawer(false)}></button>
        </div>
        <div className="drawer-bottom-box mt-4">
          {/* Status */}
          <div className='item'>
            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em5">
              <Form.Label>Status</Form.Label>
              <div className='input-border'>
                <Select
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 5,
                    colors: {
                      ...theme.colors,
                    },
                  })}
                  placeholder="Select"
                  options={[{ label: "Activated", value: "Activated" }, { label: "Invited", value: "Invited" }, { label: "Uploaded", value: "Uploaded" }]}
                  onChange={(option) => setFilter(option)}
                  value={filter}
                  isSearchable={false}
                  isClearable={true}
                />
              </div>
            </Form.Group>
          </div>
          <div className='submit-box d-flex align-items-end justify-content-end gap-3' style={{ height: "60vh" }}>
            <button className='btn-type-2 cursor-pointer'
              onClick={() => {
                setFilter(null);
                setShowDrawer(false);
              }}
            >
              Clear
            </button>
            <button className='btn-type-1'
              onClick={() => {
                handleFilterSubmit();
                setShowDrawer(false);
              }}
            >
              Submit
            </button>
          </div>
        </div>

      </Drawer>
      <div className='page-details'>
        <div className='top-section d-flex flex-wrap align-items-center justify-content-between row-gap-4'>
          <div className='w-100'>
            <div className='left'>Manage Members</div>
            {showMessages && (
              <div className="message-container mt-3" style={{ padding: "0px 6px" }}>
                {/* Success Message */}
                {successErrorMsgObj?.success &&
                  <div className="success-message p-3 rounded mb-2 border border-success position-relative">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="text-success" style={{ fontWeight: "500", fontSize: "18px" }}>Success</span>
                      <AiOutlineCheckCircle className="text-success fs-4" /> {/* Green Success Icon */}
                    </div>
                    <div className="mb-1">{successErrorMsgObj?.success}</div>
                  </div>}

                {/* Error Message */}
                {Array.isArray(successErrorMsgObj?.error) && successErrorMsgObj?.error?.length > 0 &&
                  <div className="error-message p-3 rounded mb-2 border border-danger position-relative">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="text-danger" style={{ fontWeight: "500", fontSize: "18px" }}>Error</span>
                      <AiOutlineCloseCircle className="text-danger fs-4" />
                    </div>
                    {successErrorMsgObj?.error?.map((item) => {
                      return (
                        <div className="mb-1">{item}</div>
                      )
                    })}
                  </div>}

                {/* Close Button */}
                {(successErrorMsgObj?.success || (Array.isArray(successErrorMsgObj?.error) && successErrorMsgObj?.error?.length > 0))
                  &&
                  <div className="text-end">
                    <button
                      className="btn btn-sm btn-outline-secondary"
                      onClick={clearMessages}
                    >
                      <IoClose className="fs-5" />
                    </button>
                  </div>}
              </div>
            )}
            <div>
              <p className='messages'>
                Please update the Letter of Introduction section before inviting your members if your members will be requesting access to your club's existing reciprocal network via PrivateClubWorld.
                The Letter of Introduction is appended with the member's request for access and sent to the host club for its reference
              </p>
            </div>
          </div>
          <div className='right d-flex flex-wrap align-items-center  gap-3 w-100 justify-content-end' >
            {/* Search by Name */}
            {userInfoObj?.club_details?.[0]?.club_id &&
              <div className='right  d-flex align-items-center gap-3'>
                <div className='search-box'>
                  <input
                    type='text'
                    placeholder='Search by Name'
                    className='form-control'
                    value={searchText}
                    onChange={(e) => handleSearch(e.target.value)}
                  />

                  {searchText && (
                    <FaTimes className='clear-icon' onClick={clearSearch} />
                  )}
                </div>
              </div>}
            {/* Club */}
            {userInfoObj?.is_superuser &&
              <AsyncSelect
                loadOptions={loadOptionsClub}
                defaultOptions={clubOptions || []}
                classNamePrefix="select"
                placeholder={"Select Club"}
                isClearable
                onChange={(e) => handleChange(e, "club", "club_option")}
                // value={formik.values?.club_option}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: "300px", // Set width here
                  }),
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensures dropdown is above other elements                  
                }}
                menuPortalTarget={document.body} // Attaches dropdown to body
              />}
            {/* Add Member */}
            {userInfoObj?.club_details?.[0]?.club_id &&
               !(userInfoObj?.club_details?.[0]?.both_clubs && !checkTheme()) && (
                <div className='filter-box d-flex align-items-center gap-2 cursor-pointer'
                  onClick={handleShow}
                  title='Add New Member'
                >
                  Add Member
                </div>
              )
            }

            {/* RCA, RRM and Delete  */}
            {selectedRows.length > 1 && (
              <div className='d-flex align-items-center gap-3'>
                <>
                  {selectedRows.every(club => club.reciprocal_club_access === reciprocalStatusObj?.RCA) &&
                    <div className='d-flex align-items-center'>
                      <span>RCA </span>
                      <CustomSwitch defaultChecked={reciprocalStatusObj?.RCA} onChange={() => handleBulkUpdateMember("RCA")} />
                    </div>}
                  {selectedRows.every(club => club.reciprocal_club_access === true)
                    &&
                    <div className='d-flex align-items-center'>
                      <span>RRM </span>
                      <CustomSwitch defaultChecked={reciprocalStatusObj?.RRM} onChange={() => handleBulkUpdateMember("RRM")} />
                    </div>}
                </>
                {/* {userInfoObj?.is_superuser && 
                  <div
                    className='d-flex align-items-center'
                    onClick={handleMultipleDelete}
                  >
                    <span>Delete </span>
                    <CloseIcon style={{ fontSize: "21px", cursor: "pointer", marginTop: "-2px", marginLeft: "4px" }} />
                  </div>
                } */}
              </div>
            )}
            {userInfoObj?.club_details?.[0]?.club_id &&
              <>
                {/* Invite New */}
                {selectedRows.length > 0 && Array.isArray(selectedRows) && !selectedRows.some(item => item.member_status === "Activated")  && 
                  <div className='filter-box d-flex align-items-center gap-2 cursor-pointer'
                    onClick={() => {
                      setShowInviteSwal({
                        show: true,
                        id: selectedRows?.map((item) => item.id)
                      })
                    }}
                    title='Sent Invitation Email'
                  >
                    <BsSend size={16} />
                    Invite
                  </div>}
                {/* Bulk Upload */}
                {userInfoObj?.club_details?.[0]?.club_id &&
                  !(userInfoObj?.club_details?.[0]?.both_clubs && !checkTheme()) && (
                    <div className='filter-box d-flex align-items-center gap-2 cursor-pointer'
                      onClick={() => handleShowBlkm()}
                      title='Bulk Upload'
                    >
                      <img src={uploadIcon} alt='' style={{ width: "16px" }} />
                      Bulk Upload
                    </div>
                  )
                }

                {/* Export Member List */}
                <button disabled={exportXlsLoading} className='download-excel cursor-pointer' title={exportXlsLoading ? 'Loading...' : 'Export Member List'} onClick={() => handleExportMemberList()}>
                  <ExcelIcon />
                </button>
                {/* Filter */}
                <div
                  className='filter-box d-flex align-items-center  gap-3 cursor-pointer'
                  onClick={() => setShowDrawer(true)}
                  title='Filter'
                >
                  <FilterIcon />
                  <span>Filter</span>
                </div>
              </>}
          </div>
        </div>
        {/* Data Table */}
        <div className='table-box mt-3'>
          <DataTable
            columns={columns}
            data={data}
            customStyles={customStyles}
            fixedHeader
            highlightOnHover
            responsive
            pagination
            paginationServer
            paginationPerPage={limit}
            progressPending={pending}
            onChangeRowsPerPage={setLimit}
            paginationTotalRows={totalRows}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            onChangePage={setPage}
            selectableRows // Enable row selection
            onSelectedRowsChange={handleRowSelection} // Handle row selection changes
            clearSelectedRows={resetSelection} // Reset selection state
            selectableRowsHighlight
          />
        </div>
      </div>
      {/* Delete */}
      {/* <SweetAlertModal
        showSweetAlert={isSwal.show}
        setShowSweetAlert={(show) => {
          if (show) handleDelete(isSwal.id);
          else setIsSwal({ ...isSwal, show: false });
        }}
        memberName={isSwal.name} // Pass member name here
      />  */}
      {/* Update Active/Inactive member status */}
      <BulkUpdateAlert
        showSweetBulkAlert={isSwal.show}
        setShowBulkAlert={(show) => {
          if (show) {
            updateMemberStatus();
          } else {
            setIsSwal({ show: false, id: '', country: null, active_status: false });
          }
        }}
      />
      {/* List Update RCA and RRM */}
      <BulkUpdateAlert
        showSweetBulkAlert={isListSwal.show}
        setShowBulkAlert={(show) => {
          // if (show) updateReciprocal();
          // else setIsListSwal({ ...isListSwal, show: false });
          setIsListSwal({ show: false, data: '', type: '' });
        }}
        content={isListSwal?.data || ''}
      />
      {/* Bulk Update RCA and RRM */}
      <BulkUpdateAlert
        showSweetBulkAlert={isBulkSwal.show}
        loadingAlert={loadingAlert}
        setShowBulkAlert={(show) => {
          if (show) handleUpdateMember();
          else setIsBulkSwal({ show: false, id: '', type: '', content: '' });
        }}
        content={isBulkSwal?.content}
      />
      {/* Invite Member Alert */}
      <InviteMemberAlert
        loadingAlert={loadingAlert}
        showSweetInviteAlert={isInviteSwal.show}
        setShowInviteSwal={(show) => {
          if (show) handleInviteMembers();
          else setShowInviteSwal({ show: false, id: '' });
        }}
      />
    </div>
  )
}

export default ManageMembers