import { ADD_INTEREST, INTEREST_DELETE, INTEREST_DETAILS, INTEREST_LIST, STATUS_CHANGING_INTEREST } from "../api";
import axiosInstance from "../axiosinstance";

export const listInterest = (props) => onResponse => {
    try {
        let BASE_URL = INTEREST_LIST + '?'
        if (props.page) {
            BASE_URL += 'page=' + props.page + '&'

    }
        if (props.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if(props.sort_by){
            BASE_URL+='sort_by='+props.sort_by+'&'
        }
        if(props.order){
            BASE_URL+='order='+props.order+'&'
        }
        if(props.status){
            BASE_URL+='status='+props.status+'&'
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }

};

export const addInterest=(props) => onResponse => {
    try {
        axiosInstance.post(ADD_INTEREST, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const deleteInterest = (id) => onResponse => {
    try {

        axiosInstance.delete(INTEREST_DELETE, { data: { id: [id] } })

            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};


export const getInterestdetails = (props) => onResponse => {
    try {
        axiosInstance.get(INTEREST_DETAILS + '?id=' + props.id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const interestStatusChanging=(props) => onResponse => {
    try {
        axiosInstance.post(STATUS_CHANGING_INTEREST, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};