import { DELETE_USER, USER_MANAGEMENT_CREATE_GROUP, USER_MANAGEMENT_CREATE_ROLE, USER_MANAGEMENT_DELETE_ROLE, USER_MANAGEMENT_PERMISSION, USER_MANAGEMENT_RETRIVE_GROUPS, USER_MANAGEMENT_RETRIVE_ROLES, USER_MANAGEMENT_ROLE_INFO, USER_MANAGEMENT_ROLS_DROPDOWN, USER_MANAGMENT_CREATE_USER, USER_MANAGMENT_DESTROY_GROUP, USER_MANAGMENT_DETAIL_USER, USER_MANAGMENT_DETAILS_GROUP, USER_MANAGMENT_GROUP_DROPDOWN, USER_MANAGMENT_ISACTIVE_STATUS, USER_MANAGMENT_LIST_USER, USER_MANAGMENT_PERMISSION_DROPDOWN } from "../api"
import axiosInstance from "../axiosinstance"

export const getAllPermissions = () => onReponse => {
    try {
        axiosInstance.get(USER_MANAGEMENT_PERMISSION).then((res) => {
            onReponse(res?.data)
        }).catch((err) => {
            onReponse(err)
        })
    } catch (error) {

    }
}

export const createOrUpdateRole = (data) => onReponse => {
    try {
        axiosInstance.post(USER_MANAGEMENT_CREATE_ROLE, data).then((res) => {
            onReponse(res?.data)
        }).catch((err) => {
            onReponse(err)
        })
    } catch (error) {

    }
}

export const retriveRoles = (props) => onReponse => {
    try {
        let BASE_URL = USER_MANAGEMENT_RETRIVE_ROLES + '?'
        if (props?.page) {
            BASE_URL += 'page=' + props.page + '&'
        }
        if (props?.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props?.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        axiosInstance.get(BASE_URL).then((res) => {
            onReponse(res?.data)
        }).catch((err) => {
            onReponse(err)
        })
    } catch (error) {

    }
}

export const retriveRoleInfo = (id) => onReponse => {
    try {
        let BASE_URL = USER_MANAGEMENT_ROLE_INFO
        axiosInstance.get(`${BASE_URL}?role=${id}`).then((res) => {
            onReponse(res?.data)
        }).catch((err) => {

            onReponse(err)
        })
    } catch (error) {

    }
}

export const destroyRole = (id) => onReponse => {
    try {
        axiosInstance.delete(USER_MANAGEMENT_DELETE_ROLE, { data: { role: id } }).then((res) => {
            onReponse(res?.data)
        }).catch((err) => {
            console.log(err)
            onReponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}


export const retriveGroups = (props) => onResponse => {
    try {
        let BASE_URL = USER_MANAGEMENT_RETRIVE_GROUPS + '?'
        if (props.page) {
            BASE_URL += 'page=' + props.page + '&'

        }
        if (props.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if (props.sort_by) {
            BASE_URL += 'sort_by=' + props.sort_by + '&'
        }
        if (props.order) {
            BASE_URL += 'order=' + props.order + '&'
        }
        axiosInstance.get(BASE_URL).then((res) => {
            onResponse(res?.data)
        }).catch((err) => {
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}

export const retriveRolesDropdown = () => onResponse => {
    try {
        axiosInstance.get(USER_MANAGEMENT_ROLS_DROPDOWN).then((res) => {
            onResponse(res?.data)
        }).catch((err) => {
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}
export const retriveGroupDropdown = () => onResponse => {
    try {
        axiosInstance.get(USER_MANAGMENT_GROUP_DROPDOWN).then((res) => {
            onResponse(res?.data)
        }).catch((err) => {
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}
export const retrivePermissionDropdown = () => onResponse => {
    try {
        axiosInstance.get(USER_MANAGMENT_PERMISSION_DROPDOWN + '?page=1&limit=100').then((res) => {
            onResponse(res?.data)
        }).catch((err) => {
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}


export const createOrUpdateGroup = (data) => onResponse => {
    try {
        axiosInstance.post(USER_MANAGEMENT_CREATE_GROUP, data).then((res) => {
            onResponse(res?.data)
        }).catch((err) => {
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}
export const destroygroup = (id) => onReponse => {
    try {
        axiosInstance.delete(USER_MANAGMENT_DESTROY_GROUP, { data: { group: id } }).then((res) => {
            onReponse(res?.data)
        }).catch((err) => {
            console.log(err)
            onReponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}

export const retriveGroupInfo = (id) => onReponse => {
    try {
        let BASE_URL = USER_MANAGMENT_DETAILS_GROUP
        axiosInstance.get(`${BASE_URL}?group=${id}`).then((res) => {
            onReponse(res?.data)
        }).catch((err) => {

            onReponse(err)
        })
    } catch (error) {

    }
}
export const createorupdateUser = (data) => onResponse => {
    try {
        axiosInstance.post(USER_MANAGMENT_CREATE_USER, data).then((res) => {
            onResponse(res?.data)
        }).catch((err) => {
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}
export const retriveUser = (props) => onResponse => {
    try {
        let BASE_URL = USER_MANAGMENT_LIST_USER + '?'
        if (props.page) {
            BASE_URL += 'page=' + props.page + '&'

        }
        if (props.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if (props.sort_by) {
            BASE_URL += 'sort_by=' + props.sort_by + '&'
        }
        if (props.order) {
            BASE_URL += 'order=' + props.order + '&'
        }
        axiosInstance.get(BASE_URL).then((res) => {
            onResponse(res?.data)
        }).catch((err) => {
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}

export const getUserActive=(props)=>onResponse=>{
    try{
        axiosInstance.put(USER_MANAGMENT_ISACTIVE_STATUS,props)
        .then((response) => {
            onResponse(response.data);
        })
        .catch((err) => {
            onResponse(err);
        });


    } catch(error){

    }
};
export const retriveUserInfo = (id) => onReponse => {
    try {
        let BASE_URL = USER_MANAGMENT_DETAIL_USER
        axiosInstance.get(`${BASE_URL}?user=${id}`).then((res) => {
            onReponse(res?.data)
        }).catch((err) => {

            onReponse(err)
        })
    } catch (error) {

    }
}

export const removeUser = (props) => onResPonse => {
    try{
        axiosInstance.delete(DELETE_USER,{ data: { id: [props.id] } })
        .then((res)=>{
            onResPonse(res?.data)
        }).catch((err)=>{
            onResPonse(err)
        })
    } catch (err){

    }
}