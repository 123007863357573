import { MANAGE_NETWORK_LISTING, NETWORK_ACCEPT_ALL, NETWORK_BLOCK_ALL, REQUEST_STATUS_CHANGE, RESTRICT_RC } from "../api";
import axiosInstance from "../axiosinstance";

export const listManagenetwork = (props) => onResponse => {
    console.log(props);
    
    try {
        let BASE_URL = MANAGE_NETWORK_LISTING + '?'
        if (props?.page) {
            BASE_URL += 'page=' + props.page + '&'

        }
        if (props?.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props?.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if (props?.pcn ) {
            BASE_URL += 'pcn=' + props.pcn + '&'
        }
        if(props?.club){
            BASE_URL+='club='+props.club+'&'
        }
        if(props?.country){
            BASE_URL+='country='+props.country+'&'
        }
        if(props?.country_name){
            BASE_URL+='country_name='+props.country_name+'&'
        }
        if(props?.city){
            BASE_URL+='city='+props.city+'&'
        }
        if(props?.club_type){
            BASE_URL+='club_type='+props.club_type+'&'
        }
        if(props?.member_access){
            BASE_URL+='member_access='+props.member_access+'&'
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {
        console.log("Listing club facility error", error);
        
    }

};



export const getRequestAcessstatus=(props)=>onResponse=>{
    try{
        axiosInstance.post(REQUEST_STATUS_CHANGE,props)
        .then((response) => {
            onResponse(response.data);
        })
        .catch((err) => {
            onResponse(err);
        });


    } catch(error){

    }
};

export const getRequestAllAcessstatus=(props)=>onResponse=>{
    try{
        axiosInstance.post(NETWORK_ACCEPT_ALL,props)
        .then((response) => {
            onResponse(response.data);
        })
        .catch((err) => {
            onResponse(err);
        });


    } catch(error){

    }
};
export const getRequestBlockAcessstatus=(props)=>onResponse=>{
    try{
        axiosInstance.post(NETWORK_BLOCK_ALL,props)
        .then((response) => {
            onResponse(response.data);
        })
        .catch((err) => {
            onResponse(err);
        });


    } catch(error){

    }
};
export const getRcRequest=(props)=>onResponse=>{
    try{
        axiosInstance.post(RESTRICT_RC,props)
        .then((response) => {
            onResponse(response.data);
        })
        .catch((err) => {
            onResponse(err);
        });


    } catch(error){

    }
};