import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
 
const InviteMemberAlert = ({ showSweetInviteAlert, setShowInviteSwal, loadingAlert }) => {
    return (
        <div>
            {showSweetInviteAlert &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={loadingAlert ? "Sending..." : "Yes"}
                    cancelBtnText="Cancel"
                    confirmBtnCssClass={`custom-confirm-button-class ${loadingAlert ? 'disabled' : ''}`}
                    cancelBtnCssClass="custom-cancel-button-class"
                    title={<span className="custom-sweetalert-title">Are you sure want to send email?</span>}
                    onConfirm={() => setShowInviteSwal(true)}
                    onCancel={() => setShowInviteSwal(false)}
                    focusCancelBtn
                />}
    </div>
    );
}

export default InviteMemberAlert;
