import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ReactComponent as FilterIcon } from "../../assets/images/common/filter-icon.svg";
// Drawer
import Drawer from "@mui/material/Drawer";
import { Form } from "react-bootstrap";



const BespokeBookings = () => {
  const [showDrawer, setShowDrawer] = useState(false);

  const data = [
   {
     enquiryId: "BESPOKE_145782135689523654",
     experienceName: "Member Request for Access",
     memberName: "Amit Jha",
     clubName: "OneClubNet Demo Club",
     email: "sandracarder@teleworm.us",
     enquiredOn: "5159247235",
     status: "Requested",
   },
   {
     enquiryId: "BESPOKE_145782135689523654",
     experienceName: "Member Request for Access",
     memberName: "Sandra L. Carder",
     clubName: "Sapphire City Club",
     email: "sandracarder@teleworm.us",
     enquiredOn: "5159247235",
     status: "Requested",
   },
   {
     enquiryId: "BESPOKE_145782135689523654",
     experienceName: "Member Request for Access",
     memberName: "Sandra L. Carder",
     clubName: "Estancian Golf Club",
     email: "sandracarder@teleworm.us",
     enquiredOn: "5159247235",
     status: "Requested",
   },
   {
     enquiryId: "BESPOKE_145782135689523654",
     experienceName: "Member Request for Access",
     memberName: "Sandra L. Carder",
     clubName: "St. Morrie’s World Country Club",
     email: "sandracarder@teleworm.us",
     enquiredOn: "5159247235",
     status: "Requested",
   },
   {
     enquiryId: "BESPOKE_145782135689523654",
     experienceName: "Member Request for Access",
     memberName: "Sandra L. Carder",
     clubName: "Wise Horse Yacht Club",
     email: "sandracarder@teleworm.us",
     enquiredOn: "5159247235",
     status: "Requested",
   },
   // Add the remaining rows as shown in the image
 ];
 
 const columns = [
   {
     name: "Booking ID",
     selector: (row) => row.enquiryId,
     sortable: true,
     width: "250px",
   },
   {
     name: "Experience Name",
     selector: (row) => row.experienceName,
     sortable: true,
     width: "250px",
   },
   {
     name: "Member Name",
     selector: (row) => row.memberName,
     sortable: true,
     minWidth: "200px" ,
   },
   {
     name: "Club Name",
     selector: (row) => row.clubName,
     sortable: true,
     width: "250px",
   },
   {
     name: "Email",
     selector: (row) => row.email,
     sortable: true,
     width: "250px",
   },
   {
     name: "Enquired On",
     selector: (row) => row.enquiredOn,
     sortable: true,
     width: "150px",
   },
   {
     name: "Status",
     selector: (row) => row.status,
     sortable: true,
     width: "150px",
   },
 ];
 
 // Scroll to top    
 useEffect(() => {
     if (window) {
         window.scrollTo(0, 0);
     }
 }, []);

 // Custom styling for the table
 const customStyles = {
   table: {
     style: {
       borderRadius: "15px 15px 0px 0px",
       border: "1px solid var(--border-color)",
       overflow: "hidden",
     },
   },
   headCells: {
     style: {
       backgroundColor: "var(--light-blue)",
       justifyContent: "flex-start !important",
       color: "var(--dark-gray)",
       fontWeight: "400",
       fontSize: "15px",
     },
   },
   rows: {
     style: {
       height: "45px", // Set row height
       justifyContent: "start",
     },
   },
   cells: {
     style: {
       fontSize: '14px',
       justifyContent: "flex-start !important",
       borderRight: "1px solid var(--border-color)",
     },
   },
 };

 return (
   <div className="member-stats-section">
     <Drawer
       anchor={"right"}
       open={showDrawer}
       onClose={() => setShowDrawer(false)}
       className="member-stats-drawer"
       sx={{
         "& .MuiPaper-root": {
           padding: "30px 19px",
           height: "100vh",
           // overflow: "hidden"
         },
         "& .MuiDrawer-paper": {
           width: "420px",
         },
         "& .MuiBox-root": {
           width: "420px",
         },
         "& .MuiList-root": {
           display: "flex",
           columnGap: "10px",
           width: "90%",
           margin: "auto",
           paddingTop: "25px",
         },
         "& .MuiButtonBase-root": {
           padding: "7px",
           textAlign: "center",
           alignItems: "center",
           borderRadius: "10px",
           backgroundColor: "#F5F5F5",
         },
       }}
     >
       <div className="drawer-top-box">
         <div>Filter</div>
         <button
           type="button"
           class="btn-close"
           aria-label="Close"
           onClick={() => setShowDrawer(false)}
         ></button>
       </div>
       <div className="drawer-bottom-box mt-4">
         <Form onSubmit={(e) => e.preventDefault()}>            
           {/* Experience Name */}
           <div className="item">
             <Form.Group
               className="mb-3 d-flex align-items-center justify-content-between"
               controlId="exampleForm.ControlInput1em5"
             >
               <Form.Label>Experience Name</Form.Label>
               <div className="input-border">
                 <Form.Control
                   type="text"
                   className="input-field"
                   name="experienceName"
                 />
               </div>
             </Form.Group>
           </div>
           {/* Club Name */}
           <div className="item">
             <Form.Group
               className="mb-3 d-flex align-items-center justify-content-between"
               controlId="exampleForm.ControlInput1em5"
             >
               <Form.Label>Club Name</Form.Label>
               <div className="input-border">
                 <Form.Control
                   type="text"
                   className="input-field"
                   name="clubName"
                 />
               </div>
             </Form.Group>
           </div>                   
           <div
             className="submit-box d-flex align-items-end justify-content-end gap-3"
             style={{ height: "55vh" }}
           >
             <div
               className="btn-type-2 cursor-pointer"                
             >
               Clear
             </div>
             <button className="btn-type-1" type="submit" onClick={() => setShowDrawer(false)}>
               Submit
             </button>
           </div>
         </Form>
       </div>
     </Drawer>
     <div className="page-details">
       <div className="top-section d-flex align-items-center justify-content-between">
         <div className="left">Bespoke Bookings</div>
         <div className="right d-flex align-items-center  gap-3">
           <div
             className="filter-box d-flex align-items-center  gap-3 cursor-pointer"
             onClick={() => setShowDrawer(true)}
           >
             <FilterIcon />
             <span>Filter</span>
           </div>
         </div>
       </div>

       <div className="table-box mt-3">
         <DataTable
           columns={columns}
           data={data}
           customStyles={customStyles}
           fixedHeader
           highlightOnHover
           responsive
         />
       </div>
     </div>
   </div>
 );
};

export default BespokeBookings;
