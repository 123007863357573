import React from 'react'
import ChangePassword from '../../components/ResetPassword/ChangePassword'

const ChangePass = () => {
  return (
    <div  className='reset-password-page newpassword'>
        <ChangePassword/>
      
    </div>
  )
}

export default ChangePass
