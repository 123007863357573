import React from 'react'
import CreatePlaces from '../../components/CreatePlaces/CreatePlaces'

const CreatePlacesPage = () => {
  return (
    <div className='create-places-page'>
        <CreatePlaces />
    </div>
  )
}

export default CreatePlacesPage