import DataTable from "react-data-table-component";
import { useNavigate } from 'react-router-dom';
// Icon
import { CREATE_PUSH_NOTIFICATIONS_CATEGORY } from '../../constants/pathname';
import { useEffect } from "react";

const PushNotificationsCategory = () => { 
    const navigate = useNavigate();

    // Data for the table
    const data = [
        {
          club: "Offer Invitation",
          title: "Content testing is a user research method that eva",
          users: "Activated",
          count: 2036,
          sentOn: "4th Dec 2024 | 11:57:03 am",
        },
        {
          club: "Lahore Gymkhana",
          title: "privateclubworld",
          users: "Not Subscribed",
          count: 2,
          sentOn: "4th Dec 2024 | 11:57:03 am",
        },
        {
          club: "Lahore Gymkhana",
          title: "test 9",
          users: "Not Subscribed",
          count: 3,
          sentOn: "4th Dec 2024 | 11:57:03 am",
        },
        {
          club: "Lahore Gymkhana",
          title: "test 9",
          users: "Not Subscribed",
          count: 2,
          sentOn: "4th Dec 2024 | 11:57:03 am",
        },
        {
          club: "Lahore Gymkhana",
          title: "privateclubworld",
          users: "Activated",
          count: 2,
          sentOn: "4th Dec 2024 | 11:57:03 am",
        },
        {
          club: "Lahore Gymkhana",
          title: "test 9",
          users: "Activated",
          count: 3,
          sentOn: "4th Dec 2024 | 11:57:03 am",
        },
        {
          club: "Lahore Gymkhana",
          title: "test 9",
          users: "Activated",
          count: 3,
          sentOn: "4th Dec 2024 | 11:57:03 am",
        },
        {
          club: "Lahore Gymkhana",
          title: "test 9",
          users: "Not Subscribed",
          count: 2,
          sentOn: "4th Dec 2024 | 11:57:03 am",
        },
        {
          club: "Lahore Gymkhana",
          title: "test 9",
          users: "Not Subscribed",
          count: 3,
          sentOn: "4th Dec 2024 | 11:57:03 am",
        },
      ];
      
    // Define columns for the table
    const columns = [
        { name: "Category", selector: row => <div title={row.club}>{row.club}</div>, sortable: true, minWidth: "200px"  },
        { name: "Title", selector: row => <div title={row.title}>{row.title}</div>, sortable: true, width: "350px" },
        { name: "Users", selector: row => <div title={row.users}>{row.users}</div>, sortable: true, minWidth: "200px"  },
        { name: "Count", selector: row => row.count, sortable: true, minWidth: "100px"  },
        { name: "Sent On", selector: row => row.sentOn, sortable: true, minWidth: "300px"  },
      ];
    
    // Scroll to top    
    useEffect(() => {
        if (window) {
            window.scrollTo(0, 0);
        }
    }, []);

    // Custom styling for the table
    const customStyles = {
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",        
                border: "1px solid var(--border-color)",
                overflow: "hidden"
            },
    },
        headCells: {
        style: {
            backgroundColor: "var(--light-blue)",
            justifyContent: 'flex-start !important',
            color: "var(--dark-gray)",
            fontWeight: "400",
            fontSize: "15px",
        },
        },
        rows: {
        style: {
            height: "45px", // Set row height
            justifyContent: "start",
        },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    }; 

  return (
    <div className='member-stats-section brands'>       
        <div className='page-details'>
            <div className='top-section d-flex align-items-center justify-content-between'>
                <div className='left'>Push Notifications</div>
                <div className='right d-flex align-items-center  gap-3'>
                    <div className='btn-type-6 cursor-pointer' onClick={() => navigate(CREATE_PUSH_NOTIFICATIONS_CATEGORY, {state : {currentPageName: "Add Push Notification" }})} title="Send Push Notification">
                        Send Push Notification
                    </div>
                </div>
            </div>

            <div className='table-box mt-3'>
                <DataTable
                    columns={columns}
                    data={data}
                    customStyles={customStyles}
                    fixedHeader
                    highlightOnHover
                    responsive
                />
            </div>
        </div>
    </div>
  )
}

export default PushNotificationsCategory