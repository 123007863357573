import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Form } from 'react-bootstrap'
import { CLUB_ONBOARDING_LIST } from "../../constants/pathname";
import { getClubProfile } from "../../api/actions/clubProfile";

const ViewClubinfo = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { country } = location.state || {};
    const { id } = useParams()
    const [clubprofileDetails, setClubProfileDetails] = useState(null)
    useEffect(() => {
        if (id) {
            getClubProfile({  id, country:country })((response) => {
                if (response.status) {
                    setClubProfileDetails(response.data)
                }
            })

        }
    },[])

    return (
        <div className='create-clubs-info-section view-memebr-request'>
            <div className='page-details'>
                <div className='panel-type-1'>
                    View Club

                </div>
                <Form>
                    <div className='club-form mt-5'>
                        <div className='mt-5'>
                            <div className='row'>

                                <div className="col-xl-2 col-md-4 mb-3">
                                    <Form.Group controlId="clubName">
                                        <Form.Label>Club Name</Form.Label>
                                        <Form.Control
                                            type="text"

                                            placeholder="Club Name"
                                            name='club_name'
                                            value={clubprofileDetails?.club_name}
                                            disabled={true}
                                        />

                                    </Form.Group>
                                </div>
                                <div className='col-xl-2 col-md-4 '>
                                    <Form.Group controlId="clubName">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" placeholder="Email" name="email"
                                            value={clubprofileDetails?.email}
                                            disabled
                                        />


                                    </Form.Group>
                                </div>

                                <div className='col-xl-2 col-md-4 '>
                                    <Form.Group controlId="clubName">
                                        <Form.Label>Contact number</Form.Label>
                                        <Form.Control placeholder="Contact Number" name="contact_number"
                                            value={clubprofileDetails?.contact_number}
                                            disabled
                                        />

                                    </Form.Group>
                                </div>
                                <div className="col-xl-2 col-md-4 mb-4">
                                    <Form.Group controlId="city">
                                        <Form.Label>Prefix</Form.Label>
                                        <Form.Control type="text" placeholder="Prefix" name=""
                                            value={clubprofileDetails?.email}
                                            disabled

                                        />
                                    </Form.Group>
                                </div>
                                <div className='col-xl-2 col-md-4 '>
                                    <Form.Group controlId="clubName">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control type="text" placeholder="First Name" name="first_name"
                                            value={clubprofileDetails?.first_name}
                                            disabled

                                        />

                                    </Form.Group>
                                </div>
                                <div className='col-xl-2 col-md-4 '>
                                    <Form.Group controlId="clubName">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="text" placeholder="Last Name" name="last_name"
                                            value={clubprofileDetails?.last_name}
                                            disabled

                                        />

                                    </Form.Group>
                                </div>

                                <div className="col-xl-2 col-md-4 mb-4">
                                    <Form.Group controlId="city">
                                        <Form.Label>City<span className="required">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Email" name="email"
                                            value={clubprofileDetails?.city_name}
                                            disabled

                                        />
                                    </Form.Group>
                                </div>
                                <div className='col-xl-2 col-md-4 '>
                                    <Form.Group controlId="clubName">
                                        <Form.Label>Longitude</Form.Label>

                                        <Form.Control
                                            type="text"
                                            placeholder="Longitude"
                                            name="longitude"
                                            disabled
                                            value={clubprofileDetails?.longitude}

                                        />
                                        {/* <Form.Control.Feedback type="invalid">{formik?.errors?.club_name}</Form.Control.Feedback> */}

                                    </Form.Group>
                                </div>
                                <div className="col-xl-2 col-md-4 mb-4">
                                    <Form.Group controlId="country">
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Country"
                                            name="country"
                                            value={clubprofileDetails?.country_name}
                                            disabled
                                        />
                                    </Form.Group>
                                </div>
                                <div className='col-xl-2 col-md-4 '>
                                    <Form.Group controlId="clubName">
                                        <Form.Label>Latitude</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Latitude"
                                            name="latitude"
                                            value={clubprofileDetails?.latitude}
                                            disabled
                                        />
                                        {/* <Form.Control.Feedback type="invalid">{formik?.errors?.club_name}</Form.Control.Feedback> */}

                                    </Form.Group>
                                </div>
                                <div className='col-xl-2 col-md-4 '>
                                    <Form.Group controlId="clubName">
                                        <Form.Label>Zip Code</Form.Label>
                                        <Form.Control type="text" placeholder="Zip Code" name="zip_code"
                                            value={clubprofileDetails?.zip_code}
                                            disabled
                                        />

                                    </Form.Group>
                                </div>
                                <div className="col-xl-2 col-md-4 mb-4">
                                    <Form.Group controlId="city">
                                        <Form.Label>Optional Service</Form.Label>
                                        <Form.Control type="text" placeholder="Optional Service" name="email"
                                            value={clubprofileDetails?.email}
                                            disabled
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-xl-4 col-md-4 mb-4">
                                    <Form.Group controlId="address2">
                                        <Form.Label>Address  </Form.Label>
                                        <Form.Control as="textarea" placeholder="Address" name="primary_address" rows={3} value={clubprofileDetails?.primary_address}

                                            disabled />
                                    </Form.Group>
                                </div>
                                <div className="col-xl-4 col-md-4 mb-4">
                                    <Form.Group controlId="address2">
                                        <Form.Label>Summary  </Form.Label>
                                        <Form.Control as="textarea" placeholder="Summary" name="primary_address" rows={3} value={clubprofileDetails?.primary_address}

                                            disabled />
                                    </Form.Group>
                                </div>
                                <div className="col-xl-2 col-md-4 mb-4">
                                    <Form.Group controlId="city">
                                        <Form.Label>Account Status</Form.Label>
                                        <Form.Control type="text" placeholder="Optional Service" name="email"
                                            value={clubprofileDetails?.email}
                                            disabled
                                        />
                                    </Form.Group>
                                </div>


                            </div>
                            <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-5'>
                                <div className='btn-type-2 cursor-pointer' onClick={() => navigate(CLUB_ONBOARDING_LIST)}>Back</div>


                            </div>


                        </div>

                    </div>
                </Form>

            </div>

        </div>
    );
};

export default ViewClubinfo;
