import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const Agreement = ({ setValue,formik }) => {
    const [isChecked, setIsChecked] = useState(false);
    const cellStyle = {
        border: "1px solid black",
        padding: "10px",
        textAlign: "justify"
    }
    return (
        <div>
            <h3 className="mb-3" style={{ fontSize: "20px", fontWeight: "400", lineHeight: "25px" }}>
                Agreement
            </h3>
            <Form
                style={{
                    border: "1px solid #0673dc",
                    padding: "20px",
                    borderRadius: "5px",
                    background: "transparent",
                    minHeight: "85vh",
                    display: "flex",
                    flexDirection: "column"
                }}
            >

                <h5 className="text-uppercase" style={{ fontSize: "15px", fontWeight: "500" }}>
                    Club Name :{formik.values.club_name}
                </h5>
                <p>Please read and accept the license agreement before continuing
                </p>
             <hr/>

                <div
                    style={{
                        height: "65vh",
                        overflowY: "auto",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        backgroundColor: "#fff",
                        marginBottom: "15px",
                        fontSize: "11px"

                    }}
                >
                    <p style={{ textAlign: "center" }}><strong>PLATINUMCLUBNET LICENSE AGREEMENT</strong></p>
                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>This Agreement is executed between MobiCom Platinum Partners Inc, a Delaware corporation located at 6010 W Spring Creek Parkway, Plano, Texas 75024 (referred to in this Agreement as “</span><strong>MobiCom</strong><span style={{ fontWeight: "400" }}>”), and the licensee, who is a registered legal entity (referred to in this Agreement as “</span><strong>Club</strong><span style={{ fontWeight: "400" }}>”). MobiCom and the Club may be collectively referred to as ‘</span><strong>Parties</strong><span style={{ fontWeight: "400" }}>’ and individually as ‘</span><strong>Party</strong><span style={{ fontWeight: "400" }}>’.</span>&nbsp;</p>
                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>The Agreement is executed between the Parties on the date of acceptance of this Agreement by clicking on the “I accept” button below, by the Club. In case the Club does not want to execute this Agreement, the Club can indicate its decision by clicking on the “I do not accept” button below.</span>&nbsp;</p>
                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>MobiCom has established a product platform known as “PlatinumClubNet™” whereby, </span><em><span style={{ fontWeight: "400" }}>inter alia </span></em><span style={{ fontWeight: "400" }}>the Club and the members of the Club can interact, transact and avail the Primary Benefits Program </span><em><span style={{ fontWeight: "400" }}>(defined below)</span></em><span style={{ fontWeight: "400" }}> offered by MobiCom through its partner network of brands, corporations and other entities (“</span><strong>PlatinumClubNet</strong><span style={{ fontWeight: "400" }}>”). Additionally, MobiCom also offers the Club an opportunity to increase its visibility and explore revenue opportunities through the Optional Services Programs (</span><em><span style={{ fontWeight: "400" }}>defined below). </span></em><span style={{ fontWeight: "400" }}>The Primary Benefits Program and Optional Services Programs are being made available through the Licensed Software (</span><em><span style={{ fontWeight: "400" }}>defined below).</span></em>&nbsp;</p>
                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>This Agreement is to set out the terms and conditions of the aforementioned understanding.</span></p>
                    <p style={{ textAlign: "justify" }}>&nbsp;1.&nbsp;<strong><strong>TERMS AND CONDITIONS</strong></strong>&nbsp;</p>
                    <ol style={{ listStyleType: "lowerAlpha", textAlign: "justify" }}>
                        <li style={{ paddingLeft: "30px", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>“</span><strong>Primary Benefits</strong> <strong>Program</strong><span style={{ fontWeight: "400" }}>” shall mean the ‘Global Platinum Club Access Program’ which is more fully described in </span><strong>Annexure I</strong><span style={{ fontWeight: "400" }}> herein;</span></li>
                        <li style={{ paddingLeft: "30px", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>“</span><strong>CMS</strong><span style={{ fontWeight: "400" }}>” shall mean the Club Management System on PlatinumClubNet, licensed by MobiCom to the Club and as part of the Licensed Software;&nbsp;</span></li>
                        <li style={{ paddingLeft: "30px", textAlign: "justify" }}><strong>“Curate New Events Program” </strong><span style={{ fontWeight: "400" }}>shall mean the Optional Services Program </span><span style={{ fontWeight: "400" }}>which may be availed by the Club, more fully </span><span style={{ fontWeight: "400" }}>described in </span><strong>Part I</strong><span style={{ fontWeight: "400" }}> of </span><strong>Annexure II </strong><span style={{ fontWeight: "400" }}>herein;</span></li>
                        <li style={{ paddingLeft: "30px", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>“</span><strong>Employee</strong><span style={{ fontWeight: "400" }}>” or “</span><strong>Employees</strong><span style={{ fontWeight: "400" }}>” shall mean the employee(s) of the Club;</span></li>
                        <li style={{ paddingLeft: "30px", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>“</span><strong>EULA</strong><span style={{ fontWeight: "400" }}>”</span> <span style={{ fontWeight: "400" }}>shall mean the end user license agreement governing the licensing and the terms of use of the Licensed Software that is to be accepted by each Member so as to gain access to PlatinumClubNet.</span>&nbsp;</li>
                        <li style={{ paddingLeft: "30px", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>“</span><strong>Intellectual Property Rights</strong><span style={{ fontWeight: "400" }}>” shall mean patents, utility models, rights to inventions, copyright and neighboring and related rights, trademarks and service marks, business names and domain names, rights in designs, database rights, rights to use and protect the confidentiality of, confidential information (including know-how and trade secrets), and all other intellectual property rights, in each case whether registered or unregistered, and including all applications and rights to apply for and be granted, renewals or extensions of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist now or in the future in any part of the world, including but not limited to: (i) research and development or investigations; and (ii) product offerings, content partners, product pricing, product availability, technical drawings, algorithms, processes, ideas, techniques, formulas, data, schematics, trade secrets, know-how, improvements, marketing plans, forecasts;</span></li>
                        <li style={{ paddingLeft: "30px", textAlign: "justify" }}>“<strong>Licensed Software</strong><span style={{ fontWeight: "400" }}>” shall mean the software owned by MobiCom and which is being licensed under this Agreement to the Club and to its Members, including the Web Portal, Mobile App, CMS and the Staff Function App along with all licenses relating to the same, Updates and Upgrades, underlying software and related materials, including but not limited to know-how, source code, object code, associated documents and other printed or visually perceptible materials describing the use or design of the Licensed Software;</span></li>
                        <li style={{ paddingLeft: "30px", textAlign: "justify" }}>“<strong>Member</strong><span style={{ fontWeight: "400" }}>” or “</span><strong>Members</strong><span style={{ fontWeight: "400" }}>” shall mean the member(s) of the Club who are users of PlatinumClubNet;</span></li>
                        <li style={{ paddingLeft: "30px", textAlign: "justify" }}>“<strong>Mobile App</strong><span style={{ fontWeight: "400" }}>” shall mean the PlatinumClubNet mobile application which is part of the Licensed Software licensed to the Club and will be further licensed to the Members to enable them to access PlatinumClubNet;&nbsp;</span></li>
                        <li style={{ paddingLeft: "30px", textAlign: "justify" }}>“<strong>Materials</strong><span style={{ fontWeight: "400" }}>” shall mean information, content, databases, trademarks, brand details etc. belonging to a Party to this Agreement;</span></li>
                        <li style={{ paddingLeft: "30px", textAlign: "justify" }}><strong><strong>“Optional Services Programs”</strong><span style={{ fontWeight: "400" }}> shall mean the </span><span style={{ fontWeight: "400" }}>Curate New Events Program,</span> <span style={{ fontWeight: "400" }}>Current Club Events Program, Private Event Program and</span> <span style={{ fontWeight: "400" }}>Promote Program,</span> <span style={{ fontWeight: "400" }}>each of which may be availed by the Club, and which are more fully detailed in </span><strong>Annexure II</strong><span style={{ fontWeight: "400" }}> herein;</span></strong></li>
                        <li style={{ paddingLeft: "30px", textAlign: "justify" }}><strong>“Promote Program” </strong><span style={{ fontWeight: "400" }}>shall mean the Optional Services Program which may be availed by the Club more fully described in </span><strong>Part IV of Annexure II </strong><span style={{ fontWeight: "400" }}>herein;&nbsp;</span></li>
                        <li style={{ paddingLeft: "30px", textAlign: "justify" }}><strong>“Private Event</strong> <strong>Program” </strong><span style={{ fontWeight: "400" }}>shall mean the Optional Services Program which may be availed by the Club more fully described in </span><strong>Part III of Annexure II </strong><span style={{ fontWeight: "400" }}>herein;</span></li>
                        <li style={{ paddingLeft: "30px", textAlign: "justify" }}><strong>“Current Club Events</strong> <strong>Program” </strong><span style={{ fontWeight: "400" }}>shall mean the Optional Services Program which may be availed by the Club more fully described in </span><strong>Part II of Annexure II </strong><span style={{ fontWeight: "400" }}>herein;</span></li>
                        <li style={{ paddingLeft: "30px", textAlign: "justify" }}><strong>“Staff Function App” </strong><span style={{ fontWeight: "400" }}>shall mean the PlatinumClubNet mobile application which is part of the Licensed Software licensed to the Club to enable the Employees to transact in real time on PlatinumClubNet;</span></li>
                        <li style={{ paddingLeft: "30px", textAlign: "justify" }}>“<strong>Update</strong><span style={{ fontWeight: "400" }}>” shall mean a generally available update release of the Licensed Software which will be available as and when required;</span></li>
                        <li style={{ paddingLeft: "30px", textAlign: "justify" }}>“<strong>Upgrade</strong><span style={{ fontWeight: "400" }}>” shall mean a generally available release of the same Licensed Software which MobiCom makes available from time to time that includes (i) new editions of the Licensed Software that provide major enhancements to the features or functions, as paid for by the Club and as determined by the MobiCom at its sole discretion; and/or, (ii) new editions of the Licensed Software that provide additional features or perform additional functions;</span></li>
                        <li style={{ paddingLeft: "30px", textAlign: "justify" }}><strong>“USD” </strong><span style={{ fontWeight: "400" }}>shall mean US Dollars, the lawful currency of the United States of America;</span></li>
                        <li style={{ paddingLeft: "30px", textAlign: "justify" }}>“<strong>Web Portal</strong><span style={{ fontWeight: "400" }}>” shall mean the PlatinumClubNet web application software of the Club as an interface to the Mobile App which is part of the Licensed Software.</span></li>
                    </ol>
                    <p style={{ textAlign: "justify" }}>2.&nbsp;<strong>SCOPE:</strong><span style={{ fontWeight: "400" }}>&nbsp;</span></p>
                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>2.1 Subject to this Agreement, MobiCom hereby grants a non-exclusive, revocable, non-transferable, worldwide license of the Licensed Software to the Club (“</span><strong>License</strong><span style={{ fontWeight: "400" }}>”).</span></p>
                    <p style={{ textAlign: "justify" }}><strong>2.2 Maintenance Services:</strong><span style={{ fontWeight: "400" }}> MobiCom may at its sole discretion provide the Club with Update and Upgrade versions of the Licensed Software. Updates and Upgrades shall be part of the Licensed Software and be subject to the terms and conditions of this Agreement. In case of any defects or malfunctions in the Licensed Software, MobiCom will provide the Club with a corrected version of the same, without additional charge.&nbsp;</span>&nbsp;</p>
                    <p style={{ textAlign: "justify" }}><strong>2.3 Primary Benefits Program: </strong><span style={{ fontWeight: "400" }}>MobiCom shall provide the Primary Benefits Program as described in, and on the terms and conditions detailed in, </span><strong>Annexure I </strong><span style={{ fontWeight: "400" }}>herein. Any information, materials, artwork, logo etc. provided for use in the Primary Benefits Program shall form a part of Materials under this Agreement. </span><span style={{ fontWeight: "400" }}>The provision of the Primary Benefits Program is subject to the Club providing MobiCom with such information as requested on the CMS and any other additional information as may be necessary for the purpose of rendering the service.&nbsp;</span></p>
                    <p style={{ textAlign: "justify" }}><strong>2.4 Optional Services Programs:</strong><span style={{ fontWeight: "400" }}> The Club may avail of any of the Optional Services Programs described in, and on the terms and conditions detailed in </span><strong>Annexure II</strong><span style={{ fontWeight: "400" }}> herein, by exercising that option on the CMS. The provision of any Optional Services Program shall be subject to the approval of MobiCom, and completion of a due qualification process (if any). </span><span style={{ fontWeight: "400" }}>The provision of the Optional Services Programs is subject to the Club providing MobiCom with such information as requested on the CMS and any other additional information as may be necessary for the purpose of rendering the service. </span><span style={{ fontWeight: "400" }}>At the time of availing any Optional Services Programs, such additional terms and conditions, including in relation to fees or charges payable, may be applicable as mutually agreed upon by the Parties. The Club may opt out of such Optional Services Programs by exercising that option on the CMS. Any information, materials, artwork, logo etc. provided for use in the Optional Services Programs shall form a part of Materials under this Agreement.&nbsp;</span></p>
                    <p style={{ textAlign: "justify" }}><strong>3.LICENSE RIGHTS AND LIMITATIONS</strong><span style={{ fontWeight: "400" }}>: Subject to the compliance with the terms of this Agreement, the Club is granted a non-exclusive, revocable, non-transferable, worldwide license to the Licensed Software. This is a limited license for installation and use of the Licensed Software and except as otherwise specifically provided under this Agreement the Club shall not distribute, copy, decompile, reverse engineer, disassemble, modify, resell, or attempt to derive the source code of, decrypt or create derivative works of the Licensed Software.</span></p>
                    <p style={{ textAlign: "justify" }}><strong>4. RIGHTS TO THE CLUB’S MATERIALS</strong><span style={{ fontWeight: "400" }}>: The Club shall retain all rights in and to the Materials provided by the Club to MobiCom. The Club verifies and confirms that the same is owned and/or licensed by the Club and has been assigned to MobiCom for its use in the Primary Benefits Program and/or Optional Services Programs, and for advertisement and promotional offers. The Club further confirms the receipt of adequate permissions and licenses for the assignment of its Materials to MobiCom. Additionally, MobiCom may use the name/trademark of the Club in the advertising and promotional material on its web sites or any other marketing collateral. MobiCom undertakes to use the same in a manner that is responsible and not misleading or deceitful.</span></p>
                    <p style={{ textAlign: "justify" }}><strong>5. PLATINUMCLUBNET</strong><span style={{ fontWeight: "400" }}>:&nbsp;</span></p>
                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>5.1 PlatinumClubNet shall be made available to the Members through the Mobile App/Web Portal. Access to the PlatinumClubNet is optional and the Members may choose not to opt for the same on their Mobile App/Web Portal.&nbsp;</span></p>
                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>5.2 Should the Members choose to access PlatinumClubNet through the Licensed Software, MobiCom shall conduct a verification of the Members in a manner it deems fit. All information uploaded for the verification process as well as otherwise by the Members will be processed in line with MobiCom’s privacy policy which will be made available to the Members prior to installation of the Licensed Software.</span></p>
                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>5.3 PlatinumClubNet shall be made available to the Employees through the Staff Function App. All information uploaded by the Employees will be processed in line with MobiCom’s privacy policy which will be made available to the Employees prior to installation of the Licensed Software.</span></p>
                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>5.4 The Members/Employees use of PlatinumClubNet shall be subject to MobiCom’s ‘terms of use’ and EULA which will be made available to the Members and Employees prior to installation of the Licensed Software. In case of breach of such terms, the Club shall be liable to indemnify MobiCom in a manner detailed in this Agreement.&nbsp;</span></p>
                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>5.5 In the event that a Member or Employee breaches any rules or regulations laid down by (a) a club that forms part of PlatinumClubNet, or (b) the terms and conditions of use of PlatinumClubNet; MobiCom shall have the option, at its sole discretion, to disqualify such Member or Employee from using the Licensed Software.&nbsp;</span></p>
                    <p style={{ textAlign: "justify" }}><strong>6. INSTALLATION AND MODERATION</strong><span style={{ fontWeight: "400" }}>: Once installed, the Licensed Software shall be managed and moderated solely by MobiCom. The Primary Benefits Program and each Optional Services Program opted for by the Club shall form a part of PlatinumClubNet and be managed by MobiCom based on the specifications indicated by the Club, in accordance with the terms and conditions laid down in </span><strong>Annexure I </strong><span style={{ fontWeight: "400" }}>and</span><strong> Annexure II </strong><span style={{ fontWeight: "400" }}>respectively, and such other conditions as may mutually be agreed upon by the Parties.&nbsp;</span></p>
                    <p style={{ textAlign: "justify" }}><strong><strong>7. FEES&nbsp;</strong></strong></p>
                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>7.1 Fees and terms of payment for the Primary Benefits Program, and License will be as detailed in </span><strong>Part I</strong><span style={{ fontWeight: "400" }}> of </span><strong>Annexure III </strong><span style={{ fontWeight: "400" }}>herein.</span></p>
                    <p style={{ textAlign: "justify" }}><strong><span style={{ fontWeight: "400" }}>7.2 For each Optional Services Program availed of by the Club, the Club shall pay the fees and charges as detailed in </span><strong>Part II</strong><span style={{ fontWeight: "400" }}> of </span><strong>Annexure III </strong><span style={{ fontWeight: "400" }}>herein</span><strong>. </strong><span style={{ fontWeight: "400" }}>It being clarified that the fees payable for each Optional Services Program may be revised at the time of availing of the Optional Services Program upon mutual agreement of the Parties.</span></strong></p>
                    <p style={{ textAlign: "justify" }}><strong><span style={{ fontWeight: "400" }}>7.3 All such fees and payments will have to be made either through credit card, wire transfer or net banking, at the instruction of MobiCom.&nbsp;</span></strong></p>
                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>7.4 The Parties may agree on such additional fees required for provision of any additional services by MobiCom.&nbsp;</span></p>
                    <p style={{ textAlign: "justify" }}><strong><span style={{ fontWeight: "400" }}>7.5 All fees, charges etc., payable to MobiCom shall be non-refundable in all circumstances.</span></strong></p>
                    <p style={{ textAlign: "justify" }}><strong><strong>8. INTELLECTUAL PROPERTY RIGHTS</strong></strong></p>
                    <p style={{ textAlign: "justify" }}><strong>8.1 Reservation of Rights:</strong><span style={{ fontWeight: "400" }}> The Licensed Software and all Intellectual Property Rights embodied therein, shall be the sole and exclusive property of MobiCom, subject to any Materials provided by the Club. Except for the rights expressly set forth in this Agreement, the Club is not permitted to modify or otherwise make derivative works of the Licensed Software, other than as specifically indicated in this Agreement. Any such unauthorized works developed by the Club, and any Intellectual Property Rights embodied therein, shall be the sole and exclusive property of MobiCom.&nbsp;</span></p>
                    <p style={{ textAlign: "justify" }}><strong>8.2 Protection of Rights:</strong><span style={{ fontWeight: "400" }}> The Club shall not copy, translate, disassemble, decompile, create or attempt to create the source code from the object code of the Licensed Software in any manner. Reverse engineering of the Licensed Software is prohibited. The Club is permitted to back up data in accordance with good information technology practice. The Club must not change or remove MobiCom’s copyright and authorship notices.</span></p>
                    <p style={{ textAlign: "justify" }}><strong><strong>8.3 Updates/Upgrades:</strong></strong></p>
                    <p style={{ paddingLeft: "30px", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>8.3.1 The Club shall not make any updates and/or upgrades to the Licensed Software on its own. All Updates and/or Upgrades developed by MobiCom (either independently or jointly with the Club) and all rights associated therewith shall be the exclusive property of Licensed Software. The Club agrees to execute those documents reasonably necessary to secure MobiCom’s rights in the foregoing.&nbsp;</span></p>
                    <p style={{ paddingLeft: "30px", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>8.3.2 The Club shall not make any modification or add-on on its own without MobiCom’s participation and/or (subject to other limitations set forth herein) enable the bypassing or circumventing any of the restrictions set forth in this Agreement; nor permit mass data extraction from the Licensed Software to any non-MobiCom software, including use, modification saving or other processing of data in the non-MobiCom software; nor unreasonably impair, degrade or reduce the performance or security of the Licensed Software; nor render or provide any information concerning the Licensed Software or any other information related to MobiCom’s products.</span></p>
                    <p style={{ textAlign: "justify" }}><strong>8.4 Copyrighted Materials:</strong><span style={{ fontWeight: "400" }}> The copyrights in all text, images, software codes and all other content/materials vests solely with MobiCom, with the exception of the Materials provided by the Club. The copyrights in all Materials provided by the Club shall vest solely with the Club. Except as provided herein, neither Party shall copy, distribute, display or transmit the other Party’s Materials in any form or by any means without prior written permission from the other Party. Unauthorized use of any of the other Party’s copyrighted materials may violate copyright laws, trademarks laws and/or other regulations and statues.</span></p>
                    <p style={{ textAlign: "justify" }}><strong>8.5 Trademarks:</strong><span style={{ fontWeight: "400" }}> Trademarks and service marks that do not form a part of the Materials and used anywhere in the Licensed Software vests with MobiCom or the respective owners or such trademarks. Trademarks forming part of the Materials shall vest with the Club or the owner of such trademark. Nothing in this Agreement should be construed as granting, by implication, estoppels or otherwise any license to use any trademark without the written permission of the Party who owns such trademark. The name and logo of both the Parties shall be not used in any way by the other Party or its associates, including in advertising or publicity pertaining to distribution of any materials by the other Party or its affiliates without the written permission of the Party who owns such trademark.</span></p>
                    <p style={{ textAlign: "justify" }}><strong><strong>8.6 Promotional Material: </strong><span style={{ fontWeight: "400" }}>The Club shall have the right to advertise the Primary Benefits Program, the Optional Services Programs and the Licensed Software with third parties. Any such promotional material shall require the prior approval of MobiCom, and the Club and its associates shall not use the name and logo of MobiCom in any way without the written permission of MobiCom.</span></strong></p>
                    <p style={{ textAlign: "justify" }}>9.&nbsp;<strong>DISCLAIMER OF WARRANTY</strong><span style={{ fontWeight: "400" }}>: The Licensed Software is licensed, and the Primary Benefits Program and the Optional Services Programs are provided “as-is.” The Club bears all risk of using it. MobiCom provides no express warranties, guarantees or conditions. The Club may have additional consumer rights under its local laws that this Agreement cannot change. To the extent permitted under the Club’s local laws, MobiCom excludes the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</span></p>
                    <p style={{ textAlign: "justify" }}><strong>10. LIMITATION ON AND EXCLUSION OF REMEDIES AND DAMAGES</strong><span style={{ fontWeight: "400" }}>: The Club cannot recover any damages, including consequential, lost profits, special, indirect or incidental damages from MobiCom with the exception of direct damages which has been incurred by the Club solely due to the acts, omissions, misrepresentations and/or breach of any of the terms hereof by MobiCom or its affiliates. This limitation applies to: anything related to the Licensed Software, the Primary Benefits Program, the Optional Services Programs, services, content (including code) on third party internet sites, or third-party programs to the extent permitted by applicable law.</span></p>
                    <p style={{ textAlign: "justify" }}><strong>11. TERM AND TERMINATION&nbsp;</strong></p>
                    <p style={{ textAlign: "justify" }}><strong>11.1 Term: </strong><span style={{ fontWeight: "400" }}>This Agreement shall come into force on its execution and shall automatically expire upon the completion of 2 (two) years from the date of execution of this Agreement, unless terminated by either Party in accordance with Clause 11.2 herein.</span></p>
                    <p style={{ textAlign: "justify" }}><strong>11.2 Termination:</strong><span style={{ fontWeight: "400" }}> This Agreement may be terminated:</span></p>
                    <ol style={{ listStyleType: "lowerRoman", textAlign: "justify" }}>
                        <li><span style={{ fontWeight: "400" }}>By either Party by providing ninety (90) days written notice to the other;</span></li>
                        <li><span style={{ fontWeight: "400" }}>By MobiCom forthwith in case of any material breach by the Club of any of its obligations herein;</span></li>
                        <li><span style={{ fontWeight: "400" }}>By MobiCom forthwith if the Club loses its “Platinum Club” status.&nbsp;</span></li>
                    </ol>
                    <p style={{ textAlign: "justify" }}><strong>11.3 Effect of Termination: </strong><span style={{ fontWeight: "400" }}>Upon termination or expiry (whichever being earlier) of this Agreement, the remainder period of any annual fees paid by the Club stands forfeited and MobiCom will not provide a refund of any amounts paid by the Club. The Club shall be solely responsible for any claims made by Members or Employees who use the Licensed Software. Upon termination, the Licensed Software including the Mobile App, Web Portal, CMS, and the Staff Function App will be withdrawn; and the Primary Benefits Program and any Optional Services Programs availed of shall be withdrawn.</span></p>
                    <p style={{ textAlign: "justify" }}><strong>12. INDEMNIFICATION AND INFRINGEMENT</strong><span style={{ fontWeight: "400" }}>: In case of any losses, liabilities, costs or damages (including attorneys’ fees) incurred by MobiCom attributable to the Club’s and/or Members’ and/or Employees’ use of the Licensed Software, the Primary Benefits Program and/or the Optional Services Programs, the Club agrees to fully indemnify, defend and hold MobiCom, its affiliates, and employees harmless from and against the same.&nbsp;</span></p>
                    <p style={{ textAlign: "justify" }}><strong>13. REPRESENTATIONS AND WARRANTIES</strong><span style={{ fontWeight: "400" }}>: Each Party represents and warrants that: i) it has the right and authority to enter into this Agreement and to grant the other Party all rights specified herein; and ii) all Materials furnished by it as well as all content that it may publish in the Licensed Software or as part of the Primary Benefits Program and/or Optional Services Program, in the present or future is owned and controlled by it and shall not infringe on any Intellectual Property Rights of any person or entity.&nbsp; Additionally, the Club represents and warrants that i) it shall give MobiCom access to data which is essential in the use, operation, up-gradation or updating of the Licensed Software; and ii) it has all rights to any information provided to MobiCom pertaining to the end user, which may be personal or sensitive in nature.</span></p>
                    <p style={{ textAlign: "justify" }}><strong>14. PRIVACY</strong><span style={{ fontWeight: "400" }}>: The Materials provided by the Club and transmitted to MobiCom under this Agreement shall be subject to the Club’s standard privacy policy and the transmission of any data to MobiCom shall be construed as the transmission of such data with adequate approvals and licenses. Any data independently collected by MobiCom from third party sources shall be retained, applied and monitored as per MobiCom’s privacy policy guidelines and applicable laws. In the event MobiCom collects some information or data from the users, then MobiCom will treat it as it treats its own customer information and in compliance with its applicable privacy policies.</span></p>
                    <p style={{ textAlign: "justify" }}><strong>15. GOVERNING LAW AND DISPUTES: </strong><span style={{ fontWeight: "400" }}>The validity, construction, interpretation, and enforcement of this Agreement shall be governed and construed solely in accordance with the laws of the state of Delaware, United States of America, without giving effect to any conflict of law principles. The courts located in Delaware shall have sole and exclusive jurisdiction over any disputes arising out of this Agreement.&nbsp; The Club waives any right to request a change of venue based on inconvenience. The Parties agree to first attempt to resolve any dispute by mediation, and then if required, by arbitration. Both Parties hereby agree that MobiCom shall be entitled to seek immediate injunctive relief on any disputes or differences arising from this Agreement. Such injunctive relief shall be in addition to any other remedies available, whether at law or in equity. MobiCom shall be entitled to recover its costs and fees, including reasonable attorneys’ fees, incurred in obtaining any such relief.&nbsp;</span></p>
                    <p style={{ textAlign: "justify" }}><strong><strong>16. MISCELLANEOUS</strong></strong></p>
                    <p style={{ textAlign: "justify" }}><strong>16.1 Entire Agreement:</strong><span style={{ fontWeight: "400" }}> This Agreement constitutes the final, complete, and exclusive understanding and agreement between the Parties, relating to the subject matter hereof and supersedes and terminates all prior or contemporaneous understandings, agreements, and communications, and/or advertising with respect to such subject matter. This Agreement cannot be amended, modified, or waived, unless done so in writing and signed by an authorized representative on behalf of MobiCom. The Agreement and any signed agreement or instrument entered into in connection herewith or contemplated hereby, and any amendments hereto or thereto, to the extent signed and delivered by means of digital imaging, electronic mail or a facsimile machine, shall be treated in all manner and respect as an original signed version thereof delivered in person.</span></p>
                    <p style={{ textAlign: "justify" }}><strong>16.2 Successors and Assigns: </strong><span style={{ fontWeight: "400" }}>The rights and obligations of the Club shall not be transferable without the prior written consent of MobiCom. All obligations of the parties herein shall be binding upon their respective successors or assigns.</span></p>
                    <p style={{ textAlign: "justify" }}><strong>16.3 Severability:</strong><span style={{ fontWeight: "400" }}> If any term or other provision of the Agreement is determined to be invalid, illegal or incapable of being enforced by any rule or law of Delaware, all other conditions and provisions of the Agreement shall nevertheless remain in full force and effect.&nbsp; Moreover, upon such determination that any term or other provision is invalid, illegal, or incapable of being enforced under Delaware law, such term or provision shall be deemed replaced by a term or provision that is valid and enforceable and that as closely as legally interpreted, expresses the intention of the invalid, illegal or unenforceable term or provision.</span></p>
                    <p style={{ textAlign: "justify" }}><strong>16.4 Survival:</strong><span style={{ fontWeight: "400" }}> Provisions herein, which by their nature extend beyond termination of the Agreement, will remain in effect until fulfilled.</span></p>
                    <p style={{ textAlign: "justify" }}><strong>16.5 Waiver: </strong><span style={{ fontWeight: "400" }}>No waiver or breach of any term or condition of this </span><span style={{ fontWeight: "400" }}>Agreement </span><span style={{ fontWeight: "400" }}>shall operate as a waiver of any other breach of such term or condition, or of any other term or condition, nor shall any failure to enforce any provisions hereunder operate as a waiver of such provision or any other provision hereunder.</span></p>
                    <p style={{ textAlign: "justify" }}><strong><em><strong>16.6 Notices:</strong> <span style={{ fontWeight: "400" }}>All notices and other communications required or permitted under this </span><span style={{ fontWeight: "400" }}>Agreement </span><span style={{ fontWeight: "400" }}>or required by law shall be in writing and shall be deemed to have been duly given only upon receipt of the Parties at their respective addresses as any Party shall have specified by notice to the other.&nbsp;</span></em></strong></p>
                    <p style={{ textAlign: "justify" }}><strong>16.7 Independent Contractor: </strong><span style={{ fontWeight: "400" }}>The Club acknowledges that the relationship between the MobiCom and the Club is that of independent contractors. This Agreement shall not be deemed to create a partnership or joint venture, and neither party is the other’s agent, partner or employee.</span></p>
                    <p style={{ textAlign: "justify" }}><strong>16.8 Language: </strong><span style={{ fontWeight: "400" }}>The present Agreement has been originally drafted in English language. In the event of translation into another language, the English version shall always be the sole legal and binding version between the Parties and shall prevail among any others. Furthermore, the English language version shall also prevail in any documentation produced in relation to this Agreement.&nbsp;</span></p>
                    <p style={{ textAlign: "justify" }}><strong>16.9 Force Majeure</strong><span style={{ fontWeight: "400" }}>: Neither Party shall be liable to the other for any delays, suspensions, damages, or failure to perform any of the obligations under this Agreement due to, caused by, or occasioned by reason of Force Majeure. Force Majeure shall mean any circumstance or event beyond the reasonable control and foresight of the Party unable to perform and which could not have been prevented or avoided by the exercise of due diligence, prudence, or the adoption of reasonable precautions. During an event of Force Majeure, the Parties’ duty to perform obligations shall be suspended.&nbsp;</span></p>
                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>16.10 The Parties consent and agree that this Agreement may be signed in counterparts, and may, consistent with the Delaware </span><span style={{ fontWeight: "400" }}>Uniform Electronic Transactions Act,</span><span style={{ fontWeight: "400" }}> be signed electronically, and that such counterpart and electronic execution shall be enforceable for all lawful purposes.&nbsp;</span></p>
                    <p style={{ textAlign: "center" }}><strong>ANNEXURE I</strong></p>
                    <p style={{ textAlign: "center" }}><strong>PRIMARY BENFITS PROGRAM</strong>&nbsp;</p>
                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>The </span><span style={{ fontWeight: "400" }}> Global Platinum Club Access Program </span><span style={{ fontWeight: "400" }}>offers Members of the Club the opportunity to request access to other Platinum Clubs of America, Platinum Clubs of Europe, Platinum Clubs of Asia-Pacific and Platinum Clubs of the World (a “</span><strong>Platinum Club</strong><span style={{ fontWeight: "400" }}>”) subject to the acceptance of such a request by the receiving club, at its sole discretion.&nbsp;</span></p>
                    <p><strong>Inclusions:&nbsp;&nbsp;</strong></p>
                    <ul>
                        <li style={{ fontWeight: "400" }}><span style={{ fontWeight: "400" }}>PlatinumClubNet™ CMS;&nbsp;</span></li>
                        <li style={{ fontWeight: "400" }}><span style={{ fontWeight: "400" }}>PlatinumClubNet™ Mobile App / Web Portal for Members;</span></li>
                        <li style={{ fontWeight: "400" }}><span style={{ fontWeight: "400" }}>PlatinumClubNet™ Staff Function App for Club Employees;</span></li>
                        <li style={{ fontWeight: "400" }}><span style={{ fontWeight: "400" }}>Customer support through a 24*7 Concierge service.</span></li>
                    </ul>
                    <p><strong>Other Terms:</strong></p>
                    <ul>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>All member transactions from other clubs on PlatinumClubNet associated with access to the Club including for booking and settlement will be routed through MobiCom and MobiCom will settle all payments to the Club.&nbsp;</span></li>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>For providing this seamless service through the Licensed Software and a 24*7 concierge, MobiCom does not charge any fee on Clubs. MobiCom will also not charge any fee to the visiting Member.&nbsp;</span></li>
                    </ul>
                    <p style={{ textAlign: "center" }}><strong>ANNEXURE II</strong><strong><br /></strong></p>
                    <p style={{ textAlign: "center" }}><strong>OPTIONAL SERVICES PROGRAMS</strong></p>
                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>Upon licensing of the Primary Benefits Program, MobiCom offers a host of additional services to the Club as options to choose, at its sole discretion. These Optional Services Programs are as follows:</span></p>
                    <p style={{ textAlign: "center" }}><strong>PART I</strong></p>
                    <p style={{ textAlign: "center" }}><strong>CURATE NEW EVENTS PROGRAM</strong></p>
                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>The Curate New Events Program is an optional service for the Club to choose if it requires MobiCom to curate new events specific to member sub-demographic(s) enabling participation by a higher percentage of Members in Club events and activities. MobiCom has partnered with a network of corporations, premium and luxury brands worldwide to create and deliver engaging experiential curated events at the Club or outside.&nbsp;</span></p>
                    <p><strong>Inclusions:&nbsp;</strong></p>
                    <ul>
                        <li style={{ fontWeight: "400" }}><span style={{ fontWeight: "400" }}>Services of PlatinumClubNet curation specialists;</span></li>
                        <li style={{ fontWeight: "400" }}><span style={{ fontWeight: "400" }}>Ability to leverage the PlatinumClubNet ecosystem.</span></li>
                    </ul>
                    <p><strong>Other Terms:</strong></p>
                    <ul>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>Upon listing the curation requirements by the Club on the CMS, PlatinumClubNet Curations Specialists will engage with the Club to finalize options, pricing, rules, inclusions, benefits and terms, prior to publishing and promoting on the PlatinumClubNet ecosystem. The curated event requirement will only be published and promoted on the PlatinumClubNet ecosystem once terms and conditions are mutually agreed and executed between the Club, MobiCom and the corporation/brand partner.</span></li>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>The Club is required to list their requirement/s at least 90 (ninety) days prior to the expected date for rendering the service.</span></li>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>MobiCom offers this service on a best effort basis, it does not guarantee delivery of service for any request listed by the Club.&nbsp;</span></li>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>The Curate New Events Program may be renewed upon payment of the renewal fees, as detailed in </span><strong>Serial Number 1 of Part II of Annexure III</strong><span style={{ fontWeight: "400" }}>, herein and such other terms as may be imposed by MobiCom at the time of renewal.&nbsp;</span></li>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>There will not be any cash or cash equivalent sponsorship fee paid to the Club towards any curated events as part of Curate New Events Program rendered to the Club either by MobiCom and/or any of its partner brands or corporations/entities.&nbsp;</span>&nbsp;</li>
                    </ul>
                    <ul>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>All transactions incurred in relation to the Curate New Events Program with the Club for booking and settlement by brands and/or corporations/entities will be routed through MobiCom and MobiCom will settle all payments to the Club.</span></li>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>Such additional terms as may be imposed by MobiCom from time to time, with reasonable notice to the Club.</span></li>
                    </ul>
                    <p style={{ textAlign: "center" }}><strong>PART II</strong></p>
                    <p style={{ textAlign: "center" }}><strong>CURRENT CLUB EVENTS PROGRAM</strong></p>
                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>The Current Club Events Program is an optional service for the Club to choose if it requires MobiCom to bring brand engagements for existing Club events/activities. MobiCom has partnered with a network of corporations, premium and luxury brands worldwide to deliver such a service.</span></p>
                    <p><strong>Inclusions:&nbsp;</strong></p>
                    <ul>
                        <li style={{ fontWeight: "400" }}><span style={{ fontWeight: "400" }}>Services of PlatinumClubNet Brand Engagement Specialists;</span></li>
                        <li style={{ fontWeight: "400" }}><span style={{ fontWeight: "400" }}>Ability to leverage the PlatinumClubNet ecosystem.</span></li>
                    </ul>
                    <p><strong>Other Terms:&nbsp;</strong></p>
                    <ul>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>Upon listing the current club events by the Club on its CMS, PlatinumClubNet Brand Engagement Specialists will engage with the Club to finalize options, pricing, rules, inclusions, benefits and terms, prior to publishing and promoting on the PlatinumClubNet ecosystem.&nbsp; The current club event plan/s will only be published and promoted on the PlatinumClubNet ecosystem once terms and conditions are mutually agreed and executed between the Club, MobiCom and the corporation/brand partner.</span></li>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>All transactions incurred in relation to the Current Club Events Program with the Club for booking and settlement by brands and/or corporations/entities will be routed through MobiCom and MobiCom will settle all payments to the Club.</span></li>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>The Club is required to list their requirement(s) at least 90 (ninety) days prior to the expected date of rendering the service.&nbsp;</span></li>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>While MobiCom offers these services on a best effort basis, it does not guarantee delivery of service for any request listed by the Club.&nbsp;</span></li>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>The Current Club Events Program may be renewed upon payment of the renewal fees, as detailed in </span><strong>Serial Number 2 of Part II of Annexure III</strong><span style={{ fontWeight: "400" }}> and such other terms as may be imposed by MobiCom at the time of renewal.&nbsp;</span></li>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>Such additional terms as may be imposed by MobiCom from time to time, with reasonable notice to the Club.</span></li>
                    </ul>
                    <p style={{ textAlign: "center" }}><strong>PART III</strong></p>
                    <p style={{ textAlign: "center" }}><strong>PRIVATE EVENT PROGRAM</strong></p>
                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>The Private Event Program is an optional service for the Club to choose if it requires MobiCom to promote the Club’s indoor or outdoor venues and facilities associated with meetings, conferences and/or banquets to other private club members on the PlatinumClubNet ecosystem or to partner institutions, brands and/or corporations/entities.&nbsp;</span></p>
                    <p><strong>Inclusions:&nbsp;</strong></p>
                    <ul>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>Services of PlatinumClubNet Private Events Specialists;</span></li>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>Profile published on the PlatinumClubNet ecosystem;</span></li>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>Promote Club venues to PlatinumClubNet ecosystem.&nbsp;</span></li>
                    </ul>
                    <p><strong>Other Terms:&nbsp;</strong></p>
                    <ul>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>Upon listing of the indoor or outdoor venues and facilities associated with meetings, conferences and/or banquets by the Club on the CMS, PlatinumClubNet Private Event Specialists will engage with the Club to finalize options, pricing, rules, inclusions, benefits and terms, prior to publishing and promoting on the PlatinumClubNet ecosystem.&nbsp; The venues will only be published and promoted on the PlatinumClubNet ecosystem once terms and conditions are mutually agreed and executed between the Club and MobiCom.&nbsp;</span></li>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>All transactions incurred in relation to the Private Event Program with the Club for booking and settlement by members and/or partners in the PlatinumClubNet ecosystem will be routed through MobiCom and MobiCom will settle all payments to the Club.</span></li>
                    </ul>
                    <ul style={{ textAlign: "justify" }}>
                        <li style={{ fontWeight: "400" }}><span style={{ fontWeight: "400" }}>The Club is required to list their requirement(s) at least 45 (forty-five) days prior to the expected date of rendering the service.&nbsp;</span></li>
                        <li style={{ fontWeight: "400" }}><span style={{ fontWeight: "400" }}>While MobiCom offers these services on a best effort basis, it does not guarantee delivery of service for any request listed by the Club.&nbsp;</span></li>
                    </ul>
                    <ul>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>The Private Event Program may be renewed upon payment of the renewal fees, as detailed in </span><strong>Serial Number 3 of Part II of Annexure III</strong><span style={{ fontWeight: "400" }}> and such other terms as may be imposed by MobiCom at the time of renewal.&nbsp;</span></li>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>Such additional terms as may be imposed by MobiCom from time to time, with reasonable notice to the Club.</span></li>
                    </ul>
                    <p style={{ textAlign: "center" }}><strong>PART IV</strong></p>
                    <p style={{ textAlign: "center" }}><strong>PROMOTE PROGRAM</strong></p>
                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>The Promote Program is an optional service for the Club to choose if it requires MobiCom to promote the Club’s membership plans, events, activities and packages to a global network of private club members.</span></p>
                    <p><strong>Inclusions:&nbsp;</strong></p>
                    <ul>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>Services of PlatinumClubNet Promotion Specialists;</span></li>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>Club packages and membership plans published on PlatinumClubNet ecosystem;&nbsp;</span></li>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>Promotions of the club packages and membership plans to PlatinumClubNet ecosystem.&nbsp;</span></li>
                    </ul>
                    <p><strong>Other Terms:&nbsp;</strong></p>
                    <ul>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>Upon listing the promotion package and/ or membership plan by the Club on the CMS, PlatinumClubNet Promotion Specialists will engage with the Club to finalize options, pricing, rules, inclusions, benefits and terms, prior to publishing and promoting on the PlatinumClubNet ecosystem. The package and/or membership plan will only be published and promoted on the&nbsp; PlatinumClubNet ecosystem once terms and conditions are mutually agreed and executed.</span></li>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>All transactions incurred in relation to the Promote Program with the Club for booking and settlement by members and/or partners in the PlatinumClubNet ecosystem will be routed through MobiCom and MobiCom will settle all payments to the Club.</span></li>
                    </ul>
                    <ul style={{ textAlign: "justify" }}>
                        <li style={{ fontWeight: "400" }}><span style={{ fontWeight: "400" }}>The Club is required to list their requirement(s) at least 45 (forty-five) days prior to the expected date of rendering the service.&nbsp;</span></li>
                        <li style={{ fontWeight: "400" }}><span style={{ fontWeight: "400" }}>While MobiCom offers these services on a best effort basis, it does not guarantee delivery of service for any request listed by the Club.&nbsp;</span></li>
                    </ul>
                    <ul>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>The Promote Program may be renewed upon payment of the renewal fees, as detailed in </span><strong>Serial Number 4 of Part II of Annexure III</strong><span style={{ fontWeight: "400" }}> and such other terms as may be imposed by MobiCom at the time of renewal.&nbsp;</span></li>
                        <li style={{ fontWeight: "400", textAlign: "justify" }}><span style={{ fontWeight: "400" }}>Such additional terms as may be imposed by MobiCom from time to time, with reasonable notice to the Club.</span></li>
                    </ul>
                    <p style={{ textAlign: "center" }}><strong>ANNEXURE III</strong></p>
                    <p style={{ textAlign: "center" }}><strong>&nbsp;FEES AND TERMS OF PAYMENTS</strong></p>
                    <p style={{ textAlign: "center" }}><strong>PART I</strong></p>
                    <p style={{ textAlign: "center" }}><strong>FEES AND TERMS OF PAYMENTS FOR THE</strong> <strong>LICENSE AND</strong> <strong>PRIMARY</strong> <strong>BENEFITS PROGRAM</strong>&nbsp;</p>
                    <table style={{ width: "100%", border: "1px solid black", borderCollapse: "collapse" }}>
                        <tbody>
                            {/* Table Header */}
                            <tr style={{ backgroundColor: "#f2f2f2" }}>
                                <td style={{ border: "1px solid black", padding: "10px", fontWeight: "bold" }}>Program</td>
                                <td style={{ border: "1px solid black", padding: "10px", fontWeight: "bold" }}>Fee</td>
                                <td style={{ border: "1px solid black", padding: "10px", fontWeight: "bold" }}>Payment Terms</td>
                            </tr>

                            {/* Row 1 */}
                            <tr>
                                <td style={{ border: "1px solid black", padding: "10px", textAlign: "justify" }}>
                                    1. License and Primary Benefits Program ( Global Platinum Club Access Program)
                                </td>
                                <td style={{ border: "1px solid black", padding: "10px", textAlign: "justify" }}>
                                    *No License Fee
                                </td>
                                <td style={{ border: "1px solid black", padding: "10px" }}></td>
                            </tr>

                            {/* Row 2 */}
                            <tr>
                                <td style={{ border: "1px solid black", padding: "10px", textAlign: "justify" }}>
                                    2. Convenience Fee on visiting Member transactions
                                </td>
                                <td style={{ border: "1px solid black", padding: "10px", textAlign: "justify" }}>
                                    There are no Convenience Fees charged to the club.
                                </td>
                                <td style={{ border: "1px solid black", padding: "10px" }}></td>
                            </tr>
                        </tbody>
                    </table>
                    <p style={{ fontWeight: "400" }}>
                        *License Fee includes software upgrades, revisions, renewals, maintenance, and customer support fees.
                    </p>

                    <p style={{ textAlign: "center" }}><strong>PART II</strong></p>
                    <p style={{ textAlign: "center" }}><strong>FEES AND TERMS OF PAYMENTS FOR THE OPTIONAL SERVICES PROGRAMS</strong></p>

                    <table style={{ width: "100%", border: "1px solid black", borderCollapse: "collapse" }}>
                        <tbody>
                            {/* Table Header */}
                            <tr style={{ backgroundColor: "#f2f2f2" }}>
                                <td style={cellStyle}><strong>Sl No</strong></td>
                                <td style={cellStyle}><strong>Program</strong></td>
                                <td style={cellStyle}><strong>Annual Activation Fees</strong></td>
                                <td style={cellStyle}><strong>Payment Terms</strong></td>
                                <td style={cellStyle}><strong>Renewal Fees and Terms</strong></td>
                            </tr>

                            {/* Row 1: Curate New Events Program */}
                            <tr>
                                <td style={cellStyle}>1</td>
                                <td style={cellStyle}>Curate New Events Program</td>
                                <td style={cellStyle}>USD 500 (Five Hundred United States Dollars Only)</td>
                                <td style={cellStyle}>
                                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>The annual activation fee shall be
                                        payable at the time MobiCom first renders the service to the Club and not at the time of
                                        listing the event(s) on the CMS.&nbsp;</span></p>
                                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>This activation fee is valid for a
                                        period of 12 (twelve) months from the date of rendering of the first service by
                                        MobiCom.</span></p>
                                </td>
                                <td style={cellStyle}>
                                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>For any listing to be serviced by
                                        MobiCom beyond 365 </span><span style={{ fontWeight: "400" }}>(three hundred and sixty five)
                                        </span><span style={{ fontWeight: "400" }}>days of the date of the first event serviced, an annual
                                            renewal fee </span><span style={{ fontWeight: "400" }}>of USD 500 (Five Hundred United States
                                                Dollars Only) will be charged by MobiCom and shall be payable on the date of rendering the
                                                service by MobiCom.&nbsp;</span></p>
                                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>No additional renewal fee shall be
                                        payable on rendering the service within a 365 (three hundred and sixty five) day period from
                                        the date of first rendering the renewed service.&nbsp;</span></p>
                                </td>
                            </tr>

                            {/* Row 2: Current Club Events Program */}
                            <tr>
                                <td style={cellStyle}>2</td>
                                <td style={cellStyle}>Current Club Events Program</td>
                                <td style={cellStyle}>USD 500 (Five Hundred United States Dollars Only)</td>
                                <td style={cellStyle}>
                                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>The annual activation fee shall be
                                        payable at the time MobiCom first renders the service to the Club and not at the time of
                                        listing the event(s) on the CMS requesting this service.&nbsp;</span></p>
                                    <p><span style={{ fontWeight: "400" }}>This activation fee is valid for a period of 12 (twelve) months
                                        from the date of rendering of the first service by MobiCom.</span></p>
                                    <p><span style={{ fontWeight: "400" }}>A service fee of 15% (fifteen percent) is applicable for all cash
                                        or cash equivalent sponsorships given to the Club by MobiCom or any of its partner brands
                                        and/or corporations and/or entities.</span></p>
                                </td>
                                <td style={cellStyle}>
                                    <p style={{ textAlign: "justify" }}><span style={{ fontWeight: "400" }}>For any listing to be serviced by
                                        MobiCom beyond 365 </span><span style={{ fontWeight: "400" }}>(three hundred and sixty five)
                                        </span><span style={{ fontWeight: "400" }}>days of the date of the first event serviced, an annual
                                            renewal fee </span><span style={{ fontWeight: "400" }}>of USD 500 (Five Hundred United States
                                                Dollars Only) will be charged by MobiCom and shall be payable on the date of rendering the
                                                service by MobiCom.&nbsp;</span></p>
                                    <p><span style={{ fontWeight: "400" }}>No additional renewal fee shall be payable on rendering the
                                        service within a 365 (three hundred and sixty five) day period from the date of first
                                        rendering the renewed service.&nbsp;</span></p>
                                    <p><span style={{ fontWeight: "400" }}>Upon renewal, a service fee of 15% (fifteen percent) is
                                        applicable for all cash or cash equivalent sponsorships given to the Club by MobiCom or any
                                        of its partner brands and/or corporations</span><span
                                            style={{ fontWeight: "400" }}>/entities</span><span style={{ fontWeight: "400" }}>.</span></p>
                                </td>
                            </tr>

                          
                            {/* <tr>
                                <td style={cellStyle}>3</td>
                                <td style={cellStyle}>Private Events Program</td>
                                <td style={cellStyle}>USD 500 (Five Hundred United States Dollars Only)</td>
                                <td style={cellStyle}>
                                    <p><span style={{ fontWeight: "400" }}>The annual activation fee shall be payable at the time MobiCom generates the first booking for the Club and not at the time of listing the venue(s) on the CMS.&nbsp;</span></p>
                                    <p><span style={{ fontWeight: "400" }}>This activation fee is valid for a period of 12 (twelve) months from the date of rendering the service of the first booking by MobiCom.</span></p>
                                    <p><span style={{ fontWeight: "400" }}>A service fee of 10% (ten percent) on the gross transaction value of the invoice raised by the Club will be payable by the Club for each transaction made as part of the Private Events Program.&nbsp;</span></p>
                                </td>
                                <td style={cellStyle}>
                                    <p><span style={{ fontWeight: "400" }}>For any private event venue booking to be serviced by MobiCom beyond 365 </span><span style={{ fontWeight: "400" }}>(three hundred and sixty five) </span><span style={{ fontWeight: "400" }}>days of the date of the first booking, an annual renewal fee </span><span style={{ fontWeight: "400" }}>of USD 500 (Five Hundred United States Dollars Only) will be charged by MobiCom and shall be payable on the date of rendering the service by MobiCom.&nbsp;</span></p>
                                    <p><span style={{ fontWeight: "400" }}>No additional renewal fee shall be payable on rendering the service within a 365 (three hundred and sixty five) day period from the date of first rendering the renewed service.&nbsp;</span></p>
                                    <p><span style={{ fontWeight: "400" }}>Upon renewal, a service fee of 10% (ten percent) on the gross transaction value of the invoice raised by the Club will be payable by the Club for each transaction made as part of the Private Events Program.</span></p>
                                </td>
                            </tr>

                      
                            <tr>
                                <td style={cellStyle}>4</td>
                                <td style={cellStyle}>Promote Program</td>
                                <td style={cellStyle}>USD 500 (Five Hundred United States Dollars Only)</td>
                                <td style={cellStyle}>
                                    <p><span style={{ fontWeight: "400" }}>The annual activation fee shall be payable at the time MobiCom first renders the promotion to the Club and not at the time of listing the promotions on the CMS.&nbsp;</span></p>
                                    <p><span style={{ fontWeight: "400" }}>This activation fee is valid for a period of 12 (twelve) months from the date of rendering of the first promotion by MobiCom.</span></p>
                                    <p><span style={{ fontWeight: "400" }}>A service fee of 10% (ten percent) is charged for each transaction made as part of the Promote Program.</span></p>
                                </td>
                                <td style={cellStyle}>
                                    <p><span style={{ fontWeight: "400" }}>For any promotion to be serviced by MobiCom beyond 365 </span><span style={{ fontWeight: "400" }}>(three hundred and sixty five) </span><span style={{ fontWeight: "400" }}>days of the date of the first event serviced, an annual renewal fee </span><span style={{ fontWeight: "400" }}>of USD 500 (Five Hundred United States Dollars Only) will be charged by MobiCom and shall be payable on the date of rendering the service by MobiCom.&nbsp;</span></p>
                                    <p><span style={{ fontWeight: "400" }}>No additional renewal fee shall be payable on rendering the service within a 365 (three hundred and sixty-five) day period from the renewed date of publishing and/or promoting on the PlatinumClubNet ecosystem.</span></p>
                                    <p><span style={{ fontWeight: "400" }}>Upon renewal, a service fee of 10% (ten percent) is charged for each transaction made as part of the Promote Program.</span></p>
                                </td>
                            </tr>  */}
                        </tbody>
                    </table>
                </div>
                <Form.Check
                    type="checkbox"
                    label="I accept the terms and conditions"
                    className="mb-3"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                />

                <div className="d-flex justify-content-between">
                    <Button
                        variant="dark"
                        style={{
                            backgroundColor: "#0673dc",
                            color: "white",
                            border: "none",
                            padding: "10px 20px",
                            borderRadius: "5px",
                            cursor: "pointer",
                            fontSize: "16px",
                        }}
                        onClick={() => setValue(1)}
                    >
                        Back
                    </Button>
                    <Button
                        variant="dark"
                        style={{
                            backgroundColor: "#0673dc",
                            color: "white",
                            border: "none",
                            padding: "10px 20px",
                            borderRadius: "5px",
                            cursor: "pointer",
                            fontSize: "16px",

                        }}
                        disabled={!isChecked} 
                        onClick={() => setValue(3)}
                    >
                        Next
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default Agreement;
