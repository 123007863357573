import DataTable from "react-data-table-component";
import { ReactComponent as EditIcon } from "../../assets/images/common/edit-icon.svg";
import { ReactComponent as ViewIcon } from "../../assets/images/common/view-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/common/add-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/common/close-icon.svg";
import { ReactComponent as InterestIcon } from "../../assets/images/common/interest-logo-icon.svg";
import { useEffect, useState } from "react";
// Rsuite Datepicker
import { CREATE_PLACES, EDIT_PLACES } from '../../constants/pathname';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';


import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import SweetAlertModal from '../SweetAlert/SweetAlertModal';
import { deleteInterestType, interesttypeStatusChanging, listInterestTyping } from "../../api/actions/interestType";
import { paginationRowsPerPageOptions } from "../../helpers/helper";
import toast from "react-hot-toast";
import { FaTimes } from "react-icons/fa";
import { FormControlLabel, RadioGroup, Switch } from "@mui/material";
import { checkTheme } from "../../utils/utils";
import SweetAlert from "react-bootstrap-sweetalert";
const Places = () => {
    // Sweet alert
    const [showSweetAlert, setShowSweetAlert] = useState(false);
    const [data, setData] = useState([{}]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [isDeleting, setIsDeleting] = useState(false);
    const [limit, setLimit] = useState(25);
    const [search, setSearch] = useState('');
    const [update, setUpdate] = useState(false);
    const [pending, setPending] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isSwal, setIsSwal] = useState({ show: false, id: '' });
    const [showDrawer, setShowDrawer] = useState(false);
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [searchText, setSearchText] = useState('');
    const location = useLocation();
    const { id, name } = useParams();
    const [selectedId, setSelectedId] = useState(null);

    const handleSearch = (value) => {
        setSearch(value);
        setSearchText(value);
    }
    useEffect(() => {
        if (search.length >= 2) {
            const debounceTimer = setTimeout(() => {
                setDebouncedSearch(search);
            }, 500);

            return () => clearTimeout(debounceTimer);
        } else {
            setDebouncedSearch('');
        }
    }, [search]);
    const clearSearch = () => {
        setSearch('');
        setSearchText('');
        setDebouncedSearch('');
        setUpdate(!update);
    };
   const CustomSwitch = styled((props) => (
            <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
        ))(({ theme }) => ({
            width: 39,
            height: 20,
            padding: 0,
            marginLeft: 5,
            '& .MuiSwitch-switchBase': {
                padding: 0,
                // margin: 2,
                transitionDuration: '300ms',
                '&.Mui-checked': {
                    transform: 'translateX(16px)',
                    color: '#fff',
                    '& + .MuiSwitch-track': {
                        backgroundColor: 'var(--sandy-brown)',
                        opacity: 1,
                        border: 0,
                        ...theme.applyStyles('dark', {
                            backgroundColor: '#2ECA45',
                        }),
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: 0.5,
                    },
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                    color: '#33cf4d',
                    border: '6px solid #fff',
                },
                '&.Mui-disabled .MuiSwitch-thumb': {
                    color: theme.palette.grey[100],
                    ...theme.applyStyles('dark', {
                        color: theme.palette.grey[600],
                    }),
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    backgroundColor: 'var(--sandy-brown)',
                    opacity: 0.7,
                    ...theme.applyStyles('dark', {
                        opacity: 0.3,
                    }),
                },
            },
            '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                width: 20,
                height: 20,
            },
            '& .MuiSwitch-track': {
                borderRadius: 26 / 2,
                backgroundColor: '#E9E9EA',
                opacity: 1,
                transition: theme.transitions.create(['background-color'], {
                    duration: 500,
                }),
                ...theme.applyStyles('dark', {
                    backgroundColor: '#39393D',
                }),
            },
        }));

    const StyledSelect = styled(Select)(({ theme }) => ({
        height: '35px',
        width: '100px',
        color: 'white',
        padding: '10px',
        fontSize: "14px !important",
        backgroundColor: 'var(--light-green)',
        borderRadius: '30px',
        '& .MuiSvgIcon-root': {
            color: 'white', // Dropdown icon color
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent', // Optional border color
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white', // Border color on hover
        },
        '.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
            padding: "4px"
        }
    }));

    const navigate = useNavigate();
    const [status, setStatus] = useState(10);
    useEffect(() => { getListData(); }, [page, limit, debouncedSearch, update]);

    const getListData = () => {
        setPending(true);

        if (debouncedSearch) {
            setPage(1);
        }
        listInterestTyping({
            page: debouncedSearch ? 1 : page,
            limit,
            search: debouncedSearch,
            interest_type: name,
        })(
            (response) => {
                if (response && response.status) {

                    setData(response?.data?.results[0]?.interests);
                    setTotalRows(response?.data?.count);
                    setPending(false);
                }
            }
        );
    };


    const handleToggleClick = (id) => {
        setSelectedId(id);
        setShowSweetAlert(true);
    };
    
    const confirmToggle = () => {
        if (selectedId !== null) {
            interesttypeStatusChanging({ id: selectedId })((response) => {
                if (response?.status_code === 200) {
                    toast.success(response?.message);
                    const updatedData = data.map(item => {
                        if (item.id === selectedId) {
                            return { ...item, status_interest: response?.status };
                        }
                        return item;
                    });
                    setUpdate(!update);
                } else {
                    toast.error(response?.message || "Something went wrong");
                }
            });
        }
        setShowSweetAlert(false);
    };
    // Define table columns 
    const columns = [
        {
            name: "Sl.No",
            key: "name",
            selector: row => row?.name,
            cell: (row, index) =>
                <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.name}>
                    {(limit * (page - 1)) + (index + 1)}
                </div>,
            sortable: true,
            grow: 2,
            reorder: true,
            width: '100px'
        },
        { name: "Name", selector: row => row.name, sortable: true, minWidth: "280px" },
        {
            name: !checkTheme() ? "PCW Image" : "PCN Image",
            cell: (row) =>
                row.pcw_image ? (
                    <img
                        src={!checkTheme() ? row.pcw_image : row.pcn_image}
                        alt="Facility"
                        style={{ width: "40px", height: "40px", objectFit: "cover" }}
                    />
                ) : (
                    <InterestIcon style={{ width: "20px", height: "20px" }} />
                ),
                minWidth:"200px", 
        },
        
       
        // { name: "Date Modified", selector: row => row.date, minWidth: "200px" },
        {
            name: "Active/Inactive",
            selector: row =>
                <div className='view-icon cursor-pointer' style={{ width: "140px", height: "50px", paddingTop: "9px", overflow: "visible", border: "none" }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '10px', color: row.status_interest ? 'var(--light-green)' : 'red' }}>
                           
                        </span>
                        <CustomSwitch
                            checked={row.status_interest}
                            onChange={() => handleToggleClick(row.id)}
                        />
                    </div>
                </div>,
            minWidth: "180px"
        },
        {
            name: "Actions",
            selector: row =>
                <div className='d-flex align-items-center gap-3'>
                    {/* <div className="view-icon cursor-pointer" title="View" onClick={() => navigate(CREATE_PLACES, { state: { currentPageName: "View Place" } })}>
                        <ViewIcon />
                    </div> */}
            
                        <div className='view-icon cursor-pointer' onClick={() => navigate(EDIT_PLACES + row?.id, {
                            state: { name: name, ids: id }
                        })} title="Edit"><EditIcon /></div>
                  

                    <div className='view-icon cursor-pointer' onClick={() => setIsSwal({ show: true, id: row?.id })} title="Delete"><CloseIcon style={{ marginLeft: "2px" }} /></div>
                </div>,
            minWidth: "100px"
        }
    ];

    // Custom styling for the table
    const customStyles = {
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",
                border: "1px solid var(--border-color)",
                overflow: "hidden"
            },
        },
        headCells: {
            style: {
                backgroundColor: "var(--light-blue)",
                justifyContent: 'flex-start !important',
                color: "var(--dark-gray)",
                fontWeight: "400",
                fontSize: "15px",
            },
        },
        rows: {
            style: {
                height: "45px", // Set row height
                justifyContent: "start",
            },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    };
    const handleDelete = (id) => {
        if (isDeleting) return;
        setIsDeleting(true);
        deleteInterestType(id)((response) => {
            if (response && response?.status) {
                toast.success('Successfully deleted');
                getListData();
            } else {
                toast.error('Deletion failed');
            }
            setIsDeleting(false);
            setIsSwal({ show: false, id: null });
        });
    };
    return (
        <div className='member-stats-section interests-section'>
            <SweetAlertModal showSweetAlert={isSwal.show} setShowSweetAlert={(show) => {
                if (show) handleDelete(isSwal.id);
                else setIsSwal({ ...isSwal, show: false });
            }} />
{showSweetAlert && (
    <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes"
        cancelBtnText="Cancel"
        confirmBtnCssClass="custom-confirm-button-class"
        cancelBtnCssClass="custom-cancel-button-class"
        title={
            <>
                <span className="custom-sweetalert-title">Change Status</span>
                <div className='text-center' style={{ fontSize: "12px", fontWeight: "normal", lineHeight: 2 }}>
                    Are you sure you want to change the status?
                </div>
            </>
        }
        onConfirm={confirmToggle}
        onCancel={() => setShowSweetAlert(false)}
        focusCancelBtn
    />
)}
            <div className='page-details'>
                <div className='top-section d-flex align-items-center justify-content-between'>
                    <div className='left'>{name || "Default Name"}</div>

                    <div className='right d-flex align-items-center  gap-3' >
                        <div className='search-box'>
                            <input
                                type='text'
                                placeholder='Search ...'
                                className='form-control'
                                value={searchText}
                                onChange={(e) => handleSearch(e.target.value)}
                            />

                            {searchText && (
                                <FaTimes className='clear-icon' onClick={clearSearch} />
                            )}
                        </div>
                        <div className='add-clubs-info cursor-pointer' onClick={() => navigate(CREATE_PLACES, { state: { currentPageName: "Add Place", name: name, ids: id } })} title="Create">
                            <AddIcon />
                        </div>

                    </div>
                </div>

                <div className='table-box mt-3'>
                    <DataTable
                        columns={columns}
                        data={data}
                        customStyles={customStyles}
                        fixedHeader
                        highlightOnHover
                        responsive
                        pagination
                        paginationServer
                        paginationPerPage={limit}
                        progressPending={pending}
                        onChangeRowsPerPage={setLimit}
                        paginationTotalRows={totalRows}
                        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                        onChangePage={setPage}
                    />
                </div>
            </div>
        </div>
    )
}

export default Places