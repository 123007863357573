import { Card, Form, Container, Row, Col } from "react-bootstrap";

const PrivateEventsMenu=() => {

    const questions=[
        {
            id: 1,
            question: "Do you currently have photos of each Private Event space? Can you please provide us with photos or marketing material.",
            subtext: "Please share via DropBox/ WeTransfer link here",
            placeholder: "Provide the link here...",
        },
        {
            id: 2,
            question: "Does your Club have a 360° Tour?",
            subtext: "Please provide the Tour Link here",
            placeholder: "Please type here...",
        },
        {
            id: 3,
            question: "Does your Club utilize Floor Plans in planning Private Events?",
            placeholder: "Please type here...",
        },
        {
            id: 4,
            question: "What items does the Club provide for Private Events?",
            subtext: "(i.e. tables, chairs, china, etc.)",
            placeholder: "Please type here...",
        },

        {
            id: 5,
            question: "What special instructions should we know?",
            subtext: "(i.e. no sparklers, only LED candles, etc.)",
            placeholder: "please type here.....",
        },
        {
            id: 6,
            question: "Does your Club have a suggested Vendor List? Is our Team permitted to bring in our own Vendors?",
            placeholder: "please type here.....",
        },
        {
            id: 7,
            question: "How much time before an event begins are Vendors allowed to enter the property?",
            placeholder: "please type here.....",
        },
        {
            id: 8,
            question: "What are your delivery instructions?",
            subtext: "(i.e. Is it necessary to make special requests for a loading dock? Are there specific times deliveries must take place? Does your Club have restrictions on the size/types of vehicles which are allowed on the property?)",
            placeholder: "please type here.....",
        },
        {
            id: 9,
            question: "Does your Club have a Pro Shop/Gift Shop?",
            subtext: "(Please describe)",
            placeholder: "Please type here.....",
        },
        {
            id: 10,
            question: "On what websites is your Club listed?",
            subtext: "(i.e. The Knot, WeddingWire, etc.)",
            placeholder: "please type here.....",
        }
    ];

    return (
        <div className='manage-members-page cur'>
            <div className='manage-members member-stats-section'>
                <div className='page-details'>
                    <div className='top-section'>
                        <div className='left'>
                            Profile For Private Events
                        </div>
                    </div>
                    <Container className="mt-4">
                        <Row className="justify-content-center">
                            <Col>
                                {questions.map((item) => (
                                    <Card key={item.id} className="mb-3 shadow-sm">
                                        <Card.Body>
                                            <Card.Title>{item.id}. {item.question}</Card.Title>
                                            {item.subtext&&(
                                                <Card.Text className="text-muted mb-3">{item.subtext}</Card.Text>
                                            )}
                                            <Form.Control type="text" placeholder={item.placeholder} />
                                        </Card.Body>
                                    </Card>
                                ))}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default PrivateEventsMenu