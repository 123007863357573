import React from 'react'
import Interests from '../../components/Interests/Interests'

const InterestsPage = () => {

  return (
    <div className='interests-page'>
        <Interests />
    </div>
  )
}

export default InterestsPage