// Ck Editor
import { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
    ClassicEditor,
    Autoformat,
    Autosave,
    Bold,
    Essentials,
    Heading,
    Italic,
    Paragraph,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    Undo,
    List,
    Alignment
} from "ckeditor5";
import { Form } from "react-bootstrap";
import { ReactComponent as AddIconBlack } from "../../assets/images/common/add-icon-black.svg";
import { ReactComponent as MinusIcon } from "../../assets/images/common/minus-icon.svg";
const About = ({ formik }) => {
    // For Ck Editor
    const editorContainerRef = useRef(null);
    const editorRef = useRef(null);
    const editorClubContainerRef = useRef(null);
    const editorClubRef = useRef(null);
    const [isLayoutReady, setIsLayoutReady] = useState(false);

    // Editor configuration
    const editorConfig = {
        toolbar: {
            items: [
                "undo",
                "redo",
                "|",
                "|",
                "heading",
                "|",
                "bold",
                "italic",
                "bulletedList",
                "numberedList",
                "|",
                "alignment"
            ],
            shouldNotGroupWhenFull: false,
        },
        alignment: {
            options: ['left', 'right', 'center', 'justify']
        },
        plugins: [
            Autoformat,
            Autosave,
            Bold,
            Essentials,
            Heading,
            Italic,
            Paragraph,
            List,
            TextTransformation,
            Table,
            TableCaption,
            TableCellProperties,
            TableColumnResize,
            TableProperties,
            TableToolbar,
            Alignment,
            Undo,
        ],
        heading: {
            options: [
                {
                    model: "paragraph",
                    title: "Paragraph",
                    class: "ck-heading_paragraph",
                },
                {
                    model: "heading1",
                    view: "h1",
                    title: "Heading 1",
                    class: "ck-heading_heading1",
                },
                {
                    model: "heading2",
                    view: "h2",
                    title: "Heading 2",
                    class: "ck-heading_heading2",
                },
                {
                    model: "heading3",
                    view: "h3",
                    title: "Heading 3",
                    class: "ck-heading_heading3",
                },
                {
                    model: "heading4",
                    view: "h4",
                    title: "Heading 4",
                    class: "ck-heading_heading4",
                },
                {
                    model: "heading5",
                    view: "h5",
                    title: "Heading 5",
                    class: "ck-heading_heading5",
                },
                {
                    model: "heading6",
                    view: "h6",
                    title: "Heading 6",
                    class: "ck-heading_heading6",
                },
            ],
        },

        initialData: "",
        menuBar: {
            isVisible: true,
        },
        placeholder: "Type or paste your content here!",
    };

    // Setting layout for Ckeditor
    useEffect(() => {
        setIsLayoutReady(true);

        return () => setIsLayoutReady(false);
    }, []);
    const addRule = () => {
        // setClubRules([...clubRules, { value: "" }]);
        formik?.setValues({
            ...formik?.values,
            club_rules: [
                ...formik?.values?.club_rules,
                { club_rules: "" }
            ]
        });
    };
    const handleRuleInputChange = (index, value) => {
        // setClubRules(
        //     clubRules.map((item, i) => (i===index? { ...item, value }: item))
        // );
        let filteredList = formik?.values?.club_rules.map((item, i) => (i === index ? { ...item, club_rules: value } : item));

        formik?.setValues({
            ...formik?.values,
            club_rules: filteredList
        });
    };

    // Remove a rule
    const removeRule = (index) => {
        // setClubRules(clubRules.filter((item, i) => i !== index));
        let filteredList = formik?.values?.club_rules?.filter((item, i) => i !== index);
        formik?.setValues({
            ...formik?.values,
            club_rules: filteredList
        });
    };


    return (
        <div className='about-club-section'>
            <div className='panel-type-1 mt-5'>
                About Club
            </div>
            <div
                className="editor-container editor-container_classic-editor mt-4"
                ref={editorContainerRef}
            >
                {/* CK Editor for Document */}
                <div className="editor-container__editor">
                    <div ref={editorRef} id="#editor">
                        {isLayoutReady && (
                            <CKEditor
                                editor={ClassicEditor}
                                data={formik?.values?.about_club || ""}
                                config={editorConfig}
                                onReady={(editor) => {
                                    editorRef.current = editor;
                                    editor.enableReadOnlyMode("editor-instance"); // Disable editor

                                    // Change background color when disabled
                                    const editorElement = editor.ui.view.editable.element;
                                    if (editorElement) {
                                        editorElement.style.backgroundColor = "#e9ecef"; // Light gray color
                                    }
                                }}
                            />

                        )}
                    </div>
                </div>
            </div>
            <div className='panel-type-1 mt-5 d-flex justify-content-between'>
                <div>Club Rules <span className="required">*</span></div>
                <AddIconBlack size={18} onClick={addRule} title="Add Rule" className="cursor-pointer" />
            </div>
            <div className="onboard-rules-box row m-0">
                <div className="col-md-6">
                    {formik?.values?.club_rules?.map((item, index) => (
                        <div className="item border-type-1 d-flex align-items-center mt-4 p-2" key={index}>
                            {/* Input Field */}
                            <div className="col-md-11">
                                <Form.Control
                                    type="text"
                                    className="input-field border-0 p-0"
                                    name={`rule-${index + 1}`}
                                    placeholder="Enter club rule"
                                    disabled
                                    value={item.club_rules}
                                    onChange={(e) => handleRuleInputChange(index, e.target.value)}
                                />
                            </div>

                            {/* Add or Remove Icons */}
                            <div className="col-md-1 d-flex justify-content-end cursor-pointer">
                                <MinusIcon size={18} onClick={() => removeRule(index)} title="Remove" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='panel-type-1 mt-5'>
                Location <span className="required">*</span>
            </div>
            <div className="location-map mt-4">
                <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319" width="100%" height="413" frameborder="0" style={{ borderRadius: "5px" }} title='See Nearby Places'></iframe>
                <div className="search-loc-box">
                    <input type="text" name="search-location" placeholder="Enter a Location" />
                </div>
            </div>
        </div>
    )
}

export default About