import React from 'react'
import { Form } from 'react-bootstrap'
import {ReactComponent as AddIcon} from "../../assets/images/common/add-icon.svg";
import { useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import {CREATE_SURVEY_QUESTIONER} from "../../constants/pathname";

const ViewSurveyQuestioner = () => {
  const navigate = useNavigate();

  return (
    <div className='create-clubs-info-section view-survey-questioner'>
        <Form onSubmit={(e) => e.preventDefault()}>
          <div className='page-details'>
                {/* Survey Questionnaire */}
                <div className='panel-type-1'>
                  Survey Questionnaire
                </div>
                <div className='row mt-5'>
                  {/* Event Name */}
                  <div className='col-md-4'>
                    <Form.Group controlId='eventName'>
                      <Form.Label>Event Name</Form.Label>
                      <Form.Control 
                        type='text'
                        value="Member Events"
                        name='eventName'
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                  {/* Created Date */}
                  <div className='col-md-4'>
                    <Form.Group controlId='createdDate'>
                      <Form.Label>Created Date</Form.Label>
                      <Form.Control 
                        type='text'
                        value="08 Oct 2024"
                        name='createdDate'
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                  {/* Questions */}
                  <div className='col-md-4'>
                    <Form.Group controlId='questions'>
                      <Form.Label>Questions</Form.Label>
                      <Form.Control 
                        type='text'
                        value="10"
                        name='questions'
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                </div>  
                {/* Questions */}
                <div className='panel-type-1 mt-5'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div>Questions</div>
                    <div className='d-flex align-items-start btn-type-3 cursor-pointer' onClick={() => navigate(CREATE_SURVEY_QUESTIONER)}>                    
                        <AddIcon />
                        Add New
                    </div>
                  </div>
                </div>  
                {/* Questions List */}
                <div className='question-list mt-4'>
                  <div className='question-item'>
                    <div className='d-flex gap-3'>
                      <div className='q-number'>1</div>
                      <div>
                        <div className='question'>
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                          and scrambled it to  makea type specimen book. 
                        </div>
                        <div className='options-list mt-3'>
                            <div className='option d-flex align-items-center'>
                              <Radio
                                type='radio'
                                id='option-1'
                                name='question-option-1'
                              />
                              <span>Lorem Ipsum is simply dummy text of the printing</span>
                            </div>
                            <div className='option d-flex align-items-center'>
                              <Radio
                                type='radio'
                                id='option-1'
                                name='question-option-1'
                              />
                              <span>Lorem Ipsum is simply dummy text of the printing</span>
                            </div>
                            <div className='option d-flex align-items-center'>
                              <Radio
                                type='radio'
                                id='option-1'
                                name='question-option-1'
                              />
                              <span>Lorem Ipsum is simply dummy text of the printing</span>
                            </div>
                            <div className='option d-flex align-items-center'>
                              <Radio
                                type='radio'
                                id='option-1'
                                name='question-option-1'
                              />
                              <span>Lorem Ipsum is simply dummy text of the printing</span>
                            </div>
                        </div>
                      </div>
                    </div>                    
                  </div>                 
                  <div className='question-item mt-4'>
                    <div className='d-flex gap-3'>
                      <div className='q-number'>2</div>
                      <div>
                        <div className='question'>
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                          and scrambled it to  makea type specimen book. 
                        </div>
                        <div className='mt-4'>
                            <Form.Control 
                              as="textarea"
                              value="It is a long established fact"
                              rows={4}
                            />
                        </div>
                      </div>
                    </div>                    
                  </div>                 
                  <div className='question-item mt-4'>
                    <div className='d-flex gap-3'>
                      <div className='q-number'>3</div>
                      <div>
                        <div className='question'>
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                          and scrambled it to  makea type specimen book. 
                        </div>
                        <div className='mt-4'>
                            <Form.Control 
                              as="textarea"
                              value="It is a long established fact"
                              rows={4}
                            />
                        </div>
                      </div>
                    </div>                    
                  </div>                 
                </div>
          </div>         
          
        </Form>  

    </div>
  )
}

export default ViewSurveyQuestioner