export const  CREATE_CLUB='/website/club/create-or-update-club'
export const LIST_CLUB='/website/club/get-club-list'
export const DELETE_CLUB='/website/club/delete-club'
export const DETAIL_CLUB='/website/club/get-club-details'
export const LIST_ALL_CLUBS ='/club/all-club/get-all-clubs'

//get user details
export const GET_USER_DETAILS ='/user/users/get-user-details';

// Dashboard
export const DASHBOARD_OVERVIEW='/club/dashboard/get-dashboard-overview';

// Club Profile
export const CREATE_CLUB_PROFILE='/club/club-profile/create-or-update-club-profile';
export const GET_CLUB_PROFILE='/club/club-profile/get-club-profile-details';
export const LIST_CLUB_PROFILE='/club/club-profile/get-club-profile-list'
export const CLUB_DROPDOWN='/club/club-profile/club-profile-dropdown'
export const MAIL_SENDING_CLUB_ONBOARDING='/club/club-profile/send-club-registration-mail'
export const REGISTRATION_LINK='/club/club-profile/get-club-registration-url'
export const CLUB_ONBOARDING_CREATION='/club/club-profile/club-onboard'
export const CLUB_ONBOARDING_DETAILS='/club/club-profile/get-club-details-onboard'
export const CLUB_HOST_BOOKING='/club/club-profile/enable-disable-host-booking-club'
export const CLUB_ONBOARDING_CSV_DOWNLOAD='/club/club-profile/club-profile-csv-upload'
export const CLUB__ONBOARDING_SAMPLE_FILE_DOWNLOAD='/club/club-profile/club-profile-sample-csv-download'
export const CLUB_ONBOARDING_EXCEL_DOWNLOAD='/club/club-profile/club-onboard-xcel-download'

// Black Out Dates
export const CREATE_BLACKOUT_DATES = '/club/black-out-dates/create-or-update-black-out-dates';
export const DELETE_BLACKOUT_DATES = '/club/black-out-dates/delete-black-out-dates';
export const GET_BLACKOUT_DATE_DETAIL = '/club/black-out-dates/get-black-out-dates-detail';
export const LIST_BLACKOUT_DATES = '/club/black-out-dates/get-black-out-dates-list';
export const UPDATE_BLACKOUT_DATE_STATUS = '/club/black-out-dates/update-black-out-date-status';

// Club Information
export const LIST_CLUB_INFORMATION='/club/list-club-information/get-club-information-list';
export const UPDATE_DEMO_CLUB_STATUS='/club/list-club-information/demo-club-status-change';
export const UPDATE_CITY_RES_STATUS='/club/list-club-information/member-city-status-change';

export const COMMON_STATUS_CHANGE='/club/list-club-information/common-club-status-change';
export const GOLF_BOOKING_STATUS_CHANGE='/club/list-club-information/golf-booking-status-change';
export const GPCC_STATUS_CHANGE='/club/list-club-information/gpcc-status-change';
export const CLUB_STATUS_CHANGE='/club/list-club-information/club-status-change';
export const PUBLISH_CLUB_STATUS_CHANGE='/club/list-club-information/publish-club-status-change';
export const SOLITAIRE_CLUB_STATUS_CHANGE='/club/list-club-information/solitaire-club-status-change';
export const CLUB_INFORMATION_XLS_DOWNLOAD='club/list-club-information/club-information-xls-download';
// Country and City
export const COUNTRY_DROPDOWN_URL = '/db_handler/country/get-country-dropdown';
export const CITY_DROPDOWN_URL = '/settings/city/get-city-dropdown';
// Club Types
export const CLUB_TYPE_DROPDOWN_URL = '/settings/clubtype/get-clubtype-dropdown';

// Club event
export const LIST_CLUB_EVENT = '/event/events/club-events-list';
export const GET_CLUB_EVENT = '/event/events/club-events-detail';
export const CREATE_CLUB_EVENT = '/event/events/create-or-update-club-events';

// login
export const LOGIN='/auth/login'
export const LOGOUT='/auth/logout'

//forggotten password
export const EMAIL_VERIFICATION='/auth/user-forgotpassword'
export const OTP_VERIFICATION='/auth/user-otp'
export const RESET_PASSWORD='/auth/user-password-reset'
export const CHANGE_PASSWORD='/auth/user-change-password'

// member status
export const MEMBER_STATUS_LISTING='/club/club-profile/member-stats-list'

// cmanagae member
export const ADD_MEMBER='/club/member-profile/create-or-update-member-profile'
export const MEMBER_PROFILE_LIST='/club/member-profile/get-member-profile-list'
export const CLUB_MEMBER_LIST='/club/club-profile/club-member-list'
export const EXPORT_MEMBER_LIST='club/club-profile/download-xls-club-member'
export const MEMBER_PROFILE_DETAILS='/club/member-profile/get-member-profile-details'
export const MEMBER_PROFILE_DELETE='/club/member-profile/delete-member-profile'
export const UPDATE_MEMBER_RC_MODERATION= '/club/member-profile/update-rc-moderation'
export const MEMBER_BULK_UPLOAD= '/club/member-profile/member-csv-upload'
export const MEMBER_BULK_MEMBER_PROFILE= '/club/club-profile/bulk-update-member-profile'
export const MEMBER_STATUS_UPDATE= '/club/club-profile/status-update-club-member'
export const INVITE_CLUB_MEMBER= '/club/club-profile/invite-club-member'
export const SAMPLE_CSV_DOWNLOAD= 'club/club-profile/sample-csv-download'

//addfaciltityCategory
export const ADD_FACILITY_CATEGORY='/settings/facility-category/create-or-update-facility-category'
export const FACILITY_CATEGORY_LIST='/settings/facility-category/get-facility-category-list'
export const FACILITY_CATEGORY_DETAILS='/settings/facility-category/get-facility-category-details'
export const FACILITY_CATEGORY_DELETE='/settings/facility-category/delete-facility-category'
export const FACILITY_CATEGORY_DROPDOWN='/settings/facility-category/get-facility-category-dropdown'
export const FACILITY_CATOGORY_STATUS='/settings/facility-category/update-facility-category-status'


//addclubFaciltities
export const ADD_CLUB_FACILITY='/club/club-facility/create-or-update-club-facility'
export const CLUB_FACILITY_LIST='/club/club-facility/get-club-facility-list'
export const CLUB_FACILITY_DETAILS='/club/club-facility/get-club-facility-details'
export const CLUB_FACILITY_DELETE='/club/club-facility/delete-club-facility'


// solitatre club
export const SOLITATRE_CLUB_LIST='/club/all-club/get-solitaire-clubs'
export const USER_DETAIL_FROM_TOKEN='/auth/token-login'



// brand category
export const ADD_BRAND_CATEGORY='/settings/brand-category/create-or-update-brand-category'
export const BRAND_CATEGORY_LISTING='/settings/brand-category/get-brand-category-list'
export const BRAND_CATEGORY_DETAILS='/settings/brand-category/get-brand-category-details'
export const DELETE_BRAND_CATEGORY='/settings/brand-category/delete-brand-category'
export const BRAND_CATEGORY_DROPDOWN='/settings/brand-category/get-brand-category-dropdown'
export const BRAND_CATEGORY_STATUS_CHANGING='/settings/brand-category/update-brand-category-status'

//brand
export const ADD_BRAND='/settings/brand/create-or-update-brand'
export const LIST_BRAND='/settings/brand/get-brand-list'
export const BRAND_DETAILS='/settings/brand/get-brand-details'
export const DELETE_BRAND='/settings/brand/delete-barnd'
export const BRAND_STATUS='/settings/brand/update-brand-status'

//letter of introduction
export const ADD_LETTER_OF_INTRODUCTION='/club/letter-of-introduction/create-or-update-letter-of-introduction'
export const DETAILS_LETTER_OF_INTRODUCTION='/club/letter-of-introduction/get-letter-of-introduction-details'
export const VIEWPDF='club/letter-of-introduction/get-letter-of-introduction-list'

//interest type
export const ADD_INTEREST='/settings/interesttype/create-or-update-interest-type'
export const INTEREST_LIST='/settings/interesttype/get-interest-type-list'
export const INTEREST_DETAILS='/settings/interesttype/get-interest-type-details'
export const INTEREST_DELETE='/settings/interesttype/delete-interest-type'
export const INTEREST_DROPDOWN='/settings/interesttype/get-interest-type-dropdown'
export const STATUS_CHANGING_INTEREST='/settings/interesttype/update-status'


//interest 
export const ADD_INTEREST_TYPE='/settings/interest/create-or-update-interest'
export const INTEREST_TYPE_LISTIING='/settings/interest/get-interest-list'
export const INTEREST_TYPE_DETAILS='/settings/interest/get-interest-detail'
export const INTEREST_TYPE_DELETE='/settings/interest/delete-interest'
export const STATUS_CHANGING_INTEREST_TYPE='/settings/interest/update-status'

//user-management
export const USER_MANAGEMENT_PERMISSION="/user/retrieve-permissions"
export const USER_MANAGEMENT_CREATE_ROLE="/user/roles/create-or-update-role"
export const USER_MANAGEMENT_RETRIVE_ROLES="/user/roles/retrieve-roles"
export const USER_MANAGEMENT_ROLE_INFO="/user/roles/retrieve-role-info"
export const USER_MANAGEMENT_DELETE_ROLE="/user/roles/destroy-role"
export const USER_MANAGEMENT_RETRIVE_GROUPS="/user/groups/retrieve-groups"
export const USER_MANAGEMENT_ROLS_DROPDOWN="/user/roles/role-list-dropdown"
export const USER_MANAGEMENT_CREATE_GROUP="/user/groups/create-or-update-group"
export const USER_MANAGMENT_DESTROY_GROUP='/user/groups/destroy-group'
export const USER_MANAGMENT_DETAILS_GROUP='/user/groups/retrieve-group-info'
export const USER_MANAGMENT_CREATE_USER='/user/users/create-or-update-user'
export const USER_MANAGMENT_DETAIL_USER='/user/users/retrieve-user-info'
export const USER_MANAGMENT_GROUP_DROPDOWN='/user/groups/group-list-dropdown'
export const USER_MANAGMENT_LIST_USER='/user/users/retrieve-users'
export const USER_MANAGMENT_ISACTIVE_STATUS='/user/users/activate-or-deactivate-users'
export const USER_MANAGMENT_PERMISSION_DROPDOWN='/user/retrieve-direct-permissions'
export const DELETE_USER ="/user/users/delete-user"
//Booking
export const MEMBER_REQUEST_AND_FACILITY='/club/booking/super-admin-club-bookings'
export const MEMBER_REQUEST_AND_FACILITY_ADMIN='/club/booking/admin-club-bookings'
export const MEMBER_REQUEST_AND_FACILITY_DETAILS='/club/booking/get-club-booking-detail'
export const RECIPROCAL_REQUEST_BOOKING='/club/booking/admin-reciprocalclub-bookings'
export const ADMIN_REQUEST_LOI='/club/booking/admin-request-letter'
export const RECIPROCAL_REQUEST_FOR_MODERATION_LIST='/club/booking/super-admin-reciprocalclub-bookings'
export const CLUB_BOOKING_MEMBER_MESSAGE_LIST="/club/admin-booking/admin-list-message-booking"
export const CLUB_ADMIN_BOOKING_CANCEL="/club/admin-booking/club-admin-booking-cancel"
export const CLUB_ADMIN_BOOKING_MODIFY="/club/club-profile/club-booking"
export const CLUB_ADMIN_BOOKING_ACCEPT ='/club/admin-booking/club-admin-booking-accept'
export const CLUB_ADMIN_BOOKING_DECLINED ='/club/admin-booking/club-admin-booking-decline'
export const CLUB_ADMIN_SEND_MESSAGE_BOOKING = '/club/admin-booking/admin-sent-message-booking'
export const CLUB_ADMIN_ASSIGN_MEMBER = '/user/users/retrieve-user-dropdown'
export const CLUB_ADMIN_SEND_MEMBER_VERIFICATON_BOOKING = '/club/admin-booking/send-member-verification-booking'
export const ASSIGN_ADMIN_FOR_REQUEST = '/club/admin-booking/assign-admin-for-request'
export const CLUB_ADMIN_BOOKING_UPDATE ="/club/admin-booking/club-admin-booking-update"
export const CLUB_RECIPROCAL_EXCEL='/club/booking/club-rc-moderation-booking-xcel-download'
export const CLUB_BOOKING_EXCEL_SHEET='/club/booking/club-booking-xcel-download'
export const CLUB_ADMIN_BOOKING_ACCEPT_RC='/club/admin-booking/homeclub-request-accept'
export const CLUB_ADMIN_BOOKING_DECLINED_RC='/club/admin-booking/homeclub-request-decline'
export const CLUB_ADMIN_BOOKING_DECLINE='/club/admin-booking/club-admin-booking-reject'
export const ADD_NOTES = '/club/booking/booking-add-notes'
export const ADD_STRIPE_LINK = "/club/booking/booking-add-stripe-link"
export const PAYMENT_CONFIRMED='/club/admin-booking/booking-payment-approve'

// RC Moderation Booking 
export const RC_MODERATION_BOOKING_LIST='/club/admin-booking/admin-rc-moderation-bookings'

// specail user
export const SPECAIL_SUBSCRIBE_USER='/premium/special-subscribers/create-special-subscribers'
export const LIST_SPECAIL_SUBSCRIBE_USER='/premium/special-subscribers/list-special-subscribers'
export const DETAIL_SPECIAL_SUBSCRBE_USER='/user/special-user/get-special-user-details'
export const DELETE_SPECIAL_SUBSCRIBE_USER='/premium/special-subscribers/delete-special-subscribers'

// affiliated clubs
export const AFFILIATED_CLUBS_LIST='/club/club-profile/get-reciporcal-club-for-club'
export const ADD_AFFILIATED_CLUB="/club/reciprocal-club/create-or-update-reciprocal-club"

// Club invitation
export const LIST_CLUB_INVITATION="/club/club-invitation/get-club-invitation-list"
export const GET_CLUB_INVITATION_DETAIL="/club/club-invitation/get-club-invitation-details"
export const ADD_CLUB_INVITATION="/club/club-invitation/create-or-update-club-invitation"
export const UPDATE_CLUB_INVITATION_STATUS="/club/club-invitation/club-invitation-publish-status-change"
export const SEND_CLUB_INVITATION="/club/club-invitation/send-notification-club-invitation"
export const DELETE_CLUB_INVITATION="/club/club-invitation/delete-club-invitation"

// manage network
export const MANAGE_NETWORK_LISTING='/club/manage-network/network-club-list-all'
export const REQUEST_STATUS_CHANGE='/club/manage-network/network-club-status-change'
export const NETWORK_ACCEPT_ALL='/club/manage-network/accept-all-network-club'
export const NETWORK_BLOCK_ALL='/club/manage-network/block-all-network-club'
export const RESTRICT_RC='/club/manage-network/rc-restrict'

//subscription
export const CREATE_SUBSCRIPTION="/premium/subscriber-privileges/create-or-update-subscriber-privileges"
export const LIST_SUBSCRIPTION="/premium/subscriber-privileges/get-subscriber-privileges-list"
export const SUBSCRIPTION_DETAIL="/premium/subscriber-privileges/get-subscriber-privileges-detail"
export const SUBSCRIPTION_FEATURES="/premium/subscriber-privileges/get-feature-list"
export const DELETE_SUBSCRIPTION="/premium/subscriber-privileges/delete-subscriber-privileges"

// MemberReview
export const LIST_MEMBER_REVIEW='/club/member-review/get-member-review'
export const DELETE_MEMBER_REVIEW='/club/member-review/delete-member-review'
export const DETAIL_MEMBER_REVIEW='/club/member-review/get-member-review-detail'


// Notifications
export const GET_NOTIFICATIONS_LIST='/settings/notification/get-user-notification-list'
export const READ_USER_NOTIFICATION='/settings/notification/read-user-notification'
export const ACCEPT_BOOKING_NOTIFICATION='/club/admin-booking/accept-member-verification-booking'
export const REJECT_BOOKING_NOTIFICATION='/club/admin-booking/reject-member-verification-booking'
// Push notifications
export const CREATE_NOTIFICATION_CLUB='/settings/notification/send-notification-by-club'
export const LIST_NOTIFICATION_CLUB='/settings/notification/push-notification-by-club-list'
// clubtype
export const  LIST_CLUB_TYPE='/settings/clubtype/get-clubtype-list'
export const DELETE_CLUB_TYPE='/settings/clubtype/delete-clubtype'
export const CREATE_CLUB_TYPE='/settings/clubtype/create-or-update-clubtype'
export const DETAILS_CLUB_TYPE='/settings/clubtype/get-clubtype-details'
export const CLUB_TYPE_STATUS_CHANGE='/settings/clubtype/club-type-status-change'


// user subscriotion list
export const USER_SUBSCRIPTION_LIST='/premium/user-subscription/get-user-subscription-list'


