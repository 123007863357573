import DataTable from "react-data-table-component";
import { FaSyncAlt } from "react-icons/fa";
// Drawer
import { Form } from 'react-bootstrap';
// Rsuite Datepicker
import { DatePicker } from 'rsuite';
import { useState } from "react";

const SyncContact = () => {
    const [isRotating, setIsRotating] = useState(false);

    const handleClick = () => {
        setIsRotating(true);

        // Stop rotation after 1 second (match the animation duration)
        setTimeout(() => {
        setIsRotating(false);
        }, 1000);
    };

    // Data for the table
    const data = [
        { srNo: "1", lastSyncedFrom: "December 05, 2024", newMembersSyncedOn: "December 30, 2024" },
        { srNo: "2", lastSyncedFrom: "December 10, 2024", newMembersSyncedOn: "December 31, 2024" },
        { srNo: "3", lastSyncedFrom: "December 15, 2024", newMembersSyncedOn: "January 01, 2025" },
        { srNo: "4", lastSyncedFrom: "December 20, 2024", newMembersSyncedOn: "January 02, 2025" },
        { srNo: "5", lastSyncedFrom: "December 25, 2024", newMembersSyncedOn: "January 03, 2025" },
        { srNo: "6", lastSyncedFrom: "December 28, 2024", newMembersSyncedOn: "January 04, 2025" },
        { srNo: "7", lastSyncedFrom: "January 01, 2025", newMembersSyncedOn: "January 05, 2025" },
        { srNo: "8", lastSyncedFrom: "January 02, 2025", newMembersSyncedOn: "January 06, 2025" },
        { srNo: "9", lastSyncedFrom: "January 03, 2025", newMembersSyncedOn: "January 07, 2025" },
        { srNo: "10", lastSyncedFrom: "January 04, 2025", newMembersSyncedOn: "January 08, 2025" },
      ];
      
    const columns = [
    { name: "Sl.No", selector: row => row.srNo, minWidth: "100px"  },
    { name: "Last Synced From", selector: row => row.lastSyncedFrom, sortable: true, reorder: true },
    { name: "New Members Synced On", selector: row => row.newMembersSyncedOn, sortable: true, reorder: true },
    ];

    // Custom styling for the table
    const customStyles = {
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",        
                border: "1px solid var(--border-color)",
                overflow: "hidden"
            },
    },
        headCells: {
        style: {
            backgroundColor: "var(--light-blue)",
            justifyContent: 'flex-start !important',
            color: "var(--dark-gray)",
            fontWeight: "400",
            fontSize: "15px",
        },
        },
        rows: {
        style: {
            height: "45px", // Set row height
            justifyContent: "start",
        },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    };


  return (
    <div className='sync-contact-section member-stats-section'>
        <div className='page-details'>
            <div className='top-section d-flex align-items-center justify-content-between'>
                <div className='left'>Sync Contacts</div>
                <div className='right'>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <div className='d-flex align-items-center gap-3'>
                            {/* Sync new members from (Last email Blast) */}
                            <div className='item'>                        
                                <Form.Group className="d-flex align-items-center gap-4 justify-content-between" controlId="exampleForm.ControlInput1em3">
                                    <Form.Label className='m-0' style={{fontWeight: "500", fontSize: "14px"}}>Sync new members from (the last email blast.)</Form.Label>
                                    <div className='input-border'>
                                        <DatePicker 
                                            id='sync_date' 
                                            placeholder="Select a date to sync"
                                            placement="autoVerticalEnd"
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className='submit-box cursor-pointer d-flex align-items-end justify-content-end gap-3' onClick={handleClick}>
                                <FaSyncAlt className={`sync-icon ${isRotating ? "rotating" : ""}`} title="Sync Contacts"/>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

            <div className='table-box mt-3'>
                <DataTable
                    columns={columns}
                    data={data}
                    customStyles={customStyles}
                    fixedHeader
                    highlightOnHover
                    responsive
                />
            </div>
        </div>
    </div>
  )
}

export default SyncContact