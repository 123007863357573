import { Row, Col, Form, Button, Modal } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { ADD_USER, BOOKINGS, CLUB_PROFILES } from "../../constants/pathname"
import { useEffect, useState } from 'react';
import { addloiadmin, addNotes, addStripe, adminBookingAccept, adminBookingCancel, adminBookingDeclined, adminRcBookingAccept, adminRcBookingDeclined, adminSendMessageBooking, assignAdminForRequest, getMember, getMemberRequestBookingDetails, listBookingMessages, sendmemberVerification } from '../../api/actions/bookings';
import ViewCal from '../../assets/images/common/view_cal.svg';
import ViewCal2 from '../../assets/images/common/view_cal2.svg'
import dayjs from 'dayjs';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Select from "react-select"
import * as Yup from "yup";
import { ErrorMessage, useFormik } from 'formik';
import AddClubBankModal from '../../components/MemberRequest/addClubBankDetails';
import ModifyBookingModal from '../../components/MemberRequest/modifyBookingModal';
import toast from 'react-hot-toast';
import { checkTheme, checkThemePcw } from '../../utils/utils';
import AsyncSelect from 'react-select/async';
import SweetAlert from 'react-bootstrap-sweetalert';
import Sweetalert from './Sweetalert';
import DefaultImage from "../../assets/images/common/img1.png"
import { ReactComponent as AddIcon } from "../../assets/images/common/add-icon.svg";
import AddUserModal from '../../components/MemberRequest/addUserModal';


const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid var(--light-grayish)',
    outline: 'none',
    boxShadow: 'none',
    color: 'var(--medium-gray)',
    fontSize: '15px',
    '&:hover': {
      border: '1px solid var(--light-grayish)', // Prevent hover border color
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--medium-gray)',
    fontSize: '15px',
    paddingLeft: "5px"
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'var(--medium-gray)',
    fontSize: '15px',
  }),
  menu: (provided) => ({
    ...provided,
    border: '1px solid var(--light-grayish)',
    boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? 'var(--light-grayish)' : 'white',
    color: 'var(--medium-gray)',
    fontSize: '15px',
  }),
};

const selectStyle = {
  control: (styles, { isFocused }) => ({
    ...styles,
    borderRadius: '5px',
    maxHeight: "40px",
    minHeight: "30px",
    boxShadow: 'none',
    width: "200px",
    fontSize: "15px",
    backgroundColor: 'white',
    borderColor: isFocused ? 'gray' : styles.borderColor,
  }),
  placeholder: (styles) => ({
    ...styles,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "15px",
    paddingLeft: "5px"
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: 'white',
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? 'lightgray' : 'white',
    color: 'black',
  }),
};

const ViewReciprocalRequests = () => {

  const navigate = useNavigate();
  const { id } = useParams();
  const [bookingDetails, setBookingDetails] = useState(null);
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [cancelationModal, setCancelationModal] = useState(false)
  const [update, setUpdate] = useState(false)
  const [openBankModal, setOpenBankModal] = useState(false);
  const [openModifyBooking, setOpenModifyBooking] = useState(false)
  const [pending, setPending] = useState(false)
  const [updateMessage, setUpdateMessage] = useState(false)
  const [openUserModal, setOpenUserModal] = useState(false)
  const [updateUser, setUpdateUser] = useState(false);

  const [isOpen, setIsOpen] = useState(true);
  const [isOpenOne, setIsOpenOne] = useState(true);
  const [isOpenTwo, setIsOpenTwo] = useState(true);
  const [isOpenThree, setIsOpenThree] = useState(true);
  const [isOpenFour, setIsOpenFour] = useState(true);
  const [isOpenFive, setIsOpenFive] = useState(true);
  const [isOpenSix, setIsOpenSix] = useState(true);
  const [advancePayment, setAdvancePayment] = useState(false);
  const [member, setMember] = useState([])
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [showAlertAdmin, setShowAlertAdmin] = useState(false);
  const [pendingSelectionAdmin, setPendingSelectionAdmin] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [pendingSelection, setPendingSelection] = useState(null);
  const [selectedClub, setSelectedClub] = useState(null);
  const [links, setLinks] = useState('')
  const [showModalLink, setShowModalLink] = useState(false);

  const [showModalNotes, setShowModalNotes] = useState(false);


  let userInfo = JSON.parse(localStorage.getItem("user_info"));



  const [messages, setMessages] = useState([
  ]);
  const [newMessage, setNewMessage] = useState("");
  const [notes, setNotes] = useState('')

  const handleSendMessage = () => {
    if (!newMessage.trim()) return;

    // setMessages([...messages, { text: newMessage, sender: "user" }]);

    adminSendMessageBooking({
      booking_id: bookingDetails?.id,
      country: bookingDetails?.country,
      message: newMessage
    })((res) => {
      if (res.status_code == 200 || res?.status_code == 201) {
        setUpdateMessage(!updateMessage)
        setNewMessage("");
      }
    })
  };

  const handleSendNotes = () => {
    if (!notes.trim()) return;


    addNotes({
      id: bookingDetails?.id,
      country: bookingDetails?.country,
      note_text: notes
    })((res) => {
      if (res.status_code == 200 || res?.status_code == 201) {
        setNotes("");
        setShowModalNotes(!showModalNotes)
        setUpdate(!update)
      }
    })
  };

  const handleAddLink = () => {
    if (!links.trim()) return;

    const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;

    if (!urlPattern.test(links)) {
      toast.error("Please enter a valid URL");
      return;
    }

    addStripe({
      id: bookingDetails?.id,
      country: bookingDetails?.country,
      link: links
    })((res) => {
      if (res.status_code === 200 || res?.status_code === 201) {
        setLinks("");
        setShowModalLink(!showModalLink);
        setUpdate(!update);
      }
    });
  };


  const validationSchemaCancelBooking = Yup.object({
    cancel_type: Yup.string().required("Please select a cancellation type"),
    home_club_message: Yup.string().required("Cancellation reason is required"),
  });

  const formik = useFormik({
    initialValues: {
      id: '',
      country: '',
      advance_payment: 0,
      additional_charges: '',
      total: ''
    },
    onSubmit: (values, { resetForm }) => {

      const processedValues = {
        id: values.id || null,
        country: values.country || null,
        // advance_payment: values.advance_payment || 0,
        // additional_charges: values.additional_charges || 0,
        // total: values.total || 0
      };

      setPending(true)
      adminRcBookingAccept(processedValues)((res) => {
        if (res?.status) {
          setPending(false)
          resetForm()
          setUpdate(!update)
          toast.success("Booking Accepted")
        }
        setPending(false)
      })
    }
  })




  const cancelFormik = useFormik({
    initialValues: {
      id: '',
      home_club_message: "",
      country: ''
    },
    // validationSchema: validationSchemaCancelBooking,
    onSubmit: (values, { resetForm }) => {
      console.log("Submitting form with values:", values);
      adminRcBookingDeclined(values)((res) => {
        if (res?.status_code === 200 || res?.status_code === 201) {
          setUpdate(!update)
          resetForm()
          setCancelationModal(!cancelationModal)
        }
      })
    },
  })


  useEffect(() => {
    fetchBookingDetails();
  }, [id, update]);

  useEffect(() => {
    fetchMessagesList();
    if (bookingDetails) {
      cancelFormik.setValues({
        ...cancelFormik.values,
        id: bookingDetails.id,
        country: bookingDetails.country
      });
      formik.setValues({
        ...formik.values,
        id: bookingDetails.id,
        country: bookingDetails.country,
        advance_payment: bookingDetails.advance_payment,
        additional_charges: bookingDetails.additional_charges,
        total: bookingDetails.total
      });
    }
  }, [bookingDetails, updateMessage])


  const fetchBookingDetails = () => {
    if (id) {
      getMemberRequestBookingDetails({ id })((response) => {
        if (response.status) {
          setBookingDetails(response.data);
        }
      });
    }
  };

  const fetchMessagesList = () => {
    if (bookingDetails) (
      listBookingMessages({
        id: bookingDetails?.id, country: bookingDetails?.country,
        //  pcn: !checkThemePcw() 
      })((res) => {
        if (res?.status) {
          // console.log(res);
          setMessages(res?.data?.results)
        }
      })
    )

  }
  useEffect(() => {
    getMember({ page: 1, limit: 10 })((res) => {
      if (res?.status_code === 200 || res?.status_code === 201) {
        setMember(res?.data)
      }
    })
  }, [])

  let typingTimer;

  const loadOptionsMember = (inputValue) => {
    return new Promise((resolve) => {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        if (inputValue.length >= 3) {
          getMember({ page: 1, limit: 10, search_param: inputValue })((response) => {
            if (response?.status_code === 200) {
              const options = response?.data?.results || [];
              setMember(options);
              resolve(options);
            } else {
              resolve([]);
            }
          });
        } else {
          resolve([]);
        }
      }, 300);
    });
  };

  const handleChangeAdmin = (newSelection) => {
    setSelectedMembers(newSelection || []);
  };

  const handleAssignClick = () => {
    if (selectedMembers.length === 0) {
      alert("Please select at least one member.");
      return;
    }
    setPendingSelectionAdmin(selectedMembers);
    setShowAlertAdmin(true);
  };

  const handleConfirmAdmin = () => {
    setShowAlertAdmin(false);

    assignAdminForRequest({ booking_id: bookingDetails?.id, country: bookingDetails?.country, assigned_users: pendingSelectionAdmin.map((member) => member.value) })((res) => {
      if (res?.status) {
        console.log(res)
        setUpdate(!update)
      }
    })
  };

  const handleCancelAdmin = () => {
    setShowAlertAdmin(false);
  };

  const handleChange = (selectedOption) => {
    if (!selectedOption) {
      setSelectedClub(null);
      return;
    }

    setPendingSelection(selectedOption);
    setShowAlert(true);
  };

  const handleConfirm = () => {
    setShowAlert(false);
    setSelectedClub(pendingSelection);

    sendmemberVerification({ booking: bookingDetails?.id, country: bookingDetails?.country, home_club: pendingSelection?.value, home_club_country: pendingSelection?.country })((res) => {
      if (res?.status) {
        setPendingSelection(null);
        setUpdate(!update)
      }
    })
  }

  const handleCancel = () => {
    setShowAlert(false);
    setPendingSelection(null);
  };



  if (!bookingDetails) return <div>Loading...</div>;



  const clubDetails = bookingDetails?.club_details || {};
  const letterOfIntroduction = bookingDetails?.letter_of_introduction || false;
  const letterOfIntroductionPDF = bookingDetails?.letter_of_introduction_pdf || '';
  const adminRequestLetter = bookingDetails?.admin_request_letter || false;
  // Conditions to hide both buttons
  const shouldHideButtons =
    // clubDetails?.gpcc === true &&
    clubDetails?.is_reciprocal_club === true ||
    clubDetails?.solitaire_club === true;


  const handleClubClick = () => {
    const clubId = bookingDetails?.club_details?.club;
    const country = bookingDetails?.club_details?.country;

    if (clubId && country) {
      navigate(`${CLUB_PROFILES}${clubId}`, {
        state: { country }
      });
    }
  };


  const handleRequestLoi = () => {
    if (!bookingDetails?.id || !bookingDetails?.country) return;
    addloiadmin({ values: { id: bookingDetails.id, country: bookingDetails.country } })((response) => {
      if (response.status) {
      } else {
      }
      setShowSweetAlert(false);
      fetchBookingDetails();
    });
  };

  const handleDecline = () => {
    if (bookingDetails) {
      adminBookingDeclined({
        id: bookingDetails.id,
        country: bookingDetails.country
      })((res) => {
        if (res?.status_code === 200 || res?.status_code === 201) {
          setUpdate(!update)
          toast.success("Booking Declined")
        }
      })
    }
  }

  const testTwo = (status) => {
    if (userInfo?.is_superuser) {
      return status
    }
    switch (status) {
      case "In Review": {
        return status
      }
      case "Home Club Declined": {
        return status
      }
      default: {
        return "Accepted"
      }
    }
  }


  return (
    <div className='row create-clubs-info-section view-memebr-request'>
      <div className='col-md-3'>
        <div className='booking-overview1'>
          <div className='booking-overview-club'>
            <div className='img-wraper'>
              <img src={bookingDetails?.club_details?.main_image || DefaultImage} alt={bookingDetails?.club_details?.club_name} />
              <div className='tags-container'>
                {bookingDetails?.club_details?.solitaire_club && (
                  <div className='tag solitaire-tag'>Solitaire</div>
                )}
                {bookingDetails?.club_details?.is_reciprocal_club && (
                  <div className='tag reciprocal-tag'>Reciprocal</div>
                )}
              </div>
            </div>
            <div className='club-name-wraper'>
              <div className='club-name-sec'>
                <p className='club_name_title'>Club Name</p>
                <p className='club_name_main' onClick={handleClubClick}>{bookingDetails?.club_details?.club_name}</p>
              </div>
            </div>
          </div>
        </div>

        {/* booking details */}
        <div className='booking_stats'>
          <div className='row booking_stats_inside'>
            <div className='col-md-12 col-xl-3'>
              <img src={ViewCal} />
            </div>
            <div className='col-md-12 col-xl-9'>
              <p className='booking_date'>Booking Date</p>
              <div className='row mt-3 date_view'>
                <div className='col-md-12 col-xl-6'>
                  <p style={{ fontSize: "14px" }}>Start Date</p>
                  <p style={{ fontSize: "15px" }}>{dayjs(bookingDetails?.start_date).format('MMM D, YYYY')}</p>
                </div>
                <div className='col-md-12 col-xl-6'>
                  <p style={{ fontSize: "14px" }}>End Date</p>
                  <p style={{ fontSize: "15px" }}>{dayjs(bookingDetails?.end_date).format('MMM D, YYYY')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='booking_divider'></div>
          <div className='row booking_status_inside'>
            <div className='col-md-12 col-xl-3'>
              <img src={ViewCal2} />
            </div>
            <div className='col-md-12 col-xl-9'>
              <p className='booking_date'>Status</p>
              <button type="button" className="btn-type-7">
                {testTwo(bookingDetails?.status)}
              </button>
            </div>
          </div>
        </div>

        {(userInfo?.is_superuser && bookingDetails?.note_text) &&
          <div className='notes_section'>
            <div className='head'>
              <p>Notes</p>
            </div>
            <div className='body'>
              {bookingDetails?.note_text}
            </div>
          </div>
        }

      </div>

      {/* main section  */}

      <div className='col-md-9'>
        <Sweetalert
          showSweetAlert={showSweetAlert}
          setShowSweetAlert={setShowSweetAlert}
          onConfirm={handleRequestLoi}
          content="Do you really want to request a Letter of Introduction?"
        />
        <Form onSubmit={(e) => e.preventDefault()}>
          <div className='page-details'>
            {/* Booking Details */}
            <div className='panel-type-1a' onClick={() => setIsOpen(!isOpen)}>
              Booking Details
              {isOpen ? <FaChevronUp className="accordion-icon" /> : <FaChevronDown className="accordion-icon" />}
            </div>
            <div className={`accordion-content ${isOpen ? "open" : ""}`}>
              <div className='grid mt-5' style={{ gap: "6px" }}>
                {/* Member Name */}
                <div>
                  <Form.Group controlId='memberName'>
                    <Form.Label>Member Name</Form.Label>
                    <Form.Control
                      type='text'
                      value={bookingDetails.member?.member_name || "N/A"}
                      name='memberName'
                      disabled
                    />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group controlId='clubName'>
                    <Form.Label>Member Home Club(S)</Form.Label>
                    <Form.Control
                      type='text'
                      value={bookingDetails.member_club[0]?.club || "N/A"}
                      name='clubName'
                      // style={{ color: '#c58f5a', cursor: 'pointer' }}

                      disabled
                    />
                  </Form.Group>
                </div>
                {/* Request Type */}
                <div>
                  <Form.Group controlId='requestType'>
                    <Form.Label>Request Type</Form.Label>
                    <p style={{ cursor: "not-allowed" }}>{bookingDetails.request_type || "N/A"}</p>
                    {/* <Form.Control
                      type='text'
                      value={bookingDetails.request_type || "N/A"}
                      name='requestType'
                      disabled
                    /> */}
                  </Form.Group>
                </div>

                {/* Booking ID */}
                <div>
                  <Form.Group controlId='bookingId'>
                    <Form.Label>Booking ID</Form.Label>
                    <Form.Control
                      type='text'
                      value={bookingDetails.booking_id || "N/A"}
                      name='bookingId'
                      disabled
                    />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group controlId="transactionId">
                    <Form.Label>Transaction ID</Form.Label>
                    <Form.Control
                      type="text"
                      value="None"
                      name="transactionId"
                      disabled={true}
                    />
                  </Form.Group>
                </div>
                {/* No of Guests */}
                <div>
                  <Form.Group controlId="noOfGuests">
                    <Form.Label>No of Guests</Form.Label>
                    <Form.Control
                      type="text"
                      value={bookingDetails.guest_number || "0"}
                      name="noOfGuests"
                      disabled
                    />
                  </Form.Group>
                </div>

                {/* Requested On */}
                <div>
                  <Form.Group controlId="requestedOn">
                    <Form.Label>Requested On</Form.Label>
                    <Form.Control
                      type="text"
                      value={dayjs(bookingDetails.created_date).format('MMM D, YYYY') || "N/A"}
                      name="requestedOn"
                      disabled
                    />
                  </Form.Group>
                </div>


                {/* Arrival Time */}
                <div >
                  <Form.Group controlId="arrivalTime">
                    <Form.Label>Arrival Time</Form.Label>
                    <Form.Control
                      type="text"
                      value={bookingDetails.arrival_time || "N/A"}
                      name="arrivalTime"
                      disabled
                    />
                  </Form.Group>
                </div>
                {/* <div>
                  <Form.Group controlId="extraServices">
                    <Form.Label>Reciprocal</Form.Label>
                    <Form.Control
                      type="text"

                      value={bookingDetails?.member_club[0]?.is_reciprocal ? "Yes" : "No"}
                      disabled={true}
                    />
                  </Form.Group>
                </div> */}
                {/* <div>
                  <Form.Group controlId="extraServices">
                    <Form.Label>GPCC</Form.Label>
                    <Form.Control
                      type="text"

                      value={bookingDetails?.member_club[0]?.gpcc ? "Yes" : "No"}
                      disabled={true}
                    />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group controlId="extraServices">
                    <Form.Label>Extra Services Required</Form.Label>
                    <Form.Control
                      type="text"

                      value={bookingDetails.special_request ? "Yes" : "No"}
                      disabled={true}
                    />
                  </Form.Group>
                </div> */}
                {bookingDetails?.home_club_message &&
                  <div>
                    <Form.Group controlId="reason">
                      <Form.Label>Cancellation Reason</Form.Label>
                      <Form.Control
                        type="text"

                        value={bookingDetails?.home_club_message}
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                }

              </div>
              {bookingDetails?.special_request && (
                <div className='row mt-5'>
                  <div>
                    <Form.Group controlId="specialRequest">
                      <Form.Label>Special Requests/Instructions</Form.Label>
                      <div dangerouslySetInnerHTML={{ __html: bookingDetails.special_request }}></div>
                    </Form.Group>
                  </div>
                </div>
              )}

            </div>

            {/* two */}
            {bookingDetails?.status !== "Expired" && (
              <>
                <div className='panel-type-1a mt-2' onClick={() => setIsOpenTwo(!isOpenTwo)}>
                  Member Verification (Optional)
                  {isOpenTwo ? <FaChevronUp className="accordion-icon" /> : <FaChevronDown className="accordion-icon" />}
                </div>
                <div className={`accordion-content ${isOpenTwo ? "open" : ""}`}>
                  <div className='grid mt-1'>
                    {bookingDetails?.member_verification_status ? (
                      <>
                        <p className='text-center'>{bookingDetails?.member_verification_status}</p>
                      </>
                    ) : (
                      <>
                        <Form.Group controlId="clubs">
                          {/* <Form.Label>Member Verification</Form.Label> */}

                          <Select
                            placeholder="Select "
                            styles={customStyles}
                            options={bookingDetails?.member_club?.map((itm) => {
                              return {
                                label: itm.club,
                                value: itm.club_id
                              }
                            })}
                            onChange={handleChange}
                            isClearable
                          />
                        </Form.Group>
                      </>
                    )}
                  </div>
                </div>
              </>
            )

            }

            {/* three */}

            {!userInfo?.is_superuser && bookingDetails?.status == "In Review" &&
              <>
                <div className='panel-type-1a mt-2' onClick={() => setIsOpenThree(!isOpenThree)}>
                  Assign To
                  {isOpenTwo ? <FaChevronUp className="accordion-icon" /> : <FaChevronDown className="accordion-icon" />}
                </div>
                <div className={`accordion-content ${isOpenThree ? "open" : ""}`}>
                  <div className='d-flex gap-3'>
                    <div className=''>
                      <Form.Group controlId="clubs">
                        <AsyncSelect
                          loadOptions={loadOptionsMember}
                          defaultOptions={member || []}
                          classNamePrefix="select"
                          isClearable={true}
                          isMulti
                          placeholder={'Select'}
                          value={selectedMembers}
                          onChange={handleChangeAdmin}
                          styles={{
                            control: (styles) => ({
                              ...styles, borderRadius: 'none', fontSize: '13px', minHeight: '41px', maxHeight: "auto", minWidth: "200px", maxWidth: "300px", border: '1px solid var(--light-grayish)', boxShadow: 'unset', borderRadius: "5px",
                              '&:hover': {
                                border: '1px solid var(--light-grayish)',
                              }
                            }),
                            valueContainer: (styles) => ({
                              ...styles, alignItems: 'center', padding: '3px 8px',

                            }),
                            indicatorSeparator: (styles) => ({

                              ...styles, marginBottom: '11px', marginTop: '6px'
                            }),
                            dropdownIndicator: (styles) => ({
                              ...styles, paddingTop: '2px'
                            }),
                            clearIndicator: (styles) => ({
                              ...styles, paddingTop: '2px'
                            }),
                            placeholder: (styles) => ({
                              ...styles, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", paddingLeft: "5px"
                            })
                          }}
                        />
                      </Form.Group>

                    </div>
                    <button variant="primary" className="btn-type-7 h-25" onClick={handleAssignClick}>
                      Assign Admin
                    </button>
                    <div className='add_user_booking'>
                      <AddIcon size={18} onClick={() => setOpenUserModal(true)} title="Add User" className="cursor-pointer" />
                    </div>
                  </div>
                  {showAlertAdmin && (
                    <SweetAlert
                      warning
                      showCancel
                      confirmBtnText="Yes, Confirm!"
                      cancelBtnText="No, Cancel"
                      confirmBtnStyle={{ backgroundColor: "var(--sandy-brown)", borderColor: "var(--sandy-brown)", color: "#fff", textDecoration: "none" }}
                      cancelBtnBsStyle="default"
                      title="Are you sure?"
                      onConfirm={handleConfirmAdmin}
                      onCancel={handleCancelAdmin}
                    >
                      Are you sure you want to assign this booking to new admin?<br />
                      {/* {pendingSelectionAdmin.map((admin) => (
                        <li key={admin.value}>{admin.label}</li>
                      ))} */}
                    </SweetAlert>
                  )}
                </div>
              </>
            }
            {
              bookingDetails?.tee_time_request ? (
                <>
                  <div className='panel-type-1a mt-2' onClick={() => setIsOpenOne(!isOpenOne)}>
                    Golf Booking Details
                    {isOpenOne ? <FaChevronUp className="accordion-icon" /> : <FaChevronDown className="accordion-icon" />}
                  </div>
                  <div className={`accordion-content ${isOpenOne ? "open" : ""}`}>
                    <div className='mt-2'>
                      {/* Request for Tee Time */}
                      <div className='mb-1 d-flex'>
                        <Form.Group controlId='teeTime' className='d-flex'>
                          <Form.Label className='w-100'>Request for Tee Time :</Form.Label>
                          <Form.Control
                            type='text'
                            value={bookingDetails.tee_time_request ? "Yes" : "No"}
                            name='teeTime'
                            disabled
                          />
                        </Form.Group>
                      </div>

                    </div>
                  </div>
                </>
              ) : (
                null
              )
            }


            {/* LOI */}
            <Ternary when={shouldHideButtons} show={
              <Ternary when={userInfo?.is_superuser} show={
                <HandleSuperAdmin status={letterOfIntroduction} pdf={letterOfIntroductionPDF} />
              }
                otherwise={
                  <HandleClubAdmin status={letterOfIntroduction} pdf={letterOfIntroductionPDF} onClick={() => setShowSweetAlert(true)} disabled={adminRequestLetter} />
                }
              />
            } />

            {/* {shouldHideButtons ? null : 
             userInfo?.is_superuser? 
              <HandleSuperAdmin status={letterOfIntroduction} pdf={letterOfIntroductionPDF} />
              : <HandleClubAdmin status={letterOfIntroduction} pdf={letterOfIntroductionPDF} />
            } */}

            {/* { !userInfo?.is_superuser && (
                <>
            <div className='panel-type-1a mt-2' onClick={() => setIsOpenSix(!isOpenSix)}>
              LOI
              {isOpenSix ? <FaChevronUp className="accordion-icon" /> : <FaChevronDown className="accordion-icon" />}
            </div>

            <div className={`accordion-content ${isOpenSix ? "open" : ""}`}>
              
                  <div className='buttonletter-container'>

                  {!letterOfIntroduction && bookingDetails?.status !== "Expired" && !userInfo?.is_superuser && (
                    <Button className='custom-button' style={{ height: "50px" }} onClick={() => setShowSweetAlert(true)} disabled={adminRequestLetter}>
                      {adminRequestLetter ? 'Request for Letter of Introduction (Pending)' : 'Request for Letter of Introduction'}
                    </Button>
                  )}


                    {letterOfIntroduction && letterOfIntroductionPDF && (
                      <Button
                        style={{height:"40px"}}
                        className='custom-button'
                        onClick={() => window.open(letterOfIntroductionPDF, '_blank')}
                      >
                        View Letter for Introduction
                      </Button>
                    )}
                  </div>
            </div>
                </>
               )} */}

            {/* Facilities */}
            {bookingDetails?.facility?.length > 0 &&
              <>
                <div className='panel-type-1a mt-2' onClick={() => setIsOpenFour(!isOpenFour)}>
                  Facilities
                  {isOpenFour ? <FaChevronUp className="accordion-icon" /> : <FaChevronDown className="accordion-icon" />}
                </div>
                <div className={`accordion-content ${isOpenOne ? "open" : ""}`}>
                  <div className='mt-2'>
                    <div className='d-flex gap-2 flex-wrap'>
                      {bookingDetails?.facility?.map((facility, index) => {
                        console.log(facility, "||CHSH")
                        return (
                          <div className='facility_itm' key={index}>
                            <img src={checkTheme() ? facility?.pcw_icon_class : facility?.pcn_icon_class || ""} alt='img' />
                            <p className='fac_name'>{facility?.facility_name}</p>
                          </div>
                        )
                      }
                      )}
                    </div>
                  </div>
                </div>
              </>
            }


            <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-5'>
              {!userInfo?.is_superuser && bookingDetails?.status === "In Review" && (
                <div
                  className={`btn-type-accept cursor-pointer ${!advancePayment || formik.values.advance_payment === "" || formik.values.advance_payment < 0 || pending ? 'disabled' : ''}`}
                  onClick={() => {
                    // if (advancePayment && formik.values.advance_payment !== "" && formik.values.advance_payment >= 0) {
                    formik.handleSubmit();
                    // }
                  }}
                  style={{
                    pointerEvents: pending
                      // !advancePayment || formik.values.advance_payment === "" || formik.values.advance_payment < 0
                      ? "none"
                      :
                      "auto",
                    opacity: pending
                      // !advancePayment || formik.values.advance_payment === "" || formik.values.advance_payment < 0
                      ? 0.5
                      :
                      1,
                  }}
                >
                  Accept
                </div>
              )}


              {!userInfo?.is_superuser && bookingDetails?.status === "In Review" && (
                <div className='btn-type-8 cursor-pointer'
                  onClick={() => {
                    cancelFormik.setValues({
                      id: bookingDetails.id,
                      country: bookingDetails.country,
                      home_club_message: ""
                    });
                    setCancelationModal(true);
                  }}>
                  Reject
                </div>
              )
              }

              {!bookingDetails?.status == "In Review" &&
                <div className='btn-type-2 cursor-pointer' onClick={() => setShowModal(!showModal)}>Messages</div>
              }

              {userInfo?.is_superuser &&
                <div className='btn-type-2 cursor-pointer' onClick={() => setShowModalNotes(!showModalNotes)}>Add Notes</div>
              }

              {(userInfo?.is_superuser && bookingDetails?.status === "Pending Payment") &&
                <div className='btn-type-2 cursor-pointer' onClick={() => setShowModalLink(!showModalLink)}>Add Payment Link</div>
              }

            </div>
          </div>
        </Form>
      </div>


      <Modal show={showModal} onHide={() => { setShowModal(!showModal) }}>
        <Modal.Header closeButton>
          <p className='view_message_header'>View Messages</p>
        </Modal.Header>
        <Modal.Body>
          <div className="message_container">

            <div className="message_list">
              {messages?.map((msg) => {
                return (
                  <div key={msg.id} className={`message_wrapper ${msg.send_by === "club" ? "received" : "sent"}`}>
                    <div className={`message ${msg.send_by === "club" ? "received" : "sent"}`}>
                      {msg.message_body}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          {/* <Form> */}
          {!userInfo?.is_superuser &&
            <>
              <Form.Group as={Row} className="mb-3">
                <Col sm="12">
                  <Form.Control
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type your message here.."
                  />
                </Col>
              </Form.Group>
              <div className='d-flex justify-content-end'>
                <button className='btn-type-7' onClick={handleSendMessage} >Send</button>
              </div>
            </>
          }
        </Modal.Body>
      </Modal>


      <Modal show={showModalNotes} onHide={() => { setShowModalNotes(!showModalNotes) }}>
        <Modal.Header closeButton>
          <p className='view_message_header'>Add Notes</p>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form.Group as={Row} className="mb-3">
              <Col sm="12">
                <Form.Control
                  as="textarea"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder="Type your notes here..."
                  rows={4}
                />
              </Col>
            </Form.Group>
            <div className='d-flex justify-content-end'>
              <button className='btn-type-7' onClick={handleSendNotes} >Add</button>
            </div>
          </>
        </Modal.Body>
      </Modal>

      <Modal show={showModalLink} onHide={() => { setShowModalLink(!showModalLink) }}>
        <Modal.Header closeButton>
          <p className='view_message_header'>Add Payment Link</p>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form.Group as={Row} className="mb-3">
              <Col sm="12">
                <Form.Control
                  type="text"
                  value={links}
                  onChange={(e) => setLinks(e.target.value)}
                // placeholder="Enter payment link"
                />
              </Col>
            </Form.Group>
            <div className='d-flex justify-content-end'>
              <button className='btn-type-7' onClick={handleAddLink} >Add</button>
            </div>
          </>
        </Modal.Body>
      </Modal>


      <Modal show={cancelationModal} onHide={() => { setCancelationModal(!cancelationModal) }}>
        <Modal.Header closeButton>
          <p className='view_message_header m-0'>Cancel Booking</p>
        </Modal.Header>
        <Form onSubmit={cancelFormik?.handleSubmit}>
          <Modal.Body>

            <Form.Group className="mt-1">
              <Form.Label>Cancellation Reason</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={cancelFormik?.values?.home_club_message}
                onChange={(e) => cancelFormik?.setFieldValue('home_club_message', e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <button className='btn-type-7' type="submit">Send</button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      {openBankModal &&
        <AddClubBankModal
          openBankModal={openBankModal}
          setOpenBankModal={setOpenBankModal}
        />
      }
      {openModifyBooking &&
        <ModifyBookingModal
          openModifyBooking={openModifyBooking}
          setOpenModifyBooking={setOpenModifyBooking}
          bookingDetails={bookingDetails}
          update={update}
          setUpdate={setUpdate}
        />
      }

      {openUserModal &&
        <AddUserModal
          openUserModal={openUserModal}
          setOpenUserModal={setOpenUserModal}
          userInfo={userInfo}
          setUpdateUser={setUpdateUser}
          updateUser={updateUser}
        />
      }

      {showAlert && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, Confirm!"
          cancelBtnText="No, Cancel"
          confirmBtnStyle={{ backgroundColor: "var(--sandy-brown)", borderColor: "var(--sandy-brown)", color: "#fff", textDecoration: "none" }}
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        >
          Do you want to select {pendingSelection?.label}?
        </SweetAlert>
      )}

    </div>
  )
}

export default ViewReciprocalRequests;


const HandleSuperAdmin = ({ status, pdf }) => {
  if (status) {
    return (
      <Wrapper>
        <ViewLetterButton pdf={pdf} />
      </Wrapper>
    )
  }
  return null
}

const HandleClubAdmin = ({ status, pdf, disabled, onClick, }) => {
  if (status) {
    return (
      <Wrapper>
        <ViewLetterButton pdf={pdf} />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Button className='custom-button' style={{ height: "50px" }} onClick={onClick} disabled={disabled}>
        {
          disabled ? "Request for Letter of Introduction (Pending)" : "Request for Letter of Introduction"
        }

      </Button>
    </Wrapper>

  )
}


const ViewLetterButton = ({ pdf }) => {
  return (

    <Button
      style={{ height: "40px" }}
      className='custom-button'
      onClick={() => window.open(pdf, '_blank')}
    >
      View Letter for Introduction
    </Button>
  )
}


// const RequestLetterButton =() => {



//   return (
//     <Button className='custom-button' style={{ height: "50px" }} onClick={() => setShowSweetAlert(true)} disabled={adminRequestLetter}>
//     {adminRequestLetter ? 'Request for Letter of Introduction (Pending)' : 'Request for Letter of Introduction'}
//   </Button>
//   )
// }


const Wrapper = ({ children }) => {
  const [isOpenSix, setIsOpenSix] = useState(true)
  return (
    <>
      <div className='panel-type-1a mt-2' onClick={() => setIsOpenSix(!isOpenSix)}>
        LOI
        {isOpenSix ? <FaChevronUp className="accordion-icon" /> : <FaChevronDown className="accordion-icon" />}
      </div>
      <div className={`accordion-content ${isOpenSix ? "open" : ""}`}>
        <div className='buttonletter-container'>
          {children}
        </div>
      </div>
    </>
  )

}


const Ternary = ({ when, show, otherwise = null }) => {
  return when ? show : otherwise

}