import React, { useEffect, useState } from 'react'
import { ReactComponent as AddIcon } from "../../assets/images/common/add-icon.svg";
import { ReactComponent as ViewIcon } from "../../assets/images/common/view-icon.svg";
import { ReactComponent as ExcelIcon } from "../../assets/images/common/sheets.svg";
import { ReactComponent as EditIcon } from "../../assets/images/common/edit-icon.svg";
import uploadIcon from "../../assets/images/common/upload_icon.png";

import { ADD_CLUB_TYPE, CLUB_ONBOARDIN, EDIT_CLUB_ONBOARDING, EDIT_CLUB_TYPE, VIEW_CLUINFO } from '../../constants/pathname';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { bulkUploadClub, getemailsendig, listClubProfile } from '../../api/actions/clubProfile';
import { paginationRowsPerPageOptions } from '../../helpers/helper';
import { FaTimes } from 'react-icons/fa';
import { MdFileUpload } from "react-icons/md"
import { RiMailSendLine } from "react-icons/ri";
import { ReactComponent as CloseIcon } from "../../assets/images/common/close-icon.svg";
import { styled } from '@mui/material/styles';
import toast from 'react-hot-toast';
import SweetAlert from 'react-bootstrap-sweetalert';
import { createClubtypeStatuschange, deleteClubType, listClubType } from '../../api/actions/clubtype';

import { interestStatusChanging } from '../../api/actions/interest';
import { FormControlLabel, RadioGroup, Switch } from "@mui/material";
import { listuserSubscriptionlist } from '../../api/actions/userSubscriptionlist';
import dayjs from 'dayjs';


const UserSubscriptionList = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [isDeleting, setIsDeleting] = useState(false);
    const [limit, setLimit] = useState(25);

    const [search, setSearch] = useState('');
    const [update, setUpdate] = useState(false);
    const [pending, setPending] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showSweetAlert, setShowSweetAlert] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isSwal, setIsSwal] = useState({ show: false, id: '' });
    const [showDrawer, setShowDrawer] = useState(false);
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [searchText, setSearchText] = useState('');
    const handleShowBlkm = () => setShowModal(true);
    const handleCloseBlkm = () => setShowModal(false);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedClub, setSelectedClub] = useState(null);
    const customStyles = {
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",
                border: "1px solid var(--border-color)",
                overflow: "hidden"
            },
        },
        headCells: {
            style: {
                backgroundColor: "var(--light-blue)",
                justifyContent: 'flex-start !important',
                color: "var(--dark-gray)",
                fontWeight: "400",
                fontSize: "15px",
            },
        },
        rows: {
            style: {
                height: "45px", // Set row height
                justifyContent: "start",
            },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    };
    const handleSearch = (value) => {
        setSearch(value);
        setSearchText(value);
    }

    useEffect(() => {
        if (search.length >= 2) {
            const debounceTimer = setTimeout(() => {
                setDebouncedSearch(search);
            }, 500);

            return () => clearTimeout(debounceTimer);
        } else {
            setDebouncedSearch('');
        }
    }, [search]);

    const clearSearch = () => {
        setSearch('');
        setSearchText('');
        setDebouncedSearch('');
        setUpdate(!update);
    };
    useEffect(() => { getListData(); }, [page, limit, debouncedSearch, update]);

    const getListData = () => {
        setPending(true);

        if (debouncedSearch) {
            setPage(1);
        }
        listuserSubscriptionlist({
            page: debouncedSearch ? 1 : page,
            limit,
            search: debouncedSearch,
        })(
            (response) => {
                if (response && response.status) {
                    setData(response?.data?.results);
                    setTotalRows(response?.data?.count);
                    setPending(false);
                }
            }
        );
    };







    const CustomSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 39,
        height: 20,
        padding: 0,
        marginLeft: 5,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            // margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: 'var(--sandy-brown)',
                    opacity: 1,
                    border: 0,
                    ...theme.applyStyles('dark', {
                        backgroundColor: '#2ECA45',
                    }),
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.grey[100],
                ...theme.applyStyles('dark', {
                    color: theme.palette.grey[600],
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                backgroundColor: 'var(--sandy-brown)',
                opacity: 0.7,
                ...theme.applyStyles('dark', {
                    opacity: 0.3,
                }),
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 20,
            height: 20,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#E9E9EA',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
            ...theme.applyStyles('dark', {
                backgroundColor: '#39393D',
            }),
        },
    }));

    const columns = [
        {
            name: "Sl.No",
            key: "name",
            selector: row => row?.name,
            cell: (row, index) =>
                <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.name}>
                    {(limit * (page - 1)) + (index + 1)}
                </div>,
            sortable: true,
            grow: 2,
            reorder: true,
            width: '110px'
        },
        { name: "User", selector: row => <div title={row.user}>{row.user}</div>, sortable: true, minWidth: "250px" },

        {
            name: "Start Date & End Date",
            selector: row => (
                <div title={`${row.user} - ${row.plan?.plan_name || "No Plan"}`}>
                    <div>
                        {row.start_date ? dayjs(row.start_date).format("MMM D, YYYY") : "N/A"} -{" "}
                        {row.end_date ? dayjs(row.end_date).format("MMM D, YYYY") : "N/A"}
                    </div>


                </div>
            ),
            sortable: true,
            minWidth: "250px",
        },
        {
            name: "Plan Name",
            selector: row => <div>{row.plan?.plan_name || "No Plan"}</div>,
            sortable: true,
            minWidth: "200px",
        },
        // {
        //     name: "Active/Inactive",
        //     selector: row => (
        //         <div className='view-icon cursor-pointer' style={{ width: "140px", height: "50px", paddingTop: "9px", overflow: "visible", border: "none" }}>
        //             <div style={{ display: 'flex', alignItems: 'center' }}>
        //                 <span style={{ marginRight: '10px', color: row.is_type_publish ? 'var(--light-green)' : 'red' }}></span>
        //                 {/* <CustomSwitch
        //                     checked={row.is_type_publish}
        //                     onChange={() => handleToggleClick(row.id)}
        //                 /> */}
        //             </div>
        //         </div>
        //     ),
        //     minWidth: "180px"
        // },


        // {
        //     name: "Actions",
        //     selector: row =>
        //         <div className='d-flex align-items-center gap-3'>
        //             {/* <div className='view-icon cursor-pointer'  onClick={() => navigate(VIEW_CLUINFO + row?.id, {
        //                             state: { country: row?.country }
        //                         })} title='View'><ViewIcon /></div> */}
        //             <div
        //                 className='view-icon cursor-pointer'
        //                 onClick={() => navigate(EDIT_CLUB_TYPE + row?.id, {
        //                     state: { country: row?.country }
        //                 })}
        //                 title='Edit'
        //             >
        //                 <EditIcon />
        //             </div>

        //             <div
        //                 title='Delete'
        //                 className='view-icon cursor-pointer'
        //                 onClick={() => setIsSwal({ show: true, id: row?.id })}
        //             >
        //                 <CloseIcon style={{ marginLeft: "2px" }} />
        //             </div>

        //         </div>,
        //     width: "200px"
        // }
    ];

    const handleDelete = (id) => {

        deleteClubType(id)((response) => {
            if (response && response?.status) {
                toast.success('Successfully deleted');
            } else {
                toast.error('Deletion failed');
            }
            setIsSwal({ show: false, id: null });
            setUpdate(!update);
        });
    };

    return (
        <div className='manage-admins-section'>
            {showSweetAlert && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    confirmBtnCssClass="custom-confirm-button-class"
                    cancelBtnCssClass="custom-cancel-button-class"
                    title={
                        <>
                            <span className="custom-sweetalert-title">Change Status</span>
                            <div className='text-center' style={{ fontSize: "12px", fontWeight: "normal", lineHeight: 2 }}>
                                Are you sure you want to change the status?
                            </div>
                        </>
                    }
                    // onConfirm={confirmToggle}
                    onCancel={() => setShowSweetAlert(false)}
                    focusCancelBtn
                />
            )}

            <div className='page-details'>
                <div className='top-section d-flex align-items-center justify-content-between'>
                    <div className='left'>
                        User Subscription List
                    </div>
                    <div className='right d-flex align-items-center gap-3'>
                        <div className='search-box'>
                            <input
                                type='text'
                                placeholder='Search '
                                className='form-control'
                                value={searchText}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                            {
                                searchText && (
                                    <FaTimes className='clear-icon' onClick={clearSearch} />
                                )}
                        </div>


                        {/* <div className='add-clubs-info cursor-pointer' onClick={() => navigate(ADD_CLUB_TYPE, { state: { currentPageName: "Add Club" } })} title="Add Club">
                            <AddIcon />
                        </div> */}

                    </div>

                </div>
                <div className='table-box mt-3'>
                    <DataTable
                        columns={columns}
                        data={data}
                        customStyles={customStyles}
                        fixedHeader
                        highlightOnHover
                        responsive
                        pagination
                        paginationServer
                        paginationPerPage={limit}
                        progressPending={pending}
                        onChangeRowsPerPage={setLimit}
                        paginationTotalRows={totalRows}
                        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                        onChangePage={setPage}

                    />
                </div>

            </div>

        </div>
    )
}

export default UserSubscriptionList
