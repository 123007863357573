import React from 'react'
import CreateInterest from '../../components/CreateInterest/CreateInterest'

const CreateInterestPage = () => {
  return (
    <div className='create-interest-page'>
        <CreateInterest />
    </div>
  )
}

export default CreateInterestPage