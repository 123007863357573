import { useFormik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import Select from 'react-select';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { CLUB_ONBOARDING_LIST, SUBSCRIPTION_PRIVILEGES } from '../../constants/pathname';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { createClubProfile, getClubProfile, getemailsendig } from '../../api/actions/clubProfile';
import toast from 'react-hot-toast';
import { ReactComponent as AddIconBlack } from "../../assets/images/common/add-icon-black.svg";
import { ReactComponent as MinusIcon } from "../../assets/images/common/minus-icon.svg";
import { ReactComponent as UploadIcon } from "../../assets/images/common/cloud-upload-alt.svg";
import * as Yup from 'yup';
import { checkThemePcw } from '../../utils/utils';
import {
    ClassicEditor,
    Autoformat,
    Autosave,
    Bold,
    Essentials,
    Heading,
    Italic,
    Paragraph,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    Undo,
    List,
    Alignment
} from "ckeditor5";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { DatePicker } from 'rsuite';
import { RiDeleteBin6Line } from 'react-icons/ri';
import imageToBase64 from 'image-to-base64/browser';
import { createSubscription, getSubscriptionDetail, getSubscriptionFeatures, } from '../../api/actions/subscription';
const SubscriptionPrivilege = ({ modal = false, setShowModal, setRefresh, refresh }) => {
    const autocompleteRef = useRef(null);
    const navigate = useNavigate();
    const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_API_KEY;
    const { id } = useParams()
    const location = useLocation();
    const { country } = location.state || {};
    const [isChecked, setIsChecked] = useState(false);
    const [features, setFeatures] = useState([])
    const [loading, setLoading] = useState(false);
    const [isKycCompleted, setIsKycCompleted] = useState(false);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries: ['places'],
    });
    const editorContainerRef = useRef(null);
    const editorRef = useRef(null);
    const [isLayoutReady, setIsLayoutReady] = useState(true);

    // Editor configuration
    const editorConfig = {
        toolbar: {
            items: [
                "undo",
                "redo",
                "|",
                "|",
                "heading",
                "|",
                "bold",
                "italic",
                "bulletedList",
                "numberedList",
                "|",
                "alignment"
            ],
            shouldNotGroupWhenFull: false,
        },
        alignment: {
            options: ['left', 'right', 'center', 'justify']
        },
        plugins: [
            Autoformat,
            Autosave,
            Bold,
            Essentials,
            Heading,
            Italic,
            Paragraph,
            List,
            TextTransformation,
            Table,
            TableCaption,
            TableCellProperties,
            TableColumnResize,
            TableProperties,
            TableToolbar,
            Alignment,
            Undo,
        ],
        heading: {
            options: [
                {
                    model: "paragraph",
                    title: "Paragraph",
                    class: "ck-heading_paragraph",
                },
                {
                    model: "heading1",
                    view: "h1",
                    title: "Heading 1",
                    class: "ck-heading_heading1",
                },
                {
                    model: "heading2",
                    view: "h2",
                    title: "Heading 2",
                    class: "ck-heading_heading2",
                },
                {
                    model: "heading3",
                    view: "h3",
                    title: "Heading 3",
                    class: "ck-heading_heading3",
                },
                {
                    model: "heading4",
                    view: "h4",
                    title: "Heading 4",
                    class: "ck-heading_heading4",
                },
                {
                    model: "heading5",
                    view: "h5",
                    title: "Heading 5",
                    class: "ck-heading_heading5",
                },
                {
                    model: "heading6",
                    view: "h6",
                    title: "Heading 6",
                    class: "ck-heading_heading6",
                },
            ],
        },
        initialData: "",
        menuBar: {
            isVisible: true,
        },
        placeholder: "Type or paste your content here!",
    };
    const validationSchema = Yup.object({
        title: Yup.string().required('Required'),
        subscription_plan: Yup.array().of(
            Yup.object().shape({
                plan_name: Yup.string().required('Required'),
                subscription_type: Yup.string().required('Required'),
                plan_amount: Yup.string().required('Required')
            })
        ),

        // primary_address: Yup.string().required('Required'),
        // country: Yup.string().required('Required'),
        // city: Yup.string().required('Required'),
        // zip_code: Yup.string().required('Required')
    });


    const formik = useFormik({
        initialValues: {
            title: '',
            image: '',
            subscription_plan: [{
                subscription_type: '',
                selectedType: '',
                plan_name: '',
                plan_description: '',
                plan_amount: ''
                // start_date: '',
                // end_date: ''
            }],
            subscription_inclusions: [{
                inclusion: ''
            }],
            subscription_terms: [{
                terms: ''
            }],
            features: []

        },
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            values.subscription_type = values?.subscription_plan?.[0]?.subscription_type
            if (values.image.startsWith('https://')) {
                values.image = ''
            }
            setLoading(true);
            createSubscription(values)((response) => {
                if (response.status_code === 200 || response.status_code === 201) {
                    toast.success(!modal && id ? "Subscription Updated Successfully" : 'Subscription Created Successfully!');
                    resetForm();
                    !modal ? navigate(SUBSCRIPTION_PRIVILEGES) : setShowModal(false)
                } else {
                    toast.error('Failed to create subscription!');
                }
                setLoading(false);
            });
        },
    });






    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            outline: 'none',
            boxShadow: 'none',
            color: 'var(--medium-gray)',
            fontSize: '15px',
            '&:hover': {
                border: '1px solid var(--light-grayish)', // Prevent hover border color
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        menu: (provided) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            boxShadow: 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--light-grayish)' : 'white',
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
    };


    useEffect(() => {
        if (id) {
            getSubscriptionDetail({ id })(async (response) => {
                if (response?.status) {
                    let subscription_plans = response.data.subscription_plans
                    subscription_plans[0].subscription_type = response?.data?.subscription_type
                    subscription_plans[0].selectedType = response?.data?.subscription_type == 'Yearly' ? { label: "Yearly", value: 'Yearly' } : response?.data?.subscription_type == 'link' ? { label: "Link", value: 'link' } : ''
                    // Set form values
                    formik.setValues({
                        ...formik.values,
                        id: response?.data?.id,
                        image: response?.data?.image || '',
                        title: response?.data?.title,
                        subscription_plan: subscription_plans,
                        subscription_inclusions: response?.data?.inclusions,
                        subscription_terms: response?.data?.terms,
                        features: response?.data?.features

                    });




                }
            });
        }
    }, [id, country]);

    useEffect(() => {
        getSubscriptionFeatures()((res) => {
            if (res?.status) {
                setFeatures(res?.data?.results)
            }
        })


    }, [])

    // const handleRuleInputChange = (index, value) => {
    //     let filteredList = formik?.values?.subscription_inclusions.map((item, i) => (i === index ? { ...item, subscription_inclusions: value } : item));

    //     formik?.setValues({
    //         ...formik?.values,
    //         subscription_inclusions: filteredList
    //     });
    // };
    const addRule = () => {
        formik?.setValues({
            ...formik?.values,
            subscription_inclusions: [
                ...formik?.values?.subscription_inclusions,
                { inclusion: "" }
            ]
        });
    };
    const addTerms = () => {
        formik?.setValues({
            ...formik?.values,
            subscription_terms: [
                ...formik?.values?.subscription_terms,
                { terms: "" }
            ]
        });
    };
    const removeRule = (index) => {
        let filteredList = formik?.values?.subscription_inclusions?.filter((item, i) => i !== index);
        formik?.setValues({
            ...formik?.values,
            subscription_inclusions: filteredList
        });
    };
    const removeTerms = (index) => {
        let filteredList = formik?.values?.subscription_terms?.filter((item, i) => i !== index);
        formik?.setValues({
            ...formik?.values,
            subscription_terms: filteredList
        });
    };
    const handlePlanSelectChange = (option, name, selectName) => {
        let temp = [...formik.values.subscription_plan]
        temp[0][name] = option?.value
        temp[0][selectName] = option

        formik?.setValues({
            ...formik?.values,
            subscription_plan: temp
        })
    }
    const handleDateChange = (val, name) => {
        let temp = [...formik.values.subscription_plan]
        temp[0][name] = val

        formik?.setValues({
            ...formik?.values,
            subscription_plan: temp
        })
    }
    const removeThumbNail = () => {
        formik.setValues({
            ...formik.values,
            image: '', // Ensure images is an array
            thumbnailName: ""
        });
    }
    const handleImageChange = async (e) => {
        const files = Array.from(e?.target?.files);
        const maxSizeInBytes = 1 * 1024 * 1024;
        const validImageTypes = ['image/jpeg', 'image/png'];

        const file = files[0];

        if (!validImageTypes.includes(file?.type)) {
            toast.error("Only jpg/jpeg and png files are allowed!");
            e.target.value = null;
            return;
        }

        if (file?.size > maxSizeInBytes) {
            toast.error("File size exceeds 2 MB limit", 'error');
            e.target.value = null;
            return;
        }

        const images = await Promise.all(
            files.map(async (file) => {

                try {
                    const response = await imageToBase64(URL.createObjectURL(file));
                    return `data:image/jpeg;base64,${response}`;
                } catch (error) {
                    return null;
                }
            })
        );

        // Setting file name
        let fileName = "";
        if (Array.isArray(files) && files[0]?.name) {
            fileName = files[0].name;
        }

        formik.setValues({
            ...formik.values,
            image: images?.[0], // Ensure images is an array
            thumbnailName: fileName,
        });

        e.target.value = null;
    }
    const handleToggleChange = (id) => {
        let feature = [...formik.values.features]
        if (feature.includes(id)) {
            let filterFeature = feature.filter(item => item != id)
            formik.setValues({
                ...formik.values,
                features: filterFeature
            })
        }
        else {
            feature.push(id)
            formik.setValues({
                ...formik.values,
                features: feature
            })
        }

    }
    return (
        <div className='create-clubs-profile-page create-clubs-info-section'>
            <div className='page-details'>
                {!modal && <div className='panel-type-1'>

                    {id ? "Edit Subscription" : " Add Subscription"}

                </div>}

                <Form onSubmit={formik.handleSubmit}>
                    <div className={`club-form ${modal ? '' : 'mt-5'}`}>
                        <div className=''>
                            <div className='row'>
                                <div className='col-md-3'>
                                    {/* Upload Thumbnail */}
                                    <div className='item upload'>
                                        <label>Upload Image</label>
                                        <div className='d-flex align-items-end mt-2'>
                                            <div>
                                                {!formik.values?.image
                                                    ?
                                                    <label className='upload-icon cursor-pointer image-picker-custom ' htmlFor='fileInput'>
                                                        <UploadIcon />
                                                        <div className='mt-3'>Upload</div>
                                                    </label>
                                                    :
                                                    <div className={`uploaded-images upload-image-custom active`}>
                                                        <div className='pos-rel' style={{ width: "107px", height: "107px" }}>
                                                            <img src={formik.values?.image} alt={`Uploaded`} className="uploaded-image" style={{ width: '100%', height: '100%' }} />
                                                        </div>
                                                    </div>}
                                                {formik?.values?.thumbnailName &&
                                                    <div className='filename mt-2 d-flex align-items-center justify-content-between gap-2'>
                                                        <div className='name' title={formik?.values?.thumbnailName || ""}>{formik?.values?.thumbnailName || ""}</div>
                                                        <RiDeleteBin6Line size={16} fill='var(--red)' title='Delete' onClick={() => removeThumbNail()} />
                                                    </div>}
                                                <div className='mt-2 w-75'>
                                                    {!formik.values?.image &&
                                                        <div className='info m-0'>
                                                            Use JPEG or PNG. Best size 1280×720 pixels. Keep it under 1MB
                                                        </div>}
                                                </div>
                                            </div>
                                            <input
                                                id="fileInput"
                                                className='d-none'
                                                type="file"
                                                onChange={handleImageChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-9'>
                                    <div className='row'>

                                        <div className="col-xl-4 col-md-4 mb-3">
                                            <Form.Group controlId="clubName">
                                                <Form.Label>Title<span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className='dotted-input-half'
                                                    placeholder="Title"
                                                    name='title'
                                                    value={formik.values?.title}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        formik.setFieldValue(
                                                            'title',
                                                            value.charAt(0).toUpperCase() + value.slice(1)
                                                        );
                                                    }}
                                                    isInvalid={formik.touched.title && !!formik.errors.title}
                                                />
                                                <Form.Control.Feedback type="invalid">{formik.touched.title && formik.errors.title}</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-xl-4 col-md-4 mb-3">
                                            <Form.Group controlId="golf_booking">
                                                <Form.Label>Subscription Type<span className='required'>*</span></Form.Label>
                                                <Select
                                                    placeholder="Select"
                                                    styles={customStyles}
                                                    options={[{ label: "Yearly", value: 'Yearly' }, { label: "Link", value: 'link' }]}
                                                    value={formik?.values?.subscription_plan?.[0]?.selectedType}
                                                    name="selectedType"
                                                    onChange={(option) => {
                                                        handlePlanSelectChange(option, 'subscription_type', 'selectedType')
                                                    }}
                                                />
                                                {formik.touched.subscription_plan?.[0]?.subscription_type && !!formik.errors.subscription_plan?.[0]?.subscription_type ?
                                                    <div className='custom-required'>Required</div>
                                                    :
                                                    <></>
                                                }
                                            </Form.Group>
                                        </div>
                                        <div className="col-xl-4 col-md-4 mb-3">
                                            <Form.Group controlId="clubName">
                                                <Form.Label>Plan Name<span className='required'>*</span></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className='dotted-input-half'
                                                    placeholder="Plan Name"
                                                    name='subscription_plan.[0].plan_name'
                                                    value={formik.values?.subscription_plan?.[0]?.plan_name}
                                                    onChange={(e) => {
                                                        e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                                                        formik.handleChange(e)
                                                    }}
                                                    isInvalid={formik.touched.subscription_plan?.[0]?.plan_name && !!formik.errors.subscription_plan?.[0]?.plan_name}
                                                />
                                                <Form.Control.Feedback type="invalid">{formik.touched.subscription_plan?.[0]?.plan_name && formik.errors.subscription_plan?.[0]?.plan_name}</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-xl-4 col-md-4 mb-3">
                                            <Form.Group controlId="total_members">
                                                <Form.Label>Plan Amount</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Plan Amount"
                                                    name="subscription_plan.[0].plan_amount"
                                                    onWheel={(e) => e.target.blur()}
                                                    value={formik?.values?.subscription_plan?.[0]?.plan_amount}
                                                    onChange={formik?.handleChange}
                                                    isInvalid={formik.touched.subscription_plan?.[0]?.plan_amount && !!formik.errors.subscription_plan?.[0]?.plan_amount}
                                                />
                                                <Form.Control.Feedback type="invalid">{formik.touched.subscription_plan?.[0]?.plan_amount && formik.errors.subscription_plan?.[0]?.plan_amount}</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        {/* <div className='col-xl-4 col-md-4 mb-2 start-date-input'>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1em11">
                                                <Form.Label>Start Date <span className="required">*</span></Form.Label>
                                                <div className='input-border'>
                                                    <DatePicker
                                                        id='start_date'
                                                        onChange={(value) => {
                                                            handleDateChange(value, 'start_date')
                                                        }}
                                                        placement="autoVerticalEnd"
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className='col-xl-4 col-md-4 mb-2 end-date-input'>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1em4">
                                                <Form.Label>End Date <span className="required">*</span></Form.Label>
                                                <div className=''>
                                                    <DatePicker
                                                        id='end_date'
                                                        onChange={(value) => handleDateChange(value, 'end_date')}
                                                        placement="autoVerticalEnd"
                                                    // shouldDisableDate={(date) => {
                                                    //     // Disable dates before the selected Start Date
                                                    //     return fromDate ? date < fromDate : false;
                                                    // }}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div> */}

                                    </div>
                                </div>
                            </div>
                            <div className="mt-5">
                                <div className='panel-type-1'>
                                    Description
                                </div>
                                <div
                                    className="editor-container editor-container_classic-editor mt-4"
                                    ref={editorContainerRef}
                                >
                                    <div className="editor-container__editor">
                                        <div ref={editorRef} id="#editor">
                                            {isLayoutReady && (
                                                <CKEditor
                                                    data={formik?.values?.subscription_plan?.[0]?.plan_description || ""}
                                                    editor={ClassicEditor}
                                                    config={editorConfig}
                                                    onReady={(editor) =>
                                                        (editorRef.current = editor)
                                                    }
                                                    onChange={(event, editor) => {
                                                        const editorData = editor.getData();
                                                        let temp = [...formik.values.subscription_plan]
                                                        temp[0].plan_description = editorData
                                                        // formik?.setValues({
                                                        //     ...formik?.values,
                                                        //     subscription_plan: temp,
                                                        // });
                                                        formik.setFieldValue('subscription_plan[0].plan_description', editorData)
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='panel-type-1 mt-5 d-flex justify-content-between'>
                                <div>Subscription Inclusions <span className="required">*</span></div>
                                <AddIconBlack size={18} onClick={addRule} title="Add Rule" className="cursor-pointer" />
                            </div>
                            <div className="onboard-rules-box row m-0">
                                <div className="col-md-6">
                                    {formik?.values?.subscription_inclusions.map((item, index) => (
                                        <div className="item border-type-1 d-flex align-items-center mt-4 p-2" key={index}>
                                            <div className="col-md-11">
                                                <Form.Control
                                                    type="text"
                                                    className="input-field border-0 p-0"
                                                    name={`subscription_inclusions[${index}].inclusion`}
                                                    placeholder="Enter Inclusion"
                                                    value={item.inclusion}
                                                    onChange={(e) =>

                                                        formik.handleChange(e)}
                                                />
                                            </div>
                                            <div className="col-md-1 d-flex justify-content-end cursor-pointer">
                                                <MinusIcon size={18} onClick={() => removeRule(index)} title="Remove" />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='panel-type-1 mt-5 d-flex justify-content-between'>
                                <div>Subscription Terms <span className="required">*</span></div>
                                <AddIconBlack size={18} onClick={addTerms} title="Add Rule" className="cursor-pointer" />
                            </div>
                            <div className="onboard-rules-box row m-0">
                                <div className="col-md-6">
                                    {formik?.values?.subscription_terms.map((item, index) => (
                                        <div className="item border-type-1 d-flex align-items-center mt-4 p-2" key={index}>
                                            <div className="col-md-11">
                                                <Form.Control
                                                    type="text"
                                                    className="input-field border-0 p-0"
                                                    name={`subscription_terms[${index}].terms`}
                                                    placeholder="Enter Terms"
                                                    value={item.terms}
                                                    onChange={(e) =>

                                                        formik.handleChange(e)}
                                                />
                                            </div>
                                            <div className="col-md-1 d-flex justify-content-end cursor-pointer">
                                                <MinusIcon size={18} onClick={() => removeTerms(index)} title="Remove" />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='panel-type-1 mt-5 d-flex justify-content-between'>
                                <div>Features <span className="required">*</span></div>
                                {/* <AddIconBlack size={18} onClick={addTerms} title="Add Rule" className="cursor-pointer" /> */}
                            </div>
                            <div className="m-0 mt-3">
                                {features?.map((item, index) =>
                                    <div className="feature-item" key={index}>
                                        <Form.Check
                                            type="switch"
                                            id={`toggle-${index}`}
                                            label={item?.benefits}
                                            checked={formik.values.features?.includes(item?.id)}
                                            onChange={() => handleToggleChange(item?.id)}
                                        />
                                    </div>
                                )}
                            </div>

                            <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-5'>
                                <button className='btn-type-2 cursor-pointer' type='button' onClick={() => { !modal ? navigate(SUBSCRIPTION_PRIVILEGES) : setShowModal(false) }}>Cancel</button>
                                <button className='btn-type-5' type="submit"  disabled={loading}>{id && !modal ? "Update" : "Save"}</button>



                            </div>


                        </div>

                    </div>
                </Form>

            </div>

        </div>
    )
}

export default SubscriptionPrivilege
