import React from 'react'

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        border: '1px solid var(--light-grayish)',
        outline: 'none',
        boxShadow: 'none',
        color: 'var(--medium-gray)',
        fontSize: '15px',
        '&:hover': {
            border: '1px solid var(--light-grayish)', // Prevent hover border color
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'var(--medium-gray)',
        fontSize: '15px',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'var(--medium-gray)',
        fontSize: '15px',
    }),
    menu: (provided) => ({
        ...provided,
        border: '1px solid var(--light-grayish)',
        boxShadow: 'none',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? 'var(--light-grayish)' : 'white',
        color: 'var(--medium-gray)',
        fontSize: '15px',
    }),
};

const AddAffiliatedClubs = () => {
  return (
    <div className='create-affiliated-page '>
        <div className="page-details">
            <div className="panel-type-1">
                Add Affiliated Club
            </div>
        </div>
    </div>
  )
}

export default AddAffiliatedClubs