import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

const SweetAlertModal = ({ showSweetAlert, setShowSweetAlert, memberName }) => {
    return (
        <div>
        {showSweetAlert &&
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="Cancel"
                confirmBtnCssClass="custom-confirm-button-class"
                cancelBtnCssClass="custom-cancel-button-class"
                title={<span className="custom-sweetalert-title">{memberName ? `Are you sure you want to delete ${memberName}?` : 'Are you sure you want to delete?'}</span>}
                onConfirm={() => setShowSweetAlert(true)}
                onCancel={() => setShowSweetAlert(false)}
                focusCancelBtn
            />}
    </div>
    );
}

export default SweetAlertModal;
