import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { CORPORATE } from '../../constants/pathname';
import Select from 'react-select';
import { useFormik } from 'formik';

const CreateCorporate = () => { 
  const navigate = useNavigate();

  const formik = useFormik({
        initialValues : {
        countryName: ""
        },
        onSubmit: (values, resetForm)=>{
    
        }
    });

  const customStyles = {
    control: (provided, state) => ({
        ...provided,
        border: '1px solid var(--light-grayish)',
        outline: 'none',
        boxShadow: 'none',
        color: 'var(--medium-gray)',
        fontSize: '15px',
        '&:hover': {
            border: '1px solid var(--light-grayish)', // Prevent hover border color
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'var(--medium-gray)',
        fontSize: '15px',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'var(--medium-gray)',
        fontSize: '15px',
    }),
    menu: (provided) => ({
        ...provided,
        border: '1px solid var(--light-grayish)',
        boxShadow: 'none',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? 'var(--light-grayish)' : 'white',
        color: 'var(--medium-gray)',
        fontSize: '15px',
    }),
  };

  const countryOptions = [{ label: 'United States of America', value: 'usa' }];


  return ( 
    <div className='create-clubs-info-section create-interest-section'>
        <div className='page-details'>
            <div className='panel-type-1'>
                Add New Corporate
            </div>
            <Form onSubmit={(e) => e.preventDefault()}>
                <div className='mt-5'>
                    <div className="row"> 
                        {/* Name */}
                        <div className="col-md-4 mb-3">
                            <Form.Group controlId="name">
                                <Form.Label>Name<span className='required'>*</span></Form.Label>
                                <Form.Control type="text" placeholder="Name" name="name" />
                            </Form.Group>
                        </div>
                        {/* Email */}
                        <div className="col-md-4 mb-3">
                            <Form.Group controlId="email">
                                <Form.Label>Email<span className='required'>*</span></Form.Label>
                                <Form.Control type="text" placeholder="Email" name="email" />
                            </Form.Group>
                        </div>
                        {/* Contact Name */}
                        <div className="col-md-4 mb-3">
                            <Form.Group controlId="contactName">
                                <Form.Label>Contact Name</Form.Label>
                                <Form.Control type="text" placeholder="Contact Name" name="contactName" />
                            </Form.Group>
                        </div>
                        {/* Contact Email ID */}
                        <div className="col-md-4 mb-3">
                            <Form.Group controlId="contactEmailId">
                                <Form.Label>Contact Email ID</Form.Label>
                                <Form.Control type="text" placeholder="Contact Email ID" name="contactEmailId" />
                            </Form.Group>
                        </div>
                        {/* Address */}
                        <div className="col-md-4 mb-3">
                            <Form.Group controlId="address">
                                <Form.Label>Address</Form.Label>
                                <Form.Control as="textarea" placeholder="Address" name="address" />
                            </Form.Group>
                        </div>
                        {/* City */}
                        <div className="col-md-4 mb-3">
                            <Form.Group controlId="city">
                                <Form.Label>City</Form.Label>
                                <Form.Control type="text" placeholder="City" name="city" />
                            </Form.Group>
                        </div>
                        {/* Country */}
                        <div className="col-md-4 mb-4">
                            <Form.Group controlId="country">
                                <Form.Label>Country<span className='required'>*</span></Form.Label>
                                <div title={formik?.values?.countryName || ''}>
                                    <Select
                                        placeholder="Select"
                                        styles={customStyles}
                                        options={countryOptions}
                                        onChange={(e) => formik.setFieldValue("countryName", e?.label || '')} 
                                        isClearable
                                        isSearchable
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        {/* Contact Number */}
                        <div className="col-md-4 mb-3">
                            <Form.Group controlId="contactNumber">
                                <Form.Label>Contact Number</Form.Label>
                                <Form.Control type="text" placeholder="Contact Number" name="contactNumber" />
                            </Form.Group>
                        </div>
                      
                    </div>
                    <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-5'>
                        <div className='btn-type-2 cursor-pointer' onClick={() => navigate(CORPORATE) }>Cancel</div>
                        <button className='btn-type-5' type="submit" onClick={() => navigate(CORPORATE) }>Save</button>
                        <button className='btn-type-1 publish-btn' type="submit" >Publish</button>
                    </div>
                </div>
            </Form>
        </div>
    </div>
  )
}

export default CreateCorporate