
import React, { useEffect, useRef, useState } from 'react'
import DataTable from "react-data-table-component";
import { ReactComponent as FilterIcon } from "../../assets/images/common/filter-icon.svg";
import { ReactComponent as ExcelIcon } from "../../assets/images/common/sheets.svg";
import { ReactComponent as ViewIcon } from "../../assets/images/common/view-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/common/edit-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/common/close-icon.svg";
import SweetAlertModal from '../../components/SweetAlert/SweetAlertModal';
import { ReactComponent as AddIcon } from "../../assets/images/common/add-icon.svg";
import { CiUser } from "react-icons/ci";
import { FaTimes } from 'react-icons/fa';
import { IoIosClose } from "react-icons/io";
// SweetAlert

import BulkUpdateAlert from '../../components/SweetAlert/BulkUpdateAlert';
// Google Api
import {
    GoogleMap,
    Marker,
    useLoadScript,
    DirectionsService,
    DirectionsRenderer,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
// Drawer
import Drawer from '@mui/material/Drawer';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
// Rsuite Datepicker
import { AFFILIATED_CLUBS, CLUB_PROFIL, CLUB_PROFILE, CLUB_PROFILES, CREATE_CLUB_PROFILE, CREATE_CLUBS_INFORMATION, EDIT_CLUB_INFORMATIONS, VIEW_CLUBS_INFORMATION } from '../../constants/pathname';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
// Mui
import { FormControlLabel, RadioGroup, styled, Switch } from "@mui/material";
// Api
import { clubInformationStatusChange, demoClubStatusChange, listClubInformation, memberCityStatusChange } from '../../api/actions/clubProfile';
import { paginationRowsPerPageOptions } from '../../helpers/helper';
import toast from 'react-hot-toast';
import { clubHostBooking, clubTypeListDropDown } from '../../api/actions/club';
import { CLUB_INFORMATION_XLS_DOWNLOAD } from '../../api/api';
import { checkTheme } from '../../utils/utils';
import ClubsStatusAlert from '../../components/SweetAlert/ClubsStatusAlert';

const libraries = ["places"];

const ClubsInformation = () => {
    const navigate = useNavigate();
    const [dataList, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [isDeleting, setIsDeleting] = useState(false);
    const [limit, setLimit] = useState(25);
    const [update, setUpdate] = useState(false);
    const [pending, setPending] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showSweetAlert, setShowSweetAlert] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isSwal, setIsSwal] = useState({ show: false, id: '' });

    const [loadingExcel, setLoadingExcel] = useState(false);

    const [showDrawer, setShowDrawer] = useState(false);
    // Google api


    // Search
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [searchText, setSearchText] = useState('');
    const [search, setSearch] = useState('');
    // State to manage selected rows
    const [selectedRows, setSelectedRows] = useState([]);

    // Permissions
    const [permissionsList, setPermissionsList] = useState([]);
    // User information
    const [userInfo, setUserInfo] = useState(null);
    // SweetAlert
    const [isBulkSwal, setIsBulkSwal] = useState({ show: false, id: '', type: '', content: ''}); // Bulk Update
    const [isContentSwal, setContentSwal] = useState({show: false, content: ''});
    const [isDemoSwal, setIsDemoSwal] = useState({ show: false, id: '', type: '', published: false}); // Update Demo
    const [isCitySwal, setIsCitySwal] = useState({ show: false, id: '', type: '', published: false}); // Update City of Residence
    // Status details
    const [statusObj, setStatusObj] = useState({
        golf_booking: false,
        status: false,
        common: false,
        gpcc: false,
        solitaire: false,
        published: false,
        pcn: false,
    });
    // Loading for sweet alert
    const [loadingAlert, setLoadingAlert] = useState(false);

    // Club Type Options && Filter
    const [clubTypeOptions, setClubTypeOptions] = useState([]);
    const [filter, setFilter] = useState({
        status_club: "",
        club_type: "",
        location: ""
    })
    // Setting club type options
    useEffect(() => {
        clubTypeListDropDown({
            search_param: "",
            limit: 100
        })((response) => {
            if (response && response?.status) {
                setClubTypeOptions(response?.data?.results);
            }
        })
    }, []);

    // Setting permission list
    useEffect(() => {
        let userInfoObj = JSON.parse(localStorage.getItem("user_info"));
        setUserInfo(userInfoObj);
        if (userInfoObj && userInfoObj?.permissions && userInfoObj?.permissions?.length) {
            setPermissionsList(userInfoObj?.permissions);
        }

    }, []);

    const handleDelete = () => {
        setShowSweetAlert(true);
    }
    // Google map
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_API_KEY,
        libraries: libraries,
    });
    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete();

    const handleInputChange = (e) => {
        setValue(e.target.value);
        if (e.target.value === "") {
            clearSuggestions();
        }
    };

    const CustomSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 39,
        height: 20,
        padding: 0,
        marginLeft: 5,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            // margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: 'var(--sandy-brown)',
                    opacity: 1,
                    border: 0,
                    ...theme.applyStyles('dark', {
                        backgroundColor: '#2ECA45',
                    }),
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.grey[100],
                ...theme.applyStyles('dark', {
                    color: theme.palette.grey[600],
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                backgroundColor: 'var(--sandy-brown)',
                opacity: 0.7,
                ...theme.applyStyles('dark', {
                    opacity: 0.3,
                }),
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 20,
            height: 20,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#E9E9EA',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
            ...theme.applyStyles('dark', {
                backgroundColor: '#39393D',
            }),
        },
    }));

    const formik = useFormik({
        initialValues: {
            club_type: "",
            club_type_option: null,
            status_club: "",
            status_club_option: null,
            location: "",
        },
        onSubmit: (values, resetForm) => {
            setFilter({
                status_club: values?.status_club === "active" ? "True" : values?.status_club === "inactive" ? "False" : "",
                club_type: values?.club_type,
                location: values?.location
            });
            setShowDrawer(false);
        }
    });

    const handleSelect = async (address) => {
        let cityInfo = "";
        if (address) {
            let cityArr = address?.split(",");
            cityInfo = cityArr[0];
        }

        setValue(address, false);
        clearSuggestions();

        try {
            // Update formik state with new latitude and longitude
            formik.setValues({
                ...formik.values,
                location: cityInfo
            });
        } catch (error) {
            console.error("Error fetching geocode:", error);
        }
    };

    useEffect(() => { getListData(); }, [page, limit, debouncedSearch, update, filter]);

    const getListData = () => {
        setPending(true);

        if (debouncedSearch) {
            setPage(1);
        }
        listClubInformation({
            page: debouncedSearch ? 1 : page,
            limit,
            search: debouncedSearch,
            filter
        })(
            (response) => {
                if (response && response.status) {
                    setData(response?.data?.results);
                    setTotalRows(response?.data?.count);
                    setPending(false);
                }
            }
        );
    };

    const handleSwitchToggle = (row) => {
        clubHostBooking({ club: row?.club, country: row?.country })((res) => {
            if (res?.status) {
                toast.success(res?.message || "Success");
                setUpdate(!update);
            } else {
                toast.error(res?.message || "Something went wrong");
            }
        });
    };

    let columns = [
        {
            name: "Sl.No",
            key: "name",
            selector: row => row?.name,
            cell: (row, index) =>
                <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.name}>
                    {(limit * (page - 1)) + (index + 1)}
                </div>,
            grow: 2,
            reorder: true,
            width: '110px'
        },
        {
            name: "Club Name",
            selector: row => (
                <div
                    title={row.club_name}
                    style={{ cursor: 'pointer',color:'#0000EE' }}
                    onClick={() => navigate(`${CLUB_PROFILES}${row.club}`, { state: { country: row.country } })}
                >
                    {row.club_name}
                </div>
            ),

            minWidth: "250px"
        },
        { name: "Type", selector: row => <div title={row.club_type}>{row.club_type}</div>, minWidth: "150px" },
        // {
        //     name: "Affiliated Club",
        //     selector: row =>
        //         <div className='d-flex align-items-center gap-3'>
        //                 <div 
        //                     className='view-icon cursor-pointer' 
        //                     title='View'
        //                     onClick={() => navigate(AFFILIATED_CLUBS, { state: { current: row } })} 
        //                 >
        //                     <ViewIcon />
        //                 </div>
        //         </div>,
        //     minWidth: "150px"
        // },           
        { name: "City", selector: row => <div title={row.city_name}>{row.city_name}</div>, minWidth: "200px" },       
        { name: "State", selector: row => <div title={row.state}>{row.state}</div>, minWidth: "200px" },       
        { name: "Country", selector: row => row.country_name, minWidth: "200px" },  
        {
            name: "Affiliated Club Count",
            selector: row => {
                const queryParams = new URLSearchParams({
                    country: row.country,
                    value: row.club,
                    label: row.club_name
                }).toString();
        
                return (
                    <div 
                        style={{ cursor: "pointer" }} 
                        onClick={() => navigate(`${AFFILIATED_CLUBS}?${queryParams}`)}
                    >
                        {row?.affiliated_clubs_count}
                    </div>
                );
            },
            width: "200px",
        },
        { name: "Golf Booking", 
            selector: row => 
                <div>
                    <CustomSwitch
                        defaultChecked={row.golf_booking}
                        onChange={() => {
                            setIsBulkSwal({ show: true, id: [row.id], type: 'golf_booking', approve_club_spotlight: row.approve_club_spotlight, content: '' });
                            setStatusObj((prevState) => ({
                                ...prevState,
                                golf_booking: row.golf_booking,
                            }));
                        }}
                    />
                </div>,
            width: "120px"
        },
        {
            name: "Active/Inactive",
            selector: row =>
                <div >
                    <CustomSwitch
                        defaultChecked={row.status === "True" ? true : false}
                        onChange={() => {
                            setIsBulkSwal({ show: true, id: [row.id], type: 'status', approve_club_spotlight: row.approve_club_spotlight, content: '' });
                            setStatusObj((prevState) => ({
                                ...prevState,
                                status: row.status === "True" ? true : false,
                            }));
                        }}
                    />
                </div>,

            minWidth: "150px"
        },
        {
            name: "GPCC Participation",
            selector: row =>
                <div>
                    <CustomSwitch
                        defaultChecked={row.gpcc}
                        onChange={() => {
                            let contentInfo = '';
                            if(userInfo?.is_superuser && row.gpcc && (row.solitaire_club || row.approve_club_spotlight)){
                                if(row.solitaire_club){
                                    contentInfo = row.club_name + ` is a Solitaire Club.  Do you want to turn off its participation in Private Club World's Global Private Club network?`;
                                }else if(row.approve_club_spotlight){
                                    contentInfo = row.club_name + ` is listed in Club Invitations. Do you want to turn off its participation in Private Club World's Global Private Club network?`;
                                }
                            }
                            setIsBulkSwal({ show: true, id: [row.id], type: 'gpcc', approve_club_spotlight: row.approve_club_spotlight, content: contentInfo });
                            setStatusObj((prevState) => ({
                                ...prevState,
                                gpcc: row.gpcc,
                            }));
                        }}
                    />
                </div>,

            minWidth: "200px"
        },
        {
            name: "Solitaire Network",
            selector: row =>
                <div>
                    <CustomSwitch
                        defaultChecked={row.solitaire_club}
                        onChange={() => {
                            setIsBulkSwal({ show: true, id: [row.id], type: 'solitaire', approve_club_spotlight: row.approve_club_spotlight, content: '' });
                            setStatusObj((prevState) => ({
                                ...prevState,
                                solitaire: row.solitaire_club,
                            }));
                        }}
                    />
                </div>,

            minWidth: "150px"
        },
               
        {name: "Published/Not Published",
            selector: row =>
                <div style={{
                    pointerEvents:
                        row.status === "True"
                            ? 'auto' : 'none'
                }}
                >
                    <CustomSwitch
                        defaultChecked={row.is_publish}
                        onChange={() => {
                            setIsBulkSwal({ show: true, id: [row.id], type: 'published', approve_club_spotlight: row.approve_club_spotlight, content: '' });
                            setStatusObj((prevState) => ({
                                ...prevState,
                                published: row.is_publish,
                            }));
                        }}
                    />
                </div>,

            minWidth: "200px"
        },
        {
            name: "Common",
            selector: row =>
                <div>
                    <CustomSwitch
                        defaultChecked={row.both_clubs}
                        onChange={() => {
                            setIsBulkSwal({ show: true, id: [row.id], type: 'common', approve_club_spotlight: row.approve_club_spotlight, content: '' });
                            setStatusObj((prevState) => ({
                                ...prevState,
                                common: row.both_clubs,
                                pcn: row.PCN,
                            }));
                        }}
                    />
                </div>,

            minWidth: "200px"
        },        
        {  name: "City of Residence", 
            selector: row => 
                <div>
                    <CustomSwitch 
                        defaultChecked={row.member_city ? true : false} 
                        onChange={() =>{
                            setIsCitySwal({ show: true, id: [row.id], type: 'member_city', published: row.member_city ? true : false});
                        }}
                    />
                </div>, 
            minWidth: "170px" 
        },
        {  name: "Demo", 
            selector: row => 
                <div>
                    <CustomSwitch 
                        defaultChecked={row.demo_club ? true : false} 
                        onChange={() =>{
                            setIsDemoSwal({ show: true, id: [row.id], type: 'demo', published: row.demo_club ? true : false});
                        }}
                    />
                </div>, 
            minWidth: "150px" 
        },
        { name: "Accept Host Booking", 
            selector: row => 
                <div>
                    <CustomSwitch
                        defaultChecked={row?.host_booking}
                        onChange={(e) => handleSwitchToggle(row)}
                    />
                </div>,
            minWidth: "200px"
        },
        {
            name: "Actions",
            selector: row =>
                <div className='d-flex align-items-center gap-3'>
                    <div
                        title='Edit'
                        className='view-icon cursor-pointer'
                        onClick={() => navigate(CREATE_CLUB_PROFILE, { state: { country: row?.country, club: row?.club } })}
                    >
                        <EditIcon />
                    </div>
                </div>,

            minWidth: "150px"
        }
    ];

    // Remove columns
    if (checkTheme()) {
        columns = columns.filter(column => column.name !== "GPCC Participation"
            && column.name !== "Solitaire Network" && column.name !== "Accept Host Booking" && column.name !== "Affiliated Club Count");
    } 
    // else {
    //     columns = columns.filter(column => column.name !== "Common");
    // }


    // Custom styling for the table
    const customStyles = {
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",
                border: "1px solid var(--border-color)",
            },
        },
        headCells: {
            style: {
                backgroundColor: "var(--light-blue)",
                justifyContent: 'flex-start !important',
                color: "var(--dark-gray)",
                fontWeight: "400",
                fontSize: "15px",
            },
        },
        rows: {
            style: {
                height: "45px", // Set row height
                justifyContent: "start",
            },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    };

    useEffect(() => {
        if (search.length >= 2) {
            const debounceTimer = setTimeout(() => {
                setDebouncedSearch(search);
            }, 500);

            return () => clearTimeout(debounceTimer);
        } else {
            setDebouncedSearch('');
        }
    }, [search]);

    // Handle search
    const handleSearch = (value) => {
        setSearch(value);
        setSearchText(value);
    }

    // Clear search
    const clearSearch = () => {
        setSearch('');
        setSearchText('');
        setDebouncedSearch('');
        setUpdate(!update);
    };

    // Handler for when rows are selected/unselected
    const handleRowSelection = (state) => {
        setSelectedRows(state.selectedRows); // Update selected rows state
        console.log("Selected Rows:", state.selectedRows); // Log selected rows for debugging
        if (state.selectedRows?.length === 1) {
            setStatusObj({
                golf_booking: state.selectedRows?.[0]?.golf_booking,
                status: state.selectedRows?.[0]?.status,
                common: state.selectedRows?.[0]?.PCN,
                gpcc: state.selectedRows?.[0]?.gpcc,
                solitaire: state.selectedRows?.[0]?.solitaire_club,
                published: state.selectedRows?.[0]?.is_publish,
                pcn: state.selectedRows?.[0]?.PCN
            });
        }
    };

    // Handle bulk update status
    const handleBulkUpdateStatus = (type) => {
        let idArr = selectedRows.map((item) => item.id);

        setIsBulkSwal({ show: true, id: idArr, type: type })
    };

    // Update club info status
    const handleUpdateClubInfo = () => {
        let props = {};
        setLoadingAlert(true);

        if (isBulkSwal?.type === "golf_booking") {
            props = {
                ids: isBulkSwal?.id,
                publish_status: !statusObj?.golf_booking,
                type: "golf_booking"
            }
        } else if (isBulkSwal?.type === "status") {
            props = {
                ids: isBulkSwal?.id,
                publish_status: !statusObj?.status,
                type: "status"
            }
        } else if (isBulkSwal?.type === "common") {
            props = {
                ids: isBulkSwal?.id,
                publish_status: !statusObj?.common,
                pcn: statusObj?.pcn,
                // pcn: !statusObj?.common,
                type: "common"
            }
        } else if (isBulkSwal?.type === "gpcc") {
            props = {
                ids: isBulkSwal?.id,
                publish_status: !statusObj?.gpcc,
                type: "gpcc"
            }
        } else if (isBulkSwal?.type === "solitaire") {
            props = {
                ids: isBulkSwal?.id,
                publish_status: !statusObj?.solitaire,
                type: "solitaire"
            }
        } else if (isBulkSwal?.type === "published") {
            props = {
                ids: isBulkSwal?.id,
                publish_status: !statusObj?.published,
                type: "published"
            }
        }

        clubInformationStatusChange(props)((response) => {
            if (response?.status) {
                toast.success("Status updated successfully");
                // if (isBulkSwal?.type === "gpcc") {
                //     setContentSwal({
                //         show: true, 
                //         content: !statusObj?.gpcc 
                //                     ? `You have opted to participate in PrivateClubWorld's Global Private Club network` 
                //                     : `You have opted to turn off your participation in PrivateClubWorld's Global Private Club network`
                //     });
                // }else if (isBulkSwal?.type === "solitaire") {
                //     setContentSwal({
                //         show: true, 
                //         content: !statusObj?.solitaire 
                //                     ? `You have opted to participate in PrivateClubWorld's Solitaire network` 
                //                     : `You have opted to turn off your participation in PrivateClubWorld's Solitaire network`
                //     });
                // }
            } else {
                toast.error("Something went wrong")
            }
            setIsBulkSwal({ show: false, id: '', type: '', content: '' });
            setUpdate(!update);
            setLoadingAlert(false);

            if (Array.isArray(selectedRows) && selectedRows?.length) {
                if (isBulkSwal?.type === "golf_booking") {
                    setStatusObj({
                        ...statusObj,
                        golf_booking: !statusObj?.golf_booking,
                    });
                } else if (isBulkSwal?.type === "status") {
                    setStatusObj({
                        ...statusObj,
                        status: !statusObj?.status,
                    });
                } else if (isBulkSwal?.type === "common") {
                    setStatusObj({
                        ...statusObj,
                        common: !statusObj?.common
                    });
                } else if (isBulkSwal?.type === "gpcc") {
                    setStatusObj({
                        ...statusObj,
                        gpcc: !statusObj?.gpcc
                    });
                } else if (isBulkSwal?.type === "solitaire") {
                    setStatusObj({
                        ...statusObj,
                        solitaire: !statusObj?.solitaire
                    });
                } else if (isBulkSwal?.type === "published") {
                    setStatusObj({
                        ...statusObj,
                        published: !statusObj?.published
                    });
                }
            }
        })

    }

    // Export member list
    const handleExportMemberList = () => {
        const myHeaders = new Headers();
        let token = localStorage.getItem('token');

        myHeaders.append(`Authorization`, `Bearer ${token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        let downloadUrl = `${process.env.REACT_APP_BACKENDURL}/${CLUB_INFORMATION_XLS_DOWNLOAD}?pcn=${checkTheme()}&`;

        if (filter?.club_type) {
            downloadUrl += `club_type=${filter?.club_type}&`;
        }
        if (filter?.status_club) {
            downloadUrl += `status_club=${filter?.status_club}&`;
        }
        if (filter?.location) {
            downloadUrl += `location=${filter?.location}&`;
        }
        if(Array.isArray(selectedRows) && selectedRows?.length){
            const csvString = selectedRows.map(item => item.club).join(",");
            downloadUrl += `club_ids=${csvString}`;
        }
        setLoadingExcel(true);
        fetch(downloadUrl, requestOptions)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");

                a.href = url;
                a.download = "Clubs Information.xls"; // Ensure correct file extension
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                setLoadingExcel(false);
            })
            .catch((error) => {
                console.error(error);
                setLoadingExcel(false);

            });
    };

    // Handle update demo
    const handleUpdateDemo = () =>{
        
        demoClubStatusChange({ids: isDemoSwal?.id, publish_status: !isDemoSwal?.published})((response) =>{
            if(response.status){
                toast.success("Demo status updated successfully");
            }else{
                toast.error("Failed to update demo status");
            }
            setIsDemoSwal({ show: false, id: '', type: '', published: false});
            setUpdate(!update);
        })
    }
    // Handle update demo
    const handleUpdateCityResidence = () =>{
        
        memberCityStatusChange({ids: isCitySwal?.id, publish_status: !isCitySwal?.published})((response) =>{
            if(response.status){
                toast.success("City of residence status updated successfully");
            }else{
                toast.error("Failed to update city of residence  status");
            }
            setIsCitySwal({ show: false, id: '', type: '', published: false});
            setUpdate(!update);
        })
    }

    return (
        <div className='member-stats-section'>
            <SweetAlertModal showSweetAlert={showSweetAlert} setShowSweetAlert={setShowSweetAlert} />
            {/* Clubs Status content alert */}
            {isContentSwal?.content && <ClubsStatusAlert showClubsStatusAlert={isContentSwal} setShowClubsSwal={setContentSwal} content={isContentSwal?.content}/>}
            <Drawer
                anchor={'right'}
                open={showDrawer}
                onClose={() => setShowDrawer(false)}
                className='member-stats-drawer'
                sx={{
                    '& .MuiPaper-root': {
                        padding: '30px 19px',
                        height: "100vh",
                        // overflow: "hidden"
                    },
                    '& .MuiDrawer-paper': {
                        width: '420px'
                    },
                    '& .MuiBox-root': {
                        width: '420px'
                    },
                    '& .MuiList-root': {
                        display: 'flex',
                        columnGap: '10px',
                        width: '90%',
                        margin: 'auto',
                        paddingTop: "25px"
                    },
                    '& .MuiButtonBase-root': {
                        padding: '7px',
                        textAlign: 'center',
                        alignItems: 'center',
                        borderRadius: '10px',
                        backgroundColor: '#F5F5F5',
                    }
                }}
            >
                <div className="drawer-top-box">
                    <div>Filter</div>
                    <button type="button" class="btn-close" aria-label="Close" onClick={() => setShowDrawer(false)}></button>
                </div>
                <div className="drawer-bottom-box mt-4">
                    <Form onSubmit={formik.handleSubmit}>
                        {/* Type */}
                        <div className='item'>
                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em5">
                                <Form.Label>Club Type</Form.Label>
                                <div className='input-border'>
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            colors: {
                                                ...theme.colors,
                                            },
                                        })}
                                        options={clubTypeOptions}
                                        value={formik.values?.club_type_option}
                                        onChange={(option) => {
                                            formik.setValues({
                                                ...formik.values,
                                                club_type: option?.value,
                                                club_type_option: option
                                            })
                                        }}
                                        placeholder="Select"
                                        isSearchable
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                width: '230px', // Ensures a minimum width
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                width: '230px', // Ensures dropdown has enough space
                                            })
                                        }}
                                        isClearable
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        {/* Status */}
                        <div className='item'>
                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em5">
                                <Form.Label>Status</Form.Label>
                                <div className='input-border'>
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            colors: {
                                                ...theme.colors,
                                            },
                                        })}
                                        placeholder="Select"
                                        options={[{ label: "Active", value: "active" }, { label: "Inactive", value: "inactive" }]}
                                        value={formik?.values?.status_club_option}
                                        onChange={(option) => {
                                            formik.setValues({
                                                ...formik.values,
                                                status_club: option?.value,
                                                status_club_option: option
                                            })
                                        }}
                                        isClearable={true}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        {/* City */}
                        <div className='item'>
                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="city" style={{ position: "relative" }}>
                                <Form.Label>City</Form.Label>
                                <div className='input-border gp-city-box'>
                                    <Form.Control
                                        type="text"
                                        placeholder="Search city"
                                        name="city"
                                        value={value}
                                        onChange={handleInputChange}
                                        className='gp-city-search'
                                    />
                                    {formik?.values?.location &&
                                        <div className='close-icon'
                                            onClick={() => {
                                                setFilter({
                                                    status_club: filter?.status_club || "",
                                                    club_type: filter?.club_type || "",
                                                    location: ""
                                                });
                                                setValue("");
                                                formik.setFieldValue("location", "")
                                            }}
                                        >
                                            <IoIosClose size={20} />
                                        </div>}
                                    {value !== "" && status === "OK" && (
                                        <ul
                                            style={{
                                                listStyleType: "none",
                                                padding: "0",
                                                margin: "8px 0",
                                                backgroundColor: "#fff",
                                                border: "1px solid #ddd",
                                                borderRadius: "4px",
                                                position: "absolute",
                                                width: "235px",
                                                right: "0% !important",
                                                left: "auto",
                                                top: "81%",
                                                maxHeight: "200px",
                                                overflowY: "auto",
                                                zIndex: 1000,
                                            }}
                                        >
                                            {data.map(({ place_id, description }) => (
                                                <li
                                                    key={place_id}
                                                    onClick={() => handleSelect(description)}
                                                    style={{
                                                        padding: "10px",
                                                        cursor: "pointer",
                                                        borderBottom: "1px solid #eee",
                                                        backgroundColor: "#fff",
                                                        transition: "background-color 0.2s",
                                                    }}
                                                    onMouseEnter={(e) =>
                                                        (e.target.style.backgroundColor = "#f0f0f0")
                                                    }
                                                    onMouseLeave={(e) =>
                                                        (e.target.style.backgroundColor = "#fff")
                                                    }
                                                >
                                                    {description}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </Form.Group>
                        </div>                       
                        <div className='submit-box d-flex align-items-center justify-content-end gap-3' style={{ height: "50vh" }}>
                            <button className='btn-type-2 cursor-pointer' 
                                onClick={() =>{
                                    setFilter({
                                        status_club: "",
                                        club_type: "",
                                        location: ""
                                    });
                                    setValue("");
                                    formik.handleReset();
                                }}
                            >
                                Clear
                            </button>
                            <button className='btn-type-1' type="submit">Submit</button>
                        </div>
                    </Form>
                </div>

            </Drawer>
            <div className='page-details'>
                <div className='top-section'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='left'>{checkTheme() ? 'Club Accounts' : 'Club Details'}</div>
                        <div className='right d-flex align-items-center  gap-3' >
                            <div className='search-box'>
                                <input
                                    type='text'
                                    placeholder='Search by Club Name'
                                    className='form-control'
                                    value={searchText}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                                {
                                    searchText && (
                                        <FaTimes className='clear-icon' onClick={clearSearch} />
                                    )}
                            </div>
                            <div className='filter-box d-flex align-items-center  gap-3 cursor-pointer' title='Filter' onClick={() => setShowDrawer(true)}>
                                <FilterIcon />
                                <span>Filter</span>
                            </div>
                            <button className='download-excel cursor-pointer' title={!loadingExcel ? 'Download Clubs Information' : 'Loading'}
                                onClick={() => handleExportMemberList()}
                                disabled={loadingExcel}
                            >
                                <ExcelIcon />
                            </button>
                        </div>
                    </div>
                    {/* Toggle Stauts  */}
                    {selectedRows.length > 1 && (
                        <div className='d-flex align-items-center gap-3 mt-3'>
                            <div className='d-flex align-items-center'>
                                <span>Golf Booking </span>
                                <CustomSwitch defaultChecked={statusObj?.golf_booking} onChange={() => handleBulkUpdateStatus("golf_booking")} />
                            </div>
                            <div className='d-flex align-items-center'>
                                <span>Active/Inactive </span>
                                <CustomSwitch defaultChecked={(statusObj?.status === "True" || statusObj?.status === true) ? true : false} onChange={() => handleBulkUpdateStatus("status")} />
                            </div>
                            {/* <div className='d-flex align-items-center'>
                                    <span>Common </span>
                                    <CustomSwitch defaultChecked={statusObj?.common} onChange={() => handleBulkUpdateStatus("common")} />
                                </div> */}
                            {!checkTheme() && <>
                                <div className='d-flex align-items-center'>
                                    <span>GPCC </span>
                                    <CustomSwitch defaultChecked={statusObj?.gpcc} onChange={() => handleBulkUpdateStatus("gpcc")} />
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span>Solitaire </span>
                                    <CustomSwitch defaultChecked={statusObj?.solitaire} onChange={() => handleBulkUpdateStatus("solitaire")} />
                                </div>
                            </>}
                            {Array.isArray(selectedRows) && !selectedRows.some(item => item.status === "False") &&
                                <div className='d-flex align-items-center'>
                                    <span>Published </span>
                                    <CustomSwitch defaultChecked={statusObj?.published} onChange={() => handleBulkUpdateStatus("published")} />
                                </div>}
                        </div>
                    )}
                </div>

                <div className='table-box mt-3'>
                    <DataTable
                        columns={columns}
                        data={dataList}
                        customStyles={customStyles}
                        fixedHeader
                        fixedHeaderScrollHeight="900px"
                        highlightOnHover
                        responsive
                        pagination
                        paginationServer
                        paginationPerPage={limit}
                        progressPending={pending}
                        onChangeRowsPerPage={setLimit}
                        paginationTotalRows={totalRows}
                        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                        onChangePage={setPage}
                        selectableRows // Enable row selection
                        onSelectedRowsChange={handleRowSelection} // Handle row selection changes
                        selectableRowsHighlight
                    />
                </div>
                {/* Demo Update Status */}
                <BulkUpdateAlert
                    showSweetBulkAlert={isDemoSwal.show}
                    setShowBulkAlert={(show) => {
                            if (show) {
                                handleUpdateDemo()
                            }
                            else setIsDemoSwal({ show: false, id: '', type: '', published: false});
                        }
                    }
                />
                {/* City of Residence Update Status */}
                <BulkUpdateAlert
                    showSweetBulkAlert={isCitySwal.show}
                    setShowBulkAlert={(show) => {
                            if (show) {
                                handleUpdateCityResidence()
                            }
                            else setIsCitySwal({ show: false, id: '', type: '', published: false});
                        }
                    }
                /> 
                {/* Bulk Update Status */}
                <BulkUpdateAlert
                    loadingAlert={loadingAlert}
                    showSweetBulkAlert={isBulkSwal.show}
                    setShowBulkAlert={(show) => {
                        if (show) {
                            handleUpdateClubInfo()
                        }
                        else setIsBulkSwal({ ...isBulkSwal, show: false, content: '' });
                    }}
                    content={isBulkSwal?.content}
                />
            </div>
        </div>
    )
}







export default ClubsInformation