import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

const RequestAlert = ({ showSweetBulkAlert, setShowBulkAlert, handleUpdateClubInfo }) => {
    return (
        <div>
            {showSweetBulkAlert.show && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    confirmBtnCssClass="custom-confirm-button-class"
                    cancelBtnCssClass="custom-cancel-button-class"
                    title={
                        <div style={{ lineHeight: '27px' }}>
                            <span className="custom-sweetalert-title">
                                {showSweetBulkAlert.row.network_block_status
                                    ? `Are you sure you want to accept requests from members of ${showSweetBulkAlert.row.club_name}?`
                                    : `Are you sure you want to block requests from members of ${showSweetBulkAlert.row.club_name}?`
                                }
                            </span>
                        </div>
                    }
                    onConfirm={() => {
                        setShowBulkAlert({ show: false });
                        handleUpdateClubInfo(showSweetBulkAlert.row);
                    }}
                    onCancel={() => setShowBulkAlert({ show: false })}
                    focusCancelBtn
                />
            )}
        </div>
    )
}

export default RequestAlert;