import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { PUSH_NOTIFICATIONS } from '../../constants/pathname';
// Formik
import { useFormik } from "formik";
import * as Yup from "yup";
// Bootstrap
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
// Mui
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { listClubInformation } from '../../api/actions/clubProfile';
import { checkTheme } from '../../utils/utils';
import { createNotificationClub } from '../../api/actions/notifications';
import toast from 'react-hot-toast';
import { listAllClubs } from '../../api/actions/club';


const CreatePushNotifications=() => {
    const navigate=useNavigate();
    const [clubOptions, setClubOptions]=useState([]);
    // Pagination
    const [limit, setLimit]=useState(25);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const [pending, setPending] = useState(false);
 
    // Club options
    useEffect(() => {
        listAllClubs({
            page,
            limit,     
            filter: { status_club: "True" }      
        })(
            (response) => {
                if (response&&response.status) {
                    if (Array.isArray(response?.data?.results)) {
                        let clubArr=response.data?.results?.map((item) => {
                            return { label: item.club_name, value: item.id, country: item.country };
                        });
                        // Add "All" option at the beginning
                        clubArr.unshift({ label: "All", value: "all", country: null });
                        setClubOptions(clubArr);
                    }
                }
            }
        );
    }, []);

    // Function to fetch club options based on input
    let typingTimer;
    const loadOptionsClub=(inputValue) => {
        return new Promise((resolve) => {
            clearTimeout(typingTimer);
            typingTimer=setTimeout(() => {
                if (inputValue.length>=3) {
                    listAllClubs({ page: 1, limit: 10, search: inputValue, filter: {status_club: "True"} })((response) => {
                        if (response?.status_code===200) {
                            const options=response?.data?.results||[];
                            let clubArr=options?.map((item) => {
                                return { label: item.club_name, value: item.id, country: item.country };
                            });
                             // Add "All" option at the beginning
                            clubArr.unshift({ label: "All", value: "all", country: null });
                            setClubOptions(clubArr);
                            resolve(clubArr);
                        } else {
                            resolve([]);
                        }
                    });
                } else {
                    resolve([]);
                }
            }, 300);
        });
    };

    const formik = useFormik({
        initialValues: {
            club_ids: [],            
            // country: "",
            title: "",
            description: "",
            users: "",
            pcn: false
        }, 
        validationSchema: Yup.object({
            club_ids: Yup.array()
                .min(1, "At least one club must be selected")
                .required("Club is required"),
            // country: Yup.number().required("Country is required"),
            title: Yup.string().required("Title is required"),
            description: Yup.string().required("Description is required"),
            users: Yup.string()
            .oneOf([ "All Members", "Demo Users", "Activated", "Subscribed", "Not Subscribed"], "Invalid user status")
            .required("Users field is required"),
        }),
        onSubmit: (values) => {
            if(checkTheme()){
                values.pcn = true;
            }

            if(Array.isArray(values?.club_ids) && values?.club_ids?.includes("all")){
                values.is_all = true;
                let { club_ids, ...temp} = values;
                values = temp;
            }

            setLoading(true);
            console.log("Form submitted with values:", values);

            createNotificationClub(values)((response) => {

                if(response.status){
                    toast.success("Push notification sent successfully");;
                }else{
                    toast.error(response?.message || "Failed to send push notification");
                }
                setLoading(false);
                navigate(PUSH_NOTIFICATIONS);
            })
        },
    });

    console.log(formik.values, formik.errors)
    
    const handleChange = (e, name, selected_name) => {
        if (e) {
            formik.setValues({
            ...formik.values,
            [name]: e.value,
            [selected_name]: e,
            country: e.country,
            });
        } else {
            formik.setValues({
            [name]: null,
            [selected_name]: [],
            country: null,
            });
        }
    };
 
    return (
        <div className='create-clubs-info-section create-push-notifications'>
            <div className='page-details'>
                <div className='panel-type-1'>
                    Send Push Notification by Club
                </div>
                <Form onSubmit={formik.handleSubmit}>
                    <div className='mt-5'>
                        <div className="row flex-column">
                            {/* Club */}
                            <div className="col-md-6 mb-4">
                                <Form.Label>Club<span className='required'>*</span></Form.Label>
                                <Form.Group className="mb-3" controlId="club_ids">
                                    <AsyncSelect
                                        loadOptions={loadOptionsClub}
                                        defaultOptions={clubOptions||[]}
                                        classNamePrefix="select"
                                        placeholder={'Select Club'}
                                        onChange={(e) => {
                                            console.log("eee", e);
                                            let clubIdList = e.map((item) => item.value);
                                            formik.setFieldValue("club_ids", clubIdList);
                                            // handleChange(e, 'club', 'club_option')
                                        }}
                                        // value={formik.values?.club_ids}
                                        styles={{
                                            control: (styles, { isFocused }) => ({
                                                ...styles,
                                                maxHeight: '31px',
                                                border: formik.errors.club_ids&&formik.touched.club_ids
                                                    ? '1px solid #dc3545'
                                                    :'1px solid var(--light-grayish)',
                                                outline: 'none',
                                                boxShadow: isFocused? '0 0 0 1px var(--light-grayish)':'none',
                                                color: 'var(--medium-gray)',
                                                fontSize: '15px',
                                                '&:hover': {
                                                    border: formik.errors.club_ids&&formik.touched.club_ids
                                                        ? '1px solid #dc3545'
                                                        :'1px solid var(--light-grayish)',
                                                },
                                            }),
                                            valueContainer: (styles) => ({
                                                ...styles,
                                                alignItems: 'center',
                                                padding: '3px 8px',
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: 'var(--medium-gray)',
                                                fontSize: '15px',
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                border: '1px solid var(--light-grayish)',
                                                boxShadow: 'none',
                                            }),
                                            placeholder: (styles) => ({
                                                ...styles,
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                color: 'var(--medium-gray)',
                                                fontSize: '15px',
                                            }),
                                        }}
                                        isMulti
                                    />
                                    {formik?.touched?.club_ids&&formik?.errors?.club_ids?
                                        <div className='error-msg'>{formik?.errors?.club_ids}</div>
                                        :null}
                                </Form.Group>
                            </div>
                           {/* Title */}
                            <div className="col-md-6 mb-3">
                                <Form.Group controlId="title">
                                <Form.Label>
                                    Title<span className="required">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Title"
                                    name="title"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={formik.touched.title && !!formik.errors.title}
                                />
                                {formik.touched.title && formik.errors.title && 
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.title}
                                    </Form.Control.Feedback>}
                                </Form.Group>
                            </div>

                            {/* Description */}
                            <div className="col-md-6 mb-3">
                                <Form.Group controlId="description">
                                <Form.Label>
                                    Description<span className="required">*</span>
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    placeholder="Description"
                                    name="description"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={formik.touched.description && !!formik.errors.description}
                                />
                                {formik.touched.description && formik.errors.description
                                 && 
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.description}
                                    </Form.Control.Feedback>}
                                </Form.Group>
                            </div>

                            {/* Users */}
                            <div className="col-md-10 mb-3">
                                <Form.Group controlId="users">
                                <Form.Label>
                                    Users<span className="required">*</span>
                                </Form.Label>
                                </Form.Group>
                                <RadioGroup
                                    name="users"
                                    className="d-flex flex-row align-items-center"
                                    value={formik.values.users}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >                                
                                <FormControlLabel
                                    sx={{
                                    color: "#717171",
                                    fontSize: "14px",
                                    "& .MuiTypography-root": {
                                        color: "#717171",
                                        fontSize: "14px",
                                    },
                                    }}
                                    value="All Members"
                                    control={<Radio />}
                                    label="All Members"
                                />
                                <FormControlLabel
                                    sx={{
                                    color: "#717171",
                                    fontSize: "14px",
                                    "& .MuiTypography-root": {
                                        color: "#717171",
                                        fontSize: "14px",
                                    },
                                    }}
                                    value="Demo Users"
                                    control={<Radio />}
                                    label="Demo Users"
                                />
                                <FormControlLabel
                                    sx={{
                                    color: "#717171",
                                    fontSize: "14px",
                                    "& .MuiTypography-root": {
                                        color: "#717171",
                                        fontSize: "14px",
                                    },
                                    }}
                                    value="Activated"
                                    control={<Radio />}
                                    label="Activated"
                                />
                                <FormControlLabel
                                    sx={{
                                    color: "#717171",
                                    fontSize: "14px",
                                    "& .MuiTypography-root": {
                                        color: "#717171",
                                        fontSize: "14px",
                                    },
                                    }}
                                    value="Subscribed"
                                    control={<Radio />}
                                    label="Subscribed"
                                />
                                <FormControlLabel
                                    sx={{
                                    color: "#717171",
                                    fontSize: "14px",
                                    "& .MuiTypography-root": {
                                        color: "#717171",
                                        fontSize: "14px",
                                    },
                                    }}
                                    value="Not Subscribed"
                                    control={<Radio />}
                                    label="Not Subscribed"
                                />
                                </RadioGroup>
                                {formik.touched.users && formik.errors.users && (
                                <div className="text-danger" style={{fontSize: "12px"}}>{formik.errors.users}</div>
                                )}
                            </div>    
                        </div>
                        <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-5'>
                            <button className='btn-type-5' onClick={() => navigate(PUSH_NOTIFICATIONS)}>Back</button>
                            <button className='btn-type-1' type="submit" disabled={loading}>Submit</button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default CreatePushNotifications