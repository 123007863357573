import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FaRegAddressCard, FaBoxOpen, FaClipboardList, FaCheckCircle } from "react-icons/fa";
import logoIcon from "../../../assets/images/common/logo/logo-pcw-black.png";
import ClubInformation from "./ClubInformation";
import PackageConclusion from "./PackageConclusion";
import * as Yup from 'yup';
import Confirmation from "./Confirmation";
import Review from "./Review";
import { createClubOnboarding, getClubProfile, getProfileonboarding } from "../../../api/actions/clubProfile";
import { useFormik } from "formik";
import toast from "react-hot-toast";

const Clubinfo = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [searchParams] = useSearchParams();
  const { clubId } = useParams();
  const id = searchParams.get("id");
  const country = searchParams.get("country");
  const [clubProfileDetails, setClubProfileDetails] = useState(null);


  const handleChange = (event, newValue) => {
    if (newValue <= 1) {
      setValue(newValue);
      // window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top when tab changes
    }
  };

  const formik = useFormik({
    initialValues: {
      id: "",
      country: country,
      club_name: "",
      first_name: '',
      last_name: '',
      email: '',
      state: '',
      contact_number: '',
      password: '',
      confirm_password: '',
      on_boarded: false, 
    },
   
  
    onSubmit: (values) => {
  
      const updatedValues = { ...values, on_boarded: true };
  
      createClubOnboarding({ data: updatedValues })((response) => {
        if (response.status_code === 200 || response.status_code === 201) {
          toast.success("Club profile created successfully!");
          setValue(3); // Navigate to the Confirmation tab
  
          
          setClubProfileDetails(prevState => ({ ...prevState, on_boarded: true }));
        } else {
          toast.error(response?.response?.data?.message || "Something went wrong");
        }
      });
    },
  });
  useEffect(() => {
    setValue(0);
  }, []);

  useEffect(() => {
    if (id) {
      getProfileonboarding({ country: 0, key: id, onboard: true })((response) => {
        if (response?.status) {
          const data = response?.data;
          setClubProfileDetails(data);
  
          formik?.setValues({
            ...formik.values,
            id: response?.data?.id,
            club_name: response?.data?.club_name,
            email: response?.data?.admin_email,
            contact_number: response?.data?.contact,
            state: response?.data?.state,
            country: response?.data?.country,
            country_name: response?.data?.country_name,
            city: response?.data?.city_name,
            latitude: response?.data?.latitude,
            longitude: response?.data?.longitude,
            primary_address: response?.data?.primary_address,
            status: response?.data?.status,
            summary: response?.data?.summary,
            first_name: response?.data?.first_name,
            last_name: response?.data?.last_name,
            zip_code: response?.data?.zip_code,
          });
  
          // Check if the club is onboarded and set the tab value to 3
          if (response?.data?.on_boarded) {
            setValue(3);
          }
        }
      });
    }
  }, [id]);
  
  useEffect(() => {
    if (clubProfileDetails?.on_boarded) {
      setValue(3);
    }
  }, [clubProfileDetails]);
console.log(country);

  return (
    <div>
      {/* Logo Section */}
      <div className="header-section">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="logo-icon cursor-pointer">
            <img src={logoIcon} alt="Private Club World" />
          </div>
        </div>
      </div>

      
      <div className="tabreg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start", 
            minHeight: "100vh", 
          }}
        >
          <Box
            sx={{
              display: "flex",
              borderRadius: "8px",
              boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
              padding: "10px",
              width: "1000px", 
            }}
          >
            {/* Tabs List */}
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              sx={{
                borderRight: 1,
                borderColor: "divider",
                minWidth: 200,
                backgroundColor: "#f5f5f5",
                "& .MuiTab-root": {
                  fontSize: "15px",
                  fontWeight: "500",
                  textTransform: "none",
                  padding: "12px 20px",
                  transition: "0.3s",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                },
                "& .Mui-selected": {
                  color: "#c58f5a !important",
                  backgroundColor: "#fff",
                  borderLeft: "4px solid #c58f5a",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "#c58f5a",
                },
              }}
            >
              <Tab
                label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FaRegAddressCard style={{ marginRight: "10px" }} />
                    Package Inclusion  
                  </div>
                  
                }
                disabled={clubProfileDetails?.on_boarded}
              />
              <Tab
                label={
                  <div style={{ display: "flex", alignItems: "center" ,width: "100%"}}>
                    <FaBoxOpen style={{ marginRight: "10px" }} />
                    Club Information
                  </div>
                }
                disabled={clubProfileDetails?.on_boarded}
              />
              <Tab
                label={
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <FaClipboardList style={{ marginRight: "10px" }} />
                    Review
                  </div>
                }
                disabled // Disable Review tab
              />
              <Tab
                label={
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <FaCheckCircle style={{ marginRight: "10px" }} />
                    Confirmation
                  </div>
                }
                disabled // Disable Confirmation tab
              />
            </Tabs>

            {/* Tab Content */}
            <Box
              sx={{
                padding: 2,
             
                maxWidth: "800px",
                width: "100%",
               
                // maxHeight: "85vh", // Prevent content overflow
              }}
            >
               {value === 0 && (
                <Box sx={{ width: "100%" }}>
                  <PackageConclusion setValue={setValue} clubProfileDetails={clubProfileDetails}  formik={formik}  />
                </Box>
              )}
              {value === 1 && (
                <Box sx={{ width: "100%" }}>
                  <ClubInformation setValue={setValue}  clubProfileDetails={clubProfileDetails} formik={formik}  />
                </Box>
              )}
             
              {value === 2 && (
                <Box sx={{ width: "100%" }}>
                  <Review setValue={setValue} clubProfileDetails={clubProfileDetails}  formik={formik}  />
                </Box>
              )}
              {value === 3 && (
                <Box sx={{ width: "100%" }}  clubProfileDetails={clubProfileDetails}>
                  <Confirmation />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default Clubinfo;
