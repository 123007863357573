import React from 'react'
import ResetPassword from '../../components/ResetPassword/ResetPassword'


const ResetPasswordPage = () => {

  return (
    <div className='reset-password-page newpassword'>
        <ResetPassword />
    
        
    </div>
  )
}

export default ResetPasswordPage