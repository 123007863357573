import React from 'react'
import CreateCuratedMemberEvents from '../../components/CreateCuratedMemberEvents/CreateCuratedMemberEvents'

const CreateCuratedMemberEventsPage = () => {

  return (
    <div className='create-curated-member-events-page'>
      <CreateCuratedMemberEvents />
    </div>
  )
}

export default CreateCuratedMemberEventsPage