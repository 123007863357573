import React, { useEffect, useState } from 'react'
import DataTable from "react-data-table-component";
import { ReactComponent as AddIcon } from "../../assets/images/common/add-icon.svg";
import { ReactComponent as ViewIcon } from "../../assets/images/common/view-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/common/edit-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/common/close-icon.svg";
import { ReactComponent as GolfIcon } from "../../assets/images/common/golf_icon.svg";
import { Link, useNavigate } from 'react-router-dom';
import SweetAlertModal from '../SweetAlert/SweetAlertModal';
import { ReactComponent as FilterIcon } from "../../assets/images/common/filter-icon.svg";
import { CREATE_FACILITIES_CATEGORY, EDIT_FACILITIES_CATEGORY } from '../../constants/pathname';
import { deletefacilitycategory, facitliyCategoryStatus, listFaciltiyCategory } from '../../api/actions/addFacitiltycCategory';
import { paginationRowsPerPageOptions } from '../../helpers/helper';
import toast from 'react-hot-toast';
import { FaTimes } from 'react-icons/fa';
import { Drawer } from '@mui/material';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { checkTheme } from '../../utils/utils';
import { styled } from '@mui/material/styles';
import { FormControlLabel, RadioGroup, Switch } from "@mui/material";
import { createClubtypeStatuschange } from '../../api/actions/clubtype';
import SweetAlert from 'react-bootstrap-sweetalert';
const FacilitiesCategory = () => {
  const [data, setData] = useState([{}]);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [isDeleting, setIsDeleting] = useState(false);
  const [limit, setLimit] = useState(25);
  const [search, setSearch] = useState('');
  const [update, setUpdate] = useState(false);
  const [pending, setPending] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
    const [selectedClub, setSelectedClub] = useState(null);
  const [isFocused, setIsFocused] = useState(false);

  const [showDrawer, setShowDrawer] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [searchText, setSearchText] = useState('');
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const navigate = useNavigate();
  const [isSwal, setIsSwal] = useState({ show: false, id: '' });
  const[status,setStatus] = useState(null);

  // Permissions
  const [permissionsList, setPermissionsList] = useState([]);
  
  // Setting permission list
  useEffect(() => {
      let permissions = JSON.parse(localStorage.getItem("user_info"));

      if(permissions && permissions?.permissions && permissions?.permissions?.length){
          setPermissionsList(permissions?.permissions);
      }

  }, []);

  const handleFilter = () => {
    setPage(1)
    setShowDrawer(false);
    setUpdate(!update)
    // getListData();
  }

  const handleClear = () => {
      setStatus(null);
      
    
  

      setUpdate(false)
      setShowDrawer(false);
  }

  const handleSearch = (value) => {
    setSearch(value);
    setSearchText(value);

  };

  useEffect(() => {
    if (search.length >= 2) {
      const debounceTimer = setTimeout(() => {
        setDebouncedSearch(search);
      }, 500);

      return () => clearTimeout(debounceTimer);
    } else {
      setDebouncedSearch('');
    }
  }, [search]);

  const clearSearch = () => {
    setSearch('');
    setSearchText('');
    setDebouncedSearch('');
    setUpdate(!update)
  };

  const CustomSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 39,
    height: 20,
    padding: 0,
    marginLeft: 5,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        // margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: 'var(--sandy-brown)',
                opacity: 1,
                border: 0,
                ...theme.applyStyles('dark', {
                    backgroundColor: '#2ECA45',
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
            ...theme.applyStyles('dark', {
                color: theme.palette.grey[600],
            }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            backgroundColor: 'var(--sandy-brown)',
            opacity: 0.7,
            ...theme.applyStyles('dark', {
                opacity: 0.3,
            }),
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 20,
        height: 20,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        ...theme.applyStyles('dark', {
            backgroundColor: '#39393D',
        }),
    },
}));


  const columns = [
    {
      name: "Sl.No",
      key: "name",
      selector: row => row?.name,
      cell: (row, index) =>
        <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.name}>
          {(limit * (page - 1)) + (index + 1)}
        </div>,
      sortable: true,
      grow: 2,
      reorder: true,
      width: '100px'
    },
    { name: "Name", selector: (row) => row.name, sortable: true },
    { name: "Remarks", selector: (row) => row.description, sortable: true },
    {
      name: "Image",
      cell: (row) =>
        checkTheme() ? (
          row.pcn_icon_class ? (
            <img
              src={row.pcn_icon_class}
              alt="Facility"
              style={{ width: "40px", height: "40px", objectFit: "cover", margin: "auto" }}
            />
          ) : (
            <GolfIcon style={{ width: "20px", height: "20px", margin: "auto" }} />
          )
        ) : (
          row.pcw_icon_class ? (
            <img
              src={row.pcw_icon_class}
              alt="Facility"
              style={{ width: "40px", height: "40px", objectFit: "cover", margin: "auto" }}
            />
          ) : (
            <GolfIcon style={{ width: "20px", height: "20px", margin: "auto" }} />
          )
        ),
      width: "100px",
    },
    {
      name: "Active/Inactive",
      selector: row => (
          <div className='view-icon cursor-pointer' style={{ width: "140px", height: "50px", paddingTop: "9px", overflow: "visible", border: "none" }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '10px', color: row.access ? 'var(--light-green)' : 'red' }}></span>
                  <CustomSwitch
                      checked={row.access}
                      onChange={() => handleToggleClick(row.id)}
                  />
              </div>
          </div>
      ),
      minWidth: "180px"
  },
    // {
    //   name: "Access",
    //   selector: (row) => (row.access ? "Yes" : "No"),
    //   width: "120px",
    //   sortable: true
    // },
    {
      name: "Actions",
      selector: row =>
        <div className='d-flex align-items-center gap-3'>
          {/* <div className='view-icon cursor-pointer' onClick={() => navigate(CREATE_FACILITIES_CATEGORY, { state: { currentPageName: "View Facilities Category" } })} title='View'><ViewIcon style={{ marginTop: "-1px" }} /></div> */}
          
            <Link to={`${EDIT_FACILITIES_CATEGORY}${row?.id}`} >
              <div className='view-icon cursor-pointer' title="Edit"><EditIcon /></div>
            </Link>            
              <div className='view-icon cursor-pointer' onClick={() => setIsSwal({ show: true, id: row?.id })} title='Delete'><CloseIcon style={{ marginLeft: "2px" }} /></div>            
        </div>,
      width: "180px", 
    },
  ];


  const customStyles = {
    table: {
      style: {
        borderRadius: "15px 15px 0px 0px",
        border: "1px solid var(--border-color)",
        overflow: "hidden"
      },
    },
    headCells: {
      style: {
        backgroundColor: "var(--light-blue)",
        justifyContent: 'flex-start !important',
        color: "var(--dark-gray)",
        fontWeight: "400",
        fontSize: "15px",
      },
    },
    rows: {
      style: {
        height: "45px",
        justifyContent: "start",
      },
    },
    cells: {
      style: {
        fontSize: '14px',
        justifyContent: 'flex-start !important',
        borderRight: "1px solid var(--border-color)",
      },
    },
  };

  useEffect(() => { getListData(); }, [update, page, limit, debouncedSearch]);

  const getListData = () => {
    setPending(true);
    if (debouncedSearch) {
      setPage(1);
    }
    listFaciltiyCategory({ page: debouncedSearch ? 1 : page, limit, search: debouncedSearch,pcn: status ? status.value === "true" : '', })(
      (response) => {
        if (response && response.status) {
          setData(response?.data?.results);
          setTotalRows(response?.data?.count);
          setPending(false);
        }
      }
    );
  };
  const handleDelete = (id) => {
    if (isDeleting) return;
    setIsDeleting(true);
    deletefacilitycategory(id)((response) => {
      if (response && response?.status) {
        toast.success('Successfully deleted');
        getListData();
      } else {
        toast.error('Deletion failed');
      }
      setIsDeleting(false);
      setIsSwal({ show: false, id: null });
    });
  };

    const handleToggleClick = (id) => {
          setSelectedId(id);
          setShowSweetAlert(true);
      };
  
      const confirmToggle = () => {
          if (selectedId !== null) {
           
              const currentItem = data.find(item => item.id === selectedId);
              const newStatus = currentItem ? !currentItem.access : true;
              
              facitliyCategoryStatus({
                  ids: [selectedId], 
                  access_status: newStatus 
              })((response) => {
                  console.log(response);
                  if (response?.status_code === 200) {
                      toast.success("Successfully Updated");
                      setUpdate(!update);
                  } else {
                      toast.error(response?.response?.data?.message || "Something went wrong");
                  }
              });
          }
          setShowSweetAlert(false);
      };
      


  return (
    <div className='facilities-category-section member-stats-section'>
             {showSweetAlert && (
                      <SweetAlert
                          warning
                          showCancel
                          confirmBtnText="Yes"
                          cancelBtnText="Cancel"
                          confirmBtnCssClass="custom-confirm-button-class"
                          cancelBtnCssClass="custom-cancel-button-class"
                          title={
                              <>
                                  <span className="custom-sweetalert-title">Change Status</span>
                                  <div className='text-center' style={{ fontSize: "12px", fontWeight: "normal", lineHeight: 2 }}>
                                      Are you sure you want to change the status?
                                  </div>
                              </>
                          }
                          onConfirm={confirmToggle}
                          onCancel={() => setShowSweetAlert(false)}
                          focusCancelBtn
                      />
                  )}
       <Drawer
        anchor={'right'}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        className='member-stats-drawer'
        sx={{
          '& .MuiPaper-root': {
            padding: '30px 19px',
            height: "100vh",
            // overflow: "hidden"
          },
          '& .MuiDrawer-paper': {
            width: '420px'
          },
          '& .MuiBox-root': {
            width: '420px'
          },
          '& .MuiList-root': {
            display: 'flex',
            columnGap: '10px',
            width: '90%',
            margin: 'auto',
            paddingTop: "25px"
          },
          '& .MuiButtonBase-root': {
            padding: '7px',
            textAlign: 'center',
            alignItems: 'center',
            borderRadius: '10px',
            backgroundColor: '#F5F5F5',
          }
        }}
      >
        <div className="drawer-top-box">
          <div>Filter</div>
          <button type="button" class="btn-close" aria-label="Close" onClick={() => setShowDrawer(false)}></button>
        </div>
        <div className="drawer-bottom-box mt-4">
          <Form onSubmit={(e) => e.preventDefault()}>
            {/* Name */}
           
            {/* Status */}
            <div className='item'>
              <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em5">
                <Form.Label>Status</Form.Label>
                <div className='input-border'>
                  <Select
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 5,
                      colors: {
                        ...theme.colors,
                      },
                    })}
                    placeholder="Select"
                    options={[{ label: "Yes", value: "true" }, { label: "No", value: "false" }]}
                    isSearchable={false}
                    isClearable={true}
                    onChange={(e) => setStatus(e)} />
              
                </div>
              </Form.Group>
            </div>
            <div className='submit-box d-flex align-items-end justify-content-end gap-3' style={{ height: "60vh" }}>
              <div className='btn-type-2 cursor-pointer' onClick={handleClear} >Clear</div>
              <div className='btn-type-1' type="submit"  onClick={handleFilter}>Submit</div>
            </div>
          </Form>
        </div>

      </Drawer>
      <div className='page-details'>
        <div className='top-section d-flex align-items-center justify-content-between'>
          <div className='left'>Facility Category</div>
          <div className='right d-flex align-items-center  gap-3'>
            <div className='search-box'>
              <input
                type='text'
                placeholder='Search facility'
                className='form-control'
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
              />
              {/* <FaSearch className='search-icon' /> */}
              {searchText && (
                <FaTimes className='clear-icon' onClick={clearSearch} />
              )}
            </div>
            {/* <div className='filter-box d-flex align-items-center gap-3 cursor-pointer' onClick={() => setShowDrawer(true)}>
              <FilterIcon />
              <span>Filter</span>
            </div> */}
            
              <div className='add-clubs-info cursor-pointer' onClick={() => navigate(CREATE_FACILITIES_CATEGORY, { state: { currentPageName: "Add Facilities Category" } })} title="Create">
                <AddIcon />
              </div>
          </div>
        </div>

        <div className='table-box mt-3'>
          <DataTable
            columns={columns}
            data={data}
            customStyles={customStyles}
            fixedHeader
            highlightOnHover
            responsive
            pagination
            paginationServer
            paginationPerPage={limit}
            progressPending={pending}
            onChangeRowsPerPage={setLimit}
            paginationTotalRows={totalRows}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            onChangePage={setPage}
          />
        </div>
      </div>
      <SweetAlertModal showSweetAlert={isSwal.show} setShowSweetAlert={(show) => {
        if (show) handleDelete(isSwal.id);
        else setIsSwal({ ...isSwal, show: false });
      }} />
    </div>
  )
}

export default FacilitiesCategory