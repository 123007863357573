import DataTable from "react-data-table-component";
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AddIcon } from "../../assets/images/common/add-icon.svg";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SweetAlert from 'react-bootstrap-sweetalert';
import { FaTimes } from 'react-icons/fa';
// Bootstrap
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
// Icon
import { ReactComponent as EditIcon } from "../../assets/images/common/edit-icon.svg";
import { ReactComponent as NotificationIcon } from "../../assets/images/common/notification.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/common/close-icon.svg";
import { ReactComponent as ViewIcon } from "../../assets/images/common/view-icon.svg";
import { CLUB_OFFER_ADD, CREATE_OFFER_NOTIFICATION } from '../../constants/pathname';
import SweetAlertModal from '../../components/SweetAlert/SweetAlertModal';
import { useEffect, useState } from "react";
// Mui
import { styled, Switch } from "@mui/material";
// Api
import { listClubInformation } from '../../api/actions/clubProfile';
import { clubOfferDetail, clubOfferList, createInvitation, deleteClubInvitation, sendClubInvitation, updateInvitationStatus } from '../../api/actions/offer';
import toast from "react-hot-toast";
import BulkUpdateAlert from "../../components/SweetAlert/BulkUpdateAlert";
import { paginationRowsPerPageOptions } from "../../helpers/helper";
import { listAllClubs } from "../../api/actions/club";
const Offer=() => {        
    const [showInvitation, setShowInvitation]=useState(false);
    const [clubOptions, setClubOptions]=useState([]);
    const [statusOptions, setStatusOptions]=useState([
        {label: 'Active', value: true},
        {label: 'Inactive', value: false},
    ]);
    // Search
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [search, setSearch] = useState('');
    // Loading
    const [loading, setLoading]=useState(false);
    // Clubs offer
    const [data, setData] = useState([]);
    // Pagination
    const [limit, setLimit]=useState(25);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    
    const [update, setUpdate] = useState(false);
    const [pending, setPending] = useState(false);
    
    // Sweet alert for delete
    const [showSweetAlert, setShowSweetAlert]=useState({id: '', show: false});
    // Sweet alert for send notification
    const [showSendSweetAlert, setShowSendSweetAlert]=useState({id: '', show: false});
    // Sweet alert for status update
    const [isBulkSwal, setIsBulkSwal] = useState({ 
            show: false, 
            id: '',
            is_publish:'' // status
        }); // Bulk Update

    const navigate=useNavigate();
        
    // Handle delete
    const handleDelete=() => {
        deleteClubInvitation(showSweetAlert?.id)((response) =>{
            if(response?.status && response?.status_code === 200){
                toast.success("Offer invitation deleted successfully");
            }else{
                toast.error("Failed to delete offer invitation");
            }
            setShowSweetAlert({id: '', show: false});
            setUpdate(!update);
        })
    }
    // Handle notification
    const handleNotification = () =>{

        sendClubInvitation(showSendSweetAlert?.id)((response) =>{
            if(response?.status && response?.status_code === 200){
                toast.success("Offer invitation sent successfully");
            }else{
                toast.error("Failed to send offer invitation");
            }
            setShowSendSweetAlert({id: '', show: false});
            setUpdate(!update);
        })
    }
    // Send notification
    const NotificationAlert = () =>{

        return(
            showSendSweetAlert && 
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    confirmBtnCssClass="custom-confirm-button-class"
                    cancelBtnCssClass="custom-cancel-button-class"
                    title={<span className="custom-sweetalert-title">Are you sure you want to send notification?</span>}
                    onConfirm={() => handleNotification()}
                    onCancel={() => setShowSendSweetAlert({id: '', show: false})}
                    focusCancelBtn
                />
        )
    };
     useEffect(() => {
        if (search.length >= 2) {
            const debounceTimer = setTimeout(() => {
                setDebouncedSearch(search);
            }, 500);

            return () => clearTimeout(debounceTimer);
        } else {
            setDebouncedSearch('');
        }
    }, [search]);
    // Getting clubs offer list
    useEffect(() =>{
        if (debouncedSearch) {
            setPage(1);
        }

        clubOfferList({
            page: debouncedSearch ? 1 : page,
            limit,
            search: debouncedSearch,
        })((response) =>{
            if(response?.status && response?.status_code === 200){
                setData(response?.data?.results);
                setTotalRows(response?.data?.count);
            }
        })
    },[update, debouncedSearch, page, limit]);

    const formik=useFormik({
        initialValues: {
            id: '',
            club: '',
            club_option:'',
            country: '',
            description: '',
            is_publish:'' // status
        },
        validationSchema: Yup.object({
            club: Yup.string().required('Club name is required'),
            description: Yup.string().required('Description is required'),
        }),
        onSubmit: (values) => {
            let props = {
                "club": values?.club,
                "country": values?.country,
                "description": values?.description,
                "is_publish": values?.is_publish
            };

            if(values?.id){
                props.id = values?.id;
            }

            setLoading(true);
            createInvitation(props)((response) =>{
                
                if(response?.status && response?.status !== 400){
                    toast.success("Offer invitation created successfully");
                }else{
                    toast.error("Failed to create offer invitation");
                }
                setLoading(false);
                setUpdate(!update);
                formik.handleReset();
                setShowInvitation(false);
            })
        }
    });

    // Get invitation detail
    const handleInvitationDetail = (id) =>{

        clubOfferDetail(id)((response) =>{
            if(response?.status && response?.status_code === 200){
                formik.setValues({
                    id: response?.data?.id,
                    club: response?.data?.club,
                    club_option: {label: response?.data?.club_name, value: response?.data?.club},
                    country: response?.data?.country,
                    description: response?.data?.description,
                    is_publish: response?.data?.is_publish
                });
                setShowInvitation(true);
            }
        })
    }

    const CustomSwitch=styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 39,
        height: 20,
        padding: 0,
        marginLeft: 5,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            // margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: 'var(--sandy-brown)',
                    opacity: 1,
                    border: 0,
                    ...theme.applyStyles('dark', {
                        backgroundColor: '#2ECA45',
                    }),
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.grey[100],
                ...theme.applyStyles('dark', {
                    color: theme.palette.grey[600],
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                backgroundColor: 'var(--sandy-brown)',
                opacity: 0.7,
                ...theme.applyStyles('dark', {
                    opacity: 0.3,
                }),
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 20,
            height: 20,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26/2,
            backgroundColor: '#E9E9EA',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
            ...theme.applyStyles('dark', {
                backgroundColor: '#39393D',
            }),
        },
    }));

    // Define columns for the table
    const columns=[
        { 
            name: "Sl.No", 
            selector: row => row.club, 
            cell: (row, index) =>
                <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.name}>
                    {(limit * (page - 1)) + (index + 1)}
                </div>,
            width: "70px"},
        { name: "Club Name", selector: row => <div title={row.club_name}>{row.club_name}</div>},
        { name: "Description", selector: row => <div title={row.description}>{row.description}</div>,  width: "500px"},
        {
            name: "Active/Inactive",
            selector: row =>
                <div>
                    <CustomSwitch 
                        defaultChecked={row.is_publish} 
                        onChange={() =>{
                            setIsBulkSwal({ 
                                show: true, 
                                id: row.id,
                                is_publish: !row.is_publish // status
                            });
                        }}
                    />
                </div>
            , width: "150px"
        },
        {
            name: "Actions",
            selector: row => (
                <div className="d-flex align-items-center gap-2">
                    <div className="view-icon cursor-pointer" title="Edit" onClick={() => handleInvitationDetail(row.id)}>
                        <EditIcon />
                    </div>
                    <div className="view-icon remove-icon cursor-pointer" 
                        title="Delete"
                        onClick={() => {
                            setShowSweetAlert({ 
                                show: true, 
                                id: row.id,
                            });
                        }} 
                    >
                        <CloseIcon />
                    </div>
                    <div 
                        className="view-icon cursor-pointer" title="Send Notification" 
                        style={{pointerEvents: row.is_publish ? 'auto' : 'none'}}
                        onClick={() => {
                            setShowSendSweetAlert({ 
                                show: true, 
                                id: row.id,
                            });
                        }} 
                    >
                        <NotificationIcon  />
                    </div>
                </div>
            ),
            sortable: false,
        },
    ];

    // Club options
    useEffect(() => {
        listAllClubs({
            page,
            limit,
            filter: { status_club: "True" }
        })(
            (response) => {
                if (response&&response.status) {
                    if (Array.isArray(response?.data?.results)) {
                        let clubArr=response.data?.results?.map((item) => {
                            return { label: item.club_name, value: item.club, country: item.country };
                        });
                        setClubOptions(clubArr);
                    }
                }
            }
        );
    }, []);

    // Scroll to top    
    useEffect(() => {
        if (window) {
            window.scrollTo(0, 0);
        }
    }, []);

    // Custom styling for the table
    const customStyles={
        tableWrapper: {
            style: {
                minHeight: "100%",
                maxHeight: "100%",
            },
        },
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",
                border: "1px solid var(--border-color)",
                overflow: "hidden",
                minHeight: "100%",
                maxHeight: "100%",
            },
        },
        headCells: {
            style: {
                backgroundColor: "var(--light-blue)",
                justifyContent: 'flex-start !important',
                color: "var(--dark-gray)",
                fontWeight: "400",
                fontSize: "15px",
            },
        },
        rows: {
            style: {
                height: "45px", // Set row height
                justifyContent: "start",
            },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    };

    // Function to fetch club options based on input
    let typingTimer;
    const loadOptionsClub=(inputValue) => {
        return new Promise((resolve) => {
            clearTimeout(typingTimer);
            typingTimer=setTimeout(() => { 
                if (inputValue.length>=3) {
                    listAllClubs({ page: 1, limit: 10, search: inputValue, filter: {status_club: "True"} })((response) => {
                        if (response?.status_code===200) {
                            const options=response?.data?.results||[];
                            let clubArr=options?.map((item) => {
                                return { label: item.club_name, value: item.club, country: item.country };
                            });
                            setClubOptions(clubArr);
                            resolve(clubArr);
                        } else {
                            resolve([]);
                        }
                    });
                } else {
                    resolve([]);
                }
            }, 300);
        });
    };

    const handleChange = (e, name, selected_name) => {
        if (e) {
            formik.setValues({
                ...formik.values,
                [name]: e.value,
                [selected_name]: e,
                country: e.country

            })
        } else {
            formik.setValues({
                [name]: null,
                [selected_name]: [],
                country: null
            })
        }
    }
    // Update club invitation status
    const handleUpdateInvitation = () =>{

        let props = {
            "id": isBulkSwal?.id,
            "is_publish": isBulkSwal?.is_publish
        };
        updateInvitationStatus(props)((response) =>{
            
            if(response?.status && response?.status !== 400){
                toast.success("Status updated successfully");
            }else{
                toast.error("Failed to update status");
            }
            setUpdate(!update);
            setIsBulkSwal({ 
                show: false, 
                id: '',
                is_publish:'' // status
            });
        })
        
    }
    
    // Handle search
    const handleSearch = (value) => {
        setSearch(value);
    }
    // Clear search
    const clearSearch = () => {
        setSearch('');
        setDebouncedSearch('');
        setUpdate(!update);
    };

    return (
        <div className="offer-listing-page">
            <div className='member-stats-section brands'>
                {/* Notification Alert */}
                {showSendSweetAlert?.show && <NotificationAlert />}
                {/* Delete Alert */}
                <SweetAlertModal 
                    showSweetAlert={showSweetAlert?.show} 
                    setShowSweetAlert={(show) => {
                    if (show) handleDelete();
                    else setShowSweetAlert({ 
                            show: false, 
                            id: '',
                        });
                    }}                     
                />
                {/* Bulk Update Status */}
                <BulkUpdateAlert
                    showSweetBulkAlert={isBulkSwal.show}
                    setShowBulkAlert={(show) => {
                    if (show) handleUpdateInvitation();
                    else setIsBulkSwal({ 
                            show: false, 
                            id: '',
                            is_publish:'' // status
                        });
                    }}
                />
                <div className="row flex-row">
                    <div className={`page-details ${showInvitation? 'col-md-8':'col-md-12'}`}>
                        <div className='top-section d-flex align-items-center justify-content-between'>
                            <div className='left'>Club Invitations</div>
                            <div className='right d-flex gap-4'>
                                <div className='search-box'>
                                    <input
                                        type='text'
                                        placeholder='Search by Club Name'
                                        className='form-control'
                                        value={search}
                                        onChange={(e) => handleSearch(e.target.value)}
                                    />
                                    {
                                        search && (
                                            <FaTimes className='clear-icon' onClick={clearSearch} />
                                        )}
                                </div> 
                                <div 
                                    className='add-clubs-info cursor-pointer' 
                                    title="Add Invitation" 
                                    onClick={() =>{
                                        formik.handleReset();
                                        setShowInvitation(!showInvitation);
                                    }}
                                >
                                    <AddIcon />
                                </div>
                            </div>
                        </div>

                        <div className='table-box mt-3'>
                            <DataTable
                                columns={columns}
                                data={data}
                                customStyles={customStyles}
                                fixedHeader
                                highlightOnHover
                                responsive
                                pagination
                                paginationServer
                                paginationPerPage={limit}
                                progressPending={pending}
                                onChangeRowsPerPage={setLimit}
                                paginationTotalRows={totalRows}
                                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                                onChangePage={setPage}
                            />
                        </div>
                    </div>
                    <div className={`offer-invitation-box ${showInvitation? '':'d-none'} col-md-4`}>
                        <div className="title">Add Invitation</div>
                        <div className="invitation-form mt-3" style={{height: "90%"}}>
                            <Form onSubmit={formik.handleSubmit}>
                                {/* Club */}
                                <div className='item'>
                                    <Form.Label>Club<span className='required'>*</span></Form.Label>
                                    <Form.Group className="mb-3" controlId="club">
                                        <AsyncSelect
                                            loadOptions={loadOptionsClub}
                                            defaultOptions={clubOptions||[]}
                                            classNamePrefix="select"
                                            placeholder={'Select Club'}
                                            onChange={(e) => handleChange(e, 'club', 'club_option')}
                                            value={formik.values?.club_option}
                                            styles={{
                                                control: (styles, { isFocused }) => ({
                                                    ...styles,
                                                    maxHeight: '31px',
                                                    border: formik.errors.club&&formik.touched.club
                                                        ? '1px solid #dc3545'
                                                        :'1px solid var(--light-grayish)',
                                                    outline: 'none',
                                                    boxShadow: isFocused? '0 0 0 1px var(--light-grayish)':'none',
                                                    color: 'var(--medium-gray)',
                                                    fontSize: '15px',
                                                    '&:hover': {
                                                        border: formik.errors.club&&formik.touched.club
                                                            ? '1px solid #dc3545'
                                                            :'1px solid var(--light-grayish)',
                                                    },
                                                }),
                                                valueContainer: (styles) => ({
                                                    ...styles,
                                                    alignItems: 'center',
                                                    padding: '3px 8px',
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: 'var(--medium-gray)',
                                                    fontSize: '15px',
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    border: '1px solid var(--light-grayish)',
                                                    boxShadow: 'none',
                                                }),
                                                placeholder: (styles) => ({
                                                    ...styles,
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    color: 'var(--medium-gray)',
                                                    fontSize: '15px',
                                                }),
                                            }}
                                        />
                                        {formik?.touched?.club&&formik?.errors?.club?
                                            <div className='error-msg'>{formik?.errors?.club}</div>
                                            :null}
                                    </Form.Group>
                                </div>
                                {/* Description */}
                                <div className="mb-3">
                                    <Form.Label>Description<span className='required'>*</span></Form.Label>
                                    <Form.Group controlId="description">
                                        <Form.Control 
                                            as="textarea" 
                                            rows={5} 
                                            placeholder="Description" 
                                            name="description"    
                                            value={formik?.values?.description}                                         
                                            onChange={formik.handleChange}
                                            style={{
                                                border: formik.errors.description&&formik.touched.description
                                                        ? '1px solid #dc3545'
                                                        :'1px solid var(--light-grayish)',
                                            }}
                                        />
                                        {formik?.touched?.description&&formik?.errors?.description?
                                            <div className='error-msg'>{formik?.errors?.description}</div>
                                            :null}
                                    </Form.Group>
                                </div>
                                {/* Status */}
                                <div className='item'>
                                    <Form.Label>Status</Form.Label>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1em5">
                                        <Select
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 5,
                                                colors: {
                                                    ...theme.colors,
                                                },
                                            })}
                                            options={statusOptions}
                                            value={{label: `${formik.values?.is_publish ? 'Active' : 'Inactive'}`, value: formik.values?.is_publish}}
                                            onChange={(option) => {
                                                formik.setValues({
                                                     ...formik.values,
                                                     is_publish: option.value
                                                })
                                            }}
                                            placeholder="Status"
                                        />
                                    </Form.Group>
                                </div>
                                <div className='submit-box d-flex align-items-end justify-content-end gap-3' style={{ height: "36vh", paddingBottom: "30px" }}>
                                    <button 
                                        className='btn-type-2 cursor-pointer'
                                        onClick={() => formik.resetForm()}
                                    >
                                        Clear
                                    </button>
                                    <button className='btn-type-1' type="submit" disabled={loading}>Submit</button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Offer