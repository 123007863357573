import {ReactComponent as AddIcon} from "../../assets/images/common/add-icon.svg";
import {ReactComponent as AddIconBlack} from "../../assets/images/common/add-icon-black.svg";
import {ReactComponent as MinusIcon} from "../../assets/images/common/minus-icon.svg";
// Ck Editor
import { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
    ClassicEditor,  
    Autoformat,  
    Autosave,
    Bold,  
    Essentials,  
    Heading,  
    Italic, 
    Paragraph,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar	,
    TextTransformation,
    Undo,
    List,
    Alignment
} from "ckeditor5";
import { Form } from "react-bootstrap";
// Rsuite Datepicker, TimePicker
import { DatePicker, TimePicker } from 'rsuite';

const About = () => {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    // For Ck Editor
    const editorContainerRef = useRef(null);
    const editorRef = useRef(null);
    const [isLayoutReady, setIsLayoutReady] = useState(false);  

    // Editor configuration
    const editorConfig={
        toolbar: {
        items: [
            "undo",
            "redo",
            "|",        
            "|",
            "heading",
            "|",
            "bold",
            "italic",
            "bulletedList", 
            "numberedList",
            "|",
            "alignment"       
        ],
        shouldNotGroupWhenFull: false,
        },
        alignment: {
        options: [ 'left', 'right', 'center', 'justify' ]
        },
        plugins: [      
        Autoformat,
        Autosave,
        Bold,
        Essentials,
        Heading,
        Italic,
        Paragraph,      
        List,
        TextTransformation,
        Table,
                TableCaption,
                TableCellProperties,
                TableColumnResize,
                TableProperties,
                TableToolbar,
        Alignment,
        Undo,
        ],
        heading: {
        options: [
            {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
            },
            {
            model: "heading1",
            view: "h1",
            title: "Heading 1",
            class: "ck-heading_heading1",
            },
            {
            model: "heading2",
            view: "h2",
            title: "Heading 2",
            class: "ck-heading_heading2",
            },
            {
            model: "heading3",
            view: "h3",
            title: "Heading 3",
            class: "ck-heading_heading3",
            },
            {
            model: "heading4",
            view: "h4",
            title: "Heading 4",
            class: "ck-heading_heading4",
            },
            {
            model: "heading5",
            view: "h5",
            title: "Heading 5",
            class: "ck-heading_heading5",
            },
            {
            model: "heading6",
            view: "h6",
            title: "Heading 6",
            class: "ck-heading_heading6",
            },
        ],
        },
    
        initialData: "",
        menuBar: {
        isVisible: true,
        },
        placeholder: "Type or paste your content here!",
    };

     // Setting layout for Ckeditor
    useEffect(() => {
        setIsLayoutReady(true);

        return () => setIsLayoutReady(false);
    }, []);


  return (
    <div className='about-club-section'>
        {/* About Event */}
        <div className='panel-type-1'>
            About Event
        </div>
        <div
            className="editor-container editor-container_classic-editor mt-4"
            ref={editorContainerRef}
        >
            {/* CK Editor for Document */}
            <div className="editor-container__editor">
                <div ref={editorRef} id="#editor">
                    {isLayoutReady && (
                        <CKEditor
                            data={""}
                            editor={ClassicEditor}
                            config={editorConfig}
                            onReady={(editor) =>
                                (editorRef.current = editor)
                            }
                        />
                    )}
                </div>
            </div>
        </div>   
        {/* Inclusions */}
        <div className='panel-type-1 mt-5'>
            Inclusions <span className="required">*</span>
        </div>
        <div className="inclusions-box mt-4">
            <div className="item row m-0 border-type-1 p-23">
                <div className="col-md-6">
                    <Form.Control 
                        type="text"
                        className="input-field"
                        name="inclusion-1"
                    />
                </div>
                <div className="col-md-6">
                    <Form.Control 
                        as="textarea"
                        placeholder="Description"
                        name="description-1"
                        rows={4}
                    />
                </div>
            </div>
            <div className='d-flex align-items-start btn-type-4 cursor-pointer mt-3'>                    
                <AddIcon />
                Add New
            </div>
        </div>
        {/* Onboard Experiences */}
        <div className='panel-type-1 mt-5'>
            Onboard Experiences <span className="required">*</span>
        </div>
        <div className="onboard-experiences-box row m-0">
            <div className="col-md-6">
                <div className="item border-type-1 d-flex align-items-center mt-4 p-2">
                    <div className="col-md-11">
                        <Form.Control 
                            type="text"
                            className="input-field border-0 p-0"
                            name="experience-1"
                        />
                    </div>
                    <div className="col-md-1 d-flex justify-content-end cursor-pointer">
                        <AddIconBlack />
                    </div>
                </div>
                <div className="item border-type-1 d-flex align-items-center mt-4 p-2">
                    <div className="col-md-11">
                        <Form.Control 
                            type="text"
                            className="input-field border-0 p-0"
                            name="experience-1"
                        />
                    </div>
                    <div className="col-md-1 d-flex justify-content-end cursor-pointer">
                        <MinusIcon />
                    </div>
                </div>
            </div>
        </div>
        {/* Itinerary */}
        <div className='panel-type-1 mt-5'>
            Itinerary <span className="required">*</span>
        </div>
        <div className="itineray-box">
            <div className="item border-type-1 row m-0 mt-4  p-23">
                {/* Start Date */}
                <div className='col-md-3 mb-2 start-date-input'>                        
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1em11">
                        <Form.Label>Start Date <span className="required">*</span></Form.Label>
                        <div className='input-border'>
                            <DatePicker 
                                id='start_date' 
                                onChange={(value) => {
                                    setFromDate(value);
                                    if (toDate && value > toDate) {
                                        setToDate(null); // Reset "To Date" if it becomes invalid
                                    }
                                }}
                                placement="autoVerticalEnd"
                            />
                        </div>
                    </Form.Group>
                </div>
                {/* End Date */}
                <div className='col-md-3 mb-2 end-date-input'>                        
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1em4">
                        <Form.Label>End Date <span className="required">*</span></Form.Label>
                        <div className='input-border'>
                            <DatePicker 
                                id='end_date' 
                                onChange={(value) => setToDate(value)}
                                placement="autoVerticalEnd"
                                shouldDisableDate={(date) => {
                                        // Disable dates before the selected Start Date
                                        return fromDate ? date < fromDate : false;
                                }}  
                            />
                        </div>
                    </Form.Group>
                </div> 
                {/* Start Time */}
                <div className='col-md-3 mb-2'>                        
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1em4">
                        <Form.Label>Start Time <span className="required">*</span></Form.Label>
                        <div className='input-border'>
                            <TimePicker 
                                id='start_time'  
                            />
                        </div>
                    </Form.Group>
                </div> 
                {/* End Time */}
                <div className='col-md-3 mb-2'>                        
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1em4">
                        <Form.Label>End Time <span className="required">*</span></Form.Label>
                        <div className='input-border'>
                            <TimePicker 
                                id='end_time'  
                            />
                        </div>
                    </Form.Group>
                </div> 
                <div className='d-flex align-items-start btn-type-4 cursor-pointer'>                    
                    <AddIcon />
                    Add New
                </div>
            </div>            
        </div>
    </div>
  )
}

export default About