import React from 'react'
import img1 from "../../assets/images/common/img1.png";
import {ReactComponent as AddIcon} from "../../assets/images/common/add-icon.svg";
import {ReactComponent as SearchIcon} from "../../assets/images/common/search-icon.svg";
import {ReactComponent as CloseIcon} from "../../assets/images/common/close-icon.svg";
import { useNavigate } from 'react-router-dom';
import { CLUBS_INFORMATION, CREATE_CLUBS_INFORMATION } from '../../constants/pathname';
import AsyncSelect from 'react-select/async';
import { Form } from 'react-bootstrap';

const ViewClubsInfo = () => {
    const navigate = useNavigate();

  return (
    <div className='view-clubs-info-section'>
        <div className='page-details'>
            {/* Club */}
            <div className='panel-type-1'>
                Club
            </div>
            <div className='club-item col-md-3 mt-5'>
                <div className='img-wrapper'>
                    <img src={img1} alt='Club'/>
                </div>
                <div className='club-name mt-2'>Lakewood Ranch Golf and Country Club</div>
            </div>
            <div className='panel-type-1 mt-5 d-flex align-items-center justify-content-between'>
                <div>Add Affiliated Clubs</div>
                <div className='d-flex align-items-center btn-type-3 cursor-pointer' onClick={() => navigate(CREATE_CLUBS_INFORMATION)}>                    
                    <AddIcon />
                    Add New
                </div>
            </div>
            <div className='affiliated-clubs mt-4'>
                <div>Affiliated Clubs<span className='required'>*</span></div>
                <Form onSubmit={(e) => e.preventDefault()}>
                    <div className='search-box col-md-6 mt-2'>
                        <SearchIcon className='search-icon'/>
                        <AsyncSelect
                            classNamePrefix="select"
                            isClearable={true}
                            placeholder={'Select'}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 5,
                                border: "1px solid var(--light-grayish)"
                            })}
                        />
                    </div>
                    <div className='club-list row align-items-center gap-4 mt-4'>
                        <div className='item col-md-6 col-xl-4 d-flex justify-content-between'>
                            <div className='left'>
                                <div className='title'>Ambassy Club Shanghai</div>
                                <div className='location d-flex align-items-center'>
                                    Shanghai, China | City Club
                                </div>
                            </div>
                            <div className='right d-flex align-items-center'>
                                <div title='Remove'>
                                    <CloseIcon className='close-icon'/>
                                </div>
                            </div>
                        </div>
                        <div className='item col-md-6 col-xl-4 d-flex justify-content-between'>
                            <div className='left'>
                                <div className='title'>Estancia Golf Club</div>
                                <div className='location d-flex align-items-center'>
                                    Shanghai, China | City Club
                                </div>
                            </div>
                            <div className='right d-flex align-items-center'>
                                <div title='Remove'>
                                    <CloseIcon className='close-icon'/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-5'>
                        <button className='btn-type-2 cursor-pointer' onClick={() => navigate(CLUBS_INFORMATION) }>Cancel</button>
                        <button className='btn-type-1' type="submit" onClick={() => navigate(CLUBS_INFORMATION)}>Submit</button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
  )
}

export default ViewClubsInfo