import { DELETE_SPECIAL_SUBSCRIBE_USER, DETAIL_SPECIAL_SUBSCRBE_USER, LIST_SPECAIL_SUBSCRIBE_USER, SPECAIL_SUBSCRIBE_USER } from "../api";
import axiosInstance from "../axiosinstance";

export const listSpecialSubscribe = (props) => onResponse => {
    try {
        let BASE_URL = LIST_SPECAIL_SUBSCRIBE_USER + '?'
        if (props.page) {
            BASE_URL += 'page=' + props.page + '&'

    }
        if (props.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if(props.sort_by){
            BASE_URL+='sort_by='+props.sort_by+'&'
        }
        if(props.order){
            BASE_URL+='order='+props.order+'&'
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }

};

export const addSpecialSubscribeUser=(props) => onResponse => {
    try {
        axiosInstance.post(SPECAIL_SUBSCRIBE_USER, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const deleteSpecialSubscribeUser = (id) => onResponse => {
    try {

        axiosInstance.delete(DELETE_SPECIAL_SUBSCRIBE_USER, { data: { id: [id] } })

            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};


export const getSpecialSubscriber = (props) => onResponse => {
    try {
        axiosInstance.get(DETAIL_SPECIAL_SUBSCRBE_USER + '?id=' + props.id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};