import React, { Fragment, useEffect, useState } from "react";
import { Button, Container, Dropdown } from "react-bootstrap";
import logo from "../../assets/images/common/logo/logo-pcw-white.png";
import logoPcn from "../../assets/images/common/logo/logo-pcn-white.png";
import { MdKeyboardArrowRight } from "react-icons/md";
import { checkTheme } from "../../utils/utils";


const LoginHeader = () => {

    const navigateToClub = () => {
        if (checkTheme()) {
            window.location.href = 'https://member.platinumclubnet.com/'
        }
        else {
            window.location.href = 'https://member.privateclubworld.com/'
        }
    }
    return (
        <Fragment>
            <div className="header-container   login-heaed" >
                <Container className="d-flex flex-row align-items-center justify-content-between">
                    <div></div>
                    <div className="login-img-wrapper">
                        <img src={checkTheme() ? logoPcn : logo} alt="logo" style={{ height: '55px' }} className="img-fluid" />
                    </div>
                    <div className="member-btn d-flex align-items-center gap-4" style={{ fontWeight: "200", fontFamily: 'GraphikArabic' }}>
                        <div className='club-login-button' onClick={() => navigateToClub()} type='button'>Member Login<MdKeyboardArrowRight size={20}/></div>
                        <div>
                        </div>
                    </div>
                </Container>
            </div>

        </Fragment>
    )
}

export default LoginHeader
