import React, { useEffect, useState } from 'react'
import pcn from "../../assets/images/loader/PCNLOADER.gif";
import pcw from "../../assets/images/loader/PCWLOADER.gif";
import { USER_DETAIL_FROM_TOKEN } from '../../api/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { HOME } from '../../constants/pathname';
import toast from 'react-hot-toast';

const Welcome = () => {
    const [theme, setTheme] = useState("");
    const location = useLocation();
    const navigate = useNavigate()
    const BACKEND_URL = process.env.REACT_APP_BACKENDURL;
    const searchParams = new URLSearchParams(location.search);
    const tc = searchParams.get("tc");


    useEffect(() => {
        const currentTheme = document.documentElement.getAttribute("theme");
        setTheme(currentTheme);
        getUserDEtailFromToken()

    }, []);


    const getUserDEtailFromToken = async () => {
        try {
            const result = await fetch(`${BACKEND_URL}${USER_DETAIL_FROM_TOKEN}?pcn=${theme == 'platinum-club-net' ? true : false}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    access_token: tc,
                }),
            })
            if (!result.ok) {
                throw new Error(`HTTP error! Status: ${result.status}`);
            }
            const response = await result.json();
            localStorage.setItem("token", response?.data?.access_token)
            localStorage.setItem("user_info", JSON.stringify(response?.data?.user))
            localStorage.setItem("is_logged", true);
            setTimeout(() => {
                navigate(HOME, { replace: true })
            }, 800)
            toast.success('Logged in successfully');
        } catch (err) {
            console.log(err.message);
        } finally {
        }

    }

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",

                width: '100%',
                height: '100vh'

            }}
        >
            {theme === "platinum-club-net" ? (
                <img src={pcn} alt="" />
            ) : (
                <img src={pcw} alt="" />
            )}
        </div>
    )
}

export default Welcome