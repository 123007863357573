import { ADD_LETTER_OF_INTRODUCTION, DETAILS_LETTER_OF_INTRODUCTION } from "../api";
import axiosInstance from "../axiosinstance";

export const addLetterofIntroduction=(props) => onResponse => {
    try {
        axiosInstance.post(ADD_LETTER_OF_INTRODUCTION, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};


export const getletterofIntroduction = (id) => (onResponse) => {

    try {
        axiosInstance.get(`${DETAILS_LETTER_OF_INTRODUCTION}?id=${id}`)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {
       
    }
};

