import React from 'react'
import CreateClubsInfo from '../../components/CreateClubsInfo/CreateClubsInfo'

const CreateClubsInformation = () => {

  return (
    <div className='create-clubs-information-page'>
        <CreateClubsInfo />
    </div>
  )
}

export default CreateClubsInformation