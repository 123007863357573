import React from 'react'
import { Container } from 'react-bootstrap'
import logo from "../../assets/images/common/logo/logo-pcw-white.png"
import logo1 from "../../assets/images/common/logo/logo-pcn-white.png"
import { checkTheme } from '../../utils/utils'

const TermsCondtion = () => {
  
  return (

    <section className='terms-condtion'>
      <div className='logo'>
        <div className='image-wrapper'>
          <img src={checkTheme() ? logo1 : logo} alt='' style={{maxWidth: "275px"}}/>
        </div>
      </div>

      <Container>
        <h3 className='main-head'>Terms & Conditions</h3>
        <div style={{ paddingRight: "15px" }}>
          <p style={{ textAlign: "center", marginTop: "15px" }}>
            <strong>USER TERM OF USE</strong>
          </p>

          <p style={{ textAlign: "justify" }}>
            <span style={{ fontWeight: 400 }}>
              This is a legally binding agreement between you (“
            </span>
            <strong>You</strong>
            <span style={{ fontWeight: 400 }}>” or “</span>
            <strong>you</strong>
            <span style={{ fontWeight: 400 }}>
              ”) and MobiCom America Inc., a Delaware corporation located at
              6010 W Spring Creek Parkway, Plano, Texas 75024 (“
            </span>
            <strong>MobiCom</strong>
            <span style={{ fontWeight: 400 }}>” or “</span>
            <strong>us</strong>
            <span style={{ fontWeight: 400 }}>” or “</span>
            <strong>we</strong>
            <span style={{ fontWeight: 400 }}>
              ”) governing your use of {checkTheme() ? "PlatinumClubNet" : " PrivateClubWorld"} and/or its related
              internet and web components (collectively, the “
            </span>
            <strong>Platform</strong>
            <span style={{ fontWeight: 400 }}>”).&nbsp;</span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontWeight: 400 }}>
              Please read it carefully. By clicking “
            </span>
            <strong>I Agree</strong>
            <span style={{ fontWeight: 400 }}>
              ” or visiting/using/installing the Platform or any updates OR by
              streaming its content from any web portal (as may be applicable),
              owned by MobiCom, you agree to the terms and conditions contained
              herein.&nbsp;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontWeight: 400 }}>
              MobiCom has established an ecosystem known as “{checkTheme() ? "PlatinumClubNet" : " PrivateClubWorld"}™”
              whereby,{" "}
            </span>
            <em>
              <span style={{ fontWeight: 400 }}>inter alia </span>
            </em>
            <span style={{ fontWeight: 400 }}>
              private clubs and members of private clubs can interact, transact
              and avail the various benefits offered by MobiCom through its
              partner network of brands, corporations and other entities (“
            </span>
            <strong>{checkTheme() ? "PlatinumClubNet" : " PrivateClubWorld"}</strong>
            <span style={{ fontWeight: 400 }}>
              ”). The {checkTheme() ? "PlatinumClubNet" : " PrivateClubWorld"} ecosystem may be used by other clubs,
              members of other clubs, brands, corporates and their employees (“
            </span>
            <strong>Users</strong>
            <span style={{ fontWeight: 400 }}>”).</span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontWeight: 400 }}>
              Your use of the Platform and the underlying services and tools
              offered through the Platform, and such other benefits, offers,
              products and services that MobiCom may provide (collectively the “
            </span>
            <strong>Services</strong>
            <span style={{ fontWeight: 400 }}>
              ”) are subject to and governed by the following terms and
              conditions (“
            </span>
            <strong>Terms</strong>
            <span style={{ fontWeight: 400 }}>”).&nbsp;</span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontWeight: 400 }}>
              In addition to these Terms, when you use any of the Services
              provided as part of {checkTheme() ? "PlatinumClubNet" : " PrivateClubWorld"}, you may be subject to
              separate and specific rules, guidelines, policies, terms, and
              conditions applicable to such Service, and such conditions shall
              be treated as an integral part of the Terms.&nbsp;&nbsp;
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontWeight: 400 }}>
              In addition to these Terms and depending on the Services opted for
              by you, you shall be required to read and accept the relevant
              terms and conditions of service (“
            </span>
            <strong>TOS</strong>
            <span style={{ fontWeight: 400 }}>
              ”) for each such Service as displayed on our Platform or as
              otherwise made available to you by MobiCom, which may be updated
              or modified by MobiCom from time to time. Prior to availing of
              such Service, you must accept the specific TOS for such Service as
              displayed on the Platform, or otherwise communicated to you.
              Utilisation of our Services shall be deemed to be acceptance of
              the TOS and these Terms. Such TOS shall be deemed to be a part of
              these Terms and in the event of a conflict between such TOS and
              these Terms, these Terms shall prevail.&nbsp;
            </span>
          </p>
          <p>
            <strong>Membership Eligibility</strong>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontWeight: 400 }}>
              In order to access and use the Platform or register yourself on a
              MobiCom Platform, you must agree that:
            </span>
          </p>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                You have the right, authority and legal capacity to agree to the
                Terms on behalf of yourself
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                You have read, understood and agree to be bound by the Terms
                with respect to yourself; and&nbsp;
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                You have read, understood and agree to be bound by our{" "}
                {window.location.origin == <a href="https://live.platinumclubnet.com/termsofuse">
                  Privacy Policy
                </a> ? "PlatinumClubNet" :
                  <a href="https://live.privateclubworld.com/termsofuse">
                    Privacy Policy
                  </a>}
              </span>
              <span style={{ fontWeight: 400 }}>
                &nbsp;with respect to yourself&nbsp;
              </span>
            </li>
          </ol>
          <p>
            <strong>Account and Registration Obligations</strong>&nbsp;
          </p>
          <ul>
            <li style={{ textAlign: "justify" }}>
              <strong>
                <em>
                  <span style={{ fontWeight: 400 }}>
                    You must register yourself on the Platform to access and use
                    the Platform and avail the services. The steps to use the
                    Platform shall be communicated to you via registered
                    electronic mail.
                  </span>
                </em>
              </strong>
            </li>
          </ul>
          <p>
            <strong>Availing of Services</strong>
          </p>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                When you register yourself on the Platform, you agree and
                undertake at all times to be responsible for maintaining the
                confidentiality of your password and user ID and shall be fully
                responsible for all activities that occur by use of such
                password or user ID. Further, you agree not to use any other
                User’s user ID and password for any purpose whatsoever without
                proper authorization from such User. You are responsible for the
                security of your password and for all transactions undertaken
                using your password through our Platform. The password entered
                by you is encrypted and is not known to us. We will not be
                responsible for any financial loss, inconvenience or mental
                agony resulting from misuse of your account/password/credit card
                number/account details number on our Platform.
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                You agree to immediately notify us of any unauthorized use of
                your password or user ID.&nbsp;
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                You also agree to: (i) provide true, accurate and complete
                information about yourself when you are availing our Services;
                and (ii) maintain and promptly update such data to keep it true,
                accurate, current and complete and (iii) shall not impersonate,
                misrepresent or make any proxy bookings of behalf of a third
                party at any private clubs on our Platform or while availing any
                of our Services If you provide any information that is untrue,
                inaccurate, not current or incomplete or we have reasonable
                grounds to suspect that such information is untrue, inaccurate,
                not current or incomplete, we have the right to suspend or
                terminate and refuse any and all current or future use of the
                Platform and/or any Service.
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              Furthermore, you grant us the right to disclose to third parties
              such data that you may provide to the extent necessary for the
              purpose of carrying out any Services authorised by you.
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              Prior to availing of any Services, you shall be provided with the
              specific TOS as well as details specific to such Service, which
              are also subject to our general Terms and any other applicable
              MobiCom policies. Utilisation of such Service shall be deemed to
              be acceptance of such Terms, TOS, Service specific details, and
              applicable MobiCom policies.
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              MobiCom at its sole discretion reserves the right not to accept
              any order or request for Services placed by you through the
              Platform without assigning any reason thereof.
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              Any contract to provide any service by MobiCom is not complete
              until any monetary terms towards the Service is fulfilled by the
              User and accepted by MobiCom.
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              While we endeavour to provide quality Services and accurate
              pricing information, typographical and other errors may occur. In
              the event that a Service is listed at an incorrect price or with
              incorrect information due to an error in pricing or Service
              information, we may, at our discretion, either contact you for
              instructions or cancel the provision of such Services. If we
              cancel the provision of such Services after the payment has been
              processed, the said amount will be remitted to your account from
              which the payment was made.
            </li>
          </ol>
          <p>
            <span style={{ fontWeight: 400 }}>&nbsp;</span>
            <strong>
              Services availed from other Users, clubs, brands, corporations and
              service providers
            </strong>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Through this Platform, you may interact and make reservations at
              private clubs, or avail of services or products (“
            </span>
            <strong>Third Party Services</strong>
            <span style={{ fontWeight: 400 }}>
              ”) through third party service providers (which may include
              private clubs) (“
            </span>
            <strong>Service Providers</strong>
            <span style={{ fontWeight: 400 }}>
              ”). You acknowledge that MobiCom is not and cannot be a party to
              or control in any manner any transactions between Users and
              Service Providers. Consequently, you acknowledge that:
            </span>
          </p>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>
              <span style={{ fontWeight: 400 }}>
                The private clubs that you may be provided access to may be
                different from members of other clubs. This happens because
                clubs may choose, at their own discretion, to remove access from
                certain clubs and their members.&nbsp;
              </span>
            </li>
            <li>
              Your correspondence or dealings with Service Providers found on or
              through the Platform, including payment and delivery of related
              products or services, and any other terms, conditions, warranties
              or representations associated with such dealings, are solely
              between you and such Service Provider. We shall not be responsible
              or liable for any loss or damage of any sort incurred as the
              result of any such dealings or as the result of the presence of
              such Service Providers on the Platform.
            </li>
            <li>
              MobiCom does not make any representations or warranties as to the
              Services proposed to be provided by Service Providers via the
              Platform. MobiCom does not implicitly or explicitly support or
              endorse the sale or purchase of any Third-Party Services on the
              Platform. MobiCom does not verify the nature of any Third-Party
              Services proposed to be sold or provided, and merely acts as a
              platform to connect Users to Service Providers. Accordingly, you
              acknowledge that you and any User(s) and/or Service Providers you
              may interact or transact with via the Platform are each solely
              liable for your own acts or omissions and any loss or damage
              thereto. MobiCom is not the service provider and cannot therefore
              control or prevent changes in the published descriptions or
              representations, which are always based upon information provided
              by the Service Providers. MobiCom acts only as a facilitator for
              Third Party Services and shall not be held liable for any changes,
              deficiencies, disputes, etc. related to the Third-Party Services,
              including the matters related to delays in refunds or no refunds,
              of the fares and charges paid to Service Providers.
            </li>
            <li>
              When you use any Third-Party Services, such as making reservations
              at clubs, you agree to adhere to the rules and conditions set down
              by such Service Provider. For example, you agree that you will
              adhere with any club rules related to dress code, usage of
              facilities etc. Further you agree to respect the property of such
              Service Provider and will be solely liable for any loss or damages
              you may cause. In the event you fail to adhere to such terms,
              MobiCom reserves the right to immediately discontinue your access
              to the Platform and take such other action as it may deem
              necessary.
            </li>
            <li>
              When you use any Third Party Services at a private club such as
              requesting access, booking a venue for a private event or
              participating in any promotions, you must carry your home club
              membership card or club identity card during your visit to the
              other club in order to establish your identity. If the host club
              has reason to believe that there is a mis-match or
              misrepresentation of the identity of the member, they have
              complete discretion to reject permission to you to avail of the
              Third Party Services booked and take such other action as they may
              be deemed necessary. MobiCom shall not be responsible for any loss
              caused to you in such instance and is not liable to make any
              refunds. Further, MobiCom reserves the right to immediately
              discontinue your access to the Platform and take such other action
              as it may deem necessary.
            </li>
            <li>
              You agree that MobiCom is not responsible and shall not be held
              liable for any failure of the Service Provider to make available
              any Third-Party Services, for whatever reason or cause.&nbsp;
            </li>
          </ol>
          <p>
            <strong>Plan, Fee and Terms&nbsp;</strong>
          </p>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                There are no fees or charges applicable for licensing or
                registering yourself on the Platform, as a User or to request
                access to your home club’s reciprocal network of private clubs.
                However, if you were to place a request for access to any
                Private Club within the nation, outside of your home club’s
                reciprocal network on {checkTheme() ? "PlatinumClubNet" : " PrivateClubWorld"} through the Platform, you
                will have to subscribe to an annual subscription plan (
              </span>
              <strong>Plan)</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                and liable to pay a non-refundable subscription fee{" "}
              </span>
              <strong>(Fee)</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                of $250 valid for 365 days from the date of subscription, as
                under:&nbsp;
              </span>
              <span style={{ textDecoration: "underline" }}>
                Privilege plan, Fee USD 250
              </span>{" "}
              <span style={{ fontWeight: 400 }}>
                – Unlimited request for access and unlimited confirmed
                bookings&nbsp;
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              Confirmed Bookings mean your request for access confirmed by the
              Club as an accepted booking at their end. Clubs have the sole
              discretion to confirm or decline your request for access based as
              per their preference and criteria for consideration. If you have
              requested access to multiple clubs, it shall be your
              responsibility to confirm acceptance of one or more of those
              confirmed bookings as limitations of the subscribed plan if any,
              will apply. If you or the club cancel a confirmed booking, you
              will be entitled to make another booking as eligible within your
              Plan.
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              Your subscription plan will be renewed automatically at the end of
              the subscription period, unless cancelled by you prior to the
              renewal date. Unused confirmed bookings if any are not
              transferable to the next subscription plan period. You cannot
              combine multiple subscription plans at any given time.
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              Your request for access will not be processed by MobiCom unless
              you have subscribed to one of the Plan prior to availing of the
              service. However, if you were to avail the services of any Club on
              {checkTheme() ? "PlatinumClubNet" : " PrivateClubWorld"} with a booking made using the Platform, it will
              be deemed that you have also accepted the Privileged Plan and the
              Fee applicable as per that Plan will be charged by MobiCom and
              your credit card will be processed accordingly.
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              MobiCom reserves the right to change the Fee at any time and will
              provide you with adequate notice of any Fee changes before they
              become effective. Such Fee changes will not affect any bookings
              made prior to the effective date of the Fee change.
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              Any amendments or cancellations, including processing of refunds (
              <em>
                <span style={{ fontWeight: 400 }}>if any</span>
              </em>
              <span style={{ fontWeight: 400 }}>) shall be as per </span>
              <span style={{ fontWeight: 400 }}>Cancellation Policy</span>
              <span style={{ fontWeight: 400 }}>.</span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              Any amendments or cancellations to Third-Party Services, including
              processing of refunds (if any) shall be as per the policies of the
              respective Service Provider, and MobiCom shall not be responsible
              for the same. Amendments and cancellations may also be subject to
              either MobiCom’s cancellation policy or the said Service
              Provider’s cancellation policy, as may be intimated to you prior
              to availing the Third-Party Service. If applicable and subject to
              the Service Provider’s policies, you may receive alternate options
              for provision of such Third-Party Services that may be available,
              and in such instance, any payments made by you may be adjusted
              towards such alternative services.
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              Any payments you make for availing of the Services, or Third-Party
              Services shall be routed through an authorised payment gateway
              solution. You confirm that you are the authorised holder of the
              credit card or the original account holder used in the
              transactions you make. MobiCom will facilitate a seamless and
              secure transfer of your browsing from the Platform to the Payment
              Gateway. All transactions executed through such payment gateway
              are subject to the payment gateway’s terms and conditions, and any
              financial information provided and secured are subject to their
              respective privacy policy; MobiCom bears no responsibility for the
              same.
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              You acknowledge that you will make all payments for Third Party
              Services availed via the Platform only through the Platform and
              will not make any direct payments to Service Providers, unless
              explicitly agreed upon by MobiCom. Users should not take any
              action until they have received a confirmation of the User’s
              transaction.
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              The total price payable will be displayed to you before making
              payment. Users are hereby informed that in case of a booking or
              transaction request being rejected by a Service Provider (i.e. not
              confirmed by the applicable Service Provider), any amounts
              collected by MobiCom for such booking will be refunded to the
              User’s Account used for making the payments. As a general rule, we
              will only remit to the Service Provider any applicable amount due
              once we receive confirmation of a booking from the Service
              Provider.
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              You acknowledge that payments for Third Party Services may be in
              the local currency of the Service Provider. As an international
              customer, you may incur transaction charges, foreign exchange
              conversion charges etc. on the purchase of such Third-Party
              Services. We recommend you consult the exact nature and amount of
              charges with your bank before proceeding for payment. Please note
              that MobiCom cannot control any fees that may be charged to User
              by his/her/its bank related to such a transaction and MobiCom
              disclaims all liability in this regard.
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              MobiCom reserves the right to refuse to process transactions by
              Users with a prior history of questionable charges at the sole
              discretion of MobiCom, including without limitation breach of any
              agreements with MobiCom, or breach/violation of any Law or any
              charges imposed by any Banks.
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              MobiCom may do such checks as it deems fit before approving the
              receipt of a User’s commitment to pay the transaction price. As a
              result of such check if MobiCom is not satisfied with the
              creditability of the User or genuineness of the transaction /
              transaction price, it will have the right at its sole discretion
              to reject the commitment to pay transaction price and impose such
              other conditions as it may deem fit. We are a facilitator for
              payment from User to the Service Provider and our obligation to
              pay the Service Provider is conditional upon successful receipt of
              the associated payments from User. MobiCom does not guarantee
              payments to Service Providers for amounts that have not been
              successfully received by us from Users.
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              MobiCom may delay notifying confirmation of any payments received,
              or confirmation to provide any Services/Third Party Services, in
              the event that MobiCom, in its sole discretion, deems the actions
              or inactions of a User to be suspicious or not bona fide. MobiCom
              shall be entitled to take such actions as it may deem fit in such
              a situation, including but not limited to issuing instructions to
              hold payment of a transaction price (or part thereof), or
              informing relevant law enforcement officials of the same.&nbsp;
            </li>
          </ol>
          <p>
            <strong>Usage of Platform</strong>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              You agree, undertake and confirm that your use of the Platform
              shall be strictly governed by the following binding principles:
            </span>
          </p>
          <ol>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                You shall not host, display, upload, modify, publish, transmit,
                update or share any information which:
              </span>
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li style={{ fontWeight: 400, textAlign: "justify" }}>
                  <span style={{ fontWeight: 400 }}>
                    infringes upon or violates any third party's rights
                    including, but not limited to, intellectual property rights,
                    right to privacy (including without limitation unauthorized
                    disclosure of a person's name, email address, passport or
                    other identity card details, physical address or phone
                    number) or rights of publicity or involve the sale of
                    counterfeit or stolen products. Any information that is not
                    your intellectual property will identify the holder of
                    intellectual property rights in such material and shall be
                    done so with the prior permission of such holder;
                  </span>
                </li>
                <li style={{ fontWeight: 400, textAlign: "justify" }}>
                  <span style={{ fontWeight: 400 }}>
                    is misleading in any way, grossly harmful, harassing,
                    blasphemous, defamatory, obscene, pornographic, paedophilic,
                    libellous, invasive of another's privacy, hateful, or
                    racially, ethnically objectionable, disparaging, relating or
                    encouraging money laundering, or otherwise unlawful in any
                    manner whatever; or unlawfully threatening or unlawfully
                    harassing as construed under applicable Law from time to
                    time;
                  </span>
                </li>
                <li style={{ fontWeight: 400, textAlign: "justify" }}>
                  <span style={{ fontWeight: 400 }}>
                    involves the transmission of 'junk mail', 'chain letters',
                    or unsolicited mass mailing or 'spamming';
                  </span>
                </li>
                <li style={{ fontWeight: 400, textAlign: "justify" }}>
                  <span style={{ fontWeight: 400 }}>
                    promotes illegal activities or conduct that is abusive,
                    threatening, obscene, defamatory or libellous;
                  </span>
                </li>
                <li style={{ fontWeight: 400, textAlign: "justify" }}>
                  <span style={{ fontWeight: 400 }}>
                    promotes an illegal or unauthorized copy of another person's
                    copyrighted work, such as providing pirated computer
                    programs or links to them, providing information to
                    circumvent manufacture-installed copy-protect devices, or
                    providing pirated music or links to pirated music files, or
                    provides instructional information about illegal activities
                    such as making or buying illegal weapons or providing or
                    creating computer viruses;
                  </span>
                </li>
                <li style={{ fontWeight: 400, textAlign: "justify" }}>
                  <span style={{ fontWeight: 400 }}>
                    contains restricted or password-only access pages, or hidden
                    pages or images (those not linked to or from another
                    accessible page);
                  </span>
                </li>
                <li style={{ fontWeight: 400, textAlign: "justify" }}>
                  <span style={{ fontWeight: 400 }}>
                    provides material that exploits people in a sexual, violent
                    or otherwise inappropriate manner or solicits personal
                    information from anyone;
                  </span>
                </li>
                <li style={{ fontWeight: 400, textAlign: "justify" }}>
                  <span style={{ fontWeight: 400 }}>
                    tries to gain unauthorized access or exceeds the scope of
                    authorized access to the Platform or to profiles,
                    communities, account information, bulletins, or other areas
                    of the Platform or solicits passwords or personal
                    identifying information for commercial or unlawful purposes
                    from other Users;
                  </span>
                </li>
                <li style={{ fontWeight: 400, textAlign: "justify" }}>
                  <span style={{ fontWeight: 400 }}>
                    engages in commercial activities and/or sales without our
                    prior written consent such as contests, sweepstakes, barter,
                    advertising and pyramid schemes, or the buying or selling of
                    'virtual' products related to the Platform;&nbsp;
                  </span>
                </li>
                <li style={{ fontWeight: 400, textAlign: "justify" }}>
                  <span style={{ fontWeight: 400 }}>
                    interferes with the Platform or with another User's use and
                    enjoyment of the Platform;
                  </span>
                </li>
                <li style={{ fontWeight: 400, textAlign: "justify" }}>
                  <span style={{ fontWeight: 400 }}>
                    refers to any website or URL that, in our sole discretion,
                    contains material that is inappropriate for the Platform or
                    contains content that would be prohibited or violates the
                    letter or spirit of these Terms;
                  </span>
                </li>
                <li style={{ fontWeight: 400, textAlign: "justify" }}>
                  <span style={{ fontWeight: 400 }}>
                    deceives or misleads the addressee/ users about the origin
                    of such messages or communicates any information which is
                    grossly offensive or menacing in nature;
                  </span>
                </li>
                <li style={{ fontWeight: 400, textAlign: "justify" }}>
                  <span style={{ fontWeight: 400 }}>
                    impersonate another person;
                  </span>
                </li>
                <li style={{ fontWeight: 400, textAlign: "justify" }}>
                  <span style={{ fontWeight: 400 }}>
                    contains software viruses or any other computer code, files
                    or programs designed to interrupt, destroy or limit the
                    functionality of any computer resource; or contains any
                    Trojan horses, worms, time bombs, cancelbots, easter eggs or
                    other computer programming routines that may damage,
                    detrimentally interfere with, diminish value of,
                    surreptitiously intercept or expropriate any system, data or
                    personal information;
                  </span>
                </li>
                <li style={{ fontWeight: 400, textAlign: "justify" }}>
                  <span style={{ fontWeight: 400 }}>
                    shall directly or indirectly, offer, attempt to offer, trade
                    or attempt to trade in any item, the dealing of which is
                    prohibited or restricted in any manner under the provisions
                    of any applicable Law;
                  </span>
                </li>
                <li style={{ fontWeight: 400, textAlign: "justify" }}>
                  <span style={{ fontWeight: 400 }}>
                    shall create liability for us or cause us to lose (in whole
                    or in part) any business or services; or
                  </span>
                </li>
                <li style={{ fontWeight: 400, textAlign: "justify" }}>
                  <span style={{ fontWeight: 400 }}>
                    violates any Law for the time being in force;
                  </span>
                </li>
              </ol>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                You acknowledge that we are the owner of all intellectual
                property rights in any material which we have created or
                commissioned a third party to create. You shall not use any
                program, algorithm or methodology, or any similar or equivalent
                manual process, to copy or in any way reproduce or circumvent
                the navigational structure or presentation of the content on the
                Platform, to obtain or attempt to obtain any data or information
                about the Platform or the content through any means other than
                that which has been purposely made available through the
                Platform. You shall not copy, translate, disassemble, decompile,
                create or attempt to create the source code from the object
                code, or reverse engineer our intellectual property. You will
                not do or cause or permit anything which may damage or endanger
                our intellectual property or assist others to do so. You will
                not challenge the validity of our intellectual property and
                shall immediately notify us of any suspected infringement of the
                intellectual property. "MobiCom", {checkTheme() ? "PlatinumClubNet" : " PrivateClubWorld"}, the
                MobiCom logo, and other marks indicated on the Platform are
                trademarks of MobiCom. Other MobiCom or MobiCom graphics, logos,
                page headers, button icons, scripts, and service names are
                trademarks or trade dress of MobiCom. MobiCom's trademarks and
                trade dress may not be used in connection with any product or
                service that is not MobiCom's, including in any manner that is
                likely to cause confusion amongst Users, or in any manner that
                disparages or discredits MobiCom. All other trademarks not owned
                by MobiCom that appear on the Platform are the property of their
                respective owners.
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              MobiCom has no control on all text, graphics, user interfaces,
              visual interfaces, photographs, trademarks, logos, sounds, music
              and artwork generated by Users. You acknowledge that MobiCom is
              merely an intermediary for the purposes of these Terms.
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                You shall not attempt to gain unauthorized access to any portion
                or feature of the Platform, or any other systems or networks
                connected to the Platform, by hacking, password 'mining' or any
                other illegitimate means.
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                You shall not probe, scan or test the vulnerability of the
                Platform or any network connected to the Platform nor breach the
                security or authentication measures on the Platform, or any
                network connected to the Platform. You may not reverse look-up,
                trace or seek to trace any information on any other User of or
                visitor to the Platform, or exploit the Platform or any Service
                or information made available or offered by or through the
                Platform, in any way where the purpose is to reveal any
                information, including but not limited to personal
                identification or information, other than your own information,
                as provided for by the Platform.
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                You shall not make any negative, denigrating or defamatory
                statement(s) or comment(s) about us or the brand name or domain
                name used by us or otherwise engage in any conduct or action
                that might tarnish the image or reputation, of MobiCom, the
                Users, Service Providers or the Platform or otherwise tarnish or
                dilute any MobiCom’s trade or service marks, trade name and/or
                goodwill associated with such trade or service marks, trade name
                as may be owned or used by us.&nbsp;
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                You shall not establish any direct engagement with the Clubs or
                Third-Party Service Providers outside our Platform with regard
                to the Services and Third-Party Services. If you were to do so,
                you will be liable for penalties determined by MobiCom. All the
                communications or payments or any other transactions for a
                booking of any Service, shall be done only through the MobiCom
                Platform.
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                You shall at all times ensure full compliance with applicable
                Law. A reference to any applicable Laws or to any legislation,
                or to any provision of applicable Laws or of any legislation
                includes a reference to such applicable Laws or legislation as
                amended, modified or re-enacted from time to time unless the
                context otherwise requires.
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                In order to facilitate interaction amongst all Users and to
                avail of Services by MobiCom and Third-Party Services, the
                Platform may allow you access to information with regard to
                other Users, including contact information. You agree that you
                will use such information only for (a) communications in
                relation to usage of the Platform and the Services / Third Party
                Services, which are not unsolicited commercial messages; (b) in
                accordance with the usage expressly permitted by such User; and
                (c) in accordance with applicable Law. In all such instances,
                you agree that you shall provide such Users the opportunity to
                remove themselves from your database and a chance to review,
                modify, delete etc., (if required) such information that you may
                have collected about them at all times. In addition, under no
                circumstances, can you disclose personally identifiable
                information about another User to any third party without the
                express consent of such User. You acknowledge that you are
                solely responsible for any unauthorised use of the same. You
                will not communicate with a User without their express consent
                after adequate disclosure.&nbsp;
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                We reserve the right, but have no obligation, to monitor the
                materials uploaded, posted or published on the Platform. MobiCom
                shall have the right to remove or edit any content that in our
                sole discretion violates, or is alleged to violate, any
                applicable Law or either the spirit or letter of these Terms.
                Notwithstanding this right, you remain solely responsible for
                the content you post on the Platform and in your private
                messages. In no event shall MobiCom assume or have any
                responsibility or liability for any content posted or for any
                claims, damages or losses resulting from any content on the
                Platform.&nbsp;
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Your correspondence or business dealings with, or participation
                in promotions of, advertisers found on or through the Platform,
                including payment and delivery of related products or services,
                and any other terms, conditions, warranties or representations
                associated with such dealings, are solely between you and such
                advertiser. We shall not be responsible or liable for any loss
                or damage of any sort incurred as the result of any such
                dealings or as the result of the presence of such advertisers on
                the Platform.
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                It is possible that other Users (including unauthorized users or
                'hackers') may post or transmit offensive or obscene materials
                on the Platform and that you may be involuntarily exposed to
                such offensive and obscene materials. It also is possible for
                others to obtain personal information about you due to your use
                of the Platform, and that the recipient may use such information
                to harass or injure you. We do not approve of such unauthorized
                usage, but you acknowledge and agree that we are not
                responsible, to the extent permitted by applicable Law, for any
                such actions, or for the safety and security of any personal
                information that you publicly disclose or share with others on
                the Platform.&nbsp;
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Except as expressly provided in these Terms, no part of the
                Platform may be copied, reproduced, republished, uploaded,
                posted, publicly displayed, encoded, translated, transmitted or
                distributed in any way (including 'mirroring') to any other
                computer, server, application, website or other medium for
                publication or distribution or for any commercial enterprise,
                without MobiCom’s express prior written consent.
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                You may use information purposely made available on the Platform
                for downloading, if any, provided that you (1) do not remove any
                proprietary notice language in all copies of such documents, (2)
                use such information only for such purposes for which they have
                been made available and in accordance with the terms upon which
                they have been made available, (3) make no modifications to any
                such information, and (4) do not make any additional
                representations or warranties relating to such documents.
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                MobiCom may but is not obliged to mediate or resolve any dispute
                between you, or between you and the Service Providers.
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                MobiCom does not make any representation or warranty with regard
                to its Users and Service Providers including their solvency,
                legal title, creditworthiness, identity, etc. You agree that you
                shall independently verify the bona fides of any club members,
                private clubs and Service Providers that you choose to deal with
                on the Platform and use your best judgment in that behalf.
                Notwithstanding its reasonable efforts in that behalf, MobiCom
                has no responsibility to verify any information provided by club
                members, private clubs and Service Providers which is made
                available on the Platform.&nbsp;
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                MobiCom is not responsible for the non-availability of the
                Platform and you acknowledge that MobiCom has the right to
                change or discontinue any functionality of the Platform at any
                time, without notice. You further acknowledge that MobiCom may
                add or modify the procedures, modes, processes, terms and
                conditions at any time. You agree that MobiCom shall not be
                liable to you or to any third party for any modification,
                suspension or discontinuance of any aspect of the Platform.
              </span>
            </li>
          </ol>
          <p>
            <strong>Privacy</strong>
          </p>
          <ol>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                We view protection of your privacy as a very important
                principle. We understand clearly that you and your information
                are one of our most important assets. We store and process your
                information in accordance with applicable Law and our{" "}
              </span>
              <span style={{ fontWeight: 400 }}>Privacy Policy</span>
              <strong>.</strong>
              <span style={{ fontWeight: 400 }}>
                {" "}
                You agree and acknowledge that you have read, understood and
                agreed to the terms and conditions of our Privacy Policy.
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                When you use any Third-Party Services, Service Providers may
                store or use your information in accordance with their data
                privacy principles. You acknowledge that MobiCom is not
                responsible if such Service Providers or their affiliates use
                your information or contact you from time to time.
              </span>
            </li>
          </ol>
          <p>
            <strong>Disclaimer of Warranties and Liability</strong>
          </p>
          <ol>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                The Platform, the Services and any and all materials provided by
                us, including but not limited to those provided though the
                Platform, are provided on 'as is' and 'as available' basis
                without any representation or warranties, express or implied
                except otherwise specified in writing. To the extent permitted
                by applicable Law, MobiCom provides no warranty as to the
                accuracy, completeness, reliability etc., of any contents of
                Platform, and assumes no liability in this regard.
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Without limiting the generality of the forgoing paragraph,
                MobiCom does not warrant that the Platform shall be made
                available to you; that the Platform and its contents (whether
                those published by MobiCom, by a User or Service Provider) are
                complete, true, accurate or non-misleading; or that information,
                content, materials, product (including software) or services
                included on or otherwise made available to you through the
                Platform, its servers or electronic communication sent from us
                are free of viruses or other harmful components.
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Nothing on the Platform constitutes, or is meant to constitute,
                advice of any kind. You rely on the same entirely at your own
                risk.&nbsp;
              </span>
            </li>
          </ol>
          <p>
            <strong>Compliance with Laws</strong>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              You agree and acknowledge that you are in compliance with and
              shall comply with all applicable Laws.
            </span>
          </p>
          <p>
            <strong>Indemnity</strong>
          </p>
          <ol>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                You shall indemnify, defend and hold harmless MobiCom, its
                affiliates, subsidiaries, group companies (as applicable) and
                their respective officers, directors, agents, and employees,
                from any claim or demand, or actions including reasonable
                attorneys' fees, made by any third party or penalty imposed or
                loss or damage incurred by us due to or arising out of or in
                relation to (a) your usage of the Platform,; (b) availing of
                Services and/or Third Party Services; (c) breach of the Terms
                and/or our Privacy Policy and/or any other policy of MobiCom; or
                (d) your violation of any applicable Law, rules or regulations
                or the rights (including infringement of intellectual property
                rights) of a third party.
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                You release MobiCom, its affiliates, subsidiaries, group
                companies (as applicable) and their respective officers,
                directors, agents, and employees, from any cost, damage,
                liability or other consequence of any of the actions of other
                Users (including club members and private clubs), Service
                Providers, and MobiCom and specifically waive any claims that
                you may have in this behalf under any applicable Law.
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                To the extent permissible by applicable Law, in no event shall
                MobiCom, its affiliates, subsidiaries, group companies (as
                applicable) and their respective officers, directors, agents,
                and employees be liable for any special, incidental, indirect,
                lost profits, incidental or consequential damages of any kind in
                connection with these Terms or usage of the Platform, including
                those resulting from loss of use, data or profits, whether or
                not foreseeable, even if you have been informed in advance of
                the possibility of such damages or based on any theory of
                liability, including breach of contract or warranty, negligence
                or other tortious action, or any other claim. The limitation on
                liability as contained in this clause applies to anything
                related to the Platform and its content, Terms, Privacy Policy,
                Services, Third Party Services; breach of warranty, guarantee,
                or condition; any claims by Users or Service Providers; strict
                liability, negligence, or other tort, to the extent permitted by
                applicable Law.&nbsp;
              </span>
            </li>
            <li style={{ fontWeight: 400, textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                The maximum liability on part of MobiCom arising under any
                circumstances, in respect of any Services availed, shall be
                limited up to a maximum of the refund of amount received from
                the User for providing the Services less any cancellation,
                refund or other charges, as may be applicable. In no event shall
                MobiCom be liable for any kind of refunds/returns of
                charges/fees etc., paid to a Service Provider.
              </span>
            </li>
          </ol>
          <p>
            <strong>Applicable Law</strong>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontWeight: 400 }}>
              The Terms shall be governed by and interpreted and construed in
              accordance with the laws of the state of Delaware, United States
              of America. The courts of Delaware have exclusive jurisdiction to
              settle any dispute or claim that arises out of or in connection
              with these Terms or the usage of the Platform.
            </span>
          </p>
          <p>
            <strong>Jurisdictional Issues</strong>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontWeight: 400 }}>
              MobiCom make no representation that any Service or Third-Party
              Service is appropriate or available for use in any location. In
              the event you choose to avail of any Service or Third-Party
              Service, you agree that you do so on your own initiative and risk
              and will comply with any applicable Law. MobiCom is not
              responsible for ensuring the legality of the same.&nbsp;
            </span>
          </p>
          <p>
            <strong>Changes</strong>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontWeight: 400 }}>
              We reserve the right, at our sole discretion, to change, modify,
              add or remove portions of these Terms, at any time without any
              prior written notice to you. It is your responsibility to review
              these Terms periodically for updates / changes. Your continued use
              of the Platform following the posting of changes will mean that
              you accept and agree to the revisions. We will post any changes on
              this page and, if the changes are significant, we will provide a
              more prominent notice (including, for certain services, email
              notification of changes). We will also keep prior versions of the
              Terms in an archive for your review.&nbsp;
            </span>
          </p>
          <p>
            <strong>Severability</strong>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontWeight: 400 }}>
              If any provision of these Terms is determined to be invalid or
              unenforceable in whole or in part, such invalidity or
              unenforceability shall attach only to such provision or part of
              such provision and the remaining part of such provision and all
              other provisions of these Terms shall continue to be in full force
              and effect.
            </span>
          </p>
          <p>
            <strong>Consequences of breach of Terms</strong>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontWeight: 400 }}>
              If you or your affiliates violate any of the Terms, or any terms
              and conditions laid down by Service Providers, notwithstanding
              other recourses available to us under applicable Law, we reserve
              the right to (a) suspend or terminate your account with immediate
              effect; (b) permanently block access to the Platform; and (c)
              report to Law Enforcement or Appropriate Authorities. We shall not
              be liable for any loss, damage, or cost that you may incur due to
              the same. During such suspension of your account, MobiCom reserves
              the right to discontinue the provision of any agreed upon Services
              and take such other action as it may deem necessary. Any Third
              Party Services may be provided at the discretion of such Service
              Provider.&nbsp;
            </span>
          </p>
          <p>
            <strong>Contact Us</strong>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              For any legal clarifications or communications regarding the
              Platform, please contact us at
            </span>
            <span style={{ fontWeight: 400 }}>
              {" "}
              {checkTheme() ?

                <a href="mailto:support@platinumclubnet.com">
                  support@platinumclubnet.com
                </a>
                : <a href="mailto:support@privateclubworld.com">
                  support@privateclubworld.com
                </a>
              }
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontWeight: 400 }}>
              In the event you find the actions of another User or Service
              Provider to be offensive, harmful, inconsistent, inaccurate,
              deceptive, or otherwise in violation of the Terms, please contact
              us at{" "}
              {checkTheme() ?

                <a href="mailto:support@platinumclubnet.com">
                  support@platinumclubnet.com
                </a>
                : <a href="mailto:support@privateclubworld.com">
                  support@privateclubworld.com
                </a>
              }
            </span>
          </p>
          <p style={{ textAlign: "justify" }}>
            <span style={{ fontWeight: 400 }}>
              We shall remove any objectionable content from the Platform only
              upon receipt of such written request and verification of the same.
              In any case, MobiCom shall not be liable to for any direct or
              indirect losses, damages, costs or expenses arising in relation to
              the same.
            </span>
          </p>
          <p>&nbsp;</p>
        </div>
      </Container>
    </section>

  )
}

export default TermsCondtion
