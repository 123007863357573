import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FaLongArrowAltLeft, FaRegEyeSlash } from 'react-icons/fa';
import { HOME, LOGIN } from '../../constants/pathname';
import eye from '../../assets/images/login/eye.svg';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { changePassword, resetPassword } from '../../api/actions/auth';
import toast from 'react-hot-toast';

const ChangePassword = () => {
    const [showPass, setShowPass]=useState(false)
    const [showPass1, setShowPass1]=useState(false)
    const [showPass2, setShowPass2]=useState(false)
     const [show, setShow]=useState(false)
    const {state} =useLocation()
    const unmask=() => {
        setShowPass(!showPass)
    }

    const unmask1=() => {
        setShowPass1(!showPass1)
    }
    const unmask2=() => {
        setShowPass2(!showPass2)
    }
    const navigate=useNavigate()

    const formik=useFormik({
        initialValues: {
            new_password: '',
            confirm_password: '',
            old_password:''
         
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            old_password:Yup.string().required('Old Password is required'),
            new_password: Yup.string()
                .required('New password is required')
                .min(8, 'Password must be at least 8 characters long'), 
            confirm_password: Yup.string()
                .required('Confirm password is required')
                .oneOf([Yup.ref('new_password'), null], 'Passwords must match'), 

        }),
        onSubmit: (values) => {
 
            changePassword(values)((res)=>{
                if(res?.status_code===200){
                    navigate(LOGIN)
                    formik.resetForm()
                    toast.success('Password reset successfully, Please login with new password')
                   
                }else{
                 
                    toast.error(res?.response?.data?.message)
                }
            })
        }

    })
  return (
    <section className='reset-pswd-page'>
            <Row style={{ width: "100%" }}>
                <Col lg={6}> </Col>
                <Col lg={6}>
                    <div className='formd-section magh'>
                        <div className='formd-main'>
                            <div className='heading heak'>
                                <h1 className='haed'>Create a new password</h1>
                            </div>
                            <div className='form-content col-md-10'>
                                <Form onSubmit={formik.handleSubmit}>
                                <div className='pswd-main'>
                                        <Form.Group className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label >
                                                Old Password
                                            </Form.Label>
                                            <Form.Control
                                                type={showPass2? 'text':"password"}
                                                name='old_password'
                                                value={formik.values?.old_password}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={formik.touched.old_password&&formik.errors.old_password}
                                            />
                                            {formik.touched.old_password&&formik.errors.old_password&&(
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.old_password}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                        <div className='img-wrapper' onClick={unmask2}>
                                            {showPass2?<img src={eye} alt='eye' />: <FaRegEyeSlash size={18} color='#DCC4AB' />}
                                        </div>
                                    </div>
                                    <div className='pswd-main'>
                                        <Form.Group className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label >
                                                New Password
                                            </Form.Label>
                                            <Form.Control
                                                type={showPass? 'text':"password"}
                                                name='new_password'
                                                value={formik.values?.new_password}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={formik.touched.new_password&&formik.errors.new_password}
                                            />
                                            {formik.touched.new_password&&formik.errors.new_password&&(
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.new_password}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                        <div className='img-wrapper' onClick={unmask}>
                                            {showPass? <img src={eye} alt='eye' />:<FaRegEyeSlash size={18} color='#DCC4AB' />}
                                        </div>
                                    </div>
                                    <div className='pswd-main'>
                                        <Form.Group className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label >
                                                Confirm Password
                                            </Form.Label>
                                            <Form.Control
                                                type={showPass1? 'text':"password"}
                                                name='confirm_password'
                                                value={formik.values?.confirm_password}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                isInvalid={formik.touched.confirm_password&&formik.errors.confirm_password}
                                            />
                                            {formik.touched.confirm_password&&formik.errors.confirm_password&&(
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.confirm_password}
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                        <div className='img-wrapper' onClick={unmask1}>
                                            {showPass1?<img src={eye} alt='eye' /> :<FaRegEyeSlash size={18} color='#DCC4AB' />}
                                        </div>
                                    </div>
                                    <div className='sign'>
                                        <Button className='sign-button w-100' type='submit'>Reset Password  </Button>
                                    </div>
                                </Form>
                                <div className='Active'>
                                    <button onClick={() => navigate(LOGIN)} className='backto'> <span > <FaLongArrowAltLeft size={15} className='left' /></span>Back to login</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </section>
  )
}

export default ChangePassword
