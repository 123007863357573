import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { getDashboardOverview } from "../../api/actions/dashboard";
import { BOOKINGS } from "../../constants/pathname";

const Dashboard=() => {
    const [dashboardOverview, setDashboardOverview] = useState({});
    const navigate = useNavigate();

    // Getting dashboard overview
    useEffect(() =>{
        const userInfo = JSON.parse(localStorage.getItem("user_info"));

        if(!userInfo?.is_superuser){
            navigate(BOOKINGS);
            return;
        }
        let props = {
            club:  userInfo?.club_details?.[0]?.club_id || '',
            country: userInfo?.club_details?.[0]?.country || ''
        }

        getDashboardOverview(props)((response) =>{
            if(response.status){
                console.log(response.data);
                setDashboardOverview(response?.data || {});
            }
        })
    },[]);
 
    return (
        <div className='dashboard-section'>
            <div className='main'>
                <div className="widget-tiles-grid">
                    {/* Number of Members */}
                    <div className="item">
                        <div className="top row">
                            <div className="col-md-9">
                                <div className="count">{dashboardOverview?.number_of_members || 0}</div>
                                <div className="title">Number of Members</div>
                            </div>
                            <div className="col-md-3 text-end">
                                <Link to={"/"}>View</Link>
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="c-item">
                                <div>{dashboardOverview?.active_members || 0}</div>
                                <div>Active Members</div>
                            </div>
                            <div className="c-item">
                                <div>{dashboardOverview?.invited_members || 0}</div>
                                <div>Invited Members</div>
                            </div>
                        </div>
                    </div>
                    {/* Active Members */}
                    <div className="item">
                        <div className="top row">
                            <div className="col-md-9">
                                <div className="count">{dashboardOverview?.active_members || 0}</div>
                                <div className="title">Active Members</div>
                            </div>
                            <div className="col-md-3 text-end">
                                <Link to={"/"}>View</Link>
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="c-item">
                                <div>{dashboardOverview?.complimentary_access || 0}</div>
                                <div>Complimentary Access</div>
                            </div>
                            <div className="c-item">
                                <div>{dashboardOverview?.paid_access || 0}</div>
                                <div>Paid Access</div>
                            </div>
                        </div>
                    </div>
                    {/* Revenue */}
                    <div className="item">
                        <div className="top row">
                            <div className="col-md-9">
                                <div className="count">10</div>
                                <div className="title">Revenue</div>
                            </div>
                            <div className="col-md-3 text-end">
                                <Link to={"/"}>View</Link>
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="c-item">
                                <div>20</div>
                                <div>Complimentary Access</div>
                            </div>
                            <div className="c-item">
                                <div>20</div>
                                <div>Paid Access</div>
                            </div>
                        </div>
                    </div>
                    {/* Number of Subscribers */}
                    <div className="item">
                        <div className="top row">
                            <div className="col-md-9">
                                <div className="count">{dashboardOverview?.number_of_subscribers || 0}</div>
                                <div className="title">Number of Subscribers</div>
                            </div>
                            <div className="col-md-3 text-end">
                                <Link to={"/"}>View</Link>
                            </div>
                        </div>
                        <div className="bottom justify-content-between">
                            <div className="c-item">
                                <div>{dashboardOverview?.new_subscribers_count || 0}</div>
                                <div>New</div>
                            </div>
                            <div className="c-item">
                                <div>0</div>
                                <div>Lapsed</div>
                            </div>
                            <div className="c-item">
                                <div>0</div>
                                <div>Renewed</div>
                            </div>
                            <div className="c-item">
                                <div>{dashboardOverview?.expired_subscribers_count || 0}</div>
                                <div>Expired</div>
                            </div>
                        </div>
                    </div>
                    {/* Requests By Members */}
                    <div className="item">
                        <div className="top row">
                            <div className="col-md-9">
                                <div className="count">10</div>
                                <div className="title">Requests By Members</div>
                            </div>
                            <div className="col-md-3 text-end">
                                <Link to={"/"}>View</Link>
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="c-item">
                                <div>20</div>
                                <div>Open</div>
                            </div>
                            <div className="c-item">
                                <div>20</div>
                                <div>Accepted</div>
                            </div>
                            <div className="c-item">
                                <div>20</div>
                                <div>Completed</div>
                            </div>
                            <div className="c-item">
                                <div>20</div>
                                <div>Denied</div>
                            </div>
                            <div className="c-item">
                                <div>20</div>
                                <div>Abandoned</div>
                            </div>
                        </div>
                    </div>
                    {/* Member Connections */}
                    <div className="item">
                        <div className="top row">
                            <div className="col-md-9">
                                <div className="count">10</div>
                                <div className="title">Member Connections</div>
                            </div>
                            <div className="col-md-3 text-end">
                                <Link to={"/"}>View</Link>
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="c-item">
                                <div>20</div>
                                <div>Completed</div>
                            </div>
                            <div className="c-item">
                                <div>20</div>
                                <div>Invited</div>
                            </div>
                            <div className="c-item">
                                <div>20</div>
                                <div>Withdrawn</div>
                            </div>
                        </div>
                    </div>
                    {/* Member Bookings */}
                    <div className="item">
                        <div className="top row">
                            <div className="col-md-9">
                                <div className="count">80</div>
                                <div className="title">Member Bookings</div>
                            </div>
                            <div className="col-md-3 text-end">
                                <Link to={"/"}>View</Link>
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="c-item">
                                <div>20</div>
                                <div>Quarter 1</div>
                            </div>
                            <div className="c-item">
                                <div>20</div>
                                <div>Quarter 2</div>
                            </div>
                            <div className="c-item">
                                <div>20</div>
                                <div>Quarter 3</div>
                            </div>
                            <div className="c-item">
                                <div>20</div>
                                <div>Quarter 4</div>
                            </div>
                        </div>
                    </div>
                    {/* Host Guests */}
                    <div className="item">
                        <div className="top row">
                            <div className="col-md-9">
                                <div className="count">10</div>
                                <div className="title">Host Guests</div>
                            </div>
                            <div className="col-md-3 text-end">
                                <Link to={"/"}>View</Link>
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="c-item">
                                <div>20</div>
                                <div>Received</div>
                            </div>
                            <div className="c-item">
                                <div>20</div>
                                <div>Accepted</div>
                            </div>
                        </div>
                    </div>
                    {/* Number of Guests */}
                    <div className="item">
                        <div className="top row">
                            <div className="col-md-9">
                                <div className="count">10</div>
                                <div className="title">Number of Guests</div>
                            </div>
                            <div className="col-md-3 text-end">
                                <Link to={"/"}>View</Link>
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="c-item">
                                <div>5</div>
                                <div>By Member</div>
                            </div>
                            <div className="c-item">
                                <div>20</div>
                                <div>By Request</div>
                            </div>
                        </div>
                    </div>
                    {/* Member Referrals */}
                    <div className="item">
                        <div className="top row">
                            <div className="col-md-9">
                                <div className="count">10</div>
                                <div className="title">Member Referrals</div>
                            </div>
                            <div className="col-md-3 text-end">
                                <Link to={"/"}>View</Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Dashboard