import React from "react";

import { FaCheckCircle } from "react-icons/fa";
import { LOGIN } from "../../../constants/pathname";

const Confirmation = () => {
  return (
    <div >
      <h3  style={{ fontSize: "20px", fontWeight: "400", lineHeight: "25px" }}>Confirmation</h3>
      <div className="d-flex justify-content-center mt-3">
      <div style={{ width: "100%", textAlign: "center", border: "1px solid #c58f5a", padding: "10px" }}>
     
        <FaCheckCircle size={50} color="#c58f5a" className="mb-3" />
        

        <h4 style={{ fontWeight: "600", color: "#333" }}>Your Registration is Successful!</h4>
        
 
        <p style={{ color: "#666", fontSize: "15px", marginTop: "10px" }}>
          You will shortly receive an email from us once your account has been activated.
          Should you have any queries or require assistance, please write to us at
          <br />
          <a href={LOGIN} style={{ color: "#c58f5a", fontWeight: "600" }}>
          support@privateclubworld.com
          </a>. We would be happy to help!
        </p>
      </div>
    </div>
    </div>
  
  );
};

export default Confirmation;
