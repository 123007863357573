
import React from 'react'
import SpecialSubscribers from '../../components/SpecialSubscribers/SpecialSubscribers'

const SpecialSubscribersPage = () => {

  return (
    <div className='special-subscribers-page'>
        <SpecialSubscribers />
    </div>
  )
}

export default SpecialSubscribersPage