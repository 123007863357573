import React from 'react'
import ViewSurveyQuestioner from "../../components/ViewSurveyQuestioner/ViewSurveyQuestioner"

const ViewSurveyQuestionerPage = () => {

  return (
    <div className='view-survey-questioner-page'>
      <ViewSurveyQuestioner />
    </div>
  )
}

export default ViewSurveyQuestionerPage