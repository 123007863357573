import React from 'react'
import CreateOfferNotification from '../../components/CreateOfferNotification/CreateOfferNotification'

const CreateOfferNotificationPage = () => {

  return (
    <div className='create-offer-notification-page'>
        <CreateOfferNotification />
    </div>
  )
}

export default CreateOfferNotificationPage