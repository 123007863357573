import React from 'react'
import ViewClubsInfo from '../../components/ViewClubsInfo/ViewClubsInfo'

const ViewClubInformationPage = () => {
  return (
    <div className='view-clubs-information-page'>
        <ViewClubsInfo />
    </div>
  )
}

export default ViewClubInformationPage