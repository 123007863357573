import { Form } from 'react-bootstrap'
import { MANAGE_ADMINS } from '../../constants/pathname';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IoIosClose } from "react-icons/io";

const CreateAdmin=() => {
    const navigate=useNavigate();
    const [isFocused, setIsFocused]=useState(false); // State to track focus

    const formik=useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            roles: [],
        },
        validationSchema: Yup.object().shape({
            firstName: Yup.string().required('First Name is required'),
            lastName: Yup.string().required('Last Name is required'),
            email: Yup.string()
                .email('Invalid email format')
                .required('Email is required'),
            roles: Yup.array()
                .min(1, 'At least one role must be selected')
                .required('Roles are required'),
        }),
        validate: (values) => {
            let errors={};

            if (Array.isArray(values?.roles)&&!values?.roles?.length) {
                errors.roles='At least one role must be selected';
            }

            return errors;

        },
        onSubmit: (values) => {
            navigate(MANAGE_ADMINS)
        }
    });

    // Select box style
    const customSelectStyles={
        control: (provided, state) => ({
            ...provided,
            paddingTop: "2px",
            //   height: state?.isFocused ? "auto" : "38px"
        }),
        multiValue: (provided) => ({
            ...provided,
            borderRadius: 5,
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            marginBottom: "2px"
        }),
    };

    // Function to remove role option
    const removeRoleOption = (key) =>{
        let filteredRoles = formik?.values?.roles.filter(role => role.value !== key);

        formik.setFieldValue('roles', filteredRoles);
    }
    return (
        <div className='create-clubs-info-section'>
            <div className='page-details'>
                <div className='club-profile-section'>
                    <Form onSubmit={formik.handleSubmit}>
                        <div className='panel-type-1'>
                            Add Admin
                        </div>
                        <div className='club-form mt-4'>
                            <div>
                                <div className="row">
                                    {/* First Name  */}
                                    <div className="col-md-3 mb-3">
                                        <Form.Group controlId="firstName">
                                            <Form.Label>First Name<span className='required'>*</span></Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='firstName'
                                                placeholder='Enter First Name'
                                                onChange={formik.handleChange}
                                                isInvalid={formik?.touched?.firstName&&formik?.errors?.firstName}
                                            />
                                            {formik?.touched?.firstName&&formik?.errors?.firstName&&
                                                <Form.Control.Feedback type='invalid'>
                                                    {formik?.errors.firstName}
                                                </Form.Control.Feedback>
                                            }
                                        </Form.Group>
                                    </div>
                                    {/* Last Name  */}
                                    <div className="col-md-3 mb-3">
                                        <Form.Group controlId="lastName">
                                            <Form.Label>Last Name<span className='required'>*</span></Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='lastName'
                                                placeholder='Enter Last Name'
                                                onChange={formik.handleChange}
                                                isInvalid={formik?.touched?.lastName&&formik?.errors?.lastName}
                                            />
                                            {formik?.touched?.lastName&&formik?.errors?.lastName&&
                                                <Form.Control.Feedback type='invalid'>
                                                    {formik?.errors?.lastName}
                                                </Form.Control.Feedback>
                                            }
                                        </Form.Group>
                                    </div>
                                    {/* Email  */}
                                    <div className="col-md-3 mb-3">
                                        <Form.Group controlId="email">
                                            <Form.Label>Email<span className='required'>*</span></Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='email'
                                                placeholder='Enter Email'
                                                onChange={formik.handleChange}
                                                isInvalid={formik?.touched?.email&&formik?.errors?.email}
                                            />
                                            {formik?.touched?.email&&formik?.errors?.email&&
                                                <Form.Control.Feedback type='invalid'>
                                                    {formik?.errors?.email}
                                                </Form.Control.Feedback>
                                            }
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='panel-type-1 mt-4'>
                            Access and Permissions
                        </div>
                        {/* Roles */}
                        <div className='row align-items-end'>
                            <div className='item mt-4 col-md-6'>
                                <Form.Group controlId="exampleForm.ControlInput1em5">
                                    <Form.Label>Roles<span className='required'>*</span></Form.Label>
                                    <div className='input-border'>
                                        <Select
                                            className={`roles ${isFocused? 'focused':''}`}
                                            onFocus={() => setIsFocused(true)} // Track focus
                                            onBlur={() => setIsFocused(false)} // Remove focus
                                            styles={customSelectStyles}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 5,
                                                colors: {
                                                    ...theme.colors,
                                                },
                                            })}
                                            placeholder="Select"
                                            options={[
                                                { label: "Global Platinum Club Access", value: "gpct" },
                                                { label: "Curated Member Events", value: "cme" },                                                
                                                { label: "Curated Sponsorship", value: "cme" },                                                
                                                { label: "Current Club Events", value: "cme" },                                                
                                                { label: "Promotions", value: "cme" },                                                
                                                { label: "Payment Process", value: "cme" },                                                
                                                { label: "Manage Members", value: "cme" },                                                
                                            ]}
                                            value={formik?.values?.roles}
                                            onChange={(selectedOption) => formik.setFieldValue("roles", selectedOption)}
                                            isSearchable={false}
                                            isClearable={true}
                                            isMulti
                                        />
                                        {formik?.touched?.roles&&formik?.errors?.roles&&
                                            <div className='error-msg'>{formik?.errors?.roles}</div>
                                        }
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                        {/* Roles list */}
                        <div className='roles-list row'>
                            <div className='col-md-8'>
                                {Array.isArray(formik?.values?.roles)&&formik?.values?.roles?.length>0&&
                                    <ul className='d-flex flex-wrap gap-3 mt-4'>
                                        {formik?.values?.roles?.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    {item?.label}
                                                    <IoIosClose size={22} className='cursor-pointer' onClick={() => removeRoleOption(item?.value)}/>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                }
                            </div>
                        </div>
                        <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-5'>
                            <button className='btn-type-2 cursor-pointer' onClick={() => navigate(MANAGE_ADMINS)}>Cancel</button>
                            <button className='btn-type-1' type="submit" >Submit</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default CreateAdmin