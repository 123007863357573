import React from 'react'
import CreateCuratedSponsorship from '../../components/CreateCuratedSponsorship/CreateCuratedSponsorship'

const CreateCuratedSponsorshipPage = () => {

  return (
    <div className='create-curated-sponsorship-page'>
        <CreateCuratedSponsorship />
    </div>
  )
}

export default CreateCuratedSponsorshipPage