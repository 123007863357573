import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { ReactComponent as Bell } from "../../assets/images/common/bell.svg";
import { useNavigate } from 'react-router-dom';
import { VIEW_BOOKINGS } from '../../constants/pathname';
import { notificationList, readnotification, acceptbookingNotifiaction, rejectBookingNotification } from '../../api/actions/notifications';
import SweetAlert from 'react-bootstrap-sweetalert';
import toast from 'react-hot-toast';
import moment from 'moment/moment';


const ClubNotifications = () => {
    const navigate = useNavigate();
    const [notificationArr, setNotifications] = useState([]);
    const [page, setPage] = useState(1);
    const limit = 15;
    const [totalRows, setTotalRows] = useState(0);
    const [showSweetAlert, setShowSweetAlert] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [actionType, setActionType] = useState(null);

    const [update, setUpdate] = useState(false);

    const getTimeAgo = (dateString) => {
        return moment(dateString).fromNow(); 
    };
    useEffect(() => {

        notificationList({ page, limit })((response) => {
            if (response.status && response.status_code === 200) {
                console.log("Notifications fetched:", response.data.results);
                setNotifications(response.data.results.map(notification => ({
                    ...notification,
                    read: notification.read || false,
                })));
                setTotalRows(response.data.count || 0);
            } else {
                console.error("Failed to fetch notifications:", response);
            }
        });
    }, [page, update]);

    const handleBookingDetail = (id, notificationId, event) => {
        if (!id) return;


        readnotification({ id: notificationId, read: true })((response) => {
            if (response.status && response.status_code === 200) {
                setNotifications(prevnot =>
                    prevnot.map(notification =>
                        notification.id === notificationId ? { ...notification, read: true } : notification
                    )
                );
            } else {
                console.error("Failed to mark notification as read:", response);
            }
        });


        if (event === "Member Verification Request") {
            return;
        }


        navigate(VIEW_BOOKINGS + "/" + id);
    };

    const handleAccept = (notificationId, countryId, memberVerificationId) => {

        setSelectedNotification({ notificationId, countryId, memberVerificationId });
        setActionType('accept');
        setShowSweetAlert(true);
    };

    const handleReject = (notificationId, countryId, memberVerificationId) => {

        setSelectedNotification({ notificationId, countryId, memberVerificationId });
        setActionType('reject');
        setShowSweetAlert(true);
    };

    const confirmAction = () => {
        if (!selectedNotification) return;

        const { notificationId, countryId, memberVerificationId } = selectedNotification;

        if (actionType === 'accept') {

            acceptbookingNotifiaction({
                country_id: countryId,
                id: memberVerificationId,
            })((response) => {
                if (response.status && response.status_code === 200) {
                    toast.success("Successfully Updated")
                    setUpdate(!update)
                } else {
                    toast.error(response?.data?.error || "Something went wrong");
                }
            });
        } else if (actionType === 'reject') {

            rejectBookingNotification({
                country_id: countryId,
                id: memberVerificationId,
            })((response) => {
                if (response.status && response.status_code === 200) {
                    toast.success("Successfully Updated")

                    setUpdate(!update)
                } else {
                    toast.error(response?.data?.error || "Something went wrong");
                }
            });
        }


        setShowSweetAlert(false);
        setSelectedNotification(null);
        setActionType(null);
    };

    return (
        <div className='club-notifications-section'>
            <Container>
                <div className='new-notifications mt-4'>
                    {/* Notifications list */}
                    <div className='items-list mt-3'>
                        {notificationArr.length > 0 ? (
                            notificationArr.map((item) => (
                                <div key={item.id} className='item d-flex justify-content-between mb-4'>
                                    <div
                                        className='icon-content d-flex align-items-start gap-4'
                                        style={{ cursor: item?.booking && item.event !== "Member Verification" ? 'pointer' : 'default' }}
                                        onClick={(e) => {
                                            if (item.event === "Member Verification") {
                                                e.stopPropagation();
                                                return;
                                            }

                                            if (!item?.booking) {
                                                navigate(VIEW_BOOKINGS + "/" + item.id);
                                            } else {
                                                handleBookingDetail(item?.booking, item.id, item.event);
                                            }
                                        }}
                                    >


                                        <div className='icon'>
                                            <Bell />
                                        </div>
                                        <div className='content'>
                                            <div className='top'>
                                                <span style={{ fontWeight: item.read ? 'normal' : '600' }}>
                                                    {item?.title || ""}
                                                </span>

                                                {item.member_verification_confirm && (
                                                    <span className="status-text" style={{ color: 'green', marginLeft: '10px', }}>Confirmed</span>
                                                )}
                                                {item.member_verification_rejected && (
                                                    <span className="status-text" style={{ color: 'red', marginLeft: '10px' }}>Rejected</span>
                                                )}
                                            </div>
                                            <div className='bottom'>
                                                <div className='sub-title' style={{ fontWeight: item.read ? 'normal' : '600' }}>
                                                    {item?.body || ""}
                                                </div>
                                                {/* Display the time ago using Moment.js */}
                                                <div className='time-ago' style={{ fontSize: '12px', color: '#666', marginTop: '5px' }}>
                                                    {getTimeAgo(item.created_date)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {item.event === "Member Verification" && (
                                        <div className='actions d-flex gap-2'>
                                            {!item.member_verification_confirm && !item.member_verification_rejected && (
                                                <>
                                                    <button className='btn-type-1' onClick={() => handleAccept(item.id, item.country, item.member_verification_id)}>
                                                        Accept
                                                    </button>
                                                    <button className='btn-type-2' onClick={() => handleReject(item.id, item.country, item.member_verification_id)}>
                                                        Reject
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : (
                            <p>No notifications found.</p>
                        )}
                    </div>

                    {/* Pagination */}
                    {totalRows > limit && (
                        <nav aria-label="Page navigation example" className="mt-4">
                            <ul className="pagination justify-content-center">
                                <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                                    <button
                                        className="btn btn-type-5"
                                        onClick={() => setPage(page - 1)}
                                        disabled={page === 1}
                                    >
                                        Previous
                                    </button>
                                </li>
                                {[...Array(Math.ceil(totalRows / limit)).keys()].map((pageNumber) => (
                                    <li
                                        key={pageNumber + 1}
                                        className={`page-item ${page === pageNumber + 1 ? 'active' : ''}`}
                                    >
                                        <button
                                            className="btn"
                                            onClick={() => setPage(pageNumber + 1)}
                                        >
                                            {pageNumber + 1}
                                        </button>
                                    </li>
                                ))}
                                <li className={`page-item ${page * limit >= totalRows ? 'disabled' : ''}`}>
                                    <button
                                        className="btn btn-type-5"
                                        onClick={() => setPage(page + 1)}
                                        disabled={page * limit >= totalRows}
                                    >
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    )}
                </div>
            </Container>

            {/* SweetAlert Dialog */}
            {showSweetAlert && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    confirmBtnCssClass="custom-confirm-button-class"
                    cancelBtnCssClass="custom-cancel-button-class"
                    title="Are you sure?"
                    onConfirm={confirmAction}
                    onCancel={() => setShowSweetAlert(false)}
                >
                    {actionType === 'accept'
                        ? 'Do you want to accept this member verification request?'
                        : 'Do you want to reject this member verification request?'}
                </SweetAlert>
            )}
        </div>
    );
};

export default ClubNotifications;