
import DataTable from "react-data-table-component";
import { ReactComponent as AddIcon } from "../../assets/images/common/add-icon.svg";
import { ReactComponent as FilterIcon } from "../../assets/images/common/filter-icon.svg";
import { useNavigate } from 'react-router-dom';
import { CREATE_SOLITAIRE_NETWORK } from '../../constants/pathname';
import { ReactComponent as ViewIcon } from "../../assets/images/common/view-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/common/edit-icon.svg";
import { useEffect, useState } from "react";
// Drawer
import Drawer from '@mui/material/Drawer';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { listSolitareclub } from "../../api/actions/solitareClub";

const SolitaireNetwork=() => {
    const navigate=useNavigate();
    const [showDrawer, setShowDrawer]=useState(false);
  const [data, setData] = useState([{}]);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [limit, setLimit] = useState(25);
  const [search, setSearch] = useState('');
  const [update, setUpdate] = useState(false);
  const [pending, setPending] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [content, setContent] = useState(""); // Content for sweet alert
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [show, setShow] = useState(false);
  const [isSwal, setIsSwal] = useState({ show: false, id: '' });
 
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);

  useEffect(() => { getListData(); }, [page, limit, debouncedSearch, update]);

  const getListData = () => {
    setPending(true);

    if (debouncedSearch) {
      setPage(1);
    }
    listSolitareclub({
      page: debouncedSearch ? 1 : page,
      limit,
      search: debouncedSearch,

    })(
      (response) => {
        if (response && response.status) {
          setData(response?.data?.results);
          setTotalRows(response?.data?.count);
          setPending(false);
        }
      }
    );
  };

    const columns=[
        { name: "Club Name", selector: row => row.clubName, sortable: true, minWidth: "220px" },
        { name: "City", selector: row => row.city, sortable: true, width: "220px" },
        { name: "Country", selector: row => row.country, sortable: true, width: "220px" },
        {
            name: "Actions",
            selector: row =>
                <div className='d-flex align-items-center gap-3'>
                    <div className='view-icon cursor-pointer' onClick={() => navigate(CREATE_SOLITAIRE_NETWORK, { state: { currentPageName: "View Club" } })} title='View'><ViewIcon /></div>
                    <div className='view-icon cursor-pointer' onClick={() => navigate(CREATE_SOLITAIRE_NETWORK, { state: { currentPageName: "Edit Club" } })} title='Edit'><EditIcon /></div>
                </div>,
            width: "150px"
        }
    ];

    // Custom styling for the table
    const customStyles={
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",
                border: "1px solid var(--border-color)",
                overflow: "hidden"
            },
        },
        headCells: {
            style: {
                backgroundColor: "var(--light-blue)",
                justifyContent: 'flex-start !important',
                color: "var(--dark-gray)",
                fontWeight: "400",
                fontSize: "15px",
            },
        },
        rows: {
            style: {
                height: "45px", // Set row height
                justifyContent: "start",
            },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    };

    return (
        <div className='solitaire-network'>
            <Drawer
                anchor={'right'}
                open={showDrawer}
                onClose={() => setShowDrawer(false)}
                className='member-stats-drawer'
                sx={{
                    '& .MuiPaper-root': {
                        padding: '30px 19px',
                        height: "100vh",
                        // overflow: "hidden"
                    },
                    '& .MuiDrawer-paper': {
                        width: '420px'
                    },
                    '& .MuiBox-root': {
                        width: '420px'
                    },
                    '& .MuiList-root': {
                        display: 'flex',
                        columnGap: '10px',
                        width: '90%',
                        margin: 'auto',
                        paddingTop: "25px"
                    },
                    '& .MuiButtonBase-root': {
                        padding: '7px',
                        textAlign: 'center',
                        alignItems: 'center',
                        borderRadius: '10px',
                        backgroundColor: '#F5F5F5',
                    }
                }}
            >
                <div className="drawer-top-box">
                    <div>Filter</div>
                    <button type="button" class="btn-close" aria-label="Close" onClick={() => setShowDrawer(false)}></button>
                </div>
                <div className="drawer-bottom-box mt-4">
                    <Form onSubmit={(e) => e.preventDefault()}>
                        {/* Club Name */}
                        <div className='item'>
                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em5">
                                <Form.Label>Club Name</Form.Label>
                                <div className='input-border'>
                                    <Form.Control
                                        type="text"
                                        className='input-field'
                                        name='clubname'
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        {/* City */}
                        <div className='item'>
                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em5">
                                <Form.Label>City</Form.Label>
                                <div className='input-border'>
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            colors: {
                                                ...theme.colors,
                                            },
                                        })}
                                        placeholder="Select"
                                        options={[{ label: "Whitehouse Station", value: "Whitehouse Station" }]}
                                        isSearchable={false}
                                        isClearable={true}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        {/* Country */}
                        <div className='item'>
                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em5">
                                <Form.Label>Country</Form.Label>
                                <div className='input-border' >
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            colors: {
                                                ...theme.colors,
                                            },
                                        })}
                                        placeholder="Select"
                                        options={[{ label: "India", value: "india" }]}
                                        isSearchable={false}
                                        isClearable={true}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className='submit-box d-flex align-items-end justify-content-end gap-3' style={{ height: "50vh" }}>
                            <button className='btn-type-2 cursor-pointer' >Clear</button>
                            <button className='btn-type-1' type="submit">Submit</button>
                        </div>
                    </Form>
                </div>

            </Drawer>
            <div className='page-details'>
                <div className='top-section d-flex align-items-center justify-content-between'>
                    <div className='left'>List Solitaire Network</div>
                    <div className='right d-flex align-items-center  gap-3'>
                        <div className='filter-box d-flex align-items-center  gap-3 cursor-pointer' onClick={() => setShowDrawer(true)}>
                            <FilterIcon />
                            <span>Filter</span>
                        </div>
                        <div className='add-clubs-info cursor-pointer'
                            onClick={() => navigate(CREATE_SOLITAIRE_NETWORK, { state: { currentPageName: "Add Solitaire Network" } })}
                            title="Create"
                        >
                            <AddIcon />
                        </div>
                    </div>
                </div>

                <div className='table-box mt-3'>
                    <DataTable
                        columns={columns}
                        data={data}
                        customStyles={customStyles}
                        fixedHeader
                        highlightOnHover
                        responsive
                    />
                </div>
            </div>
        </div>
    )
}

export default SolitaireNetwork