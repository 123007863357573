import React from 'react'
import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { OFFER } from '../../constants/pathname';
import Select from "react-select"

const ClubOfferAdd = () => {
    const navigate=useNavigate();

    const customStyles={
        control: (provided, state) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            outline: 'none',
            boxShadow: 'none',
            color: 'var(--medium-gray)',
            fontSize: '15px',
            '&:hover': {
                border: '1px solid var(--light-grayish)', // Prevent hover border color
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        menu: (provided) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            boxShadow: 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused? 'var(--light-grayish)':'white',
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
    };

    const labelStyle = {
        color: '#717171',
        fontSize: '14px',
        '& .MuiTypography-root': {
            color: '#717171',
            fontSize: '14px',
        },
    }
    
    return (
        <div className='create-clubs-info-section create-push-notifications'>
            <div className='page-details'>
                <div className='panel-type-1'>
                    Add Club Offer 
                </div>
                <Form onSubmit={(e) => e.preventDefault()}>
                    <div className='mt-5'>
                        <div className="row flex-column">
                            {/* Select Club */}
                            <div className="col-md-6 mb-4">
                                <Form.Group controlId="clubs">
                                    <Form.Label>Club<span className='required'>*</span></Form.Label>
                                    <Select
                                        placeholder="Select Club"
                                        styles={customStyles}
                                        isClearable
                                        isSearchable
                                    />
                                </Form.Group>
                            </div>
                            {/* Category */}
                            <div className="col-md-6 mb-4">
                                <Form.Group controlId="clubs">
                                    <Form.Label>Category<span className='required'>*</span></Form.Label>
                                    <Select
                                        placeholder="Select a Category"
                                        styles={customStyles}
                                        isClearable
                                        isSearchable
                                    />
                                </Form.Group>
                            </div>
                            {/* Description */}
                            <div className="col-md-6 mb-3">
                                <Form.Group controlId="description">
                                    <Form.Label>Description<span className='required'>*</span></Form.Label>
                                    <Form.Control as="textarea" rows={5} placeholder="Description" name="description" />
                                </Form.Group>
                            </div>
                        </div>
                        <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-5'>
                            <button className='btn-type-5' onClick={() => navigate(OFFER)}>Back</button>
                            <button className='btn-type-1' type="submit" onClick={() => navigate(OFFER)}>Submit</button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default ClubOfferAdd