import { DASHBOARD_OVERVIEW } from "../api";
import axiosInstance from "../axiosinstance";

export const getDashboardOverview = (props) => onResponse => {
    console.log("props", props);
    
    try {
        let BASE_URL = `${DASHBOARD_OVERVIEW}?`;
        
        if(props?.club){
            BASE_URL += `club=${props.club}&`;
        }
        if(props?.country){
            BASE_URL += `country=${props.country}`;
        }
     
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};