import React from 'react'
const capitalizeFirstLetter = (value) => {
  if (!value || typeof value !== "string") return ""; // Ensure it's a string
  return value.replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
};
const Reviews = ({setValue,formik}) => {
  return (
    <div>
    <h3 className="mb-3" style={{ fontSize: "20px", fontWeight: "400", lineHeight: "25px" }}>Review</h3>
    <div
      style={{
        border: "1px solid #c58f5a",
        padding: "30px",
        borderRadius: "8px",
        borderColor: "#c58f5a",
        boxShadow: "none",
        background: "transparent",
      
        fontSize: "18px",
      }}
    >
          <div style={{ marginBottom: "20px" }}>
          <p className='mb-3' style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: "bold", fontSize: "15px" }}>Club Name:</span> {capitalizeFirstLetter(formik?.values?.club_name)}
          </p>
          <p className='mb-3' style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: "bold", fontSize: "15px" }}>Name:</span> {capitalizeFirstLetter(formik?.values?.first_name)} {capitalizeFirstLetter(formik?.values?.last_name)}
          </p>
          <p className='mb-3' style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: "bold", fontSize: "15px" }}>Email:</span> {formik?.values?.email}
          </p>
          <p className='mb-3' style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: "bold", fontSize: "15px" }}>Contact Number:</span> {formik?.values?.contact_number}
          </p>
          <p className='mb-3' style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: "bold", fontSize: "15px" }}>Country:</span> {capitalizeFirstLetter(formik?.values?.country_name)}
          </p>
          <p className='mb-3' style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: "bold", fontSize: "15px" }}>City:</span> {capitalizeFirstLetter(formik?.values?.city)}
          </p>
        
        </div>


      <div style={{ display: "flex", justifyContent: "space-between" }}>
  
        <button
          style={{
            backgroundColor: "#c58f5a",
            color: "white",
            border: "none",
            padding: "10px 20px",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
          }}
          onClick={() => setValue(1)}
        >
          Back
        </button>
        <button
          style={{
            backgroundColor: "#c58f5a",
            color: "white",
            border: "none",
            padding: "10px 20px",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
          }}
          onClick={formik.handleSubmit} 
        >
          Finish
        </button>
      </div>
    </div>
  </div>
  )
}

export default Reviews
