import React, { useEffect, useState } from 'react'
import { ReactComponent as AddIcon } from "../../assets/images/common/add-icon.svg";
import { ReactComponent as ViewIcon } from "../../assets/images/common/view-icon.svg";
import { ReactComponent as ExcelIcon } from "../../assets/images/common/sheets.svg";
import { ReactComponent as EditIcon } from "../../assets/images/common/edit-icon.svg";
import uploadIcon from "../../assets/images/common/upload_icon.png";
import { ReactComponent as CloseIcon } from "../../assets/images/common/close-icon.svg";
import { CLUB_ONBOARDIN, EDIT_CLUB_ONBOARDING, VIEW_CLUINFO } from '../../constants/pathname';
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { bulkUploadClub, getemailsendig, getregistrationLink, listClubProfile } from '../../api/actions/clubProfile';
import { paginationRowsPerPageOptions } from '../../helpers/helper';
import { FaTimes } from 'react-icons/fa';
import { MdFileUpload } from "react-icons/md"
import { RiMailSendLine } from "react-icons/ri";
import BulkUpload from './BulkUpload';
import toast from 'react-hot-toast';
import SweetAlert from 'react-bootstrap-sweetalert';
import { CLUB_ONBOARDING_EXCEL_DOWNLOAD } from '../../api/api';
import { checkTheme } from '../../utils/utils';
import { IoCopyOutline } from "react-icons/io5";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
const ClubOnboardingList = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [isDeleting, setIsDeleting] = useState(false);
    const [limit, setLimit] = useState(25);
    const [search, setSearch] = useState('');
    const [update, setUpdate] = useState(false);
    const [pending, setPending] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showSweetAlert, setShowSweetAlert] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isSwal, setIsSwal] = useState({ show: false, id: '' });
    const [showDrawer, setShowDrawer] = useState(false);
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [searchText, setSearchText] = useState('');
    const handleShowBlkm = () => setShowModal(true);
    const handleCloseBlkm = () => setShowModal(false);
    const [selectedClub, setSelectedClub] = useState(null);
    const [successMessages, setSuccessMessages] = useState([]);
    const [errorMessages, setErrorMessages] = useState([]);
    const [showMessages, setShowMessages] = useState(false);
    const [permissionsList, setPermissionsList] = useState([]);
    useEffect(() => {
        let permissions = JSON.parse(localStorage.getItem("user_info"));

        if (permissions && permissions?.permissions && permissions?.permissions?.length) {
            setPermissionsList(permissions?.permissions);
        }

    }, []);

    const handleExportMemberList = () => {
     
        const myHeaders = new Headers();
        let token = localStorage.getItem('token');

        myHeaders.append(`Authorization`, `Bearer ${token}`);

        setLoading(true);
        const isCheckthemeEnabled = checkTheme();


        const queryParams = `?pcn=${isCheckthemeEnabled}`;

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };


        fetch(`${process.env.REACT_APP_BACKENDURL}${CLUB_ONBOARDING_EXCEL_DOWNLOAD}${queryParams}`, requestOptions)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");

                a.href = url;
                a.download = "Club Details.xls";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                setLoading(false)
            })
            .catch((error) => {console.error(error);  setLoading(false)} );
          
    };
    const customStyles = {
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",
                border: "1px solid var(--border-color)",
                overflow: "hidden"
            },
        },
        headCells: {
            style: {
                backgroundColor: "var(--light-blue)",
                justifyContent: 'flex-start !important',
                color: "var(--dark-gray)",
                fontWeight: "400",
                fontSize: "15px",
            },
        },
        rows: {
            style: {
                height: "45px", // Set row height
                justifyContent: "start",
            },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    };
    const handleSearch = (value) => {
        setSearch(value);
        setSearchText(value);
    }

    useEffect(() => {
        if (search.length >= 2) {
            const debounceTimer = setTimeout(() => {
                setDebouncedSearch(search);
            }, 500);

            return () => clearTimeout(debounceTimer);
        } else {
            setDebouncedSearch('');
        }
    }, [search]);

    const clearSearch = () => {
        setSearch('');
        setSearchText('');
        setDebouncedSearch('');
        setUpdate(!update);
    };

    useEffect(() => { getListData(); }, [page, limit, debouncedSearch, update]);

    const getListData = () => {
        setPending(true);

        if (debouncedSearch) {
            setPage(1);
        }
        listClubProfile({
            page: debouncedSearch ? 1 : page,
            limit,
            search: debouncedSearch,
        })(
            (response) => {
                if (response && response.status) {
                    setData(response?.data?.results);
                    setTotalRows(response?.data?.count);
                    setPending(false);
                }
            }
        );
    };
    const handleSendMailClick = (id, country) => {
        setSelectedClub({ id, country });
        setShowSweetAlert(true);
    };

    const confirmSendMail = () => {
        if (!selectedClub) return;

        setShowSweetAlert(false);

        getemailsendig(selectedClub)((response) => {
            if (response) {
                toast.success("Mail sent successfully!");
                setUpdate((prev) => !prev);
            } else {
                toast.error("Failed to send mail.");
            }
        });
    };
    const handlecopylink = (id, country) => {
        getregistrationLink({ id, country })((response) => {
            if (response?.data) {
                navigator.clipboard.writeText(response.data)
                    .then(() => {
                        toast.success("Link copied successfully!");
                    })
                    .catch(() => {
                        toast.error("Failed to copy link.");
                    });
            } else {
                toast.error("Failed to retrieve link.");
            }
        });
    };


    const columns = [
        {
            name: "Sl.No",
            key: "name",
            selector: row => row?.name,
            cell: (row, index) =>
                <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.name}>
                    {(limit * (page - 1)) + (index + 1)}
                </div>,
            sortable: true,
            grow: 2,
            reorder: true,
            width: '110px'
        },
        { name: "Club Name", selector: row => <div title={row.club_name}>{row.club_name}</div>, sortable: true, minWidth: "250px" },
        {
            name: "Email",
            selector: row =>
                row.admin_email ? (
                    <a href={`mailto:${row.admin_email}`} target="_blank" rel="noopener noreferrer">
                        {row.admin_email}
                    </a>
                ) : '-',
            minWidth: "150px"
        },

        { name: "Name", selector: row => row.first_name, minWidth: "200px" },
        // {
        //     name: "Account Status",
        //     cell: (row) => (
        //       <span
        //         style={{
        //           color: row.status === "KYC Approved"
        //             ? "red"
        //             : row.status === "Active"
        //             ? "green"
        //             : row.status === "KYC Pending"
        //             ? "orange"
        //             : "inherit",
        //           fontWeight: "bold"
        //         }}
        //       >
        //         {row.status}
        //       </span>
        //     ),
        //     minWidth: "150px"
        //   },




        {
            name: "Actions",
            selector: row =>
                <div className='d-flex align-items-center gap-3'>
                    {/* <div className='view-icon cursor-pointer'  onClick={() => navigate(VIEW_CLUINFO + row?.id, {
                            state: { country: row?.country }
                        })} title='View'><ViewIcon /></div> */}
                    <div
                        className='view-icon cursor-pointer'
                        onClick={() => navigate(EDIT_CLUB_ONBOARDING + row?.id, {
                            state: { country: row?.country }
                        })}
                        title='Edit'
                    >
                        <EditIcon />
                    </div>
                    {!row.on_boarded &&  (
                        <>

                            <div
                                className='view-icon cursor-pointer'
                                onClick={() => handleSendMailClick(row?.id, row?.country)}
                                title='Send Email'
                            >
                                <RiMailSendLine />
                            </div>
                            <div
                                className='veiw-copy cursor-pointer'
                                onClick={() => handlecopylink(row?.id, row?.country)}
                                title='Copy Register Link'
                            >
                                <IoCopyOutline />
                            </div>
                        </>

                    )}

                </div>,
            width: "150px"
        }
    ];
    const handleFileUpload = async (file) => {
        if (!file) return;

        let userInfo = JSON.parse(localStorage.getItem('user_info'));

        const formData = new FormData();
        formData.append("csv_file", file);

        try {
            bulkUploadClub(formData)((res) => {
                console.log(res);

                if (res?.status_code === 200 || res?.status_code === 201) {
                  
                    setSuccessMessages([]);
                    setErrorMessages([]);

                    if (res?.message?.success && res?.message?.success.length) {
                        setSuccessMessages(res.message.success);
                    }

                   
                    if (res?.message?.error && res?.message?.error.length) {
                        setErrorMessages(res.message.error);
                    }

                    setShowMessages(true);
                    setUpdate(!update);

                    if (!res?.message?.error || res?.message?.error.length === 0) {
                        toast.success("Successfully uploaded");
                    }
                } else {
                 
                    const errorMessage = res?.response?.data?.errors?.non_field_errors?.join(" ") || "Failed to upload file.";
                    toast.error(errorMessage);
                    console.error("Failed to upload file:", errorMessage);
                }
            });
        } catch (error) {
            console.error("Error uploading file:", error);
            toast.error("Error uploading file. Please try again.");
        }
    };

    const clearMessages = () => {
        setSuccessMessages([]);
        setErrorMessages([]);
        setShowMessages(false);
    };

    return (
        <div className='manage-admins-section'>


            {showSweetAlert && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"



                    cancelBtnText="Cancel"
                    confirmBtnCssClass="custom-confirm-button-class"
                    cancelBtnCssClass="custom-cancel-button-class"
                    title="Are you sure?"
                    onConfirm={confirmSendMail}
                    onCancel={() => setShowSweetAlert(false)}
                >
                    Do you want to send an email to this club?
                </SweetAlert>
            )}
            <BulkUpload showModal={showModal} setShowModal={setShowModal} handleCloseBlkm={handleCloseBlkm} onUpload={handleFileUpload} />
            <div className='page-details'>
                <div className='top-section d-flex align-items-center justify-content-between'>
                    <div className='left'>
                        Onboarding Clubs
                    </div>
                    <div className='right d-flex align-items-center gap-3'>
                        <div className='search-box'>
                            <input
                                type='text'
                                placeholder='Search '
                                className='form-control'
                                value={searchText}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                            {
                                searchText && (
                                    <FaTimes className='clear-icon' onClick={clearSearch} />
                                )}
                        </div>
                        <div className='filter-box d-flex align-items-center gap-2 cursor-pointer'
                            onClick={() => handleShowBlkm()}
                        >
                            <img src={uploadIcon} alt='' style={{ width: "16px" }} />
                            Bulk Upload
                        </div>
                        <button disabled={loading} className='download-excel cursor-pointer' title='Export Club Details' onClick={() => handleExportMemberList()}>
                            <ExcelIcon />
                        </button>

                        <div className='add-clubs-info cursor-pointer' onClick={() => navigate(CLUB_ONBOARDIN, { state: { currentPageName: "Add Club" } })} title="Add Club">
                            <AddIcon />
                        </div>

                    </div>

                </div>
                {showMessages && (
                    <div className="message-container mt-3" style={{ padding: "0px 20px" }}>
                        {/* Success Message */}
                        {successMessages.length > 0 && (
                            <div className="success-message p-3 rounded mb-2 border border-success position-relative">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="text-success" style={{ fontWeight: "500", fontSize: "18px" }}>Success</span>
                                    <AiOutlineCheckCircle className="text-success fs-4" /> {/* Green Success Icon */}
                                </div>
                                {successMessages.map((msg, index) => (
                                    <div key={`success-${index}`} className="mb-1">{msg}</div>
                                ))}
                                {successMessages.length > 0 && successMessages[0].includes("rows inserted successfully") && (
                                    <div className="club-details-list">
                                        {data.slice(0, parseInt(successMessages[0].split(' ')[0])).map((club, idx) => (
                                            <div key={`club-${idx}`} className="mb-1">
                                                {/* Club details inserted successfully for {club.club_name} */}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}

                        {/* Error Message */}
                        {errorMessages.length > 0 && (
                            <div className="error-message p-3 rounded mb-2 border border-danger position-relative">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="text-danger" style={{ fontWeight: "500", fontSize: "18px" }}>Error</span>
                                    <AiOutlineCloseCircle className="text-danger fs-4" />
                                </div>
                                {errorMessages.map((msg, index) => (
                                    <div key={`error-${index}`} className="mb-1">{msg}</div>
                                ))}
                            </div>
                        )}

                        {/* Close Button */}
                        <div className="text-end">
                            <button
                                className="btn btn-sm btn-outline-secondary"
                                onClick={clearMessages}
                            >
                                <IoClose className="fs-5" />
                            </button>
                        </div>
                    </div>
                )}
                <div className='table-box mt-3'>
                    <DataTable
                        columns={columns}
                        data={data}
                        customStyles={customStyles}
                        fixedHeader
                        highlightOnHover
                        responsive
                        pagination
                        paginationServer
                        paginationPerPage={limit}
                        progressPending={pending}
                        onChangeRowsPerPage={setLimit}
                        paginationTotalRows={totalRows}
                        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                        onChangePage={setPage}

                    />
                </div>

            </div>

        </div>
    )
}

export default ClubOnboardingList
