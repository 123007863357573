import DataTable from "react-data-table-component";
import { Link, useNavigate } from 'react-router-dom';
import { FormControlLabel, RadioGroup, styled, Switch } from "@mui/material";
import {ReactComponent as ViewIcon} from "../../assets/images/common/view-icon.svg";
import {ReactComponent as AddIcon} from "../../assets/images/common/add-icon.svg";
import {ReactComponent as EditIcon} from "../../assets/images/common/edit-icon.svg";
import {ReactComponent as CloseIcon} from "../../assets/images/common/close-icon.svg";
import { CREATE_BRANDS, EDIT_BRANDS } from '../../constants/pathname';
import SweetAlertModal from '../SweetAlert/SweetAlertModal';
import { useEffect, useState } from "react";
import { paginationRowsPerPageOptions } from "../../helpers/helper";
import { deleteBrand, getBrandStatusChanging, listBrands } from "../../api/actions/brand";
import toast from "react-hot-toast";
import { FaTimes } from "react-icons/fa";


const Brands = () => {
    // Sweet alert
    const [data, setData] = useState([{}]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [isDeleting, setIsDeleting] = useState(false);
    const [limit, setLimit] = useState(25);
    const [search, setSearch] = useState('');
    const [update, setUpdate] = useState(false);
    const [pending, setPending] = useState(false);
    const navigate = useNavigate();
    const [showSweetAlert, setShowSweetAlert] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isSwal, setIsSwal] = useState({ show: false, id: '' });
    const [showDrawer, setShowDrawer] = useState(false);
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [searchText, setSearchText] = useState('');

 const handleSearch = (value) => {
    setSearch(value);
    setSearchText(value);
  }
  useEffect(() => {
    if (search.length >= 2) {
      const debounceTimer = setTimeout(() => {
        setDebouncedSearch(search);
      }, 500);

      return () => clearTimeout(debounceTimer);
    } else {
      setDebouncedSearch('');
    }
  }, [search]);
  const clearSearch = () => {
    setSearch('');
    setSearchText('');
    setDebouncedSearch('');
    setUpdate(!update);
  };

    const handleDelete = (id) => {
        if (isDeleting) return;
        setIsDeleting(true);
        deleteBrand(id)((response) => {
            if (response && response?.status) {
                toast.success('Successfully deleted');
                getListData();
            } else {
                toast.error('Deletion failed');
            }
            setIsDeleting(false);
            setIsSwal({ show: false, id: null });
        });
    };
    const CustomSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 39,
        height: 20,
        padding: 0,
        marginLeft: 5,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            // margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: 'var(--sandy-brown)',
                    opacity: 1,
                    border: 0,
                    ...theme.applyStyles('dark', {
                        backgroundColor: '#2ECA45',
                    }),
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.grey[100],
                ...theme.applyStyles('dark', {
                    color: theme.palette.grey[600],
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                backgroundColor: 'var(--sandy-brown)',
                opacity: 0.7,
                ...theme.applyStyles('dark', {
                    opacity: 0.3,
                }),
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 20,
            height: 20,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#E9E9EA',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
            ...theme.applyStyles('dark', {
                backgroundColor: '#39393D',
            }),
        },
    }));

    const toggleActive = (id) => {
        getBrandStatusChanging({ id: id })((response) => {
            if (response?.status_code === 200) {
                toast.success(response?.message);
                const updatedData = data.map(item => {
                    if (item.id === id) {
                        return { ...item, brand_status: response?.status };
                    }
                    return item;
                });
                setUpdate(!update);
            } else {
                toast.error(response?.message || "Something went wrong");
            }
        });
    };
    // Define columns for the table
    const columns = [
        {
            name: "Sl.No",
            key: "name",
            selector: row => row?.name,
            cell: (row, index) =>
                <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.name}>
                    {(limit * (page - 1)) + (index + 1)}
                </div>,
            sortable: true,
            grow: 2,
            reorder: true,
            width: '110px'
        },
        { name: "Name", selector: row => row.brand_name, minWidth: "200px" , sortable: true },
        {
            name: "Status",
            selector: row => (
                <CustomSwitch defaultChecked={row.brand_status} onChange={() => toggleActive(row.id)} />
            ),
            minWidth: "200px",
        },
        { name: "Country", selector: row => row.country_name, minWidth: "200px" , sortable: true },
        { name: "City", selector: row => row.city_name, width: "250px", sortable: true },
     
        {
            name: "Actions",
            selector: row => (
                <div className="d-flex align-items-center gap-3">
                    {/* <div className="view-icon cursor-pointer" title="View" onClick={() => navigate(CREATE_BRANDS, {state : {currentPageName: "View Brand" }})}>
                        <ViewIcon />
                    </div> */}
                    <Link to={`${EDIT_BRANDS}${row?.id}`}>
                    <div className="view-icon cursor-pointer" title="Edit" >
                        <EditIcon />
                    </div> 
                    </Link>
                    
                    <div className="view-icon remove-icon cursor-pointer" onClick={() => setIsSwal({ show: true, id: row?.id })} title="Delete">
                        <CloseIcon />
                    </div>
                </div>
            ),
            width: "200px",
            sortable: false,
        },
    ];

   
    // Custom styling for the table
    const customStyles = {
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",        
                border: "1px solid var(--border-color)",
                overflow: "hidden"
            },
    },
        headCells: {
        style: {
            backgroundColor: "var(--light-blue)",
            justifyContent: 'flex-start !important',
            color: "var(--dark-gray)",
            fontWeight: "400",
            fontSize: "15px",
        },
        },
        rows: {
        style: {
            height: "45px", // Set row height
            justifyContent: "start",
        },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    }; 


      useEffect(() => { getListData(); }, [page, limit, debouncedSearch,update]);
    
        const getListData = () => {
            setPending(true);
            
            if (debouncedSearch) {
                setPage(1);
            }
            listBrands({ page:debouncedSearch?1:page,
                 limit,
                 search:debouncedSearch ,
                 })(
                (response) => {
                    if (response && response.status) {
                        setData(response?.data?.results);
                        setTotalRows(response?.data?.count);
                        setPending(false);
                    }
                }
            );
        };

  return (
    <div className='member-stats-section brands'> 
        <SweetAlertModal showSweetAlert={isSwal.show} setShowSweetAlert={(show) => {
                if (show) handleDelete(isSwal.id);
                else setIsSwal({ ...isSwal, show: false });
            }} />

        <div className='page-details'>
            <div className='top-section d-flex align-items-center justify-content-between'>
                <div className='left'>List Brands</div>
                <div className='right d-flex align-items-center  gap-3'>
                {/* <div className='add-clubs-info cursor-pointer' onClick={() => navigate(CREATE_BRANDS, {state : {currentPageName: "Add Brand" }})} title="Create">
                     <span style={{color:'white'}} className="ms-2">Add Category</span>
                    </div> */}
                          <div className='search-box'>
                                    <input
                                      type='text'
                                      placeholder='Search ...'
                                      className='form-control'
                                      value={searchText}
                                      onChange={(e) => handleSearch(e.target.value)}
                                    />
                    
                                    {searchText && (
                                      <FaTimes className='clear-icon' onClick={clearSearch} />
                                    )}
                                  </div>
                    <div className='add-clubs-info cursor-pointer' onClick={() => navigate(CREATE_BRANDS, {state : {currentPageName: "Add Brand" }})} title="Create">
                        <AddIcon />
                    </div>
                  
                </div>
            </div>

            <div className='table-box mt-3'>
                <DataTable
                   columns={columns}
                   data={data}
                   customStyles={customStyles}
                   fixedHeader
                   highlightOnHover
                   responsive
                   pagination
                   paginationServer
                   paginationPerPage={limit}
                   progressPending={pending}
                   onChangeRowsPerPage={setLimit}
                   paginationTotalRows={totalRows}
                   paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                   onChangePage={setPage}
                />
            </div>
        </div>
    </div>
  )
}

export default Brands