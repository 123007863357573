import { 
    CREATE_BLACKOUT_DATES, 
    DELETE_BLACKOUT_DATES, 
    GET_BLACKOUT_DATE_DETAIL, 
    LIST_BLACKOUT_DATES, 
    UPDATE_BLACKOUT_DATE_STATUS 
} from "../api";
import axiosInstance from "../axiosinstance";

export const createBlackoutDates = (props) => (onResponse) => {
    try {
        axiosInstance.post(CREATE_BLACKOUT_DATES, props.data)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });
    } catch (error) {}
};

export const deleteBlackoutDates = (id) => (onResponse) => {
    try {
        axiosInstance.delete(DELETE_BLACKOUT_DATES, {data: {id: [id]}})
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });
    } catch (error) {}
};

export const getBlackoutDateDetail = (id) => (onResponse) => {
    try {
        let BASE_URL = `${GET_BLACKOUT_DATE_DETAIL}?`;

        if (id) {
            BASE_URL += `id=${id}&`;
        }

        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });
    } catch (error) {}
};

export const listBlackoutDates = (props) => (onResponse) => {
    try {
        let BASE_URL = `${LIST_BLACKOUT_DATES}?`;

        if (props?.page) {
            BASE_URL += `page=${props.page}&`;
        }
        if (props?.limit) {
            BASE_URL += `limit=${props.limit}&`;
        }
        if (props?.search) {
            BASE_URL += `search=${props.search}&`;
        }
        if (props?.sort_by) {
            BASE_URL += `sort_by=${props.sort_by}&`;
        }
        if (props?.order) {
            BASE_URL += `order=${props.order}&`;
        }

        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });
    } catch (error) {}
};

export const updateBlackoutDateStatus = (props) => (onResponse) => {
    try {
        axiosInstance.post(UPDATE_BLACKOUT_DATE_STATUS, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });
    } catch (error) {}
};
