
import { Outlet, useLocation } from 'react-router-dom'
import Header from './Header'
// import Sidebar from './Sidebar'
import Sidebar from './ProSidebar'
import { useEffect, useState } from 'react'
import { checkTheme } from '../../utils/utils'
import PlayStore from '../../assets/images/common/anroid-image.png'
import AppStore from '../../assets/images/common/iphone-image.png'

const Layout = () => {  
  const location = useLocation();  
  const [minimizeSidebar, setMinimizeSidebar] = useState(false);  
  const [userInfo, setUserInfo] = useState(null);
  const [showAd, setShowAd] = useState(true);

  const handleClose = () => {
    setShowAd(false);
  };

  useEffect(() => {
      let userInfoObj = JSON.parse(localStorage.getItem("user_info"));
      setUserInfo(userInfoObj);
  
    }, []);

  return (
    <div>
        <Header minimizeSidebar={minimizeSidebar} setMinimizeSidebar={setMinimizeSidebar} />
        <div>
          {userInfo?.is_superuser ? "" :
            (
              showAd && (
                <div className="app_advertisement">
                  <div className="d-flex justify-content-end">
                    <span className="close-btn_1" onClick={handleClose}>
                      ✖
                    </span>
                  </div>
                  <div className="app-title">
                    {checkTheme() ? "PlatinumClubNet" : "PrivateClubWorld"} Admin app is
                    available in stores. Find the link below!
                  </div>

                  <div className="app-icons">
                    <a
                      href={
                        checkTheme()
                          ? "https://play.google.com/store/apps/details?id=com.mobicom.pcnadmin&hl=en"
                          : "https://play.google.com/store/apps/details?id=com.mobicom.pcwadmin"
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={PlayStore} alt="Google Play" />
                    </a>
                    <a
                      href={
                        checkTheme()
                          ? "https://apps.apple.com/us/app/platinumclubnet-admin/id1472985391"
                          : "https://apps.apple.com/us/app/oneclubnet-admin/id1472986008"
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={AppStore} alt="App Store" />
                    </a>
                  </div>

                  {/* Uncomment if you need this */}
                  {/* <div
            className="dismiss-btn"
            onClick={handleClose}
          >
            Do Not show this message again
          </div> */}
                </div>
              )
            )
          }
        </div>
        <div className='d-flex ml-none mr-none ml-none'>
            <div className={ minimizeSidebar ? 'w-4 sidebar-minimized pl-none' : 'w-15 pl-none'}>
                <Sidebar minimizeSidebar={minimizeSidebar}/>                
            </div>
            <div className={minimizeSidebar ? 'w-96' : 'w-84'} style={{background: "var(--soft-gray)"}}>              
              <div className={`outlet ${location && location?.pathname === "/" ? "admin-dashboard" : ""}
                   ${minimizeSidebar ? 'pl-30 mt-30' : ''}`}>
                  <Outlet />
              </div>
            </div>
        </div>

    </div>
  )
}

export default Layout