import React, { useEffect, useState } from "react";
import pcn from "../../assets/images/loader/PCNLOADER.gif";
import pcw from "../../assets/images/loader/PCWLOADER.gif";
import { checkTheme } from "../../utils/utils";

const LoaderComponent = () => {
  const [theme, setTheme] = useState(checkTheme() ? "platinum-club-net" : "");
  
  useEffect(() => {
    // if (window) {
    //   window.scrollTo(0, 0);
    // }
    const currentTheme = document.documentElement.getAttribute("theme");
    setTheme(currentTheme);
  }, []);    
  
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: "10",
          top: '0',
          left: '0',
          width: '100%',
          minHeight: '400px'

        }}
      >
        {theme === "platinum-club-net" ? (
          <img src={pcn} alt="" style={{height:"100px"}} />
        ) : (
          <img src={pcw} alt="" style={{height:"100px"}}/>
        )}
      </div>
    </>
  );
};

export default LoaderComponent;
