import { ADD_CLUB_FACILITY, CLUB_FACILITY_DELETE, CLUB_FACILITY_DETAILS, CLUB_FACILITY_LIST} from "../api";
import axiosInstance from "../axiosinstance";

export const createClubfacitiltyCategory = (props) => onResponse => {
    try {
        axiosInstance.post(ADD_CLUB_FACILITY, props)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};

export const listClubFaciltiy = (props) => onResponse => {
    try {
        let BASE_URL = CLUB_FACILITY_LIST + '?'
        if (props?.page) {
            BASE_URL += 'page=' + props.page + '&'
        }
        if (props?.location) {
            BASE_URL += 'location=' + props.location + '&'
        }
        if (props?.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props?.club) {
            BASE_URL += 'club=' + props.club + '&'
        }
        if (props?.country) {
            BASE_URL += 'country=' + props.country + '&'
        }
        if (props?.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if (props?.category) {
            BASE_URL += 'category=' + props.category + '&'
        }
        if (props?.club_type) {
            BASE_URL += 'club_type=' + props.club_type + '&'
        }
        if(props?.sort_by){
            BASE_URL+='sort_by='+props.sort_by+'&'
        }
        if(props?.order){
            BASE_URL+='order='+props.order+'&'
        }
       
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {
        console.log("Listing club facility error", error);
        
    }

};

export const deletefacilitycategory = (id) => onResponse => {
    try {

        axiosInstance.delete(CLUB_FACILITY_DELETE, { data: { id: [id] } })

            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getfaciltyDetails = (id) => onResponse => {
    try {
        axiosInstance.get(CLUB_FACILITY_DETAILS + '?id=' + id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};


