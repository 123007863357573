import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { CLUB_TYPE_LIST } from '../../constants/pathname'
import { createClubtype, getclubtypeDetails } from '../../api/actions/clubtype'
import toast from 'react-hot-toast'
import * as Yup from 'yup';
const AddClubType = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object({
        club_type: Yup.string().required('Required'),

    });
    const formik = useFormik({
        initialValues: {
            club_type: '',
        },
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            setLoading(true);
            createClubtype(values)((response) => {
                console.log(response);

                if (response.status_code === 200 || response.status_code === 201) {
                    toast.success(id ? "Club type Updated Successfully" : "Club type Created Successfully!");
                    navigate(CLUB_TYPE_LIST);
                }
                else {
                    toast.error(response?.response?.data?.errors?.non_field_errors[0] || "Failed to create club type");
                }
                setLoading(false);
            });
        },
    });

    useEffect(() => {
        if (id) {
            getclubtypeDetails({ id })((response) => {
                if (response?.status) {
                    formik.setValues({
                        ...formik.values,
                        id: response?.data?.id,
                        club_type: response?.data?.club_type,
                    })
                }
            })
        }
    }, [])

    return (
        <div className='create-clubs-profile-page create-clubs-info-section'>
            <div className='page-details'>
                <div className='panel-type-1'>
                    {id ? "Edit Club Type" : " Add Club Type"}

                </div>
                <Form onSubmit={formik.handleSubmit}>
                    <div className='club-form mt-5'>
                        <div className='mt-5'>
                            <div className='row'>
                                <div className="col-xl-3 col-md-4 mb-3">
                                    <Form.Group controlId="clubName">
                                        <Form.Label>Club Type Name<span className='required'>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            className='dotted-input-half'
                                            placeholder="Club Name"
                                            name='club_type'
                                            value={formik.values?.club_type}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                formik.setFieldValue(
                                                    'club_type',
                                                    value.charAt(0).toUpperCase() + value.slice(1)
                                                );
                                            }}
                                            isInvalid={formik.touched.club_type && !!formik.errors.club_type}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik.touched.club_type && formik.errors.club_type}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-5'>
                                    <button className='btn-type-2 cursor-pointer' type='button' onClick={() => navigate(CLUB_TYPE_LIST)}>Cancel</button>
                                    <button className='btn-type-1' type="submit"  disabled={loading}>{id ? "Update" : "Save"}</button>



                                </div>


                            </div>

                        </div>

                    </div>

                </Form>

            </div>

        </div>
    )
}

export default AddClubType
