
import React, { useEffect, useState } from 'react'
import DataTable from "react-data-table-component";
import { ReactComponent as EditIcon } from "../../assets/images/common/edit-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/common/close-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/common/add-icon.svg";

import { useNavigate } from 'react-router-dom';
import { ADD_GROUP, EDIT_GROUP } from '../../constants/pathname';
import SweetAlertModal from '../SweetAlert/SweetAlertModal';
import { useFormik } from 'formik';
import { destroygroup, retriveGroups } from '../../api/actions/usermanagement';
import { paginationRowsPerPageOptions } from '../../helpers/helper';
import { FaTimes } from 'react-icons/fa';
import toast from 'react-hot-toast';

const Groups=() => {
  // Sweet alert
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [isDeleting, setIsDeleting] = useState(false);
  const [limit, setLimit] = useState(25);
  const [search, setSearch] = useState('');
  const [update, setUpdate] = useState(false);
  const [pending, setPending] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isSwal, setIsSwal] = useState({ show: false, id: '' });
  const [showDrawer, setShowDrawer] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [searchText, setSearchText] = useState('');
  // Permissions
  const [permissionsList, setPermissionsList] = useState([]);
  // User information
  const [userInfo, setUserInfo]=useState(null);  

  // Setting permission list
  useEffect(() => {
      let userInfoObj = JSON.parse(localStorage.getItem("user_info"));
      setUserInfo(userInfoObj);
      if(userInfoObj && userInfoObj?.permissions && userInfoObj?.permissions?.length){
          setPermissionsList(userInfoObj?.permissions);
      }

  }, []);

  const handleSearch = (value) => {
    setSearch(value);
    setSearchText(value);
  }

  useEffect(() => {
    if (search.length >= 2) {
      const debounceTimer = setTimeout(() => {
        setDebouncedSearch(search);
      }, 500);

      return () => clearTimeout(debounceTimer);
    } else {
      setDebouncedSearch('');
    }
  }, [search]);

  const clearSearch = () => {
    setSearch('');
    setSearchText('');
    setDebouncedSearch('');
    setUpdate(!update);
  };

  const formik=useFormik({
      initialValues: {
          countryName: ""
      },
      onSubmit: (values, resetForm) => {

      }
  });

  useEffect(() => { getListData(); }, [page, limit, debouncedSearch, update]);

  const getListData = () => {
    setPending(true);

    if (debouncedSearch) {
      setPage(1);
    }
    retriveGroups({
      page: debouncedSearch ? 1 : page,
      limit,
      search: debouncedSearch,
      
    })(
      (response) => {
        if (response && response.status) {
          setData(response?.data?.results);
          setTotalRows(response?.data?.count);
          setPending(false);
        }
      }
    );
  };

  const handleDelete = (id) => {
    if (isDeleting) return;
    setIsDeleting(true);
    destroygroup(id)((response) => {
        if (response && response?.status) {
            toast.success('Successfully deleted');
            getListData();
        } else {
            toast.error('Deletion failed');
        }
        setIsDeleting(false);
        setIsSwal({ show: false, id: null });
    });
  };

  const columns=[
      {
          name: "Sl.No",
          key: "name",
          selector: row => row?.name,
          cell: (row, index) =>
            <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.name}>
              {(limit * (page - 1)) + (index + 1)}
            </div>,
          sortable: true,
          grow: 2,
          reorder: true,
          width: '110px'
        },
      { name: "Groups", selector: row => row.name, minWidth: "200px", sortable: true },
      { name: "Role",  selector: row => row.roles.join(", "), minWidth: "200px", sortable: true },
      {
          name: "Actions",
          selector: row =>
              <div className='d-flex align-items-center gap-3'>
                  {/* <div className='view-icon cursor-pointer' title='View'><ViewIcon style={{ marginTop: "-1px" }} /></div> */}
                    <div className='view-icon cursor-pointer' title="Edit" onClick={()=>navigate(`${EDIT_GROUP}/${row?.id}`)}>
                      <EditIcon />
                    </div>
                    <div className='view-icon cursor-pointer' onClick={() => setIsSwal({ show: true, id: row?.id
                    , groupName: row?.name })} title='Delete'>
                      <CloseIcon style={{ marginLeft: "2px" }} />
                  </div>
              </div>,
          width: "180px", sortable: true
      }, 
  ];

  // Custom styling for the table
  const customStyles={
      table: {
          style: {
              borderRadius: "15px 15px 0px 0px",
              border: "1px solid var(--border-color)",
              overflow: "hidden"
          },
      },
      headCells: {
          style: {
              backgroundColor: "var(--light-blue)",
              justifyContent: 'flex-start !important',
              color: "var(--dark-gray)",
              fontWeight: "400",
              fontSize: "15px",
          },
      },
      rows: {
          style: {
              height: "45px", // Set row height
              justifyContent: "start",
          },
      },
      cells: {
          style: {
              fontSize: '14px',
              justifyContent: 'flex-start !important',
              borderRight: "1px solid var(--border-color)",
          },
      },
  };

  return (
      <div className='member-stats-section'>
              <SweetAlertModal 
                showSweetAlert={isSwal.show} 
                setShowSweetAlert={(show) => {
                  if (show) handleDelete(isSwal.id);
                  else setIsSwal({ ...isSwal, show: false });
                }}      
                memberName={isSwal?.groupName}           
              />          
          <div className='page-details'>
              <div className='top-section d-flex align-items-center justify-content-between'>
                  <div className='left'>Groups</div>
                  <div className='right d-flex align-items-center  gap-3'>
                      <div className='search-box'>
                        <input
                          type='text'
                          placeholder='Search'
                          className='form-control'
                          value={searchText}
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                        {
                          searchText && (
                            <FaTimes className='clear-icon' onClick={clearSearch} />
                          )}
                      </div>
                        <div className='add-clubs-info cursor-pointer' onClick={() => navigate(ADD_GROUP, { state: { currentPageName: "Add or Edit Group" } })} title="Create">
                            <AddIcon />
                        </div>
                  </div>
              </div>

              <div className='table-box mt-3'>
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                  fixedHeader
                  highlightOnHover
                  responsive
                  pagination
                  paginationServer
                  paginationPerPage={limit}
                  progressPending={pending}
                  onChangeRowsPerPage={setLimit}
                  paginationTotalRows={totalRows}
                  paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                  onChangePage={setPage}
                />
              </div>
          </div>
      </div>
  )
}

export default Groups;