
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Col, Form, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { FORGOT_PASSOWORD, HOME, PRIVACY_POLICYS, TERMS_AND_CONDITION } from '../../constants/pathname'
import { FaRegEyeSlash } from 'react-icons/fa'
import { ReactComponent as EyeIcon } from '../../assets/images/login/eye.svg'
import { ReactComponent as Mail } from '../../assets/images/login/email.svg'
import toast from 'react-hot-toast';
import { userLogin } from '../../api/actions/auth';
import LoginHeader from "../../common/layout/LoginHeader";
import { checkTheme, switchApp } from '../../utils/utils';
import { IoIosArrowRoundForward } from "react-icons/io";
import { GET_USER_DETAILS } from '../../api/api';

const Login = () => {
    const [showPass, setShowPass] = useState(false)
    const navigate = useNavigate();
    const [pending, setPending] = useState(false)

     // Redirecting to home page if user is logged in
    useEffect(() => {
        const userToken = localStorage.getItem('token');
        if (userToken) {
            navigate(HOME);
        }
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        const refresh = urlParams.get("refresh");
        
        if (token) {
            const verifyToken = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_BACKENDURL}${GET_USER_DETAILS}`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${token}`
                        },
                        // body: JSON.stringify({ token }) 
                    });
    
                    const data = await response.json();

    
                    if (response.ok) {
                        localStorage.setItem("token", token)
                        localStorage.setItem("user_info", JSON.stringify(data?.data || {}))
                        localStorage.setItem('is_logged', true)
                        localStorage.setItem("refresh", refresh)

                        navigate(HOME);
                    } else {
                        console.error('API call failed:', data.message);
                    }
                } catch (error) {
                    console.error('Network error:', error);
                }
            };

            verifyToken();
        }
    }, []);
    

    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        validationSchema: Yup.object().shape({
            username: Yup.string()
                // .email('Invalid email address')
                .required('Email is Required'),
            password: Yup.string()
                //   .min(6, 'Password must be at least 6 characters')
                .required('Password is Required'),
        }),
        onSubmit: (values) => {
            setPending(true)
            userLogin(values)((response) => {
              
                if (response?.status_code === 200) {

                    const userData = response?.data?.user;

                    const isNotMember = userData?.is_admin || userData?.is_superuser || userData?.is_staff || userData?.club_admin;
                    
                    if(isNotMember){
                        localStorage.setItem("token", response?.data?.token)
                        localStorage.setItem("user_info", JSON.stringify(response?.data?.user))
                        localStorage.setItem('is_logged', true)
                        localStorage.setItem("refresh", response?.data?.refresh)
                        setPending(false)
                        setTimeout(() => {
                            navigate(HOME)
                        }, 500)
                        toast.success("Logged in Successfully")
                    } else {
                        switchApp(response?.data?.token, response?.data?.refresh);
                    }

                    


                } else {
                    console.log("response", response);
                    setPending(false)
                    toast.error(response?.response?.data?.message ? response?.response?.data?.message : 'Invalid username or password')
                    // localStorage.clear()
                }

            })
        },
    });
    const unmask = () => {
        setShowPass(!showPass)
    }

    const navigateToClub = () => {
        if (checkTheme()) {
          window.open('https://member.platinumclubnet.com/', '_blank', 'noopener,noreferrer');
        } else {
          window.open('https://member.privateclubworld.com/', '_blank', 'noopener,noreferrer');
        }
      };

    return (
        <section className="login">
            <LoginHeader />
            <section className='login-page'>
                <Row style={{ width: "100%" }}>
                    <Col lg={6}> </Col>
                    <Col lg={6}>
                        <div className='formd-section'>
                            <div className='formd-main'>
                                <div className='heading w-75'>
                                    <h1 className='haed' style={{margin:0}}>Welcome</h1>
                                    <p className='sub-head'>Please enter your email and password</p>
                                </div>
                                <div className='form-content col-md-10'>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <div className='email-main '>
                                            <Form.Group className="mb-3 " controlId="formPlaintextEmail">
                                                <Form.Label >
                                                    Email<span className='required'>*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    placeholder='Enter your Email'
                                                    name='username'
                                                    value={formik.values?.username}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={formik.touched.username && formik.errors.username}
                                                />
                                                {formik.touched.username && formik.errors.username && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.username}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            <div className='image-wrapper'>
                                                <Mail />
                                            </div>
                                        </div>
                                        <div className='pswd-main'>
                                            <Form.Group className="mb-3" controlId="formPlaintextPassword">
                                                <Form.Label >
                                                    Password<span className='required'>*</span>
                                                </Form.Label>
                                                <Form.Control
                                                    type={showPass ? 'text' : "password"}
                                                    name='password'
                                                    placeholder='Enter your Password'
                                                    value={formik.values?.password}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={formik.touched.password && formik.errors.password}
                                                />
                                                {formik.touched.password && formik.errors.password && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.password}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            <div className='img-wrapper' onClick={unmask}>
                                                {showPass ?  <EyeIcon /> : <FaRegEyeSlash size={18} />}
                                            </div>
                                        </div>
                                        <div className='login-forgot'>
                                            <Link to={FORGOT_PASSOWORD} className='forgot'>Forgot password?</Link>
                                        </div>
                                        <div className='sign'>
                                            <Button className='sign-button w-100' type='submit' disabled={pending}>{pending ? "Signing in ..." : "Login"}</Button>
                                        </div>
                                        
                                    </Form>
                                    
                                    <div className='privacypolicy'>
                                    {/* <Button className='w-100 mb-2' onClick={navigateToClub} style={{backgroundColor:"var(--sandy-brown)",border:"1 px solid var(--sandy-brown)",height:"48px"}}>
                                            Go to Member Login <IoIosArrowRoundForward />
                                        </Button> */}
                                        <p>
                                            By registering with {checkTheme() ? "PlatinumClubNet" : "PrivateClubWorld"}, you agree to our   
                                            <Link to={TERMS_AND_CONDITION} style={{ textDecoration: "none" }} target='__blank'> <span> Terms of Use</span> 
                                            </Link> and our<Link to={PRIVACY_POLICYS} style={{ textDecoration: "none" }} target='__blank'> <span> Privacy Policy</span> 
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
        </section>
    )
}

export default Login
