import React from 'react'
import FacilitiesCategory from '../../components/FacilitiesCategory/FacilitiesCategory'

const FacilitiesCategoryPage = () => {
  return (
    <div className='facilities-category-page'>
        <FacilitiesCategory />
    </div>
  )
}

export default FacilitiesCategoryPage