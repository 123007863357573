import { ADMIN_REQUEST_LOI, CLUB_ADMIN_ASSIGN_MEMBER, CLUB_ADMIN_BOOKING_ACCEPT, CLUB_ADMIN_BOOKING_CANCEL, CLUB_ADMIN_BOOKING_DECLINED, CLUB_ADMIN_BOOKING_MODIFY, CLUB_ADMIN_SEND_MESSAGE_BOOKING, CLUB_BOOKING_MEMBER_MESSAGE_LIST, MEMBER_REQUEST_AND_FACILITY, MEMBER_REQUEST_AND_FACILITY_ADMIN, MEMBER_REQUEST_AND_FACILITY_DETAILS, RECIPROCAL_REQUEST_FOR_MODERATION_LIST,RC_MODERATION_BOOKING_LIST, CLUB_ADMIN_SEND_MEMBER_VERIFICATON_BOOKING, ASSIGN_ADMIN_FOR_REQUEST, CLUB_ADMIN_BOOKING_UPDATE, CLUB_ADMIN_BOOKING_ACCEPT_RC, CLUB_ADMIN_BOOKING_DECLINED_RC, CLUB_ADMIN_BOOKING_DECLINE, ADD_NOTES, ADD_STRIPE_LINK, PAYMENT_CONFIRMED  } from "../api";
import axiosInstance from "../axiosinstance";



export const listMemberRequestBooking = (props) => onResponse => {
    try {
        let BASE_URL = MEMBER_REQUEST_AND_FACILITY + '?'
        if (props.page) {
            BASE_URL += 'page=' + props.page + '&'

        }
        if (props.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if(props.sort_by){
            BASE_URL+='sort_by='+props.sort_by+'&'
        }
        if(props.order){
            BASE_URL+='order='+props.order+'&'
        }
        if(props.start_date){
            BASE_URL+='start_date='+props.start_date+'&'
        }
        if(props.end_date){
            BASE_URL+='end_date='+props.end_date+'&'
        }
        if(props.club_name){
            BASE_URL+='club_name='+props.club_name+'&'
        }
        if(props.facility_name){
            BASE_URL+='facility_name='+props.facility_name+'&'
        }
        if(props.new_status){
            BASE_URL+='new_status='+props.new_status+'&'
        }
        if(props.pcn){
            BASE_URL+='pcn='+props.pcn+'&'
        }
        
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }

};

export const listMemberRequestBookingAdmin = (props) => onResponse => {
    try {
        let BASE_URL = MEMBER_REQUEST_AND_FACILITY_ADMIN+ '?'
        if (props.page) {
            BASE_URL += 'page=' + props.page + '&'

        }
        if (props.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if(props.sort_by){
            BASE_URL+='sort_by='+props.sort_by+'&'
        }
        if(props.order){
            BASE_URL+='order='+props.order+'&'
        }
        if(props.start_date){
            BASE_URL+='start_date='+props.start_date+'&'
        }
        if(props.end_date){
            BASE_URL+='end_date='+props.end_date+'&'
        }
        if(props.club){
            BASE_URL+='club='+props.club+'&'
        }
        if(props.country){
            BASE_URL+='country='+props.country+'&'
        }
        if(props.facility_name){
            BASE_URL+='facility_name='+props.facility_name+'&'
        }
        if(props.new_status){
            BASE_URL+='new_status='+props.new_status+'&'
        }
        
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }

};

export const listReciprocalModeration = (props) => onResponse => {
    try {
        let BASE_URL = RECIPROCAL_REQUEST_FOR_MODERATION_LIST + '?'
        if (props.page) {
            BASE_URL += 'page=' + props.page + '&'

        }
        if (props.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if(props.sort_by){
            BASE_URL+='sort_by='+props.sort_by+'&'
        }
        if(props.order){
            BASE_URL+='order='+props.order+'&'
        }
        if(props.start_date){
            BASE_URL+='start_date='+props.start_date+'&'
        }
        if(props.end_date){
            BASE_URL+='end_date='+props.end_date+'&'
        }
        if(props.club_name){
            BASE_URL+='club_name='+props.club_name+'&'
        }
        if(props.facility_name){
            BASE_URL+='facility_name='+props.facility_name+'&'
        }
        if(props.new_status){
            BASE_URL+='new_status='+props.new_status+'&'
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }

};

export const getMemberRequestBookingDetails = (props) => onResponse => {
    try {
        axiosInstance.get(MEMBER_REQUEST_AND_FACILITY_DETAILS + '?id=' + props.id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const addloiadmin = (props) => onResponse => {
    try {
        axiosInstance.post(ADMIN_REQUEST_LOI, props.values)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};

export const listBookingMessages = (props) => onResponse => {
    try{
        let BASE_URL = `${CLUB_BOOKING_MEMBER_MESSAGE_LIST}?id=${props?.id ?? ''}&country=${props?.country}`
        axiosInstance.get(BASE_URL).then((res)=>{
            onResponse(res?.data)
    }).catch((err)=>{
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}

export const adminBookingCancel = (props) => onResponse => {
    try{
        axiosInstance.post(CLUB_ADMIN_BOOKING_CANCEL, props).then((res)=>{
            onResponse(res?.data)
        }).catch((err)=>{
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}

export const adminBookingModify = (props) => onResponse => {
    try{
        axiosInstance.post(CLUB_ADMIN_BOOKING_MODIFY, props).then((res)=>{
            onResponse(res?.data)
        }).catch((err)=>{
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}

export const adminBookingAccept = (props) => onResponse => {
    try{
        axiosInstance.post(CLUB_ADMIN_BOOKING_ACCEPT, props).then((res)=>{
            onResponse(res?.data)
        }).catch((err)=>{
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}

export const adminBookingDeclined = (props) => onResponse => {
    try{
        axiosInstance.post(CLUB_ADMIN_BOOKING_DECLINED, props).then((res)=>{
            onResponse(res?.data)
        }).catch((err)=>{
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}

export const adminBookingDeclinedMemberModified = (props) => onResponse => {
    try{
        axiosInstance.post(CLUB_ADMIN_BOOKING_DECLINE, props).then((res)=>{
            onResponse(res?.data)
        }).catch((err)=>{
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}

export const paymentConfirmed = (props) => onResponse => {
    try{
        axiosInstance.post(PAYMENT_CONFIRMED, props).then((res)=>{
            onResponse(res?.data)
        }).catch((err)=>{
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}


export const adminSendMessageBooking = (props) => onResponse => {
    try{
        axiosInstance.post(CLUB_ADMIN_SEND_MESSAGE_BOOKING, props).then((res)=>{
            onResponse(res?.data)
        }).catch((err)=>{
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}


export const getMember = (props) => onResponse => {
    try {
        let BASE_URL = CLUB_ADMIN_ASSIGN_MEMBER + '?'
        if (props.page) {
            BASE_URL += 'page=' + props.page + '&'
        }
        if (props?.limit){
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props?.search_param) {
            BASE_URL += 'search=' + props.search + '&'
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
// rc moderation booking list

export const rcModerationBookingList = (props) => onResponse => {
    try{
        let BASE_URL = RC_MODERATION_BOOKING_LIST + '?' 

        if (props.page) {
            BASE_URL += 'page=' + props.page + '&'

        }
        if (props.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if (props.start_date){
            BASE_URL += 'start_date=' + props.start_date + '&'
        }
        if (props.end_date){
            BASE_URL += 'end_date=' + props.end_date + '&'
        }
        if (props.club_name){
            BASE_URL += 'club_name=' + props.club_name + '&'
        }
        if (props.new_status){
            BASE_URL += 'new_status=' + props.new_status + '&'
        }
        if (props.club){
            BASE_URL += 'club=' + props.club + '&'
        }
        if (props.country){
            BASE_URL += 'country=' + props.country + '&'
        }

        axiosInstance.get(BASE_URL).then((res)=>{
            onResponse(res?.data)
        }).catch((err)=>{
            onResponse(err)
        })
    } catch (err){
        console.log(err)
    }
}

export const sendmemberVerification = (props) => onResponse => {
    try{
        axiosInstance.post(CLUB_ADMIN_SEND_MEMBER_VERIFICATON_BOOKING, props).then((res)=>{
            onResponse(res?.data)
        }).catch((err)=>{
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }

}

export const assignAdminForRequest = (props) => onResponse => {
    try{
        axiosInstance.post(ASSIGN_ADMIN_FOR_REQUEST, props).then((res)=>{
            onResponse(res?.data)
        }).catch((err)=>{
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}

export const clubAdminBookingUpdate = (props) => onResponse => {
    try{
        axiosInstance.post(CLUB_ADMIN_BOOKING_UPDATE, props).then((res)=>{
            onResponse(res?.data)
        }).catch((err)=>{
            onResponse(err)
        })
    } catch (err){
        console.log(err)
    }
}

export const adminRcBookingAccept = (props) => onResponse => {
    try{
        axiosInstance.post(CLUB_ADMIN_BOOKING_ACCEPT_RC, props).then((res)=>{
            onResponse(res?.data)
        }).catch((err)=>{
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}

export const adminRcBookingDeclined = (props) => onResponse => {
    try{
        axiosInstance.post(CLUB_ADMIN_BOOKING_DECLINED_RC, props).then((res)=>{
            onResponse(res?.data)
        }).catch((err)=>{
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}

export const addNotes = (props) => onResponse => {
    try{
        axiosInstance.post(ADD_NOTES,props).then((res)=>{
            onResponse(res?.data)
        }).catch((err)=>{
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}

export const addStripe = (props) => onResponse => {
    try{
        axiosInstance.post(ADD_STRIPE_LINK,props).then((res)=>{
            onResponse(res?.data)
        }).catch((err)=>{
            onResponse(err)
        })
    } catch (error) {
        console.log(error)
    }
}