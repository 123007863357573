import React, { useEffect, useRef, useState } from 'react'
import DataTable from "react-data-table-component";
import { ReactComponent as FilterIcon } from "../../assets/images/common/filter-icon.svg";
import Select from "react-select"
import { ReactComponent as AddIcon } from "../../assets/images/common/add-icon.svg";
import { useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import {ReactComponent as ViewIcon} from "../../assets/images/common/view-icon.svg";
import {
    GoogleMap,
    Marker,
    useLoadScript,
    DirectionsService,
    DirectionsRenderer,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import AsyncSelect from 'react-select/async';
import { IoIosClose } from "react-icons/io";

// Mui
import { ReactComponent as EditIcon } from "../../assets/images/common/edit-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/common/close-icon.svg";
// Drawer
import Drawer from '@mui/material/Drawer';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import { CLUB_PROFILES, CREATE_CLUBS_FACILITY } from '../../constants/pathname';
import SweetAlertModal from '../../components/SweetAlert/SweetAlertModal';
import { getfaciltyDetails, listClubFaciltiy } from '../../api/actions/addClubFacility';
import { faciltiyCategoryDropdown, listFaciltiyCategory } from '../../api/actions/addFacitiltycCategory';
import { clubTypeListDropDown, deleteClubFacility } from '../../api/actions/club';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import toast from 'react-hot-toast';
// Modal
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { checkTheme } from '../../utils/utils';
import defaultFacilityIcon from "../../assets/images/common/img1.png";

const ClubsFacilities=() => {
    const autocompleteRef=useRef(null);
    const GOOGLE_MAPS_API_KEY=process.env.REACT_APP_API_KEY;

    const { isLoaded }=useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries: ['places'],
    });
    
    const [showDrawer, setShowDrawer]=useState(false);
    const navigate=useNavigate();
    const [isSwal, setIsSwal]=useState({ show: false, id: '' });
    const [dataList, setData]=useState([]);
    const [update, setUpdate] = useState(false);
    const [categoryOptionsList, setCategoryOptions]=useState([]);
    // Search
    const [search, setSearch] = useState("");
    // Pagination
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const [pending, setPending] = useState(false);
    // Club Type Options
    const [clubTypeOptions, setClubTypeOptions]=useState([]);
    // Filter
    const [filter, setFilter] = useState({
        club_type: "",
        category: "",  
        location: ""      
    });
    // Permissions
    const [permissionsList, setPermissionsList]=useState([]);
    // User information
    const [userInfo, setUserInfo]=useState(null);  
    // Clubs facilities details modal and description
    const [showFacilityDetail, setShowFacilityDetail] = useState(false);
    const [facilityDetail, setFacilityDetail] = useState(null);

    const handleClose = () => setShowFacilityDetail(false);
    const handleShow = () => setShowFacilityDetail(true);    
    
    // Setting permission list
    useEffect(() => {
        let userInfoObj = JSON.parse(localStorage.getItem("user_info"));
        setUserInfo(userInfoObj);

        if(userInfoObj && userInfoObj?.permissions && userInfoObj?.permissions?.length){
            setPermissionsList(userInfoObj?.permissions);
        }

    }, []);

    const formik= useFormik({
        initialValues: {
            location: "",
            category: "",
            category_option: "",   
            club_type_name: "",   
            club_type_option: "",                  
            city: "",
            latitude: "",
            longitude: ""
        },
        onSubmit: (values, resetForm) => {
            console.log("values", values);
            setFilter({
                club_type: values?.club_type_option?.value || "",
                category: values?.category,
                location: values?.location
            });
            setShowDrawer(false);
        }
    });

    // Getting facility category for category options
    useEffect(() => {
        faciltiyCategoryDropdown({ page: 1, limit: 10 })(response => {
            if (response?.status_code == 200) {
                setCategoryOptions(response?.data?.results)
            }
        })
    }, []);

    // Getting facility for listing
    useEffect(() => {
        setPending(true);
        let userInfoObj = JSON.parse(localStorage.getItem("user_info"));

        listClubFaciltiy({ 
            page : search ? 1 : page, limit, search, category: filter?.category, club_type: filter?.club_type, location: filter?.location ,
            club: userInfo?.club_details?.[0]?.club_id || userInfoObj?.club_details?.[0]?.club_id  || "",
            country: userInfo?.club_details?.[0]?.country || userInfoObj?.club_details?.[0]?.country || "",
        })((response) => {
            if (response?.status) {
                setData(response?.data?.results);
                setTotalRows(response?.data?.count);
            }
            setPending(false);
        })
    }, [update, filter]);

    useEffect(() => {
        const timeout = setTimeout(() => {
          if (search != "") {
            setUpdate(!update);
          }
        }, 800);
        return () => {
          clearTimeout(timeout);
        };
    }, [search]);
    
    const handleSearchData = (e) => {
        setSearch(e.target.value);
        if (e.target.value == "") {
            setUpdate(!update);
        }
    };
    
    const handleClearSearch = () => {
        setSearch("");
        setUpdate(!update);
    }; 
    
    const handleSerialNumber = (index) => {
        return page == 1 ? index + 1 : (page - 1) * limit + index + 1;
    };

    // Setting club type options
    useEffect(() => {
        clubTypeListDropDown({
            search_param: "",
            limit: 100
        })((response) => {
            if (response&&response?.status) {
                setClubTypeOptions(response?.data?.results);
            }
        })
    }, []);

    const handleDelete = (id) => {
        
        deleteClubFacility(id)((response) => {
            if (response && response?.status) {
                toast.success('Successfully deleted');
            } else {
                toast.error('Deletion failed');
            }
            setIsSwal({ show: false, id: null });
            setUpdate(!update);
        });
    }; 
    
    // Get facilities details
    const handleFacilityDetails = (id) =>{

        getfaciltyDetails(id)((response) => {
            if(response && response?.status) {
                let data = response?.data || {};
                console.log("facility details", data);
                setFacilityDetail(data);
                setTimeout(() => {
                    handleShow();
                }, 800);
            }
        });
    };

    // Define columns for the table
    let columns=[
        { name: "Sl.No", selector: (row, index) => handleSerialNumber(index), width: "120px", },
        { name: "Facility Name", 
            selector: row => 
                <div 
                    title={row.facility_name}
                    style={{ cursor: 'pointer',color:'#0000EE' }}
                    onClick={() => handleFacilityDetails(row.id)}
                >
                    {row.facility_name}
                </div>, 
            minWidth: "180px"
        },
        { name: "Category", selector: row => row.facility_category_name, minWidth: "180px"},
        { name: "Club Name", 
            selector: row => 
                <div
                    title={row.club_name}
                    style={{ cursor: 'pointer',color:'#0000EE' }}
                    onClick={() => navigate(`${CLUB_PROFILES}${row.club}`, { state: { country: row.country } })}
                >
                    {row.club_name}
                </div>, 
            reorder: true,
            minWidth: "280px" 
        },
        { name: "Club Type", selector: row => row.club_type_name, minWidth: "180px", sortable: true },
        { name: "City", selector: row => row.city_name, minWidth: "220px", sortable: true },
        { name: "Country", selector: row => row.country_name, minWidth: "220px", sortable: true },
        {
            name: "Actions",
            selector: row => 
                <div className='d-flex align-items-center gap-3'>
                    {/* {permissionsList && permissionsList.includes("view_club_facilities") && 
                        <div className='view-icon cursor-pointer' 
                            onClick={() => navigate(CREATE_CLUBS_FACILITY, {state : {currentPageName: "View" }})} title='View'>
                                <ViewIcon style={{marginTop: "-1px"}}/>
                        </div>} */}
                        <div 
                            title="Edit" 
                            className='view-icon cursor-pointer' 
                            onClick={() => navigate(CREATE_CLUBS_FACILITY, { state: { currentPageName: "Edit Club Facility", id: row?.id  } })}
                        >
                            <EditIcon />
                        </div>
                        <div 
                            title='Delete'
                            className='view-icon cursor-pointer' 
                            onClick={() => setIsSwal({ show: true, id: row?.id })} 
                        >
                            <CloseIcon style={{ marginLeft: "2px" }} />
                        </div>
                </div>
        },
    ];

    // Eliminate club name for club admin
    if(!userInfo?.is_superuser){
        columns = columns.filter((item) => item.name !== "Club Name" 
            && item.name !== "Club Type" && item.name !== "City" && item.name !== "Country");
    }else{
        columns = columns.filter((item) => item.name !== "Actions");
    }
    // Custom styling for the table
    const customStyles={
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",
                border: "1px solid var(--border-color)",
                overflow: "hidden"
            },
        },
        headCells: {
            style: {
                backgroundColor: "var(--light-blue)",
                justifyContent: 'flex-start !important',
                color: "var(--dark-gray)",
                fontWeight: "400",
                fontSize: "15px",
            },
        },
        rows: {
            style: {
                height: "45px", // Set row height
                justifyContent: "start",
            },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    };

    // Google map

    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    }=usePlacesAutocomplete();

    const handleInputChange=(e) => {
        setValue(e.target.value);
        if (e.target.value==="") {
            clearSuggestions();
        }
    };  

    const handleSelect = async (address) => {
        let cityInfo = "";
        if(address){
            let cityArr = address?.split(",");
            cityInfo = cityArr[0];
        }

        setValue(address, false);
        clearSuggestions();

        try {        
            // Update formik state with new latitude and longitude
            formik.setValues({
                ...formik.values,
                location: cityInfo
            });
        } catch (error) {
            console.error("Error fetching geocode:", error);
        }
    };

    let typingTimer;
    const loadOptionsFaciltiycaategory = (inputValue) => {
        return new Promise((resolve) => {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {
                if (inputValue.length >= 1) {
                    faciltiyCategoryDropdown({ page: 1, limit: 10, search: inputValue })((response) => {
                        if (response?.status_code === 200) {
                            const options = response?.data?.results || [];
                            setCategoryOptions(options);
                            resolve(options);
                        } else {
                            resolve([]);
                        }
                    });
                } else {
                    resolve([]);
                }
            }, 300);
        });
    };

    const handleChange = (e, name, selected_name) => {
        if (e) {
            formik.setValues({
                ...formik.values,
                [name]: e.value,
                [selected_name]: e,

            })
        } else {
            formik.setValues({
                [name]: null,
                [selected_name]: [],
            })
        }
    }

    return (
      <div className='clubs-facilities-page'>
        <Modal show={showFacilityDetail} onHide={handleClose} className='facility-details-modal'>
            <Modal.Header closeButton>
                <Modal.Title>{facilityDetail?.facility_name || ""}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className='facility-image'>
                        <img src={
                            Array.isArray(facilityDetail?.club_facility_images) && facilityDetail?.club_facility_images?.length
                            ? facilityDetail?.club_facility_images?.[0]?.image
                            : defaultFacilityIcon
                            } 
                        alt={facilityDetail?.facility_name || ""} />
                    </div>
                    {facilityDetail?.facility_description && 
                        <div className='facility-description mt-4'>
                            <h4>Description</h4>
                            <div className='mt-3'>
                                {facilityDetail?.facility_description || ""}
                            </div>
                        </div>}                    
                </div>
            </Modal.Body>
        </Modal>
        <div className='member-stats-section'>
            <Drawer
                anchor={'right'}
                open={showDrawer}
                onClose={() => setShowDrawer(false)}
                className='member-stats-drawer'
                sx={{
                    '& .MuiPaper-root': {
                        padding: '30px 25px',
                        height: "100vh",
                        overflow: "hidden"
                    },
                    '& .MuiDrawer-paper': {
                        width: '420px'
                    },
                    '& .MuiBox-root': {
                        width: '420px'
                    },
                    '& .MuiList-root': {
                        display: 'flex',
                        columnGap: '10px',
                        width: '90%',
                        margin: 'auto',
                        paddingTop: "25px"
                    },
                    '& .MuiButtonBase-root': {
                        padding: '7px',
                        textAlign: 'center',
                        alignItems: 'center',
                        borderRadius: '10px',
                        backgroundColor: '#F5F5F5',
                    }
                }}
            >
                <div className="drawer-top-box">
                    <div>Filter</div>
                    <button type="button" class="btn-close" aria-label="Close" onClick={() => setShowDrawer(false)}></button>
                </div>
                <div className="drawer-bottom-box mt-4">
                    <Form onSubmit={formik.handleSubmit}>
                        {/* Category */}
                        <div className='item'>
                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em6">
                                <Form.Label>Category</Form.Label>
                                <div className='input-border'>                                   
                                    <AsyncSelect
                                        loadOptions={loadOptionsFaciltiycaategory}
                                        defaultOptions={categoryOptionsList || []}
                                        classNamePrefix="select"
                                        isClearable={true}
                                        placeholder={'Select Category'}
                                        onChange={(e) => handleChange(e, 'category', 'category_option')}
                                        value={formik.values?.category_option}
                                        styles={{
                                            control: (styles, { isFocused }) => ({
                                                ...styles,
                                                maxHeight: '31px',
                                                width: '230px', // Ensures a minimum width
                                                border: formik.errors.facility_category && formik.touched.facility_category
                                                    ? '1px solid #dc3545'
                                                    : '1px solid var(--light-grayish)',
                                                outline: 'none',
                                                boxShadow: isFocused ? '0 0 0 1px var(--light-grayish)' : 'none',
                                                color: 'var(--medium-gray)',
                                                fontSize: '15px',
                                                '&:hover': {
                                                    border: formik.errors.facility_category && formik.touched.facility_category
                                                        ? '1px solid #dc3545'
                                                        : '1px solid var(--light-grayish)',
                                                },
                                            }),
                                            valueContainer: (styles) => ({
                                                ...styles,
                                                alignItems: 'center',
                                                padding: '3px 8px',
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                color: 'var(--medium-gray)',
                                                fontSize: '15px',
                                            }),
                                            menu: (provided) => ({
                                                ...provided,
                                                border: '1px solid var(--light-grayish)',
                                                boxShadow: 'none',
                                                width: '230px', // Ensures a minimum width
                                                zIndex: 1050, // Ensure the dropdown appears above other elements
                                            }),
                                            menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 9999, // Ensures the dropdown appears above modals or other elements
                                            }),
                                            placeholder: (styles) => ({
                                                ...styles,
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                color: 'var(--medium-gray)',
                                                fontSize: '15px',
                                            }),
                                        }}
                                        menuPortalTarget={document.body} // Ensures the dropdown renders in a higher-level container
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        {userInfo?.is_superuser &&
                            <>
                            {/* Club Type */}
                                <div className='item'>
                                    <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em7">
                                        <Form.Label>Club Type</Form.Label>
                                        <div className='input-border'>
                                            <Select
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 5,
                                                    colors: {
                                                        ...theme.colors,
                                                    },
                                                })}
                                                options={clubTypeOptions}
                                                value={formik?.values?.club_type_option}
                                                onChange={(option) =>{
                                                    formik.setValues({
                                                        ...formik?.values,
                                                        club_type_name: option?.label,   
                                                        club_type_option: option
                                                    })
                                                }}
                                                placeholder="Select"
                                                isSearchable
                                                isClearable
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: '230px', // Ensures a minimum width
                                                        whiteSpace: 'nowrap', 
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        width: '230px', // Ensures dropdown has enough space
                                                    })
                                                }}
                                            />
                                        </div>
                                    </Form.Group>
                                </div>
                                {/* City */}
                                <div className='item'>
                                    <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="city" style={{position: "relative"}}>
                                        <Form.Label>City</Form.Label>
                                        <div className='input-border gp-city-box'>
                                            <Form.Control
                                                type="text"
                                                placeholder="Search city"
                                                name="city"
                                                value={value}
                                                onChange={handleInputChange}
                                                className='gp-city-search'
                                            /> 
                                            {formik?.values?.location && 
                                                <div className='close-icon' 
                                                    onClick={() =>{
                                                        setFilter({
                                                            category: filter?.category || "",
                                                            club_type: filter?.club_type || "",
                                                            location: ""
                                                        });
                                                        setValue("");
                                                        formik.setFieldValue("location", "")
                                                    }}
                                                >
                                                    <IoIosClose size={20}/>
                                                </div>}
                                            {value !== "" && status === "OK" && (
                                                <ul
                                                    style={{
                                                        listStyleType: "none",
                                                        padding: "0",
                                                        margin: "8px 0",
                                                        backgroundColor: "#fff",
                                                        border: "1px solid #ddd",
                                                        borderRadius: "4px",
                                                        position: "absolute",
                                                        width: "235px",
                                                        right: "0% !important",
                                                        left: "auto",
                                                        top: "81%",
                                                        maxHeight: "200px",
                                                        overflowY: "auto",
                                                        zIndex: 1000,
                                                    }}
                                                >
                                                    {data.map(({ place_id, description }) => (
                                                        <li
                                                            key={place_id}
                                                            onClick={() => handleSelect(description)}
                                                            style={{
                                                                padding: "10px",
                                                                cursor: "pointer",
                                                                borderBottom: "1px solid #eee",
                                                                backgroundColor: "#fff",
                                                                transition: "background-color 0.2s",
                                                            }}
                                                            onMouseEnter={(e) =>
                                                                (e.target.style.backgroundColor = "#f0f0f0")
                                                            }
                                                            onMouseLeave={(e) =>
                                                                (e.target.style.backgroundColor = "#fff")
                                                            }
                                                        >
                                                            {description}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </Form.Group>
                                </div>  
                            </>}                 
                        <div className='submit-box d-flex align-items-center justify-content-end gap-3' style={{ height: "50vh" }}>
                            <button 
                                className='btn-type-2 cursor-pointer'
                                onClick={() =>{
                                        setFilter({
                                           club_type: "",
                                            category: "",  
                                            location: ""      
                                        });
                                        setValue("");
                                        formik.handleReset();                                        
                                    }}
                            >Clear</button>
                            <button className='btn-type-1' type="submit">Submit</button>
                        </div>
                    </Form>
                </div>

            </Drawer>
            <div className='page-details'>
                <div className='top-section d-flex align-items-center justify-content-between'>
                    <div className='left'>Club Facilities</div>
                    <div className='right d-flex align-items-center  gap-3'>
                        <div className='search-box'>
                            <input
                                type='text'
                                placeholder='Search Facilities'
                                className='form-control'
                                value={search}
                                onChange={handleSearchData}
                            />
                            {
                                search && (
                                    <FaTimes className='clear-icon' onClick={handleClearSearch} />
                                )}
                        </div>
                        <div className='filter-box d-flex align-items-center gap-3 cursor-pointer' onClick={() => setShowDrawer(true)}>
                            <FilterIcon />
                            <span>Filter</span>
                        </div>
                       
                            {!userInfo?.is_superuser && 
                                <div className='add-clubs-info cursor-pointer' onClick={() => navigate(CREATE_CLUBS_FACILITY, { state: { currentPageName: "Add Club Facility" } })} title="Create">
                                    <AddIcon />
                                </div>}
                    </div>
                </div>
 
                <div className='table-box mt-3'>
                    <DataTable
                        columns={columns}
                        data={dataList}
                        customStyles={customStyles}
                        fixedHeader
                        highlightOnHover
                        responsive
                        onChangePage={(page) => { setPage(page); setUpdate(!update) }}
                        onChangeRowsPerPage={(perPage) => { setLimit(perPage); setUpdate(!update) }}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        progressPending={pending}
                        paginationPerPage={limit}
                        paginationRowsPerPageOptions={[25, 50, 75, 100]}
                        noDataComponent={
                            <span style={{ padding: "20px", fontSize: "15px", color: "#555" }}>
                              No Facilities Yet
                            </span>
                          }
                    />
                </div>
            </div>
            <SweetAlertModal showSweetAlert={isSwal.show} setShowSweetAlert={(show) => {
                if (show) handleDelete(isSwal.id);
                else setIsSwal({ ...isSwal, show: false });
            }} />
        </div>
      </div>
    )
}

export default ClubsFacilities