import { SOLITATRE_CLUB_LIST } from "../api";
import axiosInstance from "../axiosinstance";

export const listSolitareclub = (props) => onResponse => {
    try {
        let BASE_URL = SOLITATRE_CLUB_LIST + '?'
        if (props.page) {
            BASE_URL += 'page=' + props.page + '&'

        }
        if (props.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if(props.sort_by){
            BASE_URL+='sort_by='+props.sort_by+'&'
        }
        if(props.order){
            BASE_URL+='order='+props.order+'&'
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }

};