import DataTable from "react-data-table-component";
import { ReactComponent as EditIcon } from "../../assets/images/common/edit-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/common/add-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/common/close-icon.svg";
import { ReactComponent as InterestIcon } from "../../assets/images/common/interest-logo-icon.svg";
import { useEffect, useState } from "react";
import Drawer from '@mui/material/Drawer';
import { CREATE_INTERESTS, EDIT_INTEREST, PLACES } from '../../constants/pathname';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as FilterIcon } from "../../assets/images/common/filter-icon.svg";

import MenuItem from '@mui/material/MenuItem';
import { default as MuiSelect } from '@mui/material/Select';
import Select from 'react-select';
import { styled } from '@mui/material/styles';
import SweetAlertModal from '../SweetAlert/SweetAlertModal';
import { deleteInterest, interestStatusChanging, listInterest } from "../../api/actions/interest";
import { paginationRowsPerPageOptions } from "../../helpers/helper";
import toast from "react-hot-toast";
import { FaTimes } from "react-icons/fa";
import { Form } from "react-bootstrap";
import { CiEdit } from "react-icons/ci";
import { FormControlLabel, RadioGroup, Switch } from "@mui/material";
import { checkTheme } from "../../utils/utils";
import SweetAlert from "react-bootstrap-sweetalert";
const Interests = () => {
    // Sweet alert
    const [showSweetAlert, setShowSweetAlert] = useState(false);
    const [data, setData] = useState([{}]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [isDeleting, setIsDeleting] = useState(false);
    const [limit, setLimit] = useState(25);
    const [search, setSearch] = useState('');
    const [update, setUpdate] = useState(false);
    const [pending, setPending] = useState(false);

    const [isFocused, setIsFocused] = useState(false);
    const [isSwal, setIsSwal] = useState({ show: false, id: '' });
    const [showDrawer, setShowDrawer] = useState(false);
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [searchText, setSearchText] = useState('');
    // Permissions
    const [permissionsList, setPermissionsList] = useState([]);
    const [selectedId, setSelectedId] = useState(null);

    // Setting permission list
    useEffect(() => {
        let permissions = JSON.parse(localStorage.getItem("user_info"));

        if(permissions && permissions?.permissions && permissions?.permissions?.length){
            setPermissionsList(permissions?.permissions);
        }

    }, []);

    const handleSearch = (value) => {
        setSearch(value);
        setSearchText(value);
    }

    useEffect(() => {
        if (search.length >= 2) {
            const debounceTimer = setTimeout(() => {
                setDebouncedSearch(search);
            }, 500);

            return () => clearTimeout(debounceTimer);
        } else {
            setDebouncedSearch('');
        }
    }, [search]);

    const clearSearch = () => {
        setSearch('');
        setSearchText('');
        setDebouncedSearch('');
        setUpdate(!update);
    };

    const StyledSelect = styled(MuiSelect)(({ theme }) => ({
        height: '35px',
        width: '100px',
        color: 'white',
        padding: '10px',
        fontSize: "14px !important",
        backgroundColor: 'var(--light-green)',
        borderRadius: '30px',
        '& .MuiSvgIcon-root': {
            color: 'white', // Dropdown icon color
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent', // Optional border color
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white', // Border color on hover
        },
        '.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
            padding: "4px"
        }
    }));

    const navigate = useNavigate();
    const [status, setStatus] = useState(10);
    const handleToggleClick = (id) => {
        setSelectedId(id);
        setShowSweetAlert(true);
    };
    const confirmToggle = () => {
        if (selectedId !== null) {
            interestStatusChanging({ id: selectedId })((response) => {
                if (response?.status_code === 200) {
                    toast.success(response?.message);
                    const updatedData = data.map(item => {
                        if (item.id === selectedId) {
                            return { ...item, status_interest: response?.status };
                        }
                        return item;
                    });
                    setUpdate(!update);
                } else {
                    toast.error(response?.message || "Something went wrong");
                }
            });
        }
        setShowSweetAlert(false);
    };
    
    
        const CustomSwitch = styled((props) => (
            <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
        ))(({ theme }) => ({
            width: 39,
            height: 20,
            padding: 0,
            marginLeft: 5,
            '& .MuiSwitch-switchBase': {
                padding: 0,
                // margin: 2,
                transitionDuration: '300ms',
                '&.Mui-checked': {
                    transform: 'translateX(16px)',
                    color: '#fff',
                    '& + .MuiSwitch-track': {
                        backgroundColor: 'var(--sandy-brown)',
                        opacity: 1,
                        border: 0,
                        ...theme.applyStyles('dark', {
                            backgroundColor: '#2ECA45',
                        }),
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: 0.5,
                    },
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                    color: '#33cf4d',
                    border: '6px solid #fff',
                },
                '&.Mui-disabled .MuiSwitch-thumb': {
                    color: theme.palette.grey[100],
                    ...theme.applyStyles('dark', {
                        color: theme.palette.grey[600],
                    }),
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    backgroundColor: 'var(--sandy-brown)',
                    opacity: 0.7,
                    ...theme.applyStyles('dark', {
                        opacity: 0.3,
                    }),
                },
            },
            '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                width: 20,
                height: 20,
            },
            '& .MuiSwitch-track': {
                borderRadius: 26 / 2,
                backgroundColor: '#E9E9EA',
                opacity: 1,
                transition: theme.transitions.create(['background-color'], {
                    duration: 500,
                }),
                ...theme.applyStyles('dark', {
                    backgroundColor: '#39393D',
                }),
            },
        }));

    const columns = [
        {
            name: "Sl.No",
            key: "name",
            selector: row => row?.name,
            cell: (row, index) =>
                <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.name}>
                    {(limit * (page - 1)) + (index + 1)}
                </div>,
            sortable: true,
            grow: 2,
            reorder: true,
            width: '100px'
        },
        { name: "Category Name", selector: row => row.name, sortable: true, width: "400px" },
        {
            name: !checkTheme() ? "PCW Image" : "PCN Image",
            cell: (row) =>
                row.pcw_image ? (
                    <img
                        src={!checkTheme() ? row.pcw_image : row.pcn_image}
                        alt="Facility"
                        style={{ width: "40px", height: "40px", objectFit: "cover" }}
                    />
                ) : (
                    <InterestIcon style={{ width: "20px", height: "20px" }} />
                ),
        },
        
        // {
        //     name: "PCN Image",
        //     cell: (row) =>
        //         row.pcn_image ? (
        //             <img
        //                 src={row.pcn_image}
        //                 alt="Facility"
        //                 style={{ width: "40px", height: "40px", objectFit: "cover", }}
        //             />
        //         ) : (
        //             <InterestIcon style={{ width: "20px", height: "20px" }} />
        //         ),

        // },
        { name: "Interests", selector: row => row.interest_count, width: "180px" },
        {
            name: "Active/Inactive",
            selector: row =>
                <div className='view-icon cursor-pointer' style={{ width: "140px", height: "50px", paddingTop: "9px", overflow: "visible", border: "none" }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '10px', color: row.interest_status ? 'var(--light-green)' : 'red' }}>
                            {/* {row.interest_status ? 'Active' : 'Inactive'} */}
                        </span>
                        <CustomSwitch
                            checked={row.interest_status}
                            onChange={() => handleToggleClick(row.id)}
                        />
                    </div>
                </div>,
            width: "180px"
        },       
        {
            name: "Actions",
            selector: row =>
                
                <div className='d-flex align-items-center gap-3'>                    
                        <>
                            <div
                                className="view-icon cursor-pointer"
                                onClick={() => navigate(`${PLACES}/${row?.id}/${(row?.name)}`)}
                                title="Edit"
                            >
                                <EditIcon />
                            </div>
                            <Link to={`${EDIT_INTEREST}${row?.id}`}>
                                <div className='view-icon cursor-pointer' title="Edit"><CiEdit /></div>
                            </Link>
                        </>                    
                        <div className='view-icon cursor-pointer' onClick={() => setIsSwal({ show: true, id: row?.id })} title="Delete">
                            <CloseIcon style={{ marginLeft: "2px" }} />
                        </div>
                </div>,
            width: "200px"
        }
    ];

    // Scroll to top    
    useEffect(() => {
        if (window) {
            window.scrollTo(0, 0);
        }
    }, []);

    // Custom styling for the table
    const customStyles = {
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",
                border: "1px solid var(--border-color)",
                overflow: "hidden"
            },
        },
        headCells: {
            style: {
                backgroundColor: "var(--light-blue)",
                justifyContent: 'flex-start !important',
                color: "var(--dark-gray)",
                fontWeight: "400",
                fontSize: "15px",
            },
        },
        rows: {
            style: {
                height: "45px", // Set row height
                justifyContent: "start",
            },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    };

    useEffect(() => { getListData(); }, [page, limit, debouncedSearch, update]);

    const getListData = () => {
        setPending(true);

        if (debouncedSearch) {
            setPage(1);
        }
        listInterest({
            page: debouncedSearch ? 1 : page,
            limit,
            search: debouncedSearch,
        })(
            (response) => {
                if (response && response.status) {
                    setData(response?.data?.results);
                    setTotalRows(response?.data?.count);
                    setPending(false);
                }
            }
        );
    };

    const handleDelete = (id) => {
        if (isDeleting) return;
        setIsDeleting(true);
        deleteInterest(id)((response) => {
            if (response && response?.status) {
                toast.success('Successfully deleted');
                getListData();
            } else {
                toast.error('Deletion failed');
            }
            setIsDeleting(false);
            setIsSwal({ show: false, id: null });
        });
    };

    return (
        <div className='member-stats-section interests-section'>
            {showSweetAlert && (
    <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes"
        cancelBtnText="Cancel"
        confirmBtnCssClass="custom-confirm-button-class"
        cancelBtnCssClass="custom-cancel-button-class"
        title={
            <>
                <span className="custom-sweetalert-title">Change Status</span>
                <div className='text-center' style={{ fontSize: "12px", fontWeight: "normal", lineHeight: 2 }}>
                    Are you sure you want to change the status?
                </div>
            </>
        }
        onConfirm={confirmToggle}
        onCancel={() => setShowSweetAlert(false)}
        focusCancelBtn
    />
)}

            <SweetAlertModal showSweetAlert={isSwal.show} setShowSweetAlert={(show) => {
                if (show) handleDelete(isSwal.id);
                else setIsSwal({ ...isSwal, show: false });
            }} />

            <div className='page-details'>
                <div className='top-section d-flex align-items-center justify-content-between'>
                    <div className='left'>Interests</div>
                    <div className='right d-flex align-items-center  gap-3' >
                        <div className='search-box'>
                            <input
                                type='text'
                                placeholder='Search ...'
                                className='form-control'
                                value={searchText}
                                onChange={(e) => handleSearch(e.target.value)}
                            />

                            {searchText && (
                                <FaTimes className='clear-icon' onClick={clearSearch} />
                            )}
                        </div>                        
                        {/* <div className='filter-box d-flex align-items-center  gap-3 cursor-pointer' onClick={() => setShowDrawer(true)}>
                            <FilterIcon />
                            <span>Filter</span>
                        </div>                         */}
                            <div className='add-clubs-info cursor-pointer' onClick={() => navigate(CREATE_INTERESTS, { state: { currentPageName: "Add Interests" } })} title="Create">
                                <AddIcon />
                            </div>
                    </div>
                </div>

                <div className='table-box mt-3'>
                    <DataTable
                        columns={columns}
                        data={data}
                        customStyles={customStyles}
                        fixedHeader
                        highlightOnHover
                        responsive
                        pagination
                        paginationServer
                        paginationPerPage={limit}
                        progressPending={pending}
                        onChangeRowsPerPage={setLimit}
                        paginationTotalRows={totalRows}
                        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                        onChangePage={setPage}
                    />
                </div>
            </div>
            <Drawer
                anchor={'right'}
                open={showDrawer}
                onClose={() => setShowDrawer(false)}
                className='member-stats-drawer'
                sx={{
                    '& .MuiPaper-root': {
                        padding: '30px 19px',
                        height: "100vh",
                        // overflow: "hidden"
                    },
                    '& .MuiDrawer-paper': {
                        width: '420px'
                    },
                    '& .MuiBox-root': {
                        width: '420px'
                    },
                    '& .MuiList-root': {
                        display: 'flex',
                        columnGap: '10px',
                        width: '90%',
                        margin: 'auto',
                        paddingTop: "25px"
                    },
                    '& .MuiButtonBase-root': {
                        padding: '7px',
                        textAlign: 'center',
                        alignItems: 'center',
                        borderRadius: '10px',
                        backgroundColor: '#F5F5F5',
                    }
                }}
            >
                <div className="drawer-top-box">
                    <div>Filter</div>
                    <button type="button" class="btn-close" aria-label="Close" onClick={() => setShowDrawer(false)}></button>
                </div>
                <div className="drawer-bottom-box mt-4">
                    <Form onSubmit={(e) => e.preventDefault()}>
                        {/* Name */}

                        {/* Status */}
                        <div className='item'>
                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em5">
                                <Form.Label>Status</Form.Label>
                                <div className='input-border'>
                                    <Select
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            colors: {
                                                ...theme.colors,
                                            },
                                        })}
                                        placeholder="Select"
                                        options={[{ label: "Active", value: "true" }, { label: "Inactive", value: "false" }]}
                                        isSearchable={false}
                                        isClearable={true}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className='submit-box d-flex align-items-end justify-content-end gap-3' style={{ height: "60vh" }}>
                            <div className='btn-type-2 cursor-pointer'>Clear</div>
                            <div className='btn-type-1' type="submit">Submit</div>
                        </div>
                    </Form>
                </div>

            </Drawer>
        </div>
    )
}

export default Interests