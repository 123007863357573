import React from 'react'
import { Button, Row, Col, Card, Form } from "react-bootstrap";

const PackageInclusions = ({ setValue }) => {
    return (
        <div>
            <div>
                <h3 className="mb-3" style={{ fontSize: "20px", fontWeight: "400", lineHeight: "25px" }}>
                    Package Inclusions
                </h3>

                {/* Benefits Section */}
                <Form
                    style={{
                        border: "1px solid #0673dc",
                        padding: "20px",
                        borderRadius: "5px",
                        background: "transparent",
                        minHeight: "85vh",
                    }}
                >
                    <h5 className="text-uppercase" style={{fontSize: "15px", fontWeight: "500" }}>
                        Primary Benefits:
                    </h5>

                    <div className="p-2">

                        <Row className="mb-4 align-items-center">
                            <Col xs={12} md={2} className="text-center mb-3 mb-md-0">

                                <div className="hexagonpcn">
                                    <h5>
                                    Access Platinum Clubs® Worldwide 
                                    </h5>
                                </div>
                            </Col>
                            <Col xs={12} md={10}>
                                <h5 className="text-uppercase" style={{ fontSize: "16px", fontWeight: "400", lineHeight: "30px" }}>
                                Access Platinum Clubs® Worldwide 
                                </h5>
                                <p>
                                Members may request access to club facilities of Platinum Clubs® of America, Europe, Asia-Pacific, and the World from anywhere, anytime via the PlatinumClubNet mobile app and/or web portal.
                                </p>
                            </Col>
                        </Row>

                        <Row className="mb-4 align-items-center">
                            <Col xs={12} md={2} className="text-center mb-3 mb-md-0">
                                <div className="hexagonpcn">
                                    <h5>Access PrivateClubWorld </h5>
                                </div>
                            </Col>
                            <Col xs={12} md={10}>
                                <h5 className="text-uppercase" style={{ fontSize: "16px", fontWeight: "400", lineHeight: "30px" }}>
                                    Access PrivateClubWorld 
                                </h5>
                                <p>Members may request access to a wide network of private golf, country, city, athletic, and yacht clubs spread across 900 destinations worldwide.</p>
                            </Col>
                        </Row>
                        <Row className="mb-4 align-items-center">
                            <Col xs={12} md={2} className="text-center mb-3 mb-md-0">
                                <div className="hexagonpcn">
                                    <h5>Network with Members worldwide </h5>
                                </div>
                            </Col>
                            <Col xs={12} md={10}>
                                <h5 className="text-uppercase" style={{ fontSize: "16px", fontWeight: "400", lineHeight: "30px" }}>
                                    Network with Members worldwide 
                                </h5>
                                <p>Members can discreetly network with other Platinum Club members worldwide managed by their preferences.</p>
                            </Col>
                        </Row>
                        <Row className="mb-4 align-items-center">
                            <Col xs={12} md={2} className="text-center mb-3 mb-md-0">
                                <div className="hexagonpcn">
                                    <h5> Explore Venues to Host Private Events </h5>
                                </div>
                            </Col>
                            <Col xs={12} md={10}>
                                <h5 className="text-uppercase" style={{ fontSize: "16px", fontWeight: "400", lineHeight: "30px" }}>
                                Explore Venues to Host Private Events 
                                </h5>
                                <p> Members may explore club facilities/ venues to host private events celebrating special occasions in the comfort and safety of a private club, worldwide.</p>
                            </Col>
                        </Row>

                        {/* Pricing Section */}
                        {/* <h5 className="text-uppercase mt-4 mb-2" style={{  fontSize: "15px", fontWeight: "500" }}>
                            Optional Services:
                        </h5>

                        <Card className="mb-3">

                            <Card.Body>
                                <p>These services are optional and can be activated any time through your PlatinumClubNet™ account on enrolling for the Global Private Club Citizenship member benefits program.</p>
                                <div className='d-flex justify-content-between mt-3'>
                                    <div className="hexagonpcn">
                                        <h5>CURATE PROGRAM</h5>
                                    </div>
                                    <div className="hexagonpcn">
                                        <h5>SPONSOR  PROGRAM</h5>

                                    </div>
                                    <div className="hexagonpcn">
                                        <h5>PROMOTE PROGRAM</h5>

                                    </div>
                                    <div className="hexagonpcn">
                                        <h5>PRIVATE EVENTS PROGRAM</h5>
                                    </div>
                                </div>

                            </Card.Body>
                        </Card> */}
                        <div className='d-flex justify-content-evenly mt-5 gap-4'>
                            <Card className="mb-3" style={{ width: "30%" }}>
                                <Card.Header className="bg-dark text-light">PRICING</Card.Header>
                                <Card.Body>
                                    <p>There are no license fees payable to register</p>
                                </Card.Body>
                            </Card>

                            <Card className="mb-3" style={{ width: "65%" }}>
                                <Card.Header className="bg-dark text-light">WHAT YOU GET WHEN YOU LICENSE</Card.Header>
                                <Card.Body>
                                    <ol>
                                        <li>PlatinumClubNet™ CMS</li>
                                        <li>PlatinumClubNet™ Mobile App / Web Portal for Members</li>
                                        <li>PlatinumClubNet™ Staff Function App for Club Employees</li>
                                        <li>Customer Support through a 24*7 Concierge service.</li>
                                    </ol>
                                </Card.Body>
                            </Card>
                        </div>


<Row className="mt-3">
          <Col xs={12} className="text-center text-md-end">
          <Button
                                variant="dark"
                                style={{
                                    backgroundColor: "#0673dc",
                                    color: "white",
                                    border: "none",
                                    padding: "10px 20px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    fontSize: "16px",
                                }}
                                onClick={() => setValue(1)}
                            >
                                Next
                            </Button>
          </Col>
        </Row>
                        {/* Navigation Buttons */}
                     
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default PackageInclusions
