import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { listClub } from '../../../api/actions/club';
import image from "../../../assets/images/common/img1.png";
import { MOBICOM_WEB } from '../../../constants/pathname';
import { useNavigate } from 'react-router-dom';
import zIndex from '@mui/material/styles/zIndex';

const PlatinumClubNetadd = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]); 
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [isDeleting, setIsDeleting] = useState(false);
  const [limit, setLimit] = useState(25);
  const [search, setSearch] = useState('');
  const [update, setUpdate] = useState(false);
  const [pending, setPending] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);

  const regions = [
    { label: "Platinum Clubs of America", value: "Platinum Clubs of America" },
    { label: "Platinum Clubs of the World", value: "Platinum Clubs of the World" },
    { label: "Platinum Clubs of Europe and Middle East", value: "Platinum Clubs of Europe and Middle East" },
    { label: "Platinum Clubs of Asia-Pacific", value: "Platinum Clubs of Asia-Pacific" },
  ];

  const getListData = () => {
    setPending(true);
    listClub({ page, limit, search })((response) => {
      if (response && response.status) {
        setData(response.data.results);  
        setTotalRows(response.data.count);
        setPending(false);
      }
    });
  };

  useEffect(() => {
    getListData(); 
  }, [update, page, search]); 

  const onDragStart = (e, clubIndex) => {
    e.dataTransfer.setData('clubIndex', clubIndex);
  };

  const onDrop = (e, targetIndex) => {
    const draggedIndex = parseInt(e.dataTransfer.getData('clubIndex'), 10);
    const updatedClubs = [...data];
    const [draggedClub] = updatedClubs.splice(draggedIndex, 1);
    updatedClubs.splice(targetIndex, 0, draggedClub);
    setData(updatedClubs);
  };

  const allowDrop = (e) => {
    e.preventDefault();
  };

  const getClubStyle = (index) => {
    return {
      border: '1px solid #ccc',
      padding: '10px',
      backgroundColor: '#fff',
      cursor: 'move',
      marginBottom: '10px',
    };
  };


  const customSelectStyles = {
    control: (base) => ({
      ...base,
      width: '250px',
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999, 
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: '#ccc',
      color: '#000',
      padding: '5px',
      borderRadius: '12px',
    }),
    multiValueLabel: (base) => ({
      ...base,
      fontSize: '14px',
      fontWeight: 'bold',
    }),
  };
  


  const customMultiValue = ({ data }) => (
    <div className="custom-multivalue">
      {data.label} ({data.count})
    </div>
  );

  return (
    <div className="platinum-club-netadd">
      <div className="filter-container">
        <label htmlFor="regionFilter">Filter by Region: </label>
        <Select
          id="regionFilter"
          options={regions}
          value={selectedRegion}
          onChange={(selectedOption) => setSelectedRegion(selectedOption)}
          placeholder="Select a region"
          isClearable
          
          styles={customSelectStyles} 
          closeMenuOnSelect={false}  
        />
      </div>

      <div className="club-list">
        {pending ? (
          <p>Loading clubs...</p>
        ) : (
          data.map((club, index) => (
            <div
              key={club.id}
              className="club-card"
              draggable
              onDragStart={(e) => onDragStart(e, index)}
              onDrop={(e) => onDrop(e, index)}
              onDragOver={allowDrop}
              style={getClubStyle(index)}
            >
              <div className="club-number">{index + 1}</div> {/* Display the club number */}
              <img
                src={club.image || image}
                alt={club.club_name}
                className="club-image"
              />
              <h3>{club.club_name}</h3>
              <p>{club.club_type}</p>
            </div>
          ))
        )}
      </div>

      <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-4'>
        <button className='btn-type-2 cursor-pointer' onClick={() => navigate(MOBICOM_WEB)}>Cancel</button>
        <button className='btn-type-1' type="submit">Submit</button>
      </div>
    </div>
  );
};

export default PlatinumClubNetadd;
