export const HOME = '/';

export const LOGIN = '/login';

export const TERMS_AND_CONDITION='/terms-and-condtion';

export const PRIVACY_POLICYS="/privacy-policy";

export const POLICY_UPDATE = "/policy-updates";

export const CANCELLATION = '/cancellation';

export const TERMS_OF_USAGE = "/terms-of-usage";

export const FORGOT_PASSOWORD = '/forgot-password';

export const RESET_PASSWORD = '/reset-password';

export const UPDATE_PASSWORD = '/update-password';

export const REGISTER_PCW='/register-pcw';

export const  REGISTER_PCN='/register-pcn';

export const VERIFY_EMAIL = '/verify-email';

export const MEMBER_STATS = '/member-stats';

export const MANAGE_MEMBERS = '/manage-members';

export const MANAGE_ADMINS = '/manage-admins';

export const CREATE_ADMIN = '/create-admin';

export const SYNC_CONTACT = '/sync-contact';

export const CLUBS_INFORMATION = '/clubs-information';

export const MANAGE_NETWORK_CLUBS = '/manage-network-clubs';

export const GPCA_OVERVIEW = '/gpca-overview';

export const CLUB_PROFILE = '/club-profile';

export const CLUB_PROFIL = '/club-profile/'

export const CREATE_CLUB_PROFILE = '/create-club-profile';

export const LETTER_OF_INTRODUCTION = '/letter-of-introduction';

export const CREATE_LETTER_OF_INTRODUCTION = '/create-letter-of-introduction';

export const CREATE_CLUBS_INFORMATION = '/create-clubs-information';

export const EDIT_CLUB_INFORMATIONS='/edit-clubinformation/'

export const VIEW_CLUBS_INFORMATION = '/view-club-information';

export const SOLITAIRE_NETWORK = '/solitaire-network';

export const CREATE_SOLITAIRE_NETWORK = '/create-solitaire-network';

export const CLUBS_FACILITIES = '/clubs-facilities';

export const CREATE_CLUBS_FACILITY = '/create-clubs-facility';

export const FACILITIES_CATEGORY = '/facilities-category';

export const CREATE_FACILITIES_CATEGORY = '/create-facilities-category';

export const EDIT_FACILITIES_CATEGORY = '/edit-facitilties-category/';

export const CURATED_SPONSORSHIP = '/curated-sponsorship';

export const CREATE_CURATED_SPONSORSHIP = '/create-curated-sponsorship';

export const CURATED_MEMBER_EVENTS = '/curated-member-events';

export const CREATE_CURATED_MEMBER_EVENTS = '/create-curated-member-events';

export const PRIVATE_EVENTS = '/private-events';

export const CREATE_PRIVATE_EVENTS = '/create-private-events';

export const SURVEY_QUESTIONER = '/survey-questioner';

export const VIEW_SURVEY_QUESTIONER = '/view-survey-questioner';

export const CREATE_SURVEY_QUESTIONER = '/create-survey-questioner';

export const CLUBS_PROMOTIONS = '/clubs-promotions';

export const CREATE_CLUBS_PROMOTIONS = '/create-clubs-promotions';

export const BRANDS = '/brands';

export const CREATE_BRANDS = '/create-brands';

export const EDIT_BRANDS='/edit-brands/';

export const CORPORATE = '/corporate';

export const CREATE_CORPORATE = '/create-corporate';

export const SPECIAL_SUBSCRIBERS = '/special-subscribers';

export const CREATE_SPECIAL_SUBSCRIBERS = '/create-special-subscribers';

export const VIEW_MEMBER_INFO='/view-special-subscribers/';

export const BOOKINGS = '/bookings';

export const VIEW_BOOKINGS = '/view-bookings';

export const BESPOKE_BOOKINGS = '/bespoke-bookings';

export const RECIPROCAL_REQUESTS = '/reciprocal-requests';

export const VIEW_RECIPROCAL_REQUESTS = '/view-reciprocal-requests';

export const INTERESTS = '/interests';

export const CREATE_INTERESTS = '/create-interest';

export const EDIT_INTEREST='/edit-interest/'

export const PLACES = '/places';

export const CREATE_PLACES = '/create-places';

export const EDIT_PLACES='/edit-places/'

export const PUSH_NOTIFICATIONS = '/push-notifications';

export const PUSH_NOTIFICATIONS_CATEGORY = '/push-notifications-category';

export const CREATE_PUSH_NOTIFICATIONS = '/create-push-notifications';

export const CREATE_PUSH_NOTIFICATIONS_CATEGORY = '/create-push-notifications-category';

export const OFFER = '/offer';

export const BLACKOUT_DATES = '/blackout-dates';

export const CREATE_OFFER_NOTIFICATION = '/create-offer-notification';

export const CLUB_OFFER_ADD = '/club-offer-add';

export const NOTIFICATIONS = '/notifications';

export const NOTIFICATION_DETAIL = '/notifications/detail';

export const ADD_BRAND_CATEGORY='/add-brand-category';

export const LIST_BRAND_CATEGORY='/list-brand-category';

export const EDIT_BRAND_CATEGORY='/edit-brand-category/';

export const CLUB_ONBOARDIN='/club-onboarding'

export const EDIT_CLUB_ONBOARDING='/edit-club-onboarding/'

export const CLUB_ONBOARDING_LIST='/club-onboarding-list'

export const USER_MANAGEMENT='/user-management/roles'

export const ADD_ROLE='/user-management/add-role';

export const EDIT_ROLE='/user-management/edit-role';

export const GROUPS='/user-management/groups';

export const ADD_GROUP='/user-management/add-group';

export const EDIT_GROUP='/user-management/edit-group';

export const USERS='/user-management/user';

export const ADD_USER='/user-management/add-user';

export const EDIT_USER='/user-management/edit-user';

export const VIEW_CLUINFO='/view-club-info/'

export const CLUB_PROFILES='/club-profiles/'

export const AFFILIATED_CLUBS='/affiliated-clubs/'

export const ADD_AFFILIATED_CLUBS='/add-affiliated-clubs'
// mobiocmweb
export const MOBICOM_WEB='/mobicomweb'
export const PLATINUMCLUBNET='/platinum-club-net'
export const CLUBLISTING='/club-listing'
export const ADD_CLUB='/add-club'
export const EDIT_CLUB = '/edit-club/'
export const PLATINUMCLUBNETADD='/platinum-club-ordering'

// Curated Member Events
export const CURATION_PROFILE='/curated-member-events/curation-profile'
export const CURATED_STAUS='/curated-member-events/status'
// Current Club Events
export const CURRENT_CLUB_EVENTS='/current-club-events/club-events'
export const CLUB_EVENTS_PROFILE='/current-club-events/club-events-profile'
export const CLUB_EVENTS_STATUS='/current-club-events/status'
// Private Events
export const PRIVATE_EVENTS_PROFILE='/private-events/profile'
export const PRIVATE_EVENTS_STATUS='/private-events/status'
// Promotions
export const PROMOTIONS='/promotions'
export const WELCOME='/welcome'
export const SUBSCRIPTION_PRIVILEGES = '/subscription-privileges'
export const ADD_SUBSCRIPTION_PRIVILEGES = '/add-subscription-privileges'
export const EDIT_SUBSCRIPTION_PRIVILEGES = '/edit-subscription-privileges'
// Memeber Revies
export const MEMBERREVIEWLIST='/member-review-list'
export const VIEW_MEMBER_REVIEW='/view-member-review/'

// Club type
export const CLUB_TYPE_LIST='/club-type-list'
export const ADD_CLUB_TYPE='/add-club-type'
export const EDIT_CLUB_TYPE='/edit-club-type/'

//subscriptiontype
export const ADD_SUBSCRIPTIONTYPE='/add-subscription-type'
export const EDIT_SUBSCRIPTIONTYPE='/edit-subscription-type/'
export const SUBSCRIPTIONTYPELIST='/subscription-type-list'

//walkthrough document
export const WALKTHROUGH_DOCUMENT='/club/walk-through-document/download-walk-through-document'

export const USER_SUBSCRIPTION_LIST='user-subscription-list'