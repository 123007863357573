import { Row, Col, Form, Button, Modal } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { ADD_USER, BOOKINGS, CLUB_PROFILES } from "../../constants/pathname"
import { useEffect, useState } from 'react';
import { addloiadmin, addNotes, addStripe, adminBookingAccept, adminBookingCancel, adminBookingDeclined, adminBookingDeclinedMemberModified, adminSendMessageBooking, assignAdminForRequest, clubAdminBookingUpdate, getMember, getMemberRequestBookingDetails, listBookingMessages, paymentConfirmed, sendmemberVerification } from '../../api/actions/bookings';
import Sweetalert from './Sweetalert';
import ViewCal from '../../assets/images/common/view_cal.svg';
import ViewCal2 from '../../assets/images/common/view_cal2.svg'
import dayjs from 'dayjs';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Select from "react-select"
import AsyncSelect from 'react-select/async';
import * as Yup from "yup";
import { ErrorMessage, useFormik } from 'formik';
import AddClubBankModal from '../../components/MemberRequest/addClubBankDetails';
import ModifyBookingModal from '../../components/MemberRequest/modifyBookingModal';
import toast from 'react-hot-toast';
import { checkTheme, checkThemePcw } from '../../utils/utils';
import SweetAlert from 'react-bootstrap-sweetalert';
import DefaultImage from "../../assets/images/common/img1.png"
import { ReactComponent as AddIcon } from "../../assets/images/common/add-icon.svg";
import AddUserModal from '../../components/MemberRequest/addUserModal';


const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid var(--light-grayish)',
    outline: 'none',
    boxShadow: 'none',
    color: 'var(--medium-gray)',
    fontSize: '15px',
    '&:hover': {
      border: '1px solid var(--light-grayish)',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--medium-gray)',
    fontSize: '15px',
    paddingLeft: "5px"
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'var(--medium-gray)',
    fontSize: '15px',
  }),
  menu: (provided) => ({
    ...provided,
    border: '1px solid var(--light-grayish)',
    boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? 'var(--light-grayish)' : 'white',
    color: 'var(--medium-gray)',
    fontSize: '15px',
  }),
};

const selectStyle = {
  control: (styles, { isFocused }) => ({
    ...styles,
    borderRadius: '5px',
    maxHeight: "40px",
    minHeight: "30px",
    width: "200px",
    boxShadow: 'none',
    fontSize: "15px",
    backgroundColor: 'white',
    borderColor: isFocused ? 'gray' : styles.borderColor,
  }),
  placeholder: (styles) => ({
    ...styles,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "15px",
    paddingLeft: "5px"
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    width: "200px",
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? 'lightgray' : 'white',
    color: 'black',
    width: "200px",
  }),
};

const ViewMemberRequest = () => {

  const navigate = useNavigate();
  const { id } = useParams();
  const [bookingDetails, setBookingDetails] = useState(null);
  const [showSweetAlert, setShowSweetAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [cancelationModal, setCancelationModal] = useState(false)
  const [update, setUpdate] = useState(false)
  const [openBankModal, setOpenBankModal] = useState(false);
  const [openModifyBooking, setOpenModifyBooking] = useState(false)
  const [pending, setPending] = useState(false)
  const [updateMessage, setUpdateMessage] = useState(false)
  const [openUserModal, setOpenUserModal] = useState(false)
  const [updateUser, setUpdateUser] = useState(false);
  const [isOpenEight, setIsOpenEight] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenOne, setIsOpenOne] = useState(true);
  const [isOpenTwo, setIsOpenTwo] = useState(true);
  const [isOpenThree, setIsOpenThree] = useState(true);
  const [isOpenFour, setIsOpenFour] = useState(true);
  const [isOpenFive, setIsOpenFive] = useState(true);
  const [isOpenSix, setIsOpenSix] = useState(true);
  const [isOpenSeven, setIsOpenSeven] = useState(true);
  const [advancePayment, setAdvancePayment] = useState(false);
  const [member, setMember] = useState([])
  const [selectedClub, setSelectedClub] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [pendingSelection, setPendingSelection] = useState(null);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [showAlertAdmin, setShowAlertAdmin] = useState(false);
  const [pendingSelectionAdmin, setPendingSelectionAdmin] = useState([]);
  const [actionType, setActionType] = useState(null);
  const [selectedCards, setSelectedCards] = useState([]);
  const [notes, setNotes] = useState('')
  const [showModalNotes, setShowModalNotes] = useState(false);
  const [links, setLinks] = useState('')
  const [showModalLink, setShowModalLink] = useState(false);
  const [messaging, setMessaging] = useState(false);

  let userInfo = JSON.parse(localStorage.getItem("user_info"));


  const [messages, setMessages] = useState([
  ]);
  const [newMessage, setNewMessage] = useState("");


  const handleSendMessage = () => {
    if (!newMessage.trim()) return;

    // setMessages([...messages, { text: newMessage, sender: "user" }]);
    setMessaging(true);
    adminSendMessageBooking({
      booking_id: bookingDetails?.id,
      country: bookingDetails?.country,
      message: newMessage
    })((res) => {
      if (res.status_code == 200 || res?.status_code == 201) {
        setMessaging(false);
        setUpdateMessage(!updateMessage)
        setNewMessage("");
      }
    })
  };

  useEffect(() => {
    const autoSelectedCards = bookingDetails?.host_booking_details
      ?.filter(member => member?.admin_approved)
      ?.map(member => member.id) || [];

    setSelectedCards(prevSelected => {
      const newSelections = [...new Set([...prevSelected, ...autoSelectedCards])];
      return newSelections;
    });
  }, [bookingDetails]);

  const validationSchemaCancelBooking = Yup.object({
    cancel_type: Yup.string().required("Please select a cancellation type"),
    cancel_reason: Yup.string().required("Cancellation reason is required"),
  });

  const formik = useFormik({
    initialValues: {
      id: '',
      country: '',
      advance_payment: '',
      additional_charges: '',
      total: '',
      host_ids: []
    },
    onSubmit: (values, { resetForm }) => {

      const processedValues = {
        id: values.id || null,
        country: values.country || null,
        advance_payment: values.advance_payment || 0,
        additional_charges: values.additional_charges || 0,
        total: values.total || 0,
        host_ids: values.host_ids
      };

      setPending(true);

      if (actionType === 'accept') {
        adminBookingAccept(processedValues)((res) => {
          if (res?.status_code === 200 || res?.status_code === 201) {
            setPending(false);
            resetForm();
            setUpdate(!update);
            toast.success("Booking Accepted");
          }
          setPending(false);
        });
      } else if (actionType === 'update') {
        clubAdminBookingUpdate(processedValues)((res) => {
          if (res?.status_code === 200 || res?.status_code === 201) {
            setPending(false);
            resetForm();
            setUpdate(!update);
            toast.success("Booking Updated Successfully");
          }
          setPending(false);
        });
      }

      setActionType(null);
    }
  });



  const handleButtonClick = (action) => {
    setActionType(action);
    formik.handleSubmit();
  };

  const toggleSelection = (id) => {
    setSelectedCards((prevSelected) => {
      const newSelections = prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id];

      formik.setFieldValue("host_ids", newSelections);

      return newSelections;
    });
  };


  useEffect(() => {
    const advancePayment = parseFloat(formik.values.advance_payment) || 0;
    const additionalCharges = parseFloat(formik.values.additional_charges) || 0;
    const total = advancePayment + additionalCharges;

    formik.setFieldValue("total", total);
  }, [formik.values.advance_payment, formik.values.additional_charges]);


  const cancelFormik = useFormik({
    initialValues: {
      id: '',
      cancel_type: "Access Cancellation",
      cancel_reason: "",
      country: ''
    },
    validationSchema: validationSchemaCancelBooking,
    onSubmit: (values, { resetForm }) => {
      adminBookingCancel(values)((res) => {
        if (res?.status_code === 200 || res?.status_code === 201) {
          setUpdate(!update)
          resetForm()
          setCancelationModal(!cancelationModal)
        }
      })
    },
  })


  useEffect(() => {
    fetchBookingDetails();
  }, [id, update]);

  useEffect(() => {
    fetchMessagesList();
    if (bookingDetails) {
      cancelFormik.setValues({
        ...cancelFormik.values,
        id: bookingDetails.id,
        country: bookingDetails.country
      });
      formik.setValues({
        ...formik.values,
        id: bookingDetails.id,
        country: bookingDetails.country,
        advance_payment: bookingDetails.advance_payment,
        additional_charges: bookingDetails.additional_charges,
        total: bookingDetails.total
      });
      if (bookingDetails.status === "Booked") {
        setAdvancePayment(true);
      }
    }
  }, [bookingDetails, updateMessage])


  useEffect(() => {
    getMember({ page: 1, limit: 20 })((res) => {
      if (res?.status_code === 200 || res?.status_code === 201) {
        setMember(res?.data)
      }
    })
  }, [updateUser])

  useEffect(() => {
    if (bookingDetails?.assigned_users) {
      const formattedUsers = bookingDetails.assigned_users.map((user) => ({
        label: user.name,
        value: user.id,
      }));
      setSelectedMembers(formattedUsers);
    }
  }, [bookingDetails]);


  const handleChange = (selectedOption) => {
    if (!selectedOption) {
      setSelectedClub(null);
      return;
    }

    setPendingSelection(selectedOption);
    setShowAlert(true);
  };

  const handleConfirm = () => {
    setShowAlert(false);
    setSelectedClub(pendingSelection);

    sendmemberVerification({ booking: bookingDetails?.id, country: bookingDetails?.country, home_club: pendingSelection?.value, home_club_country: pendingSelection?.country })((res) => {
      if (res?.status) {
        setPendingSelection(null);
        setUpdate(!update)
      }
    })
  }

  const handleCancel = () => {
    setShowAlert(false);
    setPendingSelection(null);
  };

  const handleChangeAdmin = (newSelection) => {
    setSelectedMembers(newSelection || []);
  };

  const handleAssignClick = () => {
    if (selectedMembers.length === 0) {
      toast.error("Please select at least one member.");
      return;
    }
    setPendingSelectionAdmin(selectedMembers);
    setShowAlertAdmin(true);
  };

  const handleConfirmAdmin = () => {
    setShowAlertAdmin(false);

    assignAdminForRequest({ booking_id: bookingDetails?.id, country: bookingDetails?.country, assigned_users: pendingSelectionAdmin.map((member) => member.value) })((res) => {
      if (res?.status) {
        console.log(res)
        setUpdate(!update)
      }
    })
  };



  let typingTimer;

  const loadOptionsMember = (inputValue) => {
    return new Promise((resolve) => {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        if (inputValue.length >= 3) {
          getMember({ page: 1, limit: 20, search_param: inputValue })((response) => {
            if (response?.status_code === 200) {
              const options = response?.data?.results || [];
              setMember(options);
              resolve(options);
            } else {
              resolve([]);
            }
          });
        } else {
          resolve([]);
        }
      }, 300);
    });
  };

  const handleCancelAdmin = () => {
    setShowAlertAdmin(false);
  };


  const fetchBookingDetails = () => {
    if (id) {
      getMemberRequestBookingDetails({ id })((response) => {
        if (response.status) {
          setBookingDetails(response.data);
        }
      });
    }
  };

  const fetchMessagesList = () => {
    if (bookingDetails) (
      listBookingMessages({
        id: bookingDetails?.id, country: bookingDetails?.country,
        // pcn: !checkThemePcw() 
      })((res) => {
        if (res?.status) {
          // console.log(res);
          setMessages(res?.data?.results)
        }
      })
    )

  }

  if (!bookingDetails) return <div>Loading...</div>;


  const clubDetails = bookingDetails?.club_details || {};
  const letterOfIntroduction = bookingDetails?.letter_of_introduction || false;
  const letterOfIntroductionPDF = bookingDetails?.letter_of_introduction_pdf || '';
  const adminRequestLetter = bookingDetails?.admin_request_letter || false;
  // Conditions to hide both buttons
  const shouldHideButtons =
    clubDetails?.gpcc === true &&
    clubDetails?.is_reciprocal_club === false &&
    clubDetails?.solitaire_club === false;

  const handleClubClick = () => {
    const clubId = bookingDetails?.club_details?.club;
    const country = bookingDetails?.club_details?.country;

    if (clubId && country) {
      navigate(`${CLUB_PROFILES}${clubId}`, {
        state: { country }
      });
    }
  };


  const handleRequestLoi = () => {
    if (!bookingDetails?.id || !bookingDetails?.country) return;
    addloiadmin({ values: { id: bookingDetails.id, country: bookingDetails.country } })((response) => {
      if (response.status) {
      } else {
      }
      setShowSweetAlert(false);
      fetchBookingDetails();
    });
  };

  const handleDecline = () => {
    if (bookingDetails) {
      if (bookingDetails?.status === "Member Modified") {
        adminBookingDeclinedMemberModified({
          id: bookingDetails.id,
          country: bookingDetails.country
        })((res) => {
          if (res?.status_code === 200 || res?.status_code === 201) {
            setUpdate(!update)
            toast.success("Booking Declined")
          }
        })
      } else {
        adminBookingDeclined({
          id: bookingDetails.id,
          country: bookingDetails.country
        })((res) => {
          if (res?.status_code === 200 || res?.status_code === 201) {
            setUpdate(!update)
            toast.success("Booking Declined")
          }
        })
      }
    }
  }

  const handleSendNotes = () => {
    if (!notes.trim()) return;


    addNotes({
      id: bookingDetails?.id,
      country: bookingDetails?.country,
      note_text: notes
    })((res) => {
      if (res.status_code == 200 || res?.status_code == 201) {
        setNotes("");
        setShowModalNotes(!showModalNotes)
        setUpdate(!update)
      }
    })
  };

  const handleAddLink = () => {
    if (!links.trim()) return;

    const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;

    if (!urlPattern.test(links)) {
      toast.error("Please enter a valid URL");
      return;
    }

    addStripe({
      id: bookingDetails?.id,
      country: bookingDetails?.country,
      link: links
    })((res) => {
      if (res.status_code === 200 || res?.status_code === 201) {
        setLinks("");
        setShowModalLink(!showModalLink);
        setUpdate(!update);
      }
    });
  };


  const handlePaymentConfirm=()=>{
    paymentConfirmed({id:bookingDetails?.id,country:bookingDetails?.country})((response)=>{
      if(response.status_code===200||response?.status_code===201){
        toast.success("Payment confirmed");
        setUpdate(!update);
      }
    })
  }

  const isAnyAdminApproved = bookingDetails?.host_booking_details?.some(member => member.admin_approved);

  return (
    <div className='row create-clubs-info-section view-memebr-request'>
      <div className='col-md-3'>
        <div className='booking-overview1'>
          <div className='booking-overview-club'>
            <div className='img-wraper'>
              <img src={bookingDetails?.club_details?.main_image || DefaultImage} alt={bookingDetails?.club_details?.club_name} />
              <div className='tags-container'>
                {bookingDetails?.club_details?.solitaire_club && (
                  <div className='solitaire-tag tag'>Solitaire</div>
                )}
                {bookingDetails?.club_details?.is_reciprocal_club && (
                  <div className='reciprocal-tag tag'>Reciprocal</div>
                )}
              </div>
            </div>
            <div className='club-name-wraper'>
              <div className='club-name-sec'>
                <p className='club_name_title'>Club Name</p>
                <p className='club_name_main' onClick={handleClubClick}>{bookingDetails?.club_details?.club_name}</p>
              </div>
              {/* <div className='row'>
                <div className='col-md-6'>Reciprocal : {bookingDetails?.member_club[0]?.is_reciprocal ? "Yes" : "No"}</div>
                <div className='col-md-6'>GPCC : {bookingDetails?.member_club[0]?.gpcc ? "Yes" : "No"}</div>
              </div> */}
            </div>
          </div>
        </div>

        {/* booking details */}
        <div className='booking_stats'>
          <div className='row booking_stats_inside'>
            <div className='col-md-12 col-xl-3'>
              <img src={ViewCal} />
            </div>
            <div className='col-md-12 col-xl-9'>
              <p className='booking_date'>Booking Date</p>
              <div className='row date_view mt-3'>
                <div className='col-md-12 col-xl-6'>
                  <p style={{ fontSize: "14px" }}>{bookingDetails?.facility?.some(facility => facility.facility_category_name === "Accomodation")
                    ? "Check-in Date"
                    : "Start Date"}</p>
                  <p style={{ fontSize: "15px" }}>{dayjs(bookingDetails?.start_date).format('MMM D, YYYY')}</p>
                </div>
                <div className='col-md-12 col-xl-6'>
                  <p style={{ fontSize: "14px" }}>
                    {bookingDetails?.facility?.some(facility => facility.facility_category_name === "Accomodation")
                      ? "Check-out Date"
                      : "End Date"}
                  </p>
                  <p style={{ fontSize: "15px" }}>{dayjs(bookingDetails?.end_date).format('MMM D, YYYY')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='booking_divider'></div>
          <div className='row booking_status_inside'>
            <div className='col-md-12 col-xl-3'>
              <img src={ViewCal2} />
            </div>
            <div className='col-md-12 col-xl-9'>
              <p className='booking_date'>Status</p>
              <button type="button" className="btn-type-7" style={{ cursor: "default" }}>{bookingDetails?.status}</button>
            </div>
          </div>
        </div>

        {(userInfo?.is_superuser && bookingDetails?.note_text) &&
          <div className='notes_section'>
            <div className='head'>
              <p>Notes</p>
            </div>
            <div className='body'>
              {bookingDetails?.note_text}
            </div>
          </div>
        }

      </div>

      {/* main section  */}

      <div className='col-md-9'>
        <Sweetalert
          showSweetAlert={showSweetAlert}
          setShowSweetAlert={setShowSweetAlert}
          onConfirm={handleRequestLoi}
          content="Do you really want to request a Letter of Introduction?"
        />
        <Form onSubmit={(e) => e.preventDefault()}>
          <div className='page-details'>
            {/* Booking Details */}
            <div className='panel-type-1a' onClick={() => setIsOpen(!isOpen)}>
              Booking Details
              {isOpen ? <FaChevronUp className="accordion-icon" /> : <FaChevronDown className="accordion-icon" />}
            </div>
            <div className={`accordion-content ${isOpen ? "open" : ""}`}>
              <div className='grid mt-2'>
                {/* Member Name */}
                <div>
                  <Form.Group controlId='memberName'>
                    <Form.Label>Member Name</Form.Label>
                    <Form.Control
                      type='text'
                      value={bookingDetails.member?.member_name || "N/A"}
                      name='memberName'
                      disabled
                    />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group controlId='clubName'>
                    <Form.Label>Member Home Club</Form.Label>
                    <Form.Control
                      type='text'
                      value={bookingDetails.member_club[0]?.club || "N/A"}
                      name='clubName'
                      // style={{ color: '#c58f5a', cursor: 'pointer' }}

                      disabled
                    />
                  </Form.Group>
                </div>
                {/* Request Type */}
                <div>
                  <Form.Group controlId='requestType'>
                    <Form.Label>Request Type</Form.Label>
                    <p style={{ cursor: "not-allowed" }}>{bookingDetails.request_type || "N/A"}</p>
                    {/* <Form.Control
                      type='text'
                      value={bookingDetails.request_type || "N/A"}
                      name='requestType'
                      disabled
                    /> */}
                  </Form.Group>
                </div>

                {/* Booking ID */}
                <div>
                  <Form.Group controlId='bookingId'>
                    <Form.Label>Booking ID</Form.Label>
                    <Form.Control
                      type='text'
                      value={bookingDetails.booking_id || "N/A"}
                      name='bookingId'
                      disabled
                    />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group controlId="transactionId">
                    <Form.Label>Transaction ID</Form.Label>
                    <Form.Control
                      type="text"
                      // value={bookingDetails.}
                      name="transactionId"
                      disabled={true}
                    />
                  </Form.Group>
                </div>
                {/* No of Guests */}
                <div>
                  <Form.Group controlId="noOfGuests">
                    <Form.Label>No of Guests</Form.Label>
                    <Form.Control
                      type="text"
                      value={bookingDetails.guest_number || "0"}
                      name="noOfGuests"
                      disabled
                    />
                  </Form.Group>
                </div>

                {/* Requested On */}
                <div>
                  <Form.Group controlId="requestedOn">
                    <Form.Label>Requested On</Form.Label>
                    <Form.Control
                      type="text"
                      value={dayjs(bookingDetails.created_date).format('MMM D, YYYY') || "N/A"}
                      name="requestedOn"
                      disabled
                    />
                  </Form.Group>
                </div>


                {/* Arrival Time */}
                <div >
                  <Form.Group controlId="arrivalTime">
                    <Form.Label>Arrival Time</Form.Label>
                    <Form.Control
                      type="text"
                      value={bookingDetails.arrival_time || "N/A"}
                      name="arrivalTime"
                      disabled
                    />
                  </Form.Group>
                </div>
                {bookingDetails?.advance_payment > 0 || bookingDetails?.additional_charges > 0 ? (
                  bookingDetails?.transactionId && bookingDetails?.transactionId !== "None" ? (
                    <div>
                      <Form.Group controlId="additionalCharges">
                        <Form.Label>Additional Charges</Form.Label>
                        <Form.Control
                          type="text"
                          value={bookingDetails?.additional_charges || "0"}
                          name="additionalCharges"
                          disabled
                        />
                      </Form.Group>
                      <Form.Group controlId="totalPayment">
                        <Form.Label>Total Payment</Form.Label>
                        <Form.Control
                          type="text"
                          value={bookingDetails?.total || "0"}
                          name="totalPayment"
                          disabled
                        />
                      </Form.Group>
                    </div>
                  ) : null
                ) : null}

                {/* <div>
                  <Form.Group controlId="extraServices">
                    <Form.Label>Reciprocal</Form.Label>
                    <Form.Control
                      type="text"

                      value={bookingDetails?.member_club[0]?.is_reciprocal ? "Yes" : "No"}
                      disabled={true}
                    />
                  </Form.Group>
                </div> */}
                {/* <div>
                  <Form.Group controlId="extraServices">
                    <Form.Label>GPCC</Form.Label>
                    <Form.Control
                      type="text"

                      value={bookingDetails?.member_club[0]?.gpcc ? "Yes" : "No"}
                      disabled={true}
                    />
                  </Form.Group>
                </div> */}
                {/* <div>
                  <Form.Group controlId="extraServices">
                    <Form.Label>Extra Services Required</Form.Label>
                    <Form.Control
                      type="text"

                      value={bookingDetails.special_request ? "Yes" : "No"}
                      disabled={true}
                    />
                  </Form.Group>
                </div> */}
                {bookingDetails?.status === "Admin Cancelled" &&
                  <div>
                    <Form.Group controlId="cancell_type">
                      <Form.Label>Cancellation Type</Form.Label>
                      <Form.Control
                        type="text"

                        value={bookingDetails?.cancel_type}
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                }
                {bookingDetails?.status === "Admin Cancelled" &&
                  <div>
                    <Form.Group controlId="reason">
                      <Form.Label>Cancellation Reason</Form.Label>
                      <Form.Control
                        type="text"

                        value={bookingDetails?.cancellation_reason}
                        disabled={true}
                      />
                    </Form.Group>
                  </div>
                }
                {
                  bookingDetails?.link &&
                  <div>
                    <Form.Group controlId="reason">
                      <Form.Label>Payment Link</Form.Label>
                      <p style={{ cursor: "not-allowed" }}>{bookingDetails?.link || "N/A"}</p>
                      {/* <Form.Control
                        type="text"

                        value={bookingDetails?.link}
                        disabled={true}
                      /> */}
                    </Form.Group>
                  </div>
                }

              </div>
              {bookingDetails?.special_request && (
                <div className='row mt-5'>
                  <div>
                    <Form.Group controlId="specialRequest">
                      <Form.Label>Special Requests/Instructions</Form.Label>
                      <div dangerouslySetInnerHTML={{ __html: bookingDetails.special_request }}></div>
                    </Form.Group>
                  </div>
                </div>
              )}

            </div>

            {/* two */}
            {!userInfo?.is_superuser && (bookingDetails?.status === "Expired" || bookingDetails?.status === "Completed") ? (
              <></>
            ) :

              <>
                <div className='mt-2 panel-type-1a' onClick={() => setIsOpenTwo(!isOpenTwo)}>
                  Member Verification (Optional)
                  {isOpenTwo ? <FaChevronUp className="accordion-icon" /> : <FaChevronDown className="accordion-icon" />}
                </div>
                <div className={`accordion-content ${isOpenTwo ? "open" : ""}`}>
                  <div className='grid mt-1'>
                    {bookingDetails?.member_verification_status ? (
                      <>
                        <p className='text-center'>{bookingDetails?.member_verification_status}</p>
                      </>
                    ) : (
                      <>
                        <Form.Group controlId="clubs">
                          {/* <Form.Label>Member Verification</Form.Label> */}

                          <Select
                            placeholder="Select "
                            styles={customStyles}
                            options={bookingDetails?.member_club?.map((itm) => {
                              return {
                                label: itm.club,
                                value: itm.club_id
                              }
                            })}
                            onChange={handleChange}
                            isClearable
                          />
                        </Form.Group>
                      </>
                    )}

                  </div>
                </div>
              </>
            }

            {/* three */}

            {!userInfo?.is_superuser &&
              <>
                <div className='mt-2 panel-type-1a' onClick={() => setIsOpenThree(!isOpenThree)}>
                  Assign To
                  {isOpenThree ? <FaChevronUp className="accordion-icon" /> : <FaChevronDown className="accordion-icon" />}
                </div>
                <div className={`accordion-content ${isOpenThree ? "open" : ""}`}>
                  <div className='d-flex gap-3'>
                    <div className=''>
                      <Form.Group controlId="clubs">
                        <AsyncSelect
                          loadOptions={loadOptionsMember}
                          defaultOptions={member || []}
                          classNamePrefix="select"
                          isClearable={true}
                          isMulti
                          placeholder={'Select'}
                          value={selectedMembers}
                          onChange={handleChangeAdmin}
                          styles={{
                            control: (styles) => ({
                              ...styles, borderRadius: 'none', fontSize: '13px', minHeight: '41px', maxHeight: "auto", minWidth: "200px", maxWidth: "300px", border: '1px solid var(--light-grayish)', boxShadow: 'unset', borderRadius: "5px",
                              '&:hover': {
                                border: '1px solid var(--light-grayish)',
                              }
                            }),
                            valueContainer: (styles) => ({
                              ...styles, alignItems: 'center', padding: '3px 8px',

                            }),
                            indicatorSeparator: (styles) => ({

                              ...styles, marginBottom: '11px', marginTop: '6px'
                            }),
                            dropdownIndicator: (styles) => ({
                              ...styles, paddingTop: '2px'
                            }),
                            clearIndicator: (styles) => ({
                              ...styles, paddingTop: '2px'
                            }),
                            placeholder: (styles) => ({
                              ...styles, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", paddingLeft: "5px"
                            })
                          }}
                        />
                      </Form.Group>

                    </div>
                    <button variant="primary" className="btn-type-7 h-25" onClick={handleAssignClick}>
                      Assign Admin
                    </button>
                    <div className='add_user_booking'>
                      <AddIcon size={18} onClick={() => setOpenUserModal(true)} title="Add User" className="cursor-pointer" />
                    </div>
                  </div>
                  {showAlertAdmin && (
                    <SweetAlert
                      warning
                      showCancel
                      confirmBtnText="Yes, Confirm!"
                      cancelBtnText="No, Cancel"
                      confirmBtnStyle={{ backgroundColor: "var(--sandy-brown)", borderColor: "var(--sandy-brown)", color: "#fff", textDecoration: "none" }}
                      cancelBtnBsStyle={{ backgroundColor: "var(--white)", borderColor: "var(--white)", color: "#000", textDecoration: "none" }}
                      title="Are you sure?"
                      onConfirm={handleConfirmAdmin}
                      onCancel={handleCancelAdmin}
                    >
                      Are you sure you want to assign this booking to new admin?<br />
                    </SweetAlert>
                  )}
                </div>
              </>
            }
            {
              bookingDetails?.tee_time_request ? (
                <>
                  <div className='mt-2 panel-type-1a' onClick={() => setIsOpenOne(!isOpenOne)}>
                    Golf Booking Details
                    {isOpenOne ? <FaChevronUp className="accordion-icon" /> : <FaChevronDown className="accordion-icon" />}
                  </div>
                  <div className={`accordion-content ${isOpenOne ? "open" : ""}`}>
                    <div className='mt-2'>
                      {/* Request for Tee Time */}
                      <div className='d-flex mb-1'>
                        <Form.Group controlId='teeTime' className='d-flex'>
                          <Form.Label className='w-100'>Request for Tee Time :</Form.Label>
                          <Form.Control
                            type='text'
                            value={bookingDetails.tee_time_request ? "Yes" : "No"}
                            name='teeTime'
                            disabled
                          />
                        </Form.Group>
                      </div>


                    </div>

                  </div>
                </>
              ) : (
                null
              )
            }
            {/* LOI */}

            <Ternary when={!shouldHideButtons} show={
              <Ternary when={userInfo?.is_superuser} show={
                <HandleSuperAdmin status={letterOfIntroduction} pdf={letterOfIntroductionPDF} />
              }
                otherwise={
                  <HandleClubAdmin status={letterOfIntroduction} pdf={letterOfIntroductionPDF} disabled={adminRequestLetter} onClick={() => setShowSweetAlert(true)} />
                }
              />
            } />

            {/* {!shouldHideButtons && (
              <>
            <div className='mt-2 panel-type-1a' onClick={() => setIsOpenSeven(!isOpenSeven)}>
              LOI
              {isOpenSeven ? <FaChevronUp className="accordion-icon" /> : <FaChevronDown className="accordion-icon" />}
            </div>
            <div className={`accordion-content ${isOpenSeven ? "open" : ""}`}>
              
                <div className='buttonletter-container'>

                  {!letterOfIntroduction && bookingDetails?.status !== "Expired" && !userInfo?.is_superuser && (
                    <Button className='custom-button' style={{ height: "50px" }} onClick={() => setShowSweetAlert(true)} disabled={adminRequestLetter}>
                      {adminRequestLetter ? 'Request for Letter of Introduction (Pending)' : 'Request for Letter of Introduction'}
                    </Button>
                  )}


                  {letterOfIntroduction && letterOfIntroductionPDF && (
                    <Button
                      style={{ height: "40px" }}
                      className='custom-button'
                      onClick={() => window.open(letterOfIntroductionPDF, '_blank')}
                    >
                      View Letter for Introduction
                    </Button>
                  )}
                </div>
            </div>
              </>
            )} */}

            {/* Facilities */}
            {bookingDetails?.facility?.length > 0 &&
              <>
                <div className='mt-2 panel-type-1a' onClick={() => setIsOpenFour(!isOpenFour)}>
                  Facilities
                  {isOpenFour ? <FaChevronUp className="accordion-icon" /> : <FaChevronDown className="accordion-icon" />}
                </div>
                <div className={`accordion-content ${isOpenFour ? "open" : ""}`}>
                  <div className='mt-2'>
                    <div className='d-flex flex-wrap gap-2'>
                      {bookingDetails?.facility?.map((facility, index) => {
                        return (
                          <div className='facility_itm' key={index}>
                            <img src={checkTheme() ? facility?.pcw_icon_class : facility?.pcn_icon_class || ""} alt='img' />
                            <p className='fac_name'>{facility?.facility_name}</p>
                          </div>
                        )
                      }
                      )}
                    </div>
                  </div>
                </div>
              </>
            }

            {/* Host Booking */}
            {bookingDetails?.host_booking_details?.length > 0 &&
              <>
                <div className='mt-2 panel-type-1a' onClick={() => setIsOpenSix(!isOpenSix)}>
                  Host Modified
                  {isOpenSix ? <FaChevronUp className="accordion-icon" /> : <FaChevronDown className="accordion-icon" />}
                </div>
                <div className={`accordion-content ${isOpenSix ? "open" : ""}`}>
                  <div className='host-members mt-4'>
                    {bookingDetails?.host_booking_details?.map((member) => (
                      <div
                        key={member.id}
                        className={`member-card ${selectedCards?.includes(member.id) ||
                          // member?.admin_approved || 
                          member?.member_selected
                          ? "selected"
                          : ""
                          }`}
                      >
                        <div className="card-header">
                          <p>{member?.status} by: {member.host_user}</p>
                          <input
                            type="checkbox"
                            checked={selectedCards.includes(member.id)}
                            disabled={isAnyAdminApproved}
                            onChange={() => toggleSelection(member.id)}
                          />
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="detail">
                              <p className="label">Start date</p>
                              <p className="value">{dayjs(member?.host_modification_details ? member?.host_modification_details?.start_date : bookingDetails?.start_date).format('MMM D, YYYY')}</p>
                            </div>
                            <div className="detail">
                              <p className="label">End date</p>
                              <p className="value">{dayjs(member?.host_modification_details ? member?.host_modification_details?.end_date : bookingDetails?.end_date).format('MMM D, YYYY')}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="detail">
                              <p className="label">Arriving at the club</p>
                              <p className="value">{member?.host_modification_details ? member?.host_modification_details?.arrival_time : bookingDetails?.arrival_time}</p>
                            </div>
                            <div className="detail">
                              <p className="label">No. of Guests</p>
                              <p className="value">{member?.host_modification_details ? member?.host_modification_details?.guest_number : bookingDetails?.guest_number}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="detail">
                              <p className="label">Tee-Time Requested</p>
                              <p className="value">{bookingDetails?.tee_time_request ? 'Yes' : 'No'}</p>
                            </div>
                            {member?.member_modified &&
                              <div className="detail">
                                <p className="label">Status</p>
                                <p className="value">{member?.member_modified && "Member Modified"}</p>
                              </div>
                            }
                          </div>
                          {member?.host_modification_details?.preffered_tee_time &&
                            <div className="row">
                              <div className="detail">
                                <p className="label">Preffered Tee-Time</p>
                                <p className="value">{member?.host_modification_details?.preffered_tee_time}</p>
                              </div>
                            </div>
                          }

                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            }

            {/* Amount Details */}
            {bookingDetails?.status !== "Expired" && bookingDetails?.status !== "Admin Cancelled" && !userInfo?.is_superuser && (
              <>
                <div className='mt-2 panel-type-1a' onClick={() => setIsOpenFive(!isOpenFive)}>
                  Amount Details
                  {isOpenFive ? <FaChevronUp className="accordion-icon" /> : <FaChevronDown className="accordion-icon" />}
                </div>
                <div className={`accordion-content ${isOpenFive ? "open" : ""}`}>
                  <div className="payment-container">
                    {/* Advance Payment Selection */}
                    <Form.Group as={Row} className="mb-3">
                      <Form.Label column sm="4">Do you want to make advance payment</Form.Label>
                      <Col sm="8">
                        <Form.Check
                          inline
                          type="radio"
                          label="Yes"
                          name="advancePayment"
                          className="custom-radio"
                          onChange={() => setAdvancePayment(true)}
                          checked={advancePayment}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="No"
                          name="advancePayment"
                          className="custom-radio"
                          onChange={() => {
                            setAdvancePayment(false);
                            formik.setFieldValue('advance_payment', null);
                          }}
                          checked={!advancePayment}
                        />
                      </Col>
                    </Form.Group>

                    {/* Advance Amount Input */}
                    {advancePayment && (
                      <>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column sm="4">Advance Amount:</Form.Label>
                          <Col sm="8">
                            {bookingDetails?.status !== "Club Modified" &&
                              bookingDetails.status !== "Pending Payment" &&
                              bookingDetails.status !== "Available" &&
                              bookingDetails.status !== "Expired" &&
                              bookingDetails.status !== "Member Cancelled" ? (
                              <Form.Control
                                type="number"
                                name='advance_payment'
                                style={{ border: "1px solid var(--slate-gray)", padding: "5px", width: "200px" }}
                                placeholder="Price in USD"
                                disabled={bookingDetails?.status === "Booked" && formik?.values?.advance_payment >= 0}
                                value={formik.values.advance_payment}
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  formik.setFieldValue("advance_payment", value ? parseInt(value, 10) : "");
                                }}
                              />
                            ) : (
                              <p>USD {formik?.values?.advance_payment || "0.00"}</p>
                            )}
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-2">
                          <Form.Label column sm="4">Additional Charges:</Form.Label>
                          <Col sm="8">
                            {bookingDetails?.status !== "Requested" &&
                              bookingDetails?.status !== "Member Modified" &&
                              bookingDetails?.status !== "Club Modified" &&
                              bookingDetails.status !== "Pending Payment" &&
                              bookingDetails.status !== "Available" &&
                              bookingDetails.status !== "Expired" &&
                              bookingDetails.status !== "Member Cancelled" ? (
                              <Form.Control
                                type="number"
                                style={{ border: "1px solid var(--slate-gray)", padding: "5px", width: "200px" }}
                                name='additional_charges'
                                placeholder="Price in USD"
                                onWheel={(e) => e.target.blur()}
                                value={formik.values.additional_charges}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  formik.setFieldValue("additional_charges", value ? parseInt(value, 10) : "");
                                }}
                              />
                            ) : (
                              <p>USD {formik?.values?.additional_charges || "0.00"}</p>
                            )}
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-2">
                          <Form.Label column sm="4">Total:</Form.Label>
                          <Col sm="8">
                            <p className="total">USD {formik.values.total}</p>
                          </Col>
                        </Form.Group>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
            {/* Booking History Section */}
            {bookingDetails?.histories?.length > 1 && (
              <>
                <div className='mt-2 panel-type-1a' onClick={() => setIsOpenEight(!isOpenEight)}>
                  Booking History
                  {isOpenEight ? <FaChevronUp className="accordion-icon" /> : <FaChevronDown className="accordion-icon" />}
                </div>
                <div className={`accordion-content ${isOpenEight ? "open" : ""}`}>
                  <div className='history-grid mt-2'>
                    {bookingDetails.histories.map((history, index) => (
                      <div key={index} className='history-card'>
                        <div className='history-header'>
                          <p className='history-date'>
                            {index === bookingDetails.histories.length - 1
                              ? "Original Date & Time:"
                              : "Modified Date & Time:"}{" "}
                            {dayjs(history.updated_date).format("MMM D, YYYY hh:mm A")}
                          </p>
                        </div>
                        <div className='history-details'>
                          <p><strong>Start Date:</strong> {dayjs(history.start_date).format('MMM D, YYYY')}</p>
                          <p><strong>End Date:</strong> {dayjs(history.end_date).format('MMM D, YYYY')}</p>
                          <p><strong>Arrival Time:</strong> {dayjs(`2023-01-01 ${history.arrival_time}`).format("hh:mm A")}</p>
                          <p><strong>Guest Number:</strong> {history.guest_number}</p>
                          <p><strong>Tee Time Requested:</strong> {history.tee_time_request ? "Yes" : "No"}</p>
                          <p><strong>Status:</strong> {history?.status}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}

            <div className='d-flex align-items-center justify-content-end gap-3 mt-5 submit-box'>
              {!userInfo?.is_superuser &&
                bookingDetails.status !== "Available" &&
                bookingDetails.status !== "Completed" &&
                bookingDetails.status !== "Expired" &&
                bookingDetails.status !== "Admin Declined" &&
                bookingDetails.status !== "Booked" &&
                bookingDetails.status !== "Pending Payment" &&
                bookingDetails.status !== "Club Modified" &&
                bookingDetails.status !== "Admin Cancelled" &&
                bookingDetails.status !== "Payment Completed" &&
                bookingDetails.status !== "Member Cancelled" && (
                  <div
                    className={`btn-type-accept cursor-pointer ${advancePayment && (formik.values.advance_payment === "" || formik.values.advance_payment < 0) || pending
                      ? "disabled"
                      : ""
                      }`}
                    onClick={() => {
                      if (!advancePayment || (formik.values.advance_payment !== "" && formik.values.advance_payment >= 0) || !pending) {
                        handleButtonClick("accept");
                      }
                    }}
                    style={{
                      pointerEvents:
                        advancePayment && (formik.values.advance_payment === "" || formik.values.advance_payment < 0) || pending
                          ? "none"
                          : "auto",
                      opacity:
                        advancePayment && (formik.values.advance_payment === "" || formik.values.advance_payment < 0) || pending
                          ? 0.5
                          : 1,
                    }}
                  >
                    Accept
                  </div>
                )}


              {!userInfo?.is_superuser && bookingDetails.status !== "Admin Cancelled" && bookingDetails.status !== "Expired" && bookingDetails.status !== "Completed" && bookingDetails.status !== "Admin Declined" && bookingDetails.status !== "Available" && bookingDetails.status !== "Booked" && bookingDetails.status !== "Member Cancelled" && bookingDetails.status !== "Pending Payment" && bookingDetails.status !== "Club Modified" && bookingDetails.status !== "Payment Completed" &&
                <div className='btn-type-8 cursor-pointer' onClick={handleDecline}>Decline</div>
              }
              {!userInfo?.is_superuser && bookingDetails.status !== "Admin Cancelled" && bookingDetails.status !== "Completed" && bookingDetails.status !== "Member Cancelled" && bookingDetails.status !== "Admin Declined" && bookingDetails.status !== "Expired" && bookingDetails.status !== "Pending Payment" && bookingDetails.status !== "Club Modified" && bookingDetails.status !== "Booked" && bookingDetails?.status !== "Member Modified" && bookingDetails.status !== "Payment Completed" &&(
                <div
                  className={`btn-type-2 cursor-pointer ${bookingDetails.modify_count === 2 ? 'disabled' : ''}`}
                  onClick={() => {
                    // if (bookingDetails.modify_count < 2) {
                    setOpenModifyBooking(!openModifyBooking);
                    // }
                  }}
                // style={{ pointerEvents: bookingDetails.modify_count === 2 ? 'none' : 'auto', opacity: bookingDetails.modify_count === 2 ? 0.5 : 1 }}
                >
                  Modify Booking
                </div>
              )}
              {!userInfo?.is_superuser && bookingDetails.status === "Booked" && (
                <div
                  className={`btn-type-2 cursor-pointer ${formik.values.additional_charges === "" || formik.values.additional_charges < 0 ? "disabled" : ""
                    }`}
                  onClick={() => {
                    if (formik.values.additional_charges !== "" && formik.values.additional_charges >= 0) {
                      handleButtonClick("update");
                    }
                  }}
                  style={{
                    pointerEvents:
                      formik.values.additional_charges === "" || formik.values.additional_charges < 0 ? "none" : "auto",
                    opacity:
                      formik.values.additional_charges === "" || formik.values.additional_charges < 0 ? 0.5 : 1,
                  }}
                >
                  Update
                </div>
              )}
              {!userInfo?.is_superuser && bookingDetails.status !== "Admin Cancelled" && bookingDetails.status !== "Completed" && bookingDetails.status !== "Member Cancelled" && bookingDetails.status !== "Admin Declined" && bookingDetails.status !== "Expired" && bookingDetails.status !== "Pending Payment" && bookingDetails.status !== "Requested" && bookingDetails.status !== "Payment Completed" &&
                <div className='btn-type-2 cursor-pointer' onClick={() => setCancelationModal(!cancelationModal)}>Cancel Booking</div>
              }
              <div className='btn-type-2 cursor-pointer' onClick={() => setShowModal(!showModal)}>Messages</div>

              {userInfo?.is_superuser &&
                <div className='btn-type-2 cursor-pointer' onClick={() => setShowModalNotes(!showModalNotes)}>Add Notes</div>
              }

              {bookingDetails?.status === "Pending Payment" &&
                <div className='btn-type-2 cursor-pointer' onClick={() => setShowModalLink(!showModalLink)}>Add Payment Link</div>
              }
              {
                bookingDetails?.status==="Payment Completed" && !bookingDetails?.payment_approved &&
                <div className='btn-type-2 cursor-pointer' onClick={handlePaymentConfirm} >Payment Completed</div>
              }
              
            </div>
          </div>
        </Form>
      </div>


      <Modal show={showModal} onHide={() => { setShowModal(!showModal) }}>
        <Modal.Header closeButton>
          <p className='view_message_header'>View Messages</p>
        </Modal.Header>
        <Modal.Body>
          <div className="message_container">
            <div className="message_list">
              {messages?.length > 0 ? (
                messages.map((msg) => (
                  <div key={msg.id} className={`message_wrapper ${msg.send_by === "club" ? "received" : "sent"}`}>
                    <div className={`message ${msg.send_by === "club" ? "received" : "sent"}`}>
                      {msg.message_body}
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center text-muted">No messages found</p>
              )}

            </div>
          </div>

          {/* <Form> */}
          {!userInfo?.is_superuser &&
            <>
              <Form.Group as={Row} className="mb-3">
                <Col sm="12">
                  <Form.Control
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type your message here.."
                  />
                </Col>
              </Form.Group>
              <div className='d-flex justify-content-end'>
                <button className='btn-type-7' onClick={handleSendMessage} disabled={messaging} >Send</button>
              </div>
            </>
          }
          {/* </Form> */}
        </Modal.Body>
      </Modal>

      <Modal show={showModalNotes} onHide={() => { setShowModalNotes(!showModalNotes) }}>
        <Modal.Header closeButton>
          <p className='view_message_header'>Add Notes</p>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form.Group as={Row} className="mb-3">
              <Col sm="12">
                <Form.Control
                  as="textarea"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder="Type your notes here..."
                  rows={4}
                />
              </Col>
            </Form.Group>
            <div className='d-flex justify-content-end'>
              <button className='btn-type-7' onClick={handleSendNotes} >Add</button>
            </div>
          </>
        </Modal.Body>
      </Modal>

      <Modal show={showModalLink} onHide={() => { setShowModalLink(!showModalLink) }}>
        <Modal.Header closeButton>
          <p className='view_message_header'>Add Payment Link</p>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form.Group as={Row} className="mb-3">
              <Col sm="12">
                <Form.Control
                  type="text"
                  value={links}
                  onChange={(e) => setLinks(e.target.value)}
                // placeholder="Enter payment link"
                />
              </Col>
            </Form.Group>
            <div className='d-flex justify-content-end'>
              <button className='btn-type-7' onClick={handleAddLink} >Add</button>
            </div>
          </>
        </Modal.Body>
      </Modal>


      <Modal show={cancelationModal} onHide={() => { setCancelationModal(!cancelationModal) }}>
        <Modal.Header closeButton>
          <p className='view_message_header'>Cancel Booking</p>
        </Modal.Header>
        <Form onSubmit={cancelFormik.handleSubmit}>
          <Modal.Body>
            <Form.Group>
              <div>
                <Form.Check
                  type="radio"
                  label="Access Cancellation"
                  name="cancel_type"
                  value="Access Cancellation"
                  checked={cancelFormik?.values?.cancel_type === "Access Cancellation"}
                  onChange={() => cancelFormik?.setFieldValue("cancel_type", "Access Cancellation")}
                />
                <Form.Check
                  type="radio"
                  label="Disciplinary Cancellation"
                  value="Disciplinary Cancellation"
                  name="cancel_type"
                  checked={cancelFormik?.values?.cancel_type === "Disciplinary Cancellation"}
                  onChange={() => cancelFormik?.setFieldValue("cancel_type", "Disciplinary Cancellation")}
                />
              </div>
              {/* <ErrorMessage name="cancel_type" component="div" className="text-danger" /> */}
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>Cancellation Reason</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={cancelFormik?.values?.cancel_reason}
                onChange={(e) => cancelFormik?.setFieldValue('cancel_reason', e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <button className='btn-type-7' type='submit' >Send</button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      {openBankModal &&
        <AddClubBankModal
          openBankModal={openBankModal}
          setOpenBankModal={setOpenBankModal}
        />
      }
      {openModifyBooking &&
        <ModifyBookingModal
          openModifyBooking={openModifyBooking}
          setOpenModifyBooking={setOpenModifyBooking}
          bookingDetails={bookingDetails}
          update={update}
          setUpdate={setUpdate}
        />
      }
      {openUserModal &&
        <AddUserModal
          openUserModal={openUserModal}
          setOpenUserModal={setOpenUserModal}
          userInfo={userInfo}
          setUpdateUser={setUpdateUser}
          updateUser={updateUser}
        />
      }

      {showAlert && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, Confirm!"
          cancelBtnText="No, Cancel"
          confirmBtnStyle={{ backgroundColor: "var(--sandy-brown)", borderColor: "var(--sandy-brown)", color: "#fff", textDecoration: "none" }}
          cancelBtnBsStyle="default"
          title="Are you sure?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        >
          Do you want to select {pendingSelection?.label}?
        </SweetAlert>
      )}

    </div>
  )
}

export default ViewMemberRequest;


const HandleSuperAdmin = ({ status, pdf }) => {
  if (status) {
    return (
      <Wrapper>
        <ViewLetterButton pdf={pdf} />
      </Wrapper>
    )
  }
  return null
}

const HandleClubAdmin = ({ status, pdf, disabled, onClick, }) => {
  if (status) {
    return (
      <Wrapper>
        <ViewLetterButton pdf={pdf} />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Button className='custom-button' style={{ height: "50px" }} onClick={onClick} disabled={disabled}>
        {
          disabled ? "Request for Letter of Introduction (Pending)" : "Request for Letter of Introduction"
        }

      </Button>
    </Wrapper>

  )
}


const ViewLetterButton = ({ pdf }) => {
  return (

    <Button
      style={{ height: "40px" }}
      className='custom-button'
      onClick={() => window.open(pdf, '_blank')}
    >
      View Letter for Introduction
    </Button>
  )
}


// const RequestLetterButton =() => {



//   return (
//     <Button className='custom-button' style={{ height: "50px" }} onClick={() => setShowSweetAlert(true)} disabled={adminRequestLetter}>
//     {adminRequestLetter ? 'Request for Letter of Introduction (Pending)' : 'Request for Letter of Introduction'}
//   </Button>
//   )
// }


const Wrapper = ({ children }) => {
  const [isOpenSix, setIsOpenSix] = useState(true)
  return (
    <>
      <div className='mt-2 panel-type-1a' onClick={() => setIsOpenSix(!isOpenSix)}>
        LOI
        {isOpenSix ? <FaChevronUp className="accordion-icon" /> : <FaChevronDown className="accordion-icon" />}
      </div>
      <div className={`accordion-content ${isOpenSix ? "open" : ""}`}>
        <div className='buttonletter-container'>
          {children}
        </div>
      </div>
    </>
  )

}


const Ternary = ({ when, show, otherwise = null }) => {
  return when ? show : otherwise

}