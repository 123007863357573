import { ADD_BRAND_CATEGORY, BRAND_CATEGORY_DETAILS, BRAND_CATEGORY_DROPDOWN, BRAND_CATEGORY_LISTING, BRAND_CATEGORY_STATUS_CHANGING, BRAND_DETAILS, DELETE_BRAND_CATEGORY } from "../api";
import axiosInstance from "../axiosinstance";

export const addBrandCategory=(props) => onResponse => {
    try {
        axiosInstance.post(ADD_BRAND_CATEGORY, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const listBrandCategory = (props) => onResponse => {
    try {
        let BASE_URL = BRAND_CATEGORY_LISTING + '?'
        if (props.page) {
            BASE_URL += 'page=' + props.page + '&'

        }
        if (props.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if(props.sort_by){
            BASE_URL+='sort_by='+props.sort_by+'&'
        }
        if(props.order){
            BASE_URL+='order='+props.order+'&'
        }
        if(props.from_date){
            BASE_URL+='from_date='+props.from_date+'&'
        }
        if(props.to_date){
            BASE_URL+='to_date='+props.to_date+'&'
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }

};

export const deleteBrandCategory = (id) => onResponse => {
    try {

        axiosInstance.delete(DELETE_BRAND_CATEGORY, { data: { id: [id] } })

            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getBrandCategory = (props) => onResponse => {
    try {
        axiosInstance.get(BRAND_CATEGORY_DETAILS + '?id=' + props.id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getBrandCategoryStatusChanging=(props)=>onResponse=>{
    try{
        axiosInstance.post(BRAND_CATEGORY_STATUS_CHANGING,props)
        .then((response) => {
            onResponse(response.data);
        })
        .catch((err) => {
            onResponse(err);
        });


    } catch(error){

    }
};
export const brandCategoryDropdown = (props) => onResponse => {


    let BASE_URL = `${BRAND_CATEGORY_DROPDOWN}?`
    if (props?.page) {
        BASE_URL += `page=${props?.page}&`
    }
    if (props?.limit) {
        BASE_URL += `limit=${props?.limit}&`
    }
    if (props?.search) {
        BASE_URL += `search_param=${props?.search}&`
    }
   
    try {
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
           
                onResponse(err?.response?.data);
            });

    } catch (error) {

    }

};