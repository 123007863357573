import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { CLUB_TYPE_LIST, SUBSCRIPTIONTYPELIST } from '../../constants/pathname'
import { createClubtype, getclubtypeDetails } from '../../api/actions/clubtype'
import toast from 'react-hot-toast'
import * as Yup from 'yup';

const AddsubscriptionType = ({ modal = false, setShowModal, setRefresh, refresh }) => {
        const {id}=useParams()
         const navigate = useNavigate();
    
    
       const validationSchema = Yup.object({
            club_type: Yup.string().required('Required'),
          
        });
        const formik = useFormik({
        initialValues: {
            club_type: '',
        },
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            createClubtype(values)((response) => {
                console.log(response);
                
                if (response.status_code === 200 || response.status_code === 201) {
                    toast.success(!modal && id ? "Club type Updated Successfully" : "Club type Created Successfully!");
                   !modal ? navigate(CLUB_TYPE_LIST):setShowModal(false)
                } 
                else{
                    toast.error(response?.response?.data?.errors?.non_field_errors[0]||"Failed to create club type");
                }
            });
        },
    });
    
  return (
    <div className='create-clubs-profile-page create-clubs-info-section'>
    <div className='page-details'>
        {!modal && 
        <div className='panel-type-1'>
            {id ? "Edit Subscription Type" : " Add Subscription Type"}

        </div>}
        <Form onSubmit={formik.handleSubmit}>
        <div className={`club-form ${modal ? '' : 'mt-5'}`}>
                <div className=''>
                    <div className='row'>
                        <div className="col-xl-4 col-md-4 mb-3">
                            <Form.Group controlId="clubName">
                                <Form.Label>Subscription Type Name<span className='required'>*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    className='dotted-input-half'
                                    placeholder="Subscription Type Name"
                                    name='club_type'
                                    value={formik.values?.club_type}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        formik.setFieldValue(
                                            'club_type',
                                            value.charAt(0).toUpperCase() + value.slice(1)
                                        );
                                    }}
                                    isInvalid={formik.touched.club_type && !!formik.errors.club_type}
                                />
                                <Form.Control.Feedback type="invalid">{formik.touched.club_type && formik.errors.club_type}</Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className='submit-box d-flex align-items-center justify-content-end gap-3'>
                                                    <button className='btn-type-2 cursor-pointer' type="button" onClick={() => {
                                                        !modal ? navigate(SUBSCRIPTIONTYPELIST) : setShowModal(false)
                                                    }}>Cancel</button>
                                                    <button className='btn-type-1' type="submit" >
                                                        {id && !modal ? "Update" : "Submit"}
                                                    </button>
                                                </div>

                    </div>

                </div>

            </div>

        </Form>

    </div>

</div>
  )
}

export default AddsubscriptionType
