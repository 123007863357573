import { CREATE_SUBSCRIPTION, DELETE_SUBSCRIPTION, LIST_SUBSCRIPTION, SUBSCRIPTION_DETAIL, SUBSCRIPTION_FEATURES } from "../api";
import axiosInstance from "../axiosinstance";

export const createSubscription = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_SUBSCRIPTION, props)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};
export const listSubscription = (props) => onResponse => {
    try {
        let BASE_URL = LIST_SUBSCRIPTION + '?'
        if (props.page) {
            BASE_URL += 'page=' + props.page + '&'

        }
        if (props.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};
export const getSubscriptionDetail = (props) => onResponse => {
    try {
        let BASE_URL = SUBSCRIPTION_DETAIL + '?'
        if (props.id) {
            BASE_URL += 'id=' + props.id + '&'

        }

        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};
export const getSubscriptionFeatures = (props) => onResponse => {
    try {
        let BASE_URL = SUBSCRIPTION_FEATURES + '?'
        

        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};
export const deleteSubscription = (id) => onResponse => {
    try {
        axiosInstance.delete(DELETE_SUBSCRIPTION, { data: { id: [id] } })
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};