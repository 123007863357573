import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import defaultUser from "../../assets/images/common/defaultUser.jpeg";
import img1 from "../../assets/images/common/img1.png";
import { Form } from 'react-bootstrap';
import defaultFacilityIcon from "../../assets/images/common/img1.png";
import { checkTheme } from '../../utils/utils';

const ProfilesTab = ({ profileData,userInfo}) => {
    return (
        <div className='profile-tabs'>
        <Tabs
            defaultActiveKey="aboutUs"
            id="fill-tab-example"
            fill
        >
            <Tab eventKey="aboutUs" title="About Us" className='aboutus'>
                <div className='row'>
             
                        <div dangerouslySetInnerHTML={{__html: profileData?.about_club || ""}}/>
                    
                </div>
            </Tab>
            {/* <Tab eventKey="honors" title="Honors" className='honors'>
                <div dangerouslySetInnerHTML={{__html: profileData?.honors_awards || ""}}/>                    
            </Tab> */}
            {checkTheme() ?
                userInfo && userInfo?.club_details?.[0]?.both_clubs && 
                <Tab eventKey="affiliatedClubs" title="Affiliated Clubs" className='affiliated-clubs'>  
                    <div className='' style={{height: "300px", overflow: "auto"}}>
                        {/* <div className='d-flex justify-content-end mb-4'>
                            <Form.Control
                                type="text"
                                placeholder="Search Clubs"
                                name=""
                                className='search-input'
                            />
                        </div> */}
                            <div className='d-flex gap-3 flex-wrap'>
                                {Array.isArray(profileData?.affilited_clubs) 
                                && profileData?.affilited_clubs?.map((item) =>{
                                    return(
                                            <div className='club-item'>
                                                <div className='name'>{item?.club_name}</div>
                                                <div className='address'>{item?.city_name || ""}{item?.city_name && item?.country_name ? ", " : ""}{item?.country_name || ""} </div>
                                            </div>                           
                                    )})}
                            </div>   
                    </div>              
                </Tab>
              : 
                <Tab eventKey="affiliatedClubs" title="Affiliated Clubs" className='affiliated-clubs'>  
                    <div className='' style={{height: "300px", overflow: "auto"}}>
                        {/* <div className='d-flex justify-content-end mb-4'>
                            <Form.Control
                                type="text"
                                placeholder="Search Clubs"
                                name=""
                                className='search-input'
                            />
                        </div> */}
                            <div className='d-flex gap-3 flex-wrap'>
                                {Array.isArray(profileData?.affilited_clubs) 
                                && profileData?.affilited_clubs?.map((item) =>{
                                    return(
                                            <div className='club-item'>
                                                <div className='name'>{item?.club_name}</div>
                                                <div className='address'>{item?.city_name || ""}{item?.city_name && item?.country_name ? ", " : ""}{item?.country_name || ""} </div>
                                            </div>                           
                                    )})}
                            </div>   
                    </div>              
                </Tab>}
            {/* <Tab eventKey="boardMembers" title="Board Members" className='board-members'>
                <div className='row flex-wrap gap-3'>
                {Array.isArray(profileData?.board_members) 
                    && profileData?.board_members?.map((item) =>{
                        return(
                            <div className='col-md-3 profile-item'>
                                <div className='img-wrapper'>
                                    <img src={item?.image || ""} alt='Profile' />
                                </div>
                                <div>
                                    <div className='name'>{item?.first_name} {item?.last_name}</div>
                                    <div className='designation'>{item?.designation}</div>
                                </div>
                            </div>
                        )
                    })                                               
                }                    
                </div>
            </Tab> */}
            <Tab eventKey="clubFacilities" title="Club Facilities" className='board-members facilities'>
                <div>
                    {Array.isArray(profileData?.club_facilities) && profileData?.club_facilities?.length > 0 && 
                        <div className='row flex-wrap gap-3'>
                            {profileData?.club_facilities?.map((item, index) =>(
                                <div className='col-md-3 profile-item'>
                                    <div className='img-wrapper'>
                                        <img src={item?.club_facility_images?.[0]?.image || defaultFacilityIcon} alt={item?.facility_name || ""}/>
                                    </div>
                                    <div>
                                        <div className='name'>{item?.facility_name || ""}</div>
                                    </div>
                                </div>
                            ))}
                        </div>}                        
                </div>
            </Tab>
        </Tabs>
    </div>
    )
}

export default ProfilesTab
