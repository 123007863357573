import React from 'react'
import CuratedSponsorship from '../../components/CuratedSponsorship/CuratedSponsorship'

const CuratedSponsorshipPage = () => {

  return (
    <div className='curated-sponsorship-page'>
        <CuratedSponsorship />
    </div>
  )
}

export default CuratedSponsorshipPage