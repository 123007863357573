import { ADD_MEMBER, CLUB_MEMBER_LIST, EXPORT_MEMBER_LIST, INVITE_CLUB_MEMBER, MEMBER_BULK_MEMBER_PROFILE, MEMBER_BULK_UPLOAD, MEMBER_PROFILE_DELETE, MEMBER_PROFILE_DETAILS, MEMBER_STATUS_UPDATE, SAMPLE_CSV_DOWNLOAD, UPDATE_MEMBER_RC_MODERATION } from "../api";
import axiosInstance from "../axiosinstance";

export const addManager=(props) => onResponse => {
    try {
        axiosInstance.post(ADD_MEMBER, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const inviteClubMember=(props) => onResponse => {
    try {
        axiosInstance.post(INVITE_CLUB_MEMBER, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
// Updating reciprocal request moderation
export const updateRCModeration=(props) => onResponse => {
    try {
        axiosInstance.post(UPDATE_MEMBER_RC_MODERATION, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
// Bulk upload
export const bulkUploadMember=(props) => onResponse => {
    try {
        axiosInstance.post(MEMBER_BULK_UPLOAD, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
// Bulk update member profile
export const bulkUpdateMemberProfile=(props) => onResponse => {
    try {
        axiosInstance.post(MEMBER_BULK_MEMBER_PROFILE, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
// Update member status
export const updateMemberActiveStatus=(props) => onResponse => {
    let BASE_URL = MEMBER_STATUS_UPDATE;
    let { country, ...temp} = props;
    let data = temp;
    
    if(country){
        BASE_URL += `?country=${country}`;
    }

    try {
        axiosInstance.post(BASE_URL, data)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const listMember = (props) => onResponse => {
    try {
        let BASE_URL = CLUB_MEMBER_LIST + '?'
        if (props.page) {
            BASE_URL += 'page=' + props.page + '&'
        }
        if (props.club) {
            BASE_URL += 'club=' + props.club + '&'
        }
        if (props.member_status) {
            BASE_URL += 'member_status=' + props.member_status + '&'
        }
        if (props.country) {
            BASE_URL += 'country=' + props.country + '&'
        }
        if (props.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if(props.sort_by){
            BASE_URL+='sort_by='+props.sort_by+'&'
        }
        if(props.order){
            BASE_URL+='order='+props.order+'&'
        }
        if(props.from_date){
            BASE_URL+='from_date='+props.from_date+'&'
        }
        if(props.to_date){
            BASE_URL+='to_date='+props.to_date+'&'
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }

};

export const exportMemberList = (props) => onResponse => {
    try {
        let BASE_URL = EXPORT_MEMBER_LIST + '?';

        if (props.country) {
            BASE_URL += 'country=' + props.country + '&'
        }
        if (props.club) {
            BASE_URL += 'club=' + props.club + '&'
        }
        
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }

};

export const exportSampleCSV = (props) => onResponse => {
    try {
        let BASE_URL = SAMPLE_CSV_DOWNLOAD + '?';

        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }

};

export const deleteMemberDetails = (props) => onResponse => {
    try {

        axiosInstance.delete(MEMBER_PROFILE_DELETE, { data: { id: props?.id } })

            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};


export const getManageMemberDetails = (props) => onResponse => {
    try {
        axiosInstance.get(MEMBER_PROFILE_DETAILS + '?id=' + props.id + '&country=' + props.country)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};