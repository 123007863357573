import Modal from 'react-bootstrap/Modal';
import { FaFileDownload } from "react-icons/fa";
import { PiFiles } from "react-icons/pi";
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { EXPORT_MEMBER_LIST, SAMPLE_CSV_DOWNLOAD } from '../../api/api';
// Mui
import { FormControlLabel, RadioGroup } from "@mui/material";
// Radio group
import Radio from '@mui/material/Radio';
import { Form } from 'react-bootstrap'; 
import toast from 'react-hot-toast';
import { checkTheme } from '../../utils/utils';

const BulkUploadModal=({ showBulkModal, setShowBulkModal, handleCloseBlkm, onUpload }) => {
    const [uploadedFile, setUploadedFile]=useState(null); // State to store uploaded files

    const { getRootProps, getInputProps }=useDropzone({
        accept: {
            'application/vnd.ms-excel': ['.csv'],
            'text/csv': ['.csv'], // For .csv files
        },
        multiple: false, // Allow only one file
        onDrop: (acceptedFiles) => {
            // Update the state with the newly uploaded file
            const file=acceptedFiles[0];
            if (file) {
                setUploadedFile({
                    file,
                    name: file.name, // Store file name for display
                });
            }
        },
    });

    const handleRemoveFile = () => {
        setUploadedFile(null);
    };

    const handleSubmit = () => {
        if (!uploadedFile) {
            toast.error("Please upload a file before submitting.", {position: "top-center"});
            return;
        }

        // Pass the uploaded file to the parent function
        onUpload(uploadedFile.file);

        // Clear the file after submission
        setUploadedFile(null);
        handleCloseBlkm();        
        
    };

     // Export sample csv
      const sampleCSVDownload = () =>{
        const myHeaders = new Headers();
        let token = localStorage.getItem('token');
    
        myHeaders.append(`Authorization`, `Bearer ${token}`);
    
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow"
        };
    
        fetch(`${process.env.REACT_APP_BACKENDURL}/${SAMPLE_CSV_DOWNLOAD}`, requestOptions)
          .then((response) => response.blob())
          .then((blob) =>{
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            
            a.href = url;
            a.download = "Sample CSV.csv"; // Ensure correct file extension
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);    
          })
          .catch((error) => console.error(error));   
      }

    return (
        <div>
            <Modal show={showBulkModal} onHide={handleCloseBlkm} className='bulkupload-members-modal'>
                <Modal.Header closeButton>
                </Modal.Header>
                <Form onSubmit={(e) => e.preventDefault()}>
                    <Modal.Body>
                        <div className='d-flex gap-3 align-items-start justify-content-center upload-item-panel text-center' style={{position: "relative"}}>                            
                            <div className='right'>
                                <div className='d-flex flex-column align-items-center'>
                                    <div className='description mt-2'>
                                        Upload the raw format of your club member data as CSV file
                                    </div>
                                    <div className='download mt-4'>
                                        <div {...getRootProps({ className: 'dropzone cursor-pointer' })}>
                                            <input {...getInputProps()} />
                                            <PiFiles size={35} />
                                            <div className='mt-2' style={{ fontSize: "15px" }}>{uploadedFile&&uploadedFile?.name? 'Click Upload':'DRAG & DROP'}</div>
                                            <div className='mt-2'>
                                                Drop any .CSV or
                                                &nbsp;<label htmlFor="fileInput" className='cursor-pointer c-blue'>browse your files</label></div>
                                        </div>
                                    </div>
                                    <div className='file-name mt-4'>
                                        {/* Display the uploaded file */}
                                        {uploadedFile&&(
                                            <div className='d-flex gap-3'>
                                                <p>{uploadedFile.name}</p>                                                
                                                <RiDeleteBin6Line size={16} className='cursor-pointer' fill='var(--red)' title='Remove' onClick={() => handleRemoveFile()} />
                                            </div>
                                        )}
                                    </div>
                                    <div className='description cursor-pointer mt-2 download-sample c-blue' onClick={() => sampleCSVDownload()}>
                                        Download Sample Template
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='description mt-4'>
                            <div>
                                <p>If your club does not use PrivateClubWorld's Reciprocity Management System, please add “No” in the Reciprocal Club Access column of the member upload template. If your club has adopted PrivateClubWorld as your Reciprocity Management System to manage your club’s existing reciprocal network, please add "Yes" or "No" in the Reciprocal Club Access column of the member upload template to indicate if a member has access to your reciprocal club network. If you are uploading a member list other than the template provided, please add a column titled "Reciprocal Club Access" and add "Yes" or "No" for each member to indicate if they have access to your club's existing reciprocal network.</p>
                                <p>Please indicate Yes/No in Reciprocal Request Moderation if you would like to review and approve access requests from member(s) before they are sent to a reciprocal club in your network.</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                    {/* <div>Does your file have headers?<span className='required'>*</span></div> */}
                    {/* <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="yes"
                            name="radio-buttons-group"
                            className='d-flex flex-row align-items-center'
                        >
                            <FormControlLabel value="yes" control={<Radio size='small' />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio size='small' />} label="No" />
                        </RadioGroup>  */}
                        <button className='btn-type-1' type="submit" onClick={handleSubmit}>Upload</button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </div>
    )
}

export default BulkUploadModal