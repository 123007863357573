import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { DatePicker, TimePicker } from "antd";
import Plus from "../../assets/images/common/PlusCircle.svg"
import Minus from "../../assets/images/common/DashCircle.svg"
import { useFormik } from 'formik';
import { adminBookingModify } from '../../api/actions/bookings';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';

const ModifyBookingModal = ({ openModifyBooking, setOpenModifyBooking, bookingDetails, update, setUpdate }) => {

    const [pending, setPending] = useState(false)

    const formik = useFormik({
        initialValues: {
            id: '',
            club: '',
            host_guest_request: '',
            letter_of_introduction: '',
            club_of_introduction: '',
            introduction_club_country: '',
            start_date: '',
            end_date: '',
            arrival_time: '',
            guest_number: '',
            tee_time_request: '',
            preffered_tee_time: '',
            tee_time_players: [],
            need_rental_club: '',
            special_request: '',
            country: '',
            facility: '',
            is_facility_booking: '',
            advance_payment: '',
            additional_charges: '',
            old_amount: '',
            old_commission_amt: '',
            commission: '',
            commission_amt: '',
            commission_type: '',
            is_additional_charge: '',
            additional_commision_charge: '',
            total: ''
        },
        onSubmit: (values, { resetForm }) => {
            setPending(true)
            adminBookingModify(values)((res) => {
                console.log(res)
                if (res?.data?.status_code === 200 || res?.status_code === 201) {
                    setPending(false)
                    resetForm()
                    setOpenModifyBooking(!openModifyBooking)
                    toast.success("Booking Modified")
                    setUpdate(!update)
                }
                else {
                    if (res?.response?.data?.errors?.non_field_errors[0]) {
                        toast.error(res?.response?.data?.errors?.non_field_errors[0])
                        setPending(false)
                    } else {
                        toast.error(res?.response?.data?.message)
                        setPending(false)
                    }
                }
                setPending(false)
            })
        }
    })

    // console.log(formik.values, "||||VALUES")

    const handleIncrease = () => {
        const currentGuestCount = parseInt(formik.values.guest_number) || 0;
        formik.setFieldValue("guest_number", currentGuestCount + 1);
    };


    const handleDecrease = () => {
        const currentGuestCount = parseInt(formik.values.guest_number) || 0;
        if (currentGuestCount > 1) {
            formik.setFieldValue("guest_number", currentGuestCount > 1 ? currentGuestCount - 1 : currentGuestCount);
        }
    };

    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };


    useEffect(() => {
        if (bookingDetails) {
            formik.setValues({
                ...formik.values,
                id: bookingDetails.id || null,
                country: bookingDetails.country || null,
                club: bookingDetails.club || null,
                host_guest_request: bookingDetails.host_guest_request || null,
                letter_of_introduction: bookingDetails.letter_of_introduction || null,
                club_of_introduction: bookingDetails.club_of_introduction || null,
                introduction_club_country: bookingDetails.introduction_club_country || null,
                start_date: bookingDetails.start_date || null,
                end_date: bookingDetails.end_date || null,
                arrival_time: bookingDetails.arrival_time || null,
                guest_number: bookingDetails.guest_number || null,
                tee_time_request: bookingDetails.tee_time_request || null,
                preffered_tee_time: bookingDetails.preffered_tee_time || null,
                tee_time_players: bookingDetails.tee_time_players || null,
                need_rental_club: bookingDetails.need_rental_club || null,
                special_request: bookingDetails.special_request || null,
                facility: bookingDetails.facility?.map((item)=>item.id) || null,
                is_facility_booking: bookingDetails.is_facility_booking || null,
                advance_payment: bookingDetails.advance_payment || null,
                additional_charges: bookingDetails.additional_charges || null,
                old_amount: bookingDetails.old_amount || null,
                old_commission_amt: bookingDetails.old_commission_amt || null,
                commission: bookingDetails.commission || null,
                commission_amt: bookingDetails.commission_amt || null,
                commission_type: bookingDetails.commission_type || null,
                is_additional_charge: bookingDetails.is_additional_charge || null,
                additional_commision_charge: bookingDetails.additional_commision_charge || null,
                total: bookingDetails.total || null

            });
        }
    }, [])


    return (
        <Modal show={openModifyBooking} size='lg' onHide={() => setOpenModifyBooking(!openModifyBooking)}>
            <Modal.Header closeButton>
                <p style={{ fontSize: "18px" }}>Modify Booking</p>
            </Modal.Header>
            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-sm-12 col-md-6'>
                            <Form.Group className='mt-3'>
                                <div className='d-flex flex-column'>
                                    <Form.Label>Start Date<sup className='text-danger'>*</sup></Form.Label>
                                    <DatePicker
                                        onChange={(date, dateString) => 
                                            formik.setFieldValue("start_date", date ? dayjs(date).format("YYYY-MM-DD") : "")
                                        }
                                        value={formik.values.start_date ? dayjs(formik.values.start_date, "YYYY-MM-DD") : null}
                                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                        disabledDate={(current) =>
                                            current && current < dayjs(formik.values.start_date).startOf("day")
                                        }
                                        format="DD/MM/YYYY"  
                                        />

                                </div>
                            </Form.Group>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <Form.Group className='mt-3'>
                                <div className='d-flex flex-column'>
                                    <Form.Label>End Date<sup className='text-danger'>*</sup></Form.Label>
                                    <DatePicker
                                        onChange={(date, dateString) => 
                                            formik.setFieldValue("end_date", date ? dayjs(date).format("YYYY-MM-DD") : "")
                                        }
                                        value={formik.values.end_date ? dayjs(formik.values.end_date, "YYYY-MM-DD") : null}
                                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                        disabledDate={(current) =>
                                            current && current < dayjs(formik.values.start_date).startOf("day")
                                        }
                                        format="DD/MM/YYYY"  
                                        />
                                </div>
                            </Form.Group>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <Form.Group className='mt-3'>
                                <Form.Label>What time would you be arriving at the club?</Form.Label>
                                <TimePicker
                                    format="HH:mm"
                                    minuteStep={15}
                                    onChange={(time, timeString) => formik.setFieldValue("arrival_time", timeString)}
                                    value={formik.values.arrival_time ? dayjs(formik.values.arrival_time, "HH:mm") : null}
                                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                />
                            </Form.Group>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <Form.Group className='mt-3'>
                                <Form.Label>Amount</Form.Label>
                                <Form.Control type='number' placeholder='Price in USD' name='advance_payment' value={formik.values.advance_payment} onChange={formik.handleChange} />
                            </Form.Group>
                        </div>

                        <div className='col-sm-12 col-md-6'>
                            <Form.Group className='mt-3'>
                                <Form.Label>Number of Guest(s)</Form.Label>
                                {/* <Form.Control type='number' /> */}
                                <div className='d-flex align-items-center'>
                                    <div className='guest_decrement'>
                                        <img className='decrement' src={Minus} alt="minus" onClick={handleDecrease} />
                                    </div>
                                    <div className='guest_count'>
                                        <input type='number' className='guest_count_input' value={formik?.values?.guest_number} onChange={(e) => formik.setFieldValue('guest_number', e.target.value)} />
                                    </div>
                                    <div className='guest_increment'>
                                        <img className='increment' src={Plus} alt='add' onClick={handleIncrease} />
                                    </div>
                                </div>
                            </Form.Group>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-end'>
                    <button 
                        className='btn-type-7' 
                        type='submit' 
                        disabled={
                            pending 
                            // || formik.values.advance_payment === "" ||  formik.values.advance_payment === null
                        }
                    >
                        {pending ? "Updating" : "Update"}
                    </button>

                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default ModifyBookingModal