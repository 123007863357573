import React from 'react'
import Brands from '../../components/Brands/Brands'

const BrandsPage = () => {

  return (
    <div className='brands-page'>
        <Brands />
    </div>
  )
}

export default BrandsPage