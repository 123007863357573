import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component";
import SweetAlertModal from '../../SweetAlert/SweetAlertModal';
import {ReactComponent as AddIcon} from "../../../assets/images/common/add-icon.svg";
import {ReactComponent as EditIcon} from "../../../assets/images/common/edit-icon.svg";
import {ReactComponent as CloseIcon} from "../../../assets/images/common/close-icon.svg";
import { PLATINUMCLUBNETADD } from '../../../constants/pathname';

const PlatinumClubNet = () => {
        const [showSweetAlert, setShowSweetAlert] = useState(false);
        const navigate=useNavigate()
    const handleDelete = () =>{
        setShowSweetAlert(true);
      }
      const data=[
        { slNo: "01", firstName: "Platinum club of world", lastName: "4" },
        { slNo: "02", firstName: "platinum club of America", lastName: "4", },
        { slNo: "03", firstName: "platinum club of Asia specific", lastName: "4",},
        { slNo: "04", firstName: "platinum club of Europe and Middle East", lastName: "4" },
       
    ];
    const columns=[
        { name: "Sl.No", selector: row => row.slNo, width: "110px" },
        { name: "Club Type", selector: row => row.firstName, sortable: true, reorder: true },
        { name: "Country", selector: row => row.country, sortable: true, reorder: true },
            { name: "Actions", 
                selector: row => 
                <div className='d-flex align-items-center gap-3'>
                    {/* <div className='view-icon cursor-pointer' onClick={() => navigate(CREATE_FACILITIES_CATEGORY, {state : {currentPageName: "View Facilities Category" }})} title='View'><ViewIcon style={{marginTop: "-1px"}}/></div> */}
                    <div className='view-icon cursor-pointer'  onClick={() => navigate(PLATINUMCLUBNETADD, { state: { currentPageName: "Edit Club", clubData: row } })}  title="Edit"><EditIcon /></div>                
                    <div className='view-icon cursor-pointer' onClick={() => handleDelete()} title='Delete'><CloseIcon style={{marginLeft: "2px"}}/></div>
                </div>,  
                width: "180px", sortable: true },
        // { name: "Email", selector: row => row.email, sortable: true, reorder: true },
        // {
        //     name: "Role",
        //     selector: row => <div title={row.role.join(", ")}>{row.role.join(", ")}</div>, // Convert array to comma-separated string
        //     sortable: true,
        //     reorder: true,
        // },
    ];

    const customStyles={
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",
                border: "1px solid var(--border-color)",
                overflow: "hidden"
            },
        },
        headCells: {
            style: {
                backgroundColor: "var(--light-blue)",
                justifyContent: 'flex-start !important',
                color: "var(--dark-gray)",
                fontWeight: "400",
                fontSize: "15px",
            },
        },
        rows: {
            style: {
                height: "45px", // Set row height
                justifyContent: "start",
            },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    };

  return (
    <div className='manage-admins-section'>
    <div className='page-details'>
            <div className='top-section d-flex align-items-center justify-content-between'>
                <div className='left'>Platinum Club Net</div>
                <div className='right d-flex align-items-center  gap-3'>
                </div>
            </div>

            <div className='table-box mt-3'>
                <DataTable
                    columns={columns}
                    data={data}
                    customStyles={customStyles}
                    fixedHeader
                    highlightOnHover
                    responsive
                />
            </div>
        </div>
        <SweetAlertModal showSweetAlert={showSweetAlert} setShowSweetAlert={setShowSweetAlert}/>
</div>
  )
}

export default PlatinumClubNet
