import { ADD_FACILITY_CATEGORY, FACILITY_CATEGORY_DELETE, FACILITY_CATEGORY_DETAILS, FACILITY_CATEGORY_DROPDOWN, FACILITY_CATEGORY_LIST, FACILITY_CATOGORY_STATUS } from "../api";
import axiosInstance from "../axiosinstance";

export const createAddfacitiltyCategory = (props) => onResponse => {
    try {
        axiosInstance.post(ADD_FACILITY_CATEGORY, props)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};

export const facitliyCategoryStatus = (props) => onResponse => {
    try {
        axiosInstance.post(FACILITY_CATOGORY_STATUS, props)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};

export const listFaciltiyCategory = (props) => onResponse => {
    try {
        let BASE_URL = FACILITY_CATEGORY_LIST + '?'
        if (props?.page) {
            BASE_URL += 'page=' + props.page + '&'
        }
        if (props?.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props?.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if (props?.club_type_name) {
            BASE_URL += 'club_type_name=' + props.club_type_name + '&'
        }        
        if(props?.sort_by){
            BASE_URL+='sort_by='+props.sort_by+'&'
        }
        if(props?.order){
            BASE_URL+='order='+props.order+'&'
        }
        if(props?.pcn){
            BASE_URL+='pcn='+props.pcn+'&'
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }

};

export const deletefacilitycategory = (id) => onResponse => {
    try {

        axiosInstance.delete(FACILITY_CATEGORY_DELETE, { data: { id: [id] } })

            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getfaciltycategoryDetails = (props) => onResponse => {
    try {
        axiosInstance.get(FACILITY_CATEGORY_DETAILS + '?id=' + props.id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};


export const faciltiyCategoryDropdown = (props) => onResponse => {


    let BASE_URL = `${FACILITY_CATEGORY_DROPDOWN}?`
    if (props?.page) {
        BASE_URL += `page=${props?.page}&`
    }
    if (props?.limit) {
        BASE_URL += `limit=${props?.limit}&`
    }
    if (props?.search) {
        BASE_URL += `search_param=${props?.search}&`
    }
   
    try {
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
          
                onResponse(err?.response?.data);
            });

    } catch (error) {

    }

};