import React from "react";
import { Container } from "react-bootstrap";
import { checkTheme } from "../../utils/utils";
import logo from "../../assets/images/common/logo/logo-pcw-white.png";
import logo1 from "../../assets/images/common/logo/logo-pcn-white.png";

const Cancellation = () => {
    const contentStyle = {
        lineHeight: "1.6",
        fontSize: "16px",
    };
    
    const headingStyle = {
        textAlign: "center",
        marginBottom: "2rem",
        fontWeight: "600",
    };
    
    const paragraphStyle = {
        marginBottom: "1.5rem",
    };
    
    const listContainerStyle = {
        marginLeft: "20px",
        marginBottom: "2rem",
    };
    
    const mainListStyle = {
        listStyle: "inside",
        marginBottom: "1rem",
    };
    
    const mainListItemStyle = {
        fontWeight: 400,
        marginBottom: "1rem",
    };
    
    const subListContainerStyle = {
        marginLeft: "30px",
        marginTop: "0.75rem",
        marginBottom: "1.5rem",
    };
    
    const subListStyle = {
        listStyle: "inside",
        listStyleType: "circle",
    };
    
    const subListItemStyle = {
        fontWeight: 400,
        marginBottom: "0.75rem",
    };
    
    const linkStyle = {
        fontWeight: 400,
        color: "var(--sandy-brown)",
        textDecoration: "none",
    };
    
    const footerStyle = {
        marginTop: "2rem",
    };

    return (
        <section className="terms-condtion">
            <div className="logo" style={{ marginBottom: "2rem" }}>
                <div className="image-wrapper">
                    <img
                        src={checkTheme() ? logo1 : logo}
                        alt="Logo"
                        style={{ maxWidth: "275px" }}
                    />
                </div>
            </div>

            <Container style={contentStyle}>
                <h3 style={headingStyle}>CANCELLATION AND REFUND POLICY – CLUBS</h3>
                
                <p style={paragraphStyle}>
                    <span style={{ fontWeight: 400 }}>
                    This cancellation policy governs your use of the MobiCom {checkTheme() ? "PlatinumClubNet" : "PrivateClubWorld"} platform and/or its related internet and web components (the “<strong>Platform</strong>”). In the event you avail of any of MobiCom’s services on the Platform (“<strong>Services</strong>”), or any services (“<strong>Third Party Services</strong>”) from third party service providers (“<strong>Service Providers</strong>”), or agree to provide any services to the users (“<strong>Club Services</strong>”), you agree to adhere to the following terms governing cancellations:
                    </span>
                </p>
                
                <p style={paragraphStyle}>
                    <span style={{ fontWeight: 600 }}>
                    CANCELLATION BY THE CLUB
                    </span>
                </p>

                <p style={paragraphStyle}>
                    <span  style={{ fontWeight: 400 }}>
                    In the event that you cancel any bookings after the receipt of an advance payment from MobiCom, you shall refund all amounts, received by you and further pay a processing charge of 10% (ten percent) of the advance amount as charges, towards processing of such payments (“<strong>Processing Fees</strong>”). 
                    </span>
                </p>

                <p style={paragraphStyle}>
                    <span  style={{ fontWeight: 400 }}>
                    All refunds shall be credited to MobiCom’s bank account within 7 (seven) business days from the date of cancellation, the details of which will be submitted to the club at the time of processing the refund. 
                    </span>
                </p>


                <p style={paragraphStyle}>
                    <span style={{ fontWeight: 600 }}>
                    PROCEDURE FOR CANCELLATION AND MODIFICATIONS OF BOOKING
                    </span>
                </p>

                <p style={paragraphStyle}>
                    <span  style={{ fontWeight: 400 }}>
                    Please email us at {checkTheme() ? "support@platinumclubnet.com" : "support@privateclubworld.com"} for cancellation related queries. Our team will help you process the request for cancellation as well as address any queries you may have on refunds. 
                    </span>
                </p>
                
            </Container>
        </section>
    );
};

export default Cancellation;