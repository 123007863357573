
import React from 'react'
import VerfiyEmailPage from '../../components/VerfiyEmailPage/VerfiyEmailPage'

const VerifyEmail = () => {
  return (
    <section className='verfiy'>
        <VerfiyEmailPage/>
    </section>
  )
}

export default VerifyEmail