import React, { useState } from 'react'
import DataTable from "react-data-table-component";
import {ReactComponent as ViewIcon} from "../../assets/images/common/view-icon.svg";
import {ReactComponent as EditIcon} from "../../assets/images/common/edit-icon.svg";
import {ReactComponent as CloseIcon} from "../../assets/images/common/close-icon.svg";
import {ReactComponent as AddIcon} from "../../assets/images/common/add-icon.svg";
// Drawer
import { useNavigate } from 'react-router-dom';
import { CREATE_SURVEY_QUESTIONER, VIEW_SURVEY_QUESTIONER } from '../../constants/pathname';
import SweetAlertModal from '../SweetAlert/SweetAlertModal';

const SurveyQuestioner = () => {
    // Sweet alert
    const [showSweetAlert, setShowSweetAlert] = useState(false);
        
    const navigate = useNavigate();    

    const handleDelete = () =>{
        setShowSweetAlert(true);
    }

    // Data for the table
    const data = [
        { 
            eventName: "Member Events", 
            createdDate: "08 Oct 2024", 
            questions: "10" 
        },
        { 
            eventName: "Private Events", 
            createdDate: "08 Oct 2024", 
            questions: "11" 
        },
        { 
            eventName: "Curated Sponsorship", 
            createdDate: "08 Oct 2024", 
            questions: "05" 
        }
    ];
      
    // Define columns for the table
    const columns = [
        { 
            name: "Event Name", 
            selector: row => row.eventName, 
            sortable: true 
        },
        { 
            name: "Created Date", 
            selector: row => row.createdDate, 
            sortable: true 
        },
        { 
            name: "Questions", 
            selector: row => row.questions, 
            sortable: true 
        },
        { name: "Actions", 
            selector: row => 
            <div className='d-flex align-items-center gap-3'>
                <div className='view-icon cursor-pointer' onClick={() => navigate(VIEW_SURVEY_QUESTIONER, {state : {currentPageName: "View Survey Questionnaire" }})} title='View'><ViewIcon style={{marginTop: "-1px"}}/></div>
                <div className='view-icon cursor-pointer' onClick={() => navigate(CREATE_SURVEY_QUESTIONER, {state : {currentPageName: "Edit Survey Questionnaire" }})} title="Edit"><EditIcon /></div>
                <div className='view-icon cursor-pointer' onClick={() => handleDelete()} title='Delete'><CloseIcon style={{marginLeft: "2px"}}/></div>
            </div>, 
            sortable: true }, 
    ];
   
    // Custom styling for the table
    const customStyles = {
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",        
                border: "1px solid var(--border-color)",
                overflow: "hidden"
            },
    },
        headCells: {
        style: {
            backgroundColor: "var(--light-blue)",
            justifyContent: 'flex-start !important',
            color: "var(--dark-gray)",
            fontWeight: "400",
            fontSize: "15px",
        },
        },
        rows: {
        style: {
            height: "45px", // Set row height
            justifyContent: "start",
        },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    }; 

  return (
    <div className='member-stats-section'>
        <SweetAlertModal showSweetAlert={showSweetAlert} setShowSweetAlert={setShowSweetAlert}/>

        <div className='page-details'>
            <div className='top-section d-flex align-items-center justify-content-between'>
                <div className='left'>List Survey Questionnaire</div>
                <div className='right d-flex align-items-center  gap-3'>
                    <div className='add-clubs-info cursor-pointer'  onClick={() => navigate(CREATE_SURVEY_QUESTIONER, {state : {currentPageName: "Add Survey Questionnaire" }})} title="Create">                    
                        <AddIcon />
                    </div>
                </div>
            </div>

            <div className='table-box mt-3'>
                <DataTable
                    columns={columns}
                    data={data}
                    customStyles={customStyles}
                    fixedHeader
                    highlightOnHover
                    responsive
                />
            </div>
        </div>
    </div>
  )
}

export default SurveyQuestioner