import React, { useState } from 'react'
import DataTable from "react-data-table-component";
import {ReactComponent as FilterIcon} from "../../assets/images/common/filter-icon.svg";
import {ReactComponent as ViewIcon} from "../../assets/images/common/view-icon.svg";
import {ReactComponent as EditIcon} from "../../assets/images/common/edit-icon.svg";
import {ReactComponent as AddIcon} from "../../assets/images/common/add-icon.svg";
import Select from "react-select"
// Drawer
import Drawer from '@mui/material/Drawer';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
// Rsuite Datepicker
import { DatePicker } from 'rsuite';
import { CREATE_CLUBS_PROMOTIONS } from '../../constants/pathname';

const ClubsPromotions = () => { 
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const navigate = useNavigate();

    const [showDrawer, setShowDrawer] = useState(false);

    // Data for the table
    const data = [
        { slNo: "01", name: "Anna K", clubName: "OneClubNet Demo Club", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024", status: "Active" },
        { slNo: "02", name: "Sandra L. Carder", clubName: "OneClubNet Demo Club", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024", status: "Inactive" },
        { slNo: "03", name: "Sandra L. Carder", clubName: "OneClubNet Demo Club", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024", status: "Active" },
        { slNo: "04", name: "Sandra L. Carder", clubName: "OneClubNet Demo Club", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024", status: "Active" },
        { slNo: "05", name: "Sandra L. Carder", clubName: "OneClubNet Demo Club", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024", status: "Active" },
        { slNo: "06", name: "Sandra L. Carder", clubName: "OneClubNet Demo Club", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024", status: "Active" },
        { slNo: "07", name: "Sandra L. Carder", clubName: "OneClubNet Demo Club", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024", status: "Active" },
        { slNo: "08", name: "Sandra L. Carder", clubName: "OneClubNet Demo Club", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024", status: "Active" },
        { slNo: "09", name: "Sandra L. Carder", clubName: "OneClubNet Demo Club", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024", status: "Active" },
        { slNo: "10", name: "Sandra L. Carder", clubName: "OneClubNet Demo Club", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024", status: "Active" },
        { slNo: "11", name: "Sandra L. Carder", clubName: "OneClubNet Demo Club", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024", status: "Active" },
        { slNo: "12", name: "Sandra L. Carder", clubName: "OneClubNet Demo Club", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024", status: "Active" },
        { slNo: "13", name: "Sandra L. Carder", clubName: "OneClubNet Demo Club", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024", status: "Active" },
        { slNo: "14", name: "Sandra L. Carder", clubName: "OneClubNet Demo Club", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024", status: "Active" },
        { slNo: "15", name: "Sandra L. Carder", clubName: "OneClubNet Demo Club", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024", status: "Inactive" },
    ];

    // Define columns for the table
    const columns = [
        { name: "Sl.No", selector: row => row.slNo, width: "120px", sortable: true },
        { name: "Name", selector: row => row.name, minWidth: "200px" , sortable: true },
        { name: "Club Name", selector: row => row.clubName, width: "250px", sortable: true },
        { name: "Start Date", selector: row => row.startDate, width: "150px", sortable: true },
        { name: "End Date", selector: row => row.endDate, width: "150px", sortable: true },
        { name: "Created Date", selector: row => row.createdDate, width: "150px", sortable: true },
        { name: "Status", selector: row => row.status, width: "120px", sortable: true },
        {
            name: "Actions",
            selector: row => (
                <div className="d-flex align-items-center gap-3">
                    <div className="view-icon cursor-pointer" onClick={() => navigate(CREATE_CLUBS_PROMOTIONS, {state : {currentPageName: "View Club Promotion" }}) } title='View'>
                        <ViewIcon />
                    </div>
                    <div className="view-icon cursor-pointer" onClick={() => navigate(CREATE_CLUBS_PROMOTIONS, {state : {currentPageName: "Edit Club Promotion" }}) } title="Edit">
                        <EditIcon />
                    </div>  
                </div>
            ),
            width: "120px",
            sortable: false,
        },
    ];

   
    // Custom styling for the table
    const customStyles = {
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",        
                border: "1px solid var(--border-color)",
                overflow: "hidden"
            },
    },
        headCells: {
        style: {
            backgroundColor: "var(--light-blue)",
            justifyContent: 'flex-start !important',
            color: "var(--dark-gray)",
            fontWeight: "400",
            fontSize: "15px",
        },
        },
        rows: {
        style: {
            height: "45px", // Set row height
            justifyContent: "start",
        },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    }; 

  return (
    <div className='member-stats-section club-promotions'>
        <Drawer
            anchor={'right'}
            open={showDrawer}
            onClose={() => setShowDrawer(false)}
            className='member-stats-drawer'
            sx={{
                '& .MuiPaper-root':{
                    padding: '30px 25px',
                    height: "100vh",
                    overflow: "hidden"
                },
                '& .MuiDrawer-paper': {
                    width: '420px'
                },
                '& .MuiBox-root':{
                    width: '420px'
                },
                '& .MuiList-root':{
                    display: 'flex',
                    columnGap: '10px',
                    width: '90%',
                    margin: 'auto',
                    paddingTop: "25px"
                },
                '& .MuiButtonBase-root':{
                    padding: '7px',
                    textAlign: 'center',
                    alignItems: 'center',
                    borderRadius: '10px',
                    backgroundColor: '#F5F5F5',
                }
            }}
          >
            <div className="drawer-top-box">
                <div>Filter</div>
                <button type="button" class="btn-close" aria-label="Close" onClick={() => setShowDrawer(false)}></button>
            </div>
            <div className="drawer-bottom-box mt-4">
                <Form onSubmit={(e) => e.preventDefault()}>         
                    {/* Start Date */}
                    <div className='item start-date-input'>                        
                        <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em11">
                            <Form.Label>Start Date</Form.Label>
                            <div className='input-border'>
                                <DatePicker 
                                    id='start_date' 
                                    onChange={(value) => {
                                        setFromDate(value);
                                        if (toDate && value > toDate) {
                                            setToDate(null); // Reset "To Date" if it becomes invalid
                                        }
                                    }}
                                    placement="autoVerticalEnd"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    {/* End Date */}
                    <div className='item end-date-input'>                        
                        <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em4">
                            <Form.Label>End Date</Form.Label>
                            <div className='input-border'>
                                <DatePicker 
                                    id='end_date' 
                                    onChange={(value) => setToDate(value)}
                                    placement="autoVerticalEnd"
                                    shouldDisableDate={(date) => {
                                        // Disable dates before the selected Start Date
                                        return fromDate ? date < fromDate : false;
                                    }}   
                                />
                            </div>
                        </Form.Group>
                    </div>             
                    {/* Search */}
                    <div className='item'>                        
                        <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em5">
                            <Form.Label>Search</Form.Label>
                            <div className='input-border'>
                                <Form.Control
                                    type="text"
                                    className='input-field'
                                    name='search'
                                />
                            </div>
                        </Form.Group>
                    </div>
                    {/* Name */}
                    <div className='item'>                        
                        <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em6">
                            <Form.Label>Name</Form.Label>
                            <div className='input-border'>
                                <Form.Control
                                    type="text"
                                    className='input-field'
                                    name='name'
                                />
                            </div>
                        </Form.Group>
                    </div>
                    {/* Category */}
                    <div className='item'>                        
                        <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em7">
                            <Form.Label>Category</Form.Label>
                            <div className='input-border'>
                              <Select
                                  theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 5,
                                  colors: {
                                      ...theme.colors,
                                  },
                                  })}
                                  placeholder="Select"
                                  isSearchable={false}
                                  isClearable={true}
                              />
                            </div>
                        </Form.Group>
                    </div>
                    <div className='submit-box d-flex align-items-end justify-content-end gap-3' style={{ height: "40vh"}}>
                        <button className='btn-type-2 cursor-pointer' >Clear</button>
                        <button className='btn-type-1' type="submit">Submit</button>
                    </div>
                </Form>
            </div>

        </Drawer>
        <div className='page-details'>
            <div className='top-section d-flex align-items-center justify-content-between'>
                <div className='left'>List Clubs Promotions</div>
                <div className='right d-flex align-items-center  gap-3'>
                    <div  className='filter-box d-flex align-items-center gap-3 cursor-pointer' onClick={() => setShowDrawer(true)}>
                        <FilterIcon />
                        <span>Filter</span>
                    </div> 
                    <div className='add-clubs-info cursor-pointer' title="Create" onClick={() => navigate(CREATE_CLUBS_PROMOTIONS, {state : {currentPageName: "Add Clubs Promotion" }})}>                     
                        <AddIcon />
                    </div>
                </div>
            </div>

            <div className='table-box mt-3'>
                <DataTable
                    columns={columns}
                    data={data}
                    customStyles={customStyles}
                    fixedHeader
                    highlightOnHover
                    responsive
                />
            </div>
        </div>
    </div>
  )
}

export default ClubsPromotions