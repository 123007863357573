import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';

const WelcomeAlert = ({showSweetAlert, setShowSweetAlert}) => {
  
  return (
    <div>
        {showSweetAlert 
          && <SweetAlert
              warning
              showCancel
              confirmBtnText="OK"
            //   cancelBtnText="Cancel"                      
              confirmBtnCssClass="custom-confirm-button-class welcome-ok-btn"
              cancelBtnCssClass="custom-cancel-button-class d-none"
              title={
              <>
                <span className="custom-sweetalert-title">Update & Explore More!</span>
                <div className='text-center' style={{fontSize: "15px", fontWeight: "normal", lineHeight: 1.4}}>
                    Please update and publish your club’s profile. 
                    Once published, you may explore all sections of your CMS. There is so much to explore!
                </div>
              </>
              }              
              onConfirm={() => setShowSweetAlert(false)}
              onCancel={() => setShowSweetAlert(false)}
              focusCancelBtn
          />}
    </div>
  )
}

export default WelcomeAlert