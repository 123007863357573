import React, { useEffect, useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import SweetAlertModal from '../../SweetAlert/SweetAlertModal';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { FaPlusSquare, FaMinusSquare, FaCheckSquare, FaSquare } from 'react-icons/fa';
import { USER_MANAGEMENT } from '../../../constants/pathname';
import { useNavigate } from 'react-router-dom';
import { createOrUpdateRole, getAllPermissions, retriveRoleInfo } from '../../../api/actions/usermanagement';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { checkTheme } from '../../../utils/utils';

const AddRoles = () => {
    const [showSweetAlert, setShowSweetAlert] = useState(false);
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [nodes, setNodes] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [userInfoObj, setUserInfoObj] = useState({});

    const navigate = useNavigate();
    
    const {id} = useParams();
    
     // Setting permission list
    useEffect(() => {        
        let userInfo = JSON.parse(localStorage.getItem("user_info"));

        if (userInfo) {
            setUserInfoObj(userInfo);
        }               
    
    }, [id]);

    const validationSchema = Yup.object({
        name: Yup.string().required('Required'),
        permissions: Yup.array().min(1, 'At least select one permission'),
    });

    useEffect(() => {
        getAllPermissions()((res) => {
            if (res?.status_code == 200 || res?.status_code == 201) {
                let permissionsArr = res?.data?.permissions || [];
                if(checkTheme() && Array.isArray(permissionsArr) && permissionsArr?.length){
                    permissionsArr = permissionsArr.filter((item) => item?.label !== "Letter Of Introduction" && item?.label !== "Affiliated Clubs");
                }
                setNodes(permissionsArr || []);                
            }
        })
    }, [])
    console.log("super user", userInfoObj?.is_superuser );
    
    useEffect(()=>{
        if(id){
          retriveRoleInfo(id)((res)=>{
            if(res?.status_code == 200 || res?.status_code == 201){
                const numArray = res?.data?.role?.permissions?.map(item => Number(item))
                const filterArray = numArray.filter(Boolean)
                setChecked(filterArray)

                formik.setValues({
                    ...formik.values,
                    role: res?.data?.role?.id || '',
                    name: res?.data?.role?.name || '',
                    permissions: res?.data?.role?.permissions || [],
                });                
                
                if(Array.isArray(res?.data?.role?.permissions) && 
                    (res?.data?.role?.permissions?.length === 64 || 
                        (checkTheme() && res?.data?.role?.permissions?.length === 59)
                    )){
                    setSelectAll(true);
                }
                
            }
          })
        }
      },[])

    const handleDelete = () => {
        setShowSweetAlert(true);
    };

    const handleTreeChange = (e) =>{
        setChecked(e);

        formik.setValues({...formik.values, permissions: e});

        console.log("handleTreeChange==", e, e.length);

        if((Array.isArray(e) && e.length === 64) || (checkTheme() && e.length === 59)){
            handleSelectAll();
        }else{
            setSelectAll(false);
        }
        
    }

    
    const formik = useFormik({
        initialValues: {
            name: "",
            permissions: []
        },
        validationSchema,
        onSubmit: (values,{resetForm}) => {
           if(id){
            values.role = id
           }
           setSubmitting(true);
           createOrUpdateRole(values)((res)=>{            
            
            if(res?.status_code == 200 || res?.status_code == 201){               
                toast.success(id ? "Updated Role" : "Success")
                resetForm()
                navigate(USER_MANAGEMENT)
            }else{
                toast.error(res?.response?.data?.errors?.name?.[0] || "Something went wrong")
            }
            setSubmitting(false);
           })
        }
    });

    const customNode = nodes.map((nodeValue) => ({

        value: nodeValue.id,
        label: nodeValue.label,
        children: nodeValue.childNodes.map((childnode) => ({
            value: childnode.id,
            label: childnode.label,
            children: childnode.childNodes.map((innerChild) => ({
                value: innerChild.id,
                label: innerChild.label,

            }))
        }))

    }
    ))


    const handleSelectAll = () => {
        if (selectAll) {
            setChecked([]);
            formik.setValues({ ...formik.values, permissions: [] });
        } else {
            const allValues = [];
    
            const getAllPermissions = (nodes) => {
                nodes.forEach(node => {
                    if (Number.isInteger(node.value)) {
                        allValues.push(node.value);
                    }
                    if (node.children) {
                        getAllPermissions(node.children);
                    }
                });
        };
    
            getAllPermissions(customNode);
            setChecked(allValues);
            formik.setValues({ ...formik.values, permissions: allValues });
        }
        setSelectAll(!selectAll);
    };
    


    return (
        <div className='user-management-section'>
            <SweetAlertModal showSweetAlert={showSweetAlert} setShowSweetAlert={setShowSweetAlert} />

            <div className='page-details'>
                <div className='panel-type-1'>Role</div>
                <div className='details'>
                    <Form onSubmit={formik.handleSubmit}>
                        <div className='row'>
                            <div className='item col-sm-12 col-md-6'>
                                <Form.Group>
                                    <Form.Label>Role Name<sup className='text-danger'>*</sup></Form.Label>
                                    <Form.Control
                                        type="text"
                                        className='input-field'
                                        name='name'
                                        value={formik.values.name}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            formik.setFieldValue(
                                              'name',
                                              value.charAt(0).toUpperCase() + value.slice(1)
                                            );
                                          }}
                                    />
                                     <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className='col-sm-12 col-md-6'>
                                <Form.Group>
                                    <Form.Label>Permissions<sup className='text-danger'>*</sup></Form.Label>
                                    <div className="d-flex align-items-center mb-2">
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                            className="me-2"
                                        />
                                        <label>Select All</label>
                                    </div>
                                    <CheckboxTree
                                        nodes={customNode}
                                        checked={checked}
                                        expanded={expanded}
                                        onCheck={handleTreeChange}
                                        onExpand={setExpanded}
                                        icons={{
                                            check: <FaCheckSquare />,
                                            uncheck: <FaSquare />,
                                            halfCheck: <FaCheckSquare style={{ opacity: 0.5 }} />,
                                            expandClose: <FaPlusSquare />,
                                            expandOpen: <FaMinusSquare />,
                                        }}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-5'>
                            <button className='btn-type-2 cursor-pointer' onClick={() => navigate(USER_MANAGEMENT)}>Cancel</button>
                            <button className='btn-type-1' type="submit" disabled={submitting} >{submitting ? "Submitting" : "Submit"}</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default AddRoles;
