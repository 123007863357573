import React, { useState } from 'react'
import { Container, Form, Row, Col, Card, Button } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";

const CurationProfile=() => {
    const [otherInterest, setOtherInterest]=useState("");
    const [otherFacility, setOtherFacility]=useState("");
    const [selectedEvents, setSelectedEvents]=useState("");
    const [programs, setPrograms]=useState([
        { program: "", location: "", time: "" },
    ]);

    const addProgram=() => {
        setPrograms([...programs, { program: "", location: "", time: "" }]);
    };

    const removeProgram=(index) => {
        const updatedPrograms=programs.filter((_, i) => i!==index);
        setPrograms(updatedPrograms);
    };

    const facilities=[
        { label: "Golf", value: "golf" },
        { label: "Tennis", value: "tennis" },
        { label: "Sailing", value: "sailing" },
        { label: "Polo", value: "polo" },
        { label: "Beach", value: "beach" },
        { label: "Ski", value: "ski" },
        { label: "Motor Sport", value: "motor_sport" },
        { label: "Other", value: "other" },
    ];

    const eventOptions=[
        "Less than 50 Events per Year",
        "51-100 Events per Year",
        "101-150 Events per Year",
        "151-200 Events per Year",
        "201-250 Events per Year",
        "251-300 Events per Year",
        "More than 300 Events per Year",
    ];

    const interests=[
        { label: "Family Activities", value: "family_activities" },
        { label: "Golf/Tennis", value: "golf_tennis" },
        { label: "Fashion", value: "fashion" },
        { label: "Automobiles/RVs/Boats", value: "automobiles" },
        { label: "Cuisine", value: "cuisine" },
        { label: "Art & Architecture", value: "art_architecture" },
    ];
    return (
        <div className='manage-members-page cur'>
            <div className='manage-members member-stats-section'>
                <div className='page-details'>
                    <div className='top-section'>
                        <div className='left'>
                            Curation Profile
                        </div>
                    </div>
                    <Container className="mt-4">
                        <Card>
                            <Card.Body>
                                <Row className="mb-3">
                                    <Col>
                                        <h5 style={{ fontWeight: 500 }}>
                                            1. Tell us about your Members:
                                        </h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h6 style={{ fontWeight: 500 }}>Age</h6>
                                    </Col>
                                </Row>

                                {[
                                    "Under 30 years of age",
                                    "31-40 years of age",
                                    "41-50 years of age",
                                    "51-60 years of age",
                                    "61-70 years of age",
                                    "Over 70 years of age",
                                ].map((ageGroup, index) => (
                                    <Row className="align-items-center mb-2" key={index}>
                                        <Col md={4}>
                                            <Form.Label>{ageGroup} = </Form.Label>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Control type="number" placeholder="%" />
                                        </Col>
                                        <Col md={1}>
                                            <span>%</span>
                                        </Col>
                                    </Row>
                                ))}
                            </Card.Body>
                        </Card>
                        <Card className='mt-4'>
                            <Card.Body>
                                <Row className="mb-3">
                                    <Col>
                                        <h5 style={{ fontWeight: 500 }}>
                                            2. Percentage split by Gender
                                        </h5>
                                    </Col>
                                </Row>
                                {[
                                    "Male ",
                                    "Female "
                                ].map((ageGroup, index) => (
                                    <Row className="align-items-center mb-2" key={index}>
                                        <Col md={4}>
                                            <Form.Label>{ageGroup} = </Form.Label>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Control type="number" placeholder="%" />
                                        </Col>
                                        <Col md={1}>
                                            <span>%</span>
                                        </Col>
                                    </Row>
                                ))}
                            </Card.Body>
                        </Card>
                        {/* Section 3: Members' Interests */}
                        <Card className="mt-4">
                            <Card.Body>
                                <h5 style={{ fontWeight: 500 }}>
                                    3. What are your Members Interests?
                                </h5>
                                <Row className='mt-3'>
                                    {interests.map((interest, index) => (
                                        <Col md={4} key={index}>
                                            <Form.Check type="checkbox" label={interest.label} />
                                        </Col>
                                    ))}
                                </Row>
                                <Row className="mt-3">
                                    <Col md={12}>
                                        <Form.Check
                                            type="checkbox"
                                            label="Other"
                                            onChange={(e) => setOtherInterest(e.target.checked? "":otherInterest)}
                                        />
                                    </Col>
                                </Row>
                                {otherInterest!==null&&(
                                    <Row className="mt-2">
                                        <Col md={12}>
                                            <Form.Control
                                                type="text"
                                                placeholder="If other, please type answer here..."
                                                value={otherInterest}
                                                onChange={(e) => setOtherInterest(e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </Card.Body>
                        </Card>
                        {/* Section 4: Fun Fact */}
                        <Card className="mt-4">
                            <Card.Body>
                                <h5 style={{ fontWeight: 500 }}>
                                    4. Tell us a fun fact about your Members that makes them unique and special:
                                </h5>
                                <Form.Control className='mt-3' as="textarea" rows={2} placeholder="please type here....." />
                            </Card.Body>
                        </Card>
                        {/* Section 5: Engaging Event */}
                        <Card className="mt-4">
                            <Card.Body>
                                <h5 style={{ fontWeight: 500 }}>
                                    5. What do you believe would be the most appealing and engaging event/activity for Members of your Club?
                                </h5>
                                <Form.Control className='mt-3' as="textarea" rows={2} placeholder="please type here....." />
                            </Card.Body>
                        </Card>
                        {/* Section 6: Athletic Facilities */}
                        <Card className="mt-4">
                            <Card.Body>
                                <h5 style={{ fontWeight: 500 }}>
                                    6. What are the primary athletic facilities on-site at your club? Check all that apply:
                                </h5>
                                <Row className="mt-3">
                                    {facilities.map((facility, index) => (
                                        <Col md={4} key={index} className="mb-2">
                                            <Form.Check type="checkbox" label={facility.label} />
                                        </Col>
                                    ))}
                                </Row>
                                {facilities.some(f => f.value==="other")&&(
                                    <Row className="mt-2">
                                        <Col md={12}>
                                            <Form.Control
                                                type="text"
                                                placeholder="If other, please type answer here..."
                                                value={otherFacility}
                                                onChange={(e) => setOtherFacility(e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </Card.Body>
                        </Card>
                        {/* Section 7: Calendar Events */}
                        <Card className="mt-4">
                            <Card.Body>
                                <h5 style={{ fontWeight: 500 }}>
                                    7. How many calendared Member Events do you host at your Club per year?
                                </h5>
                                <Row className="mt-3">
                                    {eventOptions.map((event, index) => (
                                        <Col md={4} key={index} className="mb-2">
                                            <Form.Check
                                                type="radio"
                                                label={event}
                                                name="event_count"
                                                onChange={(e) => setSelectedEvents(e.target.value)}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </Card.Body>
                        </Card>
                        {/* Section 8: Type of Events */}
                        <Card className="mt-4">
                            <Card.Body>
                                <h5 style={{ fontWeight: 500 }}>
                                    8. What type of Events would you like to host for your Members?
                                </h5>
                                <p className="text-muted">
                                    (i.e. sports-related, culinary, instructional, educational, fashion, travel, etc.)
                                </p>
                                <Form.Control className="mt-3" as="textarea" rows={2} placeholder="please type here....." />
                            </Card.Body>
                        </Card>
                        {/* Section 9: Special Needs */}
                        <Card className="mt-4">
                            <Card.Body>
                                <h5 style={{ fontWeight: 500 }}>
                                    9. Are there any Special Needs within your Membership Base which we should be aware of to better serve your Club?
                                </h5>
                                <p className="text-muted">
                                    (i.e. large number Senior Citizens, Children with Special Needs, etc.)
                                </p>
                                <Form.Control className="mt-3" as="textarea" rows={2} placeholder="please type here....." />
                            </Card.Body>
                        </Card>
                        {/* Section 10: Contact Details */}
                        <Card className="mt-4">
                            <Card.Body>
                                <h5 style={{ fontWeight: 500 }}>
                                    10. Please provide the details of a point of contact to further the conversation.
                                </h5>
                                <Row className="mt-3">
                                    <Col md={4}>
                                        <Form.Control type="text" placeholder="Name" />
                                    </Col>
                                    <Col md={4}>
                                        <Form.Control type="email" placeholder="Email" />
                                    </Col>
                                    <Col md={4}>
                                        <Form.Control type="text" placeholder="Phone number" />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        {/* Section 11: Programs */}
                        <Card className="mt-4">
                            <Card.Body>
                                <h5 style={{ fontWeight: 500 }}>
                                    11. What programs does your Club currently have in place
                                </h5>
                                <p className="text-muted mb-3" >
                                    (i.e. Book Club, Ladies who Lunch, etc.)
                                </p>

                                {programs.map((program, index) => (
                                    <div key={index} className="border p-3 mb-2">
                                        <Row className="mb-2 mt-3">
                                            <Col md={3}>
                                                Program:
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Program"
                                                    value={program.program}
                                                    onChange={(e) => {
                                                        let newPrograms=[...programs];
                                                        newPrograms[index].program=e.target.value;
                                                        setPrograms(newPrograms);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col md={3}>
                                                Location:
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Location"
                                                    value={program.location}
                                                    onChange={(e) => {
                                                        let newPrograms=[...programs];
                                                        newPrograms[index].location=e.target.value;
                                                        setPrograms(newPrograms);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col md={3}>
                                                Day & Time of Occurrence:
                                            </Col>
                                            <Col md={9}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="1st Thursday of every month at 12 pm"
                                                    value={program.time}
                                                    onChange={(e) => {
                                                        let newPrograms=[...programs];
                                                        newPrograms[index].time=e.target.value;
                                                        setPrograms(newPrograms);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        {index>0&&(
                                            <Button
                                                variant="danger"
                                                onClick={() => removeProgram(index)}
                                                className="mt-2"
                                            >
                                                <FaTrash /> Delete
                                            </Button>
                                        )}
                                    </div>
                                ))}

                                <Button variant="primary" className="mt-3" onClick={addProgram}>
                                    Add More
                                </Button>
                            </Card.Body>
                        </Card>
                        {/* Section 12: Location */}
                        <Card className="mt-4">
                            <Card.Body>
                                <h5 style={{ fontWeight: 500 }}>
                                    12. Tell us about your location. What makes your neighbourhood/city unique?
                                </h5>
                                <Form.Control className="mt-3" as="textarea" rows={2} placeholder="please type here....." />
                            </Card.Body>
                        </Card>

                        {/* Section 13: Club Type */}
                        <Card className="mt-4">
                            <Card.Body>
                                <h5 style={{ fontWeight: 500 }}>
                                    13. Is your club a Non-Profit Club or a For-Profit Club?
                                </h5>
                                <Form className="mt-3">
                                    <Form.Check
                                        type="radio"
                                        label="Non-Profit Club, non-profit status"
                                        name="clubType"
                                        value="non-profit"
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="For-Profit Club, corporately owned"
                                        name="clubType"
                                        value="for-profit"
                                    />
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default CurationProfile