import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Select from "react-select"
import { CLUBS_PROMOTIONS } from '../../constants/pathname'
// Rsuite Datepicker
import { DatePicker } from 'rsuite';
import { useState } from 'react';

const CreateClubsPromotions = () => { 
    const navigate = useNavigate();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            outline: 'none',
            boxShadow: 'none',
            color: 'var(--medium-gray)',
            fontSize: '15px',
            '&:hover': {
                border: '1px solid var(--light-grayish)', // Prevent hover border color
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        menu: (provided) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            boxShadow: 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--light-grayish)' : 'white',
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
      };
    const statusOptions = [{ label: 'Active', value: 'active' }, { label: 'Inactive', value: 'inactive' }];


  return (
    <div className='create-clubs-promotions-page'>
      <div className='create-clubs-promotions create-clubs-info-section create-brands'>
          <div className='page-details'>
              <div className='panel-type-1'>
                  Add Clubs Promotions
              </div>
              <Form onSubmit={(e) => e.preventDefault()}>
                  <div className='mt-5'>
                      <div className="row">
                          {/* Name */}
                          <div className="col-xl-3 col-md-4 mb-3">
                              <Form.Group controlId="name">
                                  <Form.Label>Name<span className='required'>*</span></Form.Label>
                                  <Form.Control type="text" placeholder="Name" name="name" />
                              </Form.Group>
                          </div>
                          {/* Club Name */}
                          <div className="col-xl-3 col-md-4 mb-3">
                              <Form.Group controlId="clubName">
                                  <Form.Label>Club Name<span className='required'>*</span></Form.Label>
                                  <Form.Control type="text" placeholder="Club Name" name="clubName" />
                              </Form.Group>
                          </div>
                          {/* Start Date */}
                          <div className='col-md-3 mb-2 start-date-input'>                        
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1em11">
                                  <Form.Label>Start Date <span className="required">*</span></Form.Label>
                                  <div className='input-border'>
                                      <DatePicker 
                                          id='start_date' 
                                          placement="autoVerticalEnd"
                                          onChange={(value) => {
                                          setFromDate(value);
                                              if (toDate && value > toDate) {
                                                  setToDate(null); // Reset "To Date" if it becomes invalid
                                              }
                                          }}
                                      />
                                  </div>
                              </Form.Group>
                          </div>
                          {/* End Date */}
                          <div className='col-md-3 mb-2 end-date-input'>                        
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1em4">
                                  <Form.Label>End Date <span className="required">*</span></Form.Label>
                                  <div className='input-border'>
                                      <DatePicker 
                                          id='end_date' 
                                          placement="autoVerticalEnd"
                                          shouldDisableDate={(date) => {
                                              // Disable dates before the selected Start Date
                                              return fromDate ? date < fromDate : false;
                                          }} 
                                      />
                                  </div>
                              </Form.Group>
                          </div> 
                          {/* Status */}
                          <div className="col-xl-3 col-md-4 mb-4">
                              <Form.Group controlId="status">
                                  <Form.Label>Status<span className='required'>*</span></Form.Label>
                                  <Select
                                      placeholder="Select"
                                      styles={customStyles}
                                      options={statusOptions}
                                      isClearable
                                      isSearchable
                                  />
                              </Form.Group>
                          </div>
                      </div>
                      <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-5'>
                          <button className='btn-type-2 cursor-pointer' onClick={() => navigate(CLUBS_PROMOTIONS) }>Cancel</button>
                          <button className='btn-type-5' type="submit" onClick={() => navigate(CLUBS_PROMOTIONS) }>Save</button>
                      </div>
                  </div>
              </Form>
          </div>         
      </div>
    </div>
  )
}

export default CreateClubsPromotions