import React from 'react'
import ManageMembers from '../../components/ManageMembers/ManageMembers'

const ManageMembersPage = () => {
  return (
    <div className='manage-members-page'>
        <ManageMembers />
    </div>
  )
}

export default ManageMembersPage