import axios from 'axios';
import { reactLocalStorage } from 'reactjs-localstorage';

let headers = {};

const BACKEND_URL = process.env.REACT_APP_BACKENDURL
// const BACKEND_URL = 'http://192.168.0.126:3000/api'

const axiosInstance = axios.create({
    baseURL: BACKEND_URL,
    headers
});

axiosInstance.interceptors.request.use(
    async (config) => {
        const token = reactLocalStorage.get('token');
      

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        let params = {}
        params.cms = true
        const currentTheme = document.documentElement.getAttribute('theme');
        if (currentTheme == 'platinum-club-net') {
            params.pcn = true
        }
        else {
            params.pcn = false
        }
        
        config.params = {
            ...config.params,
            ...params,

        }
        return config;
    },
    (error) => {
        console.log(error);
        
        return Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (response) =>
        new Promise((resolve, reject) => {
            resolve(response);
        }),
    (error) => {
        if (!error.response) {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }

        if (error.response.status === 403) {

        }
        else if(error.response.status === 401){
            localStorage.clear()
            window.location = "/login";
        }
        else {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }
    },
);

export default axiosInstance;