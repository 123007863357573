import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { ReactComponent as UploadIcon } from "../../assets/images/common/cloud-upload-alt.svg";
import { ReactComponent as PlusIcon } from "../../assets/images/common/plus-circle-line.svg";
import imageToBase64 from 'image-to-base64/browser';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import Select from 'react-select';
import About from './About';
// Google maps
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import Gallery from './Gallery';
import { CLUBS_INFORMATION } from '../../constants/pathname';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RiDeleteBin6Line } from "react-icons/ri";
import { getClubProfile } from '../../api/actions/clubProfile';
const ClubProfile = () => {
    const navigate = useNavigate();
    const autocompleteRef = useRef(null);
    const [contacts, setContacts] = useState([{ id: 1, phone: "" }]);
    const location = useLocation();
   
    const { country } = location.state || {};
    const countryOptions = [{ label: 'United States of America', value: 'usa' }];
    const cityOptions = [{ label: 'Palm Beach, FL, USA', value: 'palm-beach' }];
    const clubTypeOptions = [{ label: 'Yacht Club', value: 'yacht-club' }];
    const yesNoOptions = [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }];
    const statusOptions = [{ label: 'Active', value: 'active' }];
    const publishedOptions = [{ label: 'Published', value: 'published' }, { label: 'Not Published', value: 'not-published' }];
    
    const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_API_KEY;
    // Google maps
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries: ['places'],
    });
    const { id } = useParams()
    
    // Google palce select
    const handlePlaceSelect = () => {
        if (autocompleteRef.current) {
            const place = autocompleteRef.current.getPlace();
    
            console.log("Autocomplete Place Object:", place); // Debug full place object
    
            if (place?.geometry?.location) {
                const latitude = place.geometry.location.lat();
                const longitude = place.geometry.location.lng();
                const addressComponents = place.address_components || [];
    
                console.log("Latitude:", latitude);
                console.log("Longitude:", longitude);
                console.log("addressComponents:", addressComponents);
    
                let country = "";
                addressComponents.forEach((component) => {
                    if (component.types.includes("country")) {
                        country = component.long_name;
                    }
                });
    
                console.log("Selected Country:", country);
                console.log("Selected City:", place.name);
    
                formik.setValues({
                    ...formik.values,
                    city: place.name,
                    latitude: latitude,
                    longitude: longitude,
                    country: country
                });
    
                console.log("Updated Formik Values:", formik.values);
            }
        }
    };
    
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            outline: 'none',
            boxShadow: 'none',
            color: 'var(--medium-gray)',
            fontSize: '15px',
            '&:hover': {
                border: '1px solid var(--light-grayish)', // Prevent hover border color
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        menu: (provided) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            boxShadow: 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--light-grayish)' : 'white',
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
    };

    // Handle input change
    const handleInputChange = (id, value) => {
        setContacts(
            contacts.map((contact) =>
                contact.id === id ? { ...contact, phone: value } : contact
            )
        );
    };

    const formik = useFormik({
        initialValues: {
            images: [],
            club_name: "",
            club_type: "",
            selected_clubtype: [],
            year_founded: '',
            club_website: '',
            club_logo: '',
            city: '',
            selected_city: [],
            country: '',
            selected_country: [],

            about_club: '',
            primary_address: '',
            secondary_address: '',
            golf_booking: '',

            thumbnailName: "",
            contact_details: [{
                first_name: "",
                last_name: "",
                email: "",
                phone_number: ""
            }],
            club_rules: [{
                club_rules: '',
            }],
            latitude: '',
            longitude: '',
            club_images_details: [{
                image: '',
            }]
        },
        onSubmit: (values, resetForm) => {
        }
    });

    const handleImageChange = async (e) => {
        const files = Array.from(e?.target?.files);
        const maxSizeInBytes = 1 * 1024 * 1024;
        const validImageTypes = ['image/jpeg', 'image/png'];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (!validImageTypes.includes(file?.type)) {
                toast.error("Only jpg/jpeg and png files are allowed!");
                e.target.value = null;
                return;
            }

            if (file?.size > maxSizeInBytes) {
                toast.error("File size exceeds 2 MB limit", 'error');
                e.target.value = null;
                return;
            }
        }

        const images = await Promise.all(
            files.map(async (file) => {

                try {
                    const response = await imageToBase64(URL.createObjectURL(file));
                    return `data:image/jpeg;base64,${response}`;
                } catch (error) {
                    return null;
                }
            })
        );

        // Setting file name
        let fileName = "";
        if (Array.isArray(files) && files[0]?.name) {
            fileName = files[0].name;
        }

        formik.setValues({
            ...formik.values,
            images: [...images], // Ensure images is an array
            thumbnailName: fileName
        });

        e.target.value = null;
    }

    const removeThumbNail = () => {
        formik.setValues({
            ...formik.values,
            images: [], // Ensure images is an array
            thumbnailName: ""
        });
    }

    const addContact = () => {
        formik.setFieldValue("contact_details", [
            ...formik.values.contact_details,
            { first_name: "", last_name: "", email: "", phone_number: "" }
        ]);
    };

    const removeContact = (index) => {
        const updatedContacts = [...formik.values.contact_details];
        updatedContacts.splice(index, 1);
        formik.setFieldValue("contact_details", updatedContacts);
    };

    useEffect(() => {
        if (id) {
            getClubProfile({ id, country: country })((response) => {
                if (response.status) {
                 
                    formik.setValues({
                        ...formik.values,
                        club_name: response.data?.club_name,
                        club_type: response.data?.club_type,
                        year_founded: response.data?.year_founded || "",
                        club_website: response.data?.club_website || "",
                        club_logo: response.data?.club_logo || "",
                        city: response.data?.city_name || "",
                        country: response.data?.country_name || "",
                        about_club: response.data?.about_club || "",
                        primary_address: response.data?.primary_address || "",
                        secondary_address: response.data?.secondary_address || "",
                        golf_booking: response.data?.golf_booking || "",
                        latitude: response.data?.latitude || "",
                        longitude: response.data?.longitude || "",
                        contact_details: response.data?.contact_details || [],
                        club_rules: response.data?.club_rules || [],
                        club_images_details: response?.data.club_images || [],
                    });
                }
            });
        }
    }, [id, country]);

   return (
    <div className='club-profile-section'>
        <div className='panel-type-1'>
            Club Profile
        </div>
        <Form onSubmit={formik.handleSubmit}>
            <div className='club-form mt-4'>
                <div className='row'>
                    <div className='col-md-3'>
                        {/* Upload Thumbnail */}
                        <div className='item upload'>
                            <label>Upload Thumbnail <span className='required'>*</span></label>
                            <div className='d-flex align-items-end mt-2'>
                                <div>
                                    {Array.isArray(formik.values?.images) && !formik.values?.images?.length
                                        ?
                                        <label className='upload-icon cursor-pointer' htmlFor='fileInput'>
                                            <UploadIcon />
                                            <div className='mt-3'>Upload</div>
                                        </label>
                                        :
                                        <div className={`uploaded-images ${Array.isArray(formik.values?.images) && formik.values?.images?.length > 0 ? 'active' : ''}`}>
                                            {formik.values.images?.map((image, index) => (
                                                <div className='pos-rel' key={index}>
                                                    <img key={index} src={image} alt={`Uploaded ${index}`} className="uploaded-image" />
                                                </div>
                                            ))}
                                        </div>}
                                    {formik?.values?.thumbnailName &&
                                        <div className='filename mt-2 d-flex align-items-center justify-content-between gap-2'>
                                            <div className='name' title={formik?.values?.thumbnailName || ""}>{formik?.values?.thumbnailName || ""}</div>
                                            <RiDeleteBin6Line size={16} fill='var(--red)' title='Delete' onClick={() => removeThumbNail()} />
                                        </div>}
                                    <div className='mt-2 w-75'>
                                        {Array.isArray(formik.values?.images) && !formik.values?.images?.length &&
                                        <div className='info m-0'>
                                            Use JPEG or PNG. Best size 1280×720 pixels. Keep it under 1MB
                                        </div>}
                                    </div>
                                </div>
                                <input
                                    id="fileInput"
                                    className='d-none'
                                    type="file"
                                    onChange={handleImageChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-9'>
                        <div className='row'>
                            {/* Club Name */}
                            <div className="col-xl-12 col-md-4 mb-3">
                                <div className='col-xl-6 col-md-12'>
                                    <Form.Group controlId="clubName">
                                        <Form.Label>Club Name<span className='required'>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Club Name" name="club_name"
                                            value={formik?.values?.club_name}
                                            onChange={formik.handleChange}
                                            isInvalid={formik?.touched?.club_name && formik?.errors?.club_name} />
                                        <Form.Control.Feedback type="invalid">{formik?.errors?.club_name}</Form.Control.Feedback>

                                    </Form.Group>
                                </div>
                            </div>
                            {/* Year Founded */}
                            <div className="col-md-4 mb-4">
                                <Form.Group controlId="yearFounded">
                                    <Form.Label>Year Founded</Form.Label>
                                    <Form.Control type="text" placeholder="Year Founded" name="year_founded"
                                        onWheel={(e) => e.target.blur()}
                                        onChange={formik.handleChange}
                                        disabled />

                                </Form.Group>
                            </div>
                            {/* Country */}
                            <div className="col-xl-4 col-md-4 mb-4">
                                <Form.Group controlId="country">
                                    <Form.Label>Country<span className='required'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Country"
                                        name="country"
                                        value={formik.values.country}
                                        disabled
                                    />
                                </Form.Group>
                            </div>
                            {/* City */}
                            <div className="col-xl-4 col-md-4 mb-4">
                                <Form.Group controlId="city">
                                    <Form.Label>City</Form.Label>
                                    {isLoaded && (
                                        <Autocomplete
                                            onLoad={(auto) => (autocompleteRef.current = auto)}
                                            onPlaceChanged={handlePlaceSelect}
                                            // options={{
                                            //     types: ['(cities)'], // Restrict to cities
                                            //     componentRestrictions: { country: 'us' }, // Replace 'us' with your desired country code
                                            // }}
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter city"
                                                name="city"
                                                value={formik.values.city}
                                                onChange={formik.handleChange}
                                            />
                                        </Autocomplete>
                                    )}
                                </Form.Group>
                            </div>                            
                            {/* Status */}
                            <div className="col-md-4 mb-4">
                                <Form.Group controlId="status">
                                    <Form.Label>Status<span className='required'>*</span></Form.Label>
                                    <Select
                                        placeholder="Select"
                                        styles={customStyles}
                                        options={statusOptions}
                                        isClearable
                                        isSearchable
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-5'>
                    <div className="row">
                        {/* Type of Club */}
                        <div className="col-xl-3 col-md-4 mb-4">
                            <Form.Group controlId="clubType">
                                <Form.Label>Type of Club<span className='required'>*</span></Form.Label>
                                <Select
                                    placeholder="Select"
                                    styles={customStyles}
                                    options={clubTypeOptions}
                                    isClearable
                                    isSearchable
                                />
                            </Form.Group>
                        </div>
                        {/* Golf Booking */}
                        <div className="col-xl-3 col-md-4 mb-4">
                            <Form.Group controlId="golfBooking">
                                <Form.Label>Golf Booking<span className='required'>*</span></Form.Label>
                                <Select
                                    placeholder="Select"
                                    styles={customStyles}
                                    options={yesNoOptions}
                                    isClearable
                                    isSearchable
                                />
                            </Form.Group>
                        </div>
                        {/* Club Website */}
                        <div className="col-xl-4 col-md-4 mb-4">
                            <Form.Group controlId="clubWebsite">
                                <Form.Label>Club Website</Form.Label>
                                <Form.Control type="text" placeholder="" name="clubWebsite" disabled />
                            </Form.Group>
                        </div>
                        {/* Address 1 */}
                        <div className="col-xl-6 col-md-4 mb-4">
                            <Form.Group controlId="address1">
                                <Form.Label>Address 1<span className='required'>*</span></Form.Label>
                                <Form.Control as="textarea" placeholder="Address 1" name="primary_address" value={formik?.values?.primary_address} onChange={formik?.handleChange} rows={4} disabled />
                            </Form.Group>
                        </div>
                        {/* Address 2 */}
                        <div className="col-xl-6 col-md-4 mb-4">
                            <Form.Group controlId="address2">
                                <Form.Label>Address 2</Form.Label>
                                <Form.Control as="textarea" placeholder="Address 2" name="address2" rows={4} disabled />
                            </Form.Group>
                        </div>
                        {/* Contact First Name */}
                        <div className="col-xl-12 col-md-4 mb-4">
                            <Form.Group controlId="contact">
                                <Form.Label>Contact<span className='required'>*</span></Form.Label>
                                <div>
                                    {formik?.values?.contact_details?.map((contact, index) => (
                                        <div className='item row align-items-start mb-4' key={index}>
                                            {/* First Name */}
                                            <div className='col-md-3'>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="First Name*"
                                                    name={`contact_details[${index}].first_name`}
                                                    value={contact.first_name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}

                                                />
                                                {formik.touched.contact_details?.[index]?.first_name &&
                                                    formik.errors.contact_details?.[index]?.first_name && (
                                                        <div className="error-msg">{formik.errors.contact_details[index].first_name}</div>
                                                    )}
                                            </div>

                                            {/* Last Name */}
                                            <div className='col-md-2'>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Last Name"
                                                    name={`contact_details[${index}].last_name`}
                                                    value={contact.last_name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                            </div>

                                            {/* Email */}
                                            <div className='col-md-3'>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Email*"
                                                    name={`contact_details[${index}].email`}
                                                    value={contact.email}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.contact_details?.[index]?.email &&
                                                    formik.errors.contact_details?.[index]?.email && (
                                                        <div className="error-msg">{formik.errors.contact_details[index].email}</div>
                                                    )}
                                            </div>

                                            {/* Phone Number */}
                                            <div className='col-md-3'>
                                                <Form.Control
                                                    type="tel"
                                                    placeholder="Phone Number*"
                                                    name={`contact_details[${index}].phone_number`}
                                                    value={contact.phone_number}
                                                    onChange={(e) => {
                                                        const { value } = e.target;
                                                        if (/^[\d\s-+()]*$/.test(value) || value === "") {
                                                            formik.handleChange(e);
                                                        }
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.contact_details?.[index]?.phone_number &&
                                                    formik.errors.contact_details?.[index]?.phone_number && (
                                                        <div className="error-msg">{formik.errors.contact_details[index].phone_number}</div>
                                                    )}
                                            </div>

                                            {/* Delete Button */}
                                            {index > 0 && (
                                                <div className="col-md-1">
                                                    <div className='d-flex align-items-center'
                                                        style={{ background: "var(--light-gray)", width: "fit-content", padding: "10px", borderRadius: "6px" }}>
                                                        <RiDeleteBin6Line
                                                            className="error-msg cursor-pointer"
                                                            onClick={() => removeContact(index)}
                                                            title="Delete Contact"
                                                            style={{ background: "var(--white)" }}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>

                                {/* Add Contact */}
                                <div
                                    className={`${formik.values.contact_details?.length > 3 ? 'pe-none opacity-50' : ''} mt-4 add-contact d-flex align-items-center gap-2 cursor-pointer disable`}
                                    onClick={addContact}
                                    style={{ width: "fit-content" }}
                                >
                                    <PlusIcon />
                                    <div>Add Contact</div>
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </div>
            {/* Contains about club, club rules and location */}
            <About formik={formik} />
            {/* Gallery */}
            <Gallery />
            <div className='submit-box d-flex align-items-center justify-content-end gap-3'>
                <button className='btn-type-2 cursor-pointer' onClick={() => navigate(CLUBS_INFORMATION)}>Cancel</button>
                <button className='btn-type-1' type="submit" onClick={() => navigate(CLUBS_INFORMATION)} >Submit</button>
            </div>
        </Form>

    </div>
);
}

export default ClubProfile