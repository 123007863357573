import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PLACES } from '../../constants/pathname';
import { useFormik } from 'formik';
import { addInterestType, getInterestTypedetails } from '../../api/actions/interestType';
import toast from 'react-hot-toast';
import { MdOutlineDelete } from 'react-icons/md';
import imageToBase64 from 'image-to-base64/browser'
import * as Yup from 'yup';
import { checkTheme } from '../../utils/utils';

const CreatePlaces = () => {
    const navigate = useNavigate();
    const [photo, setPhoto] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const location = useLocation();
    const { name, ids } = location.state || {};
    const { id } = useParams();
    const isNightMode = checkTheme(); // true for PCN (night), false for PCW (day)

    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required'),
        // Require either pcn_image or pcw_image based on current theme
        ...(isNightMode ? { pcn_image: Yup.string().required('Image is required') } : { pcw_image: Yup.string().required('Image is required') })
    });

    const styles = {
        preview: {
            position: "relative",
            minWidth: "100px",
            height: "100px",
            border: "1px solid #EAE9EB",
            borderRadius: "5px",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        image: {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        },
        removeButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
            position: "absolute",
            top: "5px",
            right: "5px",
            background: "#707070",
            border: "none",
            color: "#fff",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            cursor: "pointer",
            fontSize: "16px",
            lineHeight: "18px",
            textAlign: "center",
        },
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            pcn_image: '',
            pcw_image: '',
            description: '',
            interest_type: '',
            publish: false
        },
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (isSubmitting) return;
            setIsSubmitting(true);
            
            const updatedValues = {
                ...values,
                interest_type: ids || values.interest_type,
                publish: values.publish
            };
            
            addInterestType(updatedValues)((response) => {
                setIsSubmitting(false);
                
                if (response.status_code === 200 || response.status_code === 201) {
                    toast.success(id ? "Successfully Updated!" : 'Successfully Created!');
                    resetForm();
                    navigate(-1);
                } else {
                    formik.setErrors({
                        ...formik.errors,
                        name: response?.response?.data?.errors?.non_field_errors?.[0]
                            ? response?.response?.data?.errors?.non_field_errors?.[0]
                            : '',
                    })
                }
            })
        }
    });
    
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
            const maxSize = 5 * 1024 * 1024; // 5MB

            if (!allowedTypes.includes(file.type)) {
                toast.error('Please upload a valid image (JPEG, PNG, or GIF)');
                return;
            }

            if (file.size > maxSize) {
                toast.error('File size should not exceed 5MB');
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setPhoto(base64String);
                
                // Set the image for the current theme mode
                if (isNightMode) {
                    formik.setFieldValue('pcn_image', base64String);
                } else {
                    formik.setFieldValue('pcw_image', base64String);
                }
            };
            reader.readAsDataURL(file);
        }
    };
    
    const removeImage = () => {
        setPhoto(null);
        document.getElementById('themeImageInput').value = '';
        
        // Clear the image for the current theme mode
        if (isNightMode) {
            formik.setFieldValue('pcn_image', '');
        } else {
            formik.setFieldValue('pcw_image', '');
        }
    };

    useEffect(() => {
        if (id) {
            getInterestTypedetails({ id })(async(response) => {
                if (response?.status) {
                    const pcw_image = response?.data?.pcw_image 
                        ? "data:image/png;base64," + await imageToBase64(response?.data?.pcw_image) 
                        : "";
                    const pcn_image = response?.data?.pcn_image 
                        ? "data:image/png;base64," + await imageToBase64(response?.data?.pcn_image) 
                        : "";
                    
                    formik.setValues({
                        ...formik.values,
                        id: response?.data?.id,
                        name: response?.data?.name,
                        description: response?.data?.description,
                        interest_type: response?.data?.interest_type,
                        pcw_image: pcw_image,
                        pcn_image: pcn_image,
                        publish: response?.data?.publish || false,
                    });
                    
                    // Set the photo based on current theme
                    if (isNightMode && pcn_image) {
                        setPhoto(pcn_image);
                    } else if (!isNightMode && pcw_image) {
                        setPhoto(pcw_image);
                    }
                }
            })
        }
    }, [id]);

    return (
        <div className='create-clubs-info-section create-interest-section'>
            <div className='page-details'>
                <div className='panel-type-1'>
                    {id ? "Update Interest" : `Add New ${name}`}
                </div>
                <Form onSubmit={formik.handleSubmit}>
                    <div className='mt-5'>
                        <div className="row flex-column">
                            <div className='row'>
                                <div className="col-md-6 mb-3">
                                    <Form.Group controlId="name">
                                        <Form.Label>Name<span className='required'>*</span></Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            placeholder='Name' 
                                            name="name" 
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                formik.setFieldValue(
                                                    'name',
                                                    value.charAt(0).toUpperCase() + value.slice(1)
                                                );
                                            }}
                                            value={formik.values.name}
                                            isInvalid={formik.touched.name && formik.errors.name} 
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <Form.Group controlId="description">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control 
                                            as="textarea" 
                                            rows={3} 
                                            placeholder="Description" 
                                            name="description"  
                                            value={formik.values?.description} 
                                            onChange={formik.handleChange} 
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                          
                            {/* Theme Responsive Image Upload */}
                            <div className='row'>
                                <div className='col-md-6 mb-4'>
                                    <Form.Group className="mb-3" controlId="themeImage">
                                        <Form.Label>
                                            {isNightMode ? "Interests Image for PCN" : "Interests Image for PCW"}
                                            <span className='required'>*</span>
                                        </Form.Label>
                                        <div className='input-border'>
                                            <Form.Control
                                                type="file"
                                                id="themeImageInput"
                                                className={isNightMode ? 'input-field pcn_icon_class' : 'input-field pcw_icon_class'}
                                                onChange={handleImageChange}
                                            />
                                            {isNightMode && formik.touched.pcn_image && formik.errors.pcn_image && (
                                                <div className="text-danger mt-1">{formik.errors.pcn_image}</div>
                                            )}
                                            {!isNightMode && formik.touched.pcw_image && formik.errors.pcw_image && (
                                                <div className="text-danger mt-1">{formik.errors.pcw_image}</div>
                                            )}
                                            {photo && (
                                                <div className="mt-2 position-relative">
                                                    <img
                                                        src={photo}
                                                        alt="Uploaded"
                                                        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                                    />
                                                    <MdOutlineDelete
                                                        color='red'
                                                        style={{ fontSize: '20px', cursor: 'pointer', marginTop: "80px" }}
                                                        onClick={removeImage}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                        
                        <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-5'>
                            <div className='btn-type-2 cursor-pointer' onClick={() => id ? navigate(`${PLACES}/${id}/${name}`) : navigate(-1)}>Cancel</div>
                            <button className='btn-type-5' type="submit">{id ? "Update" : "Save"}</button>
                            {id ? (
                                <button
                                    className='btn-type-1 publish-btn'
                                    type="button"
                                    disabled={formik.values.publish || isSubmitting}
                                    onClick={() => {
                                        formik.setFieldValue('publish', true, false);
                                        formik.handleSubmit();
                                    }}
                                >
                                    {formik.values.publish ? "Published" : isSubmitting ? "Publishing..." : "Publish"}
                                </button>
                            ) : null}
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default CreatePlaces