import DataTable from "react-data-table-component";
import { useNavigate } from 'react-router-dom';
// Icon
import { CREATE_PUSH_NOTIFICATIONS } from '../../constants/pathname';
import { useEffect, useState } from "react";
import { pushNotificationClub } from "../../api/actions/notifications";
import moment from "moment/moment";
import { paginationRowsPerPageOptions } from "../../helpers/helper";

const PushNotifications = () => { 
     const navigate = useNavigate();
    
    // Data for the table
    const [data, setData] = useState([]);
     // Loading and Update
    const [limit, setLimit] = useState(25);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [pending, setPending] = useState(false);

    useEffect(() =>{
        setPending(true);
        pushNotificationClub({limit, page})((response) =>{
            if(response.status){
                setData(response.data?.results);
                setTotalRows(response?.data?.count);
            }
            setPending(false);
        })
    },[page, limit]); 
      
    // Define columns for the table
    const columns = [
        { 
            name: "Sl.No", 
            selector: row => row.club, 
            cell: (row, index) =>
                <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top">
                    {(limit * (page - 1)) + (index + 1)}
                </div>,
            width: "70px"},
        { name: "Club", selector: row => <div title={row.club_name}>{row.club_name}</div>, minWidth: "200px"  },
        { name: "Title", selector: row => <div title={row.title}>{row.title}</div>, width: "300px" },
        { name: "Users", selector: row => <div title={row.users}>{row.users}</div>, minWidth: "200px"  },
        { name: "Description", selector: row => <div title={row.description}>{row.description}</div>, minWidth: "200px"  },
        { name: "Count", selector: row => row.sent_count, minWidth: "100px"  },
        { name: "Sent On", selector: row => moment(row.created_date).format("MMMM DD YYYY | hh:mm:ss A"), minWidth: "300px"  },
        ];
    
    // Scroll to top    
    useEffect(() => {
        if (window) {
            window.scrollTo(0, 0);
        }
    }, []);

    // Custom styling for the table
    const customStyles = {
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",        
                border: "1px solid var(--border-color)",
                overflow: "hidden"
            },
    },
        headCells: {
        style: {
            backgroundColor: "var(--light-blue)",
            justifyContent: 'flex-start !important',
            color: "var(--dark-gray)",
            fontWeight: "400",
            fontSize: "15px",
        },
        },
        rows: {
        style: {
            height: "45px", // Set row height
            justifyContent: "start",
        },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    }; 

  return (
    <div className='member-stats-section brands'>       
        <div className='page-details'>
            <div className='top-section d-flex align-items-center justify-content-between'>
                <div className='left'>Push Notifications</div>
                <div className='right d-flex align-items-center  gap-3'>
                    <div className='btn-type-6 cursor-pointer' onClick={() => navigate(CREATE_PUSH_NOTIFICATIONS, {state : {currentPageName: "Add Push Notification by Club" }})} title="Send Push Notification by Club">
                        Send Push Notification by Club
                    </div>
                </div>
            </div>

            <div className='table-box mt-3'>
                <DataTable
                    columns={columns}
                    data={data}
                    customStyles={customStyles}
                    fixedHeader
                    highlightOnHover
                    responsive
                    pagination
                    paginationServer
                    paginationPerPage={limit}
                    progressPending={pending}
                    onChangeRowsPerPage={setLimit}
                    paginationTotalRows={totalRows}
                    paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                    onChangePage={setPage}
                />
            </div>
        </div>
    </div>
  )
}

export default PushNotifications