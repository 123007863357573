import { ACCEPT_BOOKING_NOTIFICATION, CREATE_NOTIFICATION_CLUB, GET_NOTIFICATIONS_LIST, LIST_NOTIFICATION_CLUB, READ_USER_NOTIFICATION, REJECT_BOOKING_NOTIFICATION } from "../api";
import axiosInstance from "../axiosinstance";
import { checkTheme } from "../../../src/utils/utils";
import { WALKTHROUGH_DOCUMENT } from "../../constants/pathname";
export const notificationList = (props) => onResponse => {

    try {
        let BASE_URL = `${GET_NOTIFICATIONS_LIST}?`;

        if (props?.page) {
            BASE_URL += 'page=' + props.page + '&'
        }
        if (props?.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};

export const createNotificationClub = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_NOTIFICATION_CLUB, props)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};

export const readnotification = (props) => onResponse => {
    try {
        axiosInstance.post(READ_USER_NOTIFICATION, props)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};

export const acceptbookingNotifiaction = (props) => onResponse => {
    try {
        axiosInstance.post(ACCEPT_BOOKING_NOTIFICATION, props)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};


export const rejectBookingNotification = (props) => onResponse => {
    try {
        axiosInstance.post(REJECT_BOOKING_NOTIFICATION, props)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};



export const pushNotificationClub = (props) => onResponse => {

    try {
        let BASE_URL = `${LIST_NOTIFICATION_CLUB}?`;

        if (props?.page) {
            BASE_URL += 'page=' + props.page + '&'
        }
        if (props?.limit) {
            BASE_URL += 'limit=' + props.limit
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};

export const downloadWalkThrough = async (prop) => {
    console.log(prop,"PROP_____")
    const myHeaders = new Headers();
    let token = localStorage.getItem('token');

    myHeaders.append(`Authorization`, `Bearer ${token}`);
    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };
    try {
        const res = await fetch(`${process.env.REACT_APP_BACKENDURL}${WALKTHROUGH_DOCUMENT}?cms=true&pcn=${prop}`, requestOptions)
        // const res = await fetch("https://api.platinumclubnet.com/api/club/walk-through-document/download-walk-through-document?cms=true&pcn=false", requestOptions)
        const blob = await res.blob()
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = url;
        a.download = "Letter of Introduction.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    } catch (err) {
        console.log("failed to get document", err)
    }
}