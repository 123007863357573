import React from 'react'
import Places from '../../components/Places/Places'

const PlacesPage = () => {
  return (
    <div className='places-page'>
        <Places />
    </div>
  )
}

export default PlacesPage