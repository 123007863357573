import { ADD_BRAND, ADD_BRAND_CATEGORY, BRAND_DETAILS, BRAND_STATUS, DELETE_BRAND, LIST_BRAND } from "../api";
import axiosInstance from "../axiosinstance";

export const listBrands = (props) => onResponse => {
    try {
        let BASE_URL = LIST_BRAND + '?'
        if (props.page) {
            BASE_URL += 'page=' + props.page + '&'

    }
        if (props.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if(props.sort_by){
            BASE_URL+='sort_by='+props.sort_by+'&'
        }
        if(props.order){
            BASE_URL+='order='+props.order+'&'
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }

};

export const addBrand=(props) => onResponse => {
    try {
        axiosInstance.post(ADD_BRAND, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const deleteBrand = (id) => onResponse => {
    try {

        axiosInstance.delete(DELETE_BRAND, { data: { id: [id] } })

            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};


export const getBrandDetails = (props) => onResponse => {
    try {
        axiosInstance.get(BRAND_DETAILS + '?id=' + props.id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getBrandStatusChanging=(props)=>onResponse=>{
    try{
        axiosInstance.post(BRAND_STATUS,props)
        .then((response) => {
            onResponse(response.data);
        })
        .catch((err) => {
            onResponse(err);
        });


    } catch(error){

    }
};