import { Form, Modal } from 'react-bootstrap'
import { CLUBS_FACILITIES } from '../../constants/pathname';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as UploadIcon } from "../../assets/images/common/cloud-upload-alt.svg";
import Select from 'react-select';
import { useFormik } from 'formik';
import { IoMdInformationCircleOutline } from "react-icons/io";
import { createClubfacitiltyCategory, getfaciltyDetails } from '../../api/actions/addClubFacility';
import toast from 'react-hot-toast';
import Gallery from './Gallery';
import { IoClose } from "react-icons/io5";
import Description from './Description';
import { useEffect, useState } from 'react';
import { faciltiyCategoryDropdown } from '../../api/actions/addFacitiltycCategory';
import * as Yup from 'yup';
import { RiDeleteBin6Line } from "react-icons/ri";
import { Checkbox, IconButton, TextField, Button } from "@mui/material";
import { AiOutlinePlus, AiOutlineDelete } from "react-icons/ai";
import { IoIosAddCircleOutline } from "react-icons/io";
import CreateFacilityCategory from '../../components/FacilitiesCategory/CreateFacilityCategory';
import AsyncSelect from 'react-select/async';
import { Tag, TimePicker } from 'antd';
import moment from 'moment';
import { FormControlLabel, RadioGroup, styled, Switch } from "@mui/material";
import dayjs from 'dayjs';
import { DatePicker, Space, Radio, Tabs } from 'antd';
import { IoMdAddCircle } from "react-icons/io";
import { checkTheme } from '../../utils/utils';
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const CreateClubsFacilitiesPage = () => {
    const navigate = useNavigate();
    const [categoryOptionsList, setCategoryOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const { id, currentPageName } = location.state || {};
    const [supplierModal, setSupplierModal] = useState(false);
    const [blackoutdate, setblackoutdate] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [closedDays, setClosedDays] = useState({0:true,1:true,2:true,3:true,4:true,5:true,6:true});
    const [showTimeSlots, setShowTimeSlots] = useState({});
    const [show, setShow] = useState(false);
    const [userInfoObj, setUserInfoObj] = useState({});
    const [blackoutDetailId, setBlackoutDetailId] = useState("");
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const toggleModal = () => setShowModal(!showModal);
    const [frequency, setFrequency] = useState('one_time');
    const [selectedDates, setSelectedDates] = useState({ start_date: null, end_date: null, date: null });
    const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState([]);
    const [selectedDaysOfMonth, setSelectedDaysOfMonth] = useState([]);
    const [blackoutTitle, setBlackoutTitle] = useState('');
    const [modalFrequency, setModalFrequency] = useState('one_time');
    const [modalSelectedDates, setModalSelectedDates] = useState({ start_date: null, end_date: null, date: null });
    const [modalSelectedDaysOfWeek, setModalSelectedDaysOfWeek] = useState([]);
    const [modalSelectedDaysOfMonth, setModalSelectedDaysOfMonth] = useState([]);
    const [activeTab, setActiveTab] = useState('daily');
    // console.log(JSON.stringify(closedDays))

    const handleBlackoutClose = () => {
        setShow(false);

        // Reset all state variables
        setFrequency('one_time');
        setSelectedDates({ start_date: null, end_date: null, date: null });
        setSelectedDaysOfWeek([]);
        setSelectedDaysOfMonth([]);
        setActiveTab('daily');
        setBlackoutDetailId("");

        formik.setFieldValue('editingBlackoutId', null);
        formik.setFieldValue('title', '');
    };

    const daysMapping = {
        0: "Monday",
        1: "Tuesday",
        2: "Wednesday",
        3: "Thursday",
        4: "Friday",
        5: "Saturday",
        6: "Sunday"
    };
    const handleDateChange = (date, dateString) => {

        if (date) {
            setSelectedDates({
                start_date: null,
                end_date: null,
                date: moment(dateString, 'MM/DD/YYYY').format('YYYY-MM-DD')
            });
        } else {
            setSelectedDates({ start_date: null, end_date: null, date: null });
        }
    };
    const handleChange = (e, name, selected_name) => {
        if (e) {
            formik.setFieldValue(name, e.value);
            formik.setFieldValue(selected_name, e);
        } else {
            formik.setFieldValue(name, null);
            formik.setFieldValue(selected_name, []);
        }
    }
    const validationSchema = Yup.object({
        facility_name: Yup.string().required('Required'),
        facility_category: Yup.string().required('Required'),
        club_facility_images: Yup.array().min(1, 'Required'),
    });

    const formik = useFormik({
        initialValues: {
            facility_name: "",
            facility_category: "",
            facility_category_option: null,
            club: "",
            country: "",
            facility_description: "",
            club_facility_images: [],
            time_slots: [],
            black_out_dates: [],
            title: "",
            pcw: !checkTheme(),
            pcn: checkTheme(),
        },
        validationSchema,
        onSubmit: (values) => {
            let data = {};
            let userInfo = localStorage.getItem('user_info');

            if (id) {
                data.id = id;
            }

            if (userInfo) {
                let userObj = JSON.parse(userInfo);
                let clubDetails = userObj?.club_details || [];

                if (Array.isArray(clubDetails) && clubDetails?.length > 0) {
                    let clubObj = clubDetails[0];
                    data.club = clubObj?.club_id;
                    data.country = clubObj?.country;
                }
            }

            data.facility_name = values?.facility_name;
            data.facility_category = values?.facility_category;
            data.facility_description = values?.facility_description;
            data.club_facility_images = values?.club_facility_images;
            data.pcw = values.pcw;
            data.pcn = values.pcn;


            data.time_slots = values.time_slots.map(slot => ({
                ...slot,
                open: closedDays[slot.day]
            })).filter(slot => 
                slot.start_time && slot.end_time
            );
        

            data.black_out_dates = values.black_out_dates.map(blackout => {
                const { type, date_range, ...rest } = blackout.recurrence_pattern;

                if (blackout.recurrence_pattern.type === 'weekly') {
                    const { recurrence_pattern } = blackout || {}
                    const { start_date, end_date } = recurrence_pattern?.date_range || {}
                    const test = {
                        ...blackout,
                        club: data.club,
                        country: data.country,
                        recurrence_pattern: {
                            days_of_month: undefined,
                            days_of_week: blackout.recurrence_pattern?.details?.weekly?.days || [],
                            end_date: end_date,
                            recurrence_type: recurrence_pattern?.type,
                            start_date: start_date,
                            status: "Active"
                        }
                    };
                    return test
                }
                if (blackout.recurrence_pattern.type === 'monthly') {
                    const { recurrence_pattern } = blackout || {}
                    const { start_date, end_date } = recurrence_pattern?.date_range || {}
                    const test = {
                        ...blackout,
                        club: data.club,
                        country: data.country,
                        recurrence_pattern: {
                            days_of_week: undefined,
                            days_of_month: blackout.recurrence_pattern?.details?.monthly?.days || [],
                            end_date: end_date,
                            recurrence_type: recurrence_pattern?.type,
                            start_date: start_date,
                            status: "Active"
                        }
                    };
                    return test
                } else {
                    return {
                        ...blackout,
                        club: data.club,
                        country: data.country,
                        recurrence_pattern: {
                            ...(date_range ? { ...date_range } : {}), // Spread only if date_range is not null
                            recurrence_type: type, 
                            status: "Active",
                            ...rest
                        }
                    };
                }

            });

            // console.log("submit-test",data)

            setLoading(true);
            // console.log("submit-test data",data)

            createClubfacitiltyCategory(data)((response) => {
    console.log(response)
                setLoading(false);
                if (response?.status_code === 200 || response?.status_code === 201) {
                    toast.success(id ? "Club facility Updated successfully" : "Club facility created successfully");
                    navigate(CLUBS_FACILITIES);
                } else {
                    const errorMsg = response?.response?.data?.errors?.time_slots?.[0] || "Something went wrong";
                    toast.error(errorMsg);
                }
            });
        }
    });

    const handleSaveBlackout = () => {
        if (!formik.values?.title) {
            toast.error('Title is required');
            return;
        }

        // Validate based on frequency
        let recurrencePattern = {
            recurrence_type: frequency === 'one_time' ? 'one_time' : activeTab,
            status: 'Active',
        };

        if (frequency === 'one_time') {
            if (!modalSelectedDates.date) {
                toast.error('Please select a date');
                return;
            }
            recurrencePattern.date = modalSelectedDates.date;
        } else {
            if (!selectedDates.start_date || !selectedDates.end_date) {
                toast.error('Please select start and end dates');
                return;
            }
            recurrencePattern.start_date = selectedDates.start_date;
            recurrencePattern.end_date = selectedDates.end_date;

            if (activeTab === 'weekly' && selectedDaysOfWeek.length === 0) {
                toast.error('Please select days of week');
                return;
            }
            if (activeTab === 'monthly' && selectedDaysOfMonth.length === 0) {
                toast.error('Please select days of month');
                return;
            }

            recurrencePattern.days_of_week = activeTab === 'weekly' ? selectedDaysOfWeek : undefined;
            recurrencePattern.days_of_month = activeTab === 'monthly' ? selectedDaysOfMonth : undefined;
        }

        const blackoutData = {
            title: formik.values?.title,
            recurrence_pattern: recurrencePattern,
            status: 'Active'
        };

        // If we have an ID, we're editing an existing blackout
        if (formik.values.editingBlackoutId) {
            blackoutData.id = formik.values.editingBlackoutId;


            const blackoutIndex = formik.values.black_out_dates.findIndex(
                blackout => blackout.id === formik.values.editingBlackoutId
            );

            if (blackoutIndex !== -1) {

                const updatedBlackouts = [...formik.values.black_out_dates];
                updatedBlackouts[blackoutIndex] = blackoutData;
                formik.setFieldValue('black_out_dates', updatedBlackouts);
            } else {

                if (blackoutDetailId !== "") {
                    const updatedBlackouts = [...formik.values.black_out_dates];
                    updatedBlackouts[blackoutDetailId] = blackoutData;
                    formik.setFieldValue('black_out_dates', updatedBlackouts);
                } else {

                    formik.setFieldValue('black_out_dates', [...formik.values.black_out_dates, blackoutData]);
                }
            }
        } else {

            formik.setFieldValue('black_out_dates', [...formik.values.black_out_dates, blackoutData]);
        }


        formik.setFieldValue('editingBlackoutId', null);
        setBlackoutDetailId("");


        handleBlackoutClose();
    };
    const getDaysOfWeekOptions = () => {
        const days = [
            { value: 1, label: 'Mon' },
            { value: 2, label: 'Tues' },
            { value: 3, label: 'Wed' },
            { value: 4, label: 'Thu' },
            { value: 5, label: 'Fri' },
            { value: 6, label: 'Sat' },
            { value: 7, label: 'Sun' },
        ];
        return days;
    };
    const getDayofweekbyDayNumber = (key) => {
        const days = {
            "1": 'Mon',
            "2": 'Tues',
            "3": 'Wed',
            "4": 'Thu',
            "5": 'Fri',
            "6": 'Sat',
            "7": 'Sun',
        }
        return days[key];
    };
    const handleRangeChange = (dates, dateStrings) => {
        if (dates) {
            setSelectedDates({
                start_date: moment(dateStrings[0], 'MM/DD/YYYY').format('YYYY-MM-DD'),
                end_date: moment(dateStrings[1], 'MM/DD/YYYY').format('YYYY-MM-DD'),
                date: null
            });
        } else {
            setSelectedDates({ start_date: null, end_date: null, date: null });
        }
    };
    // console.log(selectedDaysOfWeek,"|||SAYS")
    const handleDaysOfWeekChange = (selectedOptions) => {
        // console.log(selectedOptions,"+++")
        const values = selectedOptions?.map(option => option.value);
        setSelectedDaysOfWeek(values);
        formik?.setFieldValue('recurrence_pattern.days_of_week', values)
    };
    const getAvailableDays = (start_date, end_date) => {
        let start = moment(start_date);
        let end = moment(end_date);
        let daysSet = new Set();

        while (start.isSameOrBefore(end)) {
            daysSet.add(start.isoWeekday());
            start.add(1, "day");
        }

        return getDaysOfWeekOptions().filter((day) => daysSet.has(day.value));
    };
    const getDaysOfMonthOptions = () => {
        if (!selectedDates.start_date || !selectedDates.end_date) return [];

        const start = dayjs(selectedDates.start_date);
        const end = dayjs(selectedDates.end_date);
        const options = [];


        if (start.month() !== end.month() || start.year() !== end.year()) {
            for (let i = 1; i <= 31; i++) {
                options.push({ value: i, label: i.toString() });
            }
        } else {

            const daysInMonth = start.daysInMonth();
            const startDay = start.date();
            const endDay = Math.min(end.date(), daysInMonth);

            for (let i = startDay; i <= endDay; i++) {
                options.push({ value: i, label: i.toString() });
            }
        }

        return options;
    };

    const getBlackoutLabel = (blackout) => {
        console.log("blackout", blackout);
        
        const pattern = blackout.recurrence_pattern;
        switch (pattern.recurrence_type || pattern.type) {
            case 'one_time':
                return `${blackout.title} (One-time: ${dayjs(pattern.date).format('MMM D, YYYY')})`;
            case 'daily':
                return `${blackout.title} (Daily: ${dayjs(pattern?.start_date || pattern?.date_range?.start_date).format('MMM D, YYYY')} - ${dayjs(pattern?.end_date || pattern?.date_range?.end_date).format('MMM D, YYYY')})`;
            case 'weekly': {
                if (pattern.days_of_week) {
                    const { days_of_week } = pattern || []
                    const label = days_of_week?.map((day) => getDayofweekbyDayNumber(day)).join(",")
                    return `${blackout.title} (Weekly: ${dayjs(pattern?.start_date || pattern?.date_range?.start_date).format('MMM D, YYYY')} - ${dayjs(pattern?.end_date || pattern?.date_range?.end_date).format('MMM D, YYYY')} - ${label})`;

                }
                else {
                    const { days } = pattern?.details?.weekly || {}
                    const label = days?.map((day) => getDayofweekbyDayNumber(day)).join(",")
                    return `${blackout.title} (Weekly: ${dayjs(pattern?.date_range?.start_date).format('MMM D, YYYY')} - ${dayjs(pattern?.date_range?.end_date).format('MMM D, YYYY')} - ${label})`;
                }

            }
            case 'monthly': {
                if (pattern.days_of_month) {
                    const { days_of_month } = pattern || []

                    return `${blackout.title} (Monthly: ${dayjs(pattern?.start_date || pattern?.date_range?.start_date).format('MMM D, YYYY')} - ${dayjs(pattern?.end_date || pattern?.date_range?.end_date).format('MMM D, YYYY')} - Days ${pattern.days_of_month?.join(', ')})`;
                }
                else {
                    const { days } = pattern?.details?.monthly || {}
                    const label = days?.map((day) => (day)).join(",")
                    return `${blackout.title} (monthly: ${dayjs(pattern?.date_range?.start_date).format('MMM D, YYYY')} - ${dayjs(pattern?.date_range?.end_date).format('MMM D, YYYY')} - ${label})`;
                }

            }

            default:
                return blackout.title;
        }
    };
    // console.log("forkim", formik?.values);

    const handleEditBlackout = (blackout, index) => {
        setBlackoutDetailId(index);
        setBlackoutTitle(blackout.title);

        const pattern = blackout.recurrence_pattern;
        const recurrenceType = pattern.recurrence_type || pattern.type;

        setFrequency(recurrenceType === 'one_time' ? 'one_time' : recurrenceType);
        setActiveTab(recurrenceType)

        formik.setFieldValue('editingBlackoutId', blackout.id || null);
        formik.setFieldValue('title', blackout.title);

        if (recurrenceType === 'one_time') {
            setModalSelectedDates({
                date: pattern.date,
                start_date: null,
                end_date: null
            });


            formik.setFieldValue('recurrence_pattern.recurrence_type', 'one_time');
            formik.setFieldValue('recurrence_pattern.date', pattern.date);
            formik.setFieldValue('recurrence_pattern.start_date', null);
            formik.setFieldValue('recurrence_pattern.end_date', null);
        } else {
            const startDate = pattern.start_date || (pattern.date_range ? pattern.date_range.start_date : null);
            const endDate = pattern.end_date || (pattern.date_range ? pattern.date_range.end_date : null);

            setModalSelectedDates({
                date: null,
                start_date: startDate,
                end_date: endDate
            });

            setSelectedDates({
                date: null,
                start_date: startDate,
                end_date: endDate
            });


            formik.setFieldValue('recurrence_pattern.recurrence_type', recurrenceType);
            formik.setFieldValue('recurrence_pattern.date', null);
            formik.setFieldValue('recurrence_pattern.start_date', startDate);
            formik.setFieldValue('recurrence_pattern.end_date', endDate);

            if (recurrenceType === 'weekly') {
                const daysOfWeek = pattern.days_of_week || (pattern.details?.weekly?.days || []);
                setSelectedDaysOfWeek(daysOfWeek);


                formik.setFieldValue('recurrence_pattern.days_of_week', daysOfWeek);
                formik.setFieldValue('recurrence_pattern.days_of_month', []);
            } else if (recurrenceType === 'monthly') {
                const daysOfMonth = pattern.days_of_month || (pattern.details?.monthly?.days || []);
                setSelectedDaysOfMonth(daysOfMonth);


                formik.setFieldValue('recurrence_pattern.days_of_month', daysOfMonth);
                formik.setFieldValue('recurrence_pattern.days_of_week', []);
            } else {

                formik.setFieldValue('recurrence_pattern.days_of_week', []);
                formik.setFieldValue('recurrence_pattern.days_of_month', []);
            }
        }

        setShow(true);
    };

    const handleDaysOfMonthChange = (selectedOptions) => {
        const values = selectedOptions.map(option => option.value);
        setSelectedDaysOfMonth(values);
    };
    const handleFrequencyChange = (e) => {
        const value = e.target.value;
        setFrequency(value);

        if (value === 'one_time') {
            setModalSelectedDates({ start_date: null, end_date: null, date: null });
        } else {
            setSelectedDates({ start_date: null, end_date: null, date: null });
            setSelectedDaysOfWeek([]);
            setSelectedDaysOfMonth([]);
            setActiveTab('daily'); // Set default tab
        }
    };
    let typingTimer;
    const loadOptionsFaciltiycaategory = (inputValue) => {
        return new Promise((resolve) => {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {
                if (inputValue.length >= 1) {
                    faciltiyCategoryDropdown({ page: 1, limit: 100, search: inputValue })((response) => {
                        if (response?.status_code === 200) {
                            const options = response?.data?.results || [];
                            setCategoryOptions(options);
                            resolve(options);
                        } else {
                            resolve([]);
                        }
                    });
                } else {
                    resolve([]);
                }
            }, 300);
        });
    };


    const getClubtype = () => {
        faciltiyCategoryDropdown({ page: 1, limit: 100 })(response => {
            if (response?.status_code == 200) {
                setCategoryOptions(response?.data?.results)
            }
        })
    }
    useEffect(() => {
        getClubtype()

    }, [])

    // useEffect(() => {
    //     if (show) {
    //         setBlackoutTitle('');
    //         setModalFrequency('one_time');
    //         setModalSelectedDates({ start_date: null, end_date: null, date: null });
    //         setModalSelectedDaysOfWeek([]);
    //         setModalSelectedDaysOfMonth([]);
    //         setActiveTab('daily');
    //     }
    // }, [show]);

    useEffect(() => {
        if (id) {
            getfaciltyDetails(id)((response) => {
                if (response && response?.status) {
                    let data = response?.data || {};

                    if (data?.id) {

                        let timeSlots = [];


                        if (Array.isArray(data.timeslots) && data.timeslots.length > 0) {
                            timeSlots = data.timeslots.map(slot => ({
                                id: slot.id,
                                day: slot.day,
                                start_time: slot.start_time ? slot.start_time.substring(0, 5) : "",
                                end_time: slot.end_time ? slot.end_time.substring(0, 5) : "",
                                open: true 
                            }));
                        }

                        let blackoutDates = [];
                        if (Array.isArray(data.blackout_dates) && data.blackout_dates.length > 0) {
                            blackoutDates = data.blackout_dates.map(date => ({
                                id: date.id,
                                title: date.title,
                                club: data.club,
                                country: data.country,
                                recurrence_pattern: date.recurrence_pattern
                            }));
                        }
                        formik.setValues({
                            ...formik.values,
                            facility_name: data?.facility_name || "",
                            facility_category: data?.facility_category || "",
                            facility_category_option: {
                                label: data?.facility_category_name,
                                value: data?.facility_category
                            },
                            club: data?.club,
                            country: data?.country,
                            facility_description: data?.facility_description,
                            club_facility_images: data?.club_facility_images || [],
                            pcw_icon_class: data?.pcw_icon_class || "",
                            pcn_icon_class: data?.pcn_icon_class || "",
                            time_slots: timeSlots,
                            black_out_dates: blackoutDates,
                        });


                        if (Array.isArray(data.timeslots)) {
                            setClosedDays((prevData) => {
                                const temp = {...prevData}
                                data.timeslots.forEach(({day ,open}) => {
                                    temp[day] =open
                                })
                                return temp
                            });
                        }
                    }
                }
            });
        }
    }, [id]);


    const format = 'h:mm A';

    const handleAddSlot = (day) => {
        const newSlot = { day, start_time: "", end_time: "" };
        formik.setFieldValue('time_slots', [...formik.values?.time_slots, newSlot]);
    };

    const handleRemoveSlot = (index) => {
        const updatedSlots = formik.values?.time_slots?.filter((_, i) => i !== index);
        formik.setFieldValue('time_slots', updatedSlots);
    };

    const handleClosedToggle = (day) => {
        setClosedDays((prev) => {
            const newClosedDays = { ...prev, [day]: !prev[day] };
            
            // Update the open status for all slots of this day
            const updatedSlots = formik.values.time_slots.map(slot => {
                if (slot.day === day) {
                    return { ...slot, open: !newClosedDays[day] };
                }
                return slot;
            });
            
            formik.setFieldValue('time_slots', updatedSlots);
            return newClosedDays;
        });
    };
    const getTimeSlotsByDay = (day) => {
        return formik.values.time_slots?.filter(slot => slot.day === day);
    };

    const handleStartTimeChange = (time, dayNumber, slot) => {
        const timeString = time ? time.format('HH:mm') : '';
        const updatedSlots = [...formik.values?.time_slots];
        const slotIndex = formik.values?.time_slots.findIndex(
            (s) => s.day === dayNumber && s === slot
        );

        // Update start time and reset end time
        const updatedSlot = {
            ...updatedSlots[slotIndex],
            start_time: timeString,
            end_time: '' // Reset end time when start time changes
        };

        updatedSlots[slotIndex] = updatedSlot;
        formik.setFieldValue('time_slots', updatedSlots);
    };

    const handleEndTimeChange = (time, dayNumber, slot) => {
        const timeString = time ? time.format('HH:mm') : '';
        const updatedSlots = [...formik.values?.time_slots];
        const slotIndex = formik.values?.time_slots.findIndex(
            (s) => s.day === dayNumber && s === slot
        );

        // Ensure end time is after start time
        if (slot.start_time && timeString > slot.start_time) {
            updatedSlots[slotIndex] = { ...updatedSlots[slotIndex], end_time: timeString };
            formik.setFieldValue('time_slots', updatedSlots);
        }
    };

    const disabledEndTime = (currentSlot) => {
        return (selectedTime) => {
            if (!currentSlot.start_time) return false;

            const startTime = moment(currentSlot.start_time, 'HH:mm');

            return {
                disabledHours: () => {
                    let hours = [];
                    for (let i = 0; i <= startTime.hour(); i++) {
                        hours.push(i);
                    }
                    return hours;
                },
                disabledMinutes: (selectedHour) => {
                    if (selectedHour === startTime.hour()) {
                        let minutes = [];
                        for (let i = 0; i <= startTime.minute(); i++) {
                            minutes.push(i);
                        }
                        return minutes;
                    }
                    return [];
                }
            };
        };
    };

    const shouldShowAddSlotButton = (day) => {
        const daySlots = getTimeSlotsByDay(day);
        return closedDays[day] && (
            daySlots?.length === 0 ||
            daySlots?.every(slot => slot.start_time && slot.end_time)
        );
    };

    const CustomSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 39,
        height: 20,
        padding: 0,
        marginLeft: 5,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            // margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: 'var(--sandy-brown)',
                    opacity: 1,
                    border: 0,
                    ...theme.applyStyles('dark', {
                        backgroundColor: '#2ECA45',
                    }),
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.grey[100],
                ...theme.applyStyles('dark', {
                    color: theme.palette.grey[600],
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                backgroundColor: 'var(--sandy-brown)',
                opacity: 0.7,
                ...theme.applyStyles('dark', {
                    opacity: 0.3,
                }),
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 20,
            height: 20,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#E9E9EA',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
            ...theme.applyStyles('dark', {
                backgroundColor: '#39393D',
            }),
        },
    }));
    const handleImageChange = (event) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = reader.result; // Get base64 data

                formik.setFieldValue('club_facility_images', [
                    ...formik.values.club_facility_images,
                    { id: formik.values.club_facility_images.length, image: base64String }
                ]);
            };

            reader.readAsDataURL(file);
        }
    };


    const handleDeleteImage = (index) => {
        const updatedImages = formik.values.club_facility_images.filter((_, i) => i !== index);
        formik.setFieldValue('club_facility_images', updatedImages);
    };

    return (
        <div className='create-clubs-info-section'>
            <Modal show={showModal} onHide={handleClose} size="lg" className="custom-modal">
                <Modal.Header closeButton >
                    <Modal.Title>Add Facility Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateFacilityCategory
                        modal={true}
                        backdrop="static"
                        setShowModal={setShowModal}
                        setRefresh={() => { }}
                        refresh={false}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                show={show}
                onHide={handleBlackoutClose}
                size='lg'
                className="add-blackout-dates-modal"
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Blackout Dates</Modal.Title>
                </Modal.Header>
                <Form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        {/* Club */}

                        {/* Title */}
                        <div className="mb-3">
                            <Form.Group controlId="title">
                                <Form.Label>
                                    Title<span className="required">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Title"
                                    name="title"
                                    value={formik?.values?.title}
                                    onChange={formik.handleChange}
                                    // onChange={(e) => setBlackoutTitle(e.target.value)}
                                    isInvalid={formik?.touched?.title && formik?.errors?.title}
                                />
                                {formik?.touched?.title && formik?.errors?.title && (
                                    <Form.Control.Feedback type="invalid">
                                        {formik?.errors?.title}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </div>
                        {/* Blackout Date Selection */}
                        <div className="form-group mb-3">
                            <div className="mb-2">Frequency<span className="required">*</span></div>
                            <Radio.Group onChange={handleFrequencyChange} value={frequency}>

                                <Radio value="one_time">One Time</Radio>
                                <Radio value={["daily", "weekly", "monthly"].includes(frequency) ? frequency : "daily"}>Recurring</Radio>
                            </Radio.Group>
                        </div>

                        {frequency === 'one_time' ? (
                            <div className="form-group">
                                <div className="mb-1">Date
                                    <span className="required">
                                        <sup>*</sup>
                                    </span>
                                </div>
                                <DatePicker
                                    format="MM/DD/YYYY"
                                    onChange={(date, dateString) => {
                                        setModalSelectedDates({
                                            date: date ? moment(dateString, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
                                            start_date: null,
                                            end_date: null
                                        });
                                    }}
                                    style={{ width: "288px", height: "41px", borderRadius: "0px" }}
                                    value={modalSelectedDates.date ? dayjs(modalSelectedDates.date, 'YYYY-MM-DD') : null}
                                />
                            </div>
                        ) : (
                            <div className="form-group">
                                <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
                                    <TabPane tab="Daily" key="daily">
                                        <div>
                                            Select Date Range
                                            <span className="required">
                                                <sup>*</sup>
                                            </span>
                                        </div>
                                        <RangePicker
                                            format="MM/DD/YYYY"
                                            onChange={(dates, dateStrings) => {
                                                const startDate = dateStrings[0] ? moment(dateStrings[0], 'MM/DD/YYYY').format('YYYY-MM-DD') : null;
                                                const endDate = dateStrings[1] ? moment(dateStrings[1], 'MM/DD/YYYY').format('YYYY-MM-DD') : null;

                                                // Update both state variables
                                                setModalSelectedDates({
                                                    start_date: startDate,
                                                    end_date: endDate,
                                                    date: null
                                                });

                                                // Also update selectedDates for consistency with other tabs
                                                setSelectedDates({
                                                    start_date: startDate,
                                                    end_date: endDate,
                                                    date: null
                                                });

                                                // Update formik values directly (similar to weekly tab)
                                                formik.setFieldValue('recurrence_pattern.start_date', startDate);
                                                formik.setFieldValue('recurrence_pattern.end_date', endDate);
                                            }}
                                            value={modalSelectedDates.start_date && modalSelectedDates.end_date ? [
                                                dayjs(modalSelectedDates.start_date, 'YYYY-MM-DD'),
                                                dayjs(modalSelectedDates.end_date, 'YYYY-MM-DD')
                                            ] : null}
                                        />
                                    </TabPane>
                                    <TabPane tab="Weekly" key="weekly">
                                        <div>
                                            Select Date Range
                                            <span className="required">
                                                <sup>*</sup>
                                            </span>
                                        </div>
                                        <RangePicker
                                            format="MM/DD/YYYY"
                                            onChange={(dates, dateStrings) => {
                                                if (dates) {
                                                    setSelectedDates({
                                                        start_date: moment(dateStrings[0], 'MM/DD/YYYY').format('YYYY-MM-DD'),
                                                        end_date: moment(dateStrings[1], 'MM/DD/YYYY').format('YYYY-MM-DD'),
                                                        date: null
                                                    });
                                                    formik.setFieldValue('recurrence_pattern.start_date',
                                                        moment(dateStrings[0], 'MM/DD/YYYY').format('YYYY-MM-DD'));
                                                    formik.setFieldValue('recurrence_pattern.end_date',
                                                        moment(dateStrings[1], 'MM/DD/YYYY').format('YYYY-MM-DD'));
                                                } else {
                                                    setSelectedDates({ start_date: null, end_date: null, date: null });
                                                }
                                            }}
                                            value={
                                                selectedDates.start_date && selectedDates.end_date
                                                    ? [
                                                        dayjs(selectedDates.start_date, 'YYYY-MM-DD'),
                                                        dayjs(selectedDates.end_date, 'YYYY-MM-DD'),
                                                    ]
                                                    : null
                                            }
                                            placeholder={["Start Date", "End Date"]}
                                            disabledDate={(current) => current && current < dayjs().startOf("day")}
                                        />
                                        <div className="mt-3 mb-1">Days of Week <span className="required">
                                            <sup>*</sup>
                                        </span></div>
                                        <Select
                                            isMulti
                                            // options={getDaysOfWeekOptions()}
                                            options={getAvailableDays(selectedDates?.start_date, selectedDates?.end_date)}
                                            onChange={handleDaysOfWeekChange}
                                            value={getDaysOfWeekOptions().filter(option => selectedDaysOfWeek.includes(option.value))}
                                            placeholder="Select days"
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    width: "288px", // Set the width here
                                                    borderRadius: "0px"
                                                }),
                                                menu: (base) => ({
                                                    ...base,
                                                    width: "288px", // Optional: Set width for dropdown menu
                                                    borderRadius: "0px"
                                                }),
                                            }}
                                        />
                                    </TabPane>
                                    <TabPane tab="Monthly" key="monthly">
                                        <div className="mb-1">
                                            Select Date Range
                                            <span className="required">
                                                <sup>*</sup>
                                            </span>
                                        </div>
                                        <RangePicker
                                            format="MM/DD/YYYY"
                                            onChange={(dates, dateStrings) => {
                                                handleRangeChange(dates, dateStrings);
                                                formik.setFieldValue('recurrence_pattern.start_date', selectedDates.start_date);
                                                formik.setFieldValue('recurrence_pattern.end_date', selectedDates.end_date);
                                            }}
                                            value={
                                                selectedDates.start_date && selectedDates.end_date
                                                    ? [
                                                        dayjs(selectedDates.start_date, 'YYYY-MM-DD'),
                                                        dayjs(selectedDates.end_date, 'YYYY-MM-DD'),
                                                    ]
                                                    : null
                                            } // Show selected range //
                                            placeholder={["Start Date", "End Date"]}
                                            disabledDate={(current) => current && current < dayjs().startOf("day")}
                                        />

                                        <div className="mt-3 mb-1">Date of Month
                                            <span className="required">
                                                <sup>*</sup>
                                            </span>
                                        </div>
                                        <Select
                                            isMulti
                                            options={getDaysOfMonthOptions()}
                                            onChange={handleDaysOfMonthChange}
                                            value={getDaysOfMonthOptions().filter(option => selectedDaysOfMonth.includes(option.value))}
                                            placeholder="Select days"
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    width: "288px",
                                                    borderRadius: "0px"
                                                }),
                                                menu: (base) => ({
                                                    ...base,
                                                    width: "288px", // Optional: Set width for dropdown menu
                                                    borderRadius: "0px"
                                                }),
                                            }}
                                        />
                                    </TabPane>
                                </Tabs>
                            </div>)}

                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn-type-1" type="button" onClick={handleSaveBlackout} disabled={loading}>
                            Save
                        </button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <div className='page-details'>
                <div className='club-profile-section'>
                    <div className='panel-type-1'>
                        {id && currentPageName ? 'Update' : 'Add'} Club Facility
                    </div>
                    <Form onSubmit={formik?.handleSubmit}>
                        <div className='club-form mt-3'>
                            <div className='mt-3'>
                                <div className="row">
                                    <div className='item upload mb-3'>
                                        <label>Upload Image <span className='required'>*</span></label>
                                        <div className='d-flex align-items-end mt-2'>
                                            <div>
                                                {formik.values.club_facility_images?.length === 0 ? (
                                                    <label className='upload-icon cursor-pointer' htmlFor='fileInput'>
                                                        <UploadIcon />
                                                        <div className='mt-3'>Upload</div>
                                                    </label>
                                                ) : (
                                                    <div className={`uploaded-images ${formik.values.club_facility_images?.length > 0 ? 'active' : ''}`}>
                                                        {formik.values.club_facility_images?.map((image, index) => (
                                                            <div className='pos-rel' key={index} style={{ width: "107px", height: "107px" }}>
                                                                <img src={image.image} alt={`Uploaded ${index}`} className="uploaded-image" />
                                                                <div className='filename mt-3 d-flex align-items-center justify-content-between' style={{ gap: "7.5rem" }}>
                                                                    <div className='name' title={formik.values.thumbnailName || ""}>
                                                                        {formik.values.thumbnailName}
                                                                    </div>
                                                                    <RiDeleteBin6Line
                                                                        size={16}
                                                                        fill='var(--red)'
                                                                        title='Delete'
                                                                        onClick={() => handleDeleteImage(index)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}

                                                    </div>
                                                )}
                                                {formik.touched.club_facility_images && formik.errors.club_facility_images && (
                                                    <div className="error-message" style={{ color: "red", fontSize: '12px' }}>
                                                        {formik.errors.club_facility_images}
                                                    </div>
                                                )}
                                                {formik.values.thumbnailName && (
                                                    <div className='filename mt-2 d-flex align-items-center justify-content-between gap-2'>
                                                        <div className='name' title={formik.values.thumbnailName || ""}>
                                                            {formik.values.thumbnailName || ""}
                                                        </div>
                                                        <RiDeleteBin6Line
                                                            size={16}
                                                            fill='var(--red)'
                                                            title='Delete'
                                                            onClick={() => handleDeleteImage()}
                                                        />
                                                    </div>
                                                )}
                                                <div className='mt-2 w-75'>
                                                    {formik.values.club_facility_images?.length === 0 && (
                                                        <div className='info m-0'>
                                                            Use JPEG or PNG. Best size 1280×720 pixels. Keep it under 1MB
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <input
                                                id="fileInput"
                                                className='d-none'
                                                type="file"
                                                onChange={handleImageChange}
                                                accept="image/*"

                                            />

                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <Form.Group controlId="facility_name">
                                            <Form.Label>Facility Name<span className='required'>*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                className='dotted-input-half'
                                                name='facility_name'
                                                value={formik.values?.facility_name}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    formik.setFieldValue(
                                                        'facility_name',
                                                        value.charAt(0).toUpperCase() + value.slice(1)
                                                    );
                                                }}
                                                isInvalid={formik.touched.facility_name && !!formik.errors.facility_name}
                                            />
                                            <Form.Control.Feedback type="invalid">{formik.touched.facility_name && formik.errors.facility_name}</Form.Control.Feedback>

                                        </Form.Group>
                                    </div>
                                    {/* Category */}
                                    <div className="col-md-4 mb-3">
                                        <Form.Group controlId="facility_category">
                                            <div className='d-flex justify-content-between'>
                                                <Form.Label>Category<span className='required'><sup>*</sup></span></Form.Label>
                                                {/* <div className='fw-semibold' onClick={() => {
                                                    setSupplierModal(true);
                                                    handleShow();
                                                }} style={{ fontSize: "20px", cursor: "pointer" }}><span style={{ paddingLeft: "3px" }}><IoIosAddCircleOutline size={18} title='Add New Faciltity Category' /></span></div>*/}
                                            </div>
                                            <AsyncSelect
                                                loadOptions={loadOptionsFaciltiycaategory}
                                                defaultOptions={categoryOptionsList || []}
                                                classNamePrefix="select"
                                                isClearable={true}
                                                placeholder={'Select Category'}
                                                onChange={(e) => handleChange(e, 'facility_category', 'facility_category_option')}
                                                value={formik.values?.facility_category_option}
                                                styles={{
                                                    control: (styles, { isFocused }) => ({
                                                        ...styles,
                                                        maxHeight: '31px',
                                                        border: formik.errors.ss && formik.touched.facility_category
                                                            ? '1px solid #dc3545'
                                                            : '1px solid var(--light-grayish)',
                                                        outline: 'none',
                                                        boxShadow: isFocused ? '0 0 0 1px var(--light-grayish)' : 'none',
                                                        color: 'var(--medium-gray)',
                                                        fontSize: '15px',
                                                        '&:hover': {
                                                            border: formik.errors.facility_category && formik.touched.facility_category
                                                                ? '1px solid #dc3545'
                                                                : '1px solid var(--light-grayish)',
                                                        },
                                                    }),
                                                    valueContainer: (styles) => ({
                                                        ...styles,
                                                        alignItems: 'center',
                                                        padding: '3px 8px',
                                                    }),
                                                    singleValue: (provided) => ({
                                                        ...provided,
                                                        color: 'var(--medium-gray)',
                                                        fontSize: '15px',
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        border: '1px solid var(--light-grayish)',
                                                        boxShadow: 'none',
                                                        zIndex: 1050, // Ensure the dropdown appears above other elements
                                                    }),
                                                    menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex: 9999, // Ensures the dropdown appears above modals or other elements
                                                    }),
                                                    placeholder: (styles) => ({
                                                        ...styles,
                                                        textOverflow: "ellipsis",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        color: 'var(--medium-gray)',
                                                        fontSize: '15px',
                                                    }),
                                                }}
                                                menuPortalTarget={document.body} // Ensures the dropdown renders in a higher-level container
                                            />

                                            {formik.touched?.facility_category && formik.errors?.facility_category &&
                                                <div className='error-msg'>{formik.touched.facility_category && formik.errors.facility_category}</div>
                                            }
                                        </Form.Group>
                                    </div>

                                    {/* Description */}
                                    <Description formik={formik} />
                                    {/* Gallery */}
                                    {/* <div className="col-md-12 mb-4">
                                        <Gallery formik={formik} id={id} />
                                    </div> */}
                                    <div className="col-md-12 mb-4 mt-3">
                                        <div className="blackout-tags-container">
                                            {formik.values.black_out_dates.map((blackout, index) => (
                                                <div key={index} className="btn-type-5 d-flex align-items-center position-relative">
                                                    <span onClick={() => handleEditBlackout(blackout, index)}>
                                                        {getBlackoutLabel(blackout)}
                                                    </span>
                                                    <IoClose
                                                        className="close-icon"
                                                        onClick={() => {
                                                            const newBlackouts = [...formik.values.black_out_dates];
                                                            newBlackouts.splice(index, 1);
                                                            formik.setFieldValue('black_out_dates', newBlackouts);
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <button onClick={() => {
                                                setblackoutdate(true);
                                                setShow(true);
                                            }}
                                                title='Add Blackout Date'
                                                type='button'
                                                className="btn-type-1 mb-3 cursor-pointer  mt-3">
                                                {id ? "Update Blackout Date" : " Add Blackout Date"}
                                            </button>

                                        </div>

                                        {/* Schedule Time Panel */}
                                        <div className="panel-type-1 mb-3 mt-5">Club Operating Hours    <IoMdInformationCircleOutline className='ms-3' style={{fontSize:"24px"}}  title='This is excluding the blackout date feature'/></div>
                                        <div className="row">
                                            {Object.keys(daysMapping)?.map((dayNum) => {
                                                const dayNumber = parseInt(dayNum);
                                                const dayName = daysMapping[dayNumber];
                                                const daySlots = getTimeSlotsByDay(dayNumber);

                                                // console.log(closedDays, dayNumber)


                     
                                                return (
                                                    <div key={dayNumber} className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                        <div className="border rounded p-3 h-100">
                                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                                <strong>{dayName}</strong>
                                                                <div className="d-flex align-items-center">
                                                                    <CustomSwitch
                                                                        // checked={!closedDays[dayNumber]}
                                                                        checked={closedDays[dayNumber]}
                                                                        onChange={() => handleClosedToggle(dayNumber)}
                                                                        color="primary"
                                                                    />
                                                                    <span className="ms-2 text-muted" style={{ fontSize: '0.9rem' }}>
                                                                        {closedDays[dayNumber] ?   "Open": "Closed"}
                                                                        {/* {closedDays[dayNumber] ? "Closed" : "Open"} */}

                                                                    </span>
                                                                </div>
                                                            </div>

                                                           
                                                                <div>
                                                                    {daySlots?.map((slot, index) => (
                                                                        <div key={index} className="d-flex mb-2 align-items-center flex-wrap">
                                                                            <div className="d-flex  align-items-center antd-input">
                                                                                <TimePicker
                                                                                    use12Hours
                                                                                    format={format}
                                                                                    value={slot.start_time ? dayjs(slot.start_time, 'HH:mm') : null}
                                                                                    onChange={(time) => handleStartTimeChange(time, dayNumber, slot)}
                                                                                    className="me-1"
                                                                                    style={{ width: "45%", minWidth: "90px" }}
                                                                                    placeholder="Start time"
                                                                                    allowClear={false}
                                                                                />
                                                                                <TimePicker
                                                                                    use12Hours
                                                                                    format={format}
                                                                                    value={slot.end_time ? dayjs(slot.end_time, 'HH:mm') : null}
                                                                                    onChange={(time) => handleEndTimeChange(time, dayNumber, slot)}
                                                                                    className="ms-1"
                                                                                    style={{ width: "45%", minWidth: "90px" }}
                                                                                    placeholder="End time"
                                                                                    allowClear={false}
                                                                                    disabledTime={disabledEndTime(slot)}
                                                                                // disabled={!slot.start_time}
                                                                                />

                                                                            </div>
                                                                            <button
                                                                                type="button"
                                                                                onClick={() => handleRemoveSlot(
                                                                                    formik.values.time_slots.findIndex(
                                                                                        (s) => s.day === dayNumber && s === slot
                                                                                    )
                                                                                )}
                                                                                className="btn btn-sm text-danger mt-1 mt-sm-0"
                                                                                style={{ background: "none", border: "none", padding: "0 0 0 5px", minWidth: "30px" }}
                                                                            >
                                                                                <AiOutlineDelete size={20} />
                                                                            </button>
                                                                        </div>
                                                                    ))}

                                                                    {shouldShowAddSlotButton(dayNumber) && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => handleAddSlot(dayNumber)}
                                                                            className="btn btn-sm btn-light d-flex align-items-center mt-2"
                                                                            style={{ fontSize: '0.8rem' }}
                                                                        >
                                                                            <AiOutlinePlus size={14} className="me-1" /> Add Time Slot
                                                                        </button>
                                                                    )}
                                                                </div>
                                                          
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='submit-box d-flex align-items-center justify-content-end gap-3'>
                            <button className='btn-type-2 cursor-pointer' onClick={() => navigate(CLUBS_FACILITIES)}>Cancel</button>
                            <button className='btn-type-1' type="submit" disabled={loading}>{id ? "Update" : "Submit"}</button>
                        </div>
                    </Form>

                </div>
            </div>
        </div>
    )
}

export default CreateClubsFacilitiesPage