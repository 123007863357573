import { useFormik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import Select from 'react-select';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { CLUB_ONBOARDIN, CLUB_ONBOARDING_LIST, CLUBLISTING } from '../../constants/pathname';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { createClubProfile, getClubProfile, getemailsendig } from '../../api/actions/clubProfile';
import toast from 'react-hot-toast';
import { getClubDetails } from '../../api/actions/club';
import * as Yup from 'yup';
import { FaTrash, FaUpload, FaPlus, FaEye } from 'react-icons/fa';
import { checkTheme, checkThemePcw } from '../../utils/utils';
import imageToBase64 from 'image-to-base64/browser'

const ClubOnboarding = () => {
    const autocompleteRef = useRef(null);
    const navigate = useNavigate();
    const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_API_KEY;
    const { id } = useParams()
    const location = useLocation();
    const { country } = location.state || {};
    const [isChecked, setIsChecked] = useState(false);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isKycCompleted, setIsKycCompleted] = useState(false);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries: ['places'],
    });
    const validationSchema = Yup.object({
        club_name: Yup.string().required('Required'),
        // state: Yup.string().required('Required'),
        latitude: Yup.string().required('Required'),
        longitude: Yup.string().required('Required'),
        admin_email: Yup.string().required('Required'),
        first_name: Yup.string().required('Required'),

        primary_address: Yup.string().required('Required'),
        // country: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        zip_code: Yup.string().required('Required'),
        contact_number: Yup.string()
            .required("Required")
            .max(15, 'Contact number must be at most 15 characters')

    });


    const formik = useFormik({
        initialValues: {
            club_name: '',
            admin_email: '',
            Prefix: '',
            select_prefix: [],
            first_name: '',
            last_name: '',
            member_city: false,
            country: '',
            select_country: [],
            latitude: '',
            longitude: '',
            state: '',
            contact_number: '',
            city: '',
            superadmin_handled: false,
            select_city: [],
            zip_code: '',
            optional_service: '',
            selected_optionService: '',
            primary_address: '',
            summary: '',
            PCW: !checkTheme(),
            PCN: checkTheme(),
            is_kyc: false,
            kyc_details: [{ key: '', document: '' }],
        },
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            const updatedValues = {
                ...values,
                PCW: Boolean(values.PCW),
                PCN: Boolean(values.PCN),
                is_kyc: true,
                gpcc: true

            };


            setLoading(true);
            createClubProfile({ data: updatedValues })((response) => {
                console.log(response);

                if (response.status_code === 200 || response.status_code === 201) {
                    toast.success(id ? "Club Profile Updated Successfully" : 'Club Profile Created Successfully!');
                    resetForm();
                    navigate(CLUB_ONBOARDING_LIST);
                } else {
                    const errorMessage = response?.response?.data?.message?.non_field_errors?.[0] ||
                       
                        'Failed to create club profile!';

                    toast.error(errorMessage);


                }
                setLoading(false);
            });
        },
    });

    console.log(formik);

    const handleCheckboxChange = (event) => {
        formik.setFieldValue('is_kyc', event.target.checked);
        setIsChecked(event.target.checked);
    };


    const getGeoCode = async (lat, lng) => {
        const apiKey = GOOGLE_MAPS_API_KEY; // Use your actual API key
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.status === "OK") {
                let zipCode = "";
                data.results.forEach((result) => {
                    result.address_components.forEach((component) => {
                        if (component.types.includes("postal_code")) {
                            zipCode = component.long_name;
                        }
                    });
                });

                return zipCode;
            } else {
                console.error("Geocode error:", data.status);
                return "";
            }
        } catch (error) {
            console.error("Geocode API Error:", error);
            return "";
        }
    };

    const handlePlaceSelect = async () => {
        if (autocompleteRef.current) {
            const place = autocompleteRef.current.getPlace();

            if (place?.geometry?.location) {
                const latitude = place.geometry.location.lat();
                const longitude = place.geometry.location.lng();
                const addressComponents = place.address_components || [];

                let country = "";
                let city = "";
                let state = "";

                addressComponents.forEach((component) => {
                    if (component.types.includes("country")) {
                        country = component.long_name;
                    }
                    if (component.types.includes("locality")) {
                        city = component.long_name;
                    }
                    if (component.types.includes("administrative_area_level_1")) {
                        state = component.long_name; // Extract state
                    }
                });

                // Fetch ZIP code using latitude and longitude
                const zipCode = await getGeoCode(latitude, longitude);

                formik.setValues({
                    ...formik.values,
                    city: city,
                    state: state, // Set state
                    latitude: latitude,
                    longitude: longitude,
                    country: country,
                    zip_code: zipCode,
                    // primary_address: place.formatted_address || "",
                });
            }
        }
    };
    const handleChange = (e, name, selected_name) => {
        if (e) {
            formik.setValues({
                ...formik.values,
                [name]: e.value,
                [selected_name]: e,

            })
        } else {
            formik.setValues({
                [name]: null,
                [selected_name]: [],
            })
        }
    }
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            outline: 'none',
            boxShadow: 'none',
            color: 'var(--medium-gray)',
            fontSize: '15px',
            '&:hover': {
                border: '1px solid var(--light-grayish)', // Prevent hover border color
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        menu: (provided) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            boxShadow: 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--light-grayish)' : 'white',
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
    };

    const defaultoption = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
    const prefix = [{ label: 'Mr', value: 'Mr' }, { label: 'Miss', value: 'Miss' }, { label: 'Mrs', value: 'Mrs' }];

    useEffect(() => {
        if (id) {
            getClubProfile({ id, country: country })(async (response) => {
                if (response?.status) {

                    // Set form values
                    formik.setValues({
                        ...formik.values,
                        id: response?.data?.id,
                        club_name: response?.data?.club_name,
                        admin_email: response?.data?.admin_email,
                        prefix: response?.data?.prefix,
                        select_prefix: prefix.filter((item) => item.value === response?.data?.prefix),
                        country: response?.data?.country_name,
                        member_city: response?.data?.member_city,
                        city: response?.data?.city_name,
                        contact_number: response?.data?.contact,
                        state: response?.data?.state,
                        latitude: response?.data?.latitude,
                        superadmin_handled: response?.data?.superadmin_handled,
                        longitude: response?.data?.longitude,
                        primary_address: response?.data?.primary_address,
                        status: response?.data?.status,
                        summary: response?.data?.summary,
                        first_name: response?.data?.first_name,
                        last_name: response?.data?.last_name,
                        zip_code: response?.data?.zip_code,
                        is_kyc: response?.data?.is_kyc,
                        optional_service: response?.data?.optional_service,
                        selected_optionService: defaultoption.filter((item) => item.value === response?.data?.optional_service),

                    });
                    setIsKycCompleted(response?.data?.is_kyc);




                }
            });
        }
    }, [id, country]);



    const handleCityBlur = () => {
        if (!autocompleteRef.current || !autocompleteRef.current.getPlace()) {
            formik.setFieldValue('city', '');
        }
    };

    return (
        <div className='create-clubs-info-section create-clubs-profile-page'>
            <div className='page-details'>
                <div className='panel-type-1'>
                    {id ? "Edit Club" : " Add Club"}

                </div>
                <Form onSubmit={formik.handleSubmit}>
                    <div className='club-form mt-5'>
                        <div className='mt-5'>
                            <div className='row'>

                                <div className="col-md-4 col-xl-3 mb-3">
                                    <Form.Group controlId="clubName">
                                        <Form.Label>Club Name<span className='required'>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            className='dotted-input-half'
                                            placeholder="Club Name"
                                            name='club_name'
                                            value={formik.values?.club_name}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                formik.setFieldValue(
                                                    'club_name',
                                                    value.charAt(0).toUpperCase() + value.slice(1)
                                                );
                                            }}
                                            isInvalid={formik.touched.club_name && !!formik.errors.club_name}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik.touched.club_name && formik.errors.club_name}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-4 col-xl-3'>
                                    <Form.Group controlId="Email">
                                        <Form.Label>Email<span className='required'>*</span></Form.Label>
                                        <Form.Control type="email" placeholder="Email" name="admin_email"
                                            value={formik?.values?.admin_email}
                                            onChange={formik.handleChange}
                                            isInvalid={!!formik?.touched?.admin_email && formik?.errors?.admin_email}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik.touched.admin_email && formik.errors.admin_email}</Form.Control.Feedback>

                                    </Form.Group>
                                </div>

                                {/* <div className='col-md-4 col-xl-3'>
                                    <Form.Group controlId="clubName">
                                        <Form.Label>Contact number</Form.Label>
                                        <Form.Control placeholder="Contact Number" name="contact_number"
                                            value={formik?.values?.contact_number}
                                            onChange={formik.handleChange}
                                            isInvalid={formik?.touched?.contact_number && formik?.errors?.contact_number}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik?.errors?.contact_number}</Form.Control.Feedback>

                                    </Form.Group>
                                </div> */}



                                <div className="col-md-4 col-xl-3 mb-4">
                                    <Form.Group controlId="city">
                                        <Form.Label>City<span className="required">*</span></Form.Label>
                                        {isLoaded && (
                                            <Autocomplete
                                                onLoad={(auto) => (autocompleteRef.current = auto)}
                                                onPlaceChanged={handlePlaceSelect}
                                                options={{
                                                    types: ["(cities)"], // Restrict to cities only
                                                }}
                                            >
                                                <Form.Control
                                                    type="text"
                                                    placeholder="City"
                                                    name="city"
                                                    disabled={id}
                                                    onBlur={handleCityBlur}
                                                    value={formik.values.city}
                                                    onChange={formik.handleChange}
                                                    isInvalid={formik?.touched?.city && formik?.errors?.city}
                                                />
                                            </Autocomplete>

                                        )}
                                        <Form.Control.Feedback type="invalid">{formik.touched.city && formik.errors.city}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-md-4 col-xl-3 mb-4">
                                    <Form.Group controlId="state">
                                        <Form.Label>State<span className="required">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="State"
                                            name="state"
                                            value={formik.values.state}
                                            onChange={formik.handleChange}
                                            isInvalid={formik?.touched?.state && formik?.errors?.state}
                                            disabled
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.touched.state && formik.errors.state}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-md-4 col-xl-3 mb-4">
                                    <Form.Group controlId="country">
                                        <Form.Label>Country<span className='required'>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Country"
                                            name="country"
                                            value={formik.values.country}
                                            isInvalid={formik?.touched?.country && formik?.errors?.country}
                                            disabled

                                        />
                                        <Form.Control.Feedback type="invalid">{formik.touched.country && formik.errors.country}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-md-4 col-xl-3 mb-4">
                                    <Form.Group controlId="latitude">
                                        <Form.Label>Latitude<span className="required">*</span></Form.Label>
                                        <Form.Control
                                            type="number"
                                            step="any"
                                            placeholder="Latitude"
                                            name="latitude"
                                            value={formik.values.latitude}
                                            onChange={formik.handleChange}
                                            isInvalid={formik?.touched?.latitude && formik?.errors?.latitude}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.touched.latitude && formik.errors.latitude}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>

                                <div className="col-md-4 col-xl-3 mb-4">
                                    <Form.Group controlId="longitude">
                                        <Form.Label>Longitude<span className="required">*</span></Form.Label>
                                        <Form.Control
                                            type="number"
                                            step="any"
                                            placeholder="Longitude"
                                            name="longitude"
                                            value={formik.values.longitude}
                                            onChange={formik.handleChange}
                                            isInvalid={formik?.touched?.longitude && formik?.errors?.longitude}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.touched.longitude && formik.errors.longitude}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-4 col-xl-3'>
                                    <Form.Group controlId="Zip">
                                        <Form.Label>Zip Code<span className='required'>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Zip Code" name="zip_code"
                                            value={formik?.values?.zip_code}
                                            onChange={formik.handleChange}
                                            isInvalid={formik?.touched?.zip_code && formik?.errors?.zip_code}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik.touched.zip_code && formik.errors.zip_code}</Form.Control.Feedback>

                                    </Form.Group>
                                </div>
                                <div className="col-md-4 col-xl-3">
                                    <Form.Group controlId="Optional">
                                        <Form.Label>Optional Service</Form.Label>
                                        <Select
                                            placeholder="Select"
                                            styles={customStyles}
                                            options={defaultoption}
                                            isClearable
                                            isSearchable
                                            name='optional_service'
                                            value={formik.values?.selected_optionService}
                                            onChange={(e) => handleChange(e, 'optional_service', 'selected_optionService')}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4 col-xl-4">
                                    <Form.Group controlId="address2">
                                        <Form.Label>Address <span className='required'>*</span> </Form.Label>
                                        <Form.Control as="textarea" placeholder="Address" name="primary_address" rows={4} value={formik?.values?.primary_address}
                                            onChange={formik.handleChange}
                                            isInvalid={formik?.touched?.primary_address && formik?.errors?.primary_address} />
                                        <Form.Control.Feedback type="invalid">{formik.touched.primary_address && formik.errors.primary_address}</Form.Control.Feedback>
                                    </Form.Group>

                                </div>
                                <div className="col-md-4 col-xl-4">
                                    <Form.Group controlId="address3">
                                        <Form.Label>Summary  </Form.Label>
                                        <Form.Control as="textarea" placeholder="Summary" name="summary" rows={4} value={formik?.values?.summary}
                                            onChange={formik.handleChange}
                                            isInvalid={formik?.touched?.summary && formik?.errors?.summary} />
                                    </Form.Group>
                                </div>
                                <div className="col-md-5 col-xl-12 mt-3">
                                    <Form.Group controlId="handle">
                                        <div className="d-flex align-items-center gap-3 mt-2">

                                            <div className="custom-checkboxclub">
                                                <input
                                                    type="checkbox"
                                                    id="cityodres"
                                                    name="member_city"
                                                    checked={formik.values.member_city}
                                                    onChange={(e) => formik.setFieldValue('member_city', e.target.checked)}
                                                />
                                                <label htmlFor="cityodres" style={{fontWeight:"bold"}}>Apply City of the club as default for all club members.</label>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className='mt-5 panel-type-1'>
                                    General Manager

                                </div>
                                <div className="col-md-4 col-xl-3 mt-5">
                                    <Form.Group controlId="Prefix">
                                        <Form.Label>Prefix</Form.Label>
                                        <Select
                                            placeholder="Select"
                                            styles={customStyles}
                                            options={prefix}
                                            isClearable
                                            isSearchable
                                            name='prefix'
                                            value={formik.values?.select_prefix}
                                            onChange={(e) => handleChange(e, 'prefix', 'select_prefix')}
                                        />
                                    </Form.Group>
                                </div>
                                <div className='col-md-4 col-xl-3 mt-5'>
                                    <Form.Group controlId="First">
                                        <Form.Label>First Name<span className='required'>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="First Name" name="first_name"
                                            value={formik?.values?.first_name}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                formik.setFieldValue(
                                                    'first_name',
                                                    value.charAt(0).toUpperCase() + value.slice(1)
                                                );
                                            }}
                                            isInvalid={formik?.touched?.first_name && formik?.errors?.first_name}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik.touched.first_name && formik.errors.first_name}</Form.Control.Feedback>

                                    </Form.Group>
                                </div>
                                <div className='col-md-4 col-xl-3 mt-5'>
                                    <Form.Group controlId="Last">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="text" placeholder="Last Name" name="last_name"
                                            value={formik?.values?.last_name}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                formik.setFieldValue(
                                                    'last_name',
                                                    value.charAt(0).toUpperCase() + value.slice(1)
                                                );
                                            }}
                                            isInvalid={formik?.touched?.last_name && formik?.errors?.last_name}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik?.errors?.last_name}</Form.Control.Feedback>

                                    </Form.Group>
                                </div>
                                <div className='col-md-4 col-xl-3 mt-5'>
                                    <Form.Group controlId="First">
                                        <Form.Label>Contact Number<span className='required'>*</span></Form.Label>
                                        <Form.Control type="number" placeholder="Contact Number" name="contact_number"
                                            value={formik?.values?.contact_number}
                                            onChange={formik.handleChange}
                                            isInvalid={formik?.touched?.contact_number && formik?.errors?.contact_number}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik.touched.contact_number && formik.errors.contact_number}</Form.Control.Feedback>

                                    </Form.Group>
                                </div>
                                <div className="col-md-4 col-xl-3 mt-3">
                                    <Form.Group controlId="handle">
                                        <div className="d-flex align-items-center gap-3 mt-2">

                                            <div className="custom-checkboxclub">
                                                <input
                                                    type="checkbox"
                                                    id="superadminhandle"
                                                    name="superadmin_handled"
                                                    checked={formik.values.superadmin_handled}
                                                    onChange={(e) => formik.setFieldValue('superadmin_handled', e.target.checked)}
                                                />
                                                <label htmlFor="superadminhandle">Handled by Super Admin</label>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </div>

                                {/* {id && (
                                    <>
                                        <div className='row'>
                                            <div className="col-md-4 col-xl-3 mt-3">
                                                <Form.Group controlId="accountStatus">
                                                    <div className="d-flex align-items-center">
                                                        <Form.Label className="mb-0" style={{ whiteSpace: 'nowrap' }}>
                                                            Account Status:
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={formik?.values?.status}
                                                            style={{
                                                                border: 'none',
                                                                color: formik?.values?.status === 'KYC Pending' ? 'red' : 'green',
                                                                fontWeight: 'bold',
                                                            }}
                                                            readOnly
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </div>

                                            <div className="col-md-4 col-xl-3 mt-3">
                                                <Form.Group controlId="sendMail">
                                                    <div className="d-flex align-items-center gap-3 mt-2">
                                                        <Form.Label>Send Mail:</Form.Label>
                                                        <div className="custom-checkboxclub">
                                                            <input
                                                                type="checkbox"
                                                                id="kycCheckbox"
                                                                name="is_kyc"
                                                                checked={formik.values.is_kyc}
                                                                onChange={(e) => formik.setFieldValue('is_kyc', e.target.checked)}
                                                            />
                                                            <label htmlFor="kycCheckbox">KYC Completed</label>
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </>
                                )} */}

                            </div>

                            <div className='d-flex align-items-center justify-content-end gap-3 mt-5 submit-box'>
                                <button className='btn-type-2 cursor-pointer' type='button' onClick={() => navigate(-1)}>Cancel</button>
                                <button className='btn-type-1' type="submit" disabled={loading}>{id ? "Update" : "Save"}</button>



                            </div>


                        </div>

                    </div>
                </Form>

            </div>

        </div>
    )
}

export default ClubOnboarding
