import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { createorupdateUser, retriveGroupDropdown, retrivePermissionDropdown } from '../../api/actions/usermanagement';
import toast from 'react-hot-toast';
import { FaEyeSlash, FaRegEye } from 'react-icons/fa';
import AsyncSelect from 'react-select/async'
import { styled, Switch } from '@mui/material';
import { checkTheme } from '../../utils/utils';

const CustomSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 39,
        height: 20,
        padding: 0,
        marginLeft: 5,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          // margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: 'var(--sandy-brown)',
              opacity: 1,
              border: 0,
              ...theme.applyStyles('dark', {
                backgroundColor: '#2ECA45',
              }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
            ...theme.applyStyles('dark', {
              color: theme.palette.grey[600],
            }),
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            backgroundColor: 'var(--sandy-brown)',
            opacity: 0.7,
            ...theme.applyStyles('dark', {
              opacity: 0.3,
            }),
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 20,
          height: 20,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: '#E9E9EA',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
          ...theme.applyStyles('dark', {
            backgroundColor: '#39393D',
          }),
        },
      }));

      const selectStyle = {
        control: (styles, { isFocused }) => ({
          ...styles,
          borderRadius: '5px',
          maxHeight: "40px",
          minHeight: "30px",
          width:"200px",
          boxShadow: 'none',
          fontSize: "15px",
          backgroundColor: 'white',
          borderColor: isFocused ? 'gray' : styles.borderColor,
        }),
        placeholder: (styles) => ({
          ...styles,
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          fontSize: "15px",
          paddingLeft:"5px"
        }),
        menu: (styles) => ({
          ...styles,
          backgroundColor: 'white',
          width:"200px",
        }),
        option: (styles, { isFocused }) => ({
          ...styles,
          backgroundColor: isFocused ? 'lightgray' : 'white',
          color: 'black',
          width:"200px",
          }),
        };

const AddUserModal = ({openUserModal,setOpenUserModal, userInfo, updateUser, setUpdateUser}) => {

    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [user_groups, setGroup] = useState([]);
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
            retrivePermissionDropdown({ page: 1, limit: 100 })((response) => {
                if (response?.status_code == 200) {
                    setPermissions(response?.data?.results)
                }
            })
        }, []);
    

const formik = useFormik({
        initialValues: {
            user:'',
            first_name:'',
            last_name: '',
            username: '',
            email: '',            
            phonenumber: '',
            is_active: false,
            is_admin: false,
            is_staff: false,                      
            club:'',
            selectedClub:[],
            permissions: [],
            country:'',
            club_name: '',
            pcn: checkTheme(),
            pcw: !checkTheme()
        },
        validate: (values) => {
            const errors={}; 

            if (!values.first_name) {
                errors.first_name= "First Name required";
            }
           

            if (!values.email) {
                errors.email="Email required";
            } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
                errors.email="Invalid email address";
            }          

            return errors;

        },
        onSubmit: (values,{resetForm}) => {


            let valuesObj;

            // Exclude 'user' field as there's no id
            let { user, ...temp } = values;
            valuesObj = temp;
            

            if (!values?.username) {
                valuesObj.username = values?.first_name;
            }
            
            // Adding details for club admin
            if (userInfo?.club_admin || (userInfo?.is_staff && !userInfo?.is_superuser)) {
                valuesObj.country = userInfo?.club_details?.[0]?.country;
                valuesObj.club = userInfo?.club_details?.[0]?.club_id;
                valuesObj.club_name = userInfo?.club_details?.[0]?.club_name;
            }
            valuesObj.pcn = checkTheme();
            valuesObj.pcw = !checkTheme();
            
            if (userInfo?.is_superuser && !valuesObj?.club) {
                toast.error("Please select a club");
                return;
            }
            setLoading(true);
           
            // console.log(valuesObj,"||||VAL")
            
            setLoading(true);
            
            console.log(valuesObj);
                        
            createorupdateUser(valuesObj)((response)=>{
                console.log("response", response?.response);
                
                if(response?.status_code==200 || response?.status_code==201){
                    toast.success("User created successfully!")
                    resetForm()
                    setUpdateUser(!updateUser)
                    setOpenUserModal(!openUserModal)
                }else if(response?.response?.data?.errors?.password){
                    toast.error(response?.response?.data?.errors?.password?.[0] || "Something went wrong!");
                }else if(response?.response?.data?.errors?.email){
                    toast.error(response?.response?.data?.errors?.email?.[0] || "Something went wrong!");
                }else if(response?.response?.data?.errors?.username){
                    toast.error(response?.response?.data?.errors?.username?.[0] || "Something went wrong!");
                }
                setLoading(false);
            })

        }
    });

    // console.log(formik.values,"|||FOR")    

    const handleChecked1 = (e) => {
        formik.setValues({
            ...formik.values,
            is_active: e.target.checked,

        });
    };

     const selectAllPermission = () =>{
    
            let idList = permissions?.map(item => item.id);
    
            if(checkTheme()){
                idList = permissions?.filter(item => item?.codename !== "Affiliated Clubs")?.map(item => item.id);
            }        
            
            if(checkTheme() ? formik.values?.permissions?.length === 14 : formik.values?.permissions?.length === 15){
                formik.setValues({
                    ...formik.values,
                    permissions: []
                });
            }else{
                formik.setValues({
                    ...formik.values,
                    permissions: idList
                });
            }
        }
    
    const handleToggleChange = (id) => {
        let temp = [...formik.values.permissions]
        if (temp.includes(id)) {
            let filtertemp = temp.filter(item => item != id)
            formik.setValues({
                ...formik.values,
                permissions: filtertemp
            })
        }
        else {
            temp.push(id)
            formik.setValues({
                ...formik.values,
                permissions: temp
            })
        }

    }
    
  return (
    <Modal show={openUserModal} size="lg" onHide={() => { setOpenUserModal(!openUserModal) }} className="custom-modal">
    <Modal.Header closeButton>
        <Modal.Title>Add User</Modal.Title>
    </Modal.Header>

    <Modal.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <div className='row'>
                        <div className='col-md-4 col-sm-12 mb-1 mt-3'>
                            <Form.Group>
                                <Form.Label>First Name <sup className='text-danger'>*</sup></Form.Label>
                                <Form.Control
                                    type="text"
                                    className='dottedrole-input-half'
                                    placeholder="Enter First Name"
                                    name='first_name'
                                    maxLength={35}
                                    value={formik.values?.first_name}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        formik.setFieldValue(
                                            'first_name',
                                            value
                                        );
                                    }}
                                    isInvalid={formik.touched.first_name && formik.errors.first_name}
                                />
                                {formik.touched.first_name && formik.errors.first_name && 
                                    <Form.Control.Feedback type="invalid">{formik.errors.first_name}</Form.Control.Feedback>}
                            </Form.Group>
                        </div>
                        <div className='col-md-4 col-sm-12 mb-1 mt-3'>
                            <Form.Group>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    className='dottedrole-input-half'
                                    placeholder="Enter Last Name"
                                    name='last_name'
                                    maxLength={35}
                                    value={formik.values?.last_name}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        formik.setFieldValue(
                                            'last_name',
                                            value
                                        );
                                    }}                                    
                                />                               
                            </Form.Group>
                        </div>
                        <div className='col-md-4 col-sm-12 mb-1 mt-3'>
                            <Form.Group>
                                <Form.Label>Email<sup className='text-danger'>*</sup></Form.Label>
                                <Form.Control
                                    className='dottedrole-input-half'
                                    placeholder=" Enter Email ID"
                                    name='email'
                                    value={formik.values?.email}
                                    onChange={formik.handleChange}
                                    isInvalid={formik.touched.email && formik.errors.email}
                                />
                                {formik.touched.email && formik.errors.email && 
                                    <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>}
                            </Form.Group>
                        </div>                           
                        <div className='col-md-4 col-sm-12 mt-3'>
                            <Form.Group>
                                <Form.Label>Mobile</Form.Label>
                                <Form.Control
                                    type="number"
                                    className='input-field'
                                    name='phonenumber'
                                    value={formik.values?.phonenumber}
                                    onChange={formik.handleChange}
                                />
                            </Form.Group>
                        </div>                       
                        {userInfo?.is_superuser && (
                            <div className='col-md-3 col-sm-12 mt-3'>
                                <Form.Group>
                                    <Form.Label>Club</Form.Label>
                                    <AsyncSelect
                                        placeholder="Select..."
                                        isClearable
                                        value={formik?.values?.selectedClub}
                                        onChange={(selectedOption) => {
                                            formik.setFieldValue("club", selectedOption ? selectedOption.value : ""); 
                                            formik.setFieldValue("country", selectedOption ? selectedOption.country : ""); 
                                            formik.setFieldValue("selectedClub", selectedOption); 
                                            formik.setFieldValue("club_name", selectedOption?.label || ""); 
                                        }}
                                        // defaultOptions={clubDropdown}
                                        // loadOptions={loadOptionClub}
                                        styles={selectStyle}
                                        // isDisabled={id ? true : false}
                                    />
                                </Form.Group>
                            </div>
                        )}
                        <div className='col-md-2 col-sm-12 mt-5'>
                            <Form.Group>
                                <Form.Label>Active</Form.Label>
                                <CustomSwitch  
                                    checked={formik.values?.is_active}
                                    onChange={handleChecked1}
                                    color="primary"
                                    name="is_active"
                                    value={formik.values?.is_active} 
                                />
                            </Form.Group>
                        </div>
                        <div>
                            <div className='mb-2 mt-5 panel-type-1'>Permissions</div>
                            {Array.isArray(permissions) && permissions?.length 
                                ? <Form.Check
                                        label="Select All"     
                                        checked={checkTheme() ? formik.values?.permissions?.length === 14 : formik.values?.permissions?.length === 15} 
                                        onChange={() => selectAllPermission()}
                                    /> 
                                : '' }
                            {(!checkTheme() ? 
                                permissions 
                                : permissions?.filter(item => item?.codename !== "Affiliated Clubs")
                                )?.map((item, index) => (
                                    <div key={index}>
                                    <Form.Check
                                        label={item?.codename}
                                        checked={formik.values.permissions?.includes(item?.id)}
                                        onChange={() => handleToggleChange(item?.id)}
                                    />
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-end gap-3 mt-5 submit-box'>
                        <button type="button" className='btn-type-2 cursor-pointer' onClick={() => setOpenUserModal(!openUserModal)}>Cancel</button>
                        <button className='btn-type-1' type="submit" disabled={loading}>{loading ? "Submitting" : "Submit"}</button>
                    </div>
                </Form>
            </Modal.Body>
    </Modal>
  )
}

export default AddUserModal