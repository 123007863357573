import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as FilterIcon } from "../../assets/images/common/filter-icon.svg";
import { ReactComponent as ExcelIcon } from "../../assets/images/common/sheets.svg";
import AsyncSelect from 'react-select/async';
import Drawer from "@mui/material/Drawer";
import { Form } from "react-bootstrap";
// Rsuite Datepicker
import { DatePicker } from 'rsuite';
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { listMemberRequestBooking, listMemberRequestBookingAdmin, rcModerationBookingList } from "../../api/actions/bookings";
import { FaTimes } from "react-icons/fa";
import dayjs from "dayjs";
import { clubDropdowns } from "../../api/actions/clubProfile";
import Calendar from '../../assets/images/common/calendar.svg'
import InfiniteScroll from "react-infinite-scroll-component";
import { VIEW_BOOKINGS, VIEW_RECIPROCAL_REQUESTS } from "../../constants/pathname";
import checkThemePcw, { checkTheme } from "../../utils/utils";
import LoaderComponent from "../Loader";
import { CLUB_RECIPROCAL_EXCEL } from "../../api/api";

const ReciprocalRequests = () => {

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [search, setSearch] = useState('');
  const [update, setUpdate] = useState(false);
  const [pending, setPending] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [searchText, setSearchText] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [clubDropdown, setClubdropdown] = useState([]);
  const [club, setclub] = useState(null)
  const [status, setStatus] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  let userInfo = JSON.parse(localStorage.getItem("user_info"))

  // Permissions
  const [permissionsList, setPermissionsList] = useState([]);

  // Setting permission list
  useEffect(() => {
    let permissions = JSON.parse(localStorage.getItem("user_info"));

    if (permissions && permissions?.permissions && permissions?.permissions?.length) {
      setPermissionsList(permissions?.permissions);
    }

  }, []);

  const handleExportMemberList = () => {
    const myHeaders = new Headers();
    let token = localStorage.getItem('token');

    myHeaders.append(`Authorization`, `Bearer ${token}`);


    const isCheckthemeEnabled = checkTheme();


    let queryParams = `?pcn=${isCheckthemeEnabled}`;

    if (!userInfo?.is_superuser) {
      const club = userInfo?.club_details?.[0]?.club_id;
      const country = userInfo?.club_details?.[0]?.country;
      queryParams += `&country=${country}&club=${club}`;
    }

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    // Fetch the data with the dynamically constructed URL
    fetch(`${process.env.REACT_APP_BACKENDURL}${CLUB_RECIPROCAL_EXCEL}${queryParams}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = url;
        a.download = "Club Members.xls";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error(error));
  };
  const selectStyle = {
    control: (styles, { isFocused }) => ({
      ...styles,
      borderRadius: '5px',
      maxHeight: "40px",
      minHeight: "30px",
      boxShadow: 'none',
      fontSize: "15px",
      backgroundColor: 'white',
      borderColor: isFocused ? 'gray' : styles.borderColor,
    }),
    placeholder: (styles) => ({
      ...styles,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      fontSize: "15px"
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: 'white',
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? 'lightgray' : 'white',
      color: 'black',
    }),
  };

  const handleFilter = () => {
    setPage(1)
    setShowDrawer(false);
    setUpdate(!update)
    // getListData();
  }

  const handleClear = () => {
    setFromDate(null);
    setToDate(null);
    setclub(null)
    setStatus(null)
    setUpdate(!update)
    setShowDrawer(false);
  }

  const handleSearch = (value) => {
    setSearch(value);
    setSearchText(value);
  }

  useEffect(() => {
    if (search.length >= 2) {
      const debounceTimer = setTimeout(() => {
        setDebouncedSearch(search);
      }, 500);

      return () => clearTimeout(debounceTimer);
    } else {
      setDebouncedSearch('');
    }
  }, [search]);

  const clearSearch = () => {
    setSearch('');
    setSearchText('');
    setDebouncedSearch('');
    setUpdate(!update);
  };

  let typingTimer;
  const loadOptionClub = inputValue => {
    return new Promise((resolve, reject) => {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        if (inputValue.length >= 3) {
          clubDropdowns({ page: 1, limit: 10, search: inputValue })(response => {
            if (response?.status) {

              resolve(response?.data?.results)
              setclub(response?.data?.results)

            }
          })
        }
        else {
          resolve([])
        }
      }, 300);

    })
  };

  const getclub = () => {
    clubDropdowns({ page: 1, limit: 10 })(response => {
      if (response?.status) {
        setClubdropdown(response?.data?.results)
      }
    })
  }

  useEffect(() => {
    getclub();
  }, [])




  // Scroll to top    
  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);


  useEffect(() => {
    getListData();
  }, [debouncedSearch, update])

  const loadMoreData = () => {
    setPending(true)
    if (!hasMore) return;
    console.log("Fetching more data...");

    rcModerationBookingList({
      page: page + 1,
      limit: limit,
      search: debouncedSearch,
      club: userInfo?.club_details[0]?.club_id,
      country: userInfo?.club_details[0]?.country
    })(response => {
      if (response?.status) {
        console.log("More data received:", response.data.results);

        setData(prevData => [...prevData, ...response?.data?.results]);
        setHasMore(response?.data?.results?.length > 0);
        setPending(false)
      }
    });

    setPage((prevPage) => prevPage + 1);
  };


  const getListData = () => {
    setPending(true);

    rcModerationBookingList({
      page: debouncedSearch ? 1 : page,
      limit,
      search: debouncedSearch,
      start_date: fromDate ? dayjs(fromDate).format('YYYY-MM-DD') : '',
      end_date: toDate ? dayjs(toDate).format('YYYY-MM-DD') : '',
      club_name: club?.label || '',
      new_status: status?.value || '',
      club: userInfo?.club_details[0]?.club_id || null,
      country: userInfo?.club_details[0]?.country || null
    })(
      (response) => {
        if (response && response.status) {
          const colors = ["#f8edeb", "#dbe5d9", "#ebeae1"];
          const updatedData = response?.data?.results?.map(item => ({
            ...item,
            color: colors[Math.floor(Math.random() * colors.length)]
          }));

          setData(updatedData);
          setTotalRows(response?.data?.count);
          setPage(1)
          setPending(false);
        }
      }
    );

  }

  const options = [
    { label: "In Review", value: "In Review" },
    { label: "Home Club Declined", value: "Home Club Declined" },
    { label: "Accepted", value: "Accepted" }
  ];

  return (
    <div className="member-stats-section">
      <Drawer
        anchor={"right"}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        className="member-stats-drawer"
        sx={{
          "& .MuiPaper-root": {
            padding: "30px 19px",
            height: "100vh",
            // overflow: "hidden"
          },
          "& .MuiDrawer-paper": {
            width: "420px",
          },
          "& .MuiBox-root": {
            width: "420px",
          },
          "& .MuiList-root": {
            display: "flex",
            columnGap: "10px",
            width: "90%",
            margin: "auto",
            paddingTop: "25px",
          },
          "& .MuiButtonBase-root": {
            padding: "7px",
            textAlign: "center",
            alignItems: "center",
            borderRadius: "10px",
            backgroundColor: "#F5F5F5",
          },
        }}
      >
        <div className="drawer-top-box">
          <div>Filter</div>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={() => setShowDrawer(false)}
          ></button>
        </div>
        <div className="drawer-bottom-box mt-4">
          <Form onSubmit={(e) => e.preventDefault()}>
            {/* Booking ID */}

            {/* Start Date */}
            <div className='item start-date-input'>
              <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em11">
                <Form.Label>Start Date</Form.Label>
                <div className='input-border'>
                  <DatePicker
                    id='from_date'
                    onChange={(value) => {
                      setFromDate(value);
                      if (toDate && value > toDate) {
                        setToDate(null);
                      }
                    }}
                    value={fromDate}
                    placement="autoVerticalEnd"
                  />
                </div>
              </Form.Group>
            </div>
            {/* End Date */}
            <div className='item end-date-input'>
              <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em4">
                <Form.Label>End Date</Form.Label>
                <div className='input-border'>
                  <DatePicker
                    id='to_date'
                    onChange={(value) => setToDate(value)}
                    placement="autoVerticalEnd"
                    shouldDisableDate={(date) => {

                      return fromDate ? date < fromDate : false;
                    }}
                    value={toDate}
                  />
                </div>
              </Form.Group>
            </div>
            {/* Club Name */}
            {userInfo?.is_superuser &&
              <div className="item">
                <Form.Group
                  className="mb-3 d-flex align-items-center justify-content-between"
                  controlId="exampleForm.ControlInput1em5"
                >
                  <Form.Label>Club Name</Form.Label>
                  <div className="input-border">
                    <AsyncSelect
                      placeholder="Select..."
                      isClearable
                      value={club}
                      onChange={(e) => setclub(e)}
                      defaultOptions={clubDropdown}
                      loadOptions={loadOptionClub}
                      styles={selectStyle}
                    />
                  </div>
                </Form.Group>
              </div>
            }

            {/* Status */}
            <div className="item">
              <Form.Group
                className="mb-3 d-flex align-items-center justify-content-between"
                controlId="exampleForm.ControlInput1em5"
              >
                <Form.Label>Status</Form.Label>
                <div className="input-border">
                  <Select
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 5,
                      colors: {
                        ...theme.colors,
                      },
                    })}
                    placeholder="Select"
                    options={options}
                    isSearchable={false}
                    isClearable={true}
                    value={status}  // Ensure the value is set
                    onChange={(selected) => setStatus(selected)}  // Update status on change
                  />

                </div>
              </Form.Group>
            </div>

            <div className='submit-box d-flex align-items-center justify-content-end gap-3' style={{ height: "40vh" }}>
              <div className='btn-type-2 cursor-pointer' onClick={handleClear} >Clear</div>
              <button className='btn-type-1' type="submit" onClick={handleFilter}>Submit</button>
            </div>
          </Form>
        </div>
      </Drawer>


      <div className="page-details">
        <div className="top-section d-flex align-items-center justify-content-between">
          <div className="left">Reciprocal Requests for Moderation</div>
          <div className="right d-flex align-items-center  gap-3">
            <div className='search-box'>
              <input
                type='text'
                placeholder='Search'
                className='form-control'
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
              />
              {
                searchText && (
                  <FaTimes className='clear-icon' onClick={clearSearch} />
                )}
            </div>
            <div
              className="filter-box d-flex align-items-center  gap-3 cursor-pointer"
              onClick={() => setShowDrawer(true)}

            >
              <FilterIcon />
              <span>Filter</span>
            </div>
            {/* {permissionsList && permissionsList.includes("download_member_request_and_facility_booking") && */}
            <div className="download-excel cursor-pointer" title='Download' onClick={() => handleExportMemberList()}>
              <ExcelIcon />
            </div>
            {/* } */}
          </div>
        </div>

        <div
          id="scrollableDiv"
          className="booking-container mt-4 px-4"
          style={{ overflowY: "auto", maxHeight: "calc(100vh - 250px)" }}
        >
          <InfiniteScroll
            dataLength={data?.length || 0}
            next={loadMoreData}
            hasMore={data?.length < totalRows}
            scrollableTarget="scrollableDiv"

          >

            {data?.map((item, index) => {
              const statusColors = {
                Requested: "#caf0f8",
                Expired: "#f8edeb",
                "Admin Declined": "#f8edeb",
                "Member Cancelled": "#f8edeb",
                "Club Modified": "#ebeae1",
                "Admin Modified": "#ebeae1",
                Booked: "#dbe5d9",
                "Pending Payment": "#ebeae1",
                "Accepted": "#dbe5d9",
                "Admin Requested Letter": "#ebeae1",
                "Member Modified": "#ebeae1",
                "Admin Cancelled": "#f8edeb",
                "Completed": "#dbe5d9",
                "In Review": "#ebeae1",
                "Home Club Declined": "#f8edeb",
                "Available": "#dbe5d9",
              };




              const test = (status) => {
                switch (status) {
                  case "In Review": {
                    return { label: status, color: statusColors["In Review"] }
                  }
                  case "Home Club Declined": {
                    return { label: status, color: statusColors["Home Club Declined"] }
                  }
                  default: {
                    return { label: 'Accepted', color: statusColors["Accepted"] }
                  }
                }
              }

              const testTwo = (status) => {
                if (userInfo?.is_superuser) {
                  return {
                    label: status,
                    color: statusColors[status]
                  }
                }
                switch (status) {
                  case "In Review": {
                    return { label: status, color: statusColors["In Review"] }
                  }
                  case "Home Club Declined": {
                    return { label: status, color: statusColors["Home Club Declined"] }
                  }
                  default: {
                    return { label: 'Accepted', color: statusColors["Accepted"] }
                  }
                }
              }
              const { color, label } = testTwo(item?.status)
              return (
                <div
                  className="booking-item"
                  key={index}
                  onClick={() => navigate(VIEW_RECIPROCAL_REQUESTS + "/" + item.id)}
                >
                  <div className="booking-list"
                    style={{
                      backgroundColor: color
                    }}>
                    <div className="header-grid">
                      <div>
                        <p className="title">Member Name</p>
                        <span className="names">{item.member_name || "N/A"}</span>
                      </div>
                      <div>
                        <p className="title">Member Home Club(s)</p>
                        <span className="names">{item.member_home_club || "N/A"}</span>
                      </div>
                    </div>

                    <div className="bkng-dtils">
                      <div className="details-grid">
                        <div>
                          <p>Request Type</p>
                          <span className="names">{item.request_type || "N/A"}</span>
                        </div>
                        <div>
                          <p>Club Name</p>
                          <span className="names">{item.club_name || "N/A"}</span>
                        </div>
                        <div>
                          <p>Booking ID</p>
                          <span className="names">{item.booking_id || "N/A"}</span>
                        </div>
                        <div>
                          <p>No of Guests</p>
                          <span className="names">{item.guest_number || "0"}</span>
                        </div>
                        <div>
                          <p>Booking Date</p>
                          <span className="names">
                            {dayjs(item.start_date).format('MMM D, YYYY')} - {dayjs(item.end_date).format('MMM D, YYYY')}
                          </span>
                          <div className="row">
                            <span className="col-md-5 date-frmt">Start Date</span>
                            <span className="col-md-6 date-frmt">End Date</span>
                          </div>
                        </div>
                        <div>
                          <p>Status</p>
                          <button type="button" className={`btn-type-7 status-${item.status.toLowerCase()}`}>
                            {label}
                          </button>
                        </div>
                      </div>
                      <div className="cal-ico">
                        <img src={Calendar} alt="Calendar Icon" />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}

          </InfiniteScroll>
          {pending ? <LoaderComponent /> : null}
          { data?.length == 0 && !pending ? 
            <div className="text-center">
              <p>No records found</p>
            </div> : null
          }
        </div>

        {/* {pending ? <LoaderComponent/> : (
          <InfiniteScroll
            dataLength={data?.length || 0}
            next={loadMoreData}
            hasMore={data?.length < totalRows}
            // loader={<h4 className="text-center py-3">Loading more items...</h4>}
            // endMessage={
            //   <p className="text-center py-3">
            //     <b>{data?.length == 0 ? "No Bookings yet" :"You've seen all items"}</b>
            //   </p>
            // }
            // height="calc(100vh - 250px)"
            scrollableTarget="scrollableDiv"
          >
            <div
              id="scrollableDiv"
              className="booking-container mt-4 px-4"
              style={{ overflowY: "auto", maxHeight: "calc(100vh - 250px)" }} 
            >
              {data?.map((item, index) => {
              const statusColors = {
                Requested: "#caf0f8",
                Expired: "#f8edeb",
                "Admin Declined": "#f8edeb",
                "Member Cancelled": "#f8edeb",
                "Club Modified": "#ebeae1",
                "Admin Modified": "#ebeae1",
                Booked: "#dbe5d9",
                "Pending Payment": "#ebeae1",
                "Accepted": "#dbe5d9",
                "Admin Requested Letter": "#ebeae1",
                "Member Modified": "#ebeae1",
                "Admin Cancelled": "#f8edeb",
                "Completed": "#dbe5d9",
                "In Review":"#ebeae1",
                "Home Club Declined":"#f8edeb",
                "Available": "#dbe5d9",
              };



      
              const test = (status) => {
                switch(status) {
                  case "In Review": {
                    return {label:status,color:statusColors["In Review"]}
                  }
                  case "Home Club Declined" : {
                    return{ label :status, color:statusColors["Home Club Declined"]}
                  }
                  default: {
                    return{ label :'Accepted', color:statusColors["Accepted"]}
                  }
                }
              }

               const testTwo = (status) => {
                if (userInfo?.is_superuser) {
                  return {
                label :status,
                    color:statusColors[status]
                  }
                }
                switch(status) {
                  case "In Review": {
                    return {label:status,color:statusColors["In Review"]}
                  }
                  case "Home Club Declined" : {
                    return{ label :status, color:statusColors["Home Club Declined"]}
                  }
                  default: {
                    return{ label :'Accepted', color:statusColors["Accepted"]}
                  }
                }
              }
              const  {color, label}= testTwo(item?.status)
              return(
                <div
                  className="booking-item"
                  key={index}
                  onClick={() => navigate(VIEW_RECIPROCAL_REQUESTS + "/" + item.id)}
                >
                 <div className="booking-list" 
                    style={{ 
                      backgroundColor:color
                    }}>
                    <div className="header-grid">
                      <div>
                        <p className="title">Member Name</p>
                        <span className="names">{item.member_name || "N/A"}</span>
                      </div>
                      <div>
                        <p className="title">Member Home Club(s)</p>
                        <span className="names">{item.member_home_club || "N/A"}</span>
                      </div>
                    </div>

                    <div className="bkng-dtils">
                      <div className="details-grid">
                        <div>
                          <p>Request Type</p>
                          <span className="names">{item.request_type || "N/A"}</span>
                        </div>
                        <div>
                          <p>Club Name</p>
                          <span className="names">{item.club_name || "N/A"}</span>
                        </div>
                        <div>
                          <p>Booking ID</p>
                          <span className="names">{item.booking_id || "N/A"}</span>
                        </div>
                        <div>
                          <p>No of Guests</p>
                          <span className="names">{item.guest_number || "0"}</span>
                        </div>
                        <div>
                          <p>Booking Date</p>
                          <span className="names">
                            {dayjs(item.start_date).format('MMM D, YYYY')} - {dayjs(item.end_date).format('MMM D, YYYY')}
                          </span>
                          <div className="row">
                            <span className="col-md-5 date-frmt">Start Date</span>
                            <span className="col-md-6 date-frmt">End Date</span>
                          </div>
                        </div>
                        <div>
                          <p>Status</p>
                          <button type="button" className={`btn-type-7 status-${item.status.toLowerCase()}`}>
                            {label}
                          </button>
                        </div>
                      </div>
                      <div className="cal-ico">
                        <img src={Calendar} alt="Calendar Icon" />
                      </div>
                    </div>
                  </div>
                </div>
              )
              })}
            </div>
          </InfiniteScroll>
        )} */}

      </div>
    </div>
  );
};

export default ReciprocalRequests;
