import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import Tooltip from "@mui/material/Tooltip";
// Import your icons and constants as before
import { ReactComponent as DashboardIcon } from "../../assets/images/sidebar/dashboard.svg";
import { ReactComponent as GPCAIcon } from "../../assets/images/sidebar/Global Private Club Accesss.svg";
import { ReactComponent as CuratedIcon } from "../../assets/images/sidebar/curated-member-events.svg";
import { ReactComponent as CurrentClubIcon } from "../../assets/images/sidebar/Current Club Events.svg";
import { ReactComponent as PrivateIcon } from "../../assets/images/sidebar/private-events.svg";
import { ReactComponent as MemberStatsIcon } from "../../assets/images/sidebar/user.svg";
import { ReactComponent as ClubsInformationIcon } from "../../assets/images/sidebar/clubs-information.svg";
import { ReactComponent as ClubsFacilitiesIcon } from "../../assets/images/sidebar/facilities.svg";
import { ReactComponent as SpecialSubscribersIcon } from "../../assets/images/sidebar/subscriber.svg";
import { ReactComponent as BookingsIcon } from "../../assets/images/sidebar/calendar.svg";
import { ReactComponent as InterestsIcon } from "../../assets/images/sidebar/like.svg";
import { ReactComponent as BroadcastIcon } from "../../assets/images/sidebar/bell.svg";
import { ReactComponent as MembersIcon } from "../../assets/images/sidebar/svg/team.svg";
import { ReactComponent as OfferIcon } from "../../assets/images/sidebar/offer.svg";
import { ReactComponent as LetterIcon } from "../../assets/images/sidebar/svg/note.svg";
import { ReactComponent as SolitaireIcon } from "../../assets/images/sidebar/svg/solitaire.svg";
import { ReactComponent as FaciiltyIcon } from "../../assets/images/sidebar/svg/facility-management.svg";
import { ReactComponent as WebIcon } from "../../assets/images/sidebar/svg/globe.svg";
import { ReactComponent as ManagementIcon } from "../../assets/images/sidebar/svg/management.svg";
import { ReactComponent as Usersub } from '../../assets/images/sidebar/svg/user1sub.svg';

// Import your path constants as before
import {
    HOME,
    MEMBER_STATS,
    CLUBS_INFORMATION,
    CLUBS_FACILITIES,
    SPECIAL_SUBSCRIBERS,
    BOOKINGS,
    INTERESTS,
    PUSH_NOTIFICATIONS,
    OFFER,
    CREATE_CLUBS_INFORMATION,
    VIEW_CLUBS_INFORMATION,
    CREATE_SPECIAL_SUBSCRIBERS,
    RECIPROCAL_REQUESTS,
    PLACES,
    CREATE_INTERESTS,
    CREATE_PLACES,
    CREATE_PUSH_NOTIFICATIONS,
    CREATE_LETTER_OF_INTRODUCTION,
    MANAGE_MEMBERS,
    CREATE_CLUBS_FACILITY,
    FACILITIES_CATEGORY,
    CREATE_FACILITIES_CATEGORY,
    CLUB_PROFILE,
    CREATE_CLUB_PROFILE,
    CLUB_ONBOARDING_LIST,
    USER_MANAGEMENT,
    GROUPS,
    USERS,
    ADD_ROLE,
    EDIT_ROLE,
    ADD_GROUP,
    EDIT_GROUP,
    ADD_USER,
    EDIT_USER,
    MANAGE_NETWORK_CLUBS,
    CURATION_PROFILE,
    CURRENT_CLUB_EVENTS,
    CLUB_EVENTS_PROFILE,
    PRIVATE_EVENTS_PROFILE,
    PROMOTIONS,
    AFFILIATED_CLUBS,
    SUBSCRIPTION_PRIVILEGES,
    BLACKOUT_DATES,
    GPCA_OVERVIEW,
    MEMBERREVIEWLIST,
    CLUB_TYPE_LIST,
    ADD_CLUB_TYPE,
    USER_SUBSCRIPTION_LIST,
} from "../../constants/pathname";
import { checkTheme } from "../../utils/utils";

// PCW Super Admin Sidebar
const PcwSuperAdminSidebar = ({ location, navigate, showuserManagementSubMenu, setShowUserManagementSubMenu, permissionsList, userInfoObj, handleSubMenuToggle, openSubMenu, setOpenSubMenu, minimizeSidebar }) => {

    return (
        <Menu>
            {/* Dashboard */}
            <Link to={HOME}>
                <MenuItem
                    icon={
                        minimizeSidebar ? (
                            <Tooltip title="Dashboard" placement="right">
                                <span><DashboardIcon /></span>
                            </Tooltip>
                        ) : (
                            <DashboardIcon />
                        )
                    }
                    active={location?.pathname === HOME}
                >
                    {!minimizeSidebar && "Dashboard"}
                </MenuItem>
            </Link>

            {/* Member Stats */}
            <Link to={MEMBER_STATS}>
                <MenuItem
                    icon={
                        minimizeSidebar ? (
                            <Tooltip title="Member Stats" placement="right">
                                <span><MemberStatsIcon /></span>
                            </Tooltip>
                        ) : (
                            <MemberStatsIcon />
                        )
                    }
                    active={location?.pathname === MEMBER_STATS}
                >
                    {!minimizeSidebar && "Member Stats"}
                </MenuItem>
            </Link>
            {/* Manage Network Clubs */}

            {/* Club Details */}
            <Link to={CLUBS_INFORMATION}>
                <MenuItem
                    icon={
                        minimizeSidebar ? (
                            <Tooltip title="Club Details" placement="right">
                                <span><ClubsInformationIcon /></span>
                            </Tooltip>
                        ) : (
                            <ClubsInformationIcon />
                        )
                    }
                    active={[CLUBS_INFORMATION, CREATE_CLUBS_INFORMATION, CREATE_CLUB_PROFILE, VIEW_CLUBS_INFORMATION].includes(location?.pathname)}
                >
                    {!minimizeSidebar && "Club Details"}
                </MenuItem>
            </Link>
            {/* Bookings */}
            <SubMenu
                label={!minimizeSidebar && "Bookings"}
                icon={
                    minimizeSidebar ? (
                        <Tooltip title="Bookings" placement="right">
                            <span><BookingsIcon /></span>
                        </Tooltip>
                    ) : (
                        <BookingsIcon />
                    )
                }
                open={openSubMenu === "Bookings"}
                onOpenChange={() => handleSubMenuToggle("Bookings")}
            >
                <Link to={BOOKINGS}>
                    <MenuItem
                        active={location?.pathname === BOOKINGS || (location?.pathname?.split('/').includes('view-bookings'))}
                    >
                        Member Requests and Facility Bookings
                    </MenuItem>
                </Link>
                <Link to={RECIPROCAL_REQUESTS}>
                    <MenuItem
                        active={location?.pathname === RECIPROCAL_REQUESTS || (location?.pathname?.split('/').includes('view-reciprocal-requests'))}
                        onClick={() => navigate(RECIPROCAL_REQUESTS)}
                    >
                        Reciprocal Requests for Moderation
                    </MenuItem>
                </Link>
            </SubMenu>
            {/* Manage Members */}
            <Link to={MANAGE_MEMBERS}>
                <MenuItem
                    icon={
                        minimizeSidebar ? (
                            <Tooltip title="Manage Members" placement="right">
                                <span><MembersIcon /></span>
                            </Tooltip>
                        ) : (
                            <MembersIcon />
                        )
                    }
                    active={[MANAGE_MEMBERS].includes(location?.pathname) && !showuserManagementSubMenu}
                >
                    {!minimizeSidebar && "Manage Members"}
                </MenuItem>
            </Link>
            {/* Member Testimonials */}
            <Link to={MEMBERREVIEWLIST}>
                <MenuItem
                    icon={
                        minimizeSidebar ? (
                            <Tooltip title="Member Testimonials" placement="right">
                                <span><ClubsFacilitiesIcon /></span>
                            </Tooltip>
                        ) : (
                            <ClubsFacilitiesIcon />
                        )
                    }
                    active={[MEMBERREVIEWLIST].includes(location?.pathname)}
                >
                    {!minimizeSidebar && "Member Testimonials"}
                </MenuItem>
            </Link>
            {/* Club Onboarding */}
            <Link to={CLUB_ONBOARDING_LIST}>
                <MenuItem
                    icon={
                        minimizeSidebar ? (
                            <Tooltip title="Club Onboarding" placement="right">
                                <span><CurrentClubIcon /></span>
                            </Tooltip>
                        ) : (
                            <CurrentClubIcon />
                        )
                    }
                    active={[CLUB_ONBOARDING_LIST].includes(location?.pathname)}
                >
                    {!minimizeSidebar && "Club Onboarding"}
                </MenuItem>
            </Link>

            {/* Affiliated Clubs */}
            <Link to={AFFILIATED_CLUBS}>
                <MenuItem
                    icon={
                        minimizeSidebar ? (
                            <Tooltip title="Affiliated Clubs" placement="right">
                                <span><ClubsFacilitiesIcon /></span>
                            </Tooltip>
                        ) : (
                            <ClubsFacilitiesIcon />
                        )
                    }
                    active={location?.pathname === AFFILIATED_CLUBS}
                >
                    {!minimizeSidebar && "Affiliated Clubs"}
                </MenuItem>
            </Link>
            {/* Manage Network/Reciprocal Clubs */}
            <Link to={MANAGE_NETWORK_CLUBS}>
                <MenuItem
                    icon={
                        minimizeSidebar ? (
                            <Tooltip title={checkTheme() ? 'Manage Network Clubs' : 'Manage Reciprocal Clubs'} placement="right">
                                <span><ClubsInformationIcon /></span>
                            </Tooltip>
                        ) : (
                            <ClubsInformationIcon />
                        )
                    }
                    active={[MANAGE_NETWORK_CLUBS].includes(location?.pathname)}
                >
                    {!minimizeSidebar && (checkTheme() ? 'Manage Network Clubs' : 'Manage Reciprocal Clubs')}
                </MenuItem>
            </Link>
            {/* Blackout Dates */}
            <Link to={BLACKOUT_DATES}>
                <MenuItem
                    icon={
                        minimizeSidebar ? (
                            <Tooltip title="Blackout Dates" placement="right">
                                <span><BookingsIcon /></span>
                            </Tooltip>
                        ) : (
                            <BookingsIcon />
                        )
                    }
                    active={[BLACKOUT_DATES].includes(location?.pathname)}
                >
                    {!minimizeSidebar && "Blackout Dates"}
                </MenuItem>
            </Link>
            {/* Club Invitations */}
            <Link to={OFFER}>
                <MenuItem
                    icon={
                        minimizeSidebar ? (
                            <Tooltip title="Club Invitations" placement="right">
                                <span><OfferIcon /></span>
                            </Tooltip>
                        ) : (
                            <OfferIcon />
                        )
                    }
                    active={[OFFER].includes(location?.pathname)}
                >
                    {!minimizeSidebar && "Club Invitations"}
                </MenuItem>
            </Link>
            {/* Subscription Privileges */}
            <Link to={SUBSCRIPTION_PRIVILEGES}>
                <MenuItem
                    icon={
                        minimizeSidebar ? (
                            <Tooltip title="Subscription Privileges" placement="right">
                                <span><SolitaireIcon /></span>
                            </Tooltip>
                        ) : (
                            <SolitaireIcon />
                        )
                    }
                    active={[SUBSCRIPTION_PRIVILEGES].includes(location?.pathname)}
                >
                    {!minimizeSidebar && "Subscription Privileges"}
                </MenuItem>
            </Link>
            {/* Special Subscribers */}
            <Link to={SPECIAL_SUBSCRIBERS}>
                <MenuItem
                    icon={
                        minimizeSidebar ? (
                            <Tooltip title="Special Subscribers" placement="right">
                                <span><SpecialSubscribersIcon /></span>
                            </Tooltip>
                        ) : (
                            <SpecialSubscribersIcon />
                        )
                    }
                    active={[SPECIAL_SUBSCRIBERS, CREATE_SPECIAL_SUBSCRIBERS].includes(location?.pathname)}
                >
                    {!minimizeSidebar && "Special Subscribers"}
                </MenuItem>
            </Link>
            <Link to={USER_SUBSCRIPTION_LIST}>
                <MenuItem
                    icon={
                        minimizeSidebar ? (
                            <Tooltip title="User Subscription" placement="right">
                                <span><Usersub /></span>
                            </Tooltip>
                        ) : (
                            <Usersub />
                        )
                    }
                    active={[USER_SUBSCRIPTION_LIST, USER_SUBSCRIPTION_LIST].includes(location?.pathname)}
                >
                    {!minimizeSidebar && "User Subscription"}
                </MenuItem>
            </Link>
            {/* Product Settings */}
            <SubMenu
                label={!minimizeSidebar && "Product Settings"}
                icon={
                    minimizeSidebar ? (
                        <Tooltip title="Product Settings" placement="right">
                            <span><ManagementIcon /></span>
                        </Tooltip>
                    ) : (
                        <ManagementIcon />
                    )
                }
                open={openSubMenu === "ProductSettings"}
                onOpenChange={() => handleSubMenuToggle("ProductSettings")}
                className="user-management-menu"
            >
                {/* Club Types */}
                <Link to={CLUB_TYPE_LIST}>
                    <MenuItem
                        active={[CLUB_TYPE_LIST, ADD_CLUB_TYPE].includes(location?.pathname)}
                    >
                        Club Types
                    </MenuItem>
                </Link>
                {/* Clubs Facilities */}
                <Link to={CLUBS_FACILITIES}>
                    <MenuItem
                        active={[CLUBS_FACILITIES, CREATE_CLUBS_FACILITY].includes(location?.pathname)}
                    >
                        Club Facilities
                    </MenuItem>
                </Link>
                {/* Facility Category */}
                <Link to={FACILITIES_CATEGORY}>
                    <MenuItem
                        active={[FACILITIES_CATEGORY, CREATE_FACILITIES_CATEGORY].includes(location?.pathname)}
                    >
                        Facility Category
                    </MenuItem>
                </Link>
                {/* Interests */}
                <Link to={INTERESTS}>
                    <MenuItem
                        active={[INTERESTS, CREATE_INTERESTS, PLACES, CREATE_PLACES].includes(location?.pathname)}
                    >
                        Interests
                    </MenuItem>
                </Link>
            </SubMenu>
            {/* Push Notifications */}
            <Link to={PUSH_NOTIFICATIONS}>
                <MenuItem
                    icon={
                        minimizeSidebar ? (
                            <Tooltip title="Push Notifications" placement="right">
                                <span><BroadcastIcon /></span>
                            </Tooltip>
                        ) : (
                            <BroadcastIcon />
                        )
                    }
                    active={location?.pathname === PUSH_NOTIFICATIONS || (location?.pathname === CREATE_PUSH_NOTIFICATIONS)}
                >
                    {!minimizeSidebar && "Push Notifications"}
                </MenuItem>
            </Link>
            {/*  Manage Admins */}
            <Link to={USERS}>
                <MenuItem
                    icon={
                        minimizeSidebar ? (
                            <Tooltip title="Manage Admins" placement="right">
                                <span><ManagementIcon /></span>
                            </Tooltip>
                        ) : (
                            <ManagementIcon />
                        )
                    }
                    active={[USERS, ADD_USER, `${EDIT_USER}/:id`].includes(location?.pathname)}
                >
                    {!minimizeSidebar && "Manage Admins"}
                </MenuItem>
            </Link>
            {/* <SubMenu
                label="Manage Admins"
                icon={<ManagementIcon />}                        
                open={openSubMenu === "ManageAdmins"}
                onOpenChange={() => handleSubMenuToggle("ManageAdmins")}
                className="user-management-menu"
            >
                <Link to={USERS}>
                    <MenuItem
                        active={[USERS, ADD_USER, `${EDIT_USER}/:id`].includes(location?.pathname)}
                        icon={<ManagementIcon />}   
                    >
                        Manage Admins
                    </MenuItem>
                </Link>
            </SubMenu>             */}
        </Menu>);
}

// PCN Super Admin Sidebar
const PcnSuperAdminSidebar = ({ location, navigate, showuserManagementSubMenu, setShowUserManagementSubMenu, permissionsList, userInfoObj, handleSubMenuToggle, openSubMenu, setOpenSubMenu,minimizeSidebar }) => {

    return (
        <Menu>
      {/* Dashboard */}
      <Link to={HOME}>
        <MenuItem
          icon={
            minimizeSidebar ? (
              <Tooltip title="Dashboard" placement="right">
                <span><DashboardIcon /></span>
              </Tooltip>
            ) : (
              <DashboardIcon />
            )
          }
          active={location?.pathname === HOME}
        >
          {!minimizeSidebar && "Dashboard"}
        </MenuItem>
      </Link>

      {/* Member Stats */}
      <Link to={MEMBER_STATS}>
        <MenuItem
          icon={
            minimizeSidebar ? (
              <Tooltip title="Member Stats" placement="right">
                <span><MemberStatsIcon /></span>
              </Tooltip>
            ) : (
              <MemberStatsIcon />
            )
          }
          active={location?.pathname === MEMBER_STATS}
        >
          {!minimizeSidebar && "Member Stats"}
        </MenuItem>
      </Link>

      {/* Manage Network Clubs */}
      <Link to={MANAGE_NETWORK_CLUBS}>
        <MenuItem
          icon={
            minimizeSidebar ? (
              <Tooltip title={checkTheme() ? 'Manage Network Clubs' : 'Manage Reciprocal Clubs'} placement="right">
                <span><ClubsInformationIcon /></span>
              </Tooltip>
            ) : (
              <ClubsInformationIcon />
            )
          }
          active={[MANAGE_NETWORK_CLUBS].includes(location?.pathname)}
        >
          {!minimizeSidebar && (checkTheme() ? 'Manage Network Clubs' : 'Manage Reciprocal Clubs')}
        </MenuItem>
      </Link>

      {/* Manage Members */}
      <Link to={MANAGE_MEMBERS}>
        <MenuItem
          icon={
            minimizeSidebar ? (
              <Tooltip title="Manage Members" placement="right">
                <span><MembersIcon /></span>
              </Tooltip>
            ) : (
              <MembersIcon />
            )
          }
          active={[MANAGE_MEMBERS].includes(location?.pathname) && !showuserManagementSubMenu}
        >
          {!minimizeSidebar && "Manage Members"}
        </MenuItem>
      </Link>

      {/* Member Testimonials */}
      <Link to={MEMBERREVIEWLIST}>
        <MenuItem
          icon={
            minimizeSidebar ? (
              <Tooltip title="Member Testimonials" placement="right">
                <span><ClubsFacilitiesIcon /></span>
              </Tooltip>
            ) : (
              <ClubsFacilitiesIcon />
            )
          }
          active={[MEMBERREVIEWLIST].includes(location?.pathname)}
        >
          {!minimizeSidebar && "Member Testimonials"}
        </MenuItem>
      </Link>

      {/* Club Onboarding */}
      <Link to={CLUB_ONBOARDING_LIST}>
        <MenuItem
          icon={
            minimizeSidebar ? (
              <Tooltip title="Club Onboarding" placement="right">
                <span><CurrentClubIcon /></span>
              </Tooltip>
            ) : (
              <CurrentClubIcon />
            )
          }
          active={[CLUB_ONBOARDING_LIST].includes(location?.pathname)}
        >
          {!minimizeSidebar && "Club Onboarding"}
        </MenuItem>
      </Link>

      {/* Club Types */}
      <Link to={CLUB_TYPE_LIST}>
        <MenuItem
          icon={
            minimizeSidebar ? (
              <Tooltip title="Club Types" placement="right">
                <span><CurrentClubIcon /></span>
              </Tooltip>
            ) : (
              <CurrentClubIcon />
            )
          }
          active={[CLUB_TYPE_LIST, ADD_CLUB_TYPE].includes(location?.pathname)}
        >
          {!minimizeSidebar && "Club Types"}
        </MenuItem>
      </Link>

      {/* Subscription Privileges */}
      <Link to={SUBSCRIPTION_PRIVILEGES}>
        <MenuItem
          icon={
            minimizeSidebar ? (
              <Tooltip title="Subscription Privileges" placement="right">
                <span><SolitaireIcon /></span>
              </Tooltip>
            ) : (
              <SolitaireIcon />
            )
          }
          active={[SUBSCRIPTION_PRIVILEGES].includes(location?.pathname)}
        >
          {!minimizeSidebar && "Subscription Privileges"}
        </MenuItem>
      </Link>

      {/* Clubs Accounts */}
      <Link to={CLUBS_INFORMATION}>
        <MenuItem
          icon={
            minimizeSidebar ? (
              <Tooltip title="Club Accounts" placement="right">
                <span><ClubsInformationIcon /></span>
              </Tooltip>
            ) : (
              <ClubsInformationIcon />
            )
          }
          active={[CLUBS_INFORMATION, CREATE_CLUBS_INFORMATION, CREATE_CLUB_PROFILE, VIEW_CLUBS_INFORMATION].includes(location?.pathname)}
        >
          {!minimizeSidebar && "Club Accounts"}
        </MenuItem>
      </Link>

      {/* Clubs Facilities */}
      <Link to={CLUBS_FACILITIES}>
        <MenuItem
          icon={
            minimizeSidebar ? (
              <Tooltip title="Club Facilities" placement="right">
                <span><ClubsFacilitiesIcon /></span>
              </Tooltip>
            ) : (
              <ClubsFacilitiesIcon />
            )
          }
          active={[CLUBS_FACILITIES, CREATE_CLUBS_FACILITY].includes(location?.pathname)}
        >
          {!minimizeSidebar && "Club Facilities"}
        </MenuItem>
      </Link>

      {/* Special Subscribers */}
      <Link to={SPECIAL_SUBSCRIBERS}>
        <MenuItem
          icon={
            minimizeSidebar ? (
              <Tooltip title="Special Subscribers" placement="right">
                <span><SpecialSubscribersIcon /></span>
              </Tooltip>
            ) : (
              <SpecialSubscribersIcon />
            )
          }
          active={[SPECIAL_SUBSCRIBERS, CREATE_SPECIAL_SUBSCRIBERS].includes(location?.pathname)}
        >
          {!minimizeSidebar && "Special Subscribers"}
        </MenuItem>
      </Link>
      <Link to={USER_SUBSCRIPTION_LIST}>
                <MenuItem
                    icon={
                        minimizeSidebar ? (
                            <Tooltip title="User Subscription" placement="right">
                                <span><Usersub /></span>
                            </Tooltip>
                        ) : (
                            <Usersub />
                        )
                    }
                    active={[USER_SUBSCRIPTION_LIST, USER_SUBSCRIPTION_LIST].includes(location?.pathname)}
                >
                    {!minimizeSidebar && "User Subscription"}
                </MenuItem>
            </Link>
      {/* Facility Category */}
      <Link to={FACILITIES_CATEGORY}>
        <MenuItem
          icon={
            minimizeSidebar ? (
              <Tooltip title="Facility Category" placement="right">
                <span><FaciiltyIcon /></span>
              </Tooltip>
            ) : (
              <FaciiltyIcon />
            )
          }
          active={[FACILITIES_CATEGORY, CREATE_FACILITIES_CATEGORY].includes(location?.pathname)}
        >
          {!minimizeSidebar && "Facility Category"}
        </MenuItem>
      </Link>

      {/* Bookings */}
      <SubMenu
        label={!minimizeSidebar && "Bookings"}
        icon={
          minimizeSidebar ? (
            <Tooltip title="Bookings" placement="right">
              <span><BookingsIcon /></span>
            </Tooltip>
          ) : (
            <BookingsIcon />
          )
        }
        open={openSubMenu === "Bookings"}
        onOpenChange={() => handleSubMenuToggle("Bookings")}
      >
        <Link to={BOOKINGS}>
          <MenuItem
            active={location?.pathname === BOOKINGS || (location?.pathname?.split('/').includes('view-bookings'))}
          >
            Member Requests and Facility Bookings
          </MenuItem>
        </Link>
      </SubMenu>

      {/* Blackout Dates */}
      <Link to={BLACKOUT_DATES}>
        <MenuItem
          icon={
            minimizeSidebar ? (
              <Tooltip title="Blackout Dates" placement="right">
                <span><BookingsIcon /></span>
              </Tooltip>
            ) : (
              <BookingsIcon />
            )
          }
          active={[BLACKOUT_DATES].includes(location?.pathname)}
        >
          {!minimizeSidebar && "Blackout Dates"}
        </MenuItem>
      </Link>

      {/* Interests */}
      <Link to={INTERESTS}>
        <MenuItem
          icon={
            minimizeSidebar ? (
              <Tooltip title="Interests" placement="right">
                <span><InterestsIcon /></span>
              </Tooltip>
            ) : (
              <InterestsIcon />
            )
          }
          active={[INTERESTS, CREATE_INTERESTS, PLACES, CREATE_PLACES].includes(location?.pathname)}
        >
          {!minimizeSidebar && "Interests"}
        </MenuItem>
      </Link>

      {/* Push Notifications */}
      <Link to={PUSH_NOTIFICATIONS}>
        <MenuItem
          icon={
            minimizeSidebar ? (
              <Tooltip title="Push Notifications" placement="right">
                <span><BroadcastIcon /></span>
              </Tooltip>
            ) : (
              <BroadcastIcon />
            )
          }
          active={location?.pathname === PUSH_NOTIFICATIONS || (location?.pathname === CREATE_PUSH_NOTIFICATIONS)}
        >
          {!minimizeSidebar && "Push Notifications"}
        </MenuItem>
      </Link>

      {/* Club Invitations */}
      <Link to={OFFER}>
        <MenuItem
          icon={
            minimizeSidebar ? (
              <Tooltip title="Club Invitations" placement="right">
                <span><OfferIcon /></span>
              </Tooltip>
            ) : (
              <OfferIcon />
            )
          }
          active={[OFFER].includes(location?.pathname)}
        >
          {!minimizeSidebar && "Club Invitations"}
        </MenuItem>
      </Link>

      {/* Manage Admins */}
      <Link to={USERS}>
        <MenuItem
          icon={
            minimizeSidebar ? (
              <Tooltip title="Manage Admins" placement="right">
                <span><ManagementIcon /></span>
              </Tooltip>
            ) : (
              <ManagementIcon />
            )
          }
          active={[USERS, ADD_USER, `${EDIT_USER}/:id`].includes(location?.pathname)}
        >
          {!minimizeSidebar && "Manage Admins"}
        </MenuItem>
      </Link>
    </Menu> );
}

// PCW Club Admin Sidebar
const PcwClubAdminSidebar = ({ location, navigate, showuserManagementSubMenu, setShowUserManagementSubMenu, permissionsList, userInfoObj, handleSubMenuToggle, openSubMenu, setOpenSubMenu }) => {

    return (
        <Menu>
            {/* Dashboard */}
            {/* {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Dashboard"))) && 
            <Link to={HOME}>
                <MenuItem
                    icon={<DashboardIcon />}
                    active={location?.pathname === HOME}

                >
                    Dashboard
                </MenuItem>
            </Link>} */}
            {/* My Club */}
            <SubMenu
                label="My Club"
                icon={<ClubsFacilitiesIcon />}
                open={openSubMenu === "My Club"}
                onOpenChange={() => handleSubMenuToggle("My Club")}
            >
                {/* Club Profile */}
                {(
                    userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Club Profile"))
                ) &&
                    <Link to={CLUB_PROFILE}>
                        <MenuItem
                            // icon={<MemberStatsIcon />}
                            active={[CLUB_PROFILE, CREATE_CLUB_PROFILE].includes(location?.pathname)}
                        >
                            Club Profile
                        </MenuItem>
                    </Link>}
                {/* Clubs Facilities */}
                {(
                    userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Club Facilities"))
                ) &&
                    <Link to={CLUBS_FACILITIES}>
                        <MenuItem
                            // icon={<ClubsFacilitiesIcon />}
                            active={[CLUBS_FACILITIES, CREATE_CLUBS_FACILITY].includes(location?.pathname)}
                        >
                            Club Facilities
                        </MenuItem>
                    </Link>}
                {/* Letter of Introduction */}
                {(
                    userInfoObj?.is_superuser || userInfoObj?.club_admin ||
                    (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Letter Of Introduction"))
                ) &&
                    <Link to={CREATE_LETTER_OF_INTRODUCTION}>
                        <MenuItem
                            // icon={<LetterIcon />}
                            active={[CREATE_LETTER_OF_INTRODUCTION].includes(location?.pathname)}
                        >
                            Letter of Introduction
                        </MenuItem>
                    </Link>
                }
                {/* Blackout Dates */}
                {(
                    userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Black Out Dates"))
                ) &&
                    <Link to={BLACKOUT_DATES}>
                        <MenuItem
                            active={[BLACKOUT_DATES].includes(location?.pathname)}
                        >
                            Blackout Dates
                        </MenuItem>
                    </Link>}
            </SubMenu>
            {/* Global Private Club Access/ Plantinum Club Access */}
            {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Global Club Access")))
                &&
                <SubMenu
                    label={`${checkTheme() ? 'Global Platinum Club Access' : 'Global Private Club Access'}`}
                    icon={<GPCAIcon />}
                    open={openSubMenu === `${checkTheme() ? 'Global Platinum Club Access' : 'Global Private Club Access'}`}
                    onOpenChange={() => handleSubMenuToggle(`${checkTheme() ? 'Global Platinum Club Access' : 'Global Private Club Access'}`)}
                >
                    {/* Overview */}
                    <Link to={GPCA_OVERVIEW}>
                        <MenuItem
                            // icon={<MemberStatsIcon />}
                            active={[GPCA_OVERVIEW].includes(location?.pathname)}
                        >
                            Overview
                        </MenuItem>
                    </Link>
                    {/* Manage Network Clubs */}
                    <Link to={MANAGE_NETWORK_CLUBS}>
                        <MenuItem
                            // icon={<ClubsInformationIcon />}
                            active={[MANAGE_NETWORK_CLUBS,].includes(location?.pathname)}
                        >
                            {checkTheme() ? 'Manage Network Clubs' : 'Manage Reciprocal Clubs'}
                        </MenuItem>
                    </Link>
                </SubMenu>}
            {/* Bookings */}
            {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Bookings"))) &&
                <SubMenu
                    label="Bookings"
                    icon={<BookingsIcon />}
                    open={openSubMenu === "Bookings"}
                    onOpenChange={() => handleSubMenuToggle("Bookings")}
                >
                    <Link to={BOOKINGS}>
                        <MenuItem
                            active={location?.pathname === BOOKINGS || (location?.pathname?.split('/').includes('view-bookings'))}
                        >
                            Member Requests and Facility Bookings
                        </MenuItem>
                    </Link>
                    <Link to={RECIPROCAL_REQUESTS}>
                        <MenuItem
                            active={location?.pathname === RECIPROCAL_REQUESTS || (location?.pathname?.split('/').includes('view-reciprocal-requests'))}
                            onClick={() => navigate(RECIPROCAL_REQUESTS)}
                        >
                            Reciprocal Requests for Moderation
                        </MenuItem>
                    </Link>
                    {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Member Reviews"))) &&
                        <Link to={MEMBERREVIEWLIST}>
                            <MenuItem
                                active={[MEMBERREVIEWLIST].includes(location?.pathname)}
                            >
                                Club Rating
                            </MenuItem>
                        </Link>}
                </SubMenu>}
            {/* Manage Members */}
            {(
                userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Manage Member"))
            ) && <Link to={MANAGE_MEMBERS}>
                    <MenuItem
                        icon={<MembersIcon />}
                        active={[MANAGE_MEMBERS].includes(location?.pathname) && !showuserManagementSubMenu}
                    >
                        Manage Members
                    </MenuItem>
                </Link>}
            {/*  Manage Admins */}
            {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Manage Admins"))) &&
                <Link to={USERS}>
                    <MenuItem
                        active={[USERS, ADD_USER, `${EDIT_USER}/:id`].includes(location?.pathname)}
                        icon={<ManagementIcon />}
                    >
                        Manage Admins
                    </MenuItem>
                </Link>}
            {/* Curated Member Events */}
            {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Curated Member Events"))) &&
                <SubMenu
                    label={<div>Curated Member Events <span className={`optional-menu curated`}>Optional</span></div>}
                    icon={<CuratedIcon />}
                    className="cme-submenu"
                    open={openSubMenu === "Curated Member Events"}
                    onOpenChange={() => handleSubMenuToggle("Curated Member Events")}
                >
                    {/* Clubs Facilities */}
                    {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Club Facilities"))) &&
                        <Link to={CLUBS_FACILITIES}>
                            <MenuItem
                                // icon={<ClubsFacilitiesIcon />}
                                active={[CLUBS_FACILITIES, CREATE_CLUBS_FACILITY].includes(location?.pathname)}
                            >
                                Club Facilities
                            </MenuItem>
                        </Link>}
                    {/* Curation Profile */}
                    <Link to={CURATION_PROFILE}>
                        <MenuItem
                            // icon={<ClubsInformationIcon />}
                            active={[CURATION_PROFILE].includes(location?.pathname)}
                        >
                            Curation Profile
                        </MenuItem>
                    </Link>
                </SubMenu>
            }
            {/* Current Club Events */}
            {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Current Club Events"))) &&
                <SubMenu
                    label={<div>Current Club Events <span className="optional-menu">Optional</span></div>}
                    icon={<CurrentClubIcon />}
                    className="cce-submenu"
                    open={openSubMenu === "Current Club Events"}
                    onOpenChange={() => handleSubMenuToggle("Current Club Events")}
                >
                    {/* Club Events */}
                    <Link to={CURRENT_CLUB_EVENTS}>
                        <MenuItem
                            // icon={<ClubsFacilitiesIcon />}
                            active={[CURRENT_CLUB_EVENTS].includes(location?.pathname)}
                        >
                            Club Events
                        </MenuItem>
                    </Link>
                    {/* Curation Profile */}
                    <Link to={CLUB_EVENTS_PROFILE}>
                        <MenuItem
                            // icon={<ClubsInformationIcon />}
                            active={[CLUB_EVENTS_PROFILE].includes(location?.pathname)}
                        >
                            Club Events Profile
                        </MenuItem>
                    </Link>
                </SubMenu>
            }
            {/* Private Events */}
            {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Private Events"))) &&
                <SubMenu
                    label={<div>Private Events <span className="optional-menu">Optional</span></div>}
                    icon={<PrivateIcon />}
                    open={openSubMenu === "Private Events"}
                    onOpenChange={() => handleSubMenuToggle("Private Events")}
                >
                    {/* Clubs Facilities */}
                    {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Club Facilities"))) &&
                        <Link to={CLUBS_FACILITIES}>
                            <MenuItem
                                // icon={<ClubsFacilitiesIcon />}
                                active={[CLUBS_FACILITIES, CREATE_CLUBS_FACILITY].includes(location?.pathname)}
                            >
                                Club Facilities
                            </MenuItem>
                        </Link>}
                    {/* Profile For Private Events */}
                    <Link to={PRIVATE_EVENTS_PROFILE}>
                        <MenuItem
                            // icon={<ClubsInformationIcon />}
                            active={[PRIVATE_EVENTS_PROFILE].includes(location?.pathname)}
                        >
                            Profile For Private Events
                        </MenuItem>
                    </Link>
                </SubMenu>
            }
            {/* Promotions */}
            {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Club Promotions"))) &&
                <Link to={PROMOTIONS}>
                    <MenuItem
                        icon={<ClubsFacilitiesIcon />}
                        active={[PROMOTIONS].includes(location?.pathname)}
                        style={{ paddingBottom: "10px" }}
                    >
                        <div>Promotions <span className="optional-menu promotion-optional">Optional</span></div>
                    </MenuItem>
                </Link>
            }
        </Menu>);
}

// PCN Club Admin Sidebar
const PcnClubAdminSidebar = ({ location, navigate, showuserManagementSubMenu, setShowUserManagementSubMenu, permissionsList, userInfoObj, handleSubMenuToggle, openSubMenu, setOpenSubMenu }) => {

    return (
        <Menu>
            {/* Dashboard */}
            {/* {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Dashboard"))) && 
            <Link to={HOME}>
                <MenuItem
                    icon={<DashboardIcon />}
                    active={location?.pathname === HOME}

                >
                    Dashboard
                </MenuItem>
            </Link>} */}
            {/* My Club */}
            {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && (permissionsList?.includes("Club Profile") || permissionsList?.includes("Club Facilities") || permissionsList?.includes("Letter Of Introduction")))) &&
                <SubMenu
                    label="My Club"
                    icon={<ClubsFacilitiesIcon />}
                    open={openSubMenu === "My Club"}
                    onOpenChange={() => handleSubMenuToggle("My Club")}
                >
                    {/* Club Profile */}
                    {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Club Profile"))) &&
                        <Link to={CLUB_PROFILE}>
                            <MenuItem
                                // icon={<MemberStatsIcon />}
                                active={[CLUB_PROFILE, CREATE_CLUB_PROFILE].includes(location?.pathname)}
                            >
                                Club Profile
                            </MenuItem>
                        </Link>}
                    {/* Clubs Facilities */}
                    {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Club Facilities"))) &&
                        <Link to={CLUBS_FACILITIES}>
                            <MenuItem
                                // icon={<ClubsFacilitiesIcon />}
                                active={[CLUBS_FACILITIES, CREATE_CLUBS_FACILITY].includes(location?.pathname)}
                            >
                                Club Facilities
                            </MenuItem>
                        </Link>}
                    {/* Letter of Introduction */}
                    {(
                        userInfoObj?.is_superuser || userInfoObj?.club_admin ||
                        (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Letter Of Introduction"))
                    ) &&
                        <Link to={CREATE_LETTER_OF_INTRODUCTION}>
                            <MenuItem
                                // icon={<LetterIcon />}
                                active={[CREATE_LETTER_OF_INTRODUCTION].includes(location?.pathname)}
                            >
                                Letter of Introduction
                            </MenuItem>
                        </Link>
                    }
                </SubMenu>}
            {/* Global Private Club Access/ Plantinum Club Access */}
            {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Global Club Access")))
                &&
                <SubMenu
                    label={`${checkTheme() ? 'Global Platinum Club Access' : 'Global Private Club Access'}`}
                    icon={<GPCAIcon />}
                    open={openSubMenu === `${checkTheme() ? 'Global Platinum Club Access' : 'Global Private Club Access'}`}
                    onOpenChange={() => handleSubMenuToggle(`${checkTheme() ? 'Global Platinum Club Access' : 'Global Private Club Access'}`)}
                >
                    {/* Overview */}
                    <Link to={GPCA_OVERVIEW}>
                        <MenuItem
                            // icon={<MemberStatsIcon />}
                            active={[GPCA_OVERVIEW].includes(location?.pathname)}
                        >
                            Overview
                        </MenuItem>
                    </Link>
                    {/* Manage Network Clubs */}
                    <Link to={MANAGE_NETWORK_CLUBS}>
                        <MenuItem
                            // icon={<ClubsInformationIcon />}
                            active={[MANAGE_NETWORK_CLUBS,].includes(location?.pathname)}
                        >
                            {checkTheme() ? 'Manage Network Clubs' : 'Manage Reciprocal Clubs'}
                        </MenuItem>
                    </Link>
                </SubMenu>}
            {/* Curated Member Events */}
            {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Curated Member Events"))) &&
                <SubMenu
                    label={<div>Curated Member Events <span className={`optional-menu curated`}>Optional</span></div>}
                    icon={<CuratedIcon />}
                    className="cme-submenu"
                    open={openSubMenu === "Curated Member Events"}
                    onOpenChange={() => handleSubMenuToggle("Curated Member Events")}
                >
                    {/* Clubs Facilities */}
                    {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Club Facilities"))) &&
                        <Link to={CLUBS_FACILITIES}>
                            <MenuItem
                                // icon={<ClubsFacilitiesIcon />}
                                active={[CLUBS_FACILITIES, CREATE_CLUBS_FACILITY].includes(location?.pathname)}
                            >
                                Club Facilities
                            </MenuItem>
                        </Link>}
                    {/* Curation Profile */}
                    <Link to={CURATION_PROFILE}>
                        <MenuItem
                            // icon={<ClubsInformationIcon />}
                            active={[CURATION_PROFILE].includes(location?.pathname)}
                        >
                            Curation Profile
                        </MenuItem>
                    </Link>
                </SubMenu>
            }
            {/* Current Club Events */}
            {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Current Club Events"))) &&
                <SubMenu
                    label={<div>Current Club Events <span className="optional-menu">Optional</span></div>}
                    icon={<CurrentClubIcon />}
                    className="cce-submenu"
                    open={openSubMenu === "Current Club Events"}
                    onOpenChange={() => handleSubMenuToggle("Current Club Events")}
                >
                    {/* Club Events */}
                    <Link to={CURRENT_CLUB_EVENTS}>
                        <MenuItem
                            // icon={<ClubsFacilitiesIcon />}
                            active={[CURRENT_CLUB_EVENTS].includes(location?.pathname)}
                        >
                            Club Events
                        </MenuItem>
                    </Link>
                    {/* Curation Profile */}
                    <Link to={CLUB_EVENTS_PROFILE}>
                        <MenuItem
                            // icon={<ClubsInformationIcon />}
                            active={[CLUB_EVENTS_PROFILE].includes(location?.pathname)}
                        >
                            Club Events Profile
                        </MenuItem>
                    </Link>
                </SubMenu>
            }
            {/* Private Events */}
            {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Private Events"))) &&
                <SubMenu
                    label={<div>Private Events <span className="optional-menu">Optional</span></div>}
                    icon={<PrivateIcon />}
                    open={openSubMenu === "Private Events"}
                    onOpenChange={() => handleSubMenuToggle("Private Events")}
                >
                    {/* Clubs Facilities */}
                    {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Club Facilities"))) &&
                        <Link to={CLUBS_FACILITIES}>
                            <MenuItem
                                // icon={<ClubsFacilitiesIcon />}
                                active={[CLUBS_FACILITIES, CREATE_CLUBS_FACILITY].includes(location?.pathname)}
                            >
                                Club Facilities
                            </MenuItem>
                        </Link>}
                    {/* Profile For Private Events */}
                    <Link to={PRIVATE_EVENTS_PROFILE}>
                        <MenuItem
                            // icon={<ClubsInformationIcon />}
                            active={[PRIVATE_EVENTS_PROFILE].includes(location?.pathname)}
                        >
                            Profile For Private Events
                        </MenuItem>
                    </Link>
                </SubMenu>
            }
            {/* Promotions */}
            {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Club Promotions"))) &&
                <Link to={PROMOTIONS}>
                    <MenuItem
                        icon={<ClubsFacilitiesIcon />}
                        active={[PROMOTIONS].includes(location?.pathname)}
                        style={{ paddingBottom: "10px" }}
                    >
                        <div>Promotions <span className="optional-menu promotion-optional">Optional</span></div>
                    </MenuItem>
                </Link>
            }
            {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Member Reviews"))) &&
                <Link to={MEMBERREVIEWLIST}>
                    <MenuItem
                        icon={<ClubsFacilitiesIcon />}
                        active={[MEMBERREVIEWLIST].includes(location?.pathname)}
                    >
                        Club Rating
                    </MenuItem>
                </Link>}
            {/* Bookings */}
            {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Bookings"))) &&
                <SubMenu
                    label="Bookings"
                    icon={<BookingsIcon />}
                    open={openSubMenu === "Bookings"}
                    onOpenChange={() => handleSubMenuToggle("Bookings")}
                >
                    <Link to={BOOKINGS}>
                        <MenuItem
                            active={location?.pathname === BOOKINGS || (location?.pathname?.split('/').includes('view-bookings'))}
                        >
                            Member Requests and Facility Bookings
                        </MenuItem>
                    </Link>
                </SubMenu>}
            {/* Blackout Dates */}
            {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Black Out Dates")))
                &&
                <Link to={BLACKOUT_DATES}>
                    <MenuItem
                        icon={<BookingsIcon />}
                        active={[BLACKOUT_DATES].includes(location?.pathname)}
                    >
                        Blackout Dates
                    </MenuItem>
                </Link>}

            {/* Manage Members */}
            {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Manage Member"))) &&
                <Link to={MANAGE_MEMBERS}>
                    <MenuItem
                        icon={<MembersIcon />}
                        active={[MANAGE_MEMBERS].includes(location?.pathname) && !showuserManagementSubMenu}
                    >
                        Manage Members
                    </MenuItem>
                </Link>}
            {/*  Manage Admins */}
            {(userInfoObj?.club_admin || (userInfoObj?.is_staff && permissionsList && permissionsList?.includes("Manage Admins"))) &&
                <Link to={USERS}>
                    <MenuItem
                        active={[USERS, ADD_USER, `${EDIT_USER}/:id`].includes(location?.pathname)}
                        icon={<ManagementIcon />}
                    >
                        Manage Admins
                    </MenuItem>
                </Link>}
        </Menu>);
}


const ProSidebar = ({ minimizeSidebar }) => {
    let location = useLocation();
    let navigate = useNavigate();

    const [showuserManagementSubMenu, setShowUserManagementSubMenu] = useState(false);
    // Permissions and User Information
    const [permissionsList, setPermissionsList] = useState([]);
    const [userInfoObj, setUserInfoObj] = useState({});

    const [openSubMenu, setOpenSubMenu] = useState(null);

    const handleSubMenuToggle = (menu) => {
        setOpenSubMenu((prev) => (prev === menu ? null : menu));
    };

    // Setting permission list
    useEffect(() => {
        let userInfo = JSON.parse(localStorage.getItem("user_info"));

        if (userInfo) {
            setUserInfoObj(userInfo);
        }

        if (userInfo && userInfo?.permissions && userInfo?.permissions?.length) {
            setPermissionsList(userInfo?.permissions);
        }

    }, []);

    // Show/Hide the submenu options
    useEffect(() => {

        if ([USER_MANAGEMENT, ADD_ROLE, `${EDIT_ROLE}/:id`,
            GROUPS, ADD_GROUP, `${EDIT_GROUP}/:id`,
            USERS, ADD_USER, `${EDIT_USER}/:id`].includes(location?.pathname)
        ) {
            setShowUserManagementSubMenu(true);
        } else {
            setShowUserManagementSubMenu(false);
        }

    }, [location]);

    return (

        <Sidebar
            collapsed={minimizeSidebar}
            className="sidebar-main"
        >
            {!checkTheme()
                ?
                userInfoObj?.is_superuser ?
                    <PcwSuperAdminSidebar
                        location={location}
                        navigate={navigate}
                        showuserManagementSubMenu={showuserManagementSubMenu}
                        setShowUserManagementSubMenu={setShowUserManagementSubMenu}
                        permissionsList={permissionsList}
                        userInfoObj={userInfoObj}
                        handleSubMenuToggle={handleSubMenuToggle}
                        openSubMenu={openSubMenu}
                        minimizeSidebar={minimizeSidebar}
                        setOpenSubMenu={setOpenSubMenu}
                    />
                    : <PcwClubAdminSidebar
                        location={location}
                        navigate={navigate}
                        showuserManagementSubMenu={showuserManagementSubMenu}
                        setShowUserManagementSubMenu={setShowUserManagementSubMenu}
                        permissionsList={permissionsList}
                        userInfoObj={userInfoObj}
                        minimizeSidebar={minimizeSidebar}
                        handleSubMenuToggle={handleSubMenuToggle}
                        openSubMenu={openSubMenu}
                        setOpenSubMenu={setOpenSubMenu}
                    />
                :
                userInfoObj?.is_superuser ?
                    <PcnSuperAdminSidebar
                        location={location}
                        navigate={navigate}
                        showuserManagementSubMenu={showuserManagementSubMenu}
                        setShowUserManagementSubMenu={setShowUserManagementSubMenu}
                        permissionsList={permissionsList}
                        userInfoObj={userInfoObj}
                        minimizeSidebar={minimizeSidebar}
                        handleSubMenuToggle={handleSubMenuToggle}
                        openSubMenu={openSubMenu}
                        setOpenSubMenu={setOpenSubMenu}
                    />
                    : <PcnClubAdminSidebar
                        location={location}
                        navigate={navigate}
                        showuserManagementSubMenu={showuserManagementSubMenu}
                        setShowUserManagementSubMenu={setShowUserManagementSubMenu}
                        permissionsList={permissionsList}
                        userInfoObj={userInfoObj}
                        minimizeSidebar={minimizeSidebar}
                        handleSubMenuToggle={handleSubMenuToggle}
                        openSubMenu={openSubMenu}
                        setOpenSubMenu={setOpenSubMenu}
                    />
            }
        </Sidebar>

    );
};

export default ProSidebar;