import React, { useEffect, useState } from "react";
import { ReactComponent as AddIcon } from "../../assets/images/common/add-icon.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { FaTimes } from 'react-icons/fa';
import { IoIosClose } from "react-icons/io";
// Datepicker
import { DateObject } from "react-multi-date-picker";
import AsyncSelect from "react-select/async";
import { ReactComponent as EditIcon } from "../../assets/images/common/edit-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/common/close-icon.svg";
import DataTable from "react-data-table-component";

// Mui
import { styled, Switch } from "@mui/material";
// Formik
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment/moment";
import toast from "react-hot-toast";
import { listClubInformation } from "../../api/actions/clubProfile";
import { createBlackoutDates, deleteBlackoutDates, getBlackoutDateDetail, listBlackoutDates, updateBlackoutDateStatus } from "../../api/actions/blackoutDates";
import { paginationRowsPerPageOptions } from "../../helpers/helper";
import BulkUpdateAlert from "../../components/SweetAlert/BulkUpdateAlert";
import SweetAlertModal from "../../components/SweetAlert/SweetAlertModal";
import Select from 'react-select';
import { listAllClubs } from '../../api/actions/club';
// DatePicker
import { DatePicker, Space, Radio, Tabs } from 'antd';
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const BlackoutDates = () => {
  const [clubOptions, setClubOptions] = useState([]);

  const [show, setShow] = useState(false);
  const [dataList, setDataList] = useState([]);
  // User Information
  const [userInfoObj, setUserInfoObj] = useState({});
  const [permissionsList, setPermissionsList] = useState([]);
  // Sweet alert for status update
  const [isBulkSwal, setIsBulkSwal] = useState({
    show: false,
    id: '',
    status: '' // status
  });
  // Sweet alert for delete
  const [showSweetAlert, setShowSweetAlert] = useState({ id: '', show: false });

  // Loading and Update
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(false);

  // Blackout detail id
  const [blackoutDetailId, setBlackoutDetailId] = useState("");

  // Search
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [search, setSearch] = useState('');
  // User information
  const [userInfo, setUserInfo] = useState(null);

  // Date Range
  const [selectedRange, setSelectedRange] = useState(null);

  const [frequency, setFrequency] = useState('one_time');
  const [selectedDates, setSelectedDates] = useState({ start_date: null, end_date: null, date: null });
  const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState([]);
  const [selectedDaysOfMonth, setSelectedDaysOfMonth] = useState([]);  

  // Setting permission list
  useEffect(() => {
    let userInfoObj = JSON.parse(localStorage.getItem("user_info"));
    setUserInfo(userInfoObj);
  }, []);
  // Handle search
  const handleSearch = (value) => {
    setSearch(value);
  }
  // Clear search
  const clearSearch = () => {
    setSearch('');
    setDebouncedSearch('');
    setUpdate(!update);
  };

  useEffect(() => {
    if (search.length >= 2) {
      const debounceTimer = setTimeout(() => {
        setDebouncedSearch(search);
      }, 500);

      return () => clearTimeout(debounceTimer);
    } else {
      setDebouncedSearch('');
    }
  }, [search]);

  const handleClose = () => {
    setShow(false);
    formik.handleReset();
    setFrequency('one_time'); // Reset frequency on close //
    setSelectedDates({ start_date: null, end_date: null, date: null }); // Reset dates on close //
    setSelectedDaysOfWeek([]); // Reset days of week on close //
    setSelectedDaysOfMonth([]); // Reset days of month on close //
    if (blackoutDetailId) {
      setBlackoutDetailId("")
    }
  };
  const handleShow = () => {
    setShow(true);
    if (!userInfoObj?.is_superuser) {
      formik.setFieldValue('club', userInfoObj?.club_details?.[0]?.club_id || "");
      formik.setFieldValue('country', userInfoObj?.club_details?.[0]?.country || "");
    }
  };

  const formik = useFormik({
    initialValues: {
      club: !userInfoObj?.is_superuser ? userInfoObj?.club_details?.[0]?.club_id || "" : "",
      club_option: "",
      country: !userInfoObj?.is_superuser ? userInfoObj?.club_details?.[0]?.country || "" : "",
      title: "",
      dates: [],
      status: "Active",
      // Pattern
      recurrence_pattern: {
        recurrence_type: 'one_time',
        days_of_month: [],
        days_of_week: [],
        date: null,
        start_date: null,
        end_date: null,
      },
    },
    validationSchema: Yup.object({
      club: Yup.string().required("Club is Required"),
      title: Yup.string().required("Title is Required"),
    }),
    onSubmit: (values) => {
      let updatedValues = { ...values };

      if (frequency === 'one_time') {
        updatedValues.recurrence_pattern = {
          ...updatedValues.recurrence_pattern,
          recurrence_type: 'one_time',
          date: selectedDates.date || "",
          start_date: null,
          end_date: null,
          days_of_week: [],
          days_of_month: [],
        };
      } else {
        updatedValues.recurrence_pattern = {
          ...updatedValues.recurrence_pattern,
          recurrence_type: frequency,
          start_date: selectedDates.start_date,
          end_date: selectedDates.end_date,
          days_of_week: selectedDaysOfWeek,
          days_of_month: selectedDaysOfMonth,
          date: null,
        };
      }

      if (blackoutDetailId) {
        updatedValues.id = blackoutDetailId;
      }
      if (!updatedValues.club) {
        updatedValues.club = userInfoObj?.club_details?.[0]?.club_id || "";
        updatedValues.country = userInfoObj?.club_details?.[0]?.country || "";
      }


      if (updatedValues?.recurrence_pattern?.recurrence_type === "one_time" && !updatedValues?.recurrence_pattern?.date) {
        toast.error("Please select date", {
          position: "top-center",
          duration: 3000,
        });
        return;
      }
      if (updatedValues?.recurrence_pattern?.recurrence_type !== "one_time" &&
        (!updatedValues?.recurrence_pattern?.start_date || !updatedValues?.recurrence_pattern?.end_date)) {
        toast.error("Please select date range", {
          position: "top-center",
          duration: 3000,
        });
        return;
      }
      if (updatedValues?.recurrence_pattern?.recurrence_type === "weekly" &&
        Array.isArray(updatedValues?.recurrence_pattern?.days_of_week) && !updatedValues?.recurrence_pattern?.days_of_week?.length) {
        toast.error("Please select days of week", {
          position: "top-center",
          duration: 3000,
        });
        return;
      }
      if (updatedValues?.recurrence_pattern?.recurrence_type === "monthly" &&
        Array.isArray(updatedValues?.recurrence_pattern?.days_of_month) && !updatedValues?.recurrence_pattern?.days_of_month?.length) {
        toast.error("Please select days of month", {
          position: "top-center",
          duration: 3000,
        });
        return;
      }

      setLoading(true);

      createBlackoutDates({ data: updatedValues })((response) => {
        if (response?.status && (response?.status_code === 200 || response?.status_code === 201)) {
          toast.success("Blackout Dates Added Successfully");
        } else {
          toast.error("Failed to Create Blackout Dates");
        }
        formik.handleReset();
        setLoading(false);
        setUpdate(!update);
        setShow(false);
        if (blackoutDetailId) {
          setBlackoutDetailId("");
        }

      });

    },
  });
  console.log(formik.errors);

  // Handle Date Change
  const onChangeDate = (dates, dateStrings) => {
    if (dates) {
      setSelectedRange({
        start_date: moment(dateStrings[0], "MM/DD/YYYY").format("YYYY-MM-DD"),
        end_date: moment(dateStrings[1], "MM/DD/YYYY").format("YYYY-MM-DD"),
        display_start: dateStrings[0], // MM/DD/YYYY format for display
        display_end: dateStrings[1],
      });
    }
  };

  // Club options 
  useEffect(() => {
    // List clubs
    listAllClubs({
      limit,
      filter: { status_club: "True" },
    })((response) => {
      if (response && response.status) {
        if (Array.isArray(response?.data?.results)) {
          let clubArr = response.data?.results?.map((item) => {
            return {
              label: item.club_name,
              value: item.club,
              country: item.country,
            };
          });
          setClubOptions(clubArr);
        }
      }
    });

  }, []);

  // Blackout dates
  useEffect(() => {
    if (debouncedSearch) {
      setPage(1);
    }
    setPending(true);
    listBlackoutDates({ limit, page: debouncedSearch ? 1 : page, search: debouncedSearch })((response) => {
      if (response && response?.status) {
        setDataList(response?.data?.results);
        setTotalRows(response?.data?.count);
      }
      setPending(false);
    })
  }, [update, debouncedSearch, page, limit]);

  // Function to fetch club options based on input
  let typingTimer;
  const loadOptionsClub = (inputValue) => {
    return new Promise((resolve) => {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        if (inputValue.length >= 3) {
          listAllClubs({
            page: 1,
            limit: 10,
            search: inputValue,
            filter: { status_club: "True" },
          })((response) => {
            if (response?.status_code === 200) {
              const options = response?.data?.results || [];
              let clubArr = options?.map((item) => {
                return {
                  label: item.club_name,
                  value: item.club,
                  country: item.country,
                };
              });
              setClubOptions(clubArr);
              resolve(clubArr);
            } else {
              resolve([]);
            }
          });
        } else {
          resolve([]);
        }
      }, 300);
    });
  };
  // Setting permission list
  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem("user_info"));

    if (userInfo) {
      setUserInfoObj(userInfo);
    }
    if (userInfo && userInfo?.permissions && userInfo?.permissions?.length) {
      setPermissionsList(userInfo?.permissions);
    }

    if (userInfo && !userInfo?.is_superuser) {
      formik.setFieldValue("club", userInfo?.club_details?.[0]?.club_id);
      formik.setFieldValue("country", userInfo?.club_details?.[0]?.country);
    }
  }, []);

  const handleChange = (e, name, selected_name) => {
    if (e) {
      formik.setValues({
        ...formik.values,
        [name]: e.value,
        [selected_name]: e,
        country: e.country,
      });
    } else {
      formik.setValues({
        [name]: null,
        [selected_name]: [],
        country: null,
      });
    }
  };


  const CustomSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 39,
    height: 20,
    padding: 0,
    marginLeft: 5,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      // margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: 'var(--sandy-brown)',
          opacity: 1,
          border: 0,
          ...theme.applyStyles('dark', {
            backgroundColor: '#2ECA45',
          }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        ...theme.applyStyles('dark', {
          color: theme.palette.grey[600],
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: 'var(--sandy-brown)',
        opacity: 0.7,
        ...theme.applyStyles('dark', {
          opacity: 0.3,
        }),
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 20,
      height: 20,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      ...theme.applyStyles('dark', {
        backgroundColor: '#39393D',
      }),
    },
  }));

  const formatDates = (dateRange, date, reccurenceType) => {
    let dateArr = [];

    if (date) {
      return moment(date).format("D MMMM YYYY");
    } else if (typeof dateRange === "object") {
      dateArr.push(dateRange);
    }
    if (!dateArr.length) {
      return '';
    }

    return dateArr?.map(range => {
      const startDate = moment(range.start_date).format("D MMMM YYYY");
      const endDate = moment(range.end_date).format("D MMMM YYYY");

      // If start and end dates are the same, show only one date
      return startDate === endDate ? startDate + (reccurenceType) : `${startDate} to ${endDate} (${reccurenceType})`;
    })
      .join(", ");
  }

  // Handle frequency change
  const handleFrequencyChange = (e) => {
    const value = e.target.value;
    setFrequency(value);
    setSelectedDates({ start_date: null, end_date: null, date: null });
    setSelectedDaysOfWeek([]);
    setSelectedDaysOfMonth([]);
  };

  // Handle single date change (One Time)
  const handleDateChange = (date, dateString) => {

    if (date) {
      setSelectedDates({
        start_date: null,
        end_date: null,
        date: moment(dateString, 'MM/DD/YYYY').format('YYYY-MM-DD')
      });
    } else {
      setSelectedDates({ start_date: null, end_date: null, date: null });
    }
  };

  // Handle range date change (Recurring: Daily, Weekly, Monthly)
  const handleRangeChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedDates({
        start_date: moment(dateStrings[0], 'MM/DD/YYYY').format('YYYY-MM-DD'),
        end_date: moment(dateStrings[1], 'MM/DD/YYYY').format('YYYY-MM-DD'),
        date: null
      });
    } else {
      setSelectedDates({ start_date: null, end_date: null, date: null });
    }
  };

  // Generate days of week options (1-7) with labels (Mon-Sun)
  const getDaysOfWeekOptions = () => {
    const days = [
      { value: 1, label: 'Mon' },
      { value: 2, label: 'Tues' },
      { value: 3, label: 'Wed' },
      { value: 4, label: 'Thu' },
      { value: 5, label: 'Fri' },
      { value: 6, label: 'Sat' },
      { value: 7, label: 'Sun' },
    ];
    return days;
  };

  // Generate days of month options based on selected date range
  const getDaysOfMonthOptions = () => {
    if (!selectedDates.start_date || !selectedDates.end_date) return [];

    const start = dayjs(selectedDates.start_date);
    const end = dayjs(selectedDates.end_date);
    const options = [];

    // If the range spans multiple months, show 1-31
    if (start.month() !== end.month() || start.year() !== end.year()) {
      for (let i = 1; i <= 31; i++) {
        options.push({ value: i, label: i.toString() });
      }
    } else {
      // If within the same month, show only days in the range
      const daysInMonth = start.daysInMonth();
      const startDay = start.date();
      const endDay = Math.min(end.date(), daysInMonth);

      for (let i = startDay; i <= endDay; i++) {
        options.push({ value: i, label: i.toString() });
      }
    }

    return options;
  };

  // Handle days of week selection
  const handleDaysOfWeekChange = (selectedOptions) => {
    const values = selectedOptions.map(option => option.value);
    setSelectedDaysOfWeek(values);
  };

  // Handle days of month selection
  const handleDaysOfMonthChange = (selectedOptions) => {
    const values = selectedOptions.map(option => option.value);
    setSelectedDaysOfMonth(values);
  };

  // Get blackout date detail
  const handleBlackoutDetail = (id) => {

    getBlackoutDateDetail(id)((response) => {

      if (response?.status && response?.status_code !== 400) {
        let detailObj = response?.data;

        // Extract recurrence pattern details //
        const recurrencePattern = detailObj?.recurrence_pattern || {}; //
        const recurrenceType = recurrencePattern?.type || 'one_time'; //
        const dateRange = recurrencePattern?.date_range || {}; //
        const singleDate = recurrencePattern?.date; //

        formik.setValues({
          club: detailObj?.club,
          club_option: { label: detailObj?.club_name, value: detailObj?.club },
          country: detailObj?.country,
          title: detailObj?.title,
          status: detailObj?.status, //
          recurrence_pattern: {
            recurrence_type: recurrenceType, //
            date: singleDate ? dayjs(singleDate).format('YYYY-MM-DD') : null, //
            start_date: dateRange.start_date ? dayjs(dateRange.start_date).format('YYYY-MM-DD') : null, //
            end_date: dateRange.end_date ? dayjs(dateRange.end_date).format('YYYY-MM-DD') : null, //
            days_of_week: recurrencePattern?.details?.weekly?.days || [], //
            days_of_month: recurrencePattern?.details?.monthly?.days || [], //
          },
        });
        // Update component state to reflect in UI //
        setFrequency(recurrenceType); //
        if (recurrenceType === 'one_time' && singleDate) {
          setSelectedDates({
            start_date: null, //
            end_date: null, //
            date: dayjs(singleDate).format('YYYY-MM-DD'), //
          });
        } else if (dateRange.start_date && dateRange.end_date) {
          setSelectedDates({
            start_date: dayjs(dateRange.start_date).format('YYYY-MM-DD'), //
            end_date: dayjs(dateRange.end_date).format('YYYY-MM-DD'), //
            date: null,
          });
        }
        setSelectedDaysOfWeek(recurrencePattern?.details?.weekly?.days || []); //
        setSelectedDaysOfMonth(recurrencePattern?.details?.monthly?.days || []);

        handleShow();
      }
    })
  }

  useEffect(() => {
    if (blackoutDetailId) {
      handleBlackoutDetail(blackoutDetailId);
    }
  }, [blackoutDetailId]);

  // Define columns for the table
  let columns = [
    {
      name: "Sl.No",
      selector: row => row.club,
      cell: (row, index) =>
        <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.name}>
          {(limit * (page - 1)) + (index + 1)}
        </div>,
      width: "70px"
    },
    { name: "Club Name", selector: row => row.club_name, width: "300px" },
    { name: "Title", selector: row => row.title },
    {
      name: "Date",
      selector: row => <div title={formatDates(row?.recurrence_pattern?.date_range, row?.recurrence_pattern?.date, row?.recurrence_pattern?.type)}>
        {formatDates(row?.recurrence_pattern?.date_range, row?.recurrence_pattern?.date, row?.recurrence_pattern?.type)}
      </div>,
      minWidth: "350px"
    },
    {
      name: "Status",
      selector: row =>
        <div
          style={{
            pointerEvents: 'auto'
          }}
        >
          <CustomSwitch
            defaultChecked={row.status === "Active" ? true : false}
            onChange={() => {
              setIsBulkSwal({
                show: true,
                id: row.id,
                status: row.status === "Active" ? false : true  // status
              });
            }}
          />
        </div>
      , width: "150px"
    },
    {
      name: "Actions",
      selector: row => (
        <div className="d-flex align-items-center gap-2">

          <div
            className="view-icon cursor-pointer"
            title="Edit"
            onClick={() => setBlackoutDetailId(row.id)}
          >
            <EditIcon />
          </div>

          <div className="view-icon remove-icon cursor-pointer"
            title="Delete"
            onClick={() => {
              setShowSweetAlert({
                show: true,
                id: row.id,
              });
            }}
          >
            <CloseIcon />
          </div>
        </div>
      )
    },
  ];

  // Eliminate club name for club admin
  if (!userInfo?.is_superuser) {
    columns = columns.filter((item) => item.name !== "Club Name");
  }
  // Custom styling for the table
  const customStyles = {
    tableWrapper: {
      style: {
        minHeight: "100%",
        maxHeight: "100%",
      },
    },
    table: {
      style: {
        borderRadius: "15px 15px 0px 0px",
        border: "1px solid var(--border-color)",
        overflow: "hidden",
        minHeight: "100%",
        maxHeight: "100%",
      },
    },
    headCells: {
      style: {
        backgroundColor: "var(--light-blue)",
        justifyContent: 'flex-start !important',
        color: "var(--dark-gray)",
        fontWeight: "400",
        fontSize: "15px",
      },
    },
    rows: {
      style: {
        height: "45px", // Set row height
        justifyContent: "start",
      },
    },
    cells: {
      style: {
        fontSize: '14px',
        justifyContent: 'flex-start !important',
        borderRight: "1px solid var(--border-color)",
      },
    },
  };

  // Update status
  const handleUpdateStatus = () => {
    let props = {
      "blackout_ids": [isBulkSwal?.id],
      "status": isBulkSwal?.status ? "Active" : "InActive"
    };

    updateBlackoutDateStatus(props)((response) => {
      if (response?.status && response?.status !== 400) {
        toast.success("Status updated successfully");
      } else {
        toast.error("Failed to update status");
      }
      setUpdate(!update);
      setIsBulkSwal({
        show: false,
        id: '',
        status: '' // status
      });
    })
  }

  // Handle delete
  const handleDelete = () => {
    deleteBlackoutDates(showSweetAlert?.id)((response) => {
      if (response?.status && response?.status_code === 200) {
        toast.success("Blackout date deleted successfully");
      } else {
        toast.error("Failed to delete blackout date");
      }
      setShowSweetAlert({ id: '', show: false });
      setUpdate(!update);
    })
  }
  // Function to get days of the week in the selected range
  const getAvailableDays = (start_date, end_date) => {
    let start = moment(start_date);
    let end = moment(end_date);
    let daysSet = new Set();

    while (start.isSameOrBefore(end)) {
      daysSet.add(start.isoWeekday()); // Get weekday (1 = Mon, 7 = Sun)
      start.add(1, "day"); // Move to the next day
    }

    return getDaysOfWeekOptions().filter((day) => daysSet.has(day.value));
  };
 
  return (
    <div className="member-stats-section">
      {/* Delete Alert */}
      <SweetAlertModal
        showSweetAlert={showSweetAlert?.show}
        setShowSweetAlert={(show) => {
          if (show) handleDelete();
          else setShowSweetAlert({
            show: false,
            id: '',
          });
        }}
      />
      {/* Update Status */}
      <BulkUpdateAlert
        showSweetBulkAlert={isBulkSwal.show}
        setShowBulkAlert={(show) => {
          if (show) handleUpdateStatus();
          else setIsBulkSwal({
            show: false,
            id: '',
            status: '' // status
          });
        }}
      />
      <Modal
        show={show}
        onHide={handleClose}
        className="add-blackout-dates-modal"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Blackout Dates</Modal.Title>
        </Modal.Header>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            {/* Club */}
            {userInfoObj && userInfoObj?.is_superuser && (
              <>
                {/* Club */}
                <div className="item" style={{ pointerEvents: `${blackoutDetailId ? "none" : "auto"}` }}>
                  <Form.Label>
                    Club<span className="required">*</span>
                  </Form.Label>
                  <Form.Group className="mb-3" controlId="club">
                    <AsyncSelect
                      loadOptions={loadOptionsClub}
                      defaultOptions={clubOptions || []}
                      classNamePrefix="select"
                      placeholder={"Select Club"}
                      onChange={(e) => handleChange(e, "club", "club_option")}
                      value={formik.values?.club_option}
                      styles={{
                        control: (styles, { isFocused }) => ({
                          ...styles,
                          maxHeight: "31px",
                          border:
                            formik.errors.club && formik.touched.club
                              ? "1px solid #dc3545"
                              : "1px solid var(--light-grayish)",
                          outline: "none",
                          boxShadow: isFocused
                            ? "0 0 0 1px var(--light-grayish)"
                            : "none",
                          color: "var(--medium-gray)",
                          fontSize: "15px",
                          "&:hover": {
                            border:
                              formik.errors.club && formik.touched.club
                                ? "1px solid #dc3545"
                                : "1px solid var(--light-grayish)",
                          },
                        }),
                        valueContainer: (styles) => ({
                          ...styles,
                          alignItems: "center",
                          padding: "3px 8px",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "var(--medium-gray)",
                          fontSize: "15px",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          border: "1px solid var(--light-grayish)",
                          boxShadow: "none",
                        }),
                        placeholder: (styles) => ({
                          ...styles,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          color: "var(--medium-gray)",
                          fontSize: "15px",
                        }),
                      }}
                    />
                    {formik?.touched?.club && formik?.errors?.club ? (
                      <div className="error-msg">{formik?.errors?.club}</div>
                    ) : null}
                  </Form.Group>
                </div>
              </>
            )}
            {/* Title */}
            <div className="mb-3">
              <Form.Group controlId="title">
                <Form.Label>
                  Title<span className="required">*</span>
                </Form.Label>
                <div className="title-box">
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    name="title"
                    value={formik?.values?.title}
                    onChange={formik.handleChange}
                    isInvalid={formik?.touched?.title && formik?.errors?.title}
                  />
                  {formik?.values?.title && <FaTimes className='clear-icon' onClick={() =>{ formik.setFieldValue('title' , '')}}/>}
                </div>
                {formik?.touched?.title && formik?.errors?.title && (
                  <Form.Control.Feedback type="invalid">
                    {formik?.errors?.title}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
            {/* Blackout Date Selection */}
            <div className="form-group mb-3">
              <div className="mb-2">Frequency<span className="required">*</span></div>
              <Radio.Group onChange={handleFrequencyChange} value={frequency}>
                <Radio value="one_time">One Time</Radio>
                <Radio value={["daily", "weekly", "monthly"].includes(frequency) ? frequency : "daily"}>Recurring</Radio>
              </Radio.Group>
            </div>

            {frequency === 'one_time' ? (
              <div className="form-group">
                <div className="mb-1">Date
                  <span className="required">
                    <sup>*</sup>
                  </span>
                </div>
                <DatePicker
                  format="MM/DD/YYYY"
                  onChange={(date, dateString) => {
                    handleDateChange(date, dateString);
                    formik.setFieldValue('recurrence_pattern.date', date ? moment(date).format('YYYY-MM-DD') : null);
                  }}
                  value={selectedDates.date ? dayjs(selectedDates.date, 'YYYY-MM-DD') : null} // Show selected date //
                  placeholder="Select Date"
                  style={{ width: "288px", height: "41px", borderRadius: "0px" }}
                  disabledDate={(current) => current && current < dayjs().startOf("day")}
                />
              </div>
            ) : (
              <div className="form-group">
                <Tabs defaultActiveKey={blackoutDetailId ? frequency : "daily"} onChange={(key) => setFrequency(key)}>
                  <TabPane tab="Daily" key="daily">
                    <div>
                      Select Date Range
                      <span className="required">
                        <sup>*</sup>
                      </span>
                    </div>
                    <RangePicker
                      format="MM/DD/YYYY"
                      onChange={(dates, dateStrings) => {
                        handleRangeChange(dates, dateStrings);
                        formik.setFieldValue('recurrence_pattern.start_date', selectedDates.start_date);
                        formik.setFieldValue('recurrence_pattern.end_date', selectedDates.end_date);
                      }}
                      value={
                        selectedDates.start_date && selectedDates.end_date
                          ? [
                            dayjs(selectedDates.start_date, 'YYYY-MM-DD'),
                            dayjs(selectedDates.end_date, 'YYYY-MM-DD'),
                          ]
                          : null
                      } // Show selected range // 
                      placeholder={["Start Date", "End Date"]}
                      disabledDate={(current) => current && current < dayjs().startOf("day")}
                    />
                  </TabPane>
                  <TabPane tab="Weekly" key="weekly">
                    <div>
                      Select Date Range
                      <span className="required">
                        <sup>*</sup>
                      </span>
                    </div>
                    <RangePicker
                      format="MM/DD/YYYY"
                      onChange={(dates, dateStrings) => {
                        handleRangeChange(dates, dateStrings);
                        formik.setFieldValue('recurrence_pattern.start_date', selectedDates.start_date);
                        formik.setFieldValue('recurrence_pattern.end_date', selectedDates.end_date);
                      }}
                      value={
                        selectedDates.start_date && selectedDates.end_date
                          ? [
                            dayjs(selectedDates.start_date, 'YYYY-MM-DD'),
                            dayjs(selectedDates.end_date, 'YYYY-MM-DD'),
                          ]
                          : null
                      } // Show selected range //
                      placeholder={["Start Date", "End Date"]}
                      disabledDate={(current) => current && current < dayjs().startOf("day")}
                    />
                    <div className="mt-3 mb-1">Days of Week <span className="required">
                      <sup>*</sup>
                    </span></div>
                    <Select
                      isMulti
                      // options={getDaysOfWeekOptions()}
                      options={getAvailableDays(selectedDates?.start_date, selectedDates?.end_date)}
                      onChange={handleDaysOfWeekChange}
                      value={getDaysOfWeekOptions().filter(option => selectedDaysOfWeek.includes(option.value))}
                      placeholder="Select days"
                      styles={{
                        control: (base) => ({
                          ...base,
                          width: "288px", // Set the width here
                          borderRadius: "0px"
                        }),
                        menu: (base) => ({
                          ...base,
                          width: "288px", // Optional: Set width for dropdown menu
                          borderRadius: "0px"
                        }),
                      }}
                    />
                  </TabPane>
                  <TabPane tab="Monthly" key="monthly">
                    <div className="mb-1">
                      Select Date Range
                      <span className="required">
                        <sup>*</sup>
                      </span>
                    </div>
                    <RangePicker
                      format="MM/DD/YYYY"
                      onChange={(dates, dateStrings) => {
                        handleRangeChange(dates, dateStrings);
                        formik.setFieldValue('recurrence_pattern.start_date', selectedDates.start_date);
                        formik.setFieldValue('recurrence_pattern.end_date', selectedDates.end_date);
                      }}
                      value={
                        selectedDates.start_date && selectedDates.end_date
                          ? [
                            dayjs(selectedDates.start_date, 'YYYY-MM-DD'),
                            dayjs(selectedDates.end_date, 'YYYY-MM-DD'),
                          ]
                          : null
                      } // Show selected range //
                      placeholder={["Start Date", "End Date"]}
                      disabledDate={(current) => current && current < dayjs().startOf("day")}
                    />

                    <div className="mt-3 mb-1">Date of Month
                      <span className="required">
                        <sup>*</sup>
                      </span>
                    </div>
                    <Select
                      isMulti
                      options={getDaysOfMonthOptions()}
                      onChange={handleDaysOfMonthChange}
                      value={getDaysOfMonthOptions().filter(option => selectedDaysOfMonth.includes(option.value))}
                      placeholder="Select dates"
                      styles={{
                        control: (base) => ({
                          ...base,
                          width: "288px", // Set the width here
                          borderRadius: "0px"
                        }),
                        menu: (base) => ({
                          ...base,
                          width: "288px", // Optional: Set width for dropdown menu
                          borderRadius: "0px"
                        }),
                      }}
                    />
                  </TabPane>
                </Tabs>
              </div>)}

          </Modal.Body>
          <Modal.Footer>
            <button className="btn-type-1" type="submit" disabled={loading}>
              Save
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <div className="page-details">
        <div className="top-section">
          <div className="d-flex align-items-center justify-content-between">
            <div className="left">Blackout Dates</div>
            <div className="right d-flex align-items-center  gap-3">
              <div className='search-box'>
                <input
                  type='text'
                  placeholder='Search'
                  className='form-control'
                  value={search}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                {
                  search && (
                    <FaTimes className='clear-icon' onClick={clearSearch} />
                  )}
              </div>
              
                <div
                  className="add-clubs-info cursor-pointer"
                  title="Create"
                  onClick={handleShow}
                >
                  <AddIcon />
                </div>
            </div>
          </div>
        </div>
        <div className='table-box mt-3'>
          <DataTable
            columns={columns}
            data={dataList}
            customStyles={customStyles}
            fixedHeader
            highlightOnHover
            responsive
            pagination
            paginationServer
            paginationPerPage={limit}
            progressPending={pending}
            onChangeRowsPerPage={setLimit}
            paginationTotalRows={totalRows}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            onChangePage={setPage}
          />
        </div>
      </div>
    </div>
  );
};

export default BlackoutDates;
