import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const Gallery = ({ formik }) => {
    const [imageFiles, setImageFiles] = useState([]);
    const [base64Images, setBase64Images] = useState([]);

    const imageToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': ['.jpeg', '.pjpeg', '.png']
        },
        multiple: true,
        onDrop: async (acceptedFiles) => {
            const updatedFiles = [
                ...imageFiles,
                ...acceptedFiles.map((file) => ({
                    file,
                    preview: URL.createObjectURL(file),
                })),
            ];

            const base64Promises = acceptedFiles.map(async (file) => {
                const base64 = await imageToBase64(file);
                return base64;
            });

            const base64Results = await Promise.all(base64Promises);

            setImageFiles(updatedFiles);
            setBase64Images([...base64Images, ...base64Results]);

            const combinedImages = [
                ...formik.values.club_images_details,
                ...base64Results.map((base64) => ({ image: base64 })),
            ];

            formik.setValues({
                ...formik.values,
                club_images_details: combinedImages,
            });
        },
    });

    const removeImage = (index) => {
        const updatedFiles = imageFiles.filter((_, i) => i !== index);
        const updatedBase64Images = base64Images.filter((_, i) => i !== index);

        setImageFiles(updatedFiles);
        setBase64Images(updatedBase64Images);

        const updatedFormikImages = formik.values.club_images_details.filter((_, i) => i !== index);
        formik.setValues({
            ...formik.values,
            club_images_details: updatedFormikImages,
        });
    };

    const styles = {
        preview: {
            position: "relative",
            minWidth: "100px",
            height: "100px",
            border: "1px solid #EAE9EB",
            borderRadius: "5px",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        image: {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        },
        removeButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
            position: "absolute",
            top: "5px",
            right: "5px",
            background: "#707070",
            border: "none",
            color: "#fff",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            cursor: "pointer",
            fontSize: "16px",
            lineHeight: "18px",
            textAlign: "center",
        },
    };

    return (
        <div className='gallery-section mt-5'>
            <div className='panel-type-1'>
                Club Images <span className='required'>*</span>
            </div>
            <div className='gallery-details mt-4'>
                <div className='multiple-images-upload'>
                    <div className='top'>
                        Multiple Images Upload
                    </div>
                    <div className='bottom'>
                        <div className='drag-box'>
                            <div {...getRootProps({ className: 'dropzone cursor-pointer' })}>
                                <input {...getInputProps()} />
                                <p>Drag & Drop your file or Browse</p>
                            </div>
                            <aside className='d-flex flex-wrap gap-2'>
                                {formik.values?.club_images_details?.map((image, index) => (
                                    <div key={index} style={styles.preview}>
                                        <img
                                            src={image.image}
                                            alt={`preview-${index}`}
                                            style={styles.image}
                                        />
                                        <button
                                            type='button'
                                            style={styles.removeButton}
                                            onClick={() => removeImage(index)}
                                        >
                                            &times;
                                        </button>
                                    </div>
                                ))}
                            </aside>
                        </div>
                    </div>
                </div>
                {/* Display the error message if validation fails */}
                {formik.touched.club_images_details && formik.errors.club_images_details && (
                    <div className='error-msg'>{formik.errors.club_images_details}</div>
                )}
                <div className='info-msg mt-2'>Maximum file size is 1 MB</div>
            </div>
        </div>
    );
};


export default Gallery;