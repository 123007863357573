import { CLUB_TYPE_STATUS_CHANGE, CREATE_CLUB_TYPE, DELETE_CLUB_TYPE, DETAILS_CLUB_TYPE, LIST_CLUB_TYPE} from "../api";
import axiosInstance from "../axiosinstance";

export const createClubtype= (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_CLUB_TYPE, props)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};
export const createClubtypeStatuschange= (props) => onResponse => {
    try {
        axiosInstance.post(CLUB_TYPE_STATUS_CHANGE, props)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};
export const listClubType = (props) => onResponse => {
    try {
        let BASE_URL = LIST_CLUB_TYPE + '?'
        if (props?.page) {
            BASE_URL += 'page=' + props.page + '&'
        }
        if (props?.location) {
            BASE_URL += 'location=' + props.location + '&'
        }
        if (props?.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props?.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
     
        if(props?.sort_by){
            BASE_URL+='sort_by='+props.sort_by+'&'
        }
        if(props?.order){
            BASE_URL+='order='+props.order+'&'
        }
       
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {
        console.log("Listing club facility error", error);
        
    }

};

export const deleteClubType = (id) => onResponse => {
    try {

        axiosInstance.delete(DELETE_CLUB_TYPE, { data: { id: [id] } })

            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getclubtypeDetails = (props) => onResponse => {
    try {
        axiosInstance.get(DETAILS_CLUB_TYPE + '?id=' + props.id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};


