
import React, { useEffect, useState } from 'react'
import DataTable from "react-data-table-component";
import { ReactComponent as FilterIcon } from "../../assets/images/common/filter-icon.svg";
// Drawer
import Drawer from '@mui/material/Drawer';
import { Form } from 'react-bootstrap';
// Rsuite Datepicker
import { DatePicker } from 'rsuite';
import { listMemberStats } from '../../api/actions/memberstats';
import { paginationRowsPerPageOptions } from '../../helpers/helper';
import { FaTimes } from 'react-icons/fa';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { CLUB_PROFILES } from '../../constants/pathname';
const MemberStats = () => {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [showDrawer, setShowDrawer] = useState(false);
    const [data, setData] = useState([{}]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [limit, setLimit] = useState(25);
    const [search, setSearch] = useState('');
    const [update, setUpdate] = useState(false);
    const [pending, setPending] = useState(false);
    const [debouncedSearch, setDebouncedSearch] = useState('');

    const navigate = useNavigate();

    const handleSearch = (value) => {
        setSearch(value);
        setSearchText(value);

    };
    const clearSearch = () => {
        setSearch('');
        setSearchText('');
        setDebouncedSearch('');
        setUpdate(!update);
    };
    const handleFilter = () => {
        setPage(1)
        setShowDrawer(false);
        setUpdate(!update)
        // getListData();
    }
    const handleClear = () => {
        setFromDate(null);
        setToDate(null);           
        setUpdate(false)
        setShowDrawer(false);
    }
    

    useEffect(() => {
        if (search.length >= 2) {
            const debounceTimer = setTimeout(() => {
                setDebouncedSearch(search);
            }, 500);

            return () => clearTimeout(debounceTimer);
        } else {
            setDebouncedSearch('');
        }
    }, [search]);

    const columns = [
        {
            name: "Sl.No",
            key: "name",
            selector: row => row?.name,
            cell: (row, index) =>
                <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.name}>
                    {(limit * (page - 1)) + (index + 1)}
                </div>,
            sortable: true,
            grow: 2,
            reorder: true,
            width: '110px'
        },
        {
            name: "Club Name",
            selector: row => (
                <div
                    title={row.club_name}
                    style={{ cursor: 'pointer',color:'#0000EE' }}
                    onClick={() => navigate(`${CLUB_PROFILES}${row.club}`, { state: { country: row.country }})}
                >
                    {row.club_name}
                </div>
            ),

            minWidth: "250px"
        },
        { name: "Uploaded Members", selector: row => row?.member_stats?.uploaded, width: "230px", reorder: true },
        { name: "Invited Members", 
            selector: row => 
            <div>
                {row?.member_stats?.invited}/{row?.member_stats?.uploaded}
            </div>, 
            width: "230px", reorder: true },
        { name: "Activated Members", 
            selector: row => 
                <div>
                    {row?.member_stats?.activated}/{row?.member_stats?.invited}
                </div>, 
            width: "230px", reorder: true },
    ];

    // Custom styling for the table
    const customStyles = {
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",
                border: "1px solid var(--border-color)",
                overflow: "hidden"
            },
        },
        headCells: {
            style: {
                backgroundColor: "var(--light-blue)",
                justifyContent: 'flex-start !important',
                color: "var(--dark-gray)",
                fontWeight: "400",
                fontSize: "15px",
            },
        },
        rows: {
            style: {
                height: "45px",
                justifyContent: "start",
            },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    };

    const disableToDate = (date) => {
        return fromDate && date < fromDate;
    };

    useEffect(() => { getListData(); }, [page, limit, debouncedSearch,update]);

    const getListData = () => {
        setPending(true);
        
        if (debouncedSearch) {
            setPage(1);
        }
        listMemberStats({ page:debouncedSearch?1:page,
             limit,
             search:debouncedSearch ,
             from_date: fromDate ? dayjs(fromDate).format('YYYY-MM-DD') : '',
             to_date: toDate ? dayjs(toDate).format('YYYY-MM-DD') : ''})(
            (response) => {
                if (response && response.status) {
                    setData(response?.data?.results);
                    setTotalRows(response?.data?.count);
                    setPending(false);
                }
            }
        );
    };

    return (
        <div className='member-stats-section member-stats-list'>
            <Drawer
                anchor={'right'}
                open={showDrawer}
                onClose={() => setShowDrawer(false)}
                className='member-stats-drawer'
                sx={{
                    '& .MuiPaper-root': {
                        padding: '30px 25px',
                        height: "100vh",
                        overflow: "hidden"
                    },
                    '& .MuiDrawer-paper': {
                        width: '420px'
                    },
                    '& .MuiBox-root': {
                        width: '420px'
                    },
                    '& .MuiList-root': {
                        display: 'flex',
                        columnGap: '10px',
                        width: '90%',
                        margin: 'auto',
                        paddingTop: "25px"
                    },
                    '& .MuiButtonBase-root': {
                        padding: '7px',
                        textAlign: 'center',
                        alignItems: 'center',
                        borderRadius: '10px',
                        backgroundColor: '#F5F5F5',
                    }
                }}
            >
                <div className="drawer-top-box">
                    <div>Filter</div>
                    <button type="button" class="btn-close" aria-label="Close" onClick={() => setShowDrawer(false)}></button>
                </div>
                <div className="drawer-bottom-box mt-4">
                    <Form onSubmit={(e) => e.preventDefault()}>
                        {/* From Date */}
                        <div className='item'>
                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em3">
                                <Form.Label>From Date</Form.Label>
                                <div className='input-border'>
                                    <DatePicker
                                        id='from_date'
                                        onChange={(value) => {
                                            setFromDate(value);
                                            if (toDate && value > toDate) {
                                                setToDate(null);
                                            }
                                        }}
                                        value={fromDate}
                                        format="MM/dd/yyyy"  // Format the date as MM/DD/YYYY
                                        placement="autoVerticalEnd"
                                        shouldDisableDate={(date) => date > new Date()}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        {/* To Date */}
                        <div className='item'>
                            <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em4">
                                <Form.Label>To Date</Form.Label>
                                <div className='input-border'>
                                    <DatePicker
                                        id='to_date'
                                        onChange={(value) => setToDate(value)}
                                        placement="autoVerticalEnd"
                                        shouldDisableDate={(date) => {
                                            return fromDate ? date < fromDate || date > new Date() : date > new Date();
                                        }}
                                        value={toDate}
                                        format="MM/dd/yyyy"  // Format the date as MM/DD/YYYY
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        {/* Search */}

                        <div className='submit-box d-flex align-items-center justify-content-end gap-3' style={{height: "70vh"}}>
                            <div className='btn-type-2 cursor-pointer' onClick={handleClear} >Clear</div>
                            <button className='btn-type-1' type="submit"  onClick={handleFilter}>Submit</button>
                        </div>
                    </Form>
                </div>

            </Drawer>
            <div className='page-details'>
                <div className='top-section d-flex align-items-center justify-content-between'>
                    <div className='left'>Member Stats</div>
                    <div className='right  d-flex align-items-center gap-3'>
                        <div className='search-box'>
                            <input
                                type='text'
                                placeholder='Search by club name'
                                className='form-control'
                                value={searchText}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                            {/* <FaSearch className='search-icon' /> */}
                            {searchText && (
                                <FaTimes className='clear-icon' onClick={clearSearch} />
                            )}
                        </div>
                        <div className='filter-box d-flex align-items-center gap-3 cursor-pointer' onClick={() => setShowDrawer(true)}>
                            <FilterIcon />
                            <span>Filter</span>
                        </div>
                    </div>
                </div>

                <div className='table-box mt-3'>
                    <DataTable
                        columns={columns}
                        data={data}
                        customStyles={customStyles}
                        fixedHeader
                        highlightOnHover
                        responsive
                        pagination
                        paginationServer
                        paginationPerPage={limit}
                        progressPending={pending}
                        onChangeRowsPerPage={setLimit}
                        paginationTotalRows={totalRows}
                        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                        onChangePage={setPage}
                    />
                </div>
            </div>
        </div>
    )
}

export default MemberStats