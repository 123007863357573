import React from 'react'
import { Container } from 'react-bootstrap'
import logo from "../../assets/images/common/logo/logo-pcw-white.png"
import logo1 from "../../assets/images/common/logo/logo-pcn-white.png"
import { checkTheme } from '../../utils/utils';


const PrivacyPolicys = () => {  
    
    return (
      <section className='terms-condtion'>
        <div className='logo'>
          <div className='image-wrapper'>
            <img src={checkTheme() ? logo1 : logo} alt='' style={{maxWidth: "275px"}}/>
          </div>
        </div>
  
        <Container>
          <h3 className='main-head'>Privacy Policy</h3>
          <div style={{ paddingRight: "15px" }}>
            <p style={{ textAlign: "center", marginTop: "15px" }}>
              <strong>MOBICOM PRIVACY POLICY</strong>
            </p>
            <p>
              &nbsp;<strong>Introduction</strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                This privacy policy (“Privacy Policy” or “Policy”) explains the
                data protection practices of MobiCom America Inc and its
                subsidiaries and affiliates (collectively, “MobiCom”, “we” and
                “us”) when you visit or use the websites, mobile-optimized version
                of our websites, and our digital applications to which this policy
                is linked, as well as when you visit or use third-party
                subscription pages and or submit offline subscription forms and
                requests. Notwithstanding anything herein to the contrary, this
                Policy does not extend to information submitted on the MobiCom
                Careers page at
              </span>
              <span style={{ fontWeight: 400 }}>&nbsp;</span>
              <a href="https://www.mobicom.com/careers"  style={{color: "#1675e0"}}>
                <strong>https://www.mobicom.com/careers</strong>
              </a>{" "}
              <span style={{ fontWeight: 400 }}>
                or to employees, former employees, candidates, contractors,
                service providers, or business contacts of MobiCom.&nbsp;
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                If you live in the United States or outside the European Union
                (EU), your information is controlled by MobiCom America Inc., 6010
                West Spring Creek Parkway, Plano, Texas 75024, U.S.A. If you live
                within the EU, the data controller representative responsible for
                your information is Stefano Cardoni, Via Litoranea Km 6,900,
                Localita Pian di Spille, Tarquinia (VT) 01016 Italy.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                For the purposes of data protection laws, MobiCom is the data
                controller.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                This Privacy Policy is incorporated into and subject to the
                services’ Terms of Use. If you do not agree to the Terms of Use
                and this Privacy Policy, you must discontinue using the services
                and all components and features therein. By using the services,
                you acknowledge you have read and understand this Privacy Policy.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <strong>The Information We Collect</strong>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                We obtain information about you through the means discussed below
                when you use our services. The information we collect and the
                purposes for which we use it will depend to some extent on the
                specific services you use and how you interact with MobiCom. For
                example, we collect the information you provide when you
                participate in our social networking features, subscribe to
                newsletters, or other electronic services, or send us an email or
                feedback. If you elect to post material to any blogs or forums, or
                participate in our social networking features or other community
                boards that may be offered on our services, then such materials
                will be collected and some information, including your posted
                pseudonym, may be publicly available for others to view. We, or
                our service providers on our behalf, may collect the following
                information about you or your use of the services:
              </span>
            </p>
            <p>
              <strong>Information You Provide to Us:</strong>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Registration, Subscription or Contact Information&nbsp;such as
                e-mail address and name
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Customer service information&nbsp;such as questions and other
                messages you address to us directly through online forms, by
                email, over the phone, or by post; and summaries or voice
                recordings of your interactions with customer care
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                User-generated content&nbsp;such as comments on articles, photos,
                videos, audio, any information you submit in public forums or
                message boards, reviews and feedback or testimonials you provide
                about our services
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Research or survey information&nbsp;(including contact information
                and user generated content) collected if you participate in a
                survey; includes information needed for you to participate (such
                as contact information)
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Social media information&nbsp;(which may include contact
                information, photos, location, user generated content, demographic
                information) if you link your account or access the services
                through a third-party connection or log-in, we may have access to
                any information you provide to that social network depending on
                your privacy settings such as your name, email address, location,
                and current city; and information you provide to us directly
                through our pages on social networking and blogging platforms
                (e.g., Facebook, Instagram, Snapchat, WordPress, and Twitter)
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Other information&nbsp;any other information you choose to
                directly provide to MobiCom in connection with your use of the
                services
              </span>
            </p>
            <ul>
              <li style={{ textAlign: "justify" }}>
                Comply with any applicable procedures, laws, and regulations,
                subpoenas, governmental requests or legal process, or in
                connection with a legal investigation, if in our good faith
                opinion such is required or permitted by law;
              </li>
              <li style={{ fontWeight: 400, textAlign: "justify" }}>
                <span style={{ fontWeight: 400 }}>
                  Establish, exercise, or defend our legal rights (e.g., to
                  enforce compliance with our Terms of Use, Privacy Policies, or
                  other contracts or legal rights)
                </span>
              </li>
              <li style={{ fontWeight: 400, textAlign: "justify" }}>
                <span style={{ fontWeight: 400 }}>
                  Protect or defend our services, users, or others
                </span>
              </li>
            </ul>
            <span style={{ fontWeight: 400 }}>&nbsp;</span>
            <p style={{ textAlign: "justify" }}>
              <strong>Aggregate and/or De-Identified Information.</strong>
              <span style={{ fontWeight: 400 }}>&nbsp;</span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                We may aggregate or anonymized information and/or de-identify any
                information collected through the services so that such
                information can no longer be linked to you or your device
                (“Aggregate/De-Identified Information”). We may use this
                Aggregate/De-Identified Information for any purpose permitted
                under the law, including without limitation for research and
                marketing purposes, and disclose such information to third
                parties, including advertisers, promotional partners, and sponsors
                in our sole discretion
              </span>
              <span style={{ fontWeight: 400 }}>
                Information We Collect Automatically:
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Device information and identifiers&nbsp;such as IP address;
                browser type and language; operating system; platform type; device
                type; software and hardware attributes; and unique device,
                advertising, and app identifiers
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Internet network and device activity data&nbsp;such as information
                about files you download, domain names, landing pages, browsing
                activity, content or ads viewed and clicked, dates and times of
                access, pages viewed, forms you complete or partially complete,
                search terms, uploads or downloads, the URL that referred you to
                our services, the web sites you visit after this web site; if you
                share our content to social media platforms; and other web usage
                activity and data logged by our web servers, whether you open an
                email and your interaction with email content, access times, error
                logs, and other similar information. See “Cookies and Other
                Tracking Technologies” below for more information about how we
                collect and use this information.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Geolocation information&nbsp;such as city, state and ZIP code
                associated with your IP address or derived through Wi-Fi
                triangulation; and precise geolocation information from GPS-based
                functionality on your mobile devices, with your permission in
                accordance with your mobile device settings
              </span>
            </p>
            <p>
              <strong>
                <span style={{ textAlign: "justify" }}>
                  How We Use the Information We Collect
                </span>
              </strong>
            </p>
            <p>
              <strong>For our Marketing for example to:</strong>
            </p>
            <ul>
              <li>
                <span style={{ fontWeight: 400 }}>
                  Send you newsletters, emails, surveys and information about
                  products, services and promotions offered by us.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  Bug detection and error reporting, including to understand and
                  resolve technical issues, and other issues being reported.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  Audit consumer interactions on the services&nbsp;including to
                  measure the placement, frequency, efficacy and compliance of
                  advertising and ad impressions.
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  Security, Fraud, and Legal Compliance, including by detecting,
                  protecting against, and prosecuting security incidents, fraud,
                  and illegal activity for example to:
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  Monitor prevent, and detect fraud, such as through verifying
                  your identity
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  Combat spam or other malware or security risks
                </span>
              </li>
              <li>
                <span style={{ fontWeight: 400 }}>
                  Monitor, enforce, and improve the security of our services
                </span>
              </li>
            </ul>
            <span style={{ fontWeight: 400 }}>&nbsp;</span>
            <p>
              &nbsp;<strong>How We Share the Information Collected</strong>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                MobiCom may (and you authorize us to) share or disclose
                information collected from and about you to other companies or
                individuals as set forth below.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Service Providers.&nbsp;We may provide access to information to
                vendors that are performing services on our behalf, managing our
                email lists and sending email messages on our behalf, providing
                customer support, analytics providers, and performing other
                administrative services, in order to carry out such services.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Our Affiliates.&nbsp;We may share information we collect within
                the MobiCom group of companies to deliver products and services to
                you, ensure a consistent level of service across our services,
                internal management and administrative purposes on our behalf and
                enhance our products, services, and your customer experience.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Information You Disclose Publicly.&nbsp;You may submit
                photographs, user profiles, written material, music, video,
                photos, comments and other content, which may include information
                relating to individual users (collectively, “UGC”) on the
                services. We do not control who will have access to the UGC you
                choose to make public or how they will use it and take no
                responsibility for ensuring such UGC remains private or is secure.
                The UGC is not subject to this Privacy Policy. We are also not
                responsible for the accuracy, use or misuse of any UGC that you
                disclose or receive through the services. Please see our Terms of
                Use for further information about the terms that govern the UGC
                you post.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Third Parties for Legal Purposes.&nbsp;By using the services, you
                acknowledge and agree that we may access, retain, and disclose the
                information we collect and maintain about you if required to do so
                by law or in a good faith belief that such access, retention or
                disclosure is reasonably necessary to: (a) comply with legal
                process or a regulatory investigation (e.g. a subpoena or court
                order); (b) enforce our Terms of Service, this Privacy Policy, or
                other contracts with you, including investigation of potential
                violations thereof; (c) respond to{" "}
              </span>
              <span style={{ fontWeight: 400 }}>
                claims that any content violates{" "}
              </span>
              <span style={{ fontWeight: 400 }}>
                the rights of third parties; and/or (d) protect the rights,
                property or personal safety of MobiCom, its agents and affiliates,
                its users and/or the public. This includes exchanging information
                with other companies and organizations for fraud protection, and
                spam/malware prevention, and similar purposes.
              </span>
            </p>
            <ol>
              <li style={{ fontWeight: 400, textAlign: "justify" }}>
                <span style={{ fontWeight: 400 }}>
                  Social Networks.&nbsp;As noted above, if you use your login
                  credentials from a social media networking service on a Website,
                  we may receive information from such service in accordance with
                  its terms and privacy policy and your settings. If you elect to
                  share your information with these social networking sites, we
                  will share information with them in accordance with your
                  election. The terms and conditions of these social networking
                  sites will apply to the information we disclose to them.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Sales or Transfer of Business or Assets.&nbsp;In connection with
                  a sale, merger, transfer, exchange, or other disposition
                  (whether of assets, stock, or otherwise, including via
                  bankruptcy) of all or a portion of the business conducted by the
                  Website to which this policy applies, in which case the company
                  will possess the information collected by us and will assume the
                  rights and obligations regarding your information as described
                  in this Privacy Policy (the “Acquisition Use”).
                </span>
              </li>
            </ol>
            <p>
              <strong>
                Legal Grounds for Using (Processing) Your Information
              </strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                If you are a visitor from the European Economic Area, our legal
                basis for collecting and using the information described above
                will depend on the information concerned and the context in which
                we collect it. We collect information from you:
              </span>
            </p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  If you have questions about or need further information
                  concerning the legal basis on which we collect and use your
                  information, please contact us using the contact details
                  provided under the “
                </span>
                <a href="https://www.mobicom.com/contact"  style={{color: "#1675e0"}}>
                  <span style={{ fontWeight: 400 }}>Contact Us”</span>
                </a>
                <span style={{ fontWeight: 400 }}> section below.</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  If we otherwise have your consent.
                </span>
              </li>
              <li style={{ fontWeight: 400, textAlign: "justify" }}>
                <span style={{ fontWeight: 400 }}>
                  Where the processing is in our legitimate interests (provided
                  that these aren’t overridden by your interests or rights) (such
                  as personalizing our services and marketing for example), or
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Where we need it to perform our contract with you (i.e. our
                  Terms of Service),
                </span>
              </li>
            </ul>
            <span style={{ fontWeight: 400 }}>&nbsp;</span>
            <p>
              <strong>Online Analytics&nbsp;</strong>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Delivery of Other Content.&nbsp;In addition to content that we
                serve you directly, MobiCom may use third party services to serve
                content when you use our services, as well as on other websites
                you visit and other applications you use. The content may be based
                on various factors such as the content of the page you are
                visiting, your searches, demographic data, user-generated content,
                and other information. These content may be based on your current
                activity or your activity over time and across other websites and
                online services and may be tailored to your interests. Third
                parties, whose products or services are accessible or advertised
                via the services, may also place cookies or other tracking
                technologies on your computer, mobile phone, or other device to
                collect information about you as discussed above.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                We neither have access to, nor does this Privacy Policy govern,
                the use of cookies or other tracking technologies that may be
                placed on the device you use to access the services by such
                non-affiliated third parties.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                If you are interested in more information about tailored browser
                advertising and how you can generally control cookies from being
                put on your computer to deliver tailored advertising, you may
                visit the
              </span>
              <span style={{ fontWeight: 400 }}>&nbsp;</span>
              <a href="http://optout.networkadvertising.org/?c=1">
                <span style={{ fontWeight: 400 }}>
                  Network Advertising Initiative Opt Out Page
                </span>
              </a>
              <span style={{ fontWeight: 400 }}>,</span>
              <span style={{ fontWeight: 400 }}>
                {" "}
                the Digital Advertising Alliance’s Consumer Opt-Out link, or&nbsp;
              </span>
              <a href="http://www.youronlinechoices.eu/">
                <span style={{ fontWeight: 400 }}>Your Online Choices</span>
              </a>
              <span style={{ fontWeight: 400 }}>
                &nbsp;to opt-out of receiving tailored advertising from companies
                that participate in those programs.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                To learn more about how to manage your preferences regarding our
                cookie-based advertising, please see the&nbsp;“Cookie Management”
                section in our Cookie Notice below
              </span>
              <span style={{ fontWeight: 400 }}>.</span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                To opt out of Google Analytics for display advertising or
                customize Google display network ads, visit the&nbsp;
              </span>
              <a href="https://adssettings.google.com/authenticated" style={{color: "#1675e0"}}>
                <span style={{ fontWeight: 400 }}>Google Ads Settings</span>
                <span style={{ fontWeight: 400 }}> page</span>
              </a>
              <span style={{ fontWeight: 400 }}>
                . We do not control these opt-out links or whether any particular
                company chooses to participate in these opt-out programs. We are
                not responsible for any choices you make using these mechanisms or
                the continued availability or accuracy of these mechanisms.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Please note that if you exercise the opt out choices above, you
                will still see advertising when you use the services, but it will
                not be tailored to you based on your online behavior over time.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Do Not Track.&nbsp;MobiCom does not act on “do not track” requests
                from your browser because, this way, we are able to personalize
                your experiences on our services.
              </span>
            </p>
            <p>
              <strong>Your Rights &amp; Your Choices</strong>
            </p>
            <p style={{ textAlign: "justify" }}>
              <strong>Marketing Emails</strong>
              <strong>.</strong>
              <span style={{ fontWeight: 400 }}>
                &nbsp;If you want to stop receiving promotional e-mails from us,
                click on the “unsubscribe” link in any promotional email from us.
                Please note that once we receive your request, it may take an
                additional period of time for your opt-out to become effective. We
                may still communicate with you from time to time if we need to
                provide you with transaction information about the services you
                are using, if we need to request information from you with respect
                to a transaction initiated by you, or for other legitimate
                non-marketing reasons, for example, if we update this Privacy
                Policy or our Terms of Service
              </span>
              <span style={{ fontWeight: 400 }}>.</span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <strong>SMS Text Communications.</strong>
              <span style={{ fontWeight: 400 }}>
                &nbsp;You may opt-out of SMS messages from us by following the
                instructions provided in the message or by texting back STOP to
                the number we send the text from for that particular SMS message.
                When we receive an opt-out message from you for SMS messages, we
                may send a message confirming our receipt of your opt-out.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                You have certain rights around the use of your data, including the
                ability to manage the content and information which we can access
                when you use MobiCom {checkTheme() ? 'PlatinumClubNet' : 'PrivateClubWorld'} Software. You can view and
                change your personal data or which data we can access by signing
                into your account or by&nbsp;
              </span>
              {checkTheme() ?
  
                <a href="mailto:support@platinumclubnet.com">
                  <span style={{ fontWeight: 400 }}>contacting us</span>
                </a>
                :
                <a href="mailto:support@privateclub.world">
                  <span style={{ fontWeight: 400 }}>contacting us</span>
                </a>
              }
  
              <span style={{ fontWeight: 400 }}>.</span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                We will respond to your access request within 14 days and, in any
                case, not more than one month of receiving it. Normally, we aim to
                provide a complete response, including a copy of your personal
                data within that time. In some cases, however, particularly if
                your request is more complex, more time may be required up to a
                maximum of three months from the date we receive your request. You
                will be kept fully informed of our progress.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                To terminate and/or delete your account, or any specific data that
                we have access to, you can submit your request by&nbsp;
              </span>
              <a href="mailto:support@privateclub.world">
                <span style={{ fontWeight: 400 }}>contacting us</span>
              </a>
              <span style={{ fontWeight: 400 }}>
                . We will comply your request within 30 (thirty) days and send you
                a confirmation email. Please note that when you request to delete
                your account, we delete all your personal information and
                personally identifiable data, and we are not able to recover your
                account or data after that.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Please note: Even after your account is deleted, we may retain
                your data for as long as we have a legitimate purpose to do so
                (and in accordance with applicable law), including to assist with
                legal obligations, resolve disputes, and enforce our agreements.
                We may retain and disclose such data pursuant to this Privacy
                Policy after your account has been terminated.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Privacy Information for California Residents.&nbsp;If you are a
                California resident, California law requires us to provide you
                with some additional information regarding your rights with
                respect to your “personal information” (as defined in the
                California Consumer Privacy Act (“CCPA”)). We describe the
                categories of personal information we collect, the sources and
                uses of such information and the entities to which we share such
                information in our&nbsp;California Privacy Notice.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <strong>California Data Access &amp; Deletion Rights</strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                If you are a California resident, the CCPA allows you to make
                certain requests about your personal information. Specifically,
                the CCPA allows you to request us to:
              </span>
            </p>
            <ol>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Inform you about the categories of personal information we
                  collect or disclose about you; the categories of sources of such
                  information; the business or commercial purpose for collecting
                  your personal informati
                </span>
                <span style={{ fontWeight: 400 }}>
                  on; and the categories of third parties with{" "}
                </span>
                <span style={{ fontWeight: 400 }}>
                  whom we share/disclose personal information.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Provide access to and/or a copy of certain personal information
                  we hold about you.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Delete certain personal information we have about you
                </span>
                <span style={{ fontWeight: 400 }}>.</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Provide you with information about the financial incentives that
                  we offer to you, if any.
                </span>
              </li>
            </ol>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Please note that certain information may be exempt from such
                requests under California law. For example, we need certain
                information in order to provide the services to you
              </span>
              <span style={{ fontWeight: 400 }}>.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Verification: We will take reasonable steps to verify your
                identity before responding to a request, which may include,
                depending on your request and relationship with you verifying your
                name, email address, address, and/or having you respond to an
                email from us.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                You are also permitted to designate an authorized agent to submit
                certain requests on your behalf. In order for an authorized agent
                to be verified, you must provide the authorized agent with signed,
                written permission to make such requests or a power of attorney.
                We may also follow up with you to verify your identity before
                processing the authorized agent’s request.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                If you would like further information regarding your legal rights
                under California law or would like to exercise any of them, please
                email us at support@mobicom.com.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Please note that MobiCom employees who are seeking further
                information about their legal rights under California law should
                instead contact their MobiCom HR representative
              </span>
              <span style={{ fontWeight: 400 }}>.&nbsp;</span>
            </p>
            <p>
              <strong>
                Notice of Right to Opt Out of Sale of Personal Information
              </strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                If you would like to opt out of MobiCom’s use of your information
                for such purposes that are considered a “sale” under California
                law, you may do so as outlined on the following page:&nbsp;Do Not
                Sell My Personal Information. You can also submit a sale opt-out
                request by emailing us at support@mobicom.com. Please note that we
                do not knowingly sell the personal information of minors under 16
                years of age without legally required affirmative authorization.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                Please note that MobiCom employees who are seeking further
                information about their legal rights under California law should
                instead contact their MobiCom HR representative.
              </span>
              <span style={{ fontWeight: 400 }}>&nbsp;</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                The CCPA further provides you with the right to not be
                discriminated against (as provided for in applicable law) for
                exercising your access, deletion or sale opt out rights.
              </span>
            </p>
            <p>
              <strong>California “Shine the Light” Disclosure</strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                The California “Shine the Light” law gives residents of California
                the right under certain circumstances to opt out of the sharing of
                certain categories of personal information (as defined in the
                Shine the Light law) with third parties for their direct marketing
                purposes. If you would like to opt out of such sharing, please use
                the sale opt out request noted above. If you are a California
                resident and would like a copy of this notice, please submit a
                written request to the following address: MobiCom America Inc,
                6010 W Spring Creek Pkwy, Plano, TX 75024, Attn: Privacy Officer.
                In your request, please specify that you want a “MobiCom
                California Privacy Rights Notice.” Please allow at least 30 days
                for a response.
              </span>
            </p>
            <p>
              <strong>International Users</strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Some international users of our services have certain legal rights
                to access certain information we hold about them and to request
                its deletion. For example, your local laws may permit you to
                request that we:
              </span>
            </p>
            <ol>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Provide access to and/or a copy of certain information we have
                  about you;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Prevent the processing of your information for direct-marketing
                  purposes;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Update information which is out of date or incorrect;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Delete certain information which we have about you;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Restrict the way that we process and disclose certain of your
                  information;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Transfer your information to a third-party provider of services;
                  and
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Revoke your consent for the processing of your information.
                </span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                We will consider all requests and provide our response within the
                time period stated by applicable law. Certain information may be
                exempt from such requests in some circumstances, which may include
                if we need to keep processing your information for our legitimate
                interests or to comply with a legal obligation. We may request you
                provide us with information necessary to confirm your identity
                before responding to your request.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                If you would like further information in relation to your legal
                rights under applicable law or would like to exercise any of them,
                please contact us using the information in the “Contact Us”
                section below at any time.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                We are a global business. Personal data may be stored and
                processed in any country where we have operations or where we
                request in services to our affiliates. We may transfer Personal
                Data that we maintain about you to recipients in countries other
                than the country in which the Personal Data was originally
                collected, including to the United States. Those countries may
                have data protection rules that are different from those of your
                country. However, we will take measures to ensure that any such
                transfers comply with applicable data protection laws and that
                your Personal Data remains protected to the standards described in
                this Privacy Policy.&nbsp;In certain circumstances, courts, law
                enforcement agencies, regulatory agencies or security authorities
                in those other countries may be entitled to access your Personal
                Data.
              </span>
            </p>
            <p>
              &nbsp;<strong>General Data Privacy Regulation (GDPR)</strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                The GDPR took effect on May 25, 2018 and is intended to protect
                the data of European Union (EU) citizens. If the data that you
                provide to us, in the course of your use of our site, services or
                products is governed by GDPR, we will abide by the relevant
                portions of the regulation. To make any requests related to your
                GDPR rights, please review the section above on Your Rights.
              </span>
            </p>
            <p>
              <strong>Privacy Information for Nevada Residents</strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Under Nevada law, Nevada residents who have purchased services
                from us may opt out of the “sale” of “covered information” (as
                such terms are defined under Nevada law). “Covered information”
                includes, among other things, first and last name, address, email
                address, and phone number. In some circumstances, this sharing may
                qualify as a sale under Nevada law. If you are a Nevada resident
                who has purchased goods or services from us, you may submit a
                request to opt out of such activity by submitting a request to
                support@mobicom.com. Please note we may take reasonable steps to
                verify your identity and the authenticity of the request.
              </span>
            </p>
            <p>
              <strong>Retention of Your Information</strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                We keep your information for no longer than necessary for the
                purposes for which it is processed. The length of time for which
                we retain information depends on the purposes for which we
                collected and use it and/or as required to comply with applicable
                laws.
              </span>
            </p>
            <p>
              <strong>Cookies and Other Tracking Technologies</strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                We use and allow certain other companies to use certain tracking
                technologies, including cookies, web beacons, and other similar
                technologies (collectively, “Cookies”) on the services. To see an
                explanation what these technologies are and why we use them, as
                well as your rights to control our use of them please see
                our&nbsp;cookie notice. We endeavor to adhere to the Digital
                Advertising Alliance’s (DAA) self-regulatory principles governing
                interest-based advertising.
              </span>
            </p>
            <p>
              <strong>Links to Third Party Sites</strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                When you are on the services, you may be directed to other
                websites that are operated and controlled by third parties that we
                do not control. We are not responsible for the privacy practices
                employed by any of these third parties. If you access a
                third-party website from the services, you do so at your own risk,
                and you understand that this Privacy Policy does not apply to your
                use of such websites
              </span>
              <span style={{ fontWeight: 400 }}>.</span>
            </p>
            <p>
              <strong>Security</strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                We implement appropriate administrative, technical and security
                safeguards to help prevent unauthorized access, use, or disclosure
                of the information we collect. However, no systems can be
                completely secure. Therefore, while MobiCom uses reasonable
                efforts to protect your information, MobiCom cannot guarantee its
                absolute security, and your use of the services indicates your
                agreement to assume this risk.
              </span>
            </p>
            <p>
              <strong>Special Note For Parents</strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                The services are for a general audience and are not designed or
                intended for use by children, especially those under age thirteen
                (13) or sixteen (16) in the EU.
              </span>
            </p>
            <p>
              <strong>
                <strong>Transfer Information to the United States</strong>
              </strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                The services are operated in the United States of America (USA)
                and are intended for users located in the USA. If you are located
                outside of the USA, please note that the information you provide
                to us will be transferred to and processed in the USA, where laws
                regarding processing of personal data may be less stringent than
                the laws in your country.
              </span>
            </p>
            <p>
              <strong>
                <strong>Changes to This Privacy Policy</strong>
              </strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                We reserve the right to change this Privacy Policy at any time
                without prior notice. Any changes will be effective immediately
                upon the posting of the revised Privacy Policy.
              </span>
            </p>
            <p>
              <strong>
                <strong>Disputes; No Rights of Third Parties</strong>
              </strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                If you choose to access the services, any dispute over privacy is
                subject to our&nbsp;
              </span>
              <a href="https://pmc.com/terms-of-use/"  style={{color: "#1675e0"}}>
                <span style={{ fontWeight: 400 }}>Terms of Use</span>
              </a>
              <span style={{ fontWeight: 400 }}>
                , including limitations on damages, resolution of disputes by
                binding arbitration, and application of the laws of the United
                States, and the State of Texas. This Privacy Policy does not
                create rights enforceable by third parties.
              </span>
            </p>
            <p>
              <strong>
                <strong>Contact Us: Data Privacy Officer</strong>
              </strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                If you have any questions or concerns about any aspect of this
                Privacy Policy, please contact our Data Privacy Officer at the
                address set forth below.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>Data Privacy Officer</span>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>MobiCom America Inc</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>6010 W Spring Creek Pkwy,</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>Plano, TX 75024</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>Or</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>support@mobicom.com&nbsp;</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>Last revised on July 2023</span>
            </p>
            <p>
              <strong>Appendix A: California Privacy Notice</strong>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                This Privacy Notice for California residents supplements the&nbsp;
              </span>
              <span style={{ fontWeight: 400 }}>MobiCom Privacy Policy</span>
              <span style={{ fontWeight: 400 }}>.</span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                This Notice applies to website visitors and other customers in
                California. This Notice does not apply to employees, former
                employees, candidates, contractors, service providers, or business
                contacts of MobiCom. This supplemental privacy notice gives
                California residents additional information about our information
                collection and use practices required by the California Consumer
                Privacy Act of 2018 (“CCPA”). The CCPA also provides California
                residents with specific privacy rights, which are outlined in our
                Privacy Policy.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                This notice provides information about the categories of personal
                information that we collect from California residents generally,
                the purposes for which we use the information, the sources of the
                information, and the categories of third parties to whom we
                disclose the information for business purposes. More detailed
                information is available in our Privacy Policy at&nbsp;
              </span>
              <span style={{ fontWeight: 400 }}>
                How We Use Personal Information
              </span>
              <span style={{ fontWeight: 400 }}>&nbsp;and&nbsp;</span>
              <span style={{ fontWeight: 400 }}>
                How We Share Personal Information.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                If you would like to opt out of MobiCom’s use of your information
                for such purposes that are considered a “sale” under California
                law, you may do so as outlined on the following page:&nbsp;Do Not
                Sell My Personal Information. You can also submit a sale opt-out
                request by emailing us at support@mobicom.com. Please note that we
                do not knowingly sell the personal information of minors under 16
                years of age without legally required affirmative authorization.
              </span>
            </p>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
              <span style={{ fontWeight: 400 }}>
                Please note that MobiCom employees who are seeking further
                information about their legal rights under California law should
                instead contact their MobiCom HR representative.&nbsp;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Information you provide to us:
              </span>
            </p>
            <div className="table-responsive">
              <table style={{ width: "100%" }} border="1" className="table table-bordered">
                <tbody>
                  <tr>
                    <td style={{ width: "361px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Categories of information collected
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "295px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>Purposes of use</span>
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        <span style={{ fontWeight: 400 }}>
                          <br />
                        </span>
                        <span style={{ fontWeight: 400 }}>
                          (see “How We Use Personal Information” in our Privacy
                          Policy for additional information)
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "148.203px" }}>
                      <p style={{ textAlign: "center" }}>
                        <span style={{ fontWeight: 400 }}>
                          Sources of personal information
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "187px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Categories of third parties to whom we disclose the
                          information&nbsp;
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "361px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Registration, Subscription or Contact Information&nbsp;
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          such as e-mail address, name, phone number, shipping
                          address, and billing information
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "295px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Provide the services
                        </span>
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        <span style={{ fontWeight: 400 }}>
                          Communicate with You &amp; Provide Customer Service
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Personalize Your Experience
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Improve the services
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Our Marketing Purposes
                        </span>
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        <span style={{ fontWeight: 400 }}>
                          Bug detection and error reporting
                        </span>
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        <span style={{ fontWeight: 400 }}>
                          Auditing Consumer Interactions
                        </span>
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        <span style={{ fontWeight: 400 }}>
                          Security, Fraud, and Legal Compliance
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "148.203px" }}>
                      <p style={{ textAlign: "justify" }}>
                        <span style={{ fontWeight: 400 }}>You&nbsp;</span>
                        <span style={{ fontWeight: 400 }}>
                          Conferences and other events&nbsp;
                        </span>
                        <span style={{ fontWeight: 400 }}>Our affiliates</span>
                      </p>
                    </td>
                    <td style={{ width: "187px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>Service Providers</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>Our Affiliates</span>
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        <span style={{ fontWeight: 400 }}>
                          Other Individuals, services, and Partners at Your Request
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Entities for Legal Purposes
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Entities For Sales or Transfer of Business or Assets
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "361px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Customer service information
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          such as questions and other messages you address to us
                          directly through online forms, by email, over the phone,
                          or by post; and summaries or voice recordings of your
                          interactions with customer care
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "295px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Provide the services
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Communicate with You &amp; Provide Customer Service
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Bug detection and error reporting
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Security, Fraud, and Legal Compliance
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Improve the services
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "148.203px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>You&nbsp;</span>
                        <span style={{ fontWeight: 400 }}>Our affiliates</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>&nbsp;</span>
                      </p>
                    </td>
                    <td style={{ width: "187px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>Service Providers</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>Our Affiliates</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Entities for Legal Purposes
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Entities For Sales or Transfer of Business or Assets
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "361px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Demographic, statistical and interest information
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          such as your age, date of birth, gender, interests,
                          lifestyle information, and hobbies
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "295px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Provide the services
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Communicate with You &amp; Provide Customer Service
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Personalize Your Experience
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Improve the services
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Security, Fraud, and Legal Compliance
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "148.203px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>You&nbsp;</span>
                        <span style={{ fontWeight: 400 }}>
                          Other users who provide information about you&nbsp;
                        </span>
                        <span style={{ fontWeight: 400 }}>
                          Consumer data resellers
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Our affiliates&nbsp;
                        </span>
                        <span style={{ fontWeight: 400 }}>
                          Conferences and other events
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "187px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>Service Providers</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>Our Affiliates</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Other Individuals, services, and Partners at Your Request
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Entities for Legal Purposes
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Entities For Sales or Transfer of Business or Assets
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "361px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Financial and transactional information&nbsp;
                        </span>
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        <span style={{ fontWeight: 400 }}>
                          such as credit or debit card number, verification number,
                          and expiration date, to process payments and information
                          about your transactions and purchases with us
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>&nbsp;</span>
                      </p>
                    </td>
                    <td style={{ width: "295px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Provide the services
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Communicate with You &amp; Provide Customer Service
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Security, Fraud, and Legal Compliance
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "148.203px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>You&nbsp;</span>
                        <span style={{ fontWeight: 400 }}>
                          Third-party payment processors who collect this
                          information on our behalf and who also have an independent
                          relationship with you
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Third party partners or co-branded partners
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "187px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>Service Providers</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>Our Affiliates</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Third Parties for Legal Purposes
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Third Party For Sales or Transfer of Business or Assets
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "361px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          User-generated content
                        </span>
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        <span style={{ fontWeight: 400 }}>
                          such as comments on articles, photos, videos, audio, any
                          information you submit in public forums or message boards,
                          reviews and feedback or testimonials you provide about our
                          services
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "295px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Provide the services
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Communicate with You &amp; Provide Customer Service
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Personalize Your Experience
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>Editorial Use</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Improve the services
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Bug detection and error reporting
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Security, Fraud, and Legal Compliance
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "148.203px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>You&nbsp;</span>
                        <span style={{ fontWeight: 400 }}>
                          Other users who provide information about you in
                          connection with their event or profile
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "187px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>Service Providers</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>Our Affiliates</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Other Individuals, services, and Partners at Your Request
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Entities for Legal Purposes
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Entities For Sales or Transfer of Business or Assets
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "361px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Research, survey (including personal identifiers and user
                          generated content)
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          collected if you participate in a survey; includes
                          information needed for you to participate (such as contact
                          information), and to fulfill your prize
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "295px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Provide the services
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Communicate with You &amp; Provide Customer Service
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Personalize Your Experience
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Improve the services
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Bug detection and error reporting
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Auditing Consumer Interactions
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Security, Fraud, and Legal Compliance
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "148.203px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>You&nbsp;</span>
                        <span style={{ fontWeight: 400 }}>Survey partners</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Researchers and analysts
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "187px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>Service Providers</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>Our Affiliates</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Entities for Legal Purposes
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Entities For Sales or Transfer of Business or Assets
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "361px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Contact Information about others (including personal
                          identifiers)&nbsp;
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          such as name and mailing address to send a gift or use our
                          send-to-a-friend functionality.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          If you use send-a-friend on our services, your email
                          address may be included in the communication sent to your
                          friend.
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "295px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Provide the services
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Communicate with You &amp; Provide Customer Service
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Improve the services
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Auditing Consumer Interactions
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Security, Fraud, and Legal Compliance
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Our Marketing Purposes
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "148.203px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>You&nbsp;</span>
                        <span style={{ fontWeight: 400 }}>
                          Other users (if you are the recipient of a communication)
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>Our affiliates</span>
                      </p>
                    </td>
                    <td style={{ width: "187px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>Service Providers</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>Our Affiliates</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Other Individuals, services, and Partners at Your Request
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Entities for Legal Purposes
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Entities For Sales or Transfer of Business or Assets
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "361px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Social media information (which may include personal
                          identifiers, photos, location, user generated content,
                          demographic information)
                        </span>
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        <span style={{ fontWeight: 400 }}>
                          if you link your account or access the services through a
                          third-party connection or log-in, we may have access to
                          any information you provide to that social network
                          depending on your privacy settings such as your name,
                          email address, friend list, photo, gender, location, and
                          current city; and information you provide to us directly
                          through our pages on social networking and blogging
                          platforms (e.g., Facebook, Instagram, Snapchat, WordPress,
                          and Twitter)
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>&nbsp;</span>
                      </p>
                    </td>
                    <td style={{ width: "295px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Provide the services&nbsp;You
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Communicate with You &amp; Provide Customer Service
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Personalize Your Experience
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>Editorial Use</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Improve the services
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>Our Marketing&nbsp;</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Bug detection and error reporting
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Auditing Consumer Interactions
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Security, Fraud, and Legal Compliance
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "148.203px" }}>
                      <p style={{ textAlign: "center" }}>&nbsp;</p>
                    </td>
                    <td style={{ width: "187px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>Service Providers</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>Our Affiliates</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Other Individuals, services, and Partners at Your Request
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Entities for Legal Purposes
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Entities For Sales or Transfer of Business or Assets
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "361px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>Inference Data</span>
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        <span style={{ fontWeight: 400 }}>
                          such as information about your potential preferences that
                          we infer from your activities on the services, and
                          information you provide or inference information we
                          receive from other parties
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "295px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Provide the services
                        </span>
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        <span style={{ fontWeight: 400 }}>
                          Communicate with You &amp; Provide Customer Service
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Personalize Your Experience
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Improve the services
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Our Marketing purposes&nbsp;
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "148.203px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>Our affiliates</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Consumer data resellers
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>Analytics Providers</span>
                      </p>
                    </td>
                    <td style={{ width: "187px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>Service Providers</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>Our Affiliates</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Entities for Legal Purposes
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Entities For Sales or Transfer of Business or Assets
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "361px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>Other information</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          any other information you choose to directly provide to
                          MobiCom in connection with your use of the services
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "295px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Provide the services
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Communicate with You &amp; Provide Customer Service
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Personalize Your Experience
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>Editorial Use</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Improve the services
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Our Marketing purposes&nbsp;
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Bug detection and error reporting
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Auditing Consumer Interactions
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Security, Fraud, and Legal Compliance
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "148.203px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>You</span>
                      </p>
                    </td>
                    <td style={{ width: "187px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>Service Providers</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>Our Affiliates</span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Other Individuals, services, and Partners at Your Request
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Entities for Legal Purposes
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Entities For Sales or Transfer of Business or Assets
                        </span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              <span style={{ fontWeight: 400 }}>
                Information we collect automatically from you and/or your device:
              </span>
            </p>
            <div className="table-responsive">
              <table style={{ width: "100%" }} border="1" className="table table-bordered">
                <tbody>
                  <tr>
                    <td style={{ width: "367px" }}>
                      <p>
                        <strong>Categories of information collected</strong>
                      </p>
                    </td>
                    <td style={{ width: "263px" }}>
                      <p>
                        <strong>Purposes of use</strong>
                      </p>
                      <p>
                        <strong>
                          (see “How We Use Personal Information” in our Privacy
                          Policy for additional information)
                        </strong>
                      </p>
                    </td>
                    <td style={{ width: "183px" }}>
                      <p>
                        <strong>Sources of personal information</strong>
                      </p>
                    </td>
                    <td style={{ width: "180px" }}>
                      <p>
                        <strong>
                          Categories of third parties to whom we disclose the
                          information&nbsp;
                        </strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "367px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Device information and identifiers&nbsp;
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          such as IP address; browser type and language; operating
                          system; platform type; device type; software and hardware
                          attributes; and unique device, advertising, and app
                          identifiers
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "263px" }}>
                      <ul>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Provide the services
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Personalize Your Experience
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Improve the services
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Our Marketing Purposes
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Bug detection and error reporting
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Auditing Consumer Interactions
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Security, Fraud, and Legal Compliance
                          </span>
                        </li>
                      </ul>
                    </td>
                    <td style={{ width: "183px" }}>
                      <ul>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            You (through your device)
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Cookies and tracking technologies
                          </span>
                        </li>
                      </ul>
                      <p>
                        <span style={{ fontWeight: 400 }}>&nbsp;</span>
                      </p>
                    </td>
                    <td style={{ width: "180px" }}>
                      <ul>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>Service Providers</span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>Our Affiliates</span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Entities for Legal Purposes
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Entities For Sales or Transfer of Business or Assets
                          </span>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "367px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Internet network and device activity data&nbsp;
                        </span>
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        <span style={{ fontWeight: 400 }}>
                          such as information about files you download, domain
                          names, landing pages, browsing activity, content or ads
                          viewed and clicked, dates and times of access, pages
                          viewed, forms you complete or partially complete, search
                          terms, uploads or downloads, the URL that referred you to
                          our services, the web sites you visit after this web site;
                          if you share our content to social media platforms; and
                          other web usage activity and data logged by our web
                          servers, whether you open an email and your interaction
                          with email content, access times, error logs, and other
                          similar information.
                        </span>
                      </p>
                      <p>
                        <span style={{ fontWeight: 400 }}>&nbsp;</span>
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        <span style={{ fontWeight: 400 }}>See “</span>
                        <span style={{ fontWeight: 400 }}>
                          Cookies and Other Tracking Technologies
                        </span>
                        <span style={{ fontWeight: 400 }}>
                          ” for more information about how we collect and use this
                          information.
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "263px" }}>
                      <ul>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Provide the services
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Personalize Your Experience
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Improve the services
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Our Marketing&nbsp;
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Bug detection and error reporting
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Auditing Consumer Interactions
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Security, Fraud, and Legal Compliance
                          </span>
                        </li>
                      </ul>
                      <p>
                        <span style={{ fontWeight: 400 }}>&nbsp;</span>
                      </p>
                    </td>
                    <td style={{ width: "183px" }}>
                      <ul>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            You (through your device)
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Analytics providers
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Cookies and tracking technologies
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>Our affiliates</span>
                        </li>
                      </ul>
                    </td>
                    <td style={{ width: "180px" }}>
                      <ul>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>Service Providers</span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>Our Affiliates</span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Entities for Legal Purposes
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Entities For Sales or Transfer of Business or Assets
                          </span>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "367px" }}>
                      <p>
                        <span style={{ fontWeight: 400 }}>
                          Geolocation information&nbsp;
                        </span>
                      </p>
                      <p style={{ textAlign: "justify" }}>
                        <span style={{ fontWeight: 400 }}>
                          such as city, state and ZIP code associated with your IP
                          address or derived through Wi-Fi triangulation; and
                          precise geolocation information from GPS-based
                          functionality on your mobile devices, with your permission
                          in accordance with your mobile device settings
                        </span>
                      </p>
                    </td>
                    <td style={{ width: "263px" }}>
                      <ul>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Provide the services
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Personalize Your Experience
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Improve the services
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Our Marketing Purposes
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Bug detection and error reporting
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Auditing Consumer Interactions
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Security, Fraud, and Legal Compliance
                          </span>
                        </li>
                      </ul>
                    </td>
                    <td style={{ width: "183px" }}>
                      <ul>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>You</span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Analytics providers
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>Our affiliates</span>
                        </li>
                      </ul>
                    </td>
                    <td style={{ width: "180px" }}>
                      <ul>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>Service Providers</span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>Our Affiliates</span>
                        </li>
                        <li style={{ fontWeight: 400 }}></li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Entities for Legal Purposes
                          </span>
                        </li>
                        <li style={{ fontWeight: 400 }}>
                          <span style={{ fontWeight: 400 }}>
                            Entities For Sales or Transfer of Business or Assets
                          </span>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Container>
      </section>
    )
  }
  
  export default PrivacyPolicys