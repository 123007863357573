import React from 'react'
import { Form, Modal } from 'react-bootstrap'

const AddClubBankModal = ({ openBankModal, setOpenBankModal }) => {
    return (
        <Modal show={openBankModal} size="lg" onHide={() => { setOpenBankModal(!openBankModal) }}>
            <Modal.Header closeButton>
                <p style={{fontSize:"18px"}}>Bank Details</p>
            </Modal.Header>

            <Form>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-sm-12 col-md-6'>
                            <Form.Group className='mt-3'>
                                <Form.Label>Account Name<sup className='text-danger'>*</sup></Form.Label>
                                <Form.Control type='text' />
                            </Form.Group>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <Form.Group className='mt-3'>
                                <Form.Label>Tax ID/EIN<sup className='text-danger'>*</sup></Form.Label>
                                <Form.Control type='text' />
                            </Form.Group>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <Form.Group className='mt-3'>
                                <Form.Label>Country <sup className='text-danger'>*</sup></Form.Label>
                                <Form.Control type='text' />
                            </Form.Group>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <Form.Group className='mt-3'>
                                <Form.Label>Currency <sup className='text-danger'>*</sup></Form.Label>
                                <Form.Control type='text' />
                            </Form.Group>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <Form.Group className='mt-3'>
                                <Form.Label>Account Number/IBAN <sup className='text-danger'>*</sup></Form.Label>
                                <Form.Control type='text' />
                            </Form.Group>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <Form.Group className='mt-3'>
                                <Form.Label>Confirm Account Number/IBAN* <sup className='text-danger'>*</sup></Form.Label>
                                <Form.Control type='text' />
                            </Form.Group>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <Form.Group className='mt-3'>
                                <Form.Label>Routing Number/Swift Code <sup className='text-danger'>*</sup></Form.Label>
                                <Form.Control type='text' />
                            </Form.Group>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <Form.Group className='mt-3'>
                                <Form.Label>Bank Name <sup className='text-danger'>*</sup></Form.Label>
                                <Form.Control type='text' />
                            </Form.Group>
                        </div>
                        <div className='col-sm-12 col-md-12'>
                            <Form.Group className='mt-3'>
                                <Form.Label>Bank Address <sup className='text-danger'>*</sup></Form.Label>
                                <Form.Control as="textarea" rows={3}/>
                            </Form.Group>
                        </div>
                        <div className='mt-2'>
                            <p>Information about Club’s account controller or administrator:    (Why we need this?)</p>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <Form.Group className='mt-3'>
                                <Form.Label>First Name <sup className='text-danger'>*</sup></Form.Label>
                                <Form.Control type='text' />
                            </Form.Group>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                            <Form.Group className='mt-3'>
                                <Form.Label>Last Name <sup className='text-danger'>*</sup></Form.Label>
                                <Form.Control type='text' />
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
            </Form>
            <Modal.Footer>
                <div className='d-flex justify-content-end'>
                    <button className='btn-type-7'>Add</button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default AddClubBankModal