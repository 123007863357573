import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { ReactComponent as UploadIcon } from "../../assets/images/common/cloud-upload-alt.svg";
import imageToBase64 from 'image-to-base64/browser';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import Select from 'react-select';
import Gallery from './Gallery';
import { CURATED_SPONSORSHIP } from '../../constants/pathname';
import { useNavigate } from 'react-router-dom';
// Rsuite Datepicker
import { DatePicker } from 'rsuite';
import About from './About';
import { RiDeleteBin6Line } from 'react-icons/ri';

const CreatePrivateEvents=() => {
    const [fromDate, setFromDate]=useState(null);
    const [toDate, setToDate]=useState(null);

    const navigate=useNavigate();

    const countryOptions=[{ label: 'United States of America', value: 'usa' }];
    const cityOptions=[{ label: 'Palm Beach, FL, USA', value: 'palm-beach' }];
    const clubTypeOptions=[{ label: 'Yacht Club', value: 'yacht-club' }];
    const statusOptions=[{ label: 'Completed', value: 'completed' }];

    const customStyles={
        control: (provided, state) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            outline: 'none',
            boxShadow: 'none',
            color: 'var(--medium-gray)',
            fontSize: '15px',
            '&:hover': {
                border: '1px solid var(--light-grayish)', // Prevent hover border color
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        menu: (provided) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            boxShadow: 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused? 'var(--light-grayish)':'white',
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
    };


    const formik=useFormik({
        initialValues: {
            images: [],
            countryName: ""
        },
        onSubmit: (values, resetForm) => {

        }
    });

    const handleImageChange=async (e) => {
        const files=Array.from(e?.target?.files);
        const maxSizeInBytes=1*1024*1024;
        const validImageTypes=['image/jpeg', 'image/png'];

        for (let i=0; i<files.length; i++) {
            const file=files[i];

            if (!validImageTypes.includes(file?.type)) {
                toast.error("Only jpg/jpeg and png files are allowed!");
                e.target.value=null;
                return;
            }

            if (file?.size>maxSizeInBytes) {
                toast.error("File size exceeds 2 MB limit", 'error');
                e.target.value=null;
                return;
            }
        }

        const images=await Promise.all(
            files.map(async (file) => {

                try {
                    const response=await imageToBase64(URL.createObjectURL(file));
                    return `data:image/jpeg;base64,${response}`;
                } catch (error) {
                    return null;
                }
            })
        );

        // Setting file name
        let fileName="";
        if (Array.isArray(files)&&files[0]?.name) {
            fileName=files[0].name;
        }

        formik.setValues({
            ...formik.values,
            images: [...images], // Ensure images is an array
            thumbnailName: fileName
        });

        e.target.value=null;
    }

    const removeThumbNail=() => {
        formik.setValues({
            ...formik.values,
            images: [], // Ensure images is an array
            thumbnailName: ""
        });
    }

    return (
        <div className='create-clubs-info-section'>
            <div className='page-details'>
                <div className='club-profile-section'>
                    <div className='panel-type-1'>
                        Add New Private Events
                    </div>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <div className='club-form mt-4'>
                            <div className='item upload'>
                                <label>Upload Thumbnail <span className='required'>*</span></label>
                                <div className='d-flex align-items-end mt-2'>
                                    <div>
                                        {Array.isArray(formik.values?.images)&&!formik.values?.images?.length
                                            ?
                                            <label className='upload-icon cursor-pointer' htmlFor='fileInput'>
                                                <UploadIcon />
                                                <div className='mt-3'>Upload</div>
                                            </label>
                                            :
                                            <div className={`uploaded-images ${Array.isArray(formik.values?.images)&&formik.values?.images?.length>0? 'active':''}`}>
                                                {formik.values.images?.map((image, index) => (
                                                    <div className='pos-rel' key={index}>
                                                        <img key={index} src={image} alt={`Uploaded ${index}`} className="uploaded-image" />
                                                    </div>
                                                ))}
                                            </div>}
                                        {formik?.values?.thumbnailName&&
                                            <div className='filename mt-2 d-flex align-items-center justify-content-between gap-2'>
                                                <div className='name' title={formik?.values?.thumbnailName||""}>{formik?.values?.thumbnailName||""}</div>
                                                <RiDeleteBin6Line size={16} fill='var(--red)' title='Delete' onClick={() => removeThumbNail()} />
                                            </div>}
                                    </div>
                                    <input
                                        id="fileInput"
                                        className='d-none'
                                        type="file"
                                        onChange={handleImageChange}
                                    />
                                    <div>
                                        {Array.isArray(formik.values?.images)&&!formik.values?.images?.length&&
                                            <div className='info'>
                                                Use JPEG or PNG. Best size 1280×720 pixels. Keep it under 1MB
                                            </div>}
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <div className="row">
                                    {/* Facility  Name */}
                                    <div className="col-md-3 mb-3">
                                        <Form.Group controlId="facilityName">
                                            <Form.Label>Facility Name<span className='required'>*</span></Form.Label>
                                            <Select
                                                placeholder="Select"
                                                styles={customStyles}
                                                isClearable
                                                isSearchable
                                            />
                                        </Form.Group>
                                    </div>

                                    {/* Club Name */}
                                    <div className="col-md-3 mb-3">
                                        <Form.Group controlId="clubName">
                                            <Form.Label>Club Name<span className='required'>*</span></Form.Label>
                                            <Select
                                                placeholder="Select"
                                                styles={customStyles}
                                                isClearable
                                                isSearchable
                                            />
                                        </Form.Group>
                                    </div>

                                    {/* Club Type */}
                                    <div className="col-md-3 mb-4">
                                        <Form.Group controlId="clubType">
                                            <Form.Label>Club Type<span className='required'>*</span></Form.Label>
                                            <Select
                                                placeholder="Select"
                                                styles={customStyles}
                                                options={clubTypeOptions}
                                                isClearable
                                                isSearchable
                                            />
                                        </Form.Group>
                                    </div>

                                    {/* City */}
                                    <div className="col-md-3 mb-4">
                                        <Form.Group controlId="city">
                                            <Form.Label>City<span className='required'>*</span></Form.Label>
                                            <Select
                                                placeholder="Select"
                                                styles={customStyles}
                                                options={cityOptions}
                                                isClearable
                                                isSearchable
                                            />
                                        </Form.Group>
                                    </div>

                                    {/* Country */}
                                    <div className="col-md-3 mb-4">
                                        <Form.Group controlId="country">
                                            <Form.Label>Country<span className='required'>*</span></Form.Label>
                                            <div title={formik?.values?.countryName||''}>
                                                <Select
                                                    placeholder="Select"
                                                    styles={customStyles}
                                                    options={countryOptions}
                                                    onChange={(e) => formik.setFieldValue("countryName", e?.label||'')}
                                                    isClearable
                                                    isSearchable
                                                />
                                            </div>
                                        </Form.Group>
                                    </div>

                                    {/* Start Date */}
                                    <div className='col-md-3 mb-4 start-date-input'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1em11">
                                            <Form.Label>Start Date<span className='required'>*</span></Form.Label>
                                            <div className='input-border'>
                                                <DatePicker
                                                    id='start_date'
                                                    onChange={(value) => {
                                                        setFromDate(value);
                                                        if (toDate&&value>toDate) {
                                                            setToDate(null); // Reset "To Date" if it becomes invalid
                                                        }
                                                    }}
                                                    placement="autoVerticalEnd"
                                                />
                                            </div>
                                        </Form.Group>
                                    </div>
                                    {/* End Date */}
                                    <div className='col-md-3 mb-4 end-date-input'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1em4">
                                            <Form.Label>End Date<span className='required'>*</span></Form.Label>
                                            <div className='input-border'>
                                                <DatePicker
                                                    id='end_date'
                                                    onChange={(value) => setToDate(value)}
                                                    placement="autoVerticalEnd"
                                                    shouldDisableDate={(date) => {
                                                        // Disable dates before the selected Start Date
                                                        return fromDate? date<fromDate:false;
                                                    }}
                                                />
                                            </div>
                                        </Form.Group>
                                    </div>

                                    {/* Status */}
                                    <div className="col-md-3 mb-4">
                                        <Form.Group controlId="status">
                                            <Form.Label>Status<span className='required'>*</span></Form.Label>
                                            <Select
                                                placeholder="Select"
                                                styles={customStyles}
                                                options={statusOptions}
                                                isClearable
                                                isSearchable
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* About Club contains about event, inclusions, onboard experiences */}
                        <About />

                        {/* Gallery */}
                        <Gallery />
                        <div className='submit-box d-flex align-items-center justify-content-end gap-3'>
                            <button className='btn-type-2 cursor-pointer' onClick={() => navigate(CURATED_SPONSORSHIP)}>Cancel</button>
                            <button className='btn-type-1' type="submit" onClick={() => navigate(CURATED_SPONSORSHIP)}>Submit</button>
                        </div>
                    </Form>

                </div>
            </div>
        </div>
    )
}

export default CreatePrivateEvents