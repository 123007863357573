export const checkThemePcw = () => {
    return true;
    if (window.location.origin == '' || window.location.origin == '') {
        return true
    }
    else {
        return true
    }
}
export const checkTheme = () => {
    if (window.location.origin == 'https://club.platinumclubnet.com' || window.location.origin == 'https://pcn-cms.aventusinformatics.com') {
        return true
    }
    else {
        return false        
    }
}
export const PCW_URL = 'https://club.privateclubworld.com'
export const PCN_URL = 'https://club.platinumclubnet.com'

export const switchApp = (token, refresh) => {
    const baseUrl = checkTheme()
        ? "https://member.platinumclubnet.com/login"
        : "https://member.privateclubworld.com/login"
  
    window.location.href = `${baseUrl}?token=${token}&refresh=${refresh}`;
  };
  
