import React from 'react'
import CreateClubProfile from '../../components/CreateClubProfile/CreateClubProfile'

const CreateClubProfilePage = () => {

  return (
    <div className='create-clubs-profile-page create-clubs-info-section'>      
      <CreateClubProfile />
    </div>
  )
}

export default CreateClubProfilePage