import React, { useRef, useState } from 'react'
import {useDropzone} from 'react-dropzone';

const Gallery = () => {
    const [imageFiles, setImageFiles] = useState([]); // State to store uploaded images

    const [videoUrl, setVideoUrl] = useState(""); // State for input value
    const fileInputRef = useRef(null); // Ref for hidden file input

    // Handle file selection
    const handleFileUpload = (e) => {
        const file = e.target.files[0]; // Get the selected file
        if (file) {
        const fileURL = URL.createObjectURL(file); // Create a temporary URL for the file
        setVideoUrl(fileURL); // Update input value with the file's URL
        }
    };

    // Trigger file input when upload button is clicked
    const triggerFileInput = () => {
        fileInputRef.current.click(); // Open file picker dialog
    };

    // Handle manual input change
    const handleInputChange = (e) => {
        setVideoUrl(e.target.value); // Update input with manual URL
    };

    // Multiple image files
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        accept: {
            'image/*': ['.jpeg', '.pjpeg', '.png']
            },
        multiple: true, // Allow multiple files
        onDrop: (acceptedFiles) => {
        // Add new files to the state and create previews
        const updatedFiles = [
            ...imageFiles,
            ...acceptedFiles.map((file) => ({
            file,
            preview: URL.createObjectURL(file), // Create image preview
            })),
        ];
        setImageFiles(updatedFiles);
        },
    });

    // Function to remove a specific image
    const removeImage = (index) => {
        const updatedFiles = imageFiles.filter((_, i) => i !== index);
        setImageFiles(updatedFiles);
    };

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
          {file.path} - {file.size} bytes
        </li>
    ));
    
    const styles = {       
        preview: {
          position: "relative",
          minWidth: "100px" ,
          height: "100px",
          border: "1px solid #EAE9EB",
          borderRadius: "5px",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        image: {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
        removeButton: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'start',
          position: "absolute",
          top: "5px",
          right: "5px",
          background: "#707070",
          border: "none",
          color: "#fff",
          borderRadius: "50%",
          width: "20px",
          height: "20px",
          cursor: "pointer",
          fontSize: "16px",
          lineHeight: "18px",
          textAlign: "center",
        },
      };
    return (
        <div className='gallery-section mt-5'>
            <div className='panel-type-1'>
                Gallery
            </div>
            <div className='gallery-details mt-4'>
                {/* Multiple images upload */}
                <div className='multiple-images-upload'>
                    <div className='top'>
                        Multiple Images Upload
                    </div>
                    <div className='bottom'>
                        <div className='drag-box'>
                            <div {...getRootProps({className: 'dropzone cursor-pointer'})}>
                                <input {...getInputProps()} />
                                <p>Drag & Drop your file or Browse</p>
                            </div>
                            <aside className='d-flex flex-wrap gap-2'>
                                {/* <ul>{files}</ul> */}
                                {imageFiles.map((image, index) => (
                                    <div key={index} style={styles.preview}>
                                        <img
                                            src={image.preview}
                                            alt={`preview-${index}`}
                                            style={styles.image}
                                        />
                                        {/* Close button */}
                                        <button
                                            style={styles.removeButton}
                                            onClick={() => removeImage(index)}
                                        >
                                            &times;
                                        </button>
                                    </div>
                                    ))}
                            </aside>
                        </div>
                    </div>
                </div>
                <div className='info-msg mt-2'>Maximum file size is 1 MB</div>
            </div>
        </div>
    )
}

export default Gallery