import React, { useState } from 'react'
import { Button, Form, Row, Col } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const Clubinformations = ({  setValue, clubProfileDetails, formik }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Validate the form fields
  const validateForm = () => {
    const errors = {};

    if (!formik.values.club_name) {
      errors.club_name = 'Club Name is required';
    }

    if (!formik.values.first_name) {
      errors.first_name = 'First Name is required';
    }

    if (!formik.values.contact_number) {
      errors.contact_number = 'Contact Number is required';
    }

    if (!formik.values.password) {
      errors.password = 'Password is required';
    } else if (formik.values.password.length < 8) {
      errors.password = 'Password must be at least 8 characters';
    }

    if (!formik.values.confirm_password) {
      errors.confirm_password = 'Confirm Password is required';
    } else if (formik.values.password !== formik.values.confirm_password) {
      errors.confirm_password = 'Passwords must match';
    }

    // Set errors in Formik
    formik.setErrors(errors);

    // Return true if no errors
    return Object.keys(errors).length === 0;
  };

  // Handle the "Next" button click
  const handleNext = () => {
    if (validateForm()) {
      // Navigate to the next tab (PackageInclusions)
      setValue(2);
    }
  };

  
    return (
        <div>
            <h3 className="mb-3" style={{ fontSize: "20px", fontWeight: "400", lineHeight: "25px" }}>
                Club Information
            </h3>
            <Form 
                style={{ 
                    border: "1px solid #c58f5a", 
                    padding: "20px", 
                    borderRadius: "5px",
                    borderColor: "#c58f5a",
                    boxShadow: "none",
                    background: "transparent",
                }}
            >
               <Row className="mb-3">
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "16px" }}>Club Name <span className='required'>*</span></Form.Label>
              <Form.Control
                name="club_name"
                value={formik.values.club_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.club_name && !!formik.errors.club_name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.club_name}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "16px" }}>Country</Form.Label>
              <Form.Control
                name="country"
                value={formik.values.country_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled
                isInvalid={formik.touched.country && !!formik.errors.country}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.country}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        {/* State and City */}
        <Row className="mb-3">
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "16px" }}>State</Form.Label>
              <Form.Control
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                disabled
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.state}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "16px" }}>City<span className='required'>*</span></Form.Label>
              <Form.Control
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled
                isInvalid={formik.touched.city && !!formik.errors.city}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.city}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        {/* First Name and Last Name */}
        <Row className="mb-3">
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "16px" }}>First Name<span className='required'>*</span></Form.Label>
              <Form.Control
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.first_name && !!formik.errors.first_name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.first_name}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "16px" }}>Last Name</Form.Label>
              <Form.Control
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.last_name && !!formik.errors.last_name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.last_name}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        {/* Email and Contact Number */}
        <Row className="mb-3">
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "16px" }}>Contact Email <span className='required'>*</span></Form.Label>
              <Form.Control
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.email && !!formik.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "16px" }}>Contact Number</Form.Label>
              <Form.Control
              type='number'
                name="contact_number"
                value={formik.values.contact_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.contact_number && !!formik.errors.contact_number}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.contact_number}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        {/* Password and Confirm Password */}
        <Row className="mb-3">
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "16px" }}>
                Password <span className='required'>*</span>
              </Form.Label>
              <div className="position-relative">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.password && !!formik.errors.password}
                />
                <span
                  className={`position-absolute end-0 translate-middle-y me-3 toggle_password_eye_icon ${formik.errors.password ? "toggle_password_eye_icon_open" : "toggle_password_eye_icon_close"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ?  <FaEye /> :<FaEyeSlash />}
                </span>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
          </Col>

          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              <Form.Label style={{ fontSize: "16px" }}>
                Confirm Password <span className='required'>*</span>
              </Form.Label>
              <div className="position-relative">
                <Form.Control
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirm_password"
                  value={formik.values.confirm_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.confirm_password && !!formik.errors.confirm_password}
                />
                <span
                  className={`position-absolute end-0 translate-middle-y me-3 toggle_password_eye_icon ${formik.errors.confirm_password ? "toggle_password_eye_icon_open" : "toggle_password_eye_icon_close"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <FaEye />:<FaEyeSlash />  }
                </span>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.confirm_password}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
          </Col>
        </Row>

        {/* Button Section */}
             <div className="d-flex justify-content-between" style={{marginTop:"30px"}}>
                    <Button
                      variant="dark"
                      style={{
                        backgroundColor: "#c58f5a",
                        color: "white",
                        border: "none",
                        padding: "10px 20px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                      onClick={() => setValue(0)}
                    >
                      Back
                    </Button>
                    <Button
              variant="dark"
              style={{
                backgroundColor: "#c58f5a",
                border: "none",
                fontSize: "14px",
                padding: "8px 20px"
              }}
              onClick={handleNext}
            >
              NEXT
            </Button>
                  </div>
      
                {/* Button Section: Center on small screens, right-aligned on medium screens */}
               
            </Form>
        </div>
    );
}

export default Clubinformations
