import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
 
const BulkUpdateAlert = ({ showSweetBulkAlert, setShowBulkAlert, content, loadingAlert }) => {
    return (
        <div>
            {showSweetBulkAlert &&
                <SweetAlert
                    warning
                    showCancel
                    customClass='bulk-update-alert'
                    confirmBtnText={content ? 'OK' : 'Yes'}
                    cancelBtnText="Cancel"
                    confirmBtnCssClass={`custom-confirm-button-class ${loadingAlert ? 'disabled' : ''}`}
                    cancelBtnCssClass={`custom-cancel-button-class ${content ? 'd-none' : ''}`}
                    title={<span className="custom-sweetalert-title">{content || 'Are you sure you want to update?'}</span>}
                    onConfirm={() => setShowBulkAlert(true)}
                    onCancel={() => setShowBulkAlert(false)}
                    focusCancelBtn
                />}
    </div>
    );
}

export default BulkUpdateAlert;
