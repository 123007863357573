import React from 'react'
import Roles from '../../components/UserManagement/Roles';

const Index = () => {
  return (
    <div className='user_management'>
        <Roles/>
    </div>
  )
}

export default Index;