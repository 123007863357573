import React from 'react'
import { Container, Card } from "react-bootstrap";
import { checkTheme } from '../../utils/utils';

const Promotions=() => {
    return (
        <div className='manage-members-page cur'>
            <div className='manage-members member-stats-section'>
                <div className='page-details'>
                    <div className='top-section'>
                        <div className='left'>
                            Promotions
                        </div>
                    </div>
                    <div className="promote-program-page">
                    <Container className="mt-4 pb-5">
                        {checkTheme() ? <span className=' pt-4 '>Would you like to promote your club's membership plan to members on the PlatinumClubNet</span>
                            :<Card className="border-0 shadow-sm">
                                <Card.Body>
                                    <p>
                                    The Promote Program is an optional service for the Club to choose if it requires MobiCom to promote the Club’s membership plans, events, activities, and packages to a global network of private club members.
                                    </p>

                                    <h5 className="mt-3" style={{ fontWeight: 500 }}>Inclusions:</h5>
                                    <ul className="mt-2" style={{ listStyle: "auto", paddingLeft: "40px" }}>
                                    <li>Services of PrivateClubWorld™ Promotion Specialists</li>
                                    <li>Club packages and membership plans published on PrivateClubWorld™ member apps</li>
                                    <li>Promotions of the club packages and membership plans into PrivateClubWorld™ Ecosystem</li>
                                    </ul>

                                    <h5 className="mt-4" style={{ fontWeight: 500 }}>Payment and Other Terms:</h5>
                                    <ul className="mt-2" style={{ listStyle: "auto", paddingLeft: "40px" }}>
                                    <li>
                                        Upon listing the promotion package and/or membership plan by the Club on the CMS, PrivateClubWorld Promotion Specialists will engage with the Club to finalize options, pricing, rules, inclusions, benefits, and terms. The package and/or membership plan will only be published and promoted once terms and conditions are mutually agreed and executed.
                                    </li>
                                    <li>
                                        All transactions related to the Promote Program with the Club for booking and settlement by members and/or partners in the PrivateClubWorld™ network will be routed through MobiCom, and MobiCom will settle all payments to the Club.
                                    </li>
                                    <li>
                                        The Club is required to list their requirement(s) at least 90 (ninety) days prior to the expected date of rendering the service.
                                    </li>
                                    <li>
                                        While MobiCom offers these services on a best effort basis, it does not guarantee delivery of service for any request listed by the Club.
                                    </li>
                                    <li>
                                        Such additional terms as may be imposed by MobiCom from time to time, with reasonable notice to the Club.
                                    </li>
                                    <li>
                                        The annual activation fee shall be payable prior to publishing and/or promoting the Club program or membership plans on the PrivateClubWorld™ Ecosystem.
                                    </li>
                                    <li>
                                        This activation fee is valid for a period of 12 (twelve) months from the date of publishing by MobiCom, irrespective of the actual date of first rendering the service.
                                    </li>
                                    <li>
                                        A service fee of 10% (ten percent) is charged for each transaction made as part of the Promote Program.
                                    </li>
                                    <li>
                                        For any listed promotion to be serviced by MobiCom beyond 365 (three hundred and sixty-five) days of the date of the first promotion on the Ecosystem, an annual renewal fee of USD 500 (Five Hundred United States Dollars Only) will be charged by MobiCom and shall be payable on the date of promoting on the Ecosystem by MobiCom.
                                    </li>
                                    <li>
                                        No additional renewal fee shall be payable on rendering the service within a 365-day period from the date of promoting on the Ecosystem.
                                    </li>
                                    <li>
                                        Upon renewal, a service fee of 10% (ten percent) is charged for each transaction made as part of the Promote Program.
                                    </li>
                                    </ul>
                                </Card.Body>
                            </Card>}
                    </Container>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Promotions