import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { CLUB_ONBOARDING_LIST } from '../../constants/pathname'
import { useNavigate, useParams } from 'react-router-dom'
import { getSpecialSubscriber } from '../../api/actions/specialUser'

const ViewMemberInfo = () => {
    const [memberDetails, setMemberDetails] = useState(null)
     const navigate = useNavigate();
     const {id} =useParams()
     useEffect(()=>{
        if(id){
            getSpecialSubscriber({id})((response)=>{
                if(response.status){
                    setMemberDetails(response.data);
                    
                }
            })
        }
     },[])
     const subscribe_types = [
        { label: 'MSR With LG', value: 'MSR_with_lg' },
        { label: 'MSR Without LG', value: 'MSR_without_lg' },

    ]
    const getSubscriberTypeLabel = (typeValue) => {
        const type = subscribe_types.find((item) => item.value === typeValue);
        return type ? type.label : '';
    }
    return (
        <div className='create-clubs-info-section view-memebr-request'>
            <div className='page-details'>
                <div className='panel-type-1'>
                    View Member

                </div>
                <Form>
                    <div className='club-form mt-5'>
                        <div className='mt-5'>
                            <div className='row'>

                                <div className="col-xl-3 col-md-4 mb-3">
                                    <Form.Group controlId="clubName">
                                        <Form.Label>Club Name</Form.Label>
                                        <Form.Control
                                            type="text"

                                            placeholder="Club Name"
                                            name='club_name'
                                            value={memberDetails?.club_name}
                                            disabled={true}
                                        />

                                    </Form.Group>
                                </div>
                                <div className="col-xl-3 col-md-4 mb-3">
                                    <Form.Group controlId="clubName">
                                        <Form.Label>Prefix </Form.Label>
                                        <Form.Control
                                            type="text"

                                            placeholder="Club Name"
                                            name='club_name'
                                            value={memberDetails?.prefix_type}
                                            disabled={true}
                                        />

                                    </Form.Group>
                                </div>
                                <div className="col-xl-3 col-md-4 mb-3">
                                    <Form.Group controlId="clubName">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"

                                            placeholder="Club Name"
                                            name='club_name'
                                            value={memberDetails?.first_name}
                                            disabled={true}
                                        />

                                    </Form.Group>
                                </div>
                                <div className="col-xl-3 col-md-4 mb-3">
                                    <Form.Group controlId="clubName">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"

                                            placeholder="Club Name"
                                            name='club_name'
                                            value={memberDetails?.last_name}
                                            disabled={true}
                                        />

                                    </Form.Group>
                                </div>
                                <div className="col-xl-3 col-md-4 mb-3">
                                    <Form.Group controlId="clubName">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="text"

                                            placeholder="Club Name"
                                            name='club_name'
                                            value={memberDetails?.email}
                                            disabled={true}
                                        />

                                    </Form.Group>
                                </div>
                                <div className="col-xl-3 col-md-4 mb-3">
                                    <Form.Group controlId="clubName">
                                        <Form.Label>Special Subscriber Type</Form.Label>
                                        <Form.Control
                                            type="text"

                                            placeholder="Club Name"
                                            name='club_name'
                                            value={getSubscriberTypeLabel(memberDetails?.special_subscriber_type)}
                                            disabled={true}
                                        />

                                    </Form.Group>
                                </div>
                                <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-5'>
                                    <div className='btn-type-2 cursor-pointer' onClick={() => navigate(CLUB_ONBOARDING_LIST)}>Back</div>


                                </div>


                            </div>

                        </div>

                    </div>
                </Form>

            </div>

        </div>
    )
}

export default ViewMemberInfo
