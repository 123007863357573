import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import Select from "react-select"
// Modal
import Modal from 'react-bootstrap/Modal';
// Mui
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const NotificationDetail=() => {
    const [show, setShow]=useState(false);
    const [activeStep, setActiveStep]=React.useState(2);
    const [expandedSteps, setExpandedSteps] = useState([true, true]);

    const handleClose=() => setShow(false);
    const handleShow=() => setShow(true);

    let adminOptions=[{ label: "George", value: 1 }, { label: "Tom", value: 2 }, { label: "Roy", value: 3 }];

    const steps=[
        {
            label: 'Modified by Club',
            description: 
                <div>
                    <div><span>Requested On:</span> 10 Feb 2025</div>
                    <div><span>Requested Date(s):</span> Feb 10, 2025 - Feb 28, 2025</div>
                    <div><span>Arrival time:</span> 12:00 pm</div>
                    <div><span>Number of Guest(s):</span> 3</div>
                </div>,
        },
        {
            label: 'Orignal Member Request',
            description:
                <div>
                    <div><span>Requested On:</span> 10 Feb 2025</div>
                    <div><span>Requested Date(s):</span> Feb 10, 2025 - Feb 20, 2025</div>
                    <div><span>Arrival time:</span> 12:00 pm</div>
                    <div><span>Number of Guest(s):</span> 1</div>
                </div>
        }        
    ];

    const handleNext=() => {
        setActiveStep((prevActiveStep) => prevActiveStep+1);
    };

    const handleBack=() => {
        setActiveStep((prevActiveStep) => prevActiveStep-1);
    };

    const handleReset=() => {
        setActiveStep(0);
    };
    return (
        <div className='notification-details-section'>
            {/* Important instructions modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Important Instructions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ol>
                        <li className='mb-2'>In case there are additional charges incurred by the member during the visit, please add the additional charges against this booking.</li>
                        <li className='mb-2'>Please note that if additional charges are not updated within 7 days from the member’s visit, this booking will be automatically closed.</li>
                        <li className='mb-2'>If you offer tee-time reservation at your club and would like to propose changes to a request for a tee-time received from a member, please navigate to "Messages" to communicate with the member.</li>
                    </ol>
                </Modal.Body>
            </Modal>
            <div className='create-clubs-info-section view-memebr-request'>
                <Form>
                    <div className='page-details'>
                        <div className='panel-type-1 mt-4'>
                            Access Request Details
                        </div>
                        <div className='club-form'>
                            <div className='mt-4'>
                                <div className='row'>
                                    {/* Member Name */}
                                    <div className="col-xl-3 col-md-4 mb-3">
                                        <Form.Group controlId="memberName">
                                            <Form.Label>Member Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value="Sal test"
                                                name='memberName'
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </div>
                                    {/* Request Type */}
                                    <div className="col-xl-3 col-md-4 mb-3">
                                        <Form.Group controlId="requestType">
                                            <Form.Label>Request Type</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value="Member Access Request"
                                                name='requestType'
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </div>
                                    {/* Request ID */}
                                    <div className="col-xl-3 col-md-4 mb-3">
                                        <Form.Group controlId="requestId">
                                            <Form.Label>Request ID</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value="RFR0GE0000002778"
                                                name='requestId'
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </div>
                                    {/* Requested On */}
                                    <div className="col-xl-3 col-md-4 mb-3">
                                        <Form.Group controlId="requestedOn">
                                            <Form.Label>Requested On</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value="16 Feb 2025"
                                                name='requestedOn'
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </div>
                                    {/* Requested Date(s) */}
                                    <div className="col-xl-3 col-md-4 mb-3">
                                        <Form.Group controlId="requestedDates">
                                            <Form.Label>Requested Date(s)</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value="Feb 24, 2025 - Feb 24, 2025"
                                                name='requestedDates'
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </div>
                                    {/* Arrival Time */}
                                    <div className="col-xl-3 col-md-4 mb-3">
                                        <Form.Group controlId="arrivalTime">
                                            <Form.Label>Arrival Time</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value="05:05 PM"
                                                name='arrivalTime'
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </div>
                                    {/* Number of Guest(s) */}
                                    <div className="col-xl-3 col-md-4 mb-3">
                                        <Form.Group controlId="numberOfGuests">
                                            <Form.Label>Number of Guest(s)</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value="9"
                                                name='numberOfGuests'
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </div>
                                    {/* Status */}
                                    <div className="col-xl-3 col-md-4 mb-3">
                                        <Form.Group controlId="status">
                                            <Form.Label>Status</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value="Member Cancelled"
                                                name='status'
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                {/* Request for letter of introduction */}
                                <div className='buttonletter-container'>
                                    <button type="button" class="custom-button btn btn-primary">  View Letter of Introduction</button>
                                </div>
                                <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-5'>
                                    {/* <div className='btn-type-2 cursor-pointer' onClick={() => navigate(CLUB_ONBOARDING_LIST)}>Back</div> */}
                                </div>
                            </div>
                        </div>
                        <div className='panel-type-1'>
                            Assign To
                        </div>
                        <div className='row mt-4'>
                            <div className='col-md-4'>
                                <Select
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 5,
                                        colors: {
                                            ...theme.colors,
                                        },
                                    })}
                                    options={adminOptions}
                                    placeholder="Select"
                                    isSearchable
                                    isClearable={true}
                                />
                            </div>
                        </div>
                        <div className='panel-type-1 mt-5'>
                            Billing
                        </div>
                        <div className='club-form'>
                            <div className='mt-4'>
                                <div className='row'>
                                    <div className="col-xl-3 col-md-4 mb-3">
                                        <Form.Group controlId="advancePayment">
                                            <Form.Label>Advance Payment</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="advancePayment"
                                                value="USD 0.00"
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </div>

                                    <div className="col-xl-3 col-md-4 mb-3">
                                        <Form.Group controlId="additionalCharges">
                                            <Form.Label>Additional Charges</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="additionalCharges"
                                                value="USD 0.00"
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </div>

                                    <div className="col-xl-3 col-md-4 mb-3">
                                        <Form.Group controlId="total">
                                            <Form.Label>Total</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="total"
                                                value="USD 0.00"
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='important-instructions' onClick={handleShow}>
                                    *Important Instructions
                                </div>
                            </div>
                        </div>
                        <div className='panel-type-1 mt-5'>
                            History
                        </div>
                        <div className='mt-4'>
                            <Box sx={{ maxWidth: 400 }}>
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    {steps.map((step, index) => (
                                        <Step key={step.label} expanded={expandedSteps[index]}>
                                            <StepLabel>
                                                {step.label}
                                            </StepLabel>
                                            <StepContent>
                                                <Typography>{step.description}</Typography>
                                            </StepContent>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default NotificationDetail;