import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { ReactComponent as UploadIcon } from "../../../assets/images/common/cloud-upload-alt.svg";
import { ReactComponent as PlusIcon } from "../../../assets/images/common/plus-circle-line.svg";
import imageToBase64 from 'image-to-base64/browser';
import toast, { Toaster } from 'react-hot-toast';
import { useFormik } from 'formik';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { CLUBLISTING, MOBICOM_WEB } from '../../../constants/pathname';
import { createAddClub, getClubDetails } from '../../../api/actions/club';
import { Switch } from '@mui/material';




const AddClub = () => {
    const navigate = useNavigate();
    const editorContainerRef = useRef(null);
    const editorRef = useRef(null);
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(false);
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            outline: 'none',
            boxShadow: 'none',
            color: 'var(--medium-gray)',
            fontSize: '15px',
            '&:hover': {
                border: '1px solid var(--light-grayish)', // Prevent hover border color
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        menu: (provided) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            boxShadow: 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--light-grayish)' : 'white',
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
    };


    const validationSchema = Yup.object({
        club_name: Yup.string().required('Required'),
        // club_type: Yup.string().required('Required'),
        // club_category: Yup.string().required('Required'),
         club_website: Yup.string().required('Required'),
    
        
        
    });

   
    const clubTypeOptions = [{ label: 'Golf Clubs', value: 'Golf Clubs' }, { label: 'Country Clubs', value: 'Country Clubs' }, { label: 'City Clubs', value: 'City Clubs' }, { label: 'Athletic Clubs', value: 'Athletic Clubs' }, { label: 'Yacht Clubs', value: 'Yacht Clubs' }];
    const yesNoOptions = [{ label: 'Honorable Mention', value: 'Honorable Mention' }, { label: 'Licensee', value: 'Licensee' }];
    const category = [
        { label: "Platinum Clubs of America", value: "Platinum Clubs of America" },
        { label: "Platinum Clubs of the World", value: "Platinum Clubs of the World" },
        { label: "Platinum Clubs of Europe and Middle East", value: "Platinum Clubs of Europe and Middle East" },
        { label: "Platinum Clubs of Asia-Pacific", value: "Platinum Clubs of Asia-Pacific" },


    ]
    const handleImageChange = async (e) => {

        const files = Array.from(e?.target?.files);
        const maxSizeInBytes = 1 * 1024 * 1024;
        const validImageTypes = ['image/jpeg', 'image/png'];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (!validImageTypes.includes(file?.type)) {
                toast.error("Only jpg/jpeg and png files are allowed!");
                e.target.value = null;
                return;
            }

            if (file?.size > maxSizeInBytes) {
                toast.error("File size exceeds 2 MB limit", 'error');
                e.target.value = null;
                return;
            }
        }

        const images = await Promise.all(
            files.map(async (file) => {

                try {
                    const response = await imageToBase64(URL.createObjectURL(file));
                    return `data:image/jpeg;base64,${response}`;
                } catch (error) {
                    return null;
                }
            })
        );

        // Setting file name
        let fileName = "";
        if (Array.isArray(files) && files[0]?.name) {
            fileName = files[0].name;
        }

        formik.setValues({
            ...formik.values,
            images: [...images], // Ensure images is an array
            thumbnailName: fileName
        });

        e.target.value = null;
    }

    const removeThumbNail = () => {
        formik.setValues({
            ...formik.values,
            images: [], // Ensure images is an array
            thumbnailName: ""
        });
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            club_name: '',
            club_type: '',
            select_club_Type: [],
            club_category: '',
            select_club_category: [],
            club_website: '',
            images: [],
            honorable_mention: '',
            city: '',
            select_city: [],
            state: '',
            select_state: [],
            country: '',
            select_country: [],
        },
        validationSchema,
        onSubmit: (values) => {
            if (isLoading) return;
        
            setIsLoading(true);
        
            const payload = {
                ...values,
                image: values.images.length > 0 ? values.images[0] : '', 
            };
        
            if (id) {
                payload.id = id;
            }
        
            createAddClub({ values: payload })((response) => {
                setIsLoading(false);
        
                if (response?.status && response?.status!==400) {
                    
                    toast.success(id ? 'Successfully Updated!' : 'Successfully Created!');
                    navigate(MOBICOM_WEB);
                } else if (response?.status_code === 400) {
                 
                    const errorMessage = response?.data?.errors?.club_website?.[0] || 'An error occurred. Please try again.';
                    toast.error(errorMessage);
        
                   
                    formik.setErrors({
                        ...formik.errors,
                        club_website: response?.data?.errors?.club_website || [],
                    });
                } else {
                    
                    const errorMessage = response?.message || 'An error occurred. Please try again.';
                    toast.error(errorMessage);
                }
            });
        }
        
    });
    

    const handleChange = (e, name, selected_name) => {
        if (e) {
            formik.setValues({
                ...formik.values,
                [name]: e.value,
                [selected_name]: e,

            })
        } else {
            formik.setValues({
                [name]: null,
                [selected_name]: [],
            })
        }
    }
    useEffect(() => {
        if (id) {
            getClubDetails({ id })((response) => {
                if (response?.status) {
                    formik.setValues({
                        ...formik.values,
                        club_name:response?.data?.club_name,
                        club_website:response?.data?.club_website,
                        country:response?.data?.country,
                        state:response?.data?.state,
                        city:response?.data?.city,
                        images: response?.data?.image ? [response?.data?.image] : [],
                        club_category:response?.data?.club_category,
                        select_club_category: category?.find((item) => item?.value === response?.data?.club_category),
                        club_type:response?.data?.club_type,
                        select_club_Type: clubTypeOptions?.find((item) => item?.value === response?.data?.club_type),

                        
                    })
                }

            })
        }
    }, [id])

    const handleChecked3 = (e) => {
        formik.setValues({
            ...formik.values,

            is_staff: e.target.checked
        });
    };
    return (
        <div className='create-clubs-info-section'>
           
            <div className='club-profile-section'>
                <div className='panel-type-1'>
                    Add club
                </div>
                <Form onSubmit={formik.handleSubmit}>
                    <div className='club-form mt-5'>
                    <div className='item upload'>
                            <label>Upload Thumbnail <span className='required'>*</span></label>
                            <div className='d-flex align-items-end mt-2'>
                                <div>
                                    {Array.isArray(formik.values?.images) && formik.values?.images?.length > 0 ? (
                                        <div className='uploaded-images active'>
                                            {formik.values.images.map((image, index) => (
                                                <div className='pos-rel' key={index}>
                                                    <img src={image} alt={`Uploaded ${index}`} className="uploaded-image" />
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <label className='upload-icon cursor-pointer' htmlFor='fileInput'>
                                            <UploadIcon />
                                            <div className='mt-3'>Upload</div>
                                        </label>
                                    )}
                                    {formik?.values?.thumbnailName && (
                                        <div className='filename mt-2 d-flex align-items-center justify-content-between gap-2'>
                                            <div className='name' title={formik?.values?.thumbnailName || ""}>
                                                {formik?.values?.thumbnailName || ""}
                                            </div>
                                            <RiDeleteBin6Line size={16} fill='var(--red)' title='Delete' onClick={removeThumbNail} />
                                        </div>
                                    )}
                                </div>
                                <input
                                    id="fileInput"
                                    className='d-none'
                                    type="file"
                                    onChange={handleImageChange}
                                />
                                {Array.isArray(formik.values?.images) && !formik.values?.images?.length && (
                                    <div className='info'>
                                        Use JPEG or PNG. Best size 1280×720 pixels. Keep it under 1MB
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='mt-5'>
                            <div className="row">

                                <div className="col-xl-3 col-md-4 mb-3">
                                    <Form.Group controlId="clubName">
                                        <Form.Label>Club Name<span className='required'>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            className='dotted-input-half'
                                            placeholder="Club Name"
                                            name='club_name'
                                            value={formik.values?.club_name}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                formik.setFieldValue(
                                                    'club_name',
                                                    value.charAt(0).toUpperCase() + value.slice(1)
                                                );
                                            }}
                                            isInvalid={formik.touched.club_name && !!formik.errors.club_name}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik.errors.club_name}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-xl-3 col-md-4 mb-4">
                                    <Form.Group controlId="status">
                                        <Form.Label> Club Category<span className='required'>*</span></Form.Label>
                                        <Select
                                            styles={customStyles}
                                            options={category}
                                            placeholder="Select Category"
                                            name='club_category'
                                            value={formik.values?.select_club_category}
                                            onChange={(e) => handleChange(e, 'club_category', 'select_club_category')}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik.errors.club_category}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-xl-3 col-md-4 mb-4">
                                    <Form.Group controlId="status">
                                        <Form.Label>Club Type<span className='required'>*</span></Form.Label>
                                        <Select
                                            styles={customStyles}
                                            options={clubTypeOptions}
                                            placeholder="Select Club Type"
                                            name='club_type'
                                            value={formik.values?.select_club_Type}
                                            onChange={(e) => handleChange(e, 'club_type', 'select_club_Type')}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik.errors.club_type}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>

                                <div className="col-xl-3 col-md-4 mb-4">
                                    <Form.Group controlId="clubWebsite">
                                        <Form.Label>Club Website<span className='required'>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            className='dotted-input-half'
                                            placeholder="Club Website"
                                            name='club_website'
                                            value={formik.values?.club_website}
                                            onChange={formik.handleChange}
                                            isInvalid={formik.touched.club_website && !!formik.errors.club_website}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik.errors.club_website}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col-xl-3 col-md-4 mb-4">
                                    <Form.Group controlId="country">
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className='dotted-input-half'
                                            placeholder="Country"
                                            name='country'
                                            value={formik.values?.country}
                                            onChange={formik.handleChange}
                                            isInvalid={formik.touched.country && !!formik.errors.country}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-xl-3 col-md-4 mb-4">
                                    <Form.Group controlId="city">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className='dotted-input-half'
                                            placeholder="State"
                                            name='state'
                                            value={formik.values?.state}
                                            onChange={formik.handleChange}
                                            isInvalid={formik.touched.state && !!formik.errors.state}
                                        />
                                    </Form.Group>
                                </div>
                                {/* City */}
                                <div className="col-xl-3 col-md-4 mb-4">
                                    <Form.Group controlId="city">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className='dotted-input-half'
                                            placeholder="City"
                                            name='city'
                                            value={formik.values?.city}
                                            onChange={formik.handleChange}
                                            isInvalid={formik.touched.city && !!formik.errors.city}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-xl-3 col-md-4 mb-4">
                                    <Form.Group controlId="country">
                                        <Form.Label>Rank</Form.Label>
                                        <Form.Control
                                            type="number"
                                            className='dotted-input-half'
                                            placeholder="Rank"
                                            name='country'
                                            value={formik.values?.country}
                                            onChange={formik.handleChange}
                                            isInvalid={formik.touched.country && !!formik.errors.country}
                                        />
                                    </Form.Group>
                                </div>

                                <div className="col-xl-3 col-md-4 mb-4">
                                    <Form.Group controlId="city">
                                        <Form.Label>Club Status
                                        </Form.Label>
                                        <Select
                                            placeholder="Select"
                                            styles={customStyles}
                                            options={yesNoOptions}
                                            isClearable
                                            isSearchable
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-xl-3 col-md-4 mb-4" style={{paddingTop:"31px"}}>
                                    <Form.Group controlId="city">
                                        <Form.Label>Register status
                                        </Form.Label>
                                        <Switch
                                        checked={formik.values?.is_staff}
                                        onChange={handleChecked3}
                                        color="primary"
                                        name="is_staff"
                                        value={formik.values?.is_staff}
                                        // onChange={formik.handleChange}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                    </Form.Group>
                                </div>



                            </div>

                        </div>

                    </div>
                    <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-4'>
                        <button className='btn-type-2 cursor-pointer' onClick={() => navigate(MOBICOM_WEB)}>Cancel</button>
                        <button className='btn-type-1' type="submit">{id?"Update":"Submit"}</button>
                    </div>

                </Form>

            </div>
        </div>

    )
}

export default AddClub
