import React from 'react'
import ReciprocalRequests from '../../components/ReciprocalRequests/ReciprocalRequests'

const ReciprocalRequestsPage = () => {

  return (
    <div className='reciprocal-requests-page'>
        <ReciprocalRequests />
    </div>
  )
}

export default ReciprocalRequestsPage