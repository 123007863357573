
import React, { useEffect, useState } from 'react'
import DataTable from "react-data-table-component";
import { ReactComponent as EditIcon } from "../../assets/images/common/edit-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/common/close-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/common/add-icon.svg";
import { styled, Switch } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { ADD_GROUP, ADD_USER, EDIT_GROUP, EDIT_USER } from '../../constants/pathname';
import SweetAlertModal from '../SweetAlert/SweetAlertModal';
import { useFormik } from 'formik';
import { NavDropdown } from 'react-bootstrap';
import { getUserActive, removeUser, retriveUser } from '../../api/actions/usermanagement';
import { paginationRowsPerPageOptions } from '../../helpers/helper';
import { FaTimes } from 'react-icons/fa';
import BulkUpdateAlert from '../SweetAlert/BulkUpdateAlert';
import toast from 'react-hot-toast';

const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 39,
  height: 20,
  padding: 0,
  marginLeft: 5,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    // margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: 'var(--sandy-brown)',
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: '#2ECA45',
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600],
      }),
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: 'var(--sandy-brown)',
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    ...theme.applyStyles('dark', {
      backgroundColor: '#39393D',
    }),
  },
}));

const Users = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [isDeleting, setIsDeleting] = useState(false);
  const [limit, setLimit] = useState(25);
  const [search, setSearch] = useState('');
  const [update, setUpdate] = useState(false);
  const [pending, setPending] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSweetAlert, setShowSweetAlert] = useState({id: '', show: false});
  const [isFocused, setIsFocused] = useState(false);
  const [isSwal, setIsSwal] = useState({ show: false, id: '' });
  const [showDrawer, setShowDrawer] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [searchText, setSearchText] = useState('');
  // Permissions
  const [permissionsList, setPermissionsList] = useState([]);
  // User information
  const [userInfo, setUserInfo] = useState(null);
  // Sweet alert for status update
  const [isBulkSwal, setIsBulkSwal] = useState({
    show: false,
    id: '',
  });
  // Setting permission list
  useEffect(() => {
    let userInfoObj = JSON.parse(localStorage.getItem("user_info"));
    setUserInfo(userInfoObj);
    if (userInfoObj && userInfoObj?.permissions && userInfoObj?.permissions?.length) {
      setPermissionsList(userInfoObj?.permissions);
    }

  }, []);

  const handleSearch = (value) => {
    setSearch(value);
    setSearchText(value);
  }

  useEffect(() => {
    if (search.length >= 2) {
      const debounceTimer = setTimeout(() => {
        setDebouncedSearch(search);
      }, 500);

      return () => clearTimeout(debounceTimer);
    } else {
      setDebouncedSearch('');
    }
  }, [search]);

  const clearSearch = () => {
    setSearch('');
    setSearchText('');
    setDebouncedSearch('');
    setUpdate(!update);
  };


  const handleDelete = () => {
    removeUser({id:showSweetAlert?.id})((res)=>{
      if(res?.status){
        toast.success("Success")
        setUpdate(!update)
      }else{
        toast.error("Some thing went wrong")
      }
      setShowSweetAlert({id: '', show: false});
    })
  }

  const formik = useFormik({
    initialValues: {
      countryName: ""
    },
    onSubmit: (values, resetForm) => {

    }
  });
  const toggleActive = (pk) => {
    getUserActive({ user: pk })(response => {
      const updatedData = data.map(item => {
        if (item.pk === pk) {
          return { ...item, is_active: response?.is_active };
        }
        return item;
      });
      setIsBulkSwal({ show: false, id: '' });
      setData(updatedData);
      setUpdate(!update);
    });
  };

  const handleToggle = (pk) => {
    setIsBulkSwal({ show: true, id: pk });
  };

  let columns = [ 
    {
      name: "Sl.No",
      key: "name",
      selector: row => row?.name,
      cell: (row, index) =>
        <div className="ellipse_email" data-bs-toggle="tooltip" data-bs-placement="top" title={row.name}>
          {(limit * (page - 1)) + (index + 1)}
        </div>,      
      grow: 2,
      reorder: true,
      width: '110px'
    },
    { name: "First Name", selector: row => <div title={row.first_name}>{row.first_name}</div>, minWidth: "200px" },
    { name: "Last Name", selector: row => <div title={row.last_name}>{row.last_name}</div>, minWidth: "100px" },
    { name: "Club Name", selector: row => <div title={row?.club_details?.club_name || ""}>{row?.club_details?.club_name || ""}</div>, minWidth: "200px" },
    { name: "Email", selector: row => <div title={row.email}>{row.email}</div>, minWidth: "300px"},
    {
      name: "Active/Inactive",
      selector: row =>
        <CustomSwitch
          checked={row.is_active}
          onChange={() => handleToggle(row.pk)}
        />,
      minWidth: "200px"
    },
    {
      name: "Actions",
      selector: row =>
        <div className='d-flex align-items-center gap-3'>
          <div className='view-icon cursor-pointer' 
            title='Delete'
            onClick={() => {
              setShowSweetAlert({ 
                  show: true, 
                  id: row.pk,
              });
          }}
            >
              <CloseIcon style={{ marginTop: "-1px" }} />
            </div>
          {/* {permissionsList && (permissionsList.includes("edit_users") || userInfo?.club_admin || userInfo?.is_superuser) && */}
            <div className='view-icon cursor-pointer' title="Edit" onClick={() => navigate(`${EDIT_USER}/${row?.pk}`)}>
              <EditIcon />
            </div>
            {/* } */}
        </div>,
      width: "150px"
    },
  ];

  // Eliminate club name for club admin
  if (!userInfo?.is_superuser) {
    columns = columns.filter((item) => item.name !== "Club Name");
  }
  // Custom styling for the table
  const customStyles = {
    table: {
      style: {
        borderRadius: "15px 15px 0px 0px",
        border: "1px solid var(--border-color)",
        overflow: "hidden"
      },
    },
    headCells: {
      style: {
        backgroundColor: "var(--light-blue)",
        justifyContent: 'flex-start !important',
        color: "var(--dark-gray)",
        fontWeight: "400",
        fontSize: "15px",
      },
    },
    rows: {
      style: {
        height: "45px", // Set row height
        justifyContent: "start",
      },
    },
    cells: {
      style: {
        fontSize: '14px',
        justifyContent: 'flex-start !important',
        borderRight: "1px solid var(--border-color)",
      },
    },
  };


  useEffect(() => { getListData(); }, [page, limit, debouncedSearch, update]);

  const getListData = () => {
    setPending(true);

    if (debouncedSearch) {
      setPage(1);
    }
    retriveUser({
      page: debouncedSearch ? 1 : page,
      limit,
      search: debouncedSearch,

    })(
      (response) => {
        if (response && response.status) {
          setData(response?.data?.results);
          setTotalRows(response?.data?.count);
          setPending(false);
        }
      }
    );
  };

  return (
    <div className='member-stats-section'>
      <SweetAlertModal 
        showSweetAlert={showSweetAlert?.show} 
        setShowSweetAlert={(show)=> {
          if(show) handleDelete();
          else setShowSweetAlert({ show: false, id: '' });
        }}

        />
      <BulkUpdateAlert
        showSweetBulkAlert={isBulkSwal.show}
        setShowBulkAlert={(show) => {
          if (show) {
            toggleActive(isBulkSwal.id);
          } else {
            setIsBulkSwal({ show: false, id: '' });
          }
        }}
      />
      <div className='page-details'>
        <div className='top-section d-flex align-items-center justify-content-between'>
          <div className='left'>Manage Admins</div>
          <div className='right d-flex align-items-center  gap-3'>
            <div className='search-box'>
              <input
                type='text'
                placeholder='Search'
                className='form-control'
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
              />
              {
                searchText && (
                  <FaTimes className='clear-icon' onClick={clearSearch} />
                )}
            </div>
            {/* {permissionsList && (userInfo?.club_admin || userInfo?.is_superuser) && */}
              <div className='add-clubs-info cursor-pointer' onClick={() => navigate(ADD_USER, { state: { currentPageName: "Add or Edit User" } })} title="Create">
                <AddIcon />
              </div>
              {/* } */}
          </div>
        </div>

        <div className='table-box mt-3'>
          <DataTable
            columns={columns}
            data={data}
            customStyles={customStyles}
            fixedHeader
            highlightOnHover
            responsive
            pagination
            paginationServer
            paginationPerPage={limit}
            progressPending={pending}
            onChangeRowsPerPage={setLimit}
            paginationTotalRows={totalRows}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            onChangePage={setPage}
          />
        </div>
      </div>
    </div>
  )
}

export default Users;