import { CLUB_DROPDOWN, CLUB_ONBOARDING_CREATION, CREATE_CLUB_PROFILE, GET_CLUB_PROFILE, GPCC_STATUS_CHANGE, SOLITAIRE_CLUB_STATUS_CHANGE, LIST_CLUB_PROFILE, LIST_CLUB_INFORMATION, MAIL_SENDING_CLUB_ONBOARDING, CLUB_ONBOARDING_DETAILS, COMMON_STATUS_CHANGE, GOLF_BOOKING_STATUS_CHANGE, CLUB_STATUS_CHANGE, PUBLISH_CLUB_STATUS_CHANGE, ADD_CLUB_INVITATION, CLUB_ONBOARDING_CSV_DOWNLOAD, REGISTRATION_LINK, UPDATE_DEMO_CLUB_STATUS, UPDATE_CITY_RES_STATUS } from "../api";
import axiosInstance from "../axiosinstance";

export const createClubProfile = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_CLUB_PROFILE, props.data)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};
export const createClubOnboarding = (props) => onResponse => {
    try {
        axiosInstance.post(CLUB_ONBOARDING_CREATION, props.data)
            .then((response) => {
                console.log(response)
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};

export const bulkUploadClub=(props) => onResponse => {
    try {
        axiosInstance.post(CLUB_ONBOARDING_CSV_DOWNLOAD, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getClubProfile = (props) => onResponse => {
    console.log("props", props);
    
    try {
        let BASE_URL = `${GET_CLUB_PROFILE}?`;
        
        if(props?.id){
            BASE_URL += `id=${props.id}&`;
        }
        if(props?.country){
            BASE_URL += `country=${props.country}`;
        }
        if(props?.key){
            BASE_URL += `key=${props.key}&`;
        }
        if(props?.onboard){
            BASE_URL += `country=${props.country}`;
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};
export const getProfileonboarding = (props) => onResponse => {
    console.log("props", props);
    
    try {
        let BASE_URL = `${CLUB_ONBOARDING_DETAILS}?`;
        
        if(props?.id){
            BASE_URL += `id=${props.id}&`;
        }
        if(props?.country){
            BASE_URL += `country=${props.country}`;
        }
        if(props?.key){
            BASE_URL += `key=${props.key}&`;
        }
        if(props?.onboard){
            BASE_URL += `country=${props.country}`;
        }
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};
export const getemailsendig = (props) => onResponse => {
  
    
    try {
        let BASE_URL = `${MAIL_SENDING_CLUB_ONBOARDING}?`;
        
        if(props?.id){
            BASE_URL += `id=${props.id}&`;
        }
        if(props?.country){
            BASE_URL += `country=${props.country}`;
        }
       
        
        axiosInstance.get(BASE_URL)
            .then((response) => {
              
                
                onResponse(response.data)
            })
            .catch((err) => {
                
                
                onResponse(err)
            });

    } catch (error) {
     
        
    }
};
export const getregistrationLink = (props) => onResponse => {
  
    
    try {
        let BASE_URL = `${REGISTRATION_LINK}?`;
        
        if(props?.id){
            BASE_URL += `id=${props.id}&`;
        }
        if(props?.country){
            BASE_URL += `country=${props.country}`;
        }
       
        
        axiosInstance.get(BASE_URL)
            .then((response) => {
              
                
                onResponse(response.data)
            })
            .catch((err) => {
                
                
                onResponse(err)
            });

    } catch (error) {
     
        
    }
};
export const listClubProfile = (props) => onResponse => {
    try {
        let BASE_URL = LIST_CLUB_PROFILE + '?'
        if (props?.page) {
            BASE_URL += 'page=' + props.page + '&'

        }
        if (props?.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props?.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if(props?.sort_by){
            BASE_URL+='sort_by='+props.sort_by+'&'
        }
        if(props?.order){
            BASE_URL+='order='+props.order+'&'
        }
    
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }

};

export const listClubInformation = (props) => onResponse => {
    try {
        let BASE_URL = LIST_CLUB_INFORMATION + '?'
        if (props?.page) {
            BASE_URL += 'page=' + props.page + '&'
        }
        if (props?.filter?.status_club) {
            BASE_URL += 'status_club=' + props?.filter?.status_club + '&'
        }
        if (props?.filter?.club_type) {
            BASE_URL += 'club_type=' + props?.filter?.club_type + '&'
        }
        if (props?.filter?.location) {
            BASE_URL += 'location=' + props?.filter?.location + '&'
        }
        if (props?.limit) {
            BASE_URL += 'limit=' + props.limit + '&'
        }
        if (props?.search) {
            BASE_URL += 'search=' + props.search + '&'
        }
        if(props?.sort_by){
            BASE_URL+='sort_by='+props.sort_by+'&'
        }
        if(props?.order){
            BASE_URL+='order='+props.order+'&'
        }
    
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data)
            }).catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }

};

export const demoClubStatusChange = (props) => onResponse => {
    try {
        axiosInstance.post(UPDATE_DEMO_CLUB_STATUS, props)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};

export const memberCityStatusChange = (props) => onResponse => {
    try {
        axiosInstance.post(UPDATE_CITY_RES_STATUS, props)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};

export const gpccStatusChange = (props) => onResponse => {
    try {
        axiosInstance.post(GPCC_STATUS_CHANGE, props.data)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};

export const clubInformationStatusChange = (props) => onResponse => {
    try {
        let BASE_URL = "";

        if(props?.type === "golf_booking"){
            BASE_URL = GOLF_BOOKING_STATUS_CHANGE;
        }else if(props?.type === "status"){
            BASE_URL = CLUB_STATUS_CHANGE;
        }else if(props?.type === "common"){
            BASE_URL = COMMON_STATUS_CHANGE + `?pcn=${props.pcn}`;
        }else if(props?.type === "gpcc"){
            BASE_URL = GPCC_STATUS_CHANGE;
        }else if(props?.type === "solitaire"){
            BASE_URL = SOLITAIRE_CLUB_STATUS_CHANGE;
        }else if(props?.type === "published"){
            BASE_URL = PUBLISH_CLUB_STATUS_CHANGE;
        }

        axiosInstance.post(BASE_URL, props)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err)
            });

    } catch (error) {

    }
};

export const clubDropdowns = (props) => onResponse => {


    let BASE_URL = `${CLUB_DROPDOWN}?`
    if (props?.page) {
        BASE_URL += `page=${props?.page}&`
    }
    if (props?.limit) {
        BASE_URL += `limit=${props?.limit}&`
    }
    if (props?.search) {
        BASE_URL += `search_param=${props?.search}&`
    }

    try {
        axiosInstance.get(BASE_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
           
                onResponse(err?.response?.data);
            });

    } catch (error) {

    }

};
