import { Link, useNavigate } from "react-router-dom";
import logoIcon from "../../assets/images/common/logo/logo-pcw-black.png";
import logoPCNIcon from "../../assets/images/common/logo/pcn-logo-black.png";
import mailIcon from "../../assets/images/common/mail-icon.svg";
import notificationIcon from "../../assets/images/common/notification-icon.svg";
import profileIcon from "../../assets/images/common/img1.png";
import { CANCELLATION, HOME, LOGIN, NOTIFICATIONS, POLICY_UPDATE, RESET_PASSWORD, TERMS_OF_USAGE, UPDATE_PASSWORD, WELCOME } from "../../constants/pathname";
import { useEffect, useRef, useState } from "react";
import { IoIosDocument, IoIosHelpCircleOutline, IoIosInformationCircle, IoIosLogOut } from "react-icons/io";
import { SiHelpdesk } from "react-icons/si";
import { LiaHandsHelpingSolid } from "react-icons/lia";

import { MdLockReset, MdPolicy, MdUpdate } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import toast from "react-hot-toast";
import { userLogout } from "../../api/actions/auth";
import checkThemePcw, { checkTheme, PCN_URL, PCW_URL } from "../../utils/utils";
import { BiTransferAlt } from "react-icons/bi";
import { downloadWalkThrough, notificationList } from "../../api/actions/notifications";
import { getClubProfile } from "../../api/actions/clubProfile";
import { ListGroup, Overlay, Popover } from "react-bootstrap";

const Header = ({ minimizeSidebar, setMinimizeSidebar }) => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const userInfo = JSON.parse(localStorage.getItem("user_info"))
    const clubName = userInfo?.club_details?.[0]?.club_name || "" ;
    const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
    const [profileData, setProfileData]=useState({});
    const [show, setShow] = useState(false);

    const target = useRef(null);
    let roleName = 'Staff';

    if (userInfo?.is_superuser) {
        roleName = 'Super Admin';
    } else if (userInfo?.club_admin) {
        roleName = 'Club Admin';
    }


    const helpItems = [
        // { text: "Walkthrough Document", icon: <IoIosDocument />, link: "#" },
        { text: "Terms of Usage", icon: <IoIosInformationCircle style={{marginTop: "-4px"}} />, link: TERMS_OF_USAGE },
        { text: "Cancellation Policy", icon: <MdPolicy style={{marginTop: "-4px"}}/>, link: CANCELLATION },
        { text: "Policy Update", icon: <MdUpdate style={{marginTop: "-4px"}}/>, link: POLICY_UPDATE },
      ];

      const handleItemClick = async (link, text) => {
        if (text === "Walkthrough Document") {
            await downloadWalkThrough(checkTheme()? true : false)
        } else if (link) {
          window.open(link, "_blank", "noopener,noreferrer");
        }
        setShow(false);
      };


    const onClickLogout = async () => {
        let formData = {
            refresh: localStorage.getItem("refresh"),
          };
      
          userLogout({ formData })((res) => {
            console.log(res);
            
            if (res?.status) {
              localStorage.clear();
              // navigate(LOGIN);
              window.location.replace(LOGIN)
            }
          });
    }
    const swapTheme = () => {
        const token = localStorage.getItem('token')
        if (checkTheme()) {
            window.open(`${PCW_URL}${WELCOME}?tc=${token}`, '_blank');
        }
        else {
            window.open(`${PCN_URL}${WELCOME}?tc=${token}`, '_blank');
        }
    }

    useEffect(() => {

        // Fetch club profile data
        getClubProfile({ id: userInfo?.club_details?.[0]?.club_id, country: userInfo?.club_details?.[0]?.country })((response) => {
            if (response&&response?.status) {
                // Update profileData state with the fetched data
                setProfileData(response?.data);
            }
        });
    }, []);
    
    // Fetch notifications
    useEffect(() => {
        const fetchNotifications = async () => {
            notificationList({ page: 1, limit: 15 })((response) => {
                if (response.status && response.status_code === 200) {
                    const unreadNotifications = response.data.results.filter(notification => !notification.read);
                    setHasUnreadNotifications(unreadNotifications.length > 0);
                } else {
                    console.error("Failed to fetch notifications:", response);
                }
            });
        };

        fetchNotifications();
    }, []);

    // Handle help
    const handleHelpClick = () =>{
        if(checkTheme()){
            window.open("https://pcn.supporthero.io/", "_blank", "noopener,noreferrer");
        }else{
            window.open("https://pcw.supporthero.io/", "_blank", "noopener,noreferrer");
        }
    }

    return (
        <div className='header-section'>
            <div className="row">
                <div className="col-md-2">

                    {!checkTheme() ? 
                    <div className="logo-icon cursor-pointer" onClick={() => navigate(HOME)}>
                        <img src={logoIcon} alt="PrivateClubWorld" />
                    </div>
                    :
                    <div className="logo-icon cursor-pointer" onClick={() => navigate(HOME)}>
                        <img src={logoPCNIcon} alt="PlatinumClubNet"/>
                    </div>}

                </div>
                <div className="col-md-1">
                    <div className="d-flex align-items-center h-100 cursor-pointer" onClick={() => setMinimizeSidebar(!minimizeSidebar)}>
                        <RxHamburgerMenu size={25} />
                    </div>
                </div>
                <div className="col-md-5 col-xl-6">
                </div>
                <div className="col-md-4 col-xl-3">
                    <div className="d-flex align-items-center justify-content-end gap-3">
                        <div className="mail-icon cursor-pointer d-none">
                            <img src={mailIcon} alt="Mail" />
                        </div>
                        {userInfo?.is_superuser ? <></> : (

                        <>
                        {/* {!userInfo?.is_superuser && 
                            <button                                                             
                                onClick={handleHelpClick}
                                title="Help"
                                style={{
                                    padding: "6px 14px",
                                    background: "var(--sandy-brown)",
                                    borderRadius: "6px",
                                    color: "var(--white)"
                                }}                                
                            >
                                Help
                            </button>} */}
                        <div 
                            ref={target} 
                            className="notification-icon cursor-pointer" 
                            onClick={() => setShow(!show)}
                            title="Help"
                        >
                            <IoIosHelpCircleOutline style={{ width: "20px", height: "20px" }} />
                        </div>

                        <Overlay
                            target={target.current}
                            show={show}
                            placement="bottom-end"
                            rootClose
                            onHide={() => setShow(false)}
                        >
                            <Popover id="help-popover">
                            <Popover.Header as="h3" style={{backgroundColor:"var(--sandy-brown)",color:"var(--white)", lineHeight: "20px"}}>Help</Popover.Header>
                            <Popover.Body className="p-0">
                                <ListGroup variant="flush">
                                {helpItems.map((item, index) => (
                                    <ListGroup.Item
                                        key={index}
                                        action
                                        className="py-2"
                                        onClick={() => handleItemClick(item.link,item.text)}
                                    >
                                    {item?.icon} {item?.text}
                                  </ListGroup.Item>
                                ))}
                                </ListGroup>
                            </Popover.Body>
                            </Popover>
                        </Overlay>
                        </>
                        )}
                        <div className="notification-icon cursor-pointer" onClick={() => navigate(NOTIFICATIONS)}>
                        {hasUnreadNotifications && (
                                <div className="circle"></div>
                            )}
                            <img src={notificationIcon} alt="Notification" />
                        </div>
                        <div className="dropdown header-dropdown">
                            <button className="bg-white" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <div className="profile-panel d-flex align-items-center cursor-pointer gap-3">
                                    <div>
                                        <div className="name fw-500" title={clubName}>{clubName}</div>
                                        <div className="role text-end">{roleName}</div>
                                    </div>
                                    <div className="profile-icon cursor-pointer">
                                        <img src={profileData?.main_image || profileIcon} alt="Profile" 
                                            style={{width: "41px", height: "41px", borderRadius: "50%", overflow: "hidden", objectFit: "cover"}}/>
                                    </div>
                                </div>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <Link className="dropdown-item" to={UPDATE_PASSWORD}>
                                        <MdLockReset />
                                        Reset Password
                                    </Link>
                                </li>
                                {userInfo?.club_details?.[0]?.pcn && userInfo?.club_details?.[0]?.pcw ?
                                    <li>
                                        <div className="dropdown-item" onClick={() => swapTheme()} role="button">
                                            <BiTransferAlt />
                                            Switch to {checkTheme() ? 'PCW' : 'PCN'}
                                        </div>
                                    </li>
                                    :
                                    <></>
                                }
                                <li onClick={() => { onClickLogout() }}>
                                    <Link className="dropdown-item">
                                        <IoIosLogOut />
                                        Logout
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header