import React, { useState } from 'react'
import DataTable from "react-data-table-component";
import {ReactComponent as FilterIcon} from "../../assets/images/common/filter-icon.svg";
import {ReactComponent as ViewIcon} from "../../assets/images/common/view-icon.svg";
import {ReactComponent as EditIcon} from "../../assets/images/common/edit-icon.svg";
import {ReactComponent as CloseIcon} from "../../assets/images/common/close-icon.svg";
import {ReactComponent as AddIcon} from "../../assets/images/common/add-icon.svg";
import Select from "react-select"
// Drawer
import Drawer from '@mui/material/Drawer';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
// Rsuite Datepicker
import { DatePicker } from 'rsuite';
import { CREATE_CURATED_MEMBER_EVENTS, HOME } from '../../constants/pathname';
import SweetAlertModal from '../../components/SweetAlert/SweetAlertModal';
import { useFormik } from 'formik';

const CuratedMemberEvents = () => {
    // Sweet alert
    const [showSweetAlert, setShowSweetAlert] = useState(false);
    
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const navigate = useNavigate();

    const [showDrawer, setShowDrawer] = useState(false);

    const handleDelete = () =>{
        setShowSweetAlert(true);
    }
    
    const formik = useFormik({
        initialValues : {
            countryName: ""
        },
        onSubmit: (values, resetForm)=>{
    
        }
    });

    // Data for the table
    const data = [
        { slNo: "01", facilityName: "Accommodation", clubName: "OneClubNet Demo Club", clubType: "Golf Club", city: "Oakleigh South VIC, Australia", country: "Australia", status: "Upcoming", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024" },
        { slNo: "02", facilityName: "Tennis", clubName: "Sapphire City Club", clubType: "Yacht Club", city: "Madeline, CA, USA", country: "United States of America", status: "Completed", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024" },
        { slNo: "03", facilityName: "Tennis", clubName: "Estancia Golf Club", clubType: "Yacht Club", city: "Dallas, TX, USA", country: "United States of America", status: "Cancelled", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024" },
        { slNo: "04", facilityName: "Tennis", clubName: "St. Morrie's World Country Club", clubType: "Yacht Club", city: "Dallas, TX, USA", country: "United States of America", status: "Expired", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024" },
        { slNo: "05", facilityName: "Bowling", clubName: "Wise Horse Yacht Club", clubType: "Golf Club", city: "Singapore", country: "Singapore", status: "Upcoming", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024" },
        { slNo: "06", facilityName: "Library", clubName: "The Metropolitan Club", clubType: "Country Club", city: "New York, NY, USA", country: "United States Of America", status: "Upcoming", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024" },
        { slNo: "07", facilityName: "Fitness Centre", clubName: "The Diamond Club", clubType: "Country Club", city: "New York, NY, USA", country: "United States of America", status: "Upcoming", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024" },
        { slNo: "08", facilityName: "Dining", clubName: "Boulder Ridge Country Club", clubType: "Country Club", city: "New York, NY, USA", country: "United States of America", status: "Completed", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024" },
        { slNo: "09", facilityName: "Private Room", clubName: "Lakewood Ranch Golf and Country Club", clubType: "Sailing Club", city: "New York, NY, USA", country: "United States of America", status: "Completed", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024" },
        { slNo: "10", facilityName: "Bowling", clubName: "Central Okanagan Sailing Association", clubType: "Country Club", city: "Malaga, Spain", country: "Spain", status: "Cancelled", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024" },
        { slNo: "11", facilityName: "Bowling", clubName: "Kota Permai Golf Country Club", clubType: "Golf Club", city: "Malaga, Spain", country: "Singapore", status: "Cancelled", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024" },
        { slNo: "12", facilityName: "Bowling", clubName: "Madeline Island Yacht Club", clubType: "Yacht Club", city: "Madeline, CA, USA", country: "Singapore", status: "Expired", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024" },
        { slNo: "13", facilityName: "Bowling", clubName: "Chicago Corinthian Yacht Club", clubType: "Golf Club", city: "Madeline, CA, USA", country: "Singapore", status: "Expired", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024" },
        { slNo: "14", facilityName: "Bowling", clubName: "The Terminal City", clubType: "Country Club", city: "Oakleigh South VIC, Australia", country: "Spain", status: "Completed", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024" },
        { slNo: "15", facilityName: "Bowling", clubName: "Wise Horse Yacht Club", clubType: "Golf Club", city: "Oakleigh South VIC, Australia", country: "Spain", status: "Completed", startDate: "08 Oct 2024", endDate: "08 Oct 2024", createdDate: "08 Oct 2024" },
    ];
  
    // Define columns for the table
    const columns = [
        { name: "Facility Name", selector: row => row.facilityName, width: "180px", sortable: true },
        { name: "Club Name", selector: row => row.clubName, minWidth: "200px" , sortable: true },
        { name: "Club Type", selector: row => row.clubType, width: "180px", sortable: true },
        { name: "City", selector: row => row.city, width: "220px", sortable: true },
        { name: "Country", selector: row => row.country, width: "180px", sortable: true },
        { name: "Status", selector: row => row.status, width: "160px", sortable: true },
        { name: "Start Date", selector: row => row.startDate, width: "180px", sortable: true },
        { name: "End Date", selector: row => row.endDate, width: "180px", sortable: true },
        { name: "Created Date", selector: row => row.createdDate, width: "180px", sortable: true },
        { name: "Actions", 
            selector: row => 
            <div className='d-flex align-items-center gap-3'>
                <div className='view-icon cursor-pointer' onClick={() => navigate(CREATE_CURATED_MEMBER_EVENTS, {state : {currentPageName: "View Curated Member Event" }})} title='View'><ViewIcon style={{marginTop: "-1px"}}/></div>
                <div className='view-icon cursor-pointer' onClick={() => navigate(CREATE_CURATED_MEMBER_EVENTS, {state : {currentPageName: "Edit Curated Member Event" }})} title="Edit"><EditIcon /></div>
                <div className='view-icon cursor-pointer' onClick={() => handleDelete()} title='Delete'><CloseIcon style={{marginLeft: "2px"}}/></div>
            </div>,  
            width: "180px", sortable: true },
    ];
   
    // Custom styling for the table
    const customStyles = {
        table: {
            style: {
                borderRadius: "15px 15px 0px 0px",        
                border: "1px solid var(--border-color)",
                overflow: "hidden"
            },
    },
        headCells: {
        style: {
            backgroundColor: "var(--light-blue)",
            justifyContent: 'flex-start !important',
            color: "var(--dark-gray)",
            fontWeight: "400",
            fontSize: "15px",
        },
        },
        rows: {
        style: {
            height: "45px", // Set row height
            justifyContent: "start",
        },
        },
        cells: {
            style: {
                fontSize: '14px',
                justifyContent: 'flex-start !important',
                borderRight: "1px solid var(--border-color)",
            },
        },
    }; 

  return (
    <div className='curated-member-events-page'>
      <div className='member-stats-section'>
          <SweetAlertModal showSweetAlert={showSweetAlert} setShowSweetAlert={setShowSweetAlert}/>
          <Drawer
              anchor={'right'}
              open={showDrawer}
              onClose={() => setShowDrawer(false)}
              className='member-stats-drawer'
              sx={{
                  '& .MuiPaper-root':{
                      padding: '30px 25px',
                      height: "100vh",
                      overflow: "hidden"
                  },
                  '& .MuiDrawer-paper': {
                      width: '420px'
                  },
                  '& .MuiBox-root':{
                      width: '420px'
                  },
                  '& .MuiList-root':{
                      display: 'flex',
                      columnGap: '10px',
                      width: '90%',
                      margin: 'auto',
                      paddingTop: "25px"
                  },
                  '& .MuiButtonBase-root':{
                      padding: '7px',
                      textAlign: 'center',
                      alignItems: 'center',
                      borderRadius: '10px',
                      backgroundColor: '#F5F5F5',
                  }
              }}
            >
              <div className="drawer-top-box">
                  <div>Filter</div>
                  <button type="button" class="btn-close" aria-label="Close" onClick={() => setShowDrawer(false)}></button>
              </div>
              <div className="drawer-bottom-box mt-4">
                  <Form onSubmit={(e) => e.preventDefault()}>         
                      {/* Start Date */}
                      <div className='item start-date-input'>                        
                          <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em11">
                              <Form.Label>Start Date<span className='required'>*</span></Form.Label>
                              <div className='input-border'>
                                  <DatePicker 
                                      id='start_date' 
                                      onChange={(value) => {
                                          setFromDate(value);
                                          if (toDate && value > toDate) {
                                              setToDate(null); // Reset "To Date" if it becomes invalid
                                          }
                                      }}
                                      placement="autoVerticalEnd"
                                  />
                              </div>
                          </Form.Group>
                      </div>
                      {/* End Date */}
                      <div className='item end-date-input'>                        
                          <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em4">
                              <Form.Label>End Date<span className='required'>*</span></Form.Label>
                              <div className='input-border'>
                                  <DatePicker 
                                      id='end_date' 
                                      onChange={(value) => setToDate(value)}
                                      placement="autoVerticalEnd"
                                      shouldDisableDate={(date) => {
                                          // Disable dates before the selected Start Date
                                          return fromDate ? date < fromDate : false;
                                      }} 
                                  />
                              </div>
                          </Form.Group>
                      </div>             
                      {/* Facility Name */}
                      <div className='item'>                        
                          <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em5">
                              <Form.Label>Facility Name</Form.Label>
                              <div className='input-border'>
                                  <Form.Control
                                      type="text"
                                      className='input-field'
                                      name='facilityName'
                                  />
                              </div>
                          </Form.Group>
                      </div>
                      {/* Club Name */}
                      <div className='item'>                        
                          <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em6">
                              <Form.Label>Club Name</Form.Label>
                              <div className='input-border'>
                                  <Form.Control
                                      type="text"
                                      className='input-field'
                                      name='clubname'
                                  />
                              </div>
                          </Form.Group>
                      </div>
                      {/* Club Type */}
                      <div className='item'>                        
                          <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em7">
                              <Form.Label>Club Type</Form.Label>
                              <div className='input-border'>
                                <Select
                                    theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    colors: {
                                        ...theme.colors,
                                    },
                                    })}
                                    placeholder="Select"
                                    isSearchable={false}
                                    isClearable={true}
                                />
                              </div>
                          </Form.Group>
                      </div>
                      {/* City */}
                      <div className='item'>                        
                          <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em8">
                              <Form.Label>City</Form.Label>
                              <div className='input-border'>
                                  <Form.Control
                                      type="text"
                                      className='input-field'
                                      name='city'
                                  />
                              </div>
                          </Form.Group>
                      </div>
                      {/* Country */}
                      <div className='item'>                        
                          <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em9">
                              <Form.Label>Country</Form.Label>
                              <div className='input-border' title={formik?.values?.countryName || ''}>
                                  <Select
                                      theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 5,
                                      colors: {
                                          ...theme.colors,
                                      },
                                      })}
                                      placeholder="Select"
                                      options={[{ label: "India", value: "india" }]}
                                      onChange={(e) => formik.setFieldValue("countryName", e?.label || '')} 
                                      isSearchable={false}
                                      isClearable={true}
                                  />
                              </div>
                          </Form.Group>
                      </div>                                                            
                      {/* Status */}
                      <div className='item'>                        
                          <Form.Group className="mb-3 d-flex align-items-center justify-content-between" controlId="exampleForm.ControlInput1em10">
                              <Form.Label>Status</Form.Label>
                              <div className='input-border'>
                                  <Select
                                      theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 5,
                                      colors: {
                                          ...theme.colors,
                                      },
                                      })}
                                      placeholder="Select"
                                      isSearchable={false}
                                      isClearable={true}
                                  />
                              </div>
                          </Form.Group>
                      </div>                                                                           
                      <div className='submit-box d-flex align-items-center justify-content-end gap-3' style={{ height: "20vh"}}>
                          <div className='btn-type-2 cursor-pointer'>Clear</div>
                          <div className='btn-type-1' type="submit">Submit</div>
                      </div>
                  </Form>
              </div>

          </Drawer>
          <div className='page-details'>
              <div className='top-section d-flex align-items-center justify-content-between'>
                  <div className='left'>List Curated Member Events</div>
                  <div className='right d-flex align-items-center  gap-3'>
                      <div  className='filter-box d-flex align-items-center gap-3 cursor-pointer' onClick={() => setShowDrawer(true)}>
                          <FilterIcon />
                          <span>Filter</span>
                      </div>
                      <div className='add-clubs-info cursor-pointer'  onClick={() => navigate(CREATE_CURATED_MEMBER_EVENTS, {state : {currentPageName: "Add Curated Member Event" }})} title="Create">                    
                          <AddIcon />
                      </div>
                  </div>
              </div>

              <div className='table-box mt-3'>
                  <DataTable
                      columns={columns}
                      data={data}
                      customStyles={customStyles}
                      fixedHeader
                      highlightOnHover
                      responsive
                  />
              </div>
          </div>
      </div>
    </div>
  )
}

export default CuratedMemberEvents