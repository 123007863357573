import React from 'react'
import SurveyQuestioner from '../../components/SurveyQuestioner/SurveyQuestioner'

const SurveyQuestionerPage = () => {

  return (
    <div className='survey-questioner-page'>
        <SurveyQuestioner />
    </div>
  )
}

export default SurveyQuestionerPage