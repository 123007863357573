import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import ClubNotifications from './ClubNotifications';

const Notifications = () => {

     // Scroll to top    
     useEffect(() =>{
        if(window){
          window.scrollTo(0, 0);
        }
    },[]);

  return (
    <div className='notifications-list-page'>
      <div className='notification-section'>
          <Container>
              <div className='main'>
                  <div className='title d-flex align-items-center gap-5'>
                      <div>Notifications</div>
                  </div>
                  <ClubNotifications />
              </div>
          </Container>
      </div>
    </div>
  )
}

export default Notifications;