import React from 'react'
import PrivateEvents from '../../components/PrivateEvents/PrivateEvents'

const PrivateEventsPage = () => {
  return (
    <div className='private-events-page'>
        <PrivateEvents />
    </div>
  )
}

export default PrivateEventsPage