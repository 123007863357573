import { Form, InputGroup } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { components } from 'react-select'
import { HiChevronDown } from 'react-icons/hi';
import { IoCloseSharp } from 'react-icons/io5'
import AsyncSelect from 'react-select/async';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IoIosClose } from "react-icons/io";
import { SOLITAIRE_NETWORK } from '../../constants/pathname';
import clubImg from "../../assets/images/common/img1.png";

const CreateSolitaireNetwork=() => {
    const navigate=useNavigate();
    const [isFocused, setIsFocused]=useState(false); // State to track focus
    const [clubsList, setClubsList]=useState([
        { label: "OneClubNet Demo Club", value: "oneclubnet-demo-club" },
        { label: "Sapphire City Club", value: "sapphire-city-club" },
        { label: "Estancia Golf Club", value: "estancia-golf-club" },
        { label: "St. Morrie's World Country Club", value: "st-morries-world-country-club" },
        { label: "Wise Horse Yacht Club", value: "wise-horse-yacht-club" },
        { label: "The Metropolitan Club", value: "the-metropolitan-club" },
        { label: "The Diamond Club", value: "the-diamond-club" },
        { label: "Boulder Ridge Country Club", value: "boulder-ridge-country-club" },
        { label: "Lakewood Ranch Golf and Country Club", value: "lakewood-ranch-golf-and-country-club" },
        { label: "Central Okanagan Sailing Association", value: "central-okanagan-sailing-association" },
        { label: "Kota Permai Golf Country Club", value: "kota-permai-golf-country-club" },
        { label: "Madeline Island Yacht Club", value: "madeline-island-yacht-club" },
        { label: "Chicago Corinthian Yacht Club", value: "chicago-corinthian-yacht-club" },
        { label: "The Terminal City", value: "the-terminal-city" },
    ]);
    const [search, setSearch]=useState("");
    const [searchClubList, setSearchClubList] = useState([]);    

    const formik=useFormik({
        initialValues: {
            clubs: [],
        },
        validationSchema: Yup.object().shape({
            clubs: Yup.array()
                .min(1, 'At least one role must be selected')
                .required('Roles are required'),
        }),
        validate: (values) => {
            let errors={};

            if (Array.isArray(values?.clubs)&&!values?.clubs?.length) {
                errors.clubs='At least one role must be selected';
            }

            return errors;

        },
        onSubmit: (values) => {
            navigate(SOLITAIRE_NETWORK)
        }
    });

    useEffect(() =>{
        let data = formik?.values?.clubs?.filter((club) =>
            club?.label?.toLowerCase().includes(search.toLowerCase())
        );
        
        setSearchClubList(data);
    },[search]);
    
    // Clear search field
    const handleClearSearch=() => {
        setSearch('');
    };
    // Search
    const handleSearchData=(e) => {
        setSearch(e.target.value);
    }

    const DropdownIndicator=({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>
                <HiChevronDown style={{ fontSize: "1.5em" }} />
            </components.DropdownIndicator>
        );
    };

    // Select box style
    const customSelectStyles={
        control: (provided, state) => ({
            ...provided,
            //   height: state?.isFocused ? "auto" : "38px"
        }),
        multiValue: (provided) => ({
            ...provided,
            borderRadius: 5,
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            marginBottom: "2px",
            marginTop: "3px",
            paddingBottom: "0px"
        }),
        valueContainer: (styles, state) => ({
            ...styles,
            maxHeight: isFocused? "auto":"33px",
        }),
        indicatorSeparator: (styles) => ({
            ...styles, marginBottom: '8px', marginTop: '6px'
        }),
        dropdownIndicator: (styles) => ({
            ...styles, paddingTop: '2px'
        }),
        clearIndicator: (styles) => ({
            ...styles, paddingTop: '2px'
        })
    };

    // Function to remove club option
    const removeClubOption=(value) => {
        const updatedClubs = formik?.values?.clubs?.filter((club) => club.value!==value);
        formik.setFieldValue("clubs", updatedClubs);
        
        if(search){
            setSearch("");
        }
    };

    let searchTimer;

    // Filter out selected clubs from options
    const loadOptions=(inputValue) => {
        return new Promise((resolve) => {
            clearTimeout(searchTimer);
            searchTimer=setTimeout(() => {
                const selectedValues=formik?.values?.clubs?.map((club) => club.value)||[];
                const filteredOptions=clubsList.filter(
                    (club) =>
                        !selectedValues.includes(club.value)&&
                        club.label.toLowerCase().includes(inputValue.toLowerCase())
                );
                resolve(filteredOptions);
            }, 500); // Debounce delay
        });
    };

    // Function to filter default options
    const filterDefaultOptions=() => {
        return clubsList.filter(
            (club) => !formik?.values?.clubs?.some((selected) => selected.value===club.value)
        );
    };

    return (
        <div className='create-solitaire-network create-clubs-info-section'>
            <div className='page-details'>
                <div className='panel-type-1'>
                    Add Solitaire Network
                </div>
                <Form onSubmit={formik.handleSubmit}>
                    {/* Clubs */}
                    <div className='row'>
                        <div className='item mt-4 col-md-6'>
                            <Form.Group controlId="exampleForm.ControlInput1em5">
                                <Form.Label>Clubs<span className='required'>*</span></Form.Label>
                                <div className='input-border'>
                                    {/* Async Select */}
                                    <AsyncSelect
                                        className={`clubs ${isFocused? "focused":""}`}
                                        onFocus={() => setIsFocused(true)}
                                        onBlur={() => setIsFocused(false)}
                                        styles={customSelectStyles}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 5,
                                            colors: {
                                                ...theme.colors,
                                            },
                                        })}
                                        placeholder={formik?.values?.clubs?.length>0? "Add More":"Add Club"}
                                        value={null}
                                        loadOptions={(inputValue) => {
                                            return new Promise((resolve) => {
                                                setTimeout(() => {
                                                    const filteredOptions=filterDefaultOptions().filter((club) =>
                                                        club.label.toLowerCase().includes(inputValue.toLowerCase())
                                                    );
                                                    resolve(filteredOptions);
                                                }, 500);
                                            });
                                        }}
                                        defaultOptions={filterDefaultOptions()} // Dynamically filter the default options
                                        onChange={(selectedOption) => {
                                            if (selectedOption) {
                                                const updatedClubs=[...(formik?.values?.clubs||[]), selectedOption];
                                                formik.setFieldValue("clubs", updatedClubs);
                                            }
                                        }}
                                        isClearable={false} // Prevent clearing individual selections here
                                        isMulti={false} // Disable showing multiple values in the input 
                                        filterOption={filterDefaultOptions}
                                    />
                                    {formik?.touched?.clubs&&formik?.errors?.clubs&&
                                        <div className='error-msg'>{formik?.errors?.clubs}</div>
                                    }
                                </div>
                            </Form.Group>
                        </div>
                        {/* <div className='item mt-4 col-md-5'>
                            <Form.Group controlId="exampleForm.ControlInput1em6">
                                <Form.Label style={{minHeight: "18px"}}></Form.Label>
                                <InputGroup className="">
                                    <Form.Control
                                        type='text'
                                        placeholder='Search Club by Name'
                                        name='searchClubs'
                                        value={search}
                                        onChange={handleSearchData}
                                    />
                                    {search&&(
                                        <InputGroup.Text id="basic-addon3" onClick={handleClearSearch} style={{ cursor: "pointer" }}><IoCloseSharp /></InputGroup.Text>
                                    )}
                                </InputGroup>
                            </Form.Group>
                        </div> */}
                    </div>
                    {/* Roles list */}
                    <div className='clubs-list row mt-4'>
                        <div className='col-md-12'>
                            {!search && Array.isArray(formik?.values?.clubs) && formik?.values?.clubs?.length > 0 &&
                                <ul className='grid-container gap-3 mt-4'>
                                    {formik?.values?.clubs?.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <IoIosClose size={22} className='cursor-pointer' onClick={() => removeClubOption(item?.value)} />
                                                <div className='img-wrapper'>
                                                    <img src={clubImg} alt={item?.label} />
                                                </div>
                                                <div className='text-center mt-2'>{item?.label}</div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            }
                            {search && Array.isArray(searchClubList) && searchClubList?.length > 0 &&
                                <ul className='grid-container gap-3 mt-4'>
                                    {searchClubList?.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <IoIosClose size={22} className='cursor-pointer' onClick={() => removeClubOption(item?.value)} />
                                                <div className='img-wrapper'>
                                                    <img src={clubImg} alt={item?.label} />
                                                </div>
                                                <div className='text-center mt-2'>{item?.label}</div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            }
                        </div>
                    </div>
                    <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-5'>
                        <button className='btn-type-2 cursor-pointer' onClick={() => navigate(SOLITAIRE_NETWORK)}>Cancel</button>
                        <button className='btn-type-1' type="submit" >Submit</button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default CreateSolitaireNetwork