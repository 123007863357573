import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/fonts/font.scss';
import './assets/styles/styles.scss';
import 'ckeditor5/ckeditor5.css';
import 'antd/dist/reset.css'; // For Ant Design v4

import PrivateRoutes from "./privateRoutes";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import { CANCELLATION, FORGOT_PASSOWORD, LOGIN, POLICY_UPDATE, PRIVACY_POLICYS, REGISTER_PCN, REGISTER_PCW, RESET_PASSWORD, TERMS_AND_CONDITION, TERMS_OF_USAGE, UPDATE_PASSWORD, VERIFY_EMAIL, WELCOME } from './constants/pathname';
import LoginPage from './pages/Login/LoginPage';
import ForgotPasswordPage from './pages/ForgotPassword/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPassword/ResetPasswordPage';
import { useEffect, useState } from 'react';
import VerifyEmail from './pages/VerifyEmail/VerifyEmail';
import { LoadScript } from '@react-google-maps/api';
import ChangePass from './pages/ChangePassword/ChangePass';
import Clubinfo from './components/Register/RegisterPCw/Clubinfo';
import Clubinfos from './components/Register/RegisterPCw/RegisterPCN/Clubinfo';
import { checkTheme } from './utils/utils';
import Welcome from './components/Welcome/welcome';

import { Helmet } from 'react-helmet-async';
import TermsCondtion from './common/layout/Termscondtion';
import PrivacyPolicys from './common/layout/Privacypolicy';
import PolicyUpdate from './common/layout/policyUpdates';
import Cancellation from './common/layout/cancellation';
import TermsOfusage from './common/layout/termsOfUsage';

const libraries = ["places"];
function App() {


  const [metObj, setOMetaobj] = useState({
    title: "", ogimage: "", descrption: "",
  });


  useEffect(() => {
    // Detect the current domain
    const hostname = window.location.origin;
    // Set favicon based on the domain
    const favicon = document.querySelector("link[rel='icon']");
    if (checkTheme()) {
      favicon.href = '/favicon-pcn.ico';
      setOMetaobj({
        ogimage: "",
        descrption: "Curate bespoke experiential events and offer access to network of platinum clubs worldwide with PlatinumClubNet in association with Club Leaders Forum",
        title: "PlatinumClubNet"
      });
    } else {
      favicon.href = '/favicon-pcw.ico';
      setOMetaobj({
        ogimage: "",
        descrption: "Manage Your Club's Reciprocal Network and Member Requests, Seamlessly. A Reciprocity Management System for existing private club networks, creating a galaxy, agnostic to club verticals, complemented by a Global Private Club Citizenship.",
        title: "PrivateClubWorld"
      });
    }
  }, []);

  useEffect(() => {
    const htmlElement = document.documentElement;
    if (checkTheme()) {
      htmlElement.setAttribute('theme', 'platinum-club-net');
    }
    else {
      htmlElement.setAttribute('theme', '');
    }
  }, []);

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_API_KEY} libraries={libraries}>
      <div className="app-container">
        
        {metObj?.title &&
          <Helmet>
            <title>{metObj?.title || ""}</title>
            <meta name="description" content={metObj?.descrption || ""} />
          </Helmet>}
        <BrowserRouter>
          <Toaster position="bottom-right" toastOptions={{ duration: 2000 }} />
          <Routes>
            <Route exact path={LOGIN} element={<LoginPage />} />
            <Route exact path={FORGOT_PASSOWORD} element={<ForgotPasswordPage />} />
            <Route exact path={RESET_PASSWORD} element={<ResetPasswordPage />} />
            <Route exact path={VERIFY_EMAIL} element={<VerifyEmail />} />
            <Route exact path={UPDATE_PASSWORD} element={<ChangePass />} />
            <Route exact path={REGISTER_PCW} element={<Clubinfo />} />
            <Route exact path={REGISTER_PCN} element={<Clubinfos />} />
            <Route exact path={WELCOME} element={<Welcome />} />
            <Route exact path={TERMS_AND_CONDITION} element={<TermsCondtion />} />
            <Route exact path={PRIVACY_POLICYS} element={<PrivacyPolicys />} />
            <Route exact path={POLICY_UPDATE} element={<PolicyUpdate />} />
            <Route exact path={CANCELLATION} element={<Cancellation />} />
            <Route exact path={TERMS_OF_USAGE} element={<TermsOfusage />} />
            <Route exact path='/*' element={<PrivateRoutes />} />
          </Routes>
        </BrowserRouter>
      </div>
    </LoadScript>
  );
}

export default App;