import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import Select from "react-select"
import { BRANDS } from '../../constants/pathname'
import { useFormik } from 'formik';
import toast from 'react-hot-toast'
import { MdOutlineDelete } from 'react-icons/md'
import * as Yup from 'yup';
import AsyncSelect from "react-select/async"
import { brandCategoryDropdown } from '../../api/actions/brandCategory'
import { cityListDropdown } from '../../api/actions/city'
import { countryListDropdown } from '../../api/actions/country'
import { addBrand, getBrandDetails } from '../../api/actions/brand'
import imageToBase64 from 'image-to-base64/browser'

const CreateBrands = () => {
    const navigate = useNavigate();
    const [photoFile, setPhotoFile] = useState(null)
    const [photo, setPhoto] = useState(null);
    const [category, setCategories] = useState('');
    const [city, setCity] = useState('')
    const [country, setCountry] = useState('')
    const { id } = useParams();
    const validationSchema = Yup.object({
        brand_name: Yup.string().required('Brand Name is required'),
        brand_email: Yup.string().email('Invalid email address').required('Email is required'),
        category: Yup.string().required('Category is required'),

        city: Yup.string().required('City is required'),
        country: Yup.string().required('Country is required'),
        contact_number: Yup.string().required('Contact Number is required'),

    })
    const formik = useFormik({
        initialValues: {
            brand_name: "",
            brand_email: '',
            category: '',
            selected_category: [],
            address: '',
            city: '',
            selected_city: [],
            country: '',
            selected_country: [],
            contact_number: '',
            brand_image: '',
        },
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            addBrand(values)((response) => {
                if (response.status_code === 200 || response.status_code === 201) {
                    toast.success(id ? "Successfully Updated!" : "Successfully Created!");
                    resetForm();
                    navigate(BRANDS);
                } else {
                    toast.error(response?.response?.data?.errors[0]);
                }
            })

        }
    });
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
            const maxSize = 5 * 1024 * 1024; // 5MB

            if (!allowedTypes.includes(file.type)) {
                toast.error('Please upload a valid image (JPEG, PNG, or GIF)');
                return;
            }

            if (file.size > maxSize) {
                toast.error('File size should not exceed 5MB');
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setPhoto(base64String);
                setPhotoFile(file);
                formik.setFieldValue('brand_image', base64String);
            };
            reader.readAsDataURL(file);
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            outline: 'none',
            boxShadow: 'none',
            color: 'var(--medium-gray)',
            fontSize: '15px',
            '&:hover': {
                border: '1px solid var(--light-grayish)', // Prevent hover border color
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        menu: (provided) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            boxShadow: 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--light-grayish)' : 'white',
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
    };

    const handleChange = (e, name, selected_name) => {
        if (e) {
            formik.setValues({
                ...formik.values,
                [name]: e.value,
                [selected_name]: e,
            });
    
        
            if (name === "country") {
                getcitydrop(e.value); 
            }
        } else {
            formik.setValues({
                [name]: null,
                [selected_name]: [],
            });
    
           
            if (name === "country") {
                setCity([]);
            }
        }
    };
    

    let typingTimer;
    const loadOptionCategory = inputValue => {
        return new Promise((resolve, reject) => {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {
                if (inputValue.length >= 3) {
                    brandCategoryDropdown({ page: 1, limit: 10, search: inputValue })(response => {
                        if (response?.status) {

                            resolve(response?.data?.results)
                            setCategories(response?.data?.results)

                        }
                    })
                }
                else {
                    resolve([])
                }
            }, 300);

        })
    };
    const loadOptionCity = inputValue => {
        return new Promise((resolve, reject) => {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {
                if (inputValue.length >= 3) {
                    cityListDropdown({ page: 1, limit: 10, search: inputValue })(response => {
                        if (response?.status) {

                            resolve(response?.data?.results)
                            setCity(response?.data?.results)

                        }
                    })
                }
                else {
                    resolve([])
                }
            }, 300);

        })
    };
    const loadOptionCountry = inputValue => {
        return new Promise((resolve, reject) => {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {
                if (inputValue.length >= 3) {
                    countryListDropdown({ page: 1, limit: 10, search: inputValue })(response => {
                        if (response?.status) {

                            resolve(response?.data?.results)
                            setCountry(response?.data?.results)

                        }
                    })
                }
                else {
                    resolve([])
                }
            }, 300);

        })
    };
    const getbrandcategorydropdown = () => {
        brandCategoryDropdown({ page: 1, limit: 10 })(response => {
            if (response?.status) {
                setCategories(response?.data?.results)
            }
        })
    }
    const getcitydrop = (countryId = null) => {
        const payload = {
            page: 1,
            limit: 10,
            city_country: countryId,  
        };
    
        cityListDropdown(payload)(response => {
            if (response?.status) {
                setCity(response?.data?.results);
            }
        });
    };
    
    const getcountryydrop = () => {
        countryListDropdown({ page: 1, limit: 10 })(response => {
            if (response?.status) {
                setCountry(response?.data?.results)
            }
        })
    }
    useEffect(() => {
        getbrandcategorydropdown()
        getcitydrop()
        getcountryydrop()

    }, [])
      useEffect(()=>{
        if(id){
          getBrandDetails({id})(async(response)=>{
            if(response?.status){
              formik.setValues({
                id:response?.data?.id,
                brand_name:response?.data?.brand_name,
                brand_email:response?.data?.brand_email,
                category:response?.data?.category,
                 selected_category: {
                    label: response?.data?.category_name,
                    value: response?.data?.category
                  },
                  contact_number:response?.data?.contact_number,
                  country:response?.data?.country,
                 selected_country: {
                    label: response?.data?.country_name,
                    value: response?.data?.country
                  },
                  city:response?.data?.city,
                  selected_country: {
                     label: response?.data?.city_name,
                     value: response?.data?.city
                   },
                address:response?.data?.address,
                city:response?.data?.city?.name,

              brand_image: response?.data?.brand_image ? "data:image/png;base64," + await imageToBase64(response?.data?.brand_image) : "",
              })
            }
          })
        }
      },[])
    return (
      <div className='create-brands-page'>
          <div className='create-clubs-info-section create-brands'>
              <div className='page-details'>
                  <div className='panel-type-1'>
                      Add New Brand
                  </div>
                  <Form onSubmit={formik.handleSubmit}>
                      <div className='mt-5'>
                          <div className="row">
                              {/* Club Name */}
                              <div className="col-xl-3 col-md-4 mb-3">
                                  <Form.Group controlId="clubName">
                                      <Form.Label>Brand Name<span className='required'>*</span></Form.Label>
                                      <Form.Control type="text" placeholder="Brand Name" name="brand_name"
                                          onChange={(e) => {
                                              const value = e.target.value;
                                              formik.setFieldValue(
                                                  'brand_name',
                                                  value.charAt(0).toUpperCase() + value.slice(1)
                                              );
                                          }}
                                          isInvalid={formik.touched.brand_name && formik.errors.brand_name}
                                          value={formik.values.brand_name}
                                      />

                                      <Form.Control.Feedback type="invalid">
                                          {formik.errors.brand_name}
                                      </Form.Control.Feedback>

                                  </Form.Group>
                              </div>
                              {/* Email */}
                              <div className="col-xl-3 col-md-4 mb-4">
                                  <Form.Group controlId="email">
                                      <Form.Label>Email<span className='required'>*</span></Form.Label>
                                      <Form.Control type="email" placeholder="Email" name="brand_email"
                                          onChange={formik.handleChange}
                                          isInvalid={formik.touched.brand_email && formik.errors.brand_email}
                                          value={formik.values.brand_email}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                          {formik.errors.brand_email}
                                      </Form.Control.Feedback>

                                  </Form.Group>
                              </div>
                              {/* Contact Number */}
                              <div className="col-xl-3 col-md-4 mb-4">
                                  <Form.Group controlId="phone">
                                      <Form.Label>Contact Number<span className='required'>*</span></Form.Label>
                                      <Form.Control type="text" placeholder="Contact Number" name="contact_number"
                                          onChange={formik.handleChange}
                                          isInvalid={formik.touched.contact_number && formik.errors.contact_number}
                                          value={formik.values.contact_number}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                          {formik.errors.contact_number}
                                      </Form.Control.Feedback>
                                  </Form.Group>
                              </div>
                              {/* Category */}
                              <div className="col-xl-3 col-md-4 mb-4">
                                  <Form.Group controlId="category">
                                      <Form.Label>Category<span className='required'>*</span></Form.Label>
                                      <AsyncSelect
                                          loadOptions={loadOptionCategory}
                                          defaultOptions={category}
                                          classNamePrefix="select"
                                          isClearable={true}
                                          placeholder={'Select Category'}
                                          onChange={(e) => handleChange(e, 'category', 'selected_category')}
                                          value={formik.values?.selected_category}
                                          styles={{
                                              control: (styles, { isFocused }) => ({
                                                  ...styles,
                                                  maxHeight: '31px',
                                                  border: formik.errors.category && formik.touched.category
                                                      ? '1px solid #dc3545'
                                                      : '1px solid var(--light-grayish)',
                                                  outline: 'none',
                                                  boxShadow: isFocused ? '0 0 0 1px var(--light-grayish)' : 'none',
                                                  color: 'var(--medium-gray)',
                                                  fontSize: '15px',
                                                  '&:hover': {
                                                      border: formik.errors.category && formik.touched.category
                                                          ? '1px solid #dc3545'
                                                          : '1px solid var(--light-grayish)',
                                                  },
                                              }),
                                              valueContainer: (styles) => ({
                                                  ...styles,
                                                  alignItems: 'center',
                                                  padding: '3px 8px',
                                              }),
                                              singleValue: (provided) => ({
                                                  ...provided,
                                                  color: 'var(--medium-gray)',
                                                  fontSize: '15px',
                                              }),
                                              menu: (provided) => ({
                                                  ...provided,
                                                  border: '1px solid var(--light-grayish)',
                                                  boxShadow: 'none',
                                              }),
                                              placeholder: (styles) => ({
                                                  ...styles,
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                  color: 'var(--medium-gray)',
                                                  fontSize: '15px',
                                              }),
                                          }}
                                      />
                                      {formik.touched.category && formik.errors.category && <span className='required'>{formik.errors.category}</span>}
                                  </Form.Group>
                              </div>
                              <div className="col-xl-3 col-md-4 mb-4">
                                  <Form.Group controlId="country" title={formik?.values?.countryName || ''} >
                                      <Form.Label>Country<span className='required'>*</span></Form.Label>
                                      <AsyncSelect
                                          loadOptions={loadOptionCountry}
                                          defaultOptions={country}
                                          classNamePrefix="select"
                                          isClearable={true}
                                          placeholder={'Select Country'}
                                          onChange={(e) => handleChange(e, 'country', 'selected_country')}
                                          value={formik.values?.selected_country}
                                          styles={{
                                              control: (styles, { isFocused }) => ({
                                                  ...styles,
                                                  maxHeight: '31px',
                                                  border: formik.errors.country && formik.touched.country
                                                      ? '1px solid #dc3545'
                                                      : '1px solid var(--light-grayish)',
                                                  outline: 'none',
                                                  boxShadow: isFocused ? '0 0 0 1px var(--light-grayish)' : 'none',
                                                  color: 'var(--medium-gray)',
                                                  fontSize: '15px',
                                                  '&:hover': {
                                                      border: formik.errors.country && formik.touched.country
                                                          ? '1px solid #dc3545'
                                                          : '1px solid var(--light-grayish)',
                                                  },
                                              }),
                                              valueContainer: (styles) => ({
                                                  ...styles,
                                                  alignItems: 'center',
                                                  padding: '3px 8px',
                                              }),
                                              singleValue: (provided) => ({
                                                  ...provided,
                                                  color: 'var(--medium-gray)',
                                                  fontSize: '15px',
                                              }),
                                              menu: (provided) => ({
                                                  ...provided,
                                                  border: '1px solid var(--light-grayish)',
                                                  boxShadow: 'none',
                                              }),
                                              placeholder: (styles) => ({
                                                  ...styles,
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                  color: 'var(--medium-gray)',
                                                  fontSize: '15px',
                                              }),
                                          }}
                                      />
                                  </Form.Group>
                              </div>
                              <div className="col-xl-3 col-md-4 mb-4">
                                  <Form.Group controlId="city">
                                      <Form.Label>City<span className='required'>*</span></Form.Label>
                                      <AsyncSelect
                                          loadOptions={loadOptionCity}
                                          defaultOptions={city}
                                          classNamePrefix="select"
                                          isClearable={true}
                                          placeholder={'Select City'}
                                          onChange={(e) => handleChange(e, 'city', 'selected_city')}
                                          value={formik.values?.selected_city}
                                          styles={{
                                              control: (styles, { isFocused }) => ({
                                                  ...styles,
                                                  maxHeight: '31px',
                                                  border: formik.errors.city && formik.touched.city
                                                      ? '1px solid #dc3545'
                                                      : '1px solid var(--light-grayish)',
                                                  outline: 'none',
                                                  boxShadow: isFocused ? '0 0 0 1px var(--light-grayish)' : 'none',
                                                  color: 'var(--medium-gray)',
                                                  fontSize: '15px',
                                                  '&:hover': {
                                                      border: formik.errors.city && formik.touched.city
                                                          ? '1px solid #dc3545'
                                                          : '1px solid var(--light-grayish)',
                                                  },
                                              }),
                                              valueContainer: (styles) => ({
                                                  ...styles,
                                                  alignItems: 'center',
                                                  padding: '3px 8px',
                                              }),
                                              singleValue: (provided) => ({
                                                  ...provided,
                                                  color: 'var(--medium-gray)',
                                                  fontSize: '15px',
                                              }),
                                              menu: (provided) => ({
                                                  ...provided,
                                                  border: '1px solid var(--light-grayish)',
                                                  boxShadow: 'none',
                                              }),
                                              placeholder: (styles) => ({
                                                  ...styles,
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                  color: 'var(--medium-gray)',
                                                  fontSize: '15px',
                                              }),
                                          }}
                                      />
                                      {formik.touched.city && formik.errors.city && <span className='required'>{formik.errors.city}</span>}

                                  </Form.Group>
                              </div>
                              {/* Country */}
                
                              {/* Address 1 */}

                              {/* Status */}
                              {/* <div className="col-xl-3 col-md-4 mb-4">
                                  <Form.Group controlId="status">
                                      <Form.Label>Status<span className='required'>*</span></Form.Label>
                                      <Select
                                          placeholder="Select"
                                          styles={customStyles}
                                          options={statusOptions}
                                          isClearable
                                          isSearchable
                                      />
                                  </Form.Group>
                              </div> */}

                              <div className='col-md-3 mb-4'>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1em7">
                                      <Form.Label>Brand Image</Form.Label>
                                      <div className='input-border'>
                                          <Form.Control
                                              type="file"
                                              className='input-field'
                                              onChange={handleFileChange}
                                          />
                                          {photo && (
                                              <div className="mt-2 position-relative">
                                                  <img
                                                      src={photo}
                                                      alt="Uploaded"
                                                      style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                                  />
                                                  {/* Delete Button */}

                                                  <MdOutlineDelete
                                                      color='red'
                                                      style={{ fontSize: '20px', cursor: 'pointer', marginTop: "80px" }}
                                                      onClick={() => setPhoto(null)}
                                                  />

                                              </div>
                                          )}
                                      </div>
                                  </Form.Group>
                              </div>
                              <div className="col-xl-3 col-md-3 mb-4">
                                  <Form.Group controlId="address1">
                                      <Form.Label>Address</Form.Label>
                                      <Form.Control as="textarea" placeholder="Address" name="address"
                                          onChange={formik.handleChange}
                                          isInvalid={formik.touched.address && formik.errors.address}
                                          value={formik.values.address} />
                                  </Form.Group>
                              </div>
                          </div>
                          <div className='submit-box d-flex align-items-center justify-content-end gap-3 mt-5'>
                              <div className='btn-type-2 cursor-pointer' onClick={() => navigate(BRANDS)}>Cancel</div>
                              <button className='btn-type-5' type="submit">Save</button>
                              <button className='btn-type-1 publish-btn' type="submit" >Publish</button>
                          </div>
                      </div>
                  </Form>
              </div>
          </div>
      </div>
    )
}

export default CreateBrands