import React from 'react'
import ClubProfile from './ClubProfile'

const CreateClubsInfo = () => {
  
    return (
        <div className='create-clubs-info-section'>
            <div className='page-details'>
                <ClubProfile />
            </div>
        </div>
    )
}

export default CreateClubsInfo