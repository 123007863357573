import { Form } from 'react-bootstrap'
import { FACILITIES_CATEGORY } from '../../constants/pathname';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { createAddfacitiltyCategory, getfaciltycategoryDetails } from '../../api/actions/addFacitiltycCategory';
import * as Yup from 'yup';
import { MdOutlineDelete } from 'react-icons/md';
import imageToBase64 from 'image-to-base64/browser'
import { checkTheme } from '../../utils/utils';
import { ReactSVG } from 'react-svg';

const CreateFacilityCategory = ({ modal = false, setShowModal, setRefresh, refresh }) => {
    const navigate = useNavigate();
    const [photo, setPhoto] = useState(null);
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const isNightMode = checkTheme(); // true for PCN (night), false for PCW (day)

    // Validation schema for the form
    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required'),
        // Require either pcn_icon_class or pcw_icon_class based on current theme
        ...(isNightMode
            ? { pcn_icon_class: Yup.string().required('Icon image is required') }
            : { pcw_icon_class: Yup.string().required('Icon image is required') })
    });
    
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            outline: 'none',
            boxShadow: 'none',
            color: 'var(--medium-gray)',
            fontSize: '15px',
            '&:hover': {
                border: '1px solid var(--light-grayish)', // Prevent hover border color
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
        menu: (provided) => ({
            ...provided,
            border: '1px solid var(--light-grayish)',
            boxShadow: 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--light-grayish)' : 'white',
            color: 'var(--medium-gray)',
            fontSize: '15px',
        }),
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            description: '',
            pcw_icon_class: '',
            pcn_icon_class: '',
            pcw: !isNightMode,
            pcn: isNightMode,
        },
        validationSchema,
        onSubmit: (values, { resetForm, setErrors }) => {
            setIsLoading(true);
            createAddfacitiltyCategory(values)((response) => {
                setIsLoading(false);
                if (response.status_code === 200 || response.status_code === 201) {
                    toast.success(!modal && id ? "Facility category updated successfully" : 'Facility category created successfully!');
                    !modal ? navigate(FACILITIES_CATEGORY) : setShowModal(false);
                    resetForm();
                    if (setRefresh) setRefresh(!refresh);
                } else {
                    toast.error(response?.response?.data?.message || "Something went wrong!");
                }
            });
        },
    });

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
            const maxSize = 5 * 1024 * 1024; // 5MB

            if (!allowedTypes.includes(file.type)) {
                toast.error('Please upload a valid image (JPEG, PNG, or GIF)');
                return;
            }

            if (file.size > maxSize) {
                toast.error('File size should not exceed 5MB');
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setPhoto(base64String);

                // Set the image for the current theme mode
                if (isNightMode) {
                    formik.setFieldValue('pcn_icon_class', base64String);
                } else {
                    formik.setFieldValue('pcw_icon_class', base64String);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const removeImage = () => {
        setPhoto(null);
        document.getElementById('iconClassInput').value = '';

        // Clear the icon for the current theme mode
        if (isNightMode) {
            formik.setFieldValue('pcn_icon_class', '');
        } else {
            formik.setFieldValue('pcw_icon_class', '');
        }
    };

    const accessOptions = [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }];

    const handleChange = (e, name, selected_name) => {
        if (e) {
            formik.setValues({
                ...formik.values,
                [name]: e.value,
                [selected_name]: e,
            });
        } else {
            formik.setFieldValue(name, null);
            formik.setFieldValue(selected_name, []);
        }
    };

    useEffect(() => {
        if (id) {
            getfaciltycategoryDetails({ id })(async (response) => {
                if (response?.status) {
                    const pcw_icon = response?.data?.pcw_icon_class || "";
                    const pcn_icon = response?.data?.pcn_icon_class || "";
                    formik.setValues({
                        ...formik.values,
                        id: response?.data?.id,
                        name: response?.data?.name,
                        description: response?.data?.description,
                        pcw_icon_class: pcw_icon,
                        pcn_icon_class: pcn_icon,
                        access: response?.data?.access ? 'true' : 'false',
                        select_access: accessOptions.find((item) => item.value === (response?.data?.access ? 'true' : 'false')),
                        pcw: !isNightMode,
                        pcn: isNightMode,
                    });

                    // Set the photo based on current theme
                    setPhoto(isNightMode ? pcn_icon : pcw_icon);
                
                }
            });
        }
    }, [id]);

    return (
        <div className='create-clubs-info-section'>
            <div className='page-details'>
                <div className='club-profile-section'>

                    {!modal && <div className='panel-type-1'>
                        {id ? "Update Facility Category" : "Add Facility Category"}
                    </div>}
                    <Form onSubmit={formik.handleSubmit}>
                        <div className='club-form '>
                            <div className={`club-form ${modal ? '' : 'mt-5'}`}>

                                <div className="row">
                                    {/* Name */}
                                    <div className='col-md-4 mb-4'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1em6">
                                            <Form.Label>Name<span className="required">*</span></Form.Label>
                                            <div className='input-border'>
                                                <Form.Control
                                                    type="text"
                                                    className='input-field'
                                                    name='name'
                                                    value={formik.values?.name}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        formik.setFieldValue(
                                                            'name',
                                                            value.charAt(0).toUpperCase() + value.slice(1)
                                                        );
                                                    }}
                                                    isInvalid={formik.touched.name && !!formik.errors.name}
                                                />
                                                {formik.touched.name && formik.errors.name && (
                                                    <div className="text-danger mt-1">{formik.errors.name}</div>
                                                )}
                                            </div>
                                        </Form.Group>
                                    </div>
                                    {/* Icon Class */}
                                    <div className='col-md-4 mb-4'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1em7">
                                            <Form.Label>
                                                Icon Class
                                                <span className="required">*</span>
                                            </Form.Label>
                                            <div className='input-border'>
                                                <Form.Control
                                                    type="file"
                                                    id="iconClassInput"
                                                    accept="image/jpeg, image/png, image/gif"
                                                    className='input-field'
                                                    onChange={handleFileChange}
                                                    isInvalid={(isNightMode && formik.touched.pcn_icon_class && !!formik.errors.pcn_icon_class) ||
                                                        (!isNightMode && formik.touched.pcw_icon_class && !!formik.errors.pcw_icon_class)}
                                                />
                                                {isNightMode && formik.touched.pcn_icon_class && formik.errors.pcn_icon_class && (
                                                    <div className="text-danger mt-1">{formik.errors.pcn_icon_class}</div>
                                                )}
                                                {!isNightMode && formik.touched.pcw_icon_class && formik.errors.pcw_icon_class && (
                                                    <div className="text-danger mt-1">{formik.errors.pcw_icon_class}</div>
                                                )}
                                                {photo && (
                                                    <div className="mt-2 position-relative">
                                                        {/\.svg$/i.test(photo) ? (
                                                          <ReactSVG src={photo} style={{ width: "50px", height: "50px" }} className='facility-svg-icon' />
                                                        ) : (
                                                            

                                                            <img
                                                                src={photo}
                                                                alt="Icon"
                                                                style={{
                                                                    width: "100px",
                                                                    height: "100px",
                                                                    objectFit: "cover",
                                                                }}
                                                            />  // <div dangerouslySetInnerHTML={{ __html: photo }} />
                                                        )}

                                                        <MdOutlineDelete
                                                            color="red"
                                                            style={{
                                                                fontSize: "20px",
                                                                cursor: "pointer",
                                                                marginTop: "80px",
                                                            }}
                                                            onClick={removeImage}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </Form.Group>
                                    </div>

                                    {/* Description */}
                                    <div className='col-md-12 mb-4'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1em2">
                                            <Form.Label>Description</Form.Label>
                                            <div className='input-border'>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={6}
                                                    className='input-field'
                                                    name='description'
                                                    value={formik.values?.description}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        formik.setFieldValue(
                                                            'description',
                                                            value.charAt(0).toUpperCase() + value.slice(1)
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='submit-box d-flex align-items-center justify-content-end gap-3'>
                            <button className='btn-type-2 cursor-pointer' type="button" onClick={() => {
                                !modal ? navigate(FACILITIES_CATEGORY) : setShowModal(false)
                            }}>Cancel</button>
                            <button className='btn-type-1' type="submit" disabled={isLoading}>
                                {id && !modal ? "Update" : "Submit"}
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default CreateFacilityCategory