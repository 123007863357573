import React from 'react'
import CreateSpecialSubscribers from '../../components/CreateSpecialSubscribers/CreateSpecialSubscribers'

const CreateSpecialSubscribersPage = () => {
  return (
    <div className='create-special-subscribers-page'>
        <CreateSpecialSubscribers />
    </div>
  )
}

export default CreateSpecialSubscribersPage