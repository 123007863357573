import React from 'react'
import CreatePrivateEvents from '../../components/CreatePrivateEvents/CreatePrivateEvents'

const CreatePrivateEventsPage = () => {

  return (
    <div className='create-private-events'>
      <CreatePrivateEvents />
    </div>
  )
}

export default CreatePrivateEventsPage