import React from 'react'
import PushNotifications from '../../components/PushNotifications/PushNotifications'

const PushNotificationsPage = () => {

  return (
    <div className='push-notifications-page'>
        <PushNotifications />
    </div>
  )
}

export default PushNotificationsPage