import React from 'react'
import { Form, Container, Card } from "react-bootstrap";

const ClubEventsProfile=() => {
    return (
        <div className='manage-members-page cur'>
            <div className='manage-members member-stats-section'>
                <div className='page-details'>
                    <div className='top-section'>
                        <div className='left'>
                            Club Events Profile
                        </div>
                    </div>
                    <Container className="mt-4">
                        <Form>
                            {/* Question 1 */}
                            <Form.Group className="mb-3">
                                <Card className="p-3 border">
                                    <Form.Label style={{fontWeight: 500}}>1. How many calendar Member Events do you host at your Club per year?</Form.Label>
                                    <div style={{paddingLeft: "30px"}}>
                                        <Form.Check type="radio" label="Less than 50 Events per Year" name="eventsPerYear" />
                                        <Form.Check type="radio" label="51-100 Events per Year" name="eventsPerYear" />
                                        <Form.Check type="radio" label="101-150 Events per Year" name="eventsPerYear" />
                                        <Form.Check type="radio" label="151-200 Events per Year" name="eventsPerYear" />
                                        <Form.Check type="radio" label="201-250 Events per Year" name="eventsPerYear" />
                                        <Form.Check type="radio" label="251-300 Events per Year" name="eventsPerYear" defaultChecked />
                                        <Form.Check type="radio" label="More than 300 Events per Year" name="eventsPerYear" />
                                    </div>
                                </Card>
                            </Form.Group>

                            {/* Question 2 */}
                            <Form.Group className="mb-3">
                                <Card className="p-3 border">
                                    <Form.Label style={{fontWeight: 500}}>2. How many Members (on average) attend Events?</Form.Label>
                                    <div style={{paddingLeft: "30px"}}>
                                        <Form.Check type="radio" label="1-25" name="avgMembers" />
                                        <Form.Check type="radio" label="25-50" name="avgMembers" />
                                        <Form.Check type="radio" label="50-100" name="avgMembers" />
                                        <Form.Check type="radio" label="100-150" name="avgMembers" defaultChecked />
                                        <Form.Check type="radio" label="150-200" name="avgMembers" />
                                        <Form.Check type="radio" label="200+" name="avgMembers" />
                                    </div>
                                </Card>
                            </Form.Group>

                            {/* Question 3 */}
                            <Form.Group className="mb-3">
                                <Card className="p-3 border">
                                    <Form.Label style={{fontWeight: 500}}>3. How often do you host Events at your Club?</Form.Label>
                                    <div style={{paddingLeft: "30px"}}>
                                        <Form.Check type="radio" label="Weekly" name="eventFrequency" />
                                        <Form.Check type="radio" label="Biweekly" name="eventFrequency" defaultChecked />
                                        <Form.Check type="radio" label="Monthly" name="eventFrequency" />
                                        <Form.Check type="radio" label="Quarterly" name="eventFrequency" />
                                        <Form.Check type="radio" label="Semi-annually" name="eventFrequency" />
                                        <Form.Check type="radio" label="Annually" name="eventFrequency" />
                                    </div>
                                </Card>
                            </Form.Group>

                            {/* Question 4 */}
                            <Form.Group className="mb-3">
                                <Card className="p-3 border">
                                    <Form.Label style={{fontWeight: 500}}>4. What type of Events would you like to host for your Members?</Form.Label>
                                    <div style={{paddingLeft: "30px"}}>
                                        <Form.Control as="textarea" placeholder="Please type here..." rows={2} />
                                    </div>
                                </Card>
                            </Form.Group>

                            {/* Question 5 */}
                            <Form.Group className="mb-3">
                                <Card className="p-3 border">
                                    <Form.Label style={{fontWeight: 500}}>5. Does your Club host and/or sponsor a Charitable Event(s)?</Form.Label>
                                    <div style={{paddingLeft: "30px"}}>
                                        <Form.Check type="radio" label="Yes" name="charitableEvent" defaultChecked />
                                        <Form.Check type="radio" label="No" name="charitableEvent" />
                                    </div>
                                </Card>
                            </Form.Group>
                        </Form>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default ClubEventsProfile